import { urlWithQuery } from "../utilites/UrlUtils";

const RUN = "/screenshots-api/screenshot/run";
const SEARCH = "/screenshots-api/screenshot/search";
const SAVE = "/screenshots-api/screenshot/list";
const DATES = "/screenshots-api/screenshot/dates";
const SCHEDULES = "/scheduler-api/schedule/list?";
const SERVICE_CODE = "screenshot.service";
const CHECK_STATUS = "/screenshots-api/screenshot/checkStatus"

export const RunScreenshots = async (request) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(RUN, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        return [true, responseBody];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const SaveScreenshot = async (body) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(SAVE, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody ?? [];

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};
export const CheckStatus = async (orderId) => {
    const token = window.localStorage.getItem("token");
    const params = new URLSearchParams();

    params.append("orderId", orderId);

    let response = await fetch(`${CHECK_STATUS}?orderId=${orderId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody ?? [];

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetScreenshotsDate = async (deviceIds) => {
    const token = window.localStorage.getItem("token");
    const params = new URLSearchParams();

    deviceIds?.forEach((id) => {
        params.append("deviceIds", id);
    });

    let response = await fetch(urlWithQuery(DATES, params), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody ?? [];

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const SearchScreenshots = async (deviceIds, schedulerIds, from, to) => {
    const token = window.localStorage.getItem("token");
    const params = new URLSearchParams();

    deviceIds?.forEach((id) => {
        params.append("deviceIds", id);
    });

    if (schedulerIds) {
        params.append("schedulerIds", schedulerIds);
    }

    if (from) {
        params.append("from", from);
    }

    if (to) {
        params.append("to", to);
    }

    let response = await fetch(urlWithQuery(SEARCH, params), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody?.orders ?? [];

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetSchedules = async (filter) => {
    const token = window.localStorage.getItem("token");

    const service = filter.generalInfo?.services?.find((s) => SERVICE_CODE === s.code);

    const queryParams = new URLSearchParams({
        serviceIds: [service.serviceId],
    });

    if (filter?.like) {
        queryParams.append("like", filter.like);
    }

    let response = await fetch(SCHEDULES + queryParams, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();
        return [true, responseBody.data, responseBody.count];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetSchedule = async (schedule) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`/general-api/service/${schedule.serviceId}/schedule/${schedule.id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, null];
    }

    try {
        let responseBody = await response.json();
        return [true, responseBody];
    } catch (ex) {
        console.error(ex);

        return [false, null];
    }
};

import { Row, Text } from "headpoint-react-components";
import { ClearButton } from "../CreateUpdateWizard/Styled";
import React from "react";

export function SelectedCounter({ count, onClear, strings }) {
    return (
        <Row align="middle">
            <Text variant="body-sm" align="right">
                {`${strings("Выбрано:")} ${count}`}
            </Text>
            {!!count && <ClearButton onClick={onClear} />}
        </Row>
    );
}

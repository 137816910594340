import React from "react";
import { startPeer, closePeer } from "../../../../services/webrtc";
import "moment-duration-format";
import { Mutex } from "async-mutex";
import { GetArchiveUrl } from "../../../../services/archive";
import { withSnackbar } from "notistack";
import { getDeviceTypes, getServiceConfig } from "../../../../contexts";
import { WebGLPlayer } from "../../../../services/WebAssembly/webgl";
import { WsClient } from "../../../../services/WebAssembly/ws_client";
import Module from "../../../../services/WebAssembly/decoder.mjs";
import { WebCodecsClient } from "../../../../services/WebCodecs/ws_client";
import { DEFAULT_MODE, FRAME_MODE } from "./SyncCameraFrames";
import { fetchFile } from "@ffmpeg/ffmpeg";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class SyncArchivePlayer extends React.Component {
    constructor(props) {
        super(props);

        this.videoTag = `videoCtrl${props.frameIndex}`;
        this.lock = new Mutex();
        this.clientProtocol = props.clientProtocol ?? "webrtc";

        const currentFrame = { ...props.currentFrame };

        this.state = {
            captureTime: 0,
            currentFrame: currentFrame,
            wasmModule: null,
            closeArchiveStreamTimer: null,
        };
    }

    async componentDidMount() {
        if (this.clientProtocol === "websocket") {
            this.setState({ wasmModule: await Module() }, async () => await this.start());
        } else {
            await this.start();
        }
    }

    async componentWillUnmount() {
        await this.stop(this.peer);
    }

    tryExitFullscreen() {
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else {
                alert("Exit fullscreen doesn't work");
            }
        }
    }

    async convertImage(screenshotTime) {
        const { currentFrame } = this.state;
        const { removeFromFfmpegMemory, writeToFfmpegMemory, convertToPng, readFromFfmpegMemory } = this.props;

        const img = document.getElementById(this.videoTag);

        img.style.height = "auto";
        img.src = "/img/archive-placeholder.png";

        const token = window.localStorage.getItem("token");

        const image = await fetchFile(
            `/balancer/recorder-${currentFrame.id}/cameras/${currentFrame.id}/screenshot?from=${screenshotTime}&token=${token}`
        );

        const outputImage = `output-${currentFrame.id}.png`;
        const sourceImage = `"screenshot-${currentFrame.id}.h264`;

        removeFromFfmpegMemory(outputImage);

        writeToFfmpegMemory(sourceImage, image);
        await convertToPng(sourceImage, outputImage);

        const data = readFromFfmpegMemory(outputImage);
        const url = URL.createObjectURL(new Blob([data.buffer], { type: "image/png" }));

        removeFromFfmpegMemory(sourceImage);

        img.src = url;
        img.style.height = "inherit";
    }

    getSnapshotCanvas() {
        if (this.clientProtocol === "websocket") {
            return document.getElementById(this.videoTag);
        }

        const video = document.getElementById(this.videoTag);

        let canvas = document.createElement("canvas");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas;
    }

    handleWebsocketTimestamp = (captureTime) => {
        const { intersection, handleMainOffset, scaleDirection } = this.props;

        const offset = captureTime - intersection.from;

        this.setState({
            captureTime: captureTime,
        });

        handleMainOffset(offset);

        if (captureTime >= intersection.to) {
            void this.stop(this.peer);
        } else if (captureTime <= intersection.from && scaleDirection === -1) {
            void this.stop(this.peer);
        }
    };

    async start() {
        const { currentFrame } = this.state;
        const {
            enqueueSnackbar,
            handleMainOffset,
            intersection,
            mainOffset,
            generalInfo,
            scale,
            scaleDirection,
            playerMode,
        } = this.props;

        if (this.peer) {
            await this.stop(this.peer);
        }

        if (playerMode === FRAME_MODE) {
            const frameTime = intersection.from + mainOffset;

            await this.convertImage(frameTime);
        } else {
            const { rtcPeerConfig } = getServiceConfig(generalInfo, "service.api.archive");

            if (!rtcPeerConfig) {
                enqueueSnackbar(`Отсутствует конфигурация RTCPeerConnection для камеры ${currentFrame.name}`, {
                    variant: "error",
                });
                return;
            }

            const release = await this.lock.acquire();

            try {
                const { id } = currentFrame;

                const startTime = Math.round(intersection.from + mainOffset);
                const endTime = Math.round(intersection.to);

                const serviceCode = getDeviceTypes(generalInfo)?.find(
                    (dt) => dt?.value === currentFrame.typeId
                )?.serviceCode;
                if (!serviceCode) {
                    enqueueSnackbar("Не удалось найти сервис по типу оборудования", { variant: "error" });
                    return;
                }

                const scaleToApply = scale * scaleDirection;

                let [status, urlFull] = await GetArchiveUrl(serviceCode, id, startTime, endTime, scaleToApply);
                if (!status) {
                    enqueueSnackbar("Ошибка получения URL архива", { variant: "error" });
                    return;
                }

                switch (this.clientProtocol) {
                    case "webcodecs":
                        this.peer = new WebCodecsClient({
                            videoTag: this.videoTag,
                            handleWebsocketTimestamp: this.handleWebsocketTimestamp,
                        });

                        if (this.peer.isOpen()) {
                            this.peer.close();
                        }

                        this.peer.open({
                            url: `${WebCodecsClient.getUrl(id)}`,
                            rtsp: urlFull.url,
                            externalId: id,
                        });

                        break;

                    case "websocket":
                        if (!this.state.wasmModule) {
                            enqueueSnackbar("Отсутствует модуль WebAssemly", { variant: "error" });
                            return;
                        }

                        this.peer = new WsClient({
                            handleWebsocketTimestamp: this.handleWebsocketTimestamp,
                            Module: this.state.wasmModule,
                        });

                        if (this.peer.isOpen()) {
                            this.peer.close();
                        }

                        const canvas = document.getElementById(this.videoTag);
                        let player = new WebGLPlayer(canvas);

                        this.peer.open({
                            url: `${WsClient.getUrl(id)}`,
                            player: player,
                            rtsp: urlFull.url,
                        });
                        break;

                    default:
                        if (!document.getElementById(this.videoTag)) {
                            return;
                        }

                        const [startPeerStatus, pc, responseCode] = await startPeer(
                            urlFull.url,
                            id,
                            document.getElementById(this.videoTag),
                            JSON.parse(rtcPeerConfig)
                        );
                        if (!startPeerStatus) {
                            if (responseCode === "unauthorized") {
                                enqueueSnackbar(`Нет доступа к устройству '${currentFrame.name}'`, {
                                    variant: "error",
                                });
                            } else {
                                enqueueSnackbar("Ошибка подключения", { variant: "error" });
                            }
                            return;
                        }

                        pc.ondatachannel = (event) => {
                            event.channel.onmessage = (ev) => {
                                const message = JSON.parse(ev.data);
                                if (message.error) {
                                    switch (message.error) {
                                        case "unsupportedCodec":
                                            this.props.enqueueSnackbar("Неподдерживаемый кодек трансляции", {
                                                variant: "error",
                                            });
                                            break;
                                        case "cameraConnectionTimeout":
                                            this.props.enqueueSnackbar("Таймаут подключения к трансляции", {
                                                variant: "error",
                                            });
                                            break;
                                        case "cameraConnectionFailed":
                                        case "unknown":
                                            this.props.enqueueSnackbar("Ошибка подключения к трансляции", {
                                                variant: "error",
                                            });
                                            break;
                                        default:
                                            this.props.enqueueSnackbar("Неизвестная ошибка подключения", {
                                                variant: "error",
                                            });
                                            break;
                                    }
                                    console.error(message.message);
                                    void this.stop();
                                }

                                const offset = message.captureTime - intersection.from;

                                this.setState({ captureTime: message.captureTime });

                                handleMainOffset(offset);

                                if (message.captureTime >= intersection.to) {
                                    void this.stop(this.peer);
                                } else if (message.captureTime <= intersection.from && scaleDirection === -1) {
                                    void this.stop(this.peer);
                                }
                            };
                        };

                        this.peer = pc;
                        break;
                }
            } finally {
                release();
            }
        }
    }

    async stop(peerToClose) {
        const { playerMode } = this.props;

        if (playerMode === DEFAULT_MODE) {
            const video = document.getElementById(this.videoTag);

            if (this.clientProtocol === "webrtc") {
                video.pause();

                await closePeer(peerToClose);
            } else {
                if (peerToClose) {
                    await peerToClose.close();
                }
            }
            this.peer = null;
        }
    }

    render() {
        const { playerMode, userInfo } = this.props;

        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <>
                {playerMode === FRAME_MODE ? (
                    <img
                        id={this.videoTag}
                        alt="camera screenshot"
                        style={{
                            position: "absolute",
                            maxWidth: "100%",
                            maxHeight: "100%",
                            width: "auto",
                            height: "auto",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                ) : (
                    <>
                        {this.clientProtocol === "websocket" ? (
                            <canvas
                                id={this.videoTag}
                                style={{
                                    position: "absolute",
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    width: "auto",
                                    height: "auto",
                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            />
                        ) : (
                            <video
                                id={this.videoTag}
                                autoPlay
                                style={{ position: "absolute", width: "100%", height: "100%" }}
                            />
                        )}
                    </>
                )}
            </>
        );
    }
}

class SyncArchivePlayerWrapper extends React.Component {
    render() {
        return React.createElement(SyncArchivePlayer, { ...this.props, ref: this.props.nextRef });
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps)(withCultureContext(withSnackbar(SyncArchivePlayerWrapper)));

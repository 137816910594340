import React from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { nanoid } from "nanoid";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabExtension extends React.Component {
    render() {
        const { location, detailsTab, locationsExtensions } = this.props;

        const extensions = locationsExtensions?.filter((ext) => ext?.details?.detailsTab === detailsTab);
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                {extensions?.map((ext) => (
                                    <TableRow key={nanoid()}>
                                        <TableCell>{strings(ext?.title)}</TableCell>
                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {strings(location?.properties?.[ext?.name ?? ""] ?? "...")}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { Text, Table, TableHead, TableHeadCell, TableBody, TableRow, TableCell, Row } from "headpoint-react-components";

import { DetailsBoxContent } from "../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabEventTypes extends React.Component {
    eventTypesCount = (adapter) => {
        return adapter.eventTypes ? adapter.eventTypes.length : 0;
    };
    render() {
        const { selectedAdapter } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Row marginTop={24} marginBottom={16}>
                            <Text variant="title" component="h3">
                                {`${this.eventTypesCount(selectedAdapter)} ${strings("событий адаптера")}`}
                            </Text>
                        </Row>

                        <Table isFixed noPadding>
                            <colgroup>
                                <col />
                                <col style={{ width: "50%" }} />
                                <col />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("ID")}</TableHeadCell>
                                    <TableHeadCell>{strings("события адаптера")}</TableHeadCell>
                                    <TableHeadCell>{strings("важность")}</TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {selectedAdapter.eventTypes?.map((item) => (
                                    <TableRow key={item.code}>
                                        <TableCell>
                                            <Text color={"secondary"} variant="body-sm">
                                                {item.code}
                                            </Text>
                                        </TableCell>

                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {item.name}
                                            </Text>
                                        </TableCell>

                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {item.severity ?? "info"}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { Checkbox, ModalSection, Row, Text, TextField } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { FillLocations, GetLocationList, GetLocations } from "../../../../../../services/locations";
import { FilterAccordion } from "../../../../../components/Filters";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";
import { SelectedCounter } from "../../../../../components/SelectedCounter/SelectedCounter";
const LOCATIONS_LIMIT = 10;

class ModalStepLocations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            filteredLocations: [],
            search: "",
        };
    }

    openLocation = async (locationNode) => {
        const { devicesLocationsTree } = this.state;
        const [, children] = await GetLocations(locationNode.id);
        locationNode.children = FillLocations(children, []);

        this.setState({
            devicesLocationsTree: devicesLocationsTree,
        });
    };

    async componentDidMount() {
        const [, locations] = await GetLocations();

        this.setState({
            devicesLocationsTree: FillLocations(locations, []),
        });
    }

    async setSearch(search) {
        if (search && search !== "") {
            let [locationsStatus, locations] = await GetLocationList({ like: search, limit: LOCATIONS_LIMIT });

            if (!locationsStatus) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
                return;
            }

            this.setState({ search, filteredLocations: locations });
        } else {
            this.setState({ search, filteredLocations: [] });
        }
    }

    setLocationIdToProperties(item) {
        if (this.props.values.locations?.indexOf(item) >= 0) {
            const items = this.props.values.locations.filter((l) => l !== item);
            this.props.setLocations(items);
        } else {
            const items = [...this.props.values.locations, item];
            this.props.setLocations(items);
        }
    }

    render() {
        const { search, filteredLocations } = this.state;
        const { userInfo } = this.props;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        const { values } = this.props;
        const disabled = userInfo.userId === values.id;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <Row justify="between" align="bottom" marginBottom={24}>
                                <Text variant="body">{strings("Доступные локации")}</Text>
                                <SelectedCounter
                                    count={this.props.values.locations?.length ?? 0}
                                    onClear={() => {
                                        if (disabled) {
                                            return;
                                        }

                                        this.props.setLocations([]);
                                    }}
                                    strings={strings}
                                />
                            </Row>
                            <Row marginBottom={12}>
                                <TextField
                                    autoComplete={false}
                                    colorVariant="light"
                                    fullWidth
                                    icon="search"
                                    onChange={(e) => this.setSearch(e.target.value)}
                                    placeholder={strings("Найти")}
                                    type="search"
                                    value={search}
                                />
                            </Row>
                            {search && search !== "" ? (
                                filteredLocations.map((location) => (
                                    <Row marginBottom={12} key={location.id}>
                                        <Checkbox
                                            label={location.name}
                                            checked={this.props.values.locations?.indexOf(location.id) >= 0}
                                            onChange={() => this.setLocationIdToProperties(location.id)}
                                        />
                                    </Row>
                                ))
                            ) : (
                                <FilterAccordion
                                    items={this.state.devicesLocationsTree}
                                    selected={this.props.values.locations ?? []}
                                    openItem={this.openLocation}
                                    selectHandler={(items) => {
                                        if (disabled) {
                                            return;
                                        }

                                        this.props.setLocations(items);
                                    }}
                                />
                            )}
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(ModalStepLocations));

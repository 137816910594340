import React from "react";
import { useState, useEffect } from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { Checkbox, Select, Text, TextField } from "headpoint-react-components";
import { CompareObjects } from "../../../../../../utilites/Comparer";
import { GetOptions } from "../../../../../../services/featureCommands.js";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

export class ServiceFeatureSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedServiceFeature: {},
            selectedFeatureName: "",
            availableClassFeatures: [],
            featureSelectOptions: [],
        };
    }

    componentDidMount() {
        this.mapServiceFeatures();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { activeStep } = this.props;

        const compareFrom = { activeStep };
        if (!CompareObjects(compareFrom, prevProps)) {
            this.mapServiceFeatures();
        }
    }

    mapServiceFeatures() {
        const { availableServiceFeatures, steps, activeStep, deviceFeatureSettings } = this.props;
        if (steps.length >= activeStep) {
            const featureClassNameFromStep = steps[activeStep - 1]?.name;

            let availableClassFeatures = availableServiceFeatures.filter(
                (f) => f.featureClassName === featureClassNameFromStep
            );

            if (availableClassFeatures.length === 1) {
                const selectedServiceFeature = availableClassFeatures[0];
                if (this.props.mode === WIZARD_CREATE_MODE) {
                    this.initFeatureSettings(availableClassFeatures[0]);
                }

                this.setState({
                    selectedServiceFeature,
                    selectedFeatureName: availableClassFeatures[0].featureName,
                    availableClassFeatures,
                });
            } else {
                const featureSelectOptions = availableClassFeatures.map((f) => {
                    return { label: f.featureName, value: f.featureName };
                });

                const currentFeatureCode = availableClassFeatures[0]?.featureCode;

                if (currentFeatureCode && deviceFeatureSettings[currentFeatureCode]) {
                    let selectedFeatureName = deviceFeatureSettings[currentFeatureCode].featureName;
                    const linkedClassFeatureSettings = availableClassFeatures.find(
                        (f) => f.featureName === selectedFeatureName
                    );

                    this.setState({
                        selectedFeatureName,
                        selectedServiceFeature: linkedClassFeatureSettings,
                        featureSelectOptions,
                        availableClassFeatures,
                    });
                } else {
                    this.setState({ availableClassFeatures, featureSelectOptions, selectedServiceFeature: "" });
                }
            }
        }
    }

    initFeatureSettings(selectedServiceFeature) {
        const serviceCode = this.props.generalInfo.services.find((s) =>
            s?.properties?.features?.some((fs) => fs?.featureName === selectedServiceFeature?.featureName)
        )?.code;

        selectedServiceFeature?.commands?.settings?.forEach((param) => {
            if (param.required && !this.props.deviceFeatureSettings[selectedServiceFeature.featureCode]?.[param.code]) {
                const value = param.default ?? undefined;
                this.setFeatureParameter(value, selectedServiceFeature, param.code, serviceCode);
            }
        });
    }

    setFeatureParameter(value, serviceFeature, parameterCode, serviceCode) {
        let featureParameter = {};
        featureParameter[serviceFeature.featureCode] = {};
        featureParameter[serviceFeature.featureCode][parameterCode] = value;

        this.props.setFeatureSettings(
            featureParameter,
            serviceFeature.featureCode,
            serviceCode,
            serviceFeature.featureName
        );
    }

    render() {
        const { deviceFeatureSettings, generalInfo } = this.props;
        const { selectedServiceFeature, selectedFeatureName, availableClassFeatures, featureSelectOptions } =
            this.state;

        const serviceCode = generalInfo.services.find((s) =>
            s?.properties?.features?.some((fs) => fs?.featureName === selectedServiceFeature?.featureName)
        )?.code;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        {availableClassFeatures.length > 1 ? (
                            <ModalFormItem key={availableClassFeatures[0].featureSelectName}>
                                <Select
                                    label={strings(availableClassFeatures[0].featureSelectName)}
                                    placeholder={strings(availableClassFeatures[0].featureSelectName)}
                                    value={strings(selectedFeatureName)}
                                    options={featureSelectOptions ?? []}
                                    onChange={(value) => {
                                        this.setState({
                                            selectedFeatureName: value,
                                            selectedServiceFeature: availableClassFeatures.find(
                                                (f) => f.featureName === value
                                            ),
                                        });
                                    }}
                                    colorVariant="default"
                                    horizontalAlignment="left"
                                    verticalAlignment="bottom"
                                    fullWidth
                                />
                            </ModalFormItem>
                        ) : (
                            <ModalFormItem key={selectedServiceFeature.featureName}>
                                <Text>{strings(selectedFeatureName)}</Text>
                            </ModalFormItem>
                        )}
                        {selectedServiceFeature ? (
                            selectedServiceFeature?.commands?.settings?.map((param) => {
                                switch (param.type) {
                                    case "string":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <TextField
                                                    label={strings(param.name)}
                                                    name={param.code}
                                                    type="text"
                                                    value={
                                                        deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[
                                                            param.code
                                                        ] ?? param.default
                                                    }
                                                    onChange={(e) => {
                                                        this.setFeatureParameter(
                                                            e.target.value,
                                                            selectedServiceFeature,
                                                            param.code,
                                                            serviceCode
                                                        );
                                                    }}
                                                    colorVariant="light"
                                                    fullWidth
                                                    error={
                                                        param.required &&
                                                        !deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[
                                                            param.code
                                                        ]
                                                            ? strings(param?.errorText)
                                                            : null
                                                    }
                                                />
                                            </ModalFormItem>
                                        );
                                    case "select":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <Select
                                                    label={strings(param.name)}
                                                    placeholder={strings("Выбрать тип")}
                                                    value={
                                                        deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[
                                                            param.code
                                                        ] ?? param.default
                                                    }
                                                    options={param.options ?? []}
                                                    onChange={(value) => {
                                                        this.setFeatureParameter(
                                                            value,
                                                            selectedServiceFeature,
                                                            param.code,
                                                            serviceCode
                                                        );
                                                    }}
                                                    colorVariant="default"
                                                    horizontalAlignment="left"
                                                    verticalAlignment="bottom"
                                                    fullWidth
                                                    error={
                                                        param.required &&
                                                        !deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[
                                                            param.code
                                                        ]
                                                            ? strings(param?.errorText)
                                                            : null
                                                    }
                                                />
                                            </ModalFormItem>
                                        );
                                    case "dynamic-select":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <DynamicSelect
                                                    param={param}
                                                    deviceFeatureSettings={deviceFeatureSettings}
                                                    selectedServiceFeature={selectedServiceFeature}
                                                    serviceCode={serviceCode}
                                                    setFeatureSettings={this.props.setFeatureSettings}
                                                />
                                            </ModalFormItem>
                                        );
                                    case "int":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <TextField
                                                    label={strings(param.name)}
                                                    name={param.code}
                                                    type="number"
                                                    value={
                                                        deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[
                                                            param.code
                                                        ] ?? param.default
                                                    }
                                                    onChange={(e) => {
                                                        this.setFeatureParameter(
                                                            e.target.value,
                                                            selectedServiceFeature,
                                                            param.code,
                                                            serviceCode
                                                        );
                                                    }}
                                                    colorVariant="light"
                                                    fullWidth
                                                    error={
                                                        param?.required &&
                                                        !deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[
                                                            param.code
                                                        ]
                                                            ? strings(param?.errorText)
                                                            : null
                                                    }
                                                />
                                            </ModalFormItem>
                                        );
                                    case "checkbox":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <Checkbox
                                                    label={strings(param.name)}
                                                    checked={
                                                        deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[
                                                            param.code
                                                        ]
                                                    }
                                                    onChange={() => {
                                                        const value =
                                                            !deviceFeatureSettings?.[
                                                                selectedServiceFeature.featureCode
                                                            ]?.[param.code];
                                                        this.setFeatureParameter(
                                                            value,
                                                            selectedServiceFeature,
                                                            param.code,
                                                            serviceCode
                                                        );
                                                    }}
                                                />
                                            </ModalFormItem>
                                        );
                                    default:
                                        return <></>;
                                }
                            })
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

function DynamicSelect({ param, deviceFeatureSettings, selectedServiceFeature, serviceCode, setFeatureSettings }) {
    const [options, setOptions] = useState();
    const [selectedValue, setSelectedValue] = useState();

    useEffect(() => {
        const dataFetch = async () => {
            const [status, items] = await GetOptions(serviceCode, param.optionsUrl);

            if (status) {
                const options = items.map((item) => {
                    return { label: item.name, value: item.id };
                });

                setOptions(options);
            }
        };

        dataFetch();
        setSelectedValue(deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[param.code] ?? param.default);
    }, [param, serviceCode, deviceFeatureSettings, selectedServiceFeature]);

    const onSelect = (value) => {
        setSelectedValue(value);
        let featureParameter = {};
        featureParameter[selectedServiceFeature.featureCode] = {};
        featureParameter[selectedServiceFeature.featureCode][param.code] = value;

        setFeatureSettings(
            featureParameter,
            selectedServiceFeature.featureCode,
            serviceCode,
            selectedServiceFeature.featureName
        );
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Select
                    label={strings(param.name)}
                    placeholder={strings("Выбрать тип")}
                    value={selectedValue}
                    options={options ?? []}
                    onChange={onSelect}
                    colorVariant="default"
                    horizontalAlignment="left"
                    verticalAlignment="bottom"
                    fullWidth
                    error={
                        param.required && !deviceFeatureSettings?.[selectedServiceFeature.featureCode]?.[param.code]
                            ? strings(param?.errorText)
                            : null
                    }
                />
            )}
        </CultureContextConsumer>
    );
}

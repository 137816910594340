import React from "react";
import { withSnackbar } from "notistack";
import {
    checkFeatureByDeviceType,
    getAdapters,
    getDeviceTypes,
    permissionExists,
    serviceExists,
} from "../../../contexts";
import { CameraAngleModeButton, CameraEditAngleModeButton } from "../../components/CameraAngleButtons";
import MarkerViewAngle from "../../components/MarkerViewAngle";
import Marker from "../../components/Marker";
import pLimit from "p-limit";
import { GetViewZone, AddViewZoneDelta } from "../../../services/viewZones";
import { GetPoints } from "../../../services/map";
import moment from "moment";
import { debounce } from "debounce";
import PubSub from "pubsub-js";
import { SHOW_DETAILS_TOPIC, FREEZE_MAP_TOPIC, OPEN_CLUSTER_TOPIC, LOAD_CLUSTER_VIEW_TOPIC } from "./mapTopics";
import { isEqual as isDeepEqual } from "lodash";
import { ADJUST_VIEW_ZONE } from "../../stream/components/streamTopics";
import Cluster from "../../components/Cluster";
import { PlanPoint, PlanPointViewAngle, Text } from "headpoint-react-components";
import { connect } from "react-redux";
import { saveIsEditing, saveShowViewAngle } from "../../../app/reducers/mapReducer";

const VIDEO_STREAM_FEATURE_CODE = "video.stream";
const limit = pLimit(10);

class MapPoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zones: {},
            editingAngles: {},
        };

        this.debounceSaveAngle = debounce(this.saveAngle, 500);
    }

    loadPoints = () => {
        const { userInfo, generalContext, points, clusters, strings } = this.props;

        if (
            !serviceExists(generalContext, "service.api.view.angle") ||
            !permissionExists(userInfo, "view.angles.access")
        ) {
            return;
        }

        const adapters = getAdapters(generalContext) ?? [];
        const deviceTypes = getDeviceTypes(generalContext);
        let input =
            points
                ?.filter((p) => {
                    const deviceType = deviceTypes.find((type) => type.value === p?.deviceTypeId);
                    if (!deviceType) {
                        return false;
                    }

                    return checkFeatureByDeviceType(deviceType?.value, adapters, VIDEO_STREAM_FEATURE_CODE);
                })
                ?.map((p) => limit(async () => [p.id, ...(await GetViewZone(p.id))])) ?? [];

        clusters?.forEach((c) => {
            input = input.concat(
                c?.points
                    ?.filter((p) => {
                        const deviceType = deviceTypes.find((type) => type.value === p?.deviceTypeId);
                        if (!deviceType) {
                            return false;
                        }

                        return checkFeatureByDeviceType(deviceType?.value, adapters, VIDEO_STREAM_FEATURE_CODE);
                    })
                    ?.map((p) => limit(async () => [p.id, ...(await GetViewZone(p.id))])) ?? []
            );
        });

        Promise.all(input).then((responses) => {
            const successZoneResponses = responses.filter(([_, status]) => status);
            const failedZonesResponses = responses.filter(([_, status]) => !status);

            if (failedZonesResponses?.length) {
                failedZonesResponses.map(([id, _]) =>
                    this.props.enqueueSnackbar(
                        `${strings("Ошибка получения зоны обзора для точки")} "${
                            points.find((p) => p.id === id).name
                        }"`,
                        { variant: "error" }
                    )
                );
                console.error(failedZonesResponses);
            }

            const zones = successZoneResponses.reduce((zones, [id, _, diff]) => ({ ...zones, [id]: diff }), {});
            this.setState({ zones });
        });
    };

    componentDidMount = () => {
        const { userInfo, generalContext, strings } = this.props;
        const { userId } = userInfo;

        this.loadPoints();

        if (serviceExists(generalContext, "service.api.view.angle")) {
            this.moveZoneTopic = PubSub.subscribe(ADJUST_VIEW_ZONE, async (msg, { data }) => {
                if (!data?.id) {
                    this.props.enqueueSnackbar(strings("В объекте отсутствует информация!"), { variant: "error" });
                    console.error(data);
                    return;
                }

                let { zones, editingAngles } = this.state;
                let {
                    map: { isEditing },
                } = this.props;

                if (!isEditing) {
                    zones[data.id] += data?.pan ?? 0.0;
                    const [success, code] = await AddViewZoneDelta(data.id, data?.pan, userId);
                    if (!success) {
                        switch (code) {
                            case 403: // forbiden
                                this.props.enqueueSnackbar(strings("Не хватает прав для сохранения зоны обзора"), {
                                    variant: "warning",
                                });
                                break;

                            default:
                                this.props.enqueueSnackbar(strings("Не удалось сохранить зону обзора"), {
                                    variant: "error",
                                });
                                break;
                        }
                    }
                } else {
                    editingAngles[data.id] =
                        (editingAngles[data.id] ?? this.percentToDegrees(zones[data.id] ?? 0.0)) +
                        this.percentToDegrees(data?.pan ?? 0.0);
                }

                this.setState({ zones, editingAngles });
            });

            this.loadClusterViewZone = PubSub.subscribe(LOAD_CLUSTER_VIEW_TOPIC, async () => {
                this.loadPoints();
            });
        }
    };

    componentWillUnmount() {
        if (this.moveZoneTopic) {
            PubSub.unsubscribe(this.moveZoneTopic);
        }

        if (this.loadClusterViewZone) {
            PubSub.unsubscribe(this.loadClusterViewZone);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isDeepEqual(prevProps.points, this.props.points)) {
            this.loadPoints();
        }
    }

    getPointInfo = async (point) => {
        const { strings } = this.props;
        const [pointsStatus, points] = await GetPoints({ pointIds: [point.id] });

        if (pointsStatus && points?.length) {
            return points.find((p) => p.id === point.id);
        } else {
            this.props.enqueueSnackbar(strings("Ошибка получения объекта, информация в карточке устарела"), {
                variant: "error",
            });

            return point;
        }
    };

    filterPoints = (points) => {
        const { date } = this.state;
        if (date) {
            return points.filter((fp) => moment.utc(date).isSameOrAfter(moment.utc(fp.properties?.onDutyFrom), "day"));
        }

        return points;
    };

    saveAngle = async (point, userId) => {
        const { strings } = this.props;

        this.props.dispatch(saveIsEditing(false));
        const { editingAngles, zones } = this.state;
        if (!editingAngles[point.id]) {
            this.props.enqueueSnackbar(strings("Зона обзора не изменялась"), { variant: "success" });
            this.props.dispatch(saveIsEditing(false));

            PubSub.publish(FREEZE_MAP_TOPIC, false);
            return;
        }

        const [success, code] = await AddViewZoneDelta(
            point.id,
            this.degreesToPercent(editingAngles[point.id] - this.percentToDegrees(zones[point.id] ?? 0.0)),
            userId
        );

        if (success) {
            zones[point.id] = this.degreesToPercent(editingAngles[point.id]);
            delete editingAngles[point.id];
            this.setState({ editingAngles, zones });
            PubSub.publish(FREEZE_MAP_TOPIC, false);
            this.props.enqueueSnackbar(strings("Зона обзора сохранена"), { variant: "success" });
        } else {
            switch (code) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для сохранения зоны обзора"), {
                        variant: "warning",
                    });
                    break;

                default:
                    this.props.enqueueSnackbar(strings("Не удалось сохранить зону обзора"), { variant: "error" });
                    break;
            }
        }
    };

    percentToDegrees = (value) => {
        return (value * 360.0) % 360.0;
    };

    degreesToPercent = (value) => {
        return value / 360.0;
    };

    geViewAttributes = (point, generalContext) => {
        const { strings } = this.props;

        const deviceTypes = getDeviceTypes(generalContext);
        let icon = null;
        let tooltip = null;
        let type = null;

        switch (point.type?.toLowerCase()) {
            case "device":
                type = deviceTypes?.find((t) => t.value === point.deviceTypeId);
                icon = type?.icon?.type;
                tooltip = [
                    {
                        label: strings(type?.label),
                        text: (
                            <Text color={"gray"} variant={"body-sm"}>
                                {point.name}
                            </Text>
                        ),
                    },
                ];

                break;

            case "location":
                icon = "building";
                tooltip = [
                    {
                        label: strings("Локация"),
                        text: (
                            <Text color={"gray"} variant={"body-sm"}>
                                {point.name}
                            </Text>
                        ),
                    },
                ];

                break;

            default:
                break;
        }

        if (point?.properties?.observedObjects) {
            tooltip.push({
                label: strings("Объекты наблюдения"),
                text: (
                    <Text color={"gray"} variant={"body-sm"}>
                        {point?.properties?.observedObjects ?? ""}
                    </Text>
                ),
            });
        }

        return { icon, tooltip, type };
    };

    getEventVariant = (severities) => {
        if (severities?.includes("danger") && this.props.eventsFilter.showDanger) {
            return "danger";
        }

        if (severities?.includes("warning") && this.props.eventsFilter.showWarning) {
            return "warning";
        }
        return null;
    };

    getColorVariant = (severities, isViewAngle) => {
        if (severities?.includes("danger") && this.props.eventsFilter.showDanger) {
            return isViewAngle ? "red" : "danger";
        }

        if (severities?.includes("warning") && this.props.eventsFilter.showWarning) {
            return isViewAngle ? "yellow" : "warning";
        }

        return isViewAngle ? "green" : "success";
    };

    getClusterColorVariant = (severities) => {
        if (severities?.includes("danger") && this.props.eventsFilter.showDanger) {
            return "danger";
        }

        if (severities?.includes("warning") && this.props.eventsFilter.showWarning) {
            return "warning";
        }

        return "primary";
    };

    render() {
        const { editingAngles, zones } = this.state;
        const {
            userInfo,
            map: { isEditing, showViewAngle },
            points,
            generalContext,
            selected,
            clusters,
            onDrag,
        } = this.props;

        const adapters = getAdapters(generalContext) ?? [];
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }
        return (
            <>
                {serviceExists(generalContext, "service.api.view.angle") &&
                    permissionExists(userInfo, "view.angles.access") && (
                        <CameraAngleModeButton
                            onClick={(e) => {
                                e.stopPropagation();

                                this.props.dispatch(saveIsEditing(false));
                                this.props.dispatch(saveShowViewAngle(!showViewAngle));

                                PubSub.publish(FREEZE_MAP_TOPIC, selected && isEditing);
                            }}
                            checked={showViewAngle}
                        />
                    )}

                {serviceExists(generalContext, "service.api.view.angle") &&
                    permissionExists(userInfo, ["view.angles.access", "view.angles.set"]) && (
                        <CameraEditAngleModeButton
                            onClick={(e) => {
                                e.stopPropagation();
                                let newIsEditing = !isEditing;

                                this.props.dispatch(saveIsEditing(newIsEditing));
                                this.setState({
                                    editingAngles: newIsEditing ? editingAngles : {},
                                });

                                PubSub.publish(FREEZE_MAP_TOPIC, selected && newIsEditing);
                            }}
                            checked={isEditing}
                        />
                    )}

                {clusters?.map((c) => (
                    <Cluster
                        key={c.id}
                        position={[c.latitude ?? 0, c.longitude ?? 0]}
                        count={c.pointIds?.length}
                        isOpen={c.isOpen}
                        openCluster={(isOpen) => PubSub.publish(OPEN_CLUSTER_TOPIC, { id: c.id, isOpen })}
                        isMuted={!!selected && !!!c.isOpen}
                        eventVariant={this.getEventVariant(c?.events?.map((e) => e.severity).flat() ?? [])}
                        colorVariant={this.getClusterColorVariant(c?.events?.map((e) => e.severity).flat() ?? [])}
                    >
                        {c.points?.map((p) => {
                            const { icon, tooltip, type } = this.geViewAttributes(p, generalContext);
                            const severity = c.events?.find((e) => e.id === p.id)?.severity ?? [];

                            return showViewAngle &&
                                type?.value &&
                                checkFeatureByDeviceType(type?.value, adapters, VIDEO_STREAM_FEATURE_CODE) ? (
                                <PlanPointViewAngle
                                    key={p.id}
                                    angle={editingAngles[p.id] ?? this.percentToDegrees(zones[p.id])}
                                    colorVariant={this.getColorVariant(severity, true)}
                                    isEditing={isEditing && selected?.id === p.id}
                                    onChange={(angle) => {
                                        this.setState({ editingAngles: { ...editingAngles, [p.id]: angle } });
                                    }}
                                    onCancel={() => {
                                        delete editingAngles[p.id];

                                        this.props.dispatch(saveIsEditing(false));
                                        this.setState({ editingAngles });

                                        PubSub.publish(FREEZE_MAP_TOPIC, false);
                                    }}
                                    onAccept={() => this.saveAngle(p, userInfo?.userId)}
                                    point={
                                        <PlanPoint
                                            icon={icon}
                                            colorVariant={this.getColorVariant(severity)}
                                            eventVariant={this.getEventVariant(severity)}
                                            tooltip={tooltip}
                                            onClick={async () => {
                                                const point = await this.getPointInfo(p);
                                                if (isEditing) {
                                                    PubSub.publish(FREEZE_MAP_TOPIC, true);
                                                }

                                                PubSub.publish(SHOW_DETAILS_TOPIC, point);
                                            }}
                                        />
                                    }
                                />
                            ) : (
                                <PlanPoint
                                    onClick={async () => PubSub.publish(SHOW_DETAILS_TOPIC, await this.getPointInfo(p))}
                                    key={p.id}
                                    icon={icon}
                                    colorVariant={this.getColorVariant(severity)}
                                    eventVariant={this.getEventVariant(severity)}
                                    tooltip={tooltip}
                                    isMuted={!!selected && selected?.id !== p?.id}
                                />
                            );
                        })}
                    </Cluster>
                ))}

                {points &&
                    this.filterPoints(points).map((p) => {
                        const { icon, tooltip, type } = this.geViewAttributes(p, generalContext);
                        return showViewAngle &&
                            type?.value &&
                            checkFeatureByDeviceType(type?.value, adapters, VIDEO_STREAM_FEATURE_CODE) ? (
                            <MarkerViewAngle
                                key={p.id}
                                icon={icon}
                                angle={editingAngles[p.id] ?? this.percentToDegrees(zones[p.id])}
                                angleColorVariant={this.getColorVariant(p.severity, true)}
                                colorVariant={this.getColorVariant(p.severity)}
                                eventVariant={this.getEventVariant(p.severity)}
                                tooltip={tooltip}
                                isEditing={isEditing && selected?.id === p.id}
                                onChange={(angle) => {
                                    this.setState({ editingAngles: { ...editingAngles, [p.id]: angle } });
                                }}
                                onCancel={() => {
                                    delete editingAngles[p.id];

                                    this.props.dispatch(saveIsEditing(false));
                                    this.setState({ editingAngles });

                                    PubSub.publish(FREEZE_MAP_TOPIC, false);
                                }}
                                onAccept={() => {
                                    void this.debounceSaveAngle(p, userInfo?.userId);
                                }}
                                position={[
                                    p.properties?.geolocation?.latitude ?? 0,
                                    p.properties?.geolocation?.longitude ?? 0,
                                ]}
                                onClick={async () => {
                                    const point = await this.getPointInfo(p);
                                    if (isEditing) {
                                        PubSub.publish(FREEZE_MAP_TOPIC, true);
                                    }

                                    PubSub.publish(SHOW_DETAILS_TOPIC, point);
                                }}
                                isMuted={!!selected && selected?.id !== p?.id}
                            />
                        ) : (
                            <Marker
                                key={p.id}
                                onClick={async () => PubSub.publish(SHOW_DETAILS_TOPIC, await this.getPointInfo(p))}
                                position={[
                                    p.properties?.geolocation?.latitude ?? 0,
                                    p.properties?.geolocation?.longitude ?? 0,
                                ]}
                                icon={icon}
                                colorVariant={this.getColorVariant(p.severity)}
                                tooltip={tooltip}
                                isMuted={!!selected && selected?.id !== p?.id}
                                eventVariant={this.getEventVariant(p.severity)}
                                onDragEnd={(pos) => onDrag(p, pos)}
                                draggable={!!onDrag && isEditing}
                            />
                        );
                    })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        map: state.map,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(MapPoints));

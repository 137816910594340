import React, { useEffect, useState } from "react";
import {
    DetailsBox,
    DetailsBoxContent,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../components/DetailsBox";
import {
    Button,
    DeviceIcon,
    Dropdown,
    DropdownItem,
    Row,
    Space,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TabPanel,
    Text,
} from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../layout/EventsLayout";
import { connect, useDispatch, useSelector } from "react-redux";
import { withCultureContext } from "../../contexts/cultureContext/CultureContext";
import { withSnackbar } from "notistack";
import {
    fetchSchedule,
    pauseSchedule,
    playSchedule,
    saveScheduleState,
    showEditWizard,
} from "../../app/reducers/scheduleReducer";
import moment from "moment";
import { SearchDevices } from "../../services/devices";
import LocationDeviceInfoTooltip from "../components/DetailsTableTree/LocationDeviceInfoTooltip";
import { GetLocationNames } from "../../services/locations";
import IntervalsTable from "./IntervalsTable";
import { withRouter } from "react-router-dom";
import { permissionExists } from "../../contexts";
import { getFeatureByServiceId, withGeneralContext } from "../../contexts/GeneralContext";
import ModalStepFeatureSettings from "./Modals/ModalStepFeatureSettings";
import { wizardScheduleDefault } from "./constants";
import qs from "qs";

const ScheduleDetails = ({ strings, enqueueSnackbar, history, generalInfo }) => {
    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state.persistedReducer.userInfo);
    const detailsTab = useSelector((state) => state.schedule.detailsTab);
    const selectedSchedule = useSelector((state) => state.schedule.selectedSchedule);
    const scheduleInfo = useSelector((state) => state.schedule.scheduleInfo);

    const [devices, setDevices] = useState([]);

    useEffect(() => {
        dispatch(fetchSchedule({ selectedSchedule, enqueueSnackbar }));
    }, [selectedSchedule, dispatch, enqueueSnackbar]);

    useEffect(() => {
        const fetchDevices = async () => {

            const filter = {
                devicesTypes: scheduleInfo?.filters?.filters?.typeIds ?? [],
                deviceTags: scheduleInfo?.filters?.filters?.tagsIds ?? [],
                deviceGroups:  scheduleInfo?.filters?.filters?.groupIds ?? [],
                ids:  scheduleInfo?.filters?.deviceIds ?? [],
            }

            let devices = []

            if (qs.stringify(filter, { arrayFormat: "repeat" })) {
                const [getDevicesStatus, newDevices] = await SearchDevices(filter);

                if (!getDevicesStatus) {
                    enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                }

                devices = newDevices
            }

            const [locationStatus, locations] = await GetLocationNames(devices.map((d) => d.locationId));
            if (!locationStatus) {
                enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            }

            devices.forEach((device) => {
                device.location = locations.get(device.locationId);
            });

            setDevices(devices);
        };

        fetchDevices();
    }, [scheduleInfo, strings, enqueueSnackbar]);

    const getTypeText = (type) => {
        switch (type) {
            case "personal":
                return strings("Персональный");
            case "group":
                return strings("Групповой");
            case "system":
                return strings("Cистемный");
            default:
                return "";
        }
    };

    const getUserInfo = (scheduleInfo) => {
        const userInfo = scheduleInfo?.user?.properties;
        if (userInfo?.surname && userInfo?.name && userInfo?.patronymic) {
            return userInfo.surname + " " + userInfo.name + " " + userInfo.patronymic;
        } else if (scheduleInfo?.user?.login) {
            return scheduleInfo.user.login;
        } else {
            return scheduleInfo?.userId;
        }
    };

    const StatusButton = () => {
        switch (scheduleInfo?.status) {
            case "Active":
                return (
                    <Button
                        label={strings("Приостановить")}
                        icon="pause"
                        variant="secondary-success"
                        onClick={() =>
                            dispatch(pauseSchedule({ schedule: selectedSchedule, enqueueSnackbar, strings }))
                        }
                    />
                );
            case "Paused":
                return (
                    <Button
                        label={strings("Продолжить")}
                        icon="refresh"
                        variant="secondary-success"
                        onClick={() => dispatch(playSchedule({ schedule: selectedSchedule, enqueueSnackbar, strings }))}
                    />
                );

            default:
                return <></>;
        }
    };

    const toGallery = (schedule) => {
        history.push({
            pathname: "/gallery",
            state: { type: "FromSchedule", schedule },
        });
    };

    const DetailsTabMain = () => (
        <DetailsBoxContent>
            <Table isFixed noPadding>
                <TableBody>
                    <TableRow>
                        <TableCell>{strings("Дата создания")}</TableCell>
                        <TableCell>
                            <Row verticalAlign="middle">
                                <Text color="primary" variant="body-sm">
                                    {scheduleInfo?.createDate
                                        ? moment(scheduleInfo?.createDate).format("DD-MM-YYYY")
                                        : "..."}
                                </Text>
                            </Row>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{strings("Автор")}</TableCell>
                        <TableCell>
                            <Row verticalAlign="middle">
                                <Text color="primary" variant="body-sm">
                                    {getUserInfo(scheduleInfo) ?? "..."}
                                </Text>
                            </Row>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{strings("Тип")}</TableCell>
                        <TableCell>
                            <Row verticalAlign="middle">
                                <Text color="primary" variant="body-sm">
                                    {getTypeText(scheduleInfo?.type) ?? "..."}
                                </Text>
                            </Row>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{strings("Комментарий")}</TableCell>
                        <TableCell>
                            <Row verticalAlign="middle">
                                <Text scroll color="primary" variant="body-sm">
                                    {scheduleInfo?.comment ?? "..."}
                                </Text>
                            </Row>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Row marginTop={24} marginBottom={24}>
                <Text color="primary" variant="body">
                    {strings("Интервалы выполнения")}
                </Text>
            </Row>
            <IntervalsTable />
        </DetailsBoxContent>
    );

    const DetailsTabDevices = () => (
        <DetailsBoxContent>
            <Row marginTop={24} marginBottom={16}>
                <Text color="primary" variant="body">
                    {`${strings("Количество устройств")} ${devices?.length ?? "..."}`}
                </Text>
            </Row>

            <Table isFixed bgColor="dark">
                <TableBody>
                    {devices.map((device) => (
                        <TableRow key={device.id}>
                            <TableCell verticalAlign="middle">
                                <LocationDeviceInfoTooltip device={device}>
                                    <Text color={device.statusCode} variant="body-sm">
                                        <DeviceIcon icon="camera" size={16} />
                                    </Text>
                                    <Space isDouble={true} />
                                    <Text color="primary" variant="body-sm">
                                        {device?.name ?? "..."}
                                    </Text>
                                </LocationDeviceInfoTooltip>
                            </TableCell>
                            <TableCell verticalAlign="middle" indent={30}>
                                <Text variant="body-sm">{device?.location ?? "..."}</Text>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </DetailsBoxContent>
    );

    const getScheduleSteps = (featureSettings = []) => {
        const featureSteps = featureSettings.map((f) => ({
            name: f.name,
            isFeatureStep: true,
            featureCode: f.code,
            component: ModalStepFeatureSettings,
        }));
        return wizardScheduleDefault.concat(featureSteps);
    };

    return (
        <EventsLayoutSecondary>
            <DetailsBox>
                <DetailsBoxHeader sticky>
                    <DetailsBoxTitle>
                        <Text color="primary" variant="title" component="h2">
                            {scheduleInfo?.name ?? "..."}
                        </Text>
                    </DetailsBoxTitle>

                    <DetailsBoxHeaderActions>
                        {permissionExists(userInfo, ["schedule.lifecycle"]) && StatusButton()}
                        <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                            <DropdownItem
                                as="button"
                                onClick={() => toGallery({ ...selectedSchedule, ...scheduleInfo })}
                            >
                                {strings("Фотогалерея")}
                            </DropdownItem>
                            <DropdownItem
                                as="button"
                                onClick={() => {
                                    const feature = getFeatureByServiceId(generalInfo, scheduleInfo.serviceId);
                                    const steps = getScheduleSteps(feature.commands.settings);

                                    dispatch(showEditWizard({ feature, steps }));
                                }}
                            >
                                {strings("Редактировать")}
                            </DropdownItem>
                            <DropdownItem
                                as="button"
                                colorVariant="danger"
                                onClick={() => dispatch(saveScheduleState({ showDeleteScheduleModal: true }))}
                            >
                                {strings("Удалить")}
                            </DropdownItem>
                        </Dropdown>

                        <Button
                            icon="close"
                            variant="secondary"
                            onClick={() => dispatch(saveScheduleState({ selectedSchedule: undefined }))}
                        />
                    </DetailsBoxHeaderActions>
                </DetailsBoxHeader>

                <DetailsBoxTabs>
                    <TabPanel>
                        <Tab
                            label={strings("Общее")}
                            isActive={detailsTab === "main"}
                            onClick={() => dispatch(saveScheduleState({ detailsTab: "main" }))}
                        />
                        <Tab
                            label={strings("Оборудование")}
                            isActive={detailsTab === "devices"}
                            onClick={() => dispatch(saveScheduleState({ detailsTab: "devices" }))}
                        />
                    </TabPanel>
                </DetailsBoxTabs>

                {detailsTab === "main" && DetailsTabMain()}

                {detailsTab === "devices" && DetailsTabDevices()}
            </DetailsBox>
        </EventsLayoutSecondary>
    );
};

const mapStateToProps = (state) => {
    return {
        schedule: state.schedule,
    };
};

export default connect(mapStateToProps)(
    withRouter(withCultureContext(withSnackbar(withGeneralContext(ScheduleDetails))))
);

import { DeviceIcon, Table, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { nanoid } from "nanoid";
import React from "react";
import { getDeviceTypes } from "../../../contexts";
import { withGeneralContext } from "../../../contexts/GeneralContext";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import DeviceTooltip from "../DeviceTooltip/DeviceTooltip";
import { WideEventsLayoutTableWrapper } from "../../../layout/EventsLayout";

function DeviceLocationList({ devices, generalInfo }) {
    const deviceTypes = getDeviceTypes(generalInfo);

    return (
        <WideEventsLayoutTableWrapper>
            <Table key={nanoid()} hasHover stickyHeader isFixed bgColor="dark">
                <TableBody>
                    {devices.map((device) => (
                        <TableRow key={device?.id}>
                            <TableCell>
                                <DeviceTooltip device={device}>
                                    <Text color={device?.statusCode} variant="body-sm">
                                        <DeviceIcon
                                            icon={
                                                deviceTypes.find((dt) => dt.value === device?.typeId)?.icon?.type ??
                                                "shield"
                                            }
                                            size={16}
                                        />
                                    </Text>
                                    {"\u00A0\u00A0"}
                                    <Text color="primary" variant="body-sm">
                                        {device?.name}
                                    </Text>
                                </DeviceTooltip>
                            </TableCell>
                            <TableCell>
                                <Text color={device?.statusCode} variant="body-sm">
                                    {device?.location?.name}
                                </Text>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </WideEventsLayoutTableWrapper>
    );
}

export default withCultureContext(withGeneralContext(DeviceLocationList));

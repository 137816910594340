import React, { useEffect, useState } from "react";
import { CultureContextConsumer, withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import {
    DetailsBox,
    DetailsBoxContent,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../../../../components/DetailsBox";
import {
    Button,
    DeviceIcon,
    Dropdown,
    DropdownItem,
    ExpandableSearch,
    Pagination,
    Row,
    Space,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TabPanel,
    Text,
} from "headpoint-react-components";
import {
    EventsLayoutHeaderTitle,
    EventsLayoutPaginationWrapper,
    EventsLayoutSecondary,
} from "../../../../../layout/EventsLayout";
import { useDispatch, useSelector } from "react-redux";
import { saveControlZonesState, showEditZoneWizard } from "../../../../../app/reducers/controlZonesReducer";
import { formatGroupDeviceCount } from "../../../../../utilites/TextUtils";
import { SearchDevices } from "../../../../../services/devices";
import { withSnackbar } from "notistack";
import { GetDeviceIds } from "../../../../../services/controlZones";
import { GetLocationNames } from "../../../../../services/locations";
import LocationDeviceInfoTooltip from "../../../../components/DetailsTableTree/LocationDeviceInfoTooltip";
import { throttle } from "throttle-debounce";
import { permissionExists } from "../../../../../contexts";

const devicesOnPage = 10;

const ControlZonesDetails = ({ selectedZone, enqueueSnackbar, strings }) => {
    const dispatch = useDispatch();
    const [detailsTab, setDetailsTab] = useState("main");
    const [search, setSearch] = useState("");
    const [searchList, setSearchList] = useState([]);
    const [devices, setDevices] = useState([]);
    const [deviceIds, setDeviceIds] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const [offset, setOffset] = useState(1);
    const [countOnPage, setCountOnPage] = useState(devicesOnPage);

    const { userInfo } = useSelector((state) => state.persistedReducer);

    const handlePage = (page) => {
        if (page <= pagesCount) {
            setPageNumber(page);
        }
    };

    const handleShowMore = () => {
        setCountOnPage(countOnPage + devicesOnPage);
        setPageNumber(1);
    };

    useEffect(() => {
        setDetailsTab("main");
        setPageNumber(1);
    }, [selectedZone]);

    useEffect(() => {
        const getDevices = async () => {
            const [getDeviceIdsStatus, data, deviceIdsCount] = await GetDeviceIds(selectedZone.id);
            if (!getDeviceIdsStatus) {
                enqueueSnackbar(strings("Ошибка получения устройств зоны контроля"), { variant: "error" });
                return;
            }

            if (data.deviceIds.length > 0) {
                const [devicesStatus, devices] = await SearchDevices({
                    limit: deviceIdsCount,
                    ids: data.deviceIds.slice(
                        (pageNumber - 1) * countOnPage,
                        Math.min((pageNumber - 1) * countOnPage + countOnPage, data.deviceIds.length)
                    ),
                });
                if (!devicesStatus) {
                    enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                    return;
                }

                const [locationStatus, locations] = await GetLocationNames(devices.map((d) => d.locationId));
                if (!locationStatus) {
                    enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
                }

                devices.forEach((device) => {
                    device.location = locations.get(device.locationId);
                });

                setDeviceIds(data.deviceIds);
                setDevices(devices);
            }

            const pagesCount = Math.ceil(deviceIdsCount / countOnPage);
            const offset = Math.min(pageNumber > 3 ? pageNumber - 3 : 0, deviceIdsCount);
            setPagesCount(pagesCount);
            setOffset(offset);
        };

        const searchDevice = async (like) => {
            let filter = { like, limit: countOnPage, ids: deviceIds };

            const [devicesStatus, devices] = await SearchDevices(filter);
            if (!devicesStatus) {
                enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            const [locationStatus, locations] = await GetLocationNames(devices.map((d) => d.locationId));
            if (!locationStatus) {
                enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            }

            devices.forEach((device) => {
                device.location = locations.get(device.locationId);
            });

            setSearchList(devices);
            const offset = Math.min(pageNumber > 3 ? pageNumber - 3 : 0, countOnPage - 5);
            setPagesCount(1);
            setOffset(offset);
        };

        const throttleSearchDevice = throttle(700, (...args) => void searchDevice(...args), {
            noLeading: false,
            noTrailing: false,
        });

        if (search?.length > 0) {
            throttleSearchDevice(search);
        } else {
            getDevices();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        strings,
        enqueueSnackbar,
        selectedZone.id,
        pageNumber,
        countOnPage,
        search,
        selectedZone.devicesCount,
    ]);

    const searchNodes = async (like) => {
        setSearch(like);
        setPageNumber(1);
    };

    const DetailsTabMain = ({ selectedZone }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Table isFixed noPadding>
                        <TableBody>
                            <TableRow>
                                <TableCell>{strings("Комментарий")}</TableCell>
                                <TableCell>
                                    <Text scroll color="primary" variant="body-sm">
                                        {selectedZone.comment}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    const DetailsTabDevices = (selectedZone) => {
        const sources = search ? searchList : devices;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Row marginTop={24} marginBottom={24} justify="between" align="middle">
                            <EventsLayoutHeaderTitle>
                                <Text color="primary" variant="body">
                                    {`${strings("В группе")} ${selectedZone.devicesCount} ${strings(
                                        formatGroupDeviceCount(selectedZone.devicesCount)
                                    )}`}
                                </Text>
                                <ExpandableSearch
                                    value={search}
                                    onChange={(e) => searchNodes(e.target.value)}
                                    onSubmit={() => {}}
                                    placeholder={strings("Найти")}
                                    colorVariant="light"
                                    inHeader
                                />
                            </EventsLayoutHeaderTitle>
                        </Row>
                        <Table hasHover stickyHeader isFixed bgColor="dark">
                            <TableBody>
                                {sources?.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <LocationDeviceInfoTooltip device={row}>
                                                <Text variant="body-sm">
                                                    <DeviceIcon icon={"camera"} size={16} />
                                                </Text>
                                                <Space isDouble={true} />
                                                <Text color="primary" variant="body-sm">
                                                    {row.name ?? "..."}
                                                </Text>
                                            </LocationDeviceInfoTooltip>
                                        </TableCell>
                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {row.location ?? "..."}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <EventsLayoutPaginationWrapper>
                            <Button variant="secondary" label={strings("Показать ещё")} onClick={handleShowMore} />
                            <Pagination
                                offset={offset}
                                page={pageNumber}
                                count={pagesCount}
                                onChange={(e) => handlePage(e)}
                            />
                        </EventsLayoutPaginationWrapper>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    };

    return (
        <EventsLayoutSecondary>
            <DetailsBox>
                <DetailsBoxHeader sticky>
                    <DetailsBoxTitle>
                        <Text color="primary" variant="title" component="h2">
                            {selectedZone.name}
                        </Text>
                    </DetailsBoxTitle>

                    <DetailsBoxHeaderActions>
                        {permissionExists(userInfo, ["controlZoneGroups.lifeCycle"]) ? (
                            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                <DropdownItem
                                    as="button"
                                    variant="secondary"
                                    onClick={() =>
                                        dispatch(showEditZoneWizard({ ...selectedZone, deviceIds: deviceIds }))
                                    }
                                >
                                    {strings("Редактировать")}
                                </DropdownItem>

                                <DropdownItem
                                    as="button"
                                    colorVariant="danger"
                                    onClick={() =>
                                        dispatch(saveControlZonesState({ showDeleteWizard: true, wizardType: "zone" }))
                                    }
                                >
                                    {strings("Удалить")}
                                </DropdownItem>
                            </Dropdown>
                        ) : null}

                        <Button
                            icon="close"
                            variant="secondary"
                            onClick={() => dispatch(saveControlZonesState({ selectedZone: null }))}
                        />
                    </DetailsBoxHeaderActions>
                </DetailsBoxHeader>

                <DetailsBoxTabs>
                    <TabPanel>
                        <Tab
                            label={strings("Общее")}
                            isActive={detailsTab === "main"}
                            onClick={() => setDetailsTab("main")}
                        />
                        {selectedZone?.devicesCount ? (
                            <Tab
                                label={strings("Оборудование")}
                                isActive={detailsTab === "devices"}
                                onClick={() => setDetailsTab("devices")}
                            />
                        ) : null}
                    </TabPanel>
                </DetailsBoxTabs>
                {detailsTab === "main" && DetailsTabMain({ selectedZone })}
                {detailsTab === "devices" && DetailsTabDevices(selectedZone)}
            </DetailsBox>
        </EventsLayoutSecondary>
    );
};

export default withCultureContext(withSnackbar(ControlZonesDetails));

import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
    currentLayout: "1",
    frames: {},
    syncViewEnabled: false,
    intersection: undefined,
    dates: {
        from: undefined,
        to: undefined,
    },
    showCameraInfo: false,
};

export const archiveSlice = createSlice({
    name: "archive",
    initialState: initialState,
    reducers: {
        saveCurrentLayout: (state, action) => {
            state.currentLayout = action.payload;
        },
        saveSyncViewEnabled: (state, action) => {
            state.syncViewEnabled = action.payload;
        },
        saveIntersection: (state, action) => {
            state.intersection = action.payload;
        },
        saveFrames: (state, action) => {
            state.frames = cloneDeep(action.payload);
        },
        saveShowCameraInfo: (state, action) => {
            state.showCameraInfo = action.payload;
        },
    },
});

export const {
    saveCurrentLayout,
    saveFrames,
    saveSyncViewEnable,
    saveIntersection,
    saveSyncViewEnabled,
    saveShowCameraInfo,
} = archiveSlice.actions;

export default archiveSlice.reducer;

import React from "react";
import { getDataFromSource, getImageStorageUrl } from "../utilites";
import styled from "styled-components";
import { VideoControlBtn, VideoWrapper } from "headpoint-react-components";
import FullImageModal from "./FullImageModal";
import { AspectBox, DetailsBoxContent } from "../../../../components/DetailsBox";

export class ArrayImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullImage: false,
            sources: [],
            imageIndex: 0,
        };
    }

    componentDidMount() {
        const { media, selectedEvent } = this.props;

        const sources =
            getDataFromSource(media.source, selectedEvent)?.filter((s) => s?.type.startsWith("image")) ?? [];

        this.setState({ sources });
    }

    handleNextImage = () => {
        const { imageIndex, sources } = this.state;

        const newIndex = imageIndex + 1;
        if (newIndex >= sources.length) {
            return;
        }

        this.setState({ imageIndex: newIndex });
    };

    handlePreviousImage = () => {
        const { imageIndex } = this.state;

        const newIndex = imageIndex - 1;
        if (newIndex < 0) {
            return;
        }

        this.setState({ imageIndex: newIndex });
    };

    render() {
        const { media } = this.props;
        const { sources, imageIndex } = this.state;

        if (sources.length <= 0) {
            return null;
        }

        const imageUrl = getImageStorageUrl(sources[imageIndex].id, media.storage);

        return (
            <>
                <VideoWrapper
                    videoElement={
                        <>
                            {this.state.isFullImage ? (
                                <FullImageModal
                                    imageUrl={imageUrl}
                                    handleClose={() => this.setState({ isFullImage: false })}
                                />
                            ) : null}
                            <DetailsBoxContent style={{ textAlign: "center" }}>
                                <AspectBox aspect="16:9">
                                    <ImageLink
                                        src={imageUrl}
                                        alt={imageUrl}
                                        onClick={() => this.setState({ isFullImage: true })}
                                    />
                                </AspectBox>
                            </DetailsBoxContent>
                        </>
                    }
                    toolbox={
                        <>
                            <VideoControlBtn icon="arrow-left" onClick={this.handlePreviousImage} title="Prev" />
                            <VideoControlBtn icon="arrow-right" title="Next" onClick={this.handleNextImage} />
                        </>
                    }
                    alwaysShowControls
                />
            </>
        );
    }
}

const ImageLink = styled.img`
    cursor: pointer;
`;

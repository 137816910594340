import React from "react";
import { GetLocations, FillLocations, FillNode } from "../../../../../services/locations";
import { FilterAccordion } from "../../../../components/Filters";
import { ModalSection, Row, Text, TextField } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { GetDevices, SearchDevices } from "../../../../../services/devices";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { SelectedCounter } from "../../../../components/SelectedCounter/SelectedCounter";

const DEVICES_LIMIT = 100;

class ModalStepDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            search: "",
        };
    }
    async componentDidMount() {
        const { strings } = this.props;

        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        const tree = FillLocations(locations, []);
        this.setState({
            devicesLocationsTree: tree ?? [],
            search: "",
        });
    }

    openLocation = async (locationNode) => {
        const { devicesLocationsTree } = this.state;
        const { strings } = this.props;

        const [getDevicesStatus, newDevices] = await GetDevices(locationNode.id);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const filteredDevicesByIp = newDevices.filter((d) => {
            if ("video.available" in d.properties.settings.features)
                return d.properties.settings.features["video.available"].deviceIp;
            return [];
        });

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, filteredDevicesByIp);
        }

        this.setState({ devicesLocationsTree });
    };

    searchNodes = async (like) => {
        const { strings } = this.props;

        const [devicesStatus, devices] = await SearchDevices({ like, limit: DEVICES_LIMIT });
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }
        this.setState({ search: like, searchList: devices?.map((d) => ({ ...d, tag: "device" })) ?? [] });
    };

    render() {
        const { search, devicesLocationsTree, searchList } = this.state;
        const sources = search ? searchList : devicesLocationsTree;
        const { values, strings } = this.props;

        return (
            <>
                <ModalSection>
                    <Row justify="between" align="bottom" marginBottom={24}>
                        <Text variant="body">{strings("Устройства")}</Text>
                        <SelectedCounter
                            count={values.entityIds?.length ?? 0}
                            onClear={() => this.props.SetValuesProperty("entityIds", [])}
                            strings={strings}
                        />
                    </Row>
                    <Row marginBottom={12}>
                        <TextField
                            autoComplete={false}
                            colorVariant="light"
                            fullWidth
                            icon="search"
                            onChange={(e) => this.searchNodes(e.target.value)}
                            placeholder={strings("Найти")}
                            type="search"
                            value={search}
                        />
                    </Row>
                    <FilterAccordion
                        items={sources}
                        selected={values.entityIds ?? []}
                        openItem={(location) => this.openLocation(location)}
                        blockParentCheckbox={true}
                        selectHandler={(items) => {
                            this.props.SetValuesProperty("entityIds", items);
                        }}
                    />
                </ModalSection>
            </>
        );
    }
}

export default withCultureContext(withSnackbar(ModalStepDevices));

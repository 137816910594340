import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { FilterAccordion } from "../../../../../components/Filters";

export class ModalFormTreeProperty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    async componentDidMount() {
        const items = await this.props.loadItems();

        this.setState({
            items: items,
        });
    }

    selectHandler = (_, clickedItem) => {
        let { selected, selectItems } = this.props;
        if (!selected) {
            selected = [];
        }

        selectItems(this.processItem(selected, clickedItem));
    };

    processItem = (selectedItems, item) => {
        if (this.isParent(item)) {
            return this.processParent(item);
        } else {
            return this.processChild(selectedItems, item);
        }
    };

    processParent = (item) => {
        if (this.checkSelected(item) || this.checkIndeterminate(item)) {
            return [];
        } else {
            return this.selectAllChildren(item);
        }
    };

    processChild = (selectedItems, item) => {
        if (this.checkSelected(item) || this.checkIndeterminate(item)) {
            return this.unselectItem(selectedItems, item);
        } else {
            return this.selectItem(selectedItems, item);
        }
    };

    selectAllChildren = (item) => item.children.map((child) => child.id);

    unselectItem = (selectedItems, itemToUnselect) => selectedItems.filter((itemId) => itemId !== itemToUnselect.id);

    selectItem = (selectedItems, itemToSelect) => {
        selectedItems.push(itemToSelect.id);
        return selectedItems;
    };

    isParent = (item) => item?.children;

    checkSelected = (item) => {
        let { selected } = this.props;
        if (this.isParent(item)) {
            return !!item?.children.every((child) => selected?.includes(child.id));
        }

        return selected?.includes(item.id);
    };

    checkIndeterminate = (item) => {
        let { selected } = this.props;
        if (this.isParent(item)) {
            return !!item?.children.some((child) => selected?.includes(child.id)) && !this.checkSelected(item);
        }

        return false;
    };

    render() {
        return (
            <ModalFormItem>
                <FilterAccordion
                    items={this.state.items}
                    checkIndeterminate={this.checkIndeterminate}
                    checkSelected={this.checkSelected}
                    selected={this.state.selected ?? []}
                    selectHandler={this.selectHandler}
                />
            </ModalFormItem>
        );
    }
}

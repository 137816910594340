import React from "react";
import { ModalSection, TextField, Row, Text } from "headpoint-react-components";
import {
    FillLocations,
    GetLocationNames,
    GetLocations,
    FillNode,
    GetLocationList,
} from "../../../../../../services/locations";
import { GetDevices, SearchDevices } from "../../../../../../services/devices";
import { checkNodeName } from "../../../../../../utilites/TreeUtils";
import { nanoid } from "nanoid";
import { permissionExists } from "../../../../../../contexts";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";
import { SelectedCounter } from "../../../../../components/SelectedCounter/SelectedCounter";
import { FilterAccordion } from "../../../../../components/Filters";

const DEVICES_LIMIT = 100;
const LOCATIONS_LIMIT = 100;

class ModalStepDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            devices: [],
            devicesLocationsTree: [],
        };
    }

    async updateLocationNames(locationIds) {
        const { locationNames } = this.state;
        const uniqueIds = [...new Set(locationIds)].filter((id) => id !== null);

        const newIds = locationNames ? uniqueIds.filter((id) => !locationNames.has(id)) : uniqueIds;

        if (newIds.length === 0) {
            return locationNames ? locationNames : new Map();
        }

        const [status, names] = await GetLocationNames(newIds);
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Не удалось получить локации"), { variant: "error" });
        }

        if (locationNames) {
            return new Map([...locationNames, ...names]);
        }

        return names;
    }

    async componentDidMount() {
        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        const tree = FillLocations(locations, []);
        this.setState({
            devicesLocationsTree: tree ?? [],
        });
    }

    searchNodes = async (like) => {
        const { strings } = this.props;

        let [locationsStatus, locations] = await GetLocationList({ like, limit: LOCATIONS_LIMIT });
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [devicesStatus, devices] = await SearchDevices({ like, limit: DEVICES_LIMIT });
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let nodes = locations?.map((l) => ({ ...l, tag: "location" })) ?? [];
        nodes = nodes.concat(devices?.map((d) => ({ ...d, tag: "device" })) ?? []);
        this.setState({ search: like, searchList: nodes });
    };

    findAllNodes = (tree, searchName) => {
        let treeNodes = [];
        tree.forEach((node) => treeNodes.push(...checkNodeName(node, searchName)));
        return treeNodes;
    };

    handleClearDevices = () => this.props.setValues({ ...this.props.values, deviceIds: [] });

    updateTree(tree) {
        tree?.forEach((node) => {
            node.accordionId = nanoid();
            if (node.children) {
                this.updateTree(node.children);
            }
        });
    }

    openLocation = async (locationNode) => {
        const { devicesLocationsTree, devices } = this.state;
        const { strings } = this.props;

        const [getDevicesStatus, newDevices] = await GetDevices(locationNode.id);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, newDevices);
        }

        newDevices.forEach((nd) => {
            if (devices.find((d) => d.id === nd.id)) {
                return;
            }

            devices.push(nd);
        });

        this.setState({
            devicesLocationsTree,
            devices,
        });
    };

    render() {
        const { userInfo, values, setValues, strings } = this.props;
        const { search, devicesLocationsTree, searchList } = this.state;
        const sources = search ? searchList : devicesLocationsTree;

        return (
            <ModalSection>
                {permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
                    <div>
                        <Row justify="between" align="bottom" marginBottom={24}>
                            <Text variant="body">{strings("Устройства")}</Text>
                            <SelectedCounter
                                count={values.deviceIds.length}
                                onClear={this.handleClearDevices}
                                strings={strings}
                            />
                        </Row>
                        <Row marginBottom={12}>
                            <TextField
                                autoComplete={false}
                                colorVariant="light"
                                fullWidth
                                icon="search"
                                onChange={(e) => this.searchNodes(e.target.value)}
                                placeholder={strings("Найти")}
                                type="search"
                                value={this.state.search}
                            />
                        </Row>

                        <FilterAccordion
                            items={sources}
                            selected={values.deviceIds}
                            selectHandler={(e) => setValues({ ...values, deviceIds: e })}
                            openItem={(location) => this.openLocation(location)}
                            blockParentCheckbox={true}
                        />
                    </div>
                ) : (
                    <div />
                )}
            </ModalSection>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps)(withCultureContext(withSnackbar(ModalStepDevices)));

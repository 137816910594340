import React from "react";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class ModalStepDefault extends React.Component {
    render() {
        const { values, SetValuesProperty } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <ModalSection>
                        <ModalFormItem>
                            <TextField
                                label={strings("Название группы")}
                                name="name"
                                type="text"
                                value={values.name}
                                onChange={(e) => SetValuesProperty("name", e.target.value)}
                                colorVariant="light"
                                fullWidth
                                error={!values.name ? strings("Поле не может быть пустым") : null}
                            />
                        </ModalFormItem>
                        <ModalFormItem>
                            <TextField
                                label={strings("Комментарий")}
                                name="comment"
                                type="text"
                                value={values.properties.comment}
                                onChange={(e) =>
                                    SetValuesProperty("properties", { ...values.properties, comment: e.target.value })
                                }
                                colorVariant="light"
                                fullWidth
                                inputProps={{
                                    as: "textarea",
                                    rows: 3,
                                }}
                            />
                        </ModalFormItem>
                    </ModalSection>
                )}
            </CultureContextConsumer>
        );
    }
}

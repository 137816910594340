import React from "react";
import { DeleteModal } from "../../../DeleteModal";
import { withSnackbar } from "notistack";
import PubSub from "pubsub-js";
import { CLOSE_ALL_MODALS, ROLE_DELETED } from "../../rolesSettingsTopics";
import { DeleteRole } from "../../../../../../services/roles";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class DeleteRoleModal extends React.Component {
    removeRole = async () => {
        const { role, strings } = this.props;
        if (!role?.id) {
            return;
        }

        let [status, statusCode, response] = await DeleteRole(role.id);
        if (status) {
            PubSub.publish(ROLE_DELETED, role);
            this.props.enqueueSnackbar(`${strings("Роль")} '${role.name}' ${strings("удалена")}`, {
                variant: "success",
            });
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(`${strings("Не хватает прав для удаления роли")} '${role.name}'`, {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    switch (response.code) {
                        case "conflict-users":
                            this.props.enqueueSnackbar(
                                `${strings("Нельзя удалить")} '${role.name}' ${strings(
                                    "- роль назначена пользователям"
                                )}`,
                                {
                                    variant: "warning",
                                }
                            );
                            break;

                        default:
                            this.props.enqueueSnackbar(strings("Неизвестный конфликт"), { variant: "warning" });
                            break;
                    }
                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось удалить роль")} '${role.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }
    };

    render() {
        const { strings } = this.props;
        return (
            <DeleteModal
                visible={true}
                CloseHandler={() => PubSub.publish(CLOSE_ALL_MODALS)}
                RemoveHandler={() => this.removeRole()}
                text={`${strings("Вы хотите удалить роль")} "${this.props.role?.name}". ${strings(
                    "Удалённую роль нельзя восстановить. Продолжить?"
                )}`}
            />
        );
    }
}

export default withCultureContext(withSnackbar(DeleteRoleModal));

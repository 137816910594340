import React from "react";
import { getImageStorageUrl } from "../../events/components/EventDetails/utilites";
import styled from "styled-components";
import { Text } from "headpoint-react-components";
import { findNearestLeftNumber } from "../../../utilites/ArrayUtils";

export class ScreenshotPlayer extends React.Component {
    handleScreenshotClick = (offset) => {
        const { handleOffset } = this.props;

        if (offset !== undefined && offset !== null) {
            handleOffset(offset);
        }
    };

    render() {
        let { frames, frameIndex, screenshotsInfo, offsetTime, strings } = this.props;

        const currentFrame = frames[frameIndex];
        const screenshotsFrame = screenshotsInfo.filter((info) => info.deviceId === currentFrame.id);

        const offsets = screenshotsFrame.map((sf) => sf.offset);
        const nearestValue = findNearestLeftNumber(offsetTime, offsets);

        const screenshot = screenshotsFrame.find((sf) => sf.offset === nearestValue);

        let imageUrl = undefined;
        let opacity = "100%";

        if (screenshot) {
            imageUrl = getImageStorageUrl(screenshot.properties.screenshotFileId, "inone");
            if (nearestValue !== offsetTime) {
                opacity = "30%";
            }
        }

        return (
            <>
                {imageUrl !== undefined ? (
                    <ImageLink
                        onClick={() => this.handleScreenshotClick(screenshot?.offset)}
                        style={{
                            position: "absolute",
                            opacity: opacity,
                            maxWidth: "100%",
                            maxHeight: "100%",
                            width: "auto",
                            height: "auto",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                        src={imageUrl}
                        alt={imageUrl}
                    />
                ) : (
                    <StyledTabLoader>
                        <Text>{strings("Отсутствует фотоизображение")}</Text>
                    </StyledTabLoader>
                )}
            </>
        );
    }
}

const ImageLink = styled.img`
    cursor: pointer;
`;

const StyledTabLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    . {
        vertical-align: middle;
    }
`;

import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Table,
    TableHead,
    TableRow,
    TableHeadCell,
    TableCell,
    Text,
    Tooltip,
    Button,
    Pagination,
} from "headpoint-react-components";

import { withSnackbar } from "notistack";
import styled from "styled-components";
import { CancelConverterOrder, CountConverterOrders, GetConverterOrders } from "../../../services/converter";
import moment from "moment/moment";
import { permissionExists } from "../../../contexts";
import { CheckArchiveFile, GetArchiveFile, GetFileMetadata } from "../../../services/storage";
import { connect } from "react-redux";

const ORDERS_ON_PAGE = 10;

class converterConverterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countOnPage: ORDERS_ON_PAGE,
            converterOrders: [],
            count: 0,
            currentPage: 1,
            offset: 0,
        };
    }

    async componentDidMount() {
        const fileId = this.props.archive.id;
        const [count, converterOrders] = await this.getOrdersAndCount(fileId);
        this.setState({ converterOrders, count });
    }

    async getOrdersAndCount(fileId, rowNumber, offset, limit) {
        const { strings } = this.props;
        const [countStatus, count] = await CountConverterOrders(fileId);
        const [orderStatus, converterOrders] = await GetConverterOrders(
            fileId,
            rowNumber ?? 0,
            limit ?? ORDERS_ON_PAGE,
            offset ?? 0
        );

        if (!countStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить количество заказов"), { variant: "error" });
        }

        if (!orderStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить очередь заказов"), { variant: "error" });
        }

        return [count, converterOrders];
    }

    handleShowMore = async () => {
        let { countOnPage } = this.state;
        const fileId = this.props.archive.id;

        const [count, converterOrders] = await this.getOrdersAndCount(fileId, 0, 0, countOnPage + ORDERS_ON_PAGE);

        this.setState({
            countOnPage: countOnPage + ORDERS_ON_PAGE,
            converterOrders: converterOrders,
            count,
            currentPage: 1,
        });
    };

    handlePage = async (page) => {
        const { currentPage, converterOrders, countOnPage } = this.state;
        const fileId = this.props.archive.id;

        if (currentPage === page) {
            return;
        }
        const offset = page - currentPage;
        const rowNumber =
            offset > 0 ? converterOrders[converterOrders.length - 1]?.orderNumber : converterOrders[0]?.orderNumber;
        const [count, orders] = await this.getOrdersAndCount(fileId, rowNumber, offset, countOnPage);

        this.setState({ currentPage: page, rowNumber, converterOrders: orders, count });
    };

    getConverterOrderStatusAndColor(orderStatus) {
        const { strings } = this.props;
        let statusColor;
        let status = orderStatus ?? "Unknown";

        switch (status.toLowerCase()) {
            case "error":
                status = strings("Ошибка");
                statusColor = "danger";
                break;
            case "inprogress":
                status = strings("Подготовка");
                statusColor = "warning";
                break;
            case "done":
                status = strings("Готово");
                statusColor = "success";
                break;
            case "cancelled":
                status = strings("Отменен");
                statusColor = "secondary";
                break;
            default:
                statusColor = "secondary";
                break;
        }
        return [status, statusColor];
    }

    async handleDownload(converterOrder, cameraName) {
        const { strings } = this.props;

        const fileName = this.buildFileName(cameraName, this.props.archive);
        const convertedFileId = converterOrder?.properties?.convertedFileId;
        if (!convertedFileId) {
            this.props.enqueueSnackbar(
                `${strings("Не удалось получить convertedFileId для загрузки файла")} '${fileName}'`,
                {
                    variant: "error",
                }
            );
            return;
        }

        const [fileMetadataStatus, fileMetadata] = await GetFileMetadata(convertedFileId);
        if (!fileMetadataStatus) {
            this.props.enqueueSnackbar(`${strings("Не удалось получить metadata файла")} '${fileName}'`, {
                variant: "error",
            });
            return;
        }

        let fileExists;
        if (fileMetadata?.fileUrl) {
            fileExists = await CheckArchiveFile(fileMetadata.fileUrl);
        }

        if (!fileExists) {
            this.props.enqueueSnackbar(`${strings("Файл")} '${fileName}' ${strings("не найден")}`, {
                variant: "error",
            });
            return;
        }

        let fullFileName = this.buildFullFileName(cameraName, this.props.archive, fileMetadata);
        await GetArchiveFile(fileMetadata.fileUrl, fullFileName);
        this.props.enqueueSnackbar(`${strings("Загрузка файла")} '${fileName}' ${strings("запущена")}`, {
            variant: "success",
        });
    }

    buildFullFileName(cameraName, archiveOrder, fileMetadata) {
        const fileName = this.buildFileName(cameraName, archiveOrder);
        const extension = this.getFileExtension(fileMetadata);
        return fileName + extension;
    }

    buildFileName(cameraName, archiveOrder) {
        return `${cameraName}_${archiveOrder.from.replace(" ", "")} - ${archiveOrder.to.replace(" ", "")}`;
    }

    getFileExtension(fileMetadata) {
        const pointIndex = fileMetadata?.fileName?.lastIndexOf(".") ?? -1;
        if (pointIndex >= 0 && pointIndex < fileMetadata?.fileName?.length - 1) {
            return fileMetadata.fileName.substring(pointIndex);
        }

        return ".unk";
    }

    async handleCancel(orderId) {
        const { strings } = this.props;
        const [status] = await CancelConverterOrder(orderId);

        if (status) {
            this.props.enqueueSnackbar(`${strings("Заказ")} '${orderId}' ${strings("отменяется")}`, {
                variant: "success",
            });
        } else {
            this.props.enqueueSnackbar(`${strings("Произошла ошибка при отмене заказа")} '${orderId}'`, {
                variant: "error",
            });
        }
    }

    handleRefresh = async () => {
        const fileId = this.props.archive.id;
        const [count, converterOrders] = await this.getOrdersAndCount(fileId);

        this.setState({ converterOrders, count, currentPage: 1, countOnPage: ORDERS_ON_PAGE });
    };

    render() {
        const { strings } = this.props;
        let { converterOrders, currentPage, count, countOnPage } = this.state;
        const { userInfo } = this.props;

        const countPages = Math.ceil(count / countOnPage) ?? 0;
        let pagesOffset = Math.min(currentPage > 3 ? currentPage - 3 : 0, currentPage - 5);
        pagesOffset < 0 && (pagesOffset = 0);

        return (
            <Modal size="sm" closeHandler={this.props.handleClose}>
                <ModalHeader
                    title={strings("Конвертация")}
                    closeHandler={this.props.handleClose}
                    actions={
                        <>
                            <ModalHeaderActionItem>
                                <Button variant="secondary" icon="refresh" onClick={this.handleRefresh} />
                            </ModalHeaderActionItem>
                        </>
                    }
                />

                <ModalBody>
                    <ModalTableWrapper>
                        <Table hasHover stickyHeader bgColor="light">
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>№</TableHeadCell>
                                    <TableHeadCell>{strings("Дата")}</TableHeadCell>
                                    <TableHeadCell>{strings("Формат")}</TableHeadCell>
                                    <TableHeadCell align="right">{strings("Статус")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>
                            {converterOrders?.map((row) => {
                                const isComplete = row.status.toLowerCase() === "done";
                                const inProgress = row.status.toLowerCase() === "inprogress";
                                const isCanceled = row.status.toLowerCase() === "canceled";
                                const [status, statusColor] = this.getConverterOrderStatusAndColor(row?.status);
                                const orderDate = moment(row?.orderDate).local().format("YYYY-MM-DD");
                                const orderTime = moment(row?.orderDate).local().format("HH:mm:ss");

                                return (
                                    <TableRow key={row.orderNumber}>
                                        <TableCell>{row.orderNumber}</TableCell>

                                        <TableCell>
                                            <Text color={isComplete ? "primary" : "secondary"} variant="body-sm">
                                                {orderDate}
                                            </Text>
                                            <br />
                                            <Text color="secondary" variant="description">
                                                {orderTime}
                                            </Text>
                                        </TableCell>

                                        <TableCell>
                                            <Text color={isComplete ? "primary" : "secondary"} variant="body-sm">
                                                {row?.format}
                                            </Text>
                                        </TableCell>

                                        <TableCell align="center" verticalAlign="middle">
                                            {row.status.toLowerCase() === "error" ? (
                                                row.status && (
                                                    <Tooltip tooltip={strings(row.properties?.errorMessage) ?? ""}>
                                                        <Text color={statusColor} variant="body-sm">
                                                            {status}
                                                        </Text>
                                                    </Tooltip>
                                                )
                                            ) : (
                                                <Text color={statusColor} variant="body-sm">
                                                    {status}
                                                </Text>
                                            )}
                                        </TableCell>
                                        <TableCell align="right" verticalAlign="middle">
                                            {inProgress && (
                                                <Button
                                                    variant="ghost"
                                                    label={strings("Отменить")}
                                                    onClick={() => this.handleCancel(row.id)}
                                                />
                                            )}
                                            {permissionExists(userInfo, ["video.archive.access"]) &&
                                                isComplete &&
                                                row.properties?.convertedFileId && (
                                                    <Button
                                                        variant="secondary"
                                                        label={strings("Скачать")}
                                                        icon="download"
                                                        onClick={() => this.handleDownload(row, this.props.cameraName)}
                                                    />
                                                )}
                                            {isCanceled && (
                                                <Button variant="ghost" label={strings("Отменен")} disabled />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                    </ModalTableWrapper>
                </ModalBody>

                <ConverterOrderListModalFooter>
                    <Button variant="secondary" label={strings("Показать ещё")} onClick={this.handleShowMore} />

                    <Pagination
                        offset={pagesOffset}
                        page={currentPage}
                        count={countPages}
                        onChange={(page) => this.handlePage(page)}
                    />
                </ConverterOrderListModalFooter>
            </Modal>
        );
    }
}

const ModalTableWrapper = styled.div`
    width: 100%;
    padding: 16px 24px;
`;

const ConverterOrderListModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 32px 24px;
`;

const ModalHeaderActionItem = styled.div`
    margin-right: 8px;
`;

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(converterConverterModal));

import React from "react";
import ArchivePlayerWrapper from "./ArchivePlayerWrapper";
import { CameraLayoutWrapper, CameraLayoutRow } from "../../components/CameraLayout";
import { GeneralContextConsumer } from "../../../contexts";

export class CameraFrames extends React.Component {
    handleCameraFrameClear = (frameId) => {
        this.props.cameraFrameClear(frameId);
    };

    render() {
        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <CameraLayoutWrapper hasFullscreenToggle={Object.keys(this.props.frames).length > 1}>
                            {this.props.currentLayout === "1" && (
                                <CameraLayoutRow>
                                    <ArchivePlayerWrapper
                                        dates={this.props.dates}
                                        key={0}
                                        showCameraInfo={this.props.showCameraInfo}
                                        frameIndex={0}
                                        frames={this.props.frames}
                                        selectedCameraFrame={this.props.selectedCameraFrame}
                                        handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                        handleCameraFrameClear={this.handleCameraFrameClear}
                                        generalInfo={generalInfo}
                                    />
                                </CameraLayoutRow>
                            )}

                            {this.props.currentLayout === "2" && (
                                <CameraLayoutRow>
                                    {[0, 1].map((i) => (
                                        <ArchivePlayerWrapper
                                            dates={this.props.dates}
                                            key={i}
                                            showCameraInfo={this.props.showCameraInfo}
                                            frameIndex={i}
                                            frames={this.props.frames}
                                            selectedCameraFrame={this.props.selectedCameraFrame}
                                            handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                            handleCameraFrameClear={this.handleCameraFrameClear}
                                            generalInfo={generalInfo}
                                        />
                                    ))}
                                </CameraLayoutRow>
                            )}

                            {this.props.currentLayout === "4" && (
                                <React.Fragment>
                                    <CameraLayoutRow rowHeight="50%">
                                        {[0, 1].map((i) => (
                                            <ArchivePlayerWrapper
                                                dates={this.props.dates}
                                                key={i}
                                                showCameraInfo={this.props.showCameraInfo}
                                                frameIndex={i}
                                                frames={this.props.frames}
                                                selectedCameraFrame={this.props.selectedCameraFrame}
                                                handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                                handleCameraFrameClear={this.handleCameraFrameClear}
                                                generalInfo={generalInfo}
                                            />
                                        ))}
                                    </CameraLayoutRow>

                                    <CameraLayoutRow rowHeight="50%">
                                        {[2, 3].map((i) => (
                                            <ArchivePlayerWrapper
                                                dates={this.props.dates}
                                                key={i}
                                                showCameraInfo={this.props.showCameraInfo}
                                                frameIndex={i}
                                                frames={this.props.frames}
                                                selectedCameraFrame={this.props.selectedCameraFrame}
                                                handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                                handleCameraFrameClear={this.handleCameraFrameClear}
                                                generalInfo={generalInfo}
                                            />
                                        ))}
                                    </CameraLayoutRow>
                                </React.Fragment>
                            )}
                        </CameraLayoutWrapper>
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

import React from "react";
import { wizardSteps } from "../../constants";
import { GeneralContextConsumer } from "../../../../../../contexts";
import { CreateUpdateWizard } from "../../../../../components/CreateUpdateWizard/CreateUpdateWizard";
import PubSub from "pubsub-js";
import { CLOSE_ALL_MODALS, ROLE_CREATED, ROLE_UPDATED } from "../../rolesSettingsTopics";
import { CreateRole, UpdateRole } from "../../../../../../services/roles";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

const initialRoleValues = {
    name: "",
    description: "",
    groupId: undefined,
    permissions: [],
    priority: undefined,
};

class CreateUpdateRoleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: props.mode === WIZARD_CREATE_MODE ? 1 : wizardSteps.length,
            activeStep: 1,
            values:
                props.mode === WIZARD_CREATE_MODE || !props?.role ? initialRoleValues : this.valuesFromRole(props.role),
        };
    }

    valuesFromRole = (role) => {
        return {
            id: role.id,
            name: role.name,
            groupId: role.groupId,
            description: role.properties?.description,
            permissions: role.properties?.permissions ?? [],
            devicesGroups: role.properties?.devicesGroups ?? [],
            priority: role.properties?.priority,
        };
    };

    roleFromValues = (values) => {
        return {
            id: values.id,
            name: values.name,
            groupId: values.groupId,
            properties: {
                priority: values.priority,
                description: values.description,
                permissions: values.permissions,
                devicesGroups: values.devicesGroups,
            },
        };
    };

    validateRoleValues = (values) => {
        const { strings } = this.props;

        if (!values.name) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить роль без имени"), { variant: "error" });
            return false;
        }

        if (!values.groupId) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить роль без группы"), { variant: "error" });
            return false;
        }

        if (values.priority === undefined) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить роль без приоритета"), { variant: "error" });
            return false;
        }

        if (!values?.devicesGroups?.length) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить роль без групп оборудования"), { variant: "error" });
            return false;
        }

        return true;
    };

    createHandler = async (values) => {
        const { strings } = this.props;

        if (!this.validateRoleValues(values)) {
            return;
        }

        let role = this.roleFromValues(values);
        let [status, statusCode, response] = await CreateRole(role);
        if (status) {
            this.props.enqueueSnackbar(`${strings("Роль")} '${role.name}' ${strings("создана")}`, {
                variant: "success",
            });
            PubSub.publish(ROLE_CREATED, { ...role, id: response.id });
            PubSub.publish(CLOSE_ALL_MODALS);
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(`${strings("Не хватает прав для создания роли")} '${role.name}'`, {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    this.props.enqueueSnackbar(
                        `${strings("Роль с именем")} '${role.name}' ${strings("уже существует")}`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось создать роль")} '${role.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }
    };

    editHandler = async (values) => {
        const { strings } = this.props;

        if (!this.validateRoleValues(values)) {
            return;
        }

        let role = this.roleFromValues(values);
        let [status, statusCode] = await UpdateRole(role);
        if (status) {
            this.props.enqueueSnackbar(`${strings("Роль")} '${role.name}' ${strings("успешно изменена")}`, {
                variant: "success",
            });
            PubSub.publish(ROLE_UPDATED, role);
            PubSub.publish(CLOSE_ALL_MODALS);
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(`${strings("Не хватает прав для изменеения роли")} '${role.name}'`, {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    this.props.enqueueSnackbar(
                        `${strings("Роль с именем")} '${role.name}' ${strings("уже существует")}`,
                        { variant: "warning" }
                    );
                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось сохранить роль")} '${role.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    render() {
        const { mode, strings } = this.props;
        const { step, activeStep, values } = this.state;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => {
                    return (
                        <CreateUpdateWizard
                            visible={true}
                            SaveHandler={this.saveHandler}
                            CloseHandler={() => PubSub.publish(CLOSE_ALL_MODALS)}
                            values={values}
                            title={mode === WIZARD_CREATE_MODE ? strings("Создать роль") : strings("Обновить роль")}
                            step={step}
                            activeStep={activeStep}
                            NextStep={this.nextStep}
                            SetActiveStep={this.selectStep}
                            CreateHandler={() => this.createHandler(values)}
                            EditHandler={this.editHandler}
                            steps={wizardSteps}
                            SetValuesProperty={(name, value) => this.setState({ values: { ...values, [name]: value } })}
                            Mode={mode}
                            generalInfo={generalInfo}
                        />
                    );
                }}
            </GeneralContextConsumer>
        );
    }
}

export default withCultureContext(withSnackbar(CreateUpdateRoleModal));

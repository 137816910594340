import React from "react";
import { startPeer, closePeer, setVideoElement, hasStream } from "./webrtcPreview";
import { VideoWrapper } from "headpoint-react-components";
import { getLiveUrls } from "../../../../services/stream";
import { withSnackbar } from "notistack";
import { Mutex } from "async-mutex";
import { getDeviceTypes, getServiceConfig } from "../../../../contexts";
import { AspectBox } from "../../../components/AspectBox";
import styled from "styled-components";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class CameraPreviewPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.lock = new Mutex();
        this.videoTag = `cameraPreview-${props.camera.id}`;
        this.state = {
            playing: false,
            playStarted: false,
        };
    }

    start = async () => {
        const { camera, generalInfo, enqueueSnackbar, strings } = this.props;
        const { rtcPeerConfig } = getServiceConfig(generalInfo, "service.api.stream");
        if (!rtcPeerConfig) {
            enqueueSnackbar(strings("Отсутствует конфигурация RTCPeerConnection"), { variant: "error" });
            return;
        }

        let url = "";
        if (!hasStream(this.videoTag)) {
            const serviceId = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === camera.typeId)?.serviceId;
            if (!serviceId) {
                enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), { variant: "error" });
                return;
            }

            const [status, liveUrl] = await getLiveUrls(serviceId, camera.id);
            if (!status) {
                enqueueSnackbar(strings("Ошибка получения URL трансляции"), { variant: "error" });
                return;
            }

            if (!liveUrl?.[0]?.url) {
                enqueueSnackbar(strings("URL трансляции отсутствует"), { variant: "error" });
                return;
            }

            url = liveUrl[0].url;
        }

        const release = await this.lock.acquire();
        try {
            const video = document.getElementById(this.videoTag);
            video.addEventListener("playing", () => {
                this.setState({ playing: true });
            });

            await setVideoElement(video, this.videoTag);
            const [startPeerStatus, responseCode] = await startPeer(
                url,
                camera.id,
                this.videoTag,
                JSON.parse(rtcPeerConfig)
            );
            if (!startPeerStatus) {
                if (responseCode === "unauthorized") {
                    enqueueSnackbar(`${strings("Нет доступа к устройству")} '${camera.name}'`, { variant: "error" });
                } else {
                    enqueueSnackbar(strings("Ошибка подключения"), { variant: "error" });
                }
            }
        } finally {
            release();
        }
    };

    async stop() {
        await closePeer();
    }

    playPause = async () => {
        const { playStarted } = this.state;
        const { camera, selectCameraId } = this.props;
        if (playStarted) {
            this.setState({ playStarted: false });
            await this.stop();
        } else {
            await this.start();
            this.setState({ playStarted: true });
            selectCameraId(camera?.id);
        }
    };

    componentDidMount = async () => {
        if (hasStream(this.videoTag)) {
            await this.start();
            this.setState({ playStarted: true });
        }
    };

    render() {
        const { playing, playStarted } = this.state;
        return (
            <CameraPreviewPlayerWrapper onClick={this.playPause}>
                <AspectBox aspect="16:9">
                    <ControlPlaceHolder type={playStarted ? (playing ? "stopButton" : "loader") : "playButton"} />
                    <VideoWrapper
                        videoElement={
                            <video
                                id={this.videoTag}
                                autoPlay
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    visibility: `${playing && playStarted ? "visible" : "collapse"}`,
                                }}
                            />
                        }
                    />
                </AspectBox>
            </CameraPreviewPlayerWrapper>
        );
    }
}

const ControlPlaceHolder = (props) => (
    <ControlPlaceHolderWrapper type={props.type}>
        <div className={props.type} />
    </ControlPlaceHolderWrapper>
);

const ControlPlaceHolderWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: grid;
    background-color: ${({ type }) => (type === "loader" ? "#080f19" : "black")};
    opacity: ${({ type }) => (type === "loader" ? 1 : type === "playButton" ? 0.4 : 0)};
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 25% 50% 25%;
    justify-items: center;
    justify-content: center;
    z-index: 1;

    & .playButton {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
        aspect-ratio: 1/1;
        position: absolute;
        width: 40px;
        height: 50px;
        border-top: 25px solid transparent;
        border-left: 40px solid white;
        border-bottom: 25px solid transparent;
        justify-self: center;
        align-self: center;
    }

    & .stopButton {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
        aspect-ratio: 1/1;
        position: absolute;
        width: 40px;
        height: 40px;
        border-left: 15px solid white;
        border-right: 15px solid white;
        justify-self: center;
        align-self: center;
    }

    & .loader {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
        border: 6px solid #ffffff70;
        border-radius: 50%;
        border-top: 6px solid #182c47;
        width: 50px;
        height: 50px;
        animation: spin 2s linear infinite;
        justify-self: center;
        align-self: center;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &:hover {
        opacity: ${({ type }) => (type === "stopButton" ? 0.7 : 1)};
        background-color: ${({ type }) => (type === "stopButton" ? "black" : "#122135")};
        cursor: pointer;
    }
`;

const CameraPreviewPlayerWrapper = styled.div`
    width: 100%;
    min-width: 180px;
    max-width: 350px;
`;

export default withCultureContext(withSnackbar(CameraPreviewPlayer));

import React, { createRef } from "react";
import { DateRangePicker, Icon, Text } from "headpoint-react-components";
import { AspectBox } from "../../../components/AspectBox";
import { DetailsBoxContent, DetailsBoxItem } from "../../../components/DetailsBox";
import { CameraPlaceholder } from "../../../../layout/CameraLayout";
import { getTimeRange } from "../../../../utilites/TimeUtils";
import ArchivePlayer from "../../../archive/components/ArchivePlayer";
import moment from "moment/moment";
import styled from "styled-components";
import { getDeviceTypes } from "../../../../contexts";
import { GetCameraLacunas } from "../../../../services/archive";
import { withSnackbar } from "notistack";

class DetailsTabArchive extends React.Component {
    constructor(props) {
        const defaultMessage = { text: props.strings("Загрузка архивной трансляции..."), textColor: "white" };
        const endOrNotFoundMessage = { text: props.strings("Отсутствует архивная запись"), textColor: "white" };

        super(props);
        this.archivePlayerRef = createRef();
        this.state = {
            dates: { form: null, to: null },
            isLoading: true,
            resultMessage: defaultMessage,
            defaultMessage: defaultMessage,
            endOrNotFoundMessage: endOrNotFoundMessage,
        };
    }

    async getArchiveLacunas() {
        const camera = this.props.device;
        const { strings } = this.props;

        const serviceCode = getDeviceTypes(this.props.generalInfo)?.find(
            (dt) => dt?.value === camera.typeId
        )?.serviceCode;

        if (!serviceCode) {
            this.props.enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), { variant: "error" });
            return;
        }

        let [getLacunasStatus, result, lacunaResponseStatus] = await GetCameraLacunas(serviceCode, camera.id);

        if (!getLacunasStatus) {
            switch (lacunaResponseStatus) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для получения частей архивной записи"), {
                        variant: "warning",
                    });
                    break;
                case 404: // notfound
                    this.props.enqueueSnackbar(strings("Отсутствует архивная запись"), { variant: "warning" });
                    break;
                default:
                    this.props.enqueueSnackbar(strings("Не удалось получить части архивной записи"), {
                        variant: "error",
                    });
                    break;
            }

            return [{ from: -1, to: -1 }];
        }

        return result?.lacunas;
    }

    checkLacunas(lacunas, dates) {
        if (!lacunas || !Array.isArray(lacunas)) {
            return false;
        }

        if (lacunas?.every((l) => l.from > moment(dates.to).unix())) {
            return false;
        }

        if (lacunas?.every((l) => l.to < moment(dates.from).unix())) {
            return false;
        }

        return true;
    }

    async checkArchiveRanges(dates) {
        let lacunas = await this.getArchiveLacunas();
        if (!this.checkLacunas(lacunas, dates)) {
            this.setState({ isLoading: true, resultMessage: this.state.endOrNotFoundMessage });
        } else {
            this.setState({ isLoading: false, resultMessage: this.state.defaultMessage });
        }
    }

    handleDateRangePreset = (preset) => {
        const dates = getTimeRange(preset);
        void this.checkArchiveRanges(dates);
        this.setState({ dates });
    };

    getDays = (epochStart, epochEnd) => {
        const result = [];
        if (!epochStart || !epochEnd) {
            return result;
        }

        const startDate = moment(epochStart);
        const endDate = moment(epochEnd);
        while (startDate.isSameOrBefore(endDate)) {
            result.push(startDate.clone().toDate());
            startDate.add(1, "days");
        }

        return result;
    };

    render() {
        const { device, generalInfo, strings } = this.props;
        const { dates, isLoading, resultMessage } = this.state;
        const currentFrame = {
            ...device,
            ranges: {
                from: dates.from ? moment(dates.from).unix() : null,
                to: dates.to ? moment(dates.to).unix() : null,
            },
        };
        const clientProtocol = currentFrame?.properties?.settings?.clientProtocol;

        return (
            <DetailsBoxContent>
                <DetailsBoxItem>
                    <DateRangePicker
                        dates={dates}
                        onChange={(dates) => {
                            this.setState({ dates });
                            void this.checkArchiveRanges(dates);
                        }}
                        placeholder={strings("Выбрать период")}
                        colorVariant="transparent"
                        inputFormat="D.MM.YYYY HH:mm"
                        presets={[
                            {
                                label: strings("Последний час"),
                                handler: () => this.handleDateRangePreset("lastHour"),
                            },
                            {
                                label: strings("Последние 6 часов"),
                                handler: () => this.handleDateRangePreset("lastSixHours"),
                            },
                            {
                                label: strings("Последние сутки"),
                                handler: () => this.handleDateRangePreset("lastDay"),
                            },
                        ]}
                        withTime
                        customInputLabels={{
                            hours: strings("Часы"),
                            minutes: strings("Минуты"),
                        }}
                        customIcon={<Icon icon="calendar-clear" />}
                        events={this.getDays(dates?.from, dates?.to)}
                        calendarProps={{
                            disabledDays: { after: new Date() },
                        }}
                    />
                </DetailsBoxItem>
                <AspectBox aspect="16:9">
                    {currentFrame && currentFrame.id && currentFrame.ranges.from && currentFrame.ranges.to ? (
                        <>
                            {isLoading ? (
                                <StyledTabLoader>
                                    <Text color={resultMessage.textColor}>{resultMessage.text}</Text>
                                </StyledTabLoader>
                            ) : (
                                <ArchivePlayer
                                    clientProtocol={clientProtocol}
                                    currentFrame={currentFrame}
                                    frameIndex={0}
                                    key={`${dates?.from}:${dates?.to}`}
                                    generalInfo={generalInfo}
                                    ref={this.archivePlayerRef}
                                />
                            )}
                        </>
                    ) : (
                        <CameraPlaceholder type="archive" bgVariant="secondary" />
                    )}
                </AspectBox>
            </DetailsBoxContent>
        );
    }
}

const StyledTabLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: black;
    . {
        vertical-align: middle;
    }
`;

export default withSnackbar(DetailsTabArchive);

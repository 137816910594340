import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { NavLink } from "react-router-dom";

const SidebarNavigationItemStyled = styled(NavLink)`
    position: relative;
    display: block;
    width: 100%;
    padding: 0 8px 0 38px;
    margin: 0;
    border: none;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 32px;
    height: auto;
    white-space: normal;
    text-align: center;
    cursor: pointer;
    text-align: left;
    background-color: transparent;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
    transition: color 0.16s linear, background-color 0.16s linear;

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.colors.secondary};
        }
    }

    &:active,
    &:focus {
        outline: none;
    }

    & + & {
        margin-top: 8px;
    }

    & i {
        position: absolute;
        top: 50%;
        left: 8px;
        z-index: 1;
        transform: translateY(-50%);
    }

    &.active {
        color: ${({ theme }) => theme.colors.secondary};
        background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
    }
`;

export const SidebarNavigationItem = ({ icon, label, url = "#" }) => {
    return (
        <SidebarNavigationItemStyled to={url}>
            {icon}
            {label}
        </SidebarNavigationItemStyled>
    );
};

const SidebarNavigationNestedItemStyled = styled(NavLink)`
    position: relative;
    display: block;
    width: 100%;
    padding: 0 8px 0 38px;
    margin: 0;
    border: none;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    text-align: left;
    background-color: transparent;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
    transition: color 0.16s linear, background-color 0.16s linear;

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.colors.secondary};
        }
    }

    &:active,
    &:focus {
        outline: none;
    }

    & + & {
        margin-top: 8px;
    }

    & i {
        position: absolute;
        top: 50%;
        left: 8px;
        z-index: 1;
        transform: translateY(-50%);
    }
`;

export const SidebarNavigationNestedItem = ({ icon, label, url = "#" }) => {
    return (
        <SidebarNavigationNestedItemStyled to={url}>
            {icon}
            {label}
        </SidebarNavigationNestedItemStyled>
    );
};

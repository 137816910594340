import React, { useCallback, useRef } from "react";
import { TextField } from "headpoint-react-components";

const isUnumber = /^[+]?[0-9]+[,.]?[0-9]*$/;
const separator = /[,.]/;
const trailingSeparator = /[^,.][,.]$/;

export default function UnumberInput({ param, deviceSettings, SetDeviceSettings, strings }) {
    const lastSeparatorRef = useRef(null);
    const signedRef = useRef(null);
    const hasTrailingSeparatorRef = useRef(null);
    const zerosRef = useRef(null);

    const replaceCurrentSeparator = useCallback((value, replacement) => {
        const currentSeparator = value.match(separator);

        return !currentSeparator ? value : value.replace(currentSeparator[0], replacement);
    }, []);

    const getCurrentValue = useCallback(() => {
        let currentValue = deviceSettings[param.code]?.value?.toString() ?? param.default?.toString() ?? "";

        if (zerosRef.current) {
            currentValue = zerosRef.current;
        }

        currentValue = !lastSeparatorRef.current
            ? replaceCurrentSeparator(currentValue, ",")
            : replaceCurrentSeparator(currentValue, lastSeparatorRef.current);

        currentValue = signedRef.current ? "+" + currentValue : currentValue;

        currentValue = hasTrailingSeparatorRef.current ? currentValue + lastSeparatorRef.current : currentValue;

        return currentValue;
    }, [deviceSettings, param.code, param.default, replaceCurrentSeparator]);

    return (
        <TextField
            key={param.code}
            label={strings(param.name)}
            name={param.code}
            type="text"
            value={getCurrentValue()}
            inputFilter={(text) => {
                if (!text) {
                    return text;
                }

                if (text === "+") {
                    return text;
                }

                if (isUnumber.test(text)) {
                    return text;
                }

                return getCurrentValue();
            }}
            onChange={(e) => {
                let uniformedValue = e.target.value;

                signedRef.current = uniformedValue[0] === "+";
                uniformedValue = uniformedValue.replace("+", "");

                lastSeparatorRef.current = uniformedValue.match(separator)?.[0];
                uniformedValue = uniformedValue.replace(",", ".");

                hasTrailingSeparatorRef.current = trailingSeparator.test(uniformedValue);
                if (hasTrailingSeparatorRef.current) {
                    uniformedValue = uniformedValue.slice(0, -1);
                }

                const parsedValue = parseFloat(uniformedValue);
                if (parsedValue === 0.0) {
                    zerosRef.current = uniformedValue;
                    uniformedValue = "";
                } else {
                    zerosRef.current = null;
                }

                const settingsParameter = {
                    [param.code]: {
                        value: uniformedValue,
                        required: param.required,
                        defaultValue: param.default,
                        name: param.name,
                    },
                };

                SetDeviceSettings(settingsParameter);
            }}
            colorVariant="light"
            fullWidth
            error={param?.required && !deviceSettings[param.code]?.value ? strings(param?.errorText) : null}
        />
    );
}

import React from "react";
import { LayoutMain, LayoutPage } from "../../../layout/MainLayout";
import Sidebar from "../../components/Sidebar";
import { Toolbar } from "./ToolBar";
import { CameraFrames } from "./CameraFrames";
import { GetCameraRanges, GetCameraLacunas } from "../../../services/archive";
import { withSnackbar } from "notistack";
import { FillLocations, GetLocations, GetLocationList, FillNode } from "../../../services/locations";
import { GetDevices, SearchDevices } from "../../../services/devices";
import moment from "moment";
import { nanoid } from "nanoid";
import { GeneralContextConsumer, getDeviceTypes, permissionExists } from "../../../contexts";
import { getAdapters, getDeviceTypesByFeature } from "../../../contexts/GeneralContext";
import ArchiveOrderListModal from "./ArchiveOrderListModal";
import OrderArchiveModal from "./OrderArchiveModal/OrderArchiveModal";
import { noArchiveRecord } from "../../../services/ErrorMessages/archiveErrorMessages";
import { updateNodes } from "../../../utilites/TreeUtils.js";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import SyncCameraFrames from "./Sync/SyncCameraFrames";
import { findRangesIntersection } from "../../../utilites/ArrayUtils";
import { GetEvents } from "../../../services/events";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    saveFrames,
    saveCurrentLayout,
    saveSyncViewEnabled,
    saveIntersection,
    saveShowCameraInfo,
} from "../../../app/reducers/archiveReducer";

const DEVICES_LIMIT = 100;

class Archive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            syncViewEnabled: false,
            devicesLocationsTree: [],
            features: [],
            events: [],
            dates: {
                from: undefined,
                to: undefined,
            },
            intersection: undefined,
            search: "",
            selectedCameraFrame: null,
            frames: {},
            selectedCamera: null,
            showOrderList: false,
            showNewOrderModal: false,
        };
    }

    componentDidMount = async () => {
        const { history } = this.props;

        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
            return;
        }

        const tree = FillLocations(locations, []);
        this.setState({
            devicesLocationsTree: tree ?? [],
        });

        const data = history.location.state;
        if (data) {
            history.replace({ ...history, state: undefined });
            await this.openArchiveFromHistory(data, tree);
        }

        const frames = this.props.archive.frames;
        if (frames && Object.keys(frames).length) {
            await this.openArchiveFromStorage(tree);
        }
    };

    openArchiveFromStorage = async (tree) => {
        const {
            archive: { syncViewEnabled, intersection, frames },
            generalInfo,
        } = this.props;

        const framesToLoad = {};

        for (const frame in frames) {
            await this.openByLocationId(tree, frames[frame].locationId);
            framesToLoad[frame] = await this.getFrameToLoad(generalInfo, frames[frame]);
        }

        this.setState({ frames: framesToLoad, syncViewEnabled, intersection });
    };

    openArchiveFromHistory = async (data, tree) => {
        switch (data?.type) {
            case "SelectCamera":
                let camera = data.device;
                await this.openByLocationId(tree, camera.locationId);

                if (data.dates) {
                    camera.ranges = {};
                    camera.ranges.from = moment(data.dates.from).unix();
                    camera.ranges.to = moment(data.dates.to).unix();
                    camera.ranges.offset = data.captureTime;
                }

                await this.setSelectedCamera(this.props.generalInfo, camera, true);
                break;

            default:
                break;
        }
    };

    openByLocationId = async (devicesLocationsTree, locationId) => {
        const { strings } = this.props;

        if (!locationId) {
            return;
        }

        let [locationsStatus, locationsDb] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locationsDb?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [locationDb] = locationsDb;

        let parentIds = [...locationDb.parentIds.slice(1), locationId];
        let locationNode;

        let tree = updateNodes(devicesLocationsTree, "preOpen", true);

        for (const parentId of parentIds) {
            locationNode = tree.find((i) => i.id === parentId);

            locationNode.isClosed = false;

            if (!locationNode) {
                this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                    variant: "error",
                });
                return;
            }

            locationNode.preOpen = true;

            await this.openLocation(locationNode, this.props.generalInfo);

            tree = locationNode.children;
        }

        this.setState({
            devicesLocationsTree: devicesLocationsTree,
            updateTreeId: nanoid(),
        });
    };

    syncCameraFrameClear = (frameId) => {
        const nextFrames = { ...this.state.frames };
        delete nextFrames[frameId];

        const intersection = findRangesIntersection(Object.values(nextFrames).map((f) => f.ranges));

        if (!intersection) {
            this.setState({
                dates: {
                    from: undefined,
                    to: undefined,
                },
                selectedCamera: null,
                frames: nextFrames,
            });
            return;
        }

        if (frameId === this.state.selectedCameraFrame) {
            this.props.dispatch(saveIntersection(intersection));
            this.setState({
                dates: {
                    from: moment.unix(intersection.from)?._d,
                    to: moment.unix(intersection.from)?._d,
                },
                selectedCamera: null,
                intersection: intersection,
                frames: nextFrames,
            });
            return;
        }

        this.props.dispatch(saveIntersection(intersection));
        this.setState({
            dates: {
                from: moment.unix(intersection.from)?._d,
                to: moment.unix(intersection.to)?._d,
            },
            intersection: intersection,
            frames: nextFrames,
        });
    };

    cameraFrameClear = (frameId) => {
        const nextFrames = { ...this.state.frames };
        delete nextFrames[frameId];
        if (frameId === this.state.selectedCameraFrame) {
            this.props.dispatch(saveFrames(nextFrames));
            this.setState({
                dates: {
                    from: undefined,
                    to: undefined,
                },
                selectedCamera: null,
                frames: nextFrames,
            });
            return;
        }

        this.props.dispatch(saveFrames(nextFrames));
        this.setState({
            frames: nextFrames,
        });
    };

    syncSetFrames = async (frames) => {
        const { enqueueSnackbar, generalInfo, strings } = this.props;

        for (const key in frames) {
            const frame = frames[key];

            const serviceCode = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === frame.typeId)?.serviceCode;

            if (!serviceCode) {
                enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), { variant: "error" });
                continue;
            }

            let [getRangesStatus, ranges, rangesResponseStatus] = await GetCameraRanges(serviceCode, frame.id);
            if (!getRangesStatus) {
                switch (rangesResponseStatus) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для получения архивной записи для")} '${frame.name}'`,
                            { variant: "warning" }
                        );
                        break;
                    case 404: // notfound
                        this.props.enqueueSnackbar(noArchiveRecord(frame, strings), { variant: "warning" });
                        break;
                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Не удалось получить архивную запись для")} '${frame.name}'`,
                            {
                                variant: "error",
                            }
                        );
                        break;
                }
                return;
            }

            frame.ranges = ranges.ranges;
        }

        const intersection = findRangesIntersection(Object.values(frames).map((f) => f.ranges));

        if (!intersection) {
            enqueueSnackbar(strings("Не найдено пересечений для камер"), { variant: "warning" });
            return;
        }

        const dates = {
            from: moment.unix(intersection.from)?._d,
            to: moment.unix(intersection.to)?._d,
        };

        const filter = {
            from: moment.unix(intersection.from),
            to: moment.unix(intersection.to),
        };

        const [getEventsStatus, newEvents] = await GetEvents(filter);

        if (!getEventsStatus) {
            enqueueSnackbar(strings("Не удалось получить список событий"), { variant: "error" });
            return;
        }

        this.props.dispatch(saveIntersection(intersection));
        this.props.dispatch(saveFrames(frames));

        this.setState({
            events: newEvents,
            dates: dates,
            intersection: intersection,
            frames: frames,
        });
    };

    setFrames = async (frames) => {
        this.props.dispatch(saveFrames(frames));
        this.setState({ frames });
    };

    setSearch = async (like, generalInfo) => {
        const { strings } = this.props;

        let adapters = getAdapters(generalInfo);
        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.archive");
        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.props.enqueueSnackbar(strings("Не найдено адаптеров поддерживающих архив"), { variant: "warning" });
            this.setState({ search: like, searchDevices: [] });
            return;
        }

        const [devicesStatus, searchDevices] = await SearchDevices({
            like,
            limit: DEVICES_LIMIT,
            deviceTypes: deviceTypeIds,
        });
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        this.setState({ search: like, searchDevices: searchDevices?.map((d) => ({ ...d, tag: "device" })) });
    };

    setDates = (dates) => {
        const { strings } = this.props;

        if (!this.state.selectedCamera) {
            this.props.enqueueSnackbar(strings("Камера не выбрана!"), { variant: "warning" });
            return;
        }
        let frames = this.state.frames;
        let camera = this.state.selectedCamera;
        let from = moment(dates.from).unix();
        let to = moment(dates.to).unix();

        camera.ranges.from = from;
        camera.ranges.to = to;

        frames[this.state.selectedCameraFrame] = camera;

        this.props.dispatch(saveFrames(frames));
        this.setState({
            frames: frames,
            selectedCamera: camera,
            dates: dates,
        });
    };

    syncSetDates = (dates) => {
        const { frames } = this.state;
        const { enqueueSnackbar, strings } = this.props;

        if (Object.values(frames).length === 0) {
            enqueueSnackbar(strings("Камера не выбрана!"), { variant: "warning" });
            return;
        }

        if (dates.from === undefined && dates.to === undefined) {
            return;
        }

        const datesRanges = {
            from: moment(dates.from).unix(),
            to: moment(dates.to).unix(),
        };

        let ranges = Object.values(frames).map((f) => f.ranges);
        ranges.push(datesRanges);

        let intersection = findRangesIntersection(ranges);
        if (intersection) {
            this.props.dispatch(saveIntersection(intersection));
            this.setState({
                intersection: intersection,
                dates,
            });
        } else {
            enqueueSnackbar(strings("Не найдено пересечений для камер"), { variant: "warning" });
            this.setState({ dates: this.state.dates });
        }
    };

    setCurrentLayout = (layout, framesCount) => {
        const { selectedCameraFrame, frames } = this.state;
        const { currentLayout } = this.props.archive;

        if (currentLayout !== layout) {
            let nextFrames;

            if (layout === "1") {
                nextFrames = { 0: frames[selectedCameraFrame] };
            } else {
                nextFrames = { ...frames };
                Object.keys(nextFrames).forEach((key) => {
                    if (key >= framesCount) {
                        delete nextFrames[key];
                    }
                });
            }

            this.setState({ selectedCamera: frames[selectedCameraFrame], frames: nextFrames });

            this.props.dispatch(saveCurrentLayout(layout));
            this.props.dispatch(saveFrames(nextFrames));

            this.setSelectedCameraFrame(0);
        }
    };

    setDefaultState = () => {
        let frames = this.state.frames;
        delete frames[this.state.selectedCameraFrame];

        this.props.dispatch(saveFrames(frames));
        this.setState({
            selectedCamera: null,
            dates: {
                from: undefined,
                to: undefined,
            },
            frames: frames,
        });
    };

    syncSetSelectedCameraFrame = (selectedCameraFrame) => {
        this.setState({ selectedCameraFrame: selectedCameraFrame });
    };

    setSelectedCameraFrame = (selectedCameraFrame) => {
        let camera = this.state.frames[selectedCameraFrame];
        if (!camera) {
            this.setState({
                dates: {
                    from: undefined,
                    to: undefined,
                },
                selectedCamera: camera,
                selectedCameraFrame: selectedCameraFrame,
            });

            return;
        }

        let dates = {
            from: moment.unix(camera.ranges?.from)?._d,
            to: moment.unix(camera.ranges?.to)?._d,
        };

        this.setState({
            dates: dates,
            selectedCamera: camera,
            selectedCameraFrame: selectedCameraFrame,
        });
    };

    getFrameToLoad = async (generalInfo, selectedCamera) => {
        const { strings } = this.props;
        const serviceCode = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === selectedCamera.typeId)?.serviceCode;

        if (!serviceCode) {
            this.props.enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), {
                variant: "error",
            });
            return;
        }

        let [getRangesStatus, ranges, rangesResponseStatus] = await GetCameraRanges(serviceCode, selectedCamera.id);
        if (!getRangesStatus) {
            switch (rangesResponseStatus) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для получения архивной записи для")} '${selectedCamera.name}'`,
                        { variant: "warning" }
                    );
                    break;
                case 404: // notfound
                    this.props.enqueueSnackbar(noArchiveRecord(selectedCamera), { variant: "warning" });
                    break;
                default:
                    this.props.enqueueSnackbar(`Не удалось получить архивную запись для '${selectedCamera.name}'`, {
                        variant: "error",
                    });
                    break;
            }
            this.setDefaultState();
            return;
        }

        let [getLacunasStatus, lacunas, lacunaResponseStatus] = await GetCameraLacunas(serviceCode, selectedCamera.id);
        if (!getLacunasStatus) {
            switch (lacunaResponseStatus) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `Не хватает прав для получения частей архивной записи для '${selectedCamera.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;
                case 404: // notfound
                    this.props.enqueueSnackbar(noArchiveRecord(selectedCamera), { variant: "warning" });
                    break;
                default:
                    this.props.enqueueSnackbar(
                        `Не удалось получить части архивной записи для '${selectedCamera.name}'`,
                        { variant: "error" }
                    );
                    break;
            }
            this.setDefaultState();
            return;
        }

        return {
            id: selectedCamera.id,
            name: selectedCamera.name,
            typeId: selectedCamera.typeId,
            locationId: selectedCamera.locationId,
            lacunas: lacunas.lacunas,
            ranges: selectedCamera.ranges?.from ? selectedCamera.ranges : ranges.ranges,
        };
    };

    setSelectedCamera = async (generalInfo, selectedCamera, setFrame) => {
        const { strings } = this.props;

        const serviceCode = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === selectedCamera.typeId)?.serviceCode;

        if (!serviceCode) {
            this.props.enqueueSnackbar("Не удалось найти сервис по типу оборудования", { variant: "error" });
            return;
        }

        let [getRangesStatus, ranges, rangesResponseStatus] = await GetCameraRanges(serviceCode, selectedCamera.id);
        if (!getRangesStatus) {
            switch (rangesResponseStatus) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `Не хватает прав для получения архивной записи для '${selectedCamera.name}'`,
                        { variant: "warning" }
                    );
                    break;
                case 404: // notfound
                    this.props.enqueueSnackbar(noArchiveRecord(selectedCamera, strings), { variant: "warning" });
                    break;
                default:
                    this.props.enqueueSnackbar(
                        `${strings("Не удалось получить архивную запись для")} '${selectedCamera.name}'`,
                        {
                            variant: "error",
                        }
                    );
                    break;
            }
            this.setDefaultState();
            return;
        }

        let [getLacunasStatus, lacunas, lacunaResponseStatus] = await GetCameraLacunas(serviceCode, selectedCamera.id);
        if (!getLacunasStatus) {
            switch (lacunaResponseStatus) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для получения частей архивной записи для")} '${
                            selectedCamera.name
                        }'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;
                case 404: // notfound
                    this.props.enqueueSnackbar(noArchiveRecord(selectedCamera, strings), { variant: "warning" });
                    break;
                default:
                    this.props.enqueueSnackbar(
                        `${strings("Не удалось получить части архивной записи для")} '${selectedCamera.name}'`,
                        { variant: "error" }
                    );
                    break;
            }
            this.setDefaultState();
            return;
        }

        selectedCamera.lacunas = lacunas.lacunas;
        selectedCamera.ranges = selectedCamera.ranges ?? { ...ranges.ranges };

        let dates = {
            from: moment.unix(selectedCamera.ranges?.from)?._d,
            to: moment.unix(selectedCamera.ranges?.to)?._d,
        };

        if (setFrame) {
            this.setState({
                dates: dates,
                selectedCamera: selectedCamera,
                frames: [selectedCamera],
                selectedCameraFrame: 0,
            });
            return;
        }

        this.setState({
            dates: dates,
            selectedCamera: selectedCamera,
        });
    };

    setShowCameraInfo = (show) => {
        this.props.dispatch(saveShowCameraInfo(show));
    };

    setSyncViewEnabled = async (enabled) => {
        const { strings } = this.props;
        const { frames, selectedCameraFrame } = this.state;

        if (enabled) {
            const { enqueueSnackbar } = this.props;

            let intersection = findRangesIntersection(Object.values(frames).map((f) => f.ranges));

            if (!intersection) {
                enqueueSnackbar(strings("Не найдено пересечений для камер"), { variant: "warning" });
                return;
            }

            const dates = {
                from: moment.unix(intersection.from)?._d,
                to: moment.unix(intersection.to)?._d,
            };

            const filter = {
                from: moment.unix(intersection.from),
                to: moment.unix(intersection.to),
            };

            const [getEventsStatus, newEvents] = await GetEvents(filter);

            if (!getEventsStatus) {
                enqueueSnackbar(strings("Не удалось получить список событий"), { variant: "error" });
                return;
            }

            this.props.dispatch(saveIntersection(intersection));

            this.setState({
                events: newEvents,
                dates: dates,
                intersection: intersection,
            });
        } else {
            let camera = frames[selectedCameraFrame];

            if (!camera) {
                this.props.dispatch(saveSyncViewEnabled(enabled));

                this.setState({
                    dates: {
                        from: undefined,
                        to: undefined,
                    },
                    selectedCamera: camera,
                    selectedCameraFrame: selectedCameraFrame,
                    syncViewEnabled: enabled,
                });

                return;
            }

            let dates = {
                from: moment.unix(camera.ranges?.from)?._d,
                to: moment.unix(camera.ranges?.to)?._d,
            };

            this.setState({
                dates: dates,
                selectedCamera: camera,
                selectedCameraFrame: selectedCameraFrame,
            });
        }

        this.props.dispatch(saveSyncViewEnabled(enabled));
        this.setState({ syncViewEnabled: enabled });
    };

    openChildren = async (locationNode, generalInfo, openedLocations) => {
        const { strings } = this.props;
        let adapters = getAdapters(generalInfo);

        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.archive");
        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.props.enqueueSnackbar(strings("Не найдено адаптеров поддерживающих архив"), { variant: "warning" });
            return;
        }

        const [getDevicesStatus, devices] = await GetDevices(locationNode.id, deviceTypeIds);

        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);

        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, devices);
        }

        let sublocations = locationNode.children.filter((child) => openedLocations.has(child.id));
        for (let sublocation of sublocations) {
            sublocation.isClosed = false;
            await this.openChildren(sublocation, generalInfo, openedLocations);
        }
    };

    openLocation = async (locationNode, generalInfo) => {
        const { devicesLocationsTree } = this.state;

        let openedSublocations = new Set(
            this.flatten(locationNode)
                .filter((node) => node?.isClosed === false)
                .map((node) => node.id)
        );

        await this.openChildren(locationNode, generalInfo, openedSublocations);

        this.setState({
            devicesLocationsTree,
        });
    };

    flatten = (locationNode) => {
        let flat = [];

        let stack = [locationNode];
        while (stack.length > 0) {
            let node = stack.pop();

            if (node) {
                flat.push(node);

                if (node.children) {
                    stack.push(...node.children);
                }
            }
        }

        return flat;
    };

    createCameraForArchiveOrder = (frame) => {
        return {
            id: frame.id,
            locationId: frame.locationId,
            name: frame.name,
            settings: frame.settings,
            typeId: frame.typeId,
        };
    };

    render() {
        const {
            devicesLocationsTree,
            searchDevices,
            search,
            showOrderList,
            showNewOrderModal,
            updateTreeId,
            syncViewEnabled,
            frames,
            selectedCameraFrame,
            dates,
            events,
            intersection,
        } = this.state;

        const {
            archive: { currentLayout, showCameraInfo },
            strings,
            userInfo,
        } = this.props;

        const source = search ? searchDevices : devicesLocationsTree;
        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <LayoutPage>
                            <Sidebar
                                key={updateTreeId}
                                id={nanoid()}
                                search={search}
                                setSearch={(like) => this.setSearch(like, generalInfo)}
                                frames={frames}
                                setFrames={syncViewEnabled ? this.syncSetFrames : this.setFrames}
                                selectedCameraFrame={this.state.selectedCameraFrame}
                                currentLayout={currentLayout}
                                devicesLocationsTree={source ?? []}
                                openLocation={(location) => this.openLocation(location, generalInfo)}
                                setSelectedCamera={this.setSelectedCamera}
                                setSelectedCameraFrame={
                                    syncViewEnabled ? this.syncSetSelectedCameraFrame : this.setSelectedCameraFrame
                                }
                                generalInfo={generalInfo}
                                strings={strings}
                            />

                            <LayoutMain>
                                <Toolbar
                                    syncViewEnabled={syncViewEnabled}
                                    setSyncViewEnabled={this.setSyncViewEnabled}
                                    selectedCameraFrame={selectedCameraFrame}
                                    dates={dates}
                                    setDates={syncViewEnabled ? this.syncSetDates : this.setDates}
                                    frames={frames}
                                    setFrames={syncViewEnabled ? this.syncSetFrames : this.setFrames}
                                    currentLayout={currentLayout}
                                    setCurrentLayout={this.setCurrentLayout}
                                    showCameraInfo={showCameraInfo}
                                    setShowCameraInfo={this.setShowCameraInfo}
                                    userInfo={userInfo}
                                    openOrderList={() => this.setState({ showOrderList: true })}
                                    handleOrderRecordModal={() => this.setState({ showNewOrderModal: true })}
                                    strings={strings}
                                />
                                <>
                                    {!syncViewEnabled ? (
                                        <CameraFrames
                                            key={this.state.selectedCamera?.ranges?.offset}
                                            dates={dates}
                                            showCameraInfo={showCameraInfo}
                                            selectedCameraFrame={selectedCameraFrame}
                                            setSelectedCameraFrame={this.setSelectedCameraFrame}
                                            frames={this.state.frames}
                                            setFrames={this.setFrames}
                                            cameraFrameClear={this.cameraFrameClear}
                                            currentLayout={currentLayout}
                                        />
                                    ) : (
                                        <SyncCameraFrames
                                            events={events}
                                            generalInfo={generalInfo}
                                            dates={dates}
                                            intersection={intersection}
                                            frames={frames}
                                            currentLayout={currentLayout}
                                            showCameraInfo={showCameraInfo}
                                            selectedCameraFrame={selectedCameraFrame}
                                            setSelectedCameraFrame={this.syncSetSelectedCameraFrame}
                                            cameraFrameClear={this.syncCameraFrameClear}
                                        />
                                    )}
                                </>
                            </LayoutMain>
                        </LayoutPage>
                        {permissionExists(userInfo, ["video.archive.access"]) && showNewOrderModal === true ? (
                            <OrderArchiveModal
                                dates={syncViewEnabled ? dates : undefined}
                                selectedCameras={Object.values(this.state.frames).map(this.createCameraForArchiveOrder)}
                                devicesLocationsTree={devicesLocationsTree}
                                handleClose={() => this.setState({ showNewOrderModal: false })}
                                generalInfo={generalInfo}
                                strings={strings}
                            />
                        ) : (
                            <div />
                        )}
                        {permissionExists(userInfo, ["video.archive.access"]) && showOrderList === true ? (
                            <ArchiveOrderListModal
                                archiveOrderList={[]}
                                handleClose={() => this.setState({ showOrderList: false })}
                                generalInfo={generalInfo}
                                strings={strings}
                            />
                        ) : (
                            <div />
                        )}
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

class ArchiveWrapper extends React.Component {
    render() {
        return (
            <GeneralContextConsumer>
                {(generalInfo) => React.createElement(Archive, { ...this.props, generalInfo })}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        archive: state.archive,
    };
};

export default connect(mapStateToProps)(withCultureContext(withSnackbar(withRouter(ArchiveWrapper))));

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userInfo: {},
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        clearUserInfo: (state) => {
            state.userInfo = {};
        },
    },
});

export const { saveUserInfo, clearUserInfo } = userSlice.actions;

export default userSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { Label, Icon } from "headpoint-react-components";

export const CameraLayoutWrapper = ({ children, layoutType, hasFullscreenToggle }) => {
    const [inFullscreen, setInFullscreen] = useState(false);

    const wrapperRef = useRef();

    const activateFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    };

    const handleFullscreen = () => {
        if (inFullscreen) {
            if (document.exitFullscreen) {
                void document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            setInFullscreen(false);
        } else {
            activateFullscreen(wrapperRef.current);
            setInFullscreen(true);
        }
    };

    const listenFullscreenChange = () => {
        const isFullscreen = window.innerHeight === window.screen.height;
        setInFullscreen(isFullscreen);
    };

    useEffect(() => {
        document.addEventListener("fullscreenchange", listenFullscreenChange, false);

        return () => {
            document.removeEventListener("fullscreenchange", listenFullscreenChange, false);
        };
    }, []);

    return (
        <CameraLayoutWrapperStyled ref={wrapperRef} inFullscreen={inFullscreen} layoutType={layoutType}>
            {children}

            {hasFullscreenToggle && (
                <CameraLayoutFullscreenBtn type="button" onClick={handleFullscreen}>
                    <Icon icon="fullscreen-alt" />
                </CameraLayoutFullscreenBtn>
            )}
        </CameraLayoutWrapperStyled>
    );
};

export const CameraLayoutWrapperStyled = styled.div`
    position: relative;
    flex: 1;
    margin: -1px;

    ${({ inFullscreen }) =>
        inFullscreen &&
        css`
            width: 100vw;
            height: 100vh;
        `}
`;

const CameraLayoutFullscreenBtn = styled.button`
    position: absolute;
    right: 32px;
    bottom: 32px;
    z-index: 10;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px rgba(13, 22, 35, 0.25);
    cursor: pointer;
    transition: opacity 0.16s linear;

    @media (hover: hover) {
        &:hover {
            opacity: 0.75;
        }
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

const getItemSize = (count) => {
    switch (count) {
        case 1:
            return css`
                width: 100%;
                height: 100%;
            `;

        case 2:
            return css`
                width: 50%;
                height: 100%;
            `;

        case 4:
            return css`
                width: 50%;
                height: 50%;
            `;

        case 6:
            return css`
                width: 33.33%;
                height: 50%;
            `;

        case 8:
            return css`
                width: 25%;
                height: 50%;
            `;

        case 16:
            return css`
                width: 25%;
                height: 25%;
            `;

        default:
            return "";
    }
};

export const CameraLayoutItem = ({
    children,
    count,
    isSelected,
    onSelect,
    label = "",
    placeholderLabel = "",
    selectedPlaceholderLabel = "",
    onClear,
    hasItem,
}) => (
    <CameraLayoutItemStyled count={count} isSelected={isSelected} onClick={onSelect}>
        {onClear && hasItem && <CameraLayoutItemClearBtn type="button" onClick={onClear} />}

        <CameraLayoutItemLabel>
            {!hasItem ? (
                <Label label={isSelected ? selectedPlaceholderLabel : placeholderLabel} isActive={isSelected} />
            ) : (
                <Label label={label} isActive={isSelected} />
            )}
        </CameraLayoutItemLabel>

        {children}
    </CameraLayoutItemStyled>
);

const CameraLayoutItemLabel = styled.div`
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 1;
`;

const CameraLayoutItemStyled = styled.div`
    flex: 1;
    position: relative;
    background-color: ${({ theme }) => theme.colors.primary2};
    border: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 1px;
    height: 100%;

    ${({ count }) => getItemSize(count)};

    &:after {
        content: "";
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        z-index: 1;
        pointer-events: none;
        border: 2px solid transparent;
        transition: border-color 0.16s linear;
    }

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            &:after {
                border-color: ${theme.colors.blue};
            }
        `}
`;

const getBgVariant = (theme, bgVariant) => {
    switch (bgVariant) {
        case "primary":
            return css`
                background-color: ${theme.colors.primary2};
            `;

        case "secondary":
            return css`
                background-color: ${theme.colors.primary1};
            `;

        default:
            return "";
    }
};

const CameraPlaceholderWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    ${({ theme, bgVariant }) => bgVariant && getBgVariant(theme, bgVariant)};

    & img {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        height: 220px;
        max-height: 80%;
    }
`;

const CameraPlaceholderLabel = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 110px);
    max-width: 220px;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary};
    opacity: 0.5;
`;

export const CameraPlaceholder = ({ type = "stream", bgVariant, label }) => (
    <CameraPlaceholderWrapper bgVariant={bgVariant}>
        <img src={`/img/${type}-placeholder.png`} alt="placeholder" />
        {label && <CameraPlaceholderLabel>{label}</CameraPlaceholderLabel>}
    </CameraPlaceholderWrapper>
);

const CameraLayoutItemClearBtn = styled.button`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.blackText};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    transition: opacity 0.16s linear;

    &:before {
        content: "\\e913";
    }

    @media (hover: hover) {
        &:hover {
            opacity: 0.75;
        }
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

export const CameraLayoutRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: ${({ rowHeight }) => rowHeight || "100%"};
`;

export const CameraLayoutCol = styled.div`
    flex: 0 1 ${({ colWidth }) => colWidth || "100%"};
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const CameraLayoutPlayerInner = styled.div`
    display: flex;
    height: calc(100% - 1px);
`;

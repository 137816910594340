import React from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { withSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class DetailsTabMain extends React.Component {
    render() {
        const { devicesCount, countDanger, countWarning, location, detailsTab, locationsExtensions } = this.props;

        const extensions = locationsExtensions?.filter((ext) => ext?.details?.detailsTab === detailsTab);
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Всего устройств")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {devicesCount === -1 ? "..." : devicesCount}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Всего непросмотренных событий")}</TableCell>
                                    <TableCell />
                                </TableRow>
                                <TableRow>
                                    <TableCell indent={30} noBorder>
                                        {strings("Тревожных")}
                                    </TableCell>
                                    <TableCell>
                                        <Text color="danger" variant="body-sm">
                                            {countDanger === -1 ? "..." : countDanger}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell indent={30}>{strings("Требующих внимания")}</TableCell>
                                    <TableCell>
                                        <Text color="warning" variant="body-sm">
                                            {countWarning === -1 ? "..." : countWarning}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                {extensions?.map((ext) => (
                                    <TableRow key={nanoid()}>
                                        <TableCell>{strings(ext?.title)}</TableCell>
                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {location?.properties?.[ext?.name ?? ""] ?? "..."}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(DetailsTabMain);

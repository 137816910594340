import { Datepicker, Icon, ModalSection, Select, Text } from "headpoint-react-components";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { withGeneralContext } from "../../../contexts/GeneralContext";
import { saveScheduleState } from "../../../app/reducers/scheduleReducer";
import { useDispatch, useSelector } from "react-redux";
import { ModalFormItem } from "../../components/CreateUpdateWizard/Styled";
import {
    DateOption,
    DayOption,
    MonthOption,
    RegularIntervalOption,
    RepeatDayOption,
    TimeOption,
} from "../../components/TimeOptions";
import React from "react";
import { Space } from "headpoint-react-components";
import { cloneDeep } from "lodash";
import { withSnackbar } from "notistack";

const ModalStepIntervals = ({ strings, enqueueSnackbar }) => {
    const dispatch = useDispatch();
    const values = useSelector((state) => state.schedule.values);

    const saveValue = (name, value) => {
        dispatch(saveScheduleState({ values: { ...values, [name]: value } }));
    };

    const saveValues = (newValues) => {
        dispatch(saveScheduleState({ values: { ...values, ...newValues } }));
    };

    return (
        <>
            <ModalSection>
                <ModalFormItem>
                    <Select
                        label={strings("Интервал повторения")}
                        value={values.intervalsType}
                        options={[
                            { value: "oneTime", label: strings("Однократное") },
                            { value: "repetition", label: strings("Повторение") },
                        ]}
                        onChange={(e) => {
                            saveValues({
                                intervalsType: e,
                                dates: [],
                                times: [],
                            });
                        }}
                        fullWidth
                    />
                </ModalFormItem>
                {values.intervalsType === "repetition" && (
                    <>
                        <ModalFormItem>
                            <Datepicker
                                fullWidth="true"
                                label={strings("Дата и время начала")}
                                date={
                                    values.start ??
                                    (() => {
                                        saveValue("start", new Date());
                                        return Date();
                                    })()
                                }
                                onChange={(e) => saveValue("start", e)}
                                colorVariant="transparent"
                                width={144}
                                inputFormat="D.MM.YYYY HH:mm"
                                withTime
                                customIcon={<Icon icon="calendar" />}
                            />
                            <Space />
                            <Datepicker
                                disabled={!values.start}
                                fullWidth="true"
                                label={strings("Дата и время завершения")}
                                date={values.end}
                                onChange={(e) => {
                                    saveValue("end", e);
                                }}
                                colorVariant="transparent"
                                width={144}
                                inputFormat="D.MM.YYYY HH:mm"
                                withTime
                                customIcon={<Icon icon="calendar" />}
                            />
                        </ModalFormItem>
                    </>
                )}
            </ModalSection>
            <ModalSection>
                {values.intervalsType === "repetition" && (
                    <>
                        <ModalFormItem>
                            <Select
                                label={strings("Время")}
                                value={
                                    values.timeType ??
                                    (() => {
                                        saveValue("timeType", "time");
                                        return "time";
                                    })()
                                }
                                options={[
                                    { value: "time", label: strings("Точное время") },
                                    { value: "interval", label: strings("Временной интервал") },
                                ]}
                                onChange={(e) => saveValue("timeType", e)}
                                fullWidth
                            />

                            {values?.timeType === "time" && (
                                <TimeOption times={values.times ?? []} setTimes={(e) => saveValue("times", e)} />
                            )}

                            {values?.timeType === "interval" && (
                                <RegularIntervalOption
                                    intervals={cloneDeep(values.intervals ?? [])}
                                    setIntervals={(e) => {
                                        saveValue("intervals", e);
                                    }}
                                />
                            )}
                        </ModalFormItem>
                        <ModalFormItem>
                            <Select
                                label={strings("Повторение")}
                                value={
                                    values.dateType ??
                                    (() => {
                                        saveValue("dateType", "day");
                                        return "day";
                                    })()
                                }
                                options={[
                                    { value: "day", label: strings("Повтор каждый день") },
                                    { value: "week", label: strings("Еженедельно, дни недели") },
                                    { value: "month", label: strings("Ежемесячно, дни месяца") },
                                ]}
                                onChange={(e) => saveValue("dateType", e)}
                                fullWidth
                            />

                            {values.dateType === "day" && (
                                <RepeatDayOption dates={values.days ?? []} setDates={(e) => saveValue("days", e)} />
                            )}

                            {values.dateType === "week" && (
                                <DayOption
                                    dates={values.daysOfWeek ?? []}
                                    setDates={(e) => saveValue("daysOfWeek", e)}
                                />
                            )}

                            {values.dateType === "month" && (
                                <MonthOption
                                    dates={values.daysOfMonth ?? []}
                                    setDates={(e) => saveValue("daysOfMonth", e)}
                                />
                            )}
                        </ModalFormItem>
                    </>
                )}

                {values.intervalsType === "oneTime" && (
                    <>
                        <ModalFormItem>
                            <Text variant={"body-sm"}>{strings("Интервалы выполнения")}</Text>
                        </ModalFormItem>
                        <ModalFormItem>
                            <Text variant="description" color="secondary">
                                {strings("Время")}
                            </Text>
                            <TimeOption times={values.times ?? []} setTimes={(e) => saveValue("times", e)} />
                        </ModalFormItem>
                        <ModalFormItem>
                            <Text variant={"description"} color="secondary">
                                {strings("Дата")}
                            </Text>
                            <DateOption dates={values.dates ?? []} setDates={(e) => saveValue("dates", e)} />
                        </ModalFormItem>
                    </>
                )}
            </ModalSection>
        </>
    );
};

export default withCultureContext(withGeneralContext(withSnackbar(ModalStepIntervals)));

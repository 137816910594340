import {
    Button,
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    ExpandableSearch,
    Pagination,
} from "headpoint-react-components";
import {
    EventsLayoutHeaderToolboxItem,
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutTableWrapper,
    EventsLayoutHeaderToolbox,
    EventsLayoutWrapper,
    EventsLayoutPaginationWrapper,
} from "../../layout/EventsLayout";
import { withSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { withCultureContext } from "../../contexts/cultureContext/CultureContext";
import {
    SCHEDULES_ON_PAGE,
    fetchSchedules,
    saveScheduleState,
    showCreateWizard,
} from "../../app/reducers/scheduleReducer";
import { getFeatureByServiceId, permissionExists, withGeneralContext } from "../../contexts/GeneralContext";
import { useEffect } from "react";

const SchedulesList = ({ generalInfo, strings, enqueueSnackbar }) => {
    const dispatch = useDispatch();

    const { schedules, status, countOnPage, pageNumber, pagesCount, search, offset } = useSelector(
        (state) => state.schedule
    );

    const { userInfo } = useSelector((state) => state.persistedReducer);

    useEffect(() => {
        const filter = {
            countOnPage: countOnPage,
            pageNumber: pageNumber - 1,
            status: status,
            like: search,
        };

        dispatch(fetchSchedules({ filter, enqueueSnackbar }));
    }, [pageNumber, schedules.length, countOnPage, status, search, dispatch, strings, enqueueSnackbar]);

    const handlePage = (page) => {
        if (page <= pagesCount) {
            dispatch(saveScheduleState({ pageNumber: page }));
        }
    };

    const handleShowMore = async () => {
        dispatch(
            saveScheduleState({
                countOnPage: countOnPage + SCHEDULES_ON_PAGE,
                pageNumber: 1,
            })
        );
    };

    const getStatusText = (status) => {
        switch (status) {
            case "Active":
                return strings("Активно");
            case "Completed":
                return strings("Завершено");
            case "Paused":
                return strings("Приостановлено");
            default:
                return "";
        }
    };

    return (
        <EventsLayoutPrimary>
            <EventsLayoutHeader>
                <EventsLayoutHeaderTitle>
                    <Text variant="title" component="h1">
                        {strings("Расписания")}
                    </Text>
                    <ExpandableSearch
                        placeholder={strings("Найти")}
                        colorVariant="dark"
                        inHeader
                        value={search}
                        onChange={(e) => dispatch(saveScheduleState({ search: e.target.value }))}
                    />
                </EventsLayoutHeaderTitle>

                <EventsLayoutHeaderToolbox>
                    <EventsLayoutHeaderToolboxItem>
                        {permissionExists(userInfo, ["schedule.service.personal.lifeCycle"]) && (
                            <Button
                                variant="secondary"
                                label={strings("Создать")}
                                onClick={() => dispatch(showCreateWizard())}
                            >
                                {strings("Создать")}
                            </Button>
                        )}
                    </EventsLayoutHeaderToolboxItem>
                </EventsLayoutHeaderToolbox>
            </EventsLayoutHeader>
            <EventsLayoutWrapper>
                <EventsLayoutTableWrapper>
                    <Table hasHover stickyHeader isFixed bgColor="dark">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>{strings("Расписание")}</TableHeadCell>
                                <TableHeadCell>{strings("Вид")}</TableHeadCell>
                                <TableHeadCell>{strings("Статус")}</TableHeadCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {schedules?.map((schedule) => (
                                <TableRow
                                    key={schedule.id}
                                    onClick={() => dispatch(saveScheduleState({ selectedSchedule: schedule }))}
                                >
                                    <TableCell>
                                        <Text variant="body-sm">{schedule?.name}</Text>
                                    </TableCell>

                                    <TableCell>
                                        <Text variant="body-sm">
                                            {strings(
                                                getFeatureByServiceId(generalInfo, schedule?.serviceId)
                                                    ?.featureClassName ?? "..."
                                            )}
                                        </Text>
                                    </TableCell>

                                    <TableCell>
                                        <Text variant="body-sm">{getStatusText(schedule?.status)}</Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </EventsLayoutTableWrapper>
                <EventsLayoutPaginationWrapper>
                    <Button variant="secondary" label={strings("Показать ещё")} onClick={handleShowMore} />
                    <Pagination offset={offset} page={pageNumber} count={pagesCount} onChange={(e) => handlePage(e)} />
                </EventsLayoutPaginationWrapper>
            </EventsLayoutWrapper>
        </EventsLayoutPrimary>
    );
};

export default withGeneralContext(withCultureContext(withSnackbar(SchedulesList)));

import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { EventsLayoutHeaderTitle } from "../../../../../layout/EventsLayout";
import { formatTagsDeviceCount } from "../../../../../utilites/TextUtils";
import { Text, Row, ExpandableSearch } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { GetLocationList, GetLocationNames } from "../../../../../services/locations";
import { GetDeviceInfo, SearchDevices } from "../../../../../services/devices";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import DeviceLocationList from "../../../../components/DeviceLocationList/DeviceLocationList";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../../CommonTablePagination";

const DEVICES_LIMIT = 100;

class DetailsTabDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchList: [],
            search: "",
            devices: [],
            pageData: { start: 0, end: ITEMS_ON_PAGE },
        };
    }

    async loadDevices() {
        const { tag, strings } = this.props;
        let [getDevicesStatus, devices] = await GetDeviceInfo(tag?.entityIds ?? []);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
        }

        devices = await this.appendLocations(devices);

        return this.setState({ devices });
    }

    async setSearch(search) {
        const { tag, strings } = this.props;
        if (search && search !== "") {
            const [devicesStatus, searchList] = await SearchDevices({
                like: search,
                limit: DEVICES_LIMIT,
                ids: tag?.entityIds,
            });

            if (!devicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            this.setState({ search, searchList });
        } else {
            this.setState({ search, searchList: [] });
        }
    }

    getFullLocationName = (location, parentIdsMap) => {
        const rootLocationId = location.parentIds[1] ?? undefined;

        if (rootLocationId) {
            const parentLength = location.parentIds.length;

            if (parentLength === 2) {
                return `${parentIdsMap.get(rootLocationId)} / ${location.name}`;
            } else if (parentLength >= 3) {
                return `${parentIdsMap.get(rootLocationId)} / ... / ${location.name}`;
            }
        }

        return location.name;
    };

    appendLocations = async (devices) => {
        const { enqueueSnackbar, strings } = this.props;

        const deviceLocationsIds = devices.map((d) => d.locationId);

        let [locationsStatus, locations] = await GetLocationList({ ids: deviceLocationsIds });
        if (!locationsStatus) {
            enqueueSnackbar(strings("Ошибка при получении локаций"), { variant: "error" });
            return;
        }

        const parentIds = new Set();

        locations.forEach((location) => {
            const rootLocationId = location.parentIds[1];
            if (rootLocationId) {
                parentIds.add(rootLocationId);
            }
        });

        const [getNamesStatus, parentLocationIdNamePairs] = await GetLocationNames(parentIds);
        if (!getNamesStatus) {
            enqueueSnackbar(strings("Ошибка при получении названий локаций"), { variant: "error" });
            return;
        }

        return devices.map((device) => {
            const deviceLocation = locations.find((location) => location.id === device.locationId);

            return {
                ...device,
                location: deviceLocation,
                fullLocationName: this.getFullLocationName(deviceLocation, parentLocationIdNamePairs),
            };
        });
    };

    getHeaderText = () => {
        const { tag, strings } = this.props;

        let count = tag?.entityIds?.length ?? "...";
        let word = strings(formatTagsDeviceCount(tag?.entityIds?.length ?? 0));
        return `${count} ${word} ${strings("назначен тег оборудования")}`;
    };

    componentDidMount = async () => {
        await this.loadDevices();
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.tag?.id !== this.props.tag?.id) {
            await this.loadDevices();
        }
    };

    render() {
        const { search, searchList, pageData, devices } = this.state;
        const { strings } = this.props;

        const outputDevices = search ? searchList : devices?.slice(pageData.start, pageData.end);

        return (
            <DetailsBoxContent
                style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}
            >
                <Row marginTop={24} marginBottom={24} justify="between" align="middle">
                    <EventsLayoutHeaderTitle>
                        <Text color="primary" variant="body">
                            {this.getHeaderText()}
                        </Text>
                        <ExpandableSearch
                            value={search}
                            onChange={(e) => this.setSearch(e.target.value)}
                            onSubmit={() => {}}
                            placeholder={strings("Найти")}
                            colorVariant="light"
                            inHeader
                        />
                    </EventsLayoutHeaderTitle>
                </Row>
                <DeviceLocationList devices={outputDevices} />
                <CommonTablePagination
                    tableData={devices}
                    setPaging={(pageData) => this.setState({ pageData })}
                    searchMode={search !== ""}
                />
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabDevices));

import React from "react";
import { permissionExists } from "../../../../contexts";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";
import { FilterAccordionStatic } from "../../../components/FiltersStatic";
import { GetUsersGroups } from "../../../../services/users";
import {
    Modal,
    ModalHeader,
    ModalSection,
    ModalFooter,
    ModalBody,
    TextField,
    Button,
    Select,
    Space,
    Row,
    Text,
} from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { PERSONAL_DESKTOP_TYPE, GROUP_DESKTOP_TYPE, SYSTEM_DESKTOP_TYPE } from "../constants";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { SUPER_USER_GROUP_ID } from "../../../../contexts/GeneralContext";
import { SelectedCounter } from "../../../components/SelectedCounter/SelectedCounter";

const types = [
    {
        label: "Персональный",
        value: PERSONAL_DESKTOP_TYPE,
        permission: "desktops.personal.lifeCycle",
    },
    {
        label: "Групповой",
        value: GROUP_DESKTOP_TYPE,
        permission: "desktops.group.lifeCycle",
    },
    {
        label: "Системный",
        value: SYSTEM_DESKTOP_TYPE,
        permission: "desktops.system.lifeCycle",
    },
];

class CreateDesktopModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            usersGroups: [],
            editAllGroupsAllowed: false,
            userGroup: null,
        };
    }

    componentDidMount = async () => {
        const { userInfo, strings } = this.props;
        if (permissionExists(userInfo, "users.userGroup.read")) {
            const [status, usersGroups] = await GetUsersGroups();
            if (!status) {
                this.props.enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
                return;
            }

            const isEditAllGroupsAllowed = permissionExists(userInfo, "desktops.groups.all.lifeCycle");

            this.setState({ usersGroups, isEditAllGroupsAllowed, userGroup: userInfo.groupId });
        }
    };

    validateDesktop = (desktop) => {
        const { strings } = this.props;

        if (!desktop) {
            this.props.enqueueSnackbar(strings("Передан пустой объект"), { variant: "error" });
            return false;
        }

        if (!desktop.name || desktop.name.length === 0) {
            this.props.enqueueSnackbar(strings("Имя рабочего стола не может быть пустым"), { variant: "error" });
            return false;
        }

        if (!desktop.type) {
            this.props.enqueueSnackbar(strings("Необходимо выбрать тип рабочего стола"), { variant: "error" });
            return false;
        }

        switch (desktop.type) {
            case GROUP_DESKTOP_TYPE:
                if (!desktop.entities || desktop.entities.length === 0) {
                    this.props.enqueueSnackbar(strings("Необходимо выбрать хотя бы одну группу пользователей"), {
                        variant: "error",
                    });
                    return false;
                }
                break;
            default:
                break;
        }

        return true;
    };

    filterGroupsByPermissions(group) {
        const { userGroup } = this.state;

        if (this.state.isEditAllGroupsAllowed || userGroup === SUPER_USER_GROUP_ID) {
            return true;
        }

        return userGroup === group.id;
    }

    render() {
        const { search, usersGroups } = this.state;
        const { userInfo, desktop, visible, CloseHandler, SetDesktopProperty, CreateHandler, strings } = this.props;
        if (!visible) {
            return null;
        }

        const groups =
            usersGroups
                ?.filter((ug) => this.filterGroupsByPermissions(ug))
                ?.filter((ug) => ug?.name?.toLowerCase().includes(search.toLowerCase())) ?? [];

        return (
            <Modal size="sm">
                <ModalHeader title={strings("Новый рабочий стол")} align="center" closeHandler={CloseHandler} />
                <ModalBody>
                    <ModalSection>
                        <TextField
                            label={strings("Название")}
                            name="title"
                            type="text"
                            value={desktop?.name}
                            onChange={(e) => SetDesktopProperty("name", e.target.value)}
                            colorVariant="light"
                            fullWidth
                            error={!desktop?.name ? strings("Поле не может быть пустым") : null}
                        />
                        <Space />
                        <Select
                            label={strings("Тип рабочего стола")}
                            placeholder={strings("Выбрать тип рабочего стола")}
                            value={desktop?.type}
                            onChange={(e) => SetDesktopProperty("type", e)}
                            options={types
                                .filter((t) => permissionExists(userInfo, t.permission))
                                .map((t) => {
                                    return {
                                        label: strings(t.label),
                                        permissions: t.permission,
                                        value: t.value,
                                    };
                                })}
                            fullWidth
                            error={!desktop?.type ? strings("Поле не может быть пустым") : null}
                        />
                        {desktop?.type === GROUP_DESKTOP_TYPE && permissionExists(userInfo, "users.userGroup.read") && (
                            <>
                                <Space />
                                <ModalFormItem>
                                    <Row justify="between" align="bottom" marginBottom={24}>
                                        <Text variant="body">{strings("Группы пользователей")}</Text>
                                        <SelectedCounter
                                            count={desktop?.entities?.length ?? 0}
                                            onClear={() => SetDesktopProperty("entities", [])}
                                            strings={strings}
                                        />
                                    </Row>
                                    <Row marginBottom={12}>
                                        <TextField
                                            autoComplete={false}
                                            colorVariant="light"
                                            fullWidth
                                            icon="search"
                                            onChange={(e) => this.setState({ search: e.target.value })}
                                            placeholder={strings("Найти")}
                                            type="search"
                                            value={search}
                                        />
                                    </Row>
                                    <FilterAccordionStatic
                                        key={!!search}
                                        preOpen={!!search}
                                        items={groups}
                                        selected={desktop?.entities ?? []}
                                        selectHandler={(e) => SetDesktopProperty("entities", e)}
                                    />
                                </ModalFormItem>
                            </>
                        )}
                        {desktop?.type === GROUP_DESKTOP_TYPE &&
                            !permissionExists(userInfo, "users.userGroup.read") && (
                                <>
                                    <Space />
                                    <ModalFormItem>
                                        <Row justify="between" align="bottom" marginBottom={24}>
                                            <Text variant="body" color="danger">
                                                {strings("Нет прав для просмотра групп пользователей!")}
                                            </Text>
                                        </Row>
                                    </ModalFormItem>
                                </>
                            )}
                    </ModalSection>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="primary"
                        label={strings("Сохранить")}
                        onClick={() => {
                            if (!this.validateDesktop(desktop)) {
                                return;
                            }
                            CreateHandler(desktop);
                        }}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(CreateDesktopModal)));

import React from "react";
import { ModalSidebar, WizardStep } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import { WIZARD_UPDATE_MODE } from "./constants";

export class WizardSteps extends React.Component {
    getWizardState = (thisStep) => {
        let wizardState = "default";

        if (this.props.mode && this.props.mode === WIZARD_UPDATE_MODE) {
            if (this.props.activeStep === thisStep) {
                wizardState = "done";
            } else {
                wizardState = "current-active";
            }
        } else {
            if (thisStep < this.props.step) {
                wizardState = "done";
            }

            if (thisStep < this.props.step && this.props.activeStep === thisStep) {
                wizardState = "done-active";
            }

            if (thisStep === this.props.step) {
                wizardState = "current";
            }

            if (thisStep === this.props.step && this.props.activeStep === thisStep) {
                wizardState = "current-active";
            }
        }

        return wizardState;
    };

    clickHandler = (index) => {
        if (this.props.mode && this.props.mode === WIZARD_UPDATE_MODE) {
            this.props.SetActiveStep(index + 1);
        } else if (this.props.step >= index + 1) {
            this.props.SetActiveStep(index + 1);
        }
    };

    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <ModalSidebar>
                        {this.props.steps
                            ? this.props.steps.map((modalStep, i) => (
                                  <WizardStep
                                      key={modalStep.name}
                                      bgVariant="dark"
                                      count={i + 1}
                                      currentState={this.getWizardState(i + 1)}
                                      label={strings(modalStep.name)}
                                      onClick={() => this.clickHandler(i)}
                                  />
                              ))
                            : null}
                    </ModalSidebar>
                )}
            </CultureContextConsumer>
        );
    }
}

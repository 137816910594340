const path = "/rtsp-quality-api/rules/";

export const GetRules = async () => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const CreateRule = async (rule) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(path, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(rule),
    });

    return [response.status === 200, response.status];
};

export const DeleteRule = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    let result = null;
    try {
        result = await response.json();
    } catch {
        // ignore
    }

    return [response.status === 200, response.status, result?.code];
};

export const UpdateRule = async (id, rule) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(rule),
    });

    return [response.status === 200, response.status];
};

import React, { Component } from "react";
import styled from "styled-components";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Icon,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Text,
    Tooltip,
    Pagination,
    ExpandableSearch,
} from "headpoint-react-components";
import {
    CancelArchiveOrder,
    CountArchiveOrders,
    GetArchiveOrders,
    GetArchiveOrder,
} from "../../../services/screenshotArchive";
import { withSnackbar } from "notistack";
import { CheckArchiveFile, GetArchiveFile, GetFileMetadata } from "../../../services/storage";
import { anyPermissionExists } from "../../../contexts/GeneralContext";
import moment from "moment/moment";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

const ORDERS_ON_PAGE = 10;

class ArchiveOrderListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countOnPage: ORDERS_ON_PAGE,
            archiveOrders: [],
            count: 0,
            currentPage: 1,
            search: "",
            selectedArchiveRecord: null,
            selectedCameraName: null,
        };
    }

    async componentDidMount() {
        const [count, archiveOrders] = await this.getOrdersAndCount();
        this.setState({ archiveOrders, count });
    }

    async getOrdersAndCount(pageNumber, limit, searchString) {
        const { strings } = this.props;

        const [countStatus, count] = await CountArchiveOrders(searchString);
        const [orderStatus, archiveOrders] = await GetArchiveOrders(
            pageNumber ?? 1,
            limit ?? ORDERS_ON_PAGE,
            searchString
        );

        if (!countStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить количество заказов"), { variant: "error" });
        }

        if (!orderStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить очередь заказов"), { variant: "error" });
        }

        return [count, archiveOrders];
    }

    handleShowMore = async () => {
        let { countOnPage, search } = this.state;
        const [count, archiveOrders] = await this.getOrdersAndCount(1, countOnPage + ORDERS_ON_PAGE, search);

        this.setState({
            countOnPage: countOnPage + ORDERS_ON_PAGE,
            archiveOrders: archiveOrders,
            count,
            currentPage: 1,
        });
    };

    handlePage = async (page) => {
        const { currentPage, countOnPage, search } = this.state;

        if (currentPage === page) {
            return;
        }

        const [count, orders] = await this.getOrdersAndCount(page, countOnPage, search);

        this.setState({ currentPage: page, archiveOrders: orders, count });
    };

    handleRefresh = async () => {
        const [count, archiveOrders] = await this.getOrdersAndCount();

        this.setState({ archiveOrders, count, currentPage: 1, countOnPage: ORDERS_ON_PAGE });
    };

    async handleDownload(archiveOrder) {
        const fileUrl = archiveOrder?.properties?.archiveUrl;
        const fileName = this.buildFileName(archiveOrder);

        if (!fileUrl) {
            this.props.enqueueSnackbar(`Не удалось получить ссылку для загрузки файла '${fileName}'`, {
                variant: "error",
            });
            return;
        }

        const fileExists = await CheckArchiveFile(fileUrl);
        if (!fileExists) {
            this.props.enqueueSnackbar(`Файл '${fileName}' не найден`, { variant: "error" });
            return;
        }

        const [fileMetadataStatus, fileMetadata] = await GetFileMetadata(archiveOrder.id);
        if (!fileMetadataStatus) {
            this.props.enqueueSnackbar(`Не удалось получить metadata файла '${fileName}'`, { variant: "error" });
            return;
        }

        const fullFileName = this.buildFullFileName(archiveOrder, fileMetadata);
        await GetArchiveFile(fileUrl, fullFileName);
        this.props.enqueueSnackbar(`Загрузка файла '${fileName}' запущена`, { variant: "success" });
    }

    buildFullFileName(archiveOrder, fileMetadata) {
        const fileName = this.buildFileName(archiveOrder);
        const extension = this.getFileExtension(fileMetadata);
        return fileName + extension;
    }

    buildFileName(archiveOrder) {
        const timeFrom = moment(archiveOrder?.from).local().format("YYYY-MM-DDHH:mm:ss");
        const timeTo = moment(archiveOrder?.to).local().format("YYYY-MM-DDHH:mm:ss");
        return `order-${archiveOrder.orderNumber}_${timeFrom} - ${timeTo}`;
    }

    getFileExtension(fileMetadata) {
        const pointIndex = fileMetadata?.fileName?.lastIndexOf(".") ?? -1;
        if (pointIndex >= 0 && pointIndex < fileMetadata?.fileName?.length - 1) {
            return fileMetadata.fileName.substring(pointIndex);
        }

        return ".unk";
    }

    async handleCancel(orderId) {
        console.log(orderId);
        let { archiveOrders } = this.state;

        const [status, code] = await CancelArchiveOrder(orderId);

        if (!status && code === 404) {
            const [orderReceived, archiveOrder] = await GetArchiveOrder(orderId);

            if (orderReceived) {
                const index = archiveOrders.indexOf((at) => at.id === orderId);
                archiveOrders[index] = archiveOrder;

                this.setState({ archiveOrders });
            }
        }
    }

    async setSearch(search) {
        const [count, archiveOrders] = await this.getOrdersAndCount(1, ORDERS_ON_PAGE, search);

        this.setState({
            countOnPage: ORDERS_ON_PAGE,
            archiveOrders: archiveOrders,
            count,
            currentPage: 1,
            search,
        });
    }

    getArchiveOrderStatusAndColor(orderStatus) {
        const { strings } = this.props;

        let statusColor;
        let status = orderStatus ?? "Unknown";

        switch (status.toLowerCase()) {
            case "error":
                status = strings("Ошибка");
                statusColor = "danger";
                break;
            case "inprogress":
                status = strings("Подготовка");
                statusColor = "warning";
                break;
            case "done":
                status = strings("Готово");
                statusColor = "success";
                break;
            case "cancelled":
                status = strings("Отменен");
                statusColor = "secondary";
                break;
            default:
                statusColor = "secondary";
                break;
        }
        return [status, statusColor];
    }

    formatDate(date) {
        return moment(date).local().format("YYYY-MM-DD HH:mm:ss");
    }

    formatPeriod(from, to) {
        return `${this.formatDate(from)} — ${this.formatDate(to)}`;
    }

    formatOrderNumber = (row) => `${row.orderNumber}${row.orderSubnumber ? `-${row.orderSubnumber}` : ""}`;

    translateError(message) {
        const { strings } = this.props;

        if (message?.match("Не удалось выгрузить файл .+")) {
            const split = message.split(" ");
            return `${strings("Не удалось выгрузить файл")} ${split[4]} ${strings("камеры")} ${split[6]} ${strings(
                "из сервиса"
            )} ${split[9]}`;
        }

        if (message?.match("Не удалось загрузить файл .+")) {
            const split = message.split(" ");
            return `${strings("Не удалось загрузить файл")} ${split[4]} ${strings("камеры")} ${split[6]} ${strings(
                "на конечное хранилище"
            )}`;
        }

        if (message?.match("Не удалось авторизоваться на устройстве .+")) {
            const split = message.split(" ");
            return `${strings("Не удалось авторизоваться на устройстве")} ${split[5]}`;
        }

        return message;
    }

    render() {
        const { archiveOrders, currentPage, count, countOnPage, search } = this.state;
        const { strings } = this.props;

        const { userInfo } = this.props;

        const countPages = Math.ceil(count / countOnPage) ?? 0;
        let pagesOffset = Math.min(currentPage > 3 ? currentPage - 3 : 0, countPages - 5);
        pagesOffset < 0 && (pagesOffset = 0);

        return (
            <>
                <Modal size="xlg" closeHandler={this.handleClose}>
                    <ModalHeader
                        title={strings("История заказов фотоизображений")}
                        closeHandler={this.props.handleClose}
                        actions={
                            <>
                                <ModalHeaderActionItem>
                                    <ExpandableSearch
                                        value={search}
                                        onChange={(e) => this.setSearch(e.target.value)}
                                        onSubmit={() => {}}
                                        placeholder={strings("Найти")}
                                        colorVariant="dark"
                                    />
                                </ModalHeaderActionItem>

                                <ModalHeaderActionItem>
                                    <Button variant="secondary" icon="refresh" onClick={this.handleRefresh} />
                                </ModalHeaderActionItem>
                            </>
                        }
                    />

                    <ModalBody>
                        <ModalTableWrapper>
                            <Table hasHover stickyHeader bgColor="light">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCell>№</TableHeadCell>
                                        <TableHeadCell>{strings("Дата заказа")}</TableHeadCell>
                                        <TableHeadCell>{strings("Период")}</TableHeadCell>
                                        <TableHeadCell align="center">{strings("Причина")}</TableHeadCell>
                                        <TableHeadCell align="left">{strings("Статус")}</TableHeadCell>
                                        <TableHeadCell align="left">{strings("Действие")}</TableHeadCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {archiveOrders?.map((row) => {
                                        const isComplete = row.status.toLowerCase() === "done";
                                        const inProgress = row.status.toLowerCase() === "inprogress";
                                        const [status, statusColor] = this.getArchiveOrderStatusAndColor(row?.status);

                                        return (
                                            <TableRow key={this.formatOrderNumber(row)}>
                                                <TableCell>{this.formatOrderNumber(row)}</TableCell>

                                                <TableCell>
                                                    <Text
                                                        color={isComplete ? "primary" : "secondary"}
                                                        variant="body-sm"
                                                    >
                                                        {moment(row?.orderDate).local().format("YYYY-MM-DD")}
                                                    </Text>
                                                    <br />
                                                    <Text color="secondary" variant="description">
                                                        {moment(row?.orderDate).local().format("HH:mm:ss")}
                                                    </Text>
                                                </TableCell>

                                                <TableCell>
                                                    <Text
                                                        color={isComplete ? "primary" : "secondary"}
                                                        variant="body-sm"
                                                    >
                                                        {this.formatPeriod(row?.from, row?.to)}
                                                    </Text>
                                                </TableCell>

                                                <TableCell align="center" verticalAlign="middle">
                                                    {row.reason && (
                                                        <Tooltip
                                                            tooltip={
                                                                <span
                                                                    style={{
                                                                        display: "block",
                                                                        wordWrap: "break-word",
                                                                        width: 160,
                                                                    }}
                                                                >
                                                                    {row.reason}
                                                                </span>
                                                            }
                                                        >
                                                            <Icon icon="comment-full" size={16} />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>

                                                <TableCell align="left" verticalAlign="middle">
                                                    {row.status.toLowerCase() === "error" ? (
                                                        row.status && (
                                                            <Tooltip
                                                                tooltip={
                                                                    this.translateError(row.properties?.errorMessage) ??
                                                                    ""
                                                                }
                                                            >
                                                                <Text color={statusColor} variant="body-sm">
                                                                    {status}
                                                                </Text>
                                                            </Tooltip>
                                                        )
                                                    ) : (
                                                        <Text color={statusColor} variant="body-sm">
                                                            {status}
                                                        </Text>
                                                    )}
                                                </TableCell>

                                                <TableCell align="left" verticalAlign="middle">
                                                    {inProgress && (
                                                        <Button
                                                            variant="ghost"
                                                            label={strings("Отменить")}
                                                            onClick={() => this.handleCancel(row.id)}
                                                        />
                                                    )}
                                                    {isComplete &&
                                                        anyPermissionExists(userInfo, [
                                                            "screenshot.archive.view.personal",
                                                            "screenshot.archive.view.group",
                                                            "screenshot.archive.view.system",
                                                        ]) && (
                                                            <Button
                                                                variant="secondary"
                                                                label={strings("Скачать")}
                                                                icon="download"
                                                                onClick={() => this.handleDownload(row)}
                                                            />
                                                        )}
                                                    {row.isCanceled && (
                                                        <Button variant="ghost" label={strings("Отменен")} disabled />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </ModalTableWrapper>
                    </ModalBody>

                    <ArchiveOrderListModalFooter>
                        <Button variant="secondary" label={strings("Показать ещё")} onClick={this.handleShowMore} />

                        <Pagination
                            offset={pagesOffset}
                            page={currentPage}
                            count={countPages}
                            onChange={(page) => this.handlePage(page)}
                        />
                    </ArchiveOrderListModalFooter>
                </Modal>
            </>
        );
    }
}

const ModalHeaderActionItem = styled.div`
    margin-right: 8px;
`;

const ModalTableWrapper = styled.div`
    width: 100%;
    padding: 16px 24px;
`;

const ArchiveOrderListModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 32px 24px;
`;

export default withCultureContext(withSnackbar(ArchiveOrderListModal));

import React from "react";
import { GetLocations, FillLocations } from "../../../../../../services/locations";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { FilterAccordion } from "../../../../../components/Filters";
import { ModalSection, TextField, Space, Text } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class ModalStepLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
        };
    }

    openLocation = async (locationNode) => {
        const { strings } = this.props;
        const { devicesLocationsTree } = this.state;

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        locationNode.children = FillLocations(children, []);

        this.setState({
            devicesLocationsTree: devicesLocationsTree,
        });
    };

    async componentDidMount() {
        const [, locations] = await GetLocations();
        this.setState({
            devicesLocationsTree: FillLocations(locations, []),
        });
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const [, locations] = await GetLocations();
            this.setState({
                devicesLocationsTree: FillLocations(locations, []),
            });
        }
    };

    undefinedIfEmpty = (val) => {
        if (typeof val === "string" && !val) {
            return undefined;
        }

        return val;
    };

    render() {
        const { values, strings } = this.props;

        return (
            <>
                <ModalSection>
                    <ModalFormItem>
                        <Text>{strings("Родительская локация:")}</Text>
                        <Space />
                        <FilterAccordion
                            items={this.state.devicesLocationsTree}
                            selected={values.location ? [values.location] : []}
                            openItem={this.openLocation}
                            selectHandler={(items) => {
                                let item = items.pop();
                                this.props.SetValuesProperty("location", item);
                            }}
                        />
                    </ModalFormItem>
                </ModalSection>
                <ModalSection>
                    <ModalFormItem>
                        <TextField
                            label={strings("Широта")}
                            name="latitude"
                            type="number"
                            value={values.latitude}
                            onChange={(e) =>
                                this.props.SetValuesProperty("latitude", this.undefinedIfEmpty(e.target.value))
                            }
                            colorVariant="light"
                            error={
                                (values.latitude && isNaN(values.latitude)) ||
                                values.latitude < -90 ||
                                values.latitude > 90
                                    ? strings("Значение должно быть числом в диапазоне от -90 до 90")
                                    : null
                            }
                            width={180}
                        />
                        <Space />
                        <TextField
                            label={strings("Долгота")}
                            name="longitude"
                            type="number"
                            value={values.longitude}
                            onChange={(e) =>
                                this.props.SetValuesProperty("longitude", this.undefinedIfEmpty(e.target.value))
                            }
                            colorVariant="light"
                            error={
                                (values.longitude && isNaN(values.longitude)) ||
                                values.longitude < -180 ||
                                values.longitude > 180
                                    ? strings("Значение должно быть числом от -180 до 180")
                                    : null
                            }
                            width={180}
                        />
                    </ModalFormItem>
                </ModalSection>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(ModalStepLocation)));

import React from "react";
import { Text, Table, TableHead, TableHeadCell, TableBody, TableRow, TableCell, Row } from "headpoint-react-components";

import { DetailsBoxContent } from "../../../components/DetailsBox";
import { formatAdapterSettingsEventCount } from "../../../../utilites/TextUtils";
import { eventImportance } from "./constants";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabEventTypes extends React.Component {
    render() {
        const { selectedAdapter } = this.props;
        const eventTypesCount = selectedAdapter.eventTypes ? selectedAdapter.eventTypes.length : 0;
        return (
           <CultureContextConsumer>{({strings})=> (<DetailsBoxContent>
               <Row marginTop={24} marginBottom={16}>
                   <Text variant="title" component="h3">
                       {eventTypesCount} {strings(formatAdapterSettingsEventCount(eventTypesCount))} {strings("адаптера")}
                   </Text>
               </Row>

               <Table noPadding>
                   <TableHead>
                       <TableRow>
                           <TableHeadCell>{strings("ID")}</TableHeadCell>
                           <TableHeadCell>{strings("события адаптера")}</TableHeadCell>
                           <TableHeadCell>{strings("важность")}</TableHeadCell>
                       </TableRow>
                   </TableHead>

                   <TableBody>
                       {selectedAdapter.eventTypes.map((item) => {
                           const eventSeverity = eventImportance.find((ei) => ei.code === item.severity);
                           return (
                               <TableRow key={item.code}>
                                   <TableCell>{item.code}</TableCell>

                                   <TableCell>
                                       <Text color="primary" variant="body-sm">
                                           {strings(item.name)}
                                       </Text>
                                   </TableCell>

                                   <TableCell>
                                       <Text color={eventSeverity?.color ?? "primary"} variant="body-sm">
                                           {strings(eventSeverity?.label ?? "Обычное")}
                                       </Text>
                                   </TableCell>
                               </TableRow>
                           );
                       })}
                   </TableBody>
               </Table>
           </DetailsBoxContent>)}</CultureContextConsumer>
        );
    }
}

const path = "/license-api/license";

export const IsLicenseValid = async () => {
    try {
        let response = await fetch("/license/validate", {
            method: "GET",
        });

        return response.status !== 402;
    } catch (ex) {
        console.error(ex);
        return false;
    }
};

export const GetActiveLicenses = async () => {
    let response = await fetch(`${path}/entities`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, undefined];
    }
    try {
        let result = await response.json();

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, undefined];
    }
};

export const GetLicenseInfo = async () => {
    let response = await fetch(`${path}/info`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, undefined];
    }
    try {
        let licenseInfo = await response.json();

        let entities = licenseInfo.licenseData.entities;

        const licenseEntities = Object.keys(entities).map((key) => ({
            licenseCode: key,
            count: entities[key],
        }));

        const license = {
            id: licenseInfo?.id,
            expiryDate: licenseInfo?.expiryDate,
            sections: licenseInfo?.licenseData?.sections,
            entities: licenseEntities,
            client: licenseInfo?.licenseData?.info?.client,
        };

        return [true, license];
    } catch (ex) {
        console.error(ex);

        return [false, undefined];
    }
};

export const UploadLicense = async (file) => {
    if (!file) {
        return;
    }

    let formData = new FormData();
    formData.append(file.name, file);

    let response = await fetch(`${path}/upload`, {
        method: "POST",
        body: formData,
    });

    try {
        switch (response.status) {
            case 200:
                return { text: "Лицензия успешно загружена", variant: "success" };
            case 403:
                return { text: "Невалидный файл лицензии", variant: "error" };
            default:
                return { text: "Не удалось загрузить файл лицензии", variant: "error" };
        }
    } catch (ex) {
        console.error(ex);
    }
};

export const findMatchingCode = (sectionCode, entityCodes) => {
    let maxMatchCount = -1;
    let bestMatchingCode = null;

    for (let entityCode of entityCodes) {
        if (containsAllCodes(sectionCode, entityCode)) {
            let matchCount = getMatchingCount(sectionCode, entityCode);

            if (matchCount > maxMatchCount) {
                maxMatchCount = matchCount;
                bestMatchingCode = entityCode;
            }
        }
    }

    return bestMatchingCode;
};

const containsAllCodes = (sectionCode, entityCode) => {
    const sectionCodes = sectionCode.split(".");
    const entityWords = entityCode.split(".");
    for (let code of sectionCodes) {
        if (code !== "*" && !entityWords.includes(code)) {
            return false;
        }
    }
    return true;
};

const getMatchingCount = (sectionCode, entityCode) => {
    const sectionCodes = sectionCode.split(".");
    const entityCodes = entityCode.split(".");

    let matchingCount = 0;

    for (let i = 0; i < Math.min(sectionCodes.length, entityCodes.length); i++) {
        if (sectionCodes[i] === "*") {
            matchingCount++;
        } else if (sectionCodes[i] === entityCodes[i]) {
            matchingCount += 2;
        } else if (sectionCodes[i] !== "*") {
            matchingCount = 0;
            break;
        }
    }

    return matchingCount;
};

import React from "react";
import { CameraLayoutWrapper, CameraLayoutRow, CameraLayoutCol } from "../../components/CameraLayout";
import { nanoid } from "nanoid";
import {
    VideoControlBtn,
    VideoControlsDivider,
    VideoControlsMenu,
    VideoControlsMenuItem,
    VideoControlsMenuTitle,
    VideoControlsTimecode,
    VideoWrapper,
} from "headpoint-react-components";
import ScreenshotPlayerWrapper from "./ScreenshotPlayerWrapper";
import ProgressWrapper from "../../components/ProgressWrapper";
import moment from "moment";
import {
    findNearestLeftNumber,
    findNearestNumber,
    findNextValue,
    findPreviousValue,
} from "../../../utilites/ArrayUtils";
import { connect } from "react-redux";
import { saveOffsetTime, saveDeviceOffset, saveDeviceMode } from "../../../app/reducers/galleryReducer";
import { getSystemVariableValue } from "../../../contexts";
import { withGeneralContext } from "../../../contexts/GeneralContext";

const SCALE_OPTIONS = [0.5, 1, 3, 8];
const DIRECTION_SCALE_OPTIONS = [
    { label: "Вперед", value: 1 },
    { label: "Назад", value: -1 },
];
const MODE_OPTIONS = [
    { label: "По времени", value: false },
    { label: "По камерам", value: true },
];

class CameraFrames extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timerId: undefined,
            scale: 1,
            scaleDirection: 1,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevScreenshots = prevProps.screenshotsInfo;
        const screenshots = this.props.screenshotsInfo;
        const { gallery: { offsetTime } } = this.props;

        if (screenshots.length !== prevScreenshots.length) {
            const offsets = screenshots.map((sf) => sf.offset);

            if (!offsets.find((offset) => offset === offsetTime)) {
                const nearestLeftValue = findNearestLeftNumber(offsetTime, offsets);
                if (nearestLeftValue >= 0) {
                    this.props.dispatch(saveOffsetTime(nearestLeftValue));
                }
            }
        }
    }

    handleOffset = (offset) => {
        this.props.dispatch(saveOffsetTime(offset));
    };

    handleCameraFrameSelect = (cameraId) => {
        const nextValue = this.props.selectedCameraFrame === cameraId ? null : cameraId;
        this.props.setSelectedCameraFrame(nextValue);
    };

    handleCameraFrameClear = (frameId) => {
        const nextFrames = { ...this.props.frames };
        delete nextFrames[frameId];
        this.props.setFrames(nextFrames);
    };

    handleNextTimelineMode = () => {
        const {
            screenshotsInfo,
            gallery: { offsetTime },
        } = this.props;
        const { timerId } = this.state;

        const offsets = screenshotsInfo.map((sf) => sf.offset);

        const nextOffset = findNextValue(offsets, offsetTime);

        if (nextOffset) {
            this.props.dispatch(saveOffsetTime(nextOffset));
        } else {
            if (timerId) {
                clearInterval(timerId);
                this.setState({ timerId: undefined });
            }
        }
    };

    handleNextDeviceMode = () => {
        const { timerId } = this.state;
        const deviceOffset = this.props.gallery.deviceOffset + this.props.framesCount;

        if (deviceOffset < Object.keys(this.props.frames).length) {
            this.props.dispatch(saveDeviceOffset(deviceOffset));
        } else if (timerId) {
            clearInterval(timerId);
            this.setState({ timerId: undefined });
        }
    };

    startPlay = () => {
        const { scale, scaleDirection } = this.state;
        const {
            gallery: { deviceMode },
        } = this.props;

        if (scaleDirection > 0) {
            const timer = setInterval(() => {
                deviceMode ? this.handleNextDeviceMode() : this.handleNextTimelineMode();
            }, 1000 * scale);

            this.setState({ timerId: timer });
        } else {
            const timer = setInterval(() => {
                deviceMode ? this.handlePreviousDeviceMode() : this.handlePreviousTimelineMode();
            }, 1000 * scale);

            this.setState({ timerId: timer });
        }
    };

    stopPlay = () => {
        const { timerId } = this.state;

        if (timerId) {
            clearInterval(timerId);
            this.setState({ timerId: undefined });
        }
    };

    handlePreviousTimelineMode = () => {
        const {
            screenshotsInfo,
            gallery: { offsetTime },
        } = this.props;
        const { timerId } = this.state;

        const offsets = screenshotsInfo.map((sf) => sf.offset);

        const nextOffset = findPreviousValue(offsets, offsetTime);

        if (nextOffset !== null && nextOffset !== undefined) {
            this.props.dispatch(saveOffsetTime(nextOffset));
        } else {
            if (timerId) {
                clearInterval(timerId);
                this.setState({ timerId: undefined });
            }
        }
    };

    handlePreviousDeviceMode = () => {
        const { timerId } = this.state;
        const deviceOffset = this.props.gallery.deviceOffset - this.props.framesCount;

        if (deviceOffset > -this.props.framesCount) {
            this.props.dispatch(saveDeviceOffset(deviceOffset));
        } else if (timerId) {
            clearInterval(timerId);
            this.setState({ timerId: undefined });
        }
    };

    handleCurrentLayoutFrame = () => {
        const {
            screenshotsInfo,
            gallery: { deviceOffset, offsetTime },
        } = this.props;

        return (
            <>
                {this.props.currentLayout === "1" && (
                    <CameraLayoutRow>
                        <ScreenshotPlayerWrapper
                            handleOffset={() => {}}
                            offsetTime={offsetTime}
                            screenshotsInfo={screenshotsInfo}
                            key={this.props.frames[deviceOffset]?.id ?? nanoid()}
                            frames={this.props.frames}
                            showCameraInfo={this.props.showCameraInfo}
                            frameIndex={deviceOffset}
                            selectedCameraFrame={this.props.selectedCameraFrame}
                            handleCameraFrameSelect={this.handleCameraFrameSelect}
                            handleCameraFrameClear={this.handleCameraFrameClear}
                        />
                    </CameraLayoutRow>
                )}

                {this.props.currentLayout === "2" && (
                    <CameraLayoutRow>
                        {[0, 1].map((i) => (
                            <ScreenshotPlayerWrapper
                                handleOffset={this.handleOffset}
                                offsetTime={offsetTime}
                                screenshotsInfo={screenshotsInfo}
                                key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                frames={this.props.frames}
                                showCameraInfo={this.props.showCameraInfo}
                                frameIndex={deviceOffset + i}
                                selectedCameraFrame={this.props.selectedCameraFrame}
                                handleCameraFrameSelect={this.handleCameraFrameSelect}
                                handleCameraFrameClear={this.handleCameraFrameClear}
                            />
                        ))}
                    </CameraLayoutRow>
                )}

                {this.props.currentLayout === "4" && (
                    <>
                        <CameraLayoutRow rowHeight="50%">
                            {[0, 1].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="50%">
                            {[2, 3].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "1-5" && (
                    <>
                        <CameraLayoutRow rowHeight="66.66%">
                            <ScreenshotPlayerWrapper
                                handleOffset={this.handleOffset}
                                offsetTime={offsetTime}
                                screenshotsInfo={screenshotsInfo}
                                key={this.props.frames[deviceOffset]?.id ?? nanoid()}
                                frames={this.props.frames}
                                showCameraInfo={this.props.showCameraInfo}
                                frameIndex={deviceOffset}
                                selectedCameraFrame={this.props.selectedCameraFrame}
                                handleCameraFrameSelect={this.handleCameraFrameSelect}
                                handleCameraFrameClear={this.handleCameraFrameClear}
                            />

                            <CameraLayoutCol colWidth="33.33%">
                                {[1, 2].map((i) => (
                                    <ScreenshotPlayerWrapper
                                        handleOffset={this.handleOffset}
                                        offsetTime={offsetTime}
                                        screenshotsInfo={screenshotsInfo}
                                        key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                        frames={this.props.frames}
                                        showCameraInfo={this.props.showCameraInfo}
                                        frameIndex={deviceOffset + i}
                                        selectedCameraFrame={this.props.selectedCameraFrame}
                                        handleCameraFrameSelect={this.handleCameraFrameSelect}
                                        handleCameraFrameClear={this.handleCameraFrameClear}
                                    />
                                ))}
                            </CameraLayoutCol>
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="33.33%">
                            {[3, 4, 5].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "1-7" && (
                    <>
                        <CameraLayoutRow rowHeight="75%">
                            <ScreenshotPlayerWrapper
                                handleOffset={this.handleOffset}
                                offsetTime={offsetTime}
                                screenshotsInfo={screenshotsInfo}
                                key={this.props.frames[deviceOffset]?.id ?? nanoid()}
                                frames={this.props.frames}
                                showCameraInfo={this.props.showCameraInfo}
                                frameIndex={deviceOffset}
                                selectedCameraFrame={this.props.selectedCameraFrame}
                                handleCameraFrameSelect={this.handleCameraFrameSelect}
                                handleCameraFrameClear={this.handleCameraFrameClear}
                            />

                            <CameraLayoutCol colWidth="25%">
                                {[1, 2, 3].map((i) => (
                                    <ScreenshotPlayerWrapper
                                        handleOffset={this.handleOffset}
                                        offsetTime={offsetTime}
                                        screenshotsInfo={screenshotsInfo}
                                        key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                        frames={this.props.frames}
                                        showCameraInfo={this.props.showCameraInfo}
                                        frameIndex={deviceOffset + i}
                                        selectedCameraFrame={this.props.selectedCameraFrame}
                                        handleCameraFrameSelect={this.handleCameraFrameSelect}
                                        handleCameraFrameClear={this.handleCameraFrameClear}
                                    />
                                ))}
                            </CameraLayoutCol>
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[4, 5, 6, 7].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "16" && (
                    <>
                        <CameraLayoutRow rowHeight="25%">
                            {[0, 1, 2, 3].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[4, 5, 6, 7].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[8, 9, 10, 11].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[12, 13, 14, 15].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "32" && (
                    <>
                        <CameraLayoutRow rowHeight="12.5%">
                            {[0, 1, 2, 3].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[4, 5, 6, 7].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[8, 9, 10, 11].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[12, 13, 14, 15].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[16, 17, 18, 19].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[20, 21, 22, 23].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>
                        <CameraLayoutRow rowHeight="12.5%">
                            {[24, 25, 26, 27].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[28, 29, 30, 31].map((i) => (
                                <ScreenshotPlayerWrapper
                                    handleOffset={this.handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    key={this.props.frames[deviceOffset + i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={deviceOffset + i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}
            </>
        );
    };

    seek = (value, isFinal) => {
        const { screenshotsInfo } = this.props;

        if (isFinal) {
            const nearestValue = findNearestNumber(
                value,
                screenshotsInfo.map((info) => info.offset)
            );

            this.props.dispatch(saveOffsetTime(nearestValue));
        }
    };

    setScale = (scale) => {
        this.setState({ scale }, () => {
            this.stopPlay();
            this.startPlay();
        });
    };

    setScaleDirection = (direction) => {
        this.setState({ scaleDirection: direction }, () => {
            this.stopPlay();
            this.startPlay();
        });
    };

    setMode = (mode) => {
        this.props.dispatch(saveDeviceMode(mode));
    };

    render() {
        const { timerId, scale, scaleDirection } = this.state;
        const {
            selectedScheduleId,
            screenshotsInfo,
            strings,
            gallery: { deviceMode, offsetTime },
        } = this.props;
        const screenshotTimestamps = screenshotsInfo.map((info) => moment(info.captureTimestamp).unix());

        const from = Math.min(...screenshotTimestamps);
        const to = Math.max(...screenshotTimestamps);

        const detailInterval = getSystemVariableValue(
            this.props.generalInfo,
            "screenshot.service",
            "screenshot.interval.timeline",
            15
        );

        const marks = screenshotTimestamps.map((st) => {
            const duration = to - from;
            const offset = st - from;
            return {
                id: nanoid(),
                position: (100.0 * offset) / duration,
                data: [
                    {
                        label: strings("Дата"),
                        value: `${moment(new Date(st * 1000)).format("DD.MM.YYYY HH:mm:ss")}`,
                    },
                ],
            };
        });

        let currentTime = undefined;

        if (from !== Infinity) {
            const date = new Date((from + offsetTime) * 1000);
            currentTime = `${moment(date).format("DD.MM.YYYY HH:mm:ss")}`;
        }

        return (
            <CameraLayoutWrapper>
                <VideoWrapper
                    videoElement={this.handleCurrentLayoutFrame()}
                    progress={
                        screenshotsInfo.length ? (
                            <ProgressWrapper
                                rangeStart={from}
                                rangeEnd={to}
                                position={offsetTime}
                                seek={this.seek}
                                marks={marks}
                                setOffset={(offset) => offset}
                                detailInterval={detailInterval}
                            />
                        ) : (
                            <></>
                        )
                    }
                    mainControls={
                        screenshotsInfo.length ? (
                            <>
                                <VideoControlBtn
                                    icon="arrow-left"
                                    onClick={
                                        deviceMode ? this.handlePreviousDeviceMode : this.handlePreviousTimelineMode
                                    }
                                    title="Prev"
                                />
                                {timerId ? (
                                    <VideoControlBtn
                                        title={strings("Пауза")}
                                        icon="pause"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.stopPlay();
                                        }}
                                    />
                                ) : (
                                    <VideoControlBtn
                                        title={strings("Начать")}
                                        icon="play"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.startPlay();
                                        }}
                                    />
                                )}
                                <VideoControlBtn
                                    icon="arrow-right"
                                    title="Next"
                                    onClick={deviceMode ? this.handleNextDeviceMode : this.handleNextTimelineMode}
                                />
                                <VideoControlsDivider />

                                <VideoControlsTimecode text={currentTime} />
                            </>
                        ) : (
                            <></>
                        )
                    }
                    toolbox={
                        screenshotsInfo.length ? (
                            <>
                                <VideoControlsMenu align="right" icon="settings" toggleTitle={strings("Настройки")}>
                                    {selectedScheduleId ? (
                                        <>
                                            <VideoControlsMenuTitle title={strings("Режим")} />
                                            {MODE_OPTIONS.map((opt, id) => {
                                                return (
                                                    <VideoControlsMenuItem
                                                        key={id}
                                                        onClick={() => this.setMode(opt.value)}
                                                        label={strings(opt.label)}
                                                        isSelected={opt.value === deviceMode}
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <VideoControlsMenuTitle title={strings("интервал")} />
                                    {SCALE_OPTIONS.map((opt, id) => {
                                        return (
                                            <VideoControlsMenuItem
                                                key={id}
                                                onClick={() => this.setScale(opt)}
                                                label={`${opt.toString()} ${strings("с")}`}
                                                isSelected={opt === scale}
                                            />
                                        );
                                    })}
                                    <VideoControlsMenuTitle title={strings("Направление")} />
                                    {DIRECTION_SCALE_OPTIONS.map((opt, id) => {
                                        return (
                                            <VideoControlsMenuItem
                                                key={id}
                                                onClick={() => this.setScaleDirection(opt.value)}
                                                label={strings(opt.label)}
                                                isSelected={opt.value === scaleDirection}
                                            />
                                        );
                                    })}
                                </VideoControlsMenu>
                            </>
                        ) : (
                            <></>
                        )
                    }
                    withFullscreen={!!screenshotsInfo.length}
                    alwaysShowControls
                />
            </CameraLayoutWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        gallery: state.gallery,
    };
};

export default connect(mapStateToProps, null)(withGeneralContext(CameraFrames));

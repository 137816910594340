import React from "react";
import StreamPlayer from "./StreamPlayer";
import { CameraLayoutItem, CameraPlaceholder } from "../../components/CameraLayout";
import { GetLocationList } from "../../../services/locations";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { withGeneralContext } from "../../../contexts/GeneralContext";

class StreamPlayerWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFrameLocationName: undefined,
        };
    }

    componentDidMount() {
        const currentFrame = this.props.frames[this.props.frameIndex];
        if (currentFrame) {
            this.getLocationById(currentFrame.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }

    getLocationById = async (locationId) => {
        if (!locationId) {
            return;
        }

        let [locationsStatus, locations] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locations?.length) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        return locations?.[0];
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevSelectedFrame = prevProps.frames[prevProps.frameIndex];
        const selectedFrame = this.props.frames[this.props.frameIndex];

        if (prevSelectedFrame?.id !== selectedFrame?.id) {
            this.getLocationById(selectedFrame?.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }
    render() {
        const { strings, generalInfo } = this.props;
        return (
            <>
                <CameraLayoutItem
                    showCameraInfo={this.props.showCameraInfo}
                    isSelected={this.props.selectedCameraFrame === this.props.frameIndex}
                    onSelect={() => this.props.handleCameraFrameSelect(this.props.frameIndex)}
                    onClear={() => this.props.handleCameraFrameClear(this.props.frameIndex)}
                    hasItem={this.props.frames[this.props.frameIndex] && this.props.frames[this.props.frameIndex].id}
                    label={
                        this.props.frames[this.props.frameIndex] &&
                        this.props.frames[this.props.frameIndex].name &&
                        this.props.frames[this.props.frameIndex].name
                    }
                    locationName={this.state.selectedFrameLocationName}
                    placeholderLabel={strings("Камера не выбрана")}
                    selectedPlaceholderLabel={strings("Выберите камеру")}
                >
                    {this.props.frames[this.props.frameIndex] && this.props.frames[this.props.frameIndex].id ? (
                        <StreamPlayer
                            presets={this.props.presets}
                            frameIndex={this.props.frameIndex}
                            frames={this.props.frames}
                            generalInfo={generalInfo}
                        />
                    ) : (
                        <CameraPlaceholder type="stream" />
                    )}
                </CameraLayoutItem>
            </>
        );
    }
}

export default withCultureContext(withGeneralContext(StreamPlayerWrapper));

import React from "react";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import {
    Table,
    TableHead,
    TableHeadCell,
    TableRow,
    Text,
    TableBody,
    TableCell,
    Space,
    TableHoverContainer,
    DeviceIcon,
    TableAccordionItem,
    Button,
} from "headpoint-react-components";
import { isStringEmpty } from "../../../../utilites/TextUtils";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class UsersTable extends React.Component {
    GroupActions = (userGroup) => {
        return (
            <TableHoverContainer>
                <Button
                    icon="info"
                    title="Info"
                    variant="ghost"
                    onClick={() => this.props.selectedGroupHandle(userGroup)}
                />
            </TableHoverContainer>
        );
    };

    RowActions = (user) => (
        <TableHoverContainer>
            <Button icon="info" title="Info" variant="ghost" onClick={() => this.props.selectUserHandle(user)} />
        </TableHoverContainer>
    );

    getUserRoleName = (roleId) => {
        if (!this.props.availableRoles || !roleId) {
            return "";
        }

        return this.props.availableRoles.find((role) => role.id === roleId)?.name ?? "";
    };

    getFullName(user) {
        const userProps = user.properties;

        let fullName = [];

        if (userProps?.surname?.trim()) {
            fullName.push(userProps.surname.trim());
        }

        if (userProps?.name?.trim()) {
            fullName.push(userProps.name.trim());
        }

        if (userProps?.patronymic?.trim()) {
            fullName.push(userProps.patronymic.trim());
        }

        return fullName.join(" ");
    }

    getFullNameOrLogin(user) {
        const fullName = this.getFullName(user);

        if (isStringEmpty(fullName)) {
            return user.login;
        }

        return fullName;
    }

    render() {
        const { defaultClosed, tableData } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("группы и пользователи")}</TableHeadCell>
                                    <TableHeadCell>{strings("телефон")}</TableHeadCell>
                                    <TableHeadCell>{strings("роль")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {tableData?.map((userGroup) => (
                                    <TableAccordionItem
                                        defaultClosed={defaultClosed}
                                        key={`Group-${userGroup?.id}`}
                                        toggle={
                                            <>
                                                <TableCell verticalAlign="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        {userGroup?.name ?? ""}
                                                    </Text>
                                                </TableCell>
                                                <TableCell />
                                                <TableCell />
                                                <TableCell
                                                    onClick={(e) => e.stopPropagation()}
                                                    verticalAlign="middle"
                                                    noVerticalSpace
                                                >
                                                    {this.GroupActions(userGroup)}
                                                </TableCell>
                                            </>
                                        }
                                    >
                                        {userGroup.users?.map((user) => (
                                            <TableRow
                                                key={`User-${user.id}`}
                                                isSelected={
                                                    this.props.selectedUserId && user.id === this.props.selectedUserId
                                                }
                                            >
                                                <TableCell>
                                                    <Text color={user.statusCode} variant="body-sm">
                                                        <DeviceIcon icon="user" size={16} />
                                                    </Text>
                                                    <Space isDouble />
                                                    <Text color="primary" variant="body-sm">
                                                        {this.getFullNameOrLogin(user) ?? ""}
                                                    </Text>
                                                </TableCell>
                                                <TableCell verticalAlign="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        {user?.properties?.phone ?? ""}
                                                    </Text>
                                                </TableCell>
                                                <TableCell verticalAlign="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        {this.getUserRoleName(user?.roleId) ?? ""}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    onClick={(e) => e.stopPropagation()}
                                                    verticalAlign="middle"
                                                    noVerticalSpace
                                                >
                                                    {this.RowActions(user)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableAccordionItem>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { SHOW_STREAM_PLAYER_DIALOG_PREFIX, CRUD_PRESETS_TOPIC_PREFIX } from "../streamTopics";
import PubSub from "pubsub-js";
import { UpdatePreset } from "../../../../services/presets";
import { withSnackbar } from "notistack";
import styled from "styled-components";
import { Modal, Button, ModalBody, Row, Text } from "headpoint-react-components";
import { PTZ_FEATURE_CODE } from "../constants";
import { getServiceFeature } from "../../../../contexts/GeneralContext";
import { ExecuteCommand } from "../../../../services/featureCommands";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

const ModalStepWrapper = styled.div`
    flex: 1;
`;

const ModalButtonsContainer = styled.div`
    position: absolute;
    float: right;
    display: flex;
    align-items: center;
`;

const ModalButton = styled.div`
    display: flex;
    margin: 5px;
`;

const ModalFooter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 96px;
    border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 24px 24px;
`;

const ModalSection = styled.div`
    width: 100%;
    padding: 24px 24px;

    & + & {
        border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    }
`;

const TextWrapper = styled.div`
    overflow-wrap: anywhere;
`;

class UpdatePresetModal extends React.Component {
    constructor(props) {
        super(props);

        const {
            data: { preset },
        } = this.props;

        this.state = {
            preset: { ...preset },
            loaded: false,
        };
    }

    componentDidMount = async () => {
        await this.getPosition();
        this.setState({
            loaded: true,
        });
    };

    getPosition = async () => {
        const {
            generalInfo,
            enqueueSnackbar,
            data: { camera },
            strings,
        } = this.props;
        const { preset } = this.state;
        const featureSettings = camera?.properties?.settings?.features;
        const ptzFeatureSettings = featureSettings ? featureSettings[PTZ_FEATURE_CODE] : undefined;
        if (!ptzFeatureSettings) {
            enqueueSnackbar(strings("Отсутствуют настройки PTZ"), { variant: "error" });
            return;
        }

        const ptzFeature = getServiceFeature(generalInfo, ptzFeatureSettings.serviceCode, PTZ_FEATURE_CODE);

        if (!ptzFeature) {
            enqueueSnackbar(
                `${strings("Не удалось найти сервис с кодом")} ${ptzFeatureSettings.serviceCode} ${strings(
                    "и фичей"
                )} ${PTZ_FEATURE_CODE}`,
                { variant: "error" }
            );
            return;
        }

        let parameters = { id: camera.id };
        let commandCode = "getPosition";
        const command = ptzFeature?.commands?.list.find((c) => c.code === commandCode);

        if (command && command.path && command.method) {
            const [success, , data] = await ExecuteCommand(
                command?.method,
                ptzFeatureSettings.serviceCode,
                PTZ_FEATURE_CODE,
                command?.path,
                parameters
            );
            if (!success) {
                enqueueSnackbar(strings("Невозможно получить позицию камеры"), { variant: "error" });
            } else {
                this.setState({ preset: { ...preset, ...data } });
            }
        } else {
            enqueueSnackbar(`${strings("Команда")} ${commandCode} ${strings("отсутствует у адаптера")}`, {
                variant: "error",
            });
        }
    };

    dtoFromPreset(values) {
        let dto = { ...values };

        delete dto.pan;
        delete dto.tilt;
        delete dto.zoom;
        delete dto.id;

        dto.properties = {
            ptz: {
                pan: values.pan,
                tilt: values.tilt,
                zoom: values.zoom,
            },
        };

        return dto;
    }

    saveHandler = async (preset) => {
        const {
            enqueueSnackbar,
            data: { camera },
            strings,
        } = this.props;

        const dto = this.dtoFromPreset(preset);
        const [success, code] = await UpdatePreset(dto, preset.id, camera.id);
        if (success) {
            enqueueSnackbar(strings("Пресет сохранен"), { variant: "success" });
        } else {
            switch (code) {
                case 403: // forbiden
                    enqueueSnackbar(strings("Не хватает прав для сохранения пресета"), { variant: "warning" });
                    break;

                case 409: // conflict
                    enqueueSnackbar(strings("В одном пространстве должны быть пресеты с разными именами"), {
                        variant: "warning",
                    });
                    break;

                default:
                    enqueueSnackbar(strings("Не удалось сохранить пресет"), { variant: "error" });
                    break;
            }

            return;
        }

        PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + camera?.id, undefined);
        PubSub.publish(CRUD_PRESETS_TOPIC_PREFIX + camera?.id, {
            type: "update",
            data: {
                preset,
            },
        });
    };

    render() {
        const { CloseHandler, strings } = this.props;
        const { preset, loaded } = this.state;

        if (!loaded) {
            return <LoaderAnimation message={strings("Загрузка текущей позиции камеры...")} />;
        }

        return (
            <>
                <Modal size="sm" closeHandler={CloseHandler}>
                    <ModalStepWrapper>
                        <ModalBody>
                            <ModalSection>
                                <ModalFormItem>
                                    <Row justify="left" align="bottom">
                                        <TextWrapper>
                                            <Text variant="body" color="primary" align={"left"}>
                                                {`${strings("Сохранить изменения для пресета")} «${preset.name}»?`}
                                            </Text>
                                        </TextWrapper>
                                    </Row>
                                </ModalFormItem>
                            </ModalSection>
                        </ModalBody>
                        <ModalFooter>
                            <ModalButtonsContainer>
                                <ModalButton>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        label={strings("Да, сохранить")}
                                        width="150px"
                                        onClick={() => {
                                            this.saveHandler(preset);
                                        }}
                                    />
                                </ModalButton>
                                <ModalButton>
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        label={strings("Отмена")}
                                        width="80px"
                                        display="right"
                                        onClick={CloseHandler}
                                    />
                                </ModalButton>
                            </ModalButtonsContainer>
                        </ModalFooter>
                    </ModalStepWrapper>
                </Modal>
            </>
        );
    }
}

export default withCultureContext(withSnackbar(UpdatePresetModal));

import React from "react";
import { SignOut } from "../../services/authentication";
import { pages } from "./pages";
import { GeneralContextConsumer, permissionExists, serviceExists } from "../../contexts";
import {
    Navbar as NavbarWrapper,
    NavbarLogobox,
    NavbarLinks,
    NavbarToolbox,
    Tab,
    Dropdown,
    DropdownItem,
    Logo,
    Button,
} from "headpoint-react-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../contexts/cultureContext/CultureContext";

class Navbar extends React.Component {
    LogOut() {
        SignOut();
        window.location = "/";
    }

    onSelected = (url) => {
        const partitionsCloseTimeStr = window.localStorage.getItem("partitionsCloseTime");
        let partitionsCloseTime = JSON.parse(partitionsCloseTimeStr ?? "{}");
        partitionsCloseTime = { ...partitionsCloseTime, [window.location.pathname]: moment().utc() };
        window.localStorage.setItem("partitionsCloseTime", JSON.stringify(partitionsCloseTime));

        this.props.history.replace(`${url}`);
    };

    render = () => {
        const { userInfo } = this.props;
        const { history } = this.props;
        let login = userInfo?.login ?? "username";

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <NavbarWrapper>
                        <NavbarLogobox>
                            <Logo />
                        </NavbarLogobox>

                        <GeneralContextConsumer>
                            {(generalInfo) => (
                                <NavbarLinks>
                                    {pages.map((page) =>
                                        serviceExists(generalInfo, page.serviceCodes) &&
                                        permissionExists(userInfo, page.permissions) ? (
                                            <Tab
                                                key={page.label}
                                                label={strings(page.label)}
                                                isActive={history.location.pathname === `${page.url}`}
                                                counter={page.count}
                                                as="span"
                                                onClick={() => this.onSelected(page.url)}
                                            />
                                        ) : null
                                    )}
                                </NavbarLinks>
                            )}
                        </GeneralContextConsumer>

                        <NavbarToolbox>
                            {permissionExists(userInfo, "settings.access") ? (
                                <Button
                                    icon="cog"
                                    type="button"
                                    variant={history.location.pathname === "/settings" ? "secondary-fill" : "ghost"}
                                    title={strings("Настройки")}
                                    onClick={() => this.onSelected("/settings")}
                                />
                            ) : (
                                <div />
                            )}
                            <Dropdown
                                toggleVariant="ghost"
                                toggleLabel={login}
                                verticalAlignment="bottom"
                                horizontalAlignment="right"
                                isInline
                                closeOnClickInside
                            >
                                <DropdownItem as="button" tabIndex="0" colorVariant="danger" onClick={this.LogOut}>
                                    {strings("Выйти")}
                                </DropdownItem>
                            </Dropdown>
                        </NavbarToolbox>
                    </NavbarWrapper>
                )}
            </CultureContextConsumer>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withRouter(Navbar));

import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { TextField } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

export class ModalFormStringProperty extends React.Component {
    render() {
        const { itemId, handleAddParameters, description, name, type } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <ModalFormItem>
                        <TextField
                            label={description.en}
                            placeholder={strings("Введите текст")}
                            name={name}
                            type={type}
                            value={this.props.values.functions.find((x) => x.id === itemId).parameters?.[name] ?? ""}
                            onChange={(e) => handleAddParameters(itemId, name, e.target.value)}
                            colorVariant="light"
                            fullWidth
                        />
                    </ModalFormItem>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { TextField } from "headpoint-react-components";
import { nanoid } from "nanoid";
import { GetDeviceInfo, GetDevices, SearchDevices } from "../../../services/devices";
import { GetLocations, FillNode, GetLocationList, FillLocations } from "../../../services/locations";
import { FilterGroup, FilterGroupList, FilterAccordion } from "../Filters";
import PubSub from "pubsub-js";
import { LoaderAnimation } from "../LoaderAnimation/LoaderAnimation";

const DEVICES_LIMIT = 100;
const LOCATIONS_LIMIT = 100;

export class DeviceLocationFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceSearch: "",
            locations: [],
            devices: [],
            sourcesTree: [],
            treeLoading: false,
        };
    }

    updateTree(tree) {
        tree?.forEach((node) => {
            node.accordionId = nanoid();
            if (node.children) {
                this.updateTree(node.children);
            }
        });
    }

    componentDidMount = async () => {
        const { strings } = this.props;

        if (this.props.cmdChannelTopic) {
            this.cmdChannel = PubSub.subscribe(this.props.cmdChannelTopic, async (msg, data) => {
                await this.processFilterCmd(data);
            });
        }

        const [locationsStatus, locations] = await GetLocations();
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        const sourcesTree = FillLocations(locations, []);
        this.setState({
            locations: locations ?? [],
            sourcesTree: sourcesTree ?? [],
            devices: [],
        });
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            let { setDeviceLocationFilter } = this.props;

            const [locationsStatus, locations] = await GetLocations();
            if (!locationsStatus) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
            }

            const sourcesTree = FillLocations(locations, []);

            setDeviceLocationFilter([], [], []);

            this.setState({
                locations: locations ?? [],
                sourcesTree: sourcesTree ?? [],
            });
        }
    };

    componentWillUnmount() {
        if (this.cmdChannel) {
            PubSub.unsubscribe(this.cmdChannel);
        }
    }

    processFilterCmd = async (data) => {
        switch (data.type) {
            case "FilterBySingleDevice":
                await this.cmdFilterBySingleDevice(data.data);
                break;
            case "FilterBySingleLocation":
                await this.cmdFilterBySingleLocation(data.data);
                break;
            case "FilterByDevices":
                await this.cmdFilterByDevices(data.data);
                break;
            case "FilterByLocations":
                await this.cmdFilterByLocations(data.data);
                break;
            default:
                this.props.enqueueSnackbar(`Неизвестная операция ${data.type}`, { variant: "error" });
                break;
        }
    };

    cmdFilterBySingleLocation = async (data) => {
        const { strings } = this.props;
        this.setState({ treeLoading: true });

        try {
            if (!data || !data.location) {
                this.props.enqueueSnackbar(strings(`Неверные данные для cmdFilterBySingleLocation`), {
                    variant: "error",
                });
                return;
            }

            let { location } = data;

            let [locationsStatus, locationsDb] = await GetLocationList({ ids: [location.id] });
            if (!locationsStatus || !locationsDb?.length) {
                this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
                return;
            }

            const [locationDb] = locationsDb;

            let { sourcesTree, devices, locations } = this.state;

            let parentIds = [...locationDb.parentIds.slice(1)];

            let locationNode;

            let tree = sourcesTree;

            for (const parentId of parentIds) {
                locationNode = tree.find((i) => i.id === parentId);

                if (!locationNode) {
                    this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                        variant: "error",
                    });
                    return;
                }

                locationNode.preOpen = true;

                ({ sourcesTree, devices, locations } = await this.loadLocation(
                    locationNode,
                    sourcesTree,
                    devices,
                    locations
                ));

                tree = locationNode.children;
            }

            location =
                locationNode?.children?.find((d) => d.id === location.id) ??
                sourcesTree.find((d) => d.id === location.id);

            if (!location) {
                this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${location.id}`, {
                    variant: "error",
                });
            }

            this.setState(
                {
                    sourcesTree,
                    devices,
                    locations,
                },
                () => this.props.setDeviceLocationFilter([], location ? [location] : [])
            );
        } finally {
            this.setState({ treeLoading: false });
        }
    };

    cmdFilterBySingleDevice = async (data) => {
        this.setState({ treeLoading: true });
        const { strings } = this.props;

        try {
            if (!data || !data.device) {
                this.props.enqueueSnackbar(`${strings("Неверные данные для cmdFilterBySingleDevice")}`, {
                    variant: "error",
                });
                return;
            }

            let { device } = data;

            const [deviceStatus, devicesDb] = await GetDeviceInfo([device.id]);
            if (!deviceStatus || !devicesDb?.length) {
                this.props.enqueueSnackbar(strings("Не удалось получить устройство"), { variant: "error" });
                return;
            }

            const [deviceDb] = devicesDb;

            let [locationsStatus, locationsDb] = await GetLocationList({ ids: [deviceDb.locationId] });
            if (!locationsStatus || !locationsDb?.length) {
                this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
                return;
            }

            const [locationDb] = locationsDb;

            let { sourcesTree, devices, locations } = this.state;

            let parentIds = [...locationDb.parentIds.slice(1), deviceDb.locationId];

            let locationNode;

            let tree = sourcesTree;

            for (const parentId of parentIds) {
                locationNode = tree.find((i) => i.id === parentId);

                if (!locationNode) {
                    this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                        variant: "error",
                    });
                    return;
                }

                locationNode.preOpen = true;

                ({ sourcesTree, devices, locations } = await this.loadLocation(
                    locationNode,
                    sourcesTree,
                    devices,
                    locations
                ));

                tree = locationNode.children;
            }

            device = locationNode?.children?.find((d) => d.id === deviceDb.id);

            if (!device) {
                this.props.enqueueSnackbar(
                    `${strings("Не удалось найти устройство с идентификатором")} ${deviceDb.id}`,
                    {
                        variant: "error",
                    }
                );
            }

            this.setState(
                {
                    sourcesTree,
                    devices,
                    locations,
                },
                () => this.props.setDeviceLocationFilter(device ? [device] : [], [])
            );
        } finally {
            this.setState({ treeLoading: false });
        }
    };

    cmdFilterByDevices = async (devicesData) => {
        const { devicesVisibility } = this.props;

        this.setState({ treeLoading: true });

        try {
            if (!devicesData) {
                this.props.enqueueSnackbar(`Неверные данные для cmdFilterByDevices`, { variant: "error" });
                return;
            }

            let [locationsStatus, locationsDb] = await GetLocationList({ ids: devicesData.map((d) => d.locationId) });
            if (!locationsStatus || !locationsDb?.length) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
                return;
            }

            const locationDevices = [];

            for (const device of devicesData) {
                const locationInfo = locationsDb.find((d) => d.id === device.locationId);
                locationDevices.push({ device, location: locationInfo });
            }

            for (const locationDevice of locationDevices) {
                let { sourcesTree, devices, locations } = this.state;

                let parentIds = [...locationDevice.location.parentIds.slice(1), locationDevice.device.locationId];

                let locationNode;

                let tree = sourcesTree;

                for (const parentId of parentIds) {
                    locationNode = tree.find((i) => i.id === parentId);

                    if (!locationNode) {
                        this.props.enqueueSnackbar(`Не удалось найти локацию с идентификатором ${parentId}`, {
                            variant: "error",
                        });
                        return;
                    }

                    locationNode.preOpen = true;

                    ({ sourcesTree, devices, locations } = await this.loadLocation(
                        locationNode,
                        sourcesTree,
                        devices,
                        locations,
                        devicesVisibility
                    ));

                    tree = locationNode.children;
                }
            }
        } finally {
            this.setState({ treeLoading: false });
        }
    };

    cmdFilterByLocations = async (locationsData) => {
        const { devicesVisibility } = this.props;

        this.setState({ treeLoading: true });

        try {
            if (!locationsData) {
                this.props.enqueueSnackbar(`Неверные данные для cmdFilterBySingleLocation`, { variant: "error" });
                return;
            }

            let [locationsStatus, locationsDb] = await GetLocationList({ ids: locationsData.map((l) => l.id) });
            if (!locationsStatus || !locationsDb?.length) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
                return;
            }

            for (const locationDb of locationsDb) {
                let { sourcesTree, devices, locations } = this.state;

                let parentIds = [...locationDb.parentIds.slice(1)];

                let locationNode;

                let tree = sourcesTree;

                for (const parentId of parentIds) {
                    locationNode = tree.find((i) => i.id === parentId);

                    if (!locationNode) {
                        this.props.enqueueSnackbar(`Не удалось найти локацию с идентификатором ${parentId}`, {
                            variant: "error",
                        });
                        return;
                    }

                    locationNode.preOpen = true;

                    ({ sourcesTree, devices, locations } = await this.loadLocation(
                        locationNode,
                        sourcesTree,
                        devices,
                        locations,
                        devicesVisibility
                    ));

                    tree = locationNode.children;
                }
            }
        } finally {
            this.setState({ treeLoading: false });
        }
    };

    searchNodes = async (like) => {
        const { resetFilter, strings } = this.props;
        const { sourceSearch } = this.state;
        if (!like || !sourceSearch) {
            resetFilter();
        }

        let [locationsStatus, locations] = await GetLocationList({ like, limit: LOCATIONS_LIMIT });
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [devicesStatus, devices] = await SearchDevices({ like, limit: DEVICES_LIMIT });
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let nodes = locations?.map((l) => ({ ...l, tag: "location" })) ?? [];
        nodes = nodes.concat(devices?.map((d) => ({ ...d, tag: "device" })) ?? []);
        this.setState({ sourceSearch: like, searchList: nodes });
    };

    openLocation = async (locationNode) => {
        let { sourcesTree, devices, locations } = this.state;
        const { devicesVisibility } = this.props;

        ({ sourcesTree, devices, locations } = await this.loadLocation(
            locationNode,
            sourcesTree,
            devices,
            locations,
            devicesVisibility
        ));

        this.setState({
            sourcesTree,
            devices,
            locations,
        });
    };

    loadLocation = async (locationNode, sourcesTree, devices, locations, devicesVisibility) => {
        const { strings } = this.props;

        if (locationNode.children?.length > 0) {
            return { sourcesTree, devices, locations };
        }

        let [getDevicesStatus, newDevices] = await GetDevices(locationNode.id);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return { sourcesTree, devices, locations };
        }

        if (devicesVisibility) {
            newDevices = newDevices.filter((d) => devicesVisibility.includes(d.id));
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return { sourcesTree, devices, locations };
        }

        FillNode(locationNode, children, newDevices);

        locations = locations ?? [];
        children?.forEach((l) => {
            if (!locations.includes(l)) {
                locations.push(l);
            }
        });

        newDevices.forEach((nd) => {
            if (devices.find((d) => d.id === nd.id)) {
                return;
            }

            devices.push(nd);
        });

        this.updateTree(sourcesTree);

        return { sourcesTree, devices, locations: locations ?? [] };
    };

    checkSelected = (item) => {
        const { devicesFilter, locationsFilter } = this.props;
        const { locations } = this.state;

        if (item.tag === "location") {
            if (locationsFilter.some((l) => l.id === item.id)) {
                return true;
            }

            return !!item.parentIds?.some((id) => locationsFilter.some((l) => l.id === id));
        } else {
            if (devicesFilter.some((d) => d.id === item.id)) {
                return true;
            }

            if (locationsFilter.some((l) => l.id === item.locationId)) {
                return true;
            }

            let location = locations.find((l) => l.id === item.locationId);
            return !!location?.parentIds?.some((id) => locationsFilter.some((l) => l.id === id));
        }
    };

    selectSearchNodeHandler = (item) => {
        let { devicesFilter, locationsFilter, setDeviceLocationFilter } = this.props;

        if (item?.tag === "location") {
            if (locationsFilter?.some((l) => l.id === item.id)) {
                locationsFilter = locationsFilter.filter((l) => l.id !== item.id);
            } else {
                locationsFilter = locationsFilter.concat(item);
            }

            setDeviceLocationFilter(
                devicesFilter,
                locationsFilter,
                this.getAllCheckedLocations(devicesFilter, locationsFilter)
            );
        } else {
            if (devicesFilter?.some((d) => d.id === item.id)) {
                devicesFilter = devicesFilter.filter((d) => d.id !== item.id);
            } else {
                devicesFilter = devicesFilter.concat(item);
            }

            setDeviceLocationFilter(
                devicesFilter,
                locationsFilter,
                this.getAllCheckedLocations(devicesFilter, locationsFilter)
            );
        }
    };

    selectNodeHandler = async (item) => {
        if (item?.tag === "location") {
            await this.selectLocation(item);
        } else {
            await this.selectDevice(item);
        }
    };

    getAllCheckedLocations = (devicesFilter, locationsFilter) => {
        const { sourcesTree } = this.state;
        const result = [];
        const getFromTree = (tree, locationsFilter, devicesFilter, result) => {
            if (!tree) {
                return;
            }

            tree.forEach((node) => {
                getFromTree(node.children, locationsFilter, devicesFilter, result);
                if (result.includes(node)) {
                    return;
                }

                if (locationsFilter.includes(node)) {
                    result.push(node);
                    return;
                }

                if (
                    node.children?.length &&
                    node.children.every(
                        (c) => locationsFilter.includes(c) || devicesFilter.includes(c) || result.includes(c)
                    )
                ) {
                    result.push(node);
                }
            });
        };

        getFromTree(sourcesTree, locationsFilter, devicesFilter, result);
        return result;
    };

    selectLocation = async (location) => {
        let { locationsFilter, devicesFilter, setDeviceLocationFilter } = this.props;
        const { locations } = this.state;
        if (this.checkSelected(location) && !locationsFilter.some((l) => l.id === location.id)) {
            this.uncheckSelectedLocation(location);
            return;
        }

        if (this.checkIndeterminate(location)) {
            locationsFilter = locationsFilter.filter((l) => !l.parentIds?.some((id) => id === location.id));
            devicesFilter = devicesFilter.filter((d) => {
                if (d.locationId === location.id) {
                    return false;
                }

                let parent = locations.find((l) => l.id === d.locationId);
                return !parent?.parentIds?.some((id) => location.id === id);
            });

            setDeviceLocationFilter(
                devicesFilter,
                locationsFilter,
                this.getAllCheckedLocations(devicesFilter, locationsFilter)
            );
            return;
        }

        if (locationsFilter?.some((l) => l.id === location.id)) {
            locationsFilter = locationsFilter.filter((l) => l.id !== location.id);
        } else {
            locationsFilter = locationsFilter.concat(location);
        }

        setDeviceLocationFilter(
            devicesFilter,
            locationsFilter,
            this.getAllCheckedLocations(devicesFilter, locationsFilter)
        );
    };

    uncheckSelectedLocation = (location) => {
        let { locationsFilter, devicesFilter, setDeviceLocationFilter } = this.props;
        const { locations } = this.state;

        const checkChildrenExcept = (location, exceptId) => {
            location.children
                ?.filter((c) => c.id !== exceptId)
                ?.forEach((c) => {
                    if (c.tag === "location") {
                        locationsFilter = locationsFilter.concat(c);
                    } else {
                        devicesFilter = devicesFilter.concat(c);
                    }
                });
        };

        const uncheckParentLocation = (location) => {
            if (locationsFilter.some((l) => l.id === location.parentId)) {
                const parent = locationsFilter.find((l) => l.id === location.parentId);
                locationsFilter = locationsFilter.filter((l) => l.id !== parent.id);
                checkChildrenExcept(parent, location.id);
                location = parent.children.find((c) => c.id === location.id);
                return location;
            } else {
                let parent = locations.find((l) => l.id === location.parentId);
                parent = uncheckParentLocation(parent);
                checkChildrenExcept(parent, location.id);
                location = parent.children.find((c) => c.id === location.id);

                return location;
            }
        };

        if (locationsFilter.some((l) => l.id === location.id)) {
            locationsFilter = locationsFilter.filter((l) => l.id !== location.id);
            setDeviceLocationFilter(
                devicesFilter,
                locationsFilter,
                this.getAllCheckedLocations(devicesFilter, locationsFilter)
            );
            return location;
        } else {
            const resLoc = uncheckParentLocation(location);
            setDeviceLocationFilter(
                devicesFilter,
                locationsFilter,
                this.getAllCheckedLocations(devicesFilter, locationsFilter)
            );
            return resLoc;
        }
    };

    uncheckDevice = (device) => {
        let { locationsFilter, devicesFilter, setDeviceLocationFilter } = this.props;
        let { locations } = this.state;

        if (locationsFilter?.some((l) => l.id === device.locationId)) {
            const location = locationsFilter.find((l) => l.id === device.locationId);
            locationsFilter = locationsFilter.filter((l) => l.id !== location.id);
            location?.children
                ?.filter((c) => c.id !== device.id)
                ?.forEach((c) => {
                    if (c.tag === "location") {
                        locationsFilter = locationsFilter.concat(c);
                    } else {
                        devicesFilter = devicesFilter.concat(c);
                    }
                });

            setDeviceLocationFilter(
                devicesFilter,
                locationsFilter,
                this.getAllCheckedLocations(devicesFilter, locationsFilter)
            );
            return;
        }

        let location = locations.find((l) => l.id === device.locationId);

        const checkChildrenExcept = (location, exceptId) => {
            location.children
                ?.filter((c) => c.id !== exceptId)
                ?.forEach((c) => {
                    if (c.tag === "location") {
                        locationsFilter = locationsFilter.concat(c);
                    } else {
                        devicesFilter = devicesFilter.concat(c);
                    }
                });
        };

        const uncheckParentLocation = (location) => {
            if (locationsFilter.some((l) => l.id === location.parentId)) {
                const parent = locationsFilter.find((l) => l.id === location.parentId);
                locationsFilter = locationsFilter.filter((l) => l.id !== parent.id);
                checkChildrenExcept(parent, location.id);
                location = parent.children.find((c) => c.id === location.id);
                return location;
            } else {
                let parent = locations.find((l) => l.id === location.parentId);
                parent = uncheckParentLocation(parent);
                checkChildrenExcept(parent, location.id);
                location = parent.children.find((c) => c.id === location.id);

                return location;
            }
        };

        uncheckParentLocation(location);
        setDeviceLocationFilter(
            devicesFilter,
            locationsFilter,
            this.getAllCheckedLocations(devicesFilter, locationsFilter)
        );
    };

    selectDevice = async (device) => {
        let { devicesFilter, setDeviceLocationFilter, locationsFilter } = this.props;

        if (this.checkSelected(device) && !devicesFilter.some((d) => d.id === device.id)) {
            this.uncheckDevice(device);
            return;
        }

        if (devicesFilter?.some((d) => d.id === device.id)) {
            devicesFilter = devicesFilter.filter((d) => d.id !== device.id);
        } else {
            devicesFilter = devicesFilter.concat(device);
        }

        setDeviceLocationFilter(
            devicesFilter,
            locationsFilter,
            this.getAllCheckedLocations(devicesFilter, locationsFilter)
        );
    };

    checkIndeterminate = (item) =>
        !this.checkSelected(item) && item.children?.some((c) => this.checkSelected(c) || this.checkIndeterminate(c));

    checkDrawSelected = (item) => {
        if (this.checkSelected(item)) {
            return true;
        }

        if (item.tag !== "location") {
            return false;
        }

        return (
            !!item?.children?.length &&
            item?.children?.every((c) => {
                return this.checkDrawSelected(c);
            })
        );
    };

    render() {
        const { locationsFilter, devicesFilter, resetFilter, strings } = this.props;
        const { sourceSearch, searchList, sourcesTree, treeLoading } = this.state;
        const sources = sourceSearch ? searchList : sourcesTree;
        const sourceFilter = locationsFilter.concat(devicesFilter).map((i) => i.id);

        return (
            <>
                {treeLoading && <LoaderAnimation message={strings("Загрузка дерева")} />}
                <FilterGroup
                    title={strings("Источник")}
                    hasValue={!!sourceFilter?.length}
                    onClear={() => {
                        this.setState({ sourceSearch: "" });
                        resetFilter();
                    }}
                    isMuted={!sourcesTree?.length}
                >
                    <TextField
                        colorVariant="light"
                        fullWidth
                        onChange={(e) => this.searchNodes(e.target?.value)}
                        placeholder={strings("Найти")}
                        type="text"
                        value={sourceSearch}
                    />
                    <FilterGroupList>
                        {!sourceSearch ? (
                            <FilterAccordion
                                items={sources ?? []}
                                selected={sourceFilter ?? []}
                                checkSelected={this.checkDrawSelected}
                                checkIndeterminate={(item) =>
                                    this.checkIndeterminate(item) && !this.checkDrawSelected(item)
                                }
                                selectHandler={(_, item) => this.selectNodeHandler(item)}
                                openItem={this.openLocation}
                                preOpen={false}
                            />
                        ) : (
                            <FilterAccordion
                                items={sources ?? []}
                                selected={sourceFilter ?? []}
                                checkSelected={({ id }) =>
                                    devicesFilter?.some((d) => d.id === id) || locationsFilter?.some((l) => l.id === id)
                                }
                                selectHandler={(_, item) => this.selectSearchNodeHandler(item)}
                            />
                        )}
                    </FilterGroupList>
                </FilterGroup>
            </>
        );
    }
}

import { Component } from "react";
import {
    GetAllControlZonesGroups,
    GetControlZones,
    SearchControlZones,
    fillNode,
    fillZoneGroups,
} from "../../../services/controlZones";
import { nanoid } from "nanoid";
import PubSub from "pubsub-js";
import { LoaderAnimation } from "../LoaderAnimation/LoaderAnimation";
import { FilterAccordion, FilterGroup, FilterGroupList } from "../Filters";
import { TextField } from "headpoint-react-components";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { withSnackbar } from "notistack";

export const GROUP_ZONE_FILTER = "filters.zones.load";

class GroupZoneFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceSearch: "",
            groups: [],
            zones: [],
            sourcesTree: [],
            treeLoading: false,
        };
    }

    updateTree(tree) {
        tree?.forEach((node) => {
            node.accordionId = nanoid();
            if (node.children) {
                this.updateTree(node.children);
            }
        });
    }

    componentDidMount = async () => {
        this.cmdChannel = PubSub.subscribe(GROUP_ZONE_FILTER, async (msg, data) => {
            await this.cmdFilterByZones(data.data);
        });

        const [zoneGroupsStatus, groups] = await GetAllControlZonesGroups();
        if (!zoneGroupsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения групп зон"), { variant: "error" });
        }

        const sourcesTree = fillZoneGroups(groups, []);

        this.setState({
            sourcesTree: sourcesTree ?? [],
            groups: groups ?? [],
            zones: [],
        });
    };

    componentWillUnmount = async () => {
        if (this.cmdChannel) {
            PubSub.unsubscribe(this.cmdChannel);
        }
    };

    searchNodes = async (like) => {
        const { strings } = this.props;

        const [zonesStatus, zones] = await SearchControlZones(like);
        if (!zonesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения зон"), { variant: "error" });
            return;
        }

        let nodes = zones?.map((z) => ({ ...z, tag: "zone" })) ?? [];
        this.setState({ sourceSearch: like, searchList: nodes });
    };

    openZoneGroup = async (groupNode, sourcesTree) => {
        const { zones } = this.state;
        const { strings } = this.props;

        const [getZonesStatus, newZones] = await GetControlZones(groupNode.id);
        if (!getZonesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения зон"), { variant: "error" });
            return;
        }

        fillNode(groupNode, newZones);

        groupNode.preOpen = true;

        newZones.forEach((nz) => {
            if (zones.find((z) => z.id === nz.id)) {
                return;
            }
            zones.push(nz);
        });

        this.updateTree(sourcesTree);

        this.setState({
            sourcesTree,
            zones,
        });
    };

    checkSelected = (item) => {
        const { zonesFilter, groupsFilter } = this.props;

        if (item.tag === "zoneGroup") {
            if (groupsFilter.some((g) => g.id === item.id)) {
                return true;
            }
        } else {
            if (zonesFilter.some((z) => z.id === item.id)) {
                return true;
            }

            if (groupsFilter.some((g) => g.id === item.groupId)) {
                return true;
            }
        }

        return false;
    };

    selectSearchNodeHandler = (item) => {
        let { zonesFilter, groupsFilter, setGroupZoneFilter } = this.props;

        if (item?.tag === "zoneGroup") {
            if (groupsFilter?.some((l) => l.id === item.id)) {
                groupsFilter = groupsFilter.filter((l) => l.id !== item.id);
            } else {
                groupsFilter = groupsFilter.concat(item);
            }

            setGroupZoneFilter(zonesFilter, groupsFilter);
        } else {
            if (zonesFilter?.some((d) => d.id === item.id)) {
                zonesFilter = zonesFilter.filter((d) => d.id !== item.id);
            } else {
                zonesFilter = zonesFilter.concat(item);
            }

            setGroupZoneFilter(zonesFilter, groupsFilter);
        }
    };

    selectNodeHandler = async (item) => {
        if (item?.tag === "zoneGroup") {
            await this.selectGroup(item);
        } else {
            await this.selectZone(item);
        }
    };

    selectGroup = async (group) => {
        let { groupsFilter, zonesFilter, setGroupZoneFilter, strings } = this.props;

        if (this.checkSelected(group) || this.checkIndeterminate(group)) {
            this.uncheckSelectedGroup(group);
            return;
        }

        const [getZonesStatus, newZones] = await GetControlZones(group.id);
        if (!getZonesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения зон"), { variant: "error" });
            return;
        }

        fillNode(group, newZones);

        groupsFilter = groupsFilter.concat(group);

        setGroupZoneFilter(zonesFilter, groupsFilter);
    };

    uncheckSelectedGroup = (group) => {
        let { groupsFilter, zonesFilter, setGroupZoneFilter } = this.props;

        const groups = groupsFilter.filter((g) => g.id !== group.id);
        const zones = zonesFilter.filter((z) => z.groupId !== group.id);

        setGroupZoneFilter(zones, groups);
    };

    uncheckDevice = (zone) => {
        let { groupsFilter, zonesFilter, setGroupZoneFilter } = this.props;

        if (groupsFilter?.some((g) => g.id === zone.groupId)) {
            const group = groupsFilter.find((g) => g.id === zone.groupId);
            groupsFilter = groupsFilter.filter((g) => g.id !== group.id);
            group?.children
                ?.filter((c) => c.id !== zone.id)
                ?.forEach((c) => {
                    zonesFilter = zonesFilter.concat(c);
                });
        } else {
            zonesFilter = zonesFilter.filter((z) => z.id !== zone.id);
        }
        setGroupZoneFilter(zonesFilter, groupsFilter);
    };

    selectZone = async (zone) => {
        let { zonesFilter, groupsFilter, setGroupZoneFilter } = this.props;

        if (this.checkSelected(zone)) {
            this.uncheckDevice(zone);
            return;
        }

        zonesFilter = zonesFilter.concat(zone);

        setGroupZoneFilter(zonesFilter, groupsFilter);
    };

    checkIndeterminate = (item) => !this.checkSelected(item) && item.children?.some((c) => this.checkSelected(c));

    checkDrawSelected = (item) => {
        if (this.checkSelected(item)) {
            return true;
        }

        if (item.tag !== "zoneGroup") {
            return false;
        }

        return (
            !!item?.children?.length &&
            item?.children?.every((c) => {
                return this.checkDrawSelected(c);
            })
        );
    };

    cmdFilterByZones = async (zones) => {
        const [zoneGroupsStatus, groups] = await GetAllControlZonesGroups();
        if (!zoneGroupsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения групп зон"), { variant: "error" });
        }

        const sourcesTree = fillZoneGroups(groups, []);

        const groupsFilter = groups.filter((g) => zones.map((z) => z.groupId).includes(g.id));

        for (const group of groupsFilter) {
            let groupNode = sourcesTree.find((i) => i.id === group.id);
            await this.openZoneGroup(groupNode, sourcesTree);
        }
    };

    render() {
        const { groupsFilter, zonesFilter, resetFilter, strings } = this.props;
        const { sourceSearch, searchList, sourcesTree, treeLoading } = this.state;
        const sources = sourceSearch ? searchList : sourcesTree;
        const sourceFilter = groupsFilter.concat(zonesFilter).map((i) => i.id);

        return (
            <>
                {treeLoading && <LoaderAnimation message={strings("Загрузка дерева")} />}
                <FilterGroup
                    title={strings("Зоны контроля")}
                    hasValue={!!sourceFilter?.length}
                    onClear={() => {
                        this.setState({ sourceSearch: "" });
                        resetFilter();
                    }}
                    isMuted={!sourcesTree?.length}
                >
                    <TextField
                        colorVariant="light"
                        fullWidth
                        onChange={(e) => this.searchNodes(e.target?.value)}
                        placeholder={strings("Найти")}
                        type="text"
                        value={sourceSearch}
                    />
                    <FilterGroupList>
                        {!sourceSearch ? (
                            <FilterAccordion
                                items={sources ?? []}
                                selected={sourceFilter ?? []}
                                checkSelected={this.checkDrawSelected}
                                checkIndeterminate={(item) =>
                                    this.checkIndeterminate(item) && !this.checkDrawSelected(item)
                                }
                                selectHandler={(_, item) => this.selectNodeHandler(item)}
                                openItem={(item) => {
                                    const { sourcesTree } = this.state;

                                    this.openZoneGroup(item, sourcesTree);
                                }}
                                preOpen={false}
                            />
                        ) : (
                            <FilterAccordion
                                items={sources ?? []}
                                selected={sourceFilter ?? []}
                                checkSelected={({ id }) =>
                                    zonesFilter?.some((d) => d.id === id) || groupsFilter?.some((l) => l.id === id)
                                }
                                selectHandler={(_, item) => this.selectSearchNodeHandler(item)}
                            />
                        )}
                    </FilterGroupList>
                </FilterGroup>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(GroupZoneFilter)));

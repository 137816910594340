import { Button } from "headpoint-react-components";
import React from "react";

const AddButton = ({ show, onClick }) => {
    if (!show) {
        return null;
    }

    return <Button style={{ marginLeft: "8px" }} icon="plus" variant="secondary" onClick={onClick} />;
};

export default AddButton;

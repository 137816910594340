import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetDetails, GetChart, GetBoundaries } from "../../services/reports";
import moment from "moment";

const initialState = {
    selectedRow: null,
    rowInfo: null,
    detailsTab: null,
    chartData: [],
    dates: {
        from: null,
        to: null,
    },
    chartSection: null,
};

export const fetchRow = createAsyncThunk(
    "reports/fetchRow",
    async ({ id, serviceCode, item, dates, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [status, details] = await GetDetails(id, serviceCode, item, dates);

        if (!status) {
            enqueueSnackbar(strings("Ошибка получения детального отчета"), { variant: "error" });
            return rejectWithValue();
        }

        return details;
    }
);

export const fetchChart = createAsyncThunk(
    "reports/fetchChart",
    async ({ id, serviceCode, item, dates, sectionType, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [status, details] = await GetChart(id, serviceCode, item, dates, sectionType);

        if (!status) {
            enqueueSnackbar(strings("Ошибка получения детального отчета"), { variant: "error" });
            return rejectWithValue();
        }

        if (details.length === 1) {
            details.push(details[0]);
        }

        return details;
    }
);

export const fetchBoundaries = createAsyncThunk(
    "reports/fetchBoundaries",
    async ({ id, serviceCode, item, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [status, boundaries] = await GetBoundaries(id, serviceCode, item);

        if (!status) {
            enqueueSnackbar(strings("Ошибка получения границ детального отчета"), { variant: "error" });
            return rejectWithValue();
        }

        return boundaries;
    }
);

export const reportSlice = createSlice({
    name: "reports",
    initialState: initialState,
    reducers: {
        saveReportState: (state, { payload }) => {
            for (const key in payload) {
                state[key] = payload[key];
            }
        },
        saveDates: (state, { payload }) => {
            state.dates = payload;
        },
        saveSection: (state, { payload }) => {
            state.chartSection = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRow.fulfilled, (state, action) => {
                state.rowInfo = action.payload;
            })
            .addCase(fetchRow.pending, (state) => {
                state.rowInfo = undefined;
            })
            .addCase(fetchChart.fulfilled, (state, action) => {
                state.chartData = action.payload.map((pl) => {
                    return { ...pl, period: moment.unix(pl.period).format("DD.MM.YYYY HH:mm") };
                });
            })
            .addCase(fetchChart.pending, (state) => {
                state.chartData = [];
            })
            .addCase(fetchBoundaries.fulfilled, (state, action) => {
                state.dates = {
                    from: moment.utc(action.payload.from)._d,
                    to: moment.utc(action.payload.to)._d,
                };
                state.chartData = [];
            });
    },
});

export const { saveReportState, saveDates, saveSection } = reportSlice.actions;

export default reportSlice.reducer;

import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { GeneralContextConsumer, serviceExists, permissionExists, defaultRoute } from "../contexts";
import LoginPage from "../features/loginpage/components/LoginPage";
import { pages } from "../layout/Navbar/pages";
import Core from "../core/components/Core";
import { connect } from "react-redux";

class Router extends React.Component {
    getPermissions(serviceCode) {
        return pages.find((p) => p.serviceCodes?.[0] === serviceCode)?.permissions ?? [];
    }

    render() {
        const { userInfo } = this.props;
        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <BrowserRouter>
                        <Switch>
                            {defaultRoute(generalInfo, userInfo) && (
                                <Redirect exact from="/" to={defaultRoute(generalInfo, userInfo)} />
                            )}
                            <Route path="/login">
                                <LoginPage />
                            </Route>
                            <Core generalInfo={generalInfo}>
                                {pages.map(
                                    (p) =>
                                        serviceExists(generalInfo, p.serviceCodes ?? []) &&
                                        permissionExists(userInfo, p.permissions ?? []) && (
                                            <PrivateRoute
                                                key={p.url}
                                                name={p.label}
                                                path={p.url}
                                                component={p.component}
                                            />
                                        )
                                )}
                            </Core>
                        </Switch>
                    </BrowserRouter>
                )}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(Router);

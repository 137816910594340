import { CreateUpdateWizard } from "../../../../../components/CreateUpdateWizard/CreateUpdateWizard";
import React, { useState } from "react";
import ModalStepDefault from "./steps/ModalStepDefault";
import ModalStepLocations from "../commonSteps/ModalStepLocations";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

const wizardSteps = [
    { name: "Общее", component: ModalStepDefault },
    { name: "Локации", component: ModalStepLocations },
];

export default function UsersGroupModal({
    mode,
    values,
    availableRoles,
    closeHandler,
    createHandler,
    editHandler,
    setValuesHandler,
}) {
    const [lastAvailableStep, setLastAvailableStep] = useState(mode === WIZARD_CREATE_MODE ? 1 : wizardSteps.length);
    const [activeStep, setActiveStep] = useState(1);

    const nextStep = () => {
        const nextStep = lastAvailableStep + 1;
        setLastAvailableStep(nextStep);
        setActiveStep(nextStep);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <CreateUpdateWizard
                    visible={true}
                    title={
                        mode === WIZARD_CREATE_MODE
                            ? strings("Создать группу пользователей")
                            : strings("Обновить группу пользователей")
                    }
                    Mode={mode}
                    values={values}
                    step={lastAvailableStep}
                    activeStep={activeStep}
                    NextStep={nextStep}
                    steps={wizardSteps}
                    SetActiveStep={setActiveStep}
                    CloseHandler={closeHandler}
                    CreateHandler={() => createHandler(values)}
                    EditHandler={editHandler}
                    SetValuesProperty={setValuesHandler}
                    setLocations={(items) => setValuesHandler("locations", items)}
                    availableRoles={availableRoles}
                />
            )}
        </CultureContextConsumer>
    );
}

import ModalStepDefault from "./modals/roles/ModalStepDefault";
import ModalStepGeneralPermissions from "./modals/roles/ModalStepGeneralPermissions";
import ModalStepDGPermissions from "./modals/roles/ModalStepDGPermissions";

export const wizardSteps = [
    { name: "Общее", component: ModalStepDefault },
    { name: "Функции системы", component: ModalStepGeneralPermissions },
    { name: "Группы оборудования", component: ModalStepDGPermissions },
];

export const devicesGroupsTypes = [
    { value: "fixed", label: "Фиксированная" },
    { value: "dynamic", label: "Динамическая" },
];

export const rolePriorities = [
    { value: 1, label: "Минимальный" },
    { value: 2, label: "Общий" },
    { value: 3, label: "Средний" },
    { value: 4, label: "Системный" },
    { value: 5, label: "Высокий" },
    { value: 6, label: "Высший" },
];

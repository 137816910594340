import React from "react";
import styled from "styled-components";
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalFooterItem,
    ModalBody,
    ModalBodyItem,
    TextField,
    Button,
    Icon,
    Text,
    Row,
    Select,
    Space,
    DateRangePicker,
} from "headpoint-react-components";
import { FillNode, GetLocations } from "../../../../services/locations";
import { GetDevices, SearchDevices } from "../../../../services/devices";
import { withSnackbar } from "notistack";
import {
    anyPermissionExists,
    getAdapters,
    getDeviceTypesByFeature,
    permissionExists,
} from "../../../../contexts/GeneralContext";
import moment from "moment/moment";
import { CreateArchiveOrdersBatch } from "../../../../services/screenshotArchive";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import CamerasList from "../../../archive/components/OrderArchiveModal/CamerasList/CamerasList";
import AddButton from "../../../archive/components/OrderArchiveModal/AddButton";
import CreatedOrdersListModal from "./CreatedOrdersListModal";
import CamerasTree from "../../../components/CamerasTree/CamerasTree";
import { GetScreenshotsDate } from "../../../../services/screenshot";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { GROUP_ORDER_TYPE, PERSONAL_ORDER_TYPE, SYSTEM_ORDER_TYPE } from "../../constants";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";
import { FilterAccordionStatic } from "../../../components/FiltersStatic";
import { GetUsersGroups } from "../../../../services/users";
import { SelectedCounter } from "../../../components/SelectedCounter/SelectedCounter";

class OrderArchiveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            filteredCameras: [],
            search: "",
            searchGroup: "",
            camerasCache: [],
            selectedCameras: [],
            eventsDate: [],
            showSelectedCameras: false,
            showAddCamerasTree: false,
            archiveRanges: {
                from: undefined,
                to: undefined,
            },
            reason: "",
            isLoading: false,
            orderNumber: null,
            type: null,
            usersGroups: [],
            groupIds: [],
            types: [],
        };
    }

    componentDidMount = async () => {
        let { types, usersGroups } = this.state;
        const { strings, enqueueSnackbar, userInfo } = this.props;

        if (permissionExists(userInfo, "screenshot.archive.edit.personal")) {
            types.push(PERSONAL_ORDER_TYPE);
        }

        if (anyPermissionExists(userInfo, ["screenshot.archive.edit.group.all", "screenshot.archive.edit.group"])) {
            types.push(GROUP_ORDER_TYPE);

            const [status, fetchedUserGroups] = await GetUsersGroups();

            if (!status) {
                enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
                return;
            }

            if (permissionExists(userInfo, "screenshot.archive.edit.group")) {
                usersGroups = usersGroups.concat(fetchedUserGroups.filter((group) => group.id === userInfo.groupId));
            }

            if (permissionExists(userInfo, "screenshot.archive.edit.group.all")) {
                usersGroups = usersGroups.concat(fetchedUserGroups.filter((group) => group.id !== userInfo.groupId));
            }
        }

        if (permissionExists(userInfo, "screenshot.archive.edit.system")) {
            types.push(SYSTEM_ORDER_TYPE);
        }

        this.setState({
            devicesLocationsTree: this.copy(this.props.devicesLocationsTree),
            camerasCache: [...this.props.selectedCameras],
            types,
            usersGroups,
        });

        await this.handleSelect(this.props.selectedCameras.map((camera) => camera.id));
    };

    handleSelect = async (cameraIds) => {
        const [screenshotsDatesStatus, screenshotsDates] = await GetScreenshotsDate(cameraIds);

        if (!screenshotsDatesStatus) {
            this.setState({ selectedCameras: cameraIds, eventsDate: [] });
            return;
        }

        const eventsDate = screenshotsDates?.map((time) => new Date(time));

        this.setState({ selectedCameras: cameraIds, eventsDate });
    };

    copy = (devicesLocationsTree) => {
        return JSON.parse(JSON.stringify(devicesLocationsTree));
    };

    selectCamera = (cameraId) => {
        const { selectedCameras, showSelectedCameras } = this.state;
        const cameraIndex = selectedCameras.indexOf(cameraId);

        if (cameraIndex >= 0) {
            selectedCameras.splice(cameraIndex, 1);
            const showCameras = showSelectedCameras && selectedCameras.length !== 0;

            this.setState({ selectedCameras, showSelectedCameras: showCameras });

            return;
        }

        this.setState({ selectedCameras: [...selectedCameras, cameraId] });
    };

    setDateRange(range) {
        const archiveRanges = {};
        archiveRanges.from = moment(range.from).seconds(0).milliseconds(0).toDate();

        archiveRanges.to = moment(range.to).seconds(0).milliseconds(0).toDate();

        this.setState({ archiveRanges });
    }

    validateOrderData() {
        const { selectedCameras, archiveRanges } = this.state;

        if (selectedCameras.length === 0) {
            this.props.enqueueSnackbar("Не выбрано ни одной камеры", { variant: "error" });
            return false;
        }

        if (!archiveRanges.from) {
            this.props.enqueueSnackbar("Укажите дату начала архива", { variant: "error" });
            return false;
        }

        if (!archiveRanges.to) {
            this.props.enqueueSnackbar("Укажите дату конца архива", { variant: "error" });
            return false;
        }

        if (archiveRanges.from > archiveRanges.to) {
            this.props.enqueueSnackbar("Дата начала не может быть позже даты конца архива", { variant: "error" });
            return false;
        }

        return true;
    }

    combineDataToArchiveOrders() {
        const { selectedCameras, archiveRanges, reason, type, groupIds } = this.state;

        const order = {
            deviceIds: selectedCameras,
            from: archiveRanges.from,
            to: archiveRanges.to,
            reason,
            type,
            groupIds,
        };

        return order;
    }

    sendArchiveOrders = async (archiveOrders) => {
        this.setState({ isLoading: true });

        try {
            const [status, statusCode, response] = await CreateArchiveOrdersBatch(archiveOrders);
            if (!status) {
                switch (statusCode) {
                    case 409:
                        this.props.enqueueSnackbar(`Нет подкюченных адаптеров для выгрузки архива камеры`, {
                            variant: "error",
                        });
                        return;
                    default:
                        this.props.enqueueSnackbar(`Не удалось создать заказ архивной записи камеры`, {
                            variant: "error",
                        });
                        return;
                }
            }

            this.setState({ orderNumber: response.orderNumber });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.validateOrderData()) {
            return;
        }

        const archiveOrders = this.combineDataToArchiveOrders();

        if (!archiveOrders || archiveOrders?.length === 0) {
            return;
        }

        this.sendArchiveOrders(archiveOrders);
    };

    openLocation = async (locationNode) => {
        const { devicesLocationsTree, camerasCache } = this.state;
        const { generalInfo } = this.props;

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
            return;
        }

        let adapters = getAdapters(generalInfo);

        if (!adapters) {
            this.props.enqueueSnackbar("Ошибка получения адаптеров", { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.archive");

        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.setState({ devicesLocationsTree, camerasCache });
            return;
        }

        const [getDevicesStatus, camerasFromLocation] = await GetDevices(locationNode.id, deviceTypeIds);

        if (!getDevicesStatus) {
            this.props.enqueueSnackbar("Ошибка получения устройств", { variant: "error" });
            return;
        }

        let newCameras = [
            ...camerasCache,
            ...camerasFromLocation.filter((c) => camerasCache.map((c) => c.id).includes(c.id) !== true),
        ];

        FillNode(locationNode, children, camerasFromLocation);

        this.setState({ devicesLocationsTree, camerasCache: newCameras });
    };

    async setSearch(search) {
        if (search && search !== "") {
            const { generalInfo } = this.props;
            let { camerasCache } = this.state;

            let adapters = getAdapters(generalInfo);

            if (!adapters) {
                this.props.enqueueSnackbar("Ошибка получения адаптеров", { variant: "error" });
                return;
            }

            let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.archive");

            if (!deviceTypeIds || deviceTypeIds.length === 0) {
                this.setState({ search, filteredCameras: [] });
                return;
            }

            let [devicesStatus, filteredCameras] = await SearchDevices({
                like: search,
                limit: 5,
                deviceTypes: deviceTypeIds,
            });

            if (!devicesStatus) {
                this.props.enqueueSnackbar("Ошибка получения устройств", { variant: "error" });
                return;
            }

            filteredCameras = filteredCameras.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });

            let newCameras = [];
            filteredCameras.forEach((camera) => {
                if (!camerasCache.some((c) => c.id === camera.id)) {
                    newCameras.push(camera);
                }
            });

            this.setState({ search, filteredCameras, camerasCache: [...camerasCache, ...newCameras] });
        } else {
            this.setState({ search, filteredCameras: [] });
        }
    }

    onSaveButtonClick = () => this.setState({ showAddCamerasTree: false });

    render() {
        const {
            reason,
            search,
            filteredCameras,
            devicesLocationsTree,
            selectedCameras,
            showAddCamerasTree,
            camerasCache,
            archiveRanges,
            isLoading,
            orderNumber,
            eventsDate,
            type,
            groupIds,
            usersGroups,
            searchGroup,
            types,
        } = this.state;

        const { strings } = this.props;

        const groups = usersGroups?.filter((ug) => ug?.name?.toLowerCase().includes(searchGroup?.toLowerCase()));

        return orderNumber ? (
            <CreatedOrdersListModal orderNumber={orderNumber} onClose={this.props.handleClose} />
        ) : (
            <>
                {isLoading && <LoaderAnimation message={strings("Отправка архивных заданий")} />}
                <Modal size="lg" closeHandler={this.props.handleClose} style={{ zIndex: 1 }}>
                    <ModalHeader title={strings("Заказать фотоизображение")} closeHandler={this.props.handleClose} />

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <ModalBodyItem>
                                <BodyHeightLimit>
                                    <Row marginBottom={24} align={"bottom"} justify={"between"}>
                                        <Text marginLeft={"auto"} color="primary" variant="body-sm" align={"left"}>
                                            {strings("Камера")}
                                        </Text>
                                        <Row align="middle">
                                            <SelectedCounter
                                                count={this.state.selectedCameras?.length ?? 0}
                                                onClear={() => this.setState({ selectedCameras: [] })}
                                                strings={strings}
                                            />

                                            <AddButton
                                                show={!showAddCamerasTree}
                                                onClick={() => this.setState({ showAddCamerasTree: true })}
                                            />
                                        </Row>
                                    </Row>
                                    <Row marginBottom={12}>
                                        <TextField
                                            autoComplete={false}
                                            colorVariant="light"
                                            fullWidth
                                            icon="search"
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            placeholder={strings("Найти")}
                                            type="search"
                                            value={search}
                                        />
                                    </Row>
                                    {showAddCamerasTree ? (
                                        <CamerasTree
                                            search={search}
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            filteredCameras={filteredCameras}
                                            selectedCameras={selectedCameras}
                                            selectCamera={this.selectCamera}
                                            devicesLocationsTree={devicesLocationsTree}
                                            openItem={this.openLocation}
                                            selectHandler={this.handleSelect}
                                        />
                                    ) : (
                                        <CamerasList
                                            selectedCameras={selectedCameras}
                                            camerasCache={camerasCache}
                                            devicesLocationsTree={devicesLocationsTree}
                                            selectCamera={this.selectCamera}
                                        />
                                    )}
                                </BodyHeightLimit>
                                {showAddCamerasTree && (
                                    <Row>
                                        <Button
                                            label={strings("Сохранить")}
                                            variant="secondary"
                                            onClick={this.onSaveButtonClick}
                                        />
                                    </Row>
                                )}
                            </ModalBodyItem>
                            <ModalBodyItem>
                                <Row marginBottom={12}>
                                    <DateRangePicker
                                        dates={archiveRanges}
                                        presets={[]}
                                        onChange={(range) => {
                                            this.setDateRange(range);
                                        }}
                                        placeholder={strings("Выбрать период")}
                                        colorVariant="transparent"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        withTime
                                        customInputLabels={{
                                            hours: "Часы",
                                            minutes: strings("Минуты"),
                                        }}
                                        customIcon={<Icon icon="calendar-clear" />}
                                        calendarProps={{
                                            disabledDays: {
                                                after: new Date(),
                                            },
                                        }}
                                        events={eventsDate}
                                    />
                                </Row>

                                <Row marginBottom={12}>
                                    <Select
                                        label={strings("Тип расписания")}
                                        placeholder={strings("Выбрать тип")}
                                        value={type}
                                        onChange={(e) => this.setState({ type: e })}
                                        options={types.map((t) => ({
                                            value: t.value,
                                            label: strings(t.label),
                                        }))}
                                        fullWidth
                                        error={!type ? strings("Поле не может быть пустым") : null}
                                    />
                                </Row>

                                {type === GROUP_ORDER_TYPE.value && (
                                    <>
                                        <Space />
                                        <ModalFormItem>
                                            <Row justify="between" align="bottom" marginBottom={24}>
                                                <Text variant="body">{strings("Группы пользователей")}</Text>
                                                <SelectedCounter
                                                    count={groupIds?.length ?? 0}
                                                    onClear={() => this.setState({ groupIds: [] })}
                                                    strings={strings}
                                                />
                                            </Row>
                                            <Row marginBottom={12}>
                                                <TextField
                                                    autoComplete={false}
                                                    colorVariant="light"
                                                    fullWidth
                                                    icon="search"
                                                    onChange={(e) => this.setState({ searchGroup: e.target.value })}
                                                    placeholder={strings("Найти")}
                                                    type="search"
                                                    value={searchGroup}
                                                />
                                            </Row>
                                            <FilterAccordionStatic
                                                key={!!searchGroup}
                                                preOpen={!!searchGroup}
                                                items={groups}
                                                selected={groupIds ?? []}
                                                selectHandler={(e) => this.setState({ groupIds: e })}
                                            />
                                            <Space />
                                        </ModalFormItem>
                                    </>
                                )}

                                <Row>
                                    <TextField
                                        label={strings("Причина заказа")}
                                        name="reason"
                                        type="text"
                                        value={reason}
                                        onChange={(e) => this.setState({ reason: e.target.value })}
                                        colorVariant="light"
                                        fullWidth
                                        inputProps={{
                                            as: "textarea",
                                            rows: 3,
                                        }}
                                    />
                                </Row>
                            </ModalBodyItem>
                        </ModalBody>

                        <ModalFooter>
                            <ModalFooterItem>
                                <Button
                                    disabled={selectedCameras.length === 0 || showAddCamerasTree}
                                    variant="primary"
                                    label={strings("Заказать")}
                                    onClick={this.handleSubmit}
                                    type="submit"
                                />
                            </ModalFooterItem>

                            <ModalFooterItem>
                                <Button
                                    variant="ghost"
                                    label={strings("Отмена")}
                                    onClick={this.props.handleClose}
                                    type="button"
                                />
                            </ModalFooterItem>
                        </ModalFooter>
                    </form>
                </Modal>
            </>
        );
    }
}

const BodyHeightLimit = styled.div`
    min-height: 554px;
    max-width: 400px;
`;

export default withCultureContext(withSnackbar(OrderArchiveModal));

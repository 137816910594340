import React from "react";
import { Tab, Icon, Text, Dropdown, DropdownItem, DateRangePicker, Button } from "headpoint-react-components";
import {
    StreamToolbar,
    StreamToolbarSecondary,
    StreamToolbarItem,
    StreamToolbarInnerItem,
    StreamToolbarPrimary,
} from "../../components/StreamToolbar";
import moment from "moment/moment";
import { getTimeRange } from "../../../utilites/TimeUtils";
import { anyPermissionExists } from "../../../contexts/GeneralContext";

export class Toolbar extends React.Component {
    getFrameCount(layout) {
        let count;

        switch (layout) {
            case "1":
                count = 1;
                break;

            case "2":
                count = 2;
                break;

            case "4":
                count = 4;
                break;

            case "1-5":
                count = 6;
                break;

            case "1-7":
                count = 8;
                break;

            case "16":
                count = 16;
                break;

            case "32":
                count = 32;
                break;

            default:
                count = 1;
        }

        return count;
    }

    handleLayout(layout) {
        const { setCurrentLayout } = this.props;

        if (setCurrentLayout) {
            setCurrentLayout(layout, this.getFrameCount(layout));
        }
    }

    handleDateRangePreset = (preset) => {
        const newDates = getTimeRange(preset);
        this.props.setDates(newDates);
    };

    getDays = (epochStart, epochEnd) => {
        const result = [];
        if (!epochStart || !epochEnd) {
            return result;
        }

        const startDate = moment(epochStart);
        const endDate = moment(epochEnd);
        while (startDate.isSameOrBefore(endDate)) {
            result.push(startDate.clone().toDate());
            startDate.add(1, "days");
        }

        return result;
    };

    render() {
        const {
            frames,
            setFrames,
            activeDesktop,
            showCameraInfo,
            setShowCameraInfo,
            selectedCameraFrame,
            userInfo,
            dates,
            setDates,
            handleOrderRecordModal,
            openOrderList,
            screenshotsDates,
            strings,
        } = this.props;

        const eventsDate = screenshotsDates?.map((time) => new Date(time));

        return (
            <StreamToolbar>
                <StreamToolbarPrimary>
                    <StreamToolbarItem>
                        <DateRangePicker
                            disabled={!frames?.[selectedCameraFrame]}
                            dates={dates}
                            onChange={setDates}
                            placeholder={strings("Выбрать период")}
                            colorVariant="transparent"
                            inputFormat="D.MM.YYYY HH:mm"
                            events={eventsDate}
                            presets={[
                                {
                                    label: strings("Последний час"),
                                    handler: () => this.handleDateRangePreset("lastHour"),
                                },
                                {
                                    label: strings("Последние 6 часов"),
                                    handler: () => this.handleDateRangePreset("lastSixHours"),
                                },
                                {
                                    label: strings("Последние сутки"),
                                    handler: () => this.handleDateRangePreset("lastDay"),
                                },
                            ]}
                            withTime
                            customInputLabels={{
                                hours: strings("Часы"),
                                minutes: strings("Минуты"),
                            }}
                            customIcon={<Icon icon="calendar-clear" />}
                        />
                    </StreamToolbarItem>
                </StreamToolbarPrimary>

                <StreamToolbarSecondary>
                    {anyPermissionExists(userInfo, [
                        "screenshot.archive.edit.personal",
                        "screenshot.archive.edit.group",
                        "screenshot.archive.edit.group.all",
                        "screenshot.archive.edit.system",
                    ]) ? (
                        <StreamToolbarItem>
                            <Button label={strings("Заказать")} variant="primary" onClick={handleOrderRecordModal} />
                        </StreamToolbarItem>
                    ) : (
                        <></>
                    )}
                    {anyPermissionExists(userInfo, [
                        "screenshot.archive.view.personal",
                        "screenshot.archive.view.group",
                        "screenshot.archive.view.system",
                    ]) || Object.keys(frames).length > 0 ? (
                        <StreamToolbarItem>
                            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                {anyPermissionExists(userInfo, [
                                    "screenshot.archive.view.personal",
                                    "screenshot.archive.view.group",
                                    "screenshot.archive.view.system",
                                ]) ? (
                                    <DropdownItem as="button" onClick={openOrderList}>
                                        {strings("История заказов")}
                                    </DropdownItem>
                                ) : (
                                    <></>
                                )}
                                {Object.keys(frames).length > 0 ? (
                                    <>
                                        {showCameraInfo ? (
                                            <DropdownItem as="button" onClick={() => setShowCameraInfo(false)}>
                                                {strings("Скрыть информацию о камерах")}
                                            </DropdownItem>
                                        ) : (
                                            <DropdownItem as="button" onClick={() => setShowCameraInfo(true)}>
                                                {strings("Показать информацию о камерах")}
                                            </DropdownItem>
                                        )}
                                        <DropdownItem
                                            as="button"
                                            onClick={() => {
                                                if (setFrames) {
                                                    setFrames({});
                                                }
                                            }}
                                        >
                                            {strings("Очистить рабочий стол")}
                                        </DropdownItem>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Dropdown>
                        </StreamToolbarItem>
                    ) : (
                        <></>
                    )}
                    <StreamToolbarItem>
                        {["1", "2", "4", "1-5", "1-7", "16", "32"].map((tab) => (
                            <StreamToolbarInnerItem key={tab}>
                                <Tab
                                    label={
                                        tab === "32" ? (
                                            <Text>32</Text>
                                        ) : (
                                            <Icon icon={tab === "1" ? "window" : `windows-${tab}`} size={24} />
                                        )
                                    }
                                    iconTab
                                    isActive={activeDesktop?.layout === tab}
                                    onClick={() => this.handleLayout(tab)}
                                />
                            </StreamToolbarInnerItem>
                        ))}
                    </StreamToolbarItem>
                </StreamToolbarSecondary>
            </StreamToolbar>
        );
    }
}

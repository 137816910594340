import RolesGroupsModal from "./RolesGroupsModal";
import { UpdateRolesGroup } from "../../../../../../services/roles";
import { withSnackbar } from "notistack";
import PubSub from "pubsub-js";
import { CLOSE_ALL_MODALS, ROLE_GROUP_EDITED } from "../../rolesSettingsTopics";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class EditRolesGroupModal extends RolesGroupsModal {
    constructor(props) {
        const { strings } = props;

        super(props);
        this.title = strings("Изменить группу ролей");
        this.state = {
            values: props?.group ? this.valuesFromRolesGroup(props.group) : undefined,
        };
    }

    valuesFromRolesGroup = (group) => {
        return {
            id: group.id,
            groupName: group.name,
            properties: group.properties,
        };
    };

    saveHandler = async () => {
        const { strings } = this.props;
        const { values } = this.state;

        const group = this.rolesGroupFromValues(values);
        let [status, statusCode, response] = await UpdateRolesGroup(group);
        if (status) {
            this.props.enqueueSnackbar(`${strings("Группа ролей")} '${group.name}' ${strings("успешно изменен")}`, {
                variant: "success",
            });
            PubSub.publish(ROLE_GROUP_EDITED, group);
            PubSub.publish(CLOSE_ALL_MODALS);
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для изменения группы ролей")} '${group.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                case 409: // conflict
                    switch (response.code) {
                        case "conflict-name":
                            this.props.enqueueSnackbar(
                                `${strings("Группа ролей с именем")} '${group.name}' ${"уже существует"}`,
                                {
                                    variant: "warning",
                                }
                            );
                            break;
                        default:
                            this.props.enqueueSnackbar(strings("Неизвестный конфликт"), { variant: "warning" });
                            break;
                    }

                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось сохранить группу ролей")} '${group.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }
    };
}

export default withCultureContext(withSnackbar(EditRolesGroupModal));

import { Button, Modal, ModalBody, ModalBodyItem, Row, Text } from "headpoint-react-components";
import React from "react";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

export default function ReplacePropertiesModal({ onChoice }) {
    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Modal size="sm">
                    <ModalBody>
                        <ModalBodyItem>
                            <Text variant="body-sm" color="primary">
                                {strings("Заменить Роль и Локацию пользователя на ")}
                            </Text>
                            <br />
                            <Text variant="body-sm" color="primary">
                                {strings("действующие в выбранной группе?")}
                            </Text>

                            <Row>
                                <Button
                                    style={{ marginTop: "2em" }}
                                    label={strings("Заменить")}
                                    onClick={() => onChoice(true)}
                                />
                                <Button
                                    style={{ marginTop: "2em", marginLeft: "1em" }}
                                    label={strings("Оставить текущие")}
                                    onClick={() => onChoice(false)}
                                />
                            </Row>
                        </ModalBodyItem>
                    </ModalBody>
                </Modal>
            )}
        </CultureContextConsumer>
    );
}

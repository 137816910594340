import React from "react";
import { withSnackbar } from "notistack";
import { EventsLayoutHeader, EventsLayoutHeaderTitle, EventsLayoutPrimary } from "../../../../layout/EventsLayout";
import { Text } from "headpoint-react-components";
import Iframe from "react-iframe";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class CustomArea extends React.Component {
    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutPrimary hasSecondary={true}>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings(this.props.label)}
                                </Text>
                            </EventsLayoutHeaderTitle>
                        </EventsLayoutHeader>
                        <Iframe
                            url={`${this.props.content}/`}
                            position="relative"
                            width="100%"
                            id={this.props.serviceId}
                            height="100%"
                            frameBorder={0}
                        />
                    </EventsLayoutPrimary>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(CustomArea);

import React from "react";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { withRouter } from "react-router-dom";
import { nanoid } from "nanoid";
import { ImageBlock } from "./UiBlocks/ImageBlock";
import { getFormatDataFromSource } from "./utilites";
import { EventMedia } from "./UiBlocks/EventMedia";
import moment from "moment";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";

class DetailsTabInfo extends React.Component {
    navigateToPlans(path) {
        const { selectedPlan, selectedPoints, points } = this.props;
        this.props.history.push({ pathname: path, state: { selectedPlan, selectedPoints, points } });
    }

    render() {
        let { tabSet, strings } = this.props;
        const { selectedEvent, defaultEventTypes, eventSeverity, eventSeverityColor } = this.props;

        const { userInfo } = this.props;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        const eventSeverityLabel = eventSeverity?.label ?? selectedEvent.severity;
        const eventType = defaultEventTypes.find((et) => et.value === selectedEvent.type)?.label ?? selectedEvent.type;

        return (
            <DetailsBoxContent>
                {tabSet?.media?.map((m, index) => (
                    <EventMedia key={`${selectedEvent.id}_${index}`} media={m} selectedEvent={selectedEvent} />
                ))}
                <Table isFixed noPadding>
                    <TableBody>
                        <TableRow>
                            <TableCell>{strings("ID")}</TableCell>
                            <TableCell>
                                <Text color={eventSeverityColor} variant="body-sm">
                                    {selectedEvent.id}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Важность")}</TableCell>
                            <TableCell>
                                <Text color={eventSeverityColor} variant="body-sm">
                                    {strings(eventSeverityLabel)}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Тип")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {strings(eventType)}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Дата регистрации события")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {selectedEvent.registrationDate
                                        ? moment(selectedEvent.registrationDate).format("DD-MM-YYYY HH:mm:ss")
                                        : ""}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Дата наступления события")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {selectedEvent.eventDate
                                        ? moment(selectedEvent.eventDate).format("DD-MM-YYYY HH:mm:ss")
                                        : ""}
                                </Text>
                            </TableCell>
                        </TableRow>
                        {tabSet?.fields?.map((f) => {
                            switch (f.type) {
                                case "image":
                                    return f.source ? (
                                        <TableRow key={nanoid()}>
                                            <TableCell>{f.name}</TableCell>
                                            <TableCell>
                                                <ImageBlock image={f} selectedEvent={selectedEvent} />
                                            </TableCell>
                                        </TableRow>
                                    ) : null;

                                default:
                                    return (
                                        <TableRow key={nanoid()}>
                                            <TableCell>{strings(f.name)}</TableCell>
                                            <TableCell>
                                                <Text variant="body-sm">
                                                    {getFormatDataFromSource(f, selectedEvent)}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    );
                            }
                        })}
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withRouter(DetailsTabInfo)));

import React from "react";
import Sidebar from "../../components/Sidebar";
import CameraFrames from "./CameraFrames";
import Toolbar from "./Toolbar";
import { LayoutMain, LayoutPage } from "../../../layout/MainLayout";
import { FillLocations, GetLocations, GetLocationList, FillNode } from "../../../services/locations";
import { GetDevices, SearchDevices, GetDeviceInfo } from "../../../services/devices";
import { nanoid } from "nanoid";
import { withSnackbar } from "notistack";
import { getAdapters, getDeviceTypesByFeature, withGeneralContext } from "../../../contexts/GeneralContext";
import PubSub from "pubsub-js";
import { ACTIVATE_DESKTOP_TOPIC } from "./streamTopics";
import { isEqual as isDeepEqual } from "lodash";
import { updateNodes } from "../../../utilites/TreeUtils.js";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { withRouter } from "react-router-dom";
import { saveActiveDesktop, saveFrames, saveShowCameraInfo } from "../../../app/reducers/streamReducer";

const DEVICES_LIMIT = 100;

const initialDesktop = {
    layout: "1",
};

class Stream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            searchDevices: [],
            features: [],
            search: "",
            selectedCameraFrame: null,
        };
    }

    componentDidMount = async () => {
        const {
            history,
            stream: { frames },
        } = this.props;

        this.selectedDesktopTopic = PubSub.subscribe(ACTIVATE_DESKTOP_TOPIC, async (msg, data) => {
            await this.activateDesktop(data);
        });

        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        let tree = [];

        tree = FillLocations(locations, []);

        this.setState({
            devicesLocationsTree: tree ?? [],
        });

        const data = history.location.state;
        if (data) {
            history.replace({ ...history, state: undefined });
            await this.openStreamFromHistory(tree, data);
        } else {
            await this.openStreamFromStorage(frames, tree);
        }
    };

    openStreamFromStorage = async (frames, tree) => {
        for (const frame in frames) {
            await this.openByLocationId(tree, frames[frame]?.locationId);
        }
        this.setState({
            updateTreeId: nanoid(),
        });
    };

    openStreamFromHistory = async (tree, data) => {
        switch (data?.type) {
            case "SelectCamera":
                await this.openByLocationId(tree, data.device.locationId);

                this.props.dispatch(saveFrames([data.device]));
                this.props.dispatch(saveActiveDesktop(initialDesktop));
                this.setState({
                    updateTreeId: nanoid(),
                });
                break;

            default:
                break;
        }
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const [getLocationsStatus, locations] = await GetLocations();
            if (!getLocationsStatus) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
            }

            const tree = FillLocations(locations, []);
            this.setState({
                devicesLocationsTree: tree ?? [],
                frames: [],
            });
        }
    };

    componentWillUnmount() {
        if (this.selectedDesktopTopic) {
            PubSub.unsubscribe(this.selectedDesktopTopic);
        }
    }

    openByLocationId = async (devicesLocationsTree, locationId) => {
        const { strings } = this.props;

        if (!locationId) {
            return;
        }

        let [locationsStatus, locationsDb] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locationsDb?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [locationDb] = locationsDb;

        let parentIds = [...locationDb.parentIds.slice(1), locationId];
        let locationNode;

        let tree = updateNodes(devicesLocationsTree, "preOpen", true);

        for (const parentId of parentIds) {
            locationNode = tree.find((i) => i.id === parentId);

            locationNode.isClosed = false;

            if (!locationNode) {
                this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                    variant: "error",
                });
                return;
            }

            locationNode.preOpen = true;

            await this.openLocation(locationNode, this.props.generalInfo);

            tree = locationNode.children;
        }

        this.setState({ devicesLocationsTree: devicesLocationsTree });
    };

    activateDesktop = async (desktop) => {
        const frames = {};
        if (desktop.cameras) {
            for (let index in desktop.cameras) {
                const [getDeviceStatus, devices] = await GetDeviceInfo([desktop.cameras[index]]);
                if (getDeviceStatus && devices?.length) {
                    frames[`${index}`] = devices[0];
                } else {
                    const { strings } = this.props;
                    this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                }
            }
        }

        this.props.dispatch(saveFrames(frames));
        this.props.dispatch(saveActiveDesktop(desktop));
    };

    openChildren = async (locationNode, generalInfo, openedLocations) => {
        const { strings } = this.props;

        const adapters = getAdapters(generalInfo);
        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.stream");
        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.props.enqueueSnackbar(strings("Не найдено адаптеров поддерживающих стрим"), { variant: "warning" });
            return;
        }

        const [getDevicesStatus, devices] = await GetDevices(locationNode.id, deviceTypeIds);

        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);

        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, devices);
        }

        let sublocations = locationNode.children.filter((child) => openedLocations.has(child.id));
        for (let sublocation of sublocations) {
            sublocation.isClosed = false;
            await this.openChildren(sublocation, generalInfo, openedLocations);
        }
    };

    openLocation = async (locationNode, generalInfo) => {
        const { devicesLocationsTree } = this.state;

        let openedSublocations = new Set(
            this.flatten(locationNode)
                .filter((node) => node?.isClosed === false)
                .map((node) => node.id)
        );

        await this.openChildren(locationNode, generalInfo, openedSublocations);

        this.setState({ devicesLocationsTree });
    };

    flatten = (locationNode) => {
        let flat = [];

        let stack = [locationNode];
        while (stack.length > 0) {
            let node = stack.pop();

            if (node) {
                flat.push(node);

                if (node.children) {
                    stack.push(...node.children);
                }
            }
        }

        return flat;
    };

    framesToDesktopCameras = (frames) => {
        let cameras = {};

        for (const index of Object.keys(frames)) {
            cameras[index] = frames[index].id;
        }

        return cameras;
    };

    setFrames = (frames) => {
        let { activeDesktop } = this.props.stream;

        if (activeDesktop.cameras) {
            let cameras = this.framesToDesktopCameras(frames);
            if (!isDeepEqual(activeDesktop.cameras, cameras)) {
                activeDesktop = { ...initialDesktop, layout: activeDesktop.layout };
            }
        }

        this.props.dispatch(saveFrames(frames));
        this.props.dispatch(saveActiveDesktop(activeDesktop));
    };

    setCurrentLayout = (layout, framesCount) => {
        const { selectedCameraFrame } = this.state;
        const { activeDesktop, frames } = this.props.stream;

        if (activeDesktop.layout !== layout) {
            let nextFrames;

            if (layout === "1") {
                nextFrames = { 0: frames[selectedCameraFrame] };
            } else {
                nextFrames = { ...frames };
                Object.keys(nextFrames).forEach((key) => {
                    if (key >= framesCount) {
                        delete nextFrames[key];
                    }
                });
            }

            this.props.dispatch(saveFrames(nextFrames));
            this.setSelectedCameraFrame(0);
            this.props.dispatch(saveActiveDesktop({ ...initialDesktop, layout }));
        }
    };
    setSelectedCameraFrame = (selectedCameraFrame) => {
        this.setState({ selectedCameraFrame: selectedCameraFrame });
    };

    setShowCameraInfo = (show) => {
        this.props.dispatch(saveShowCameraInfo(show));
    };

    setSearch = async (like, generalInfo) => {
        const { strings } = this.props;

        let adapters = getAdapters(generalInfo);
        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.stream");
        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.props.enqueueSnackbar(strings("Не найдено адаптеров поддерживающих стрим"), { variant: "warning" });
            this.setState({ search: like, searchDevices: [] });
            return;
        }

        const [devicesStatus, searchDevices] = await SearchDevices({
            like,
            limit: DEVICES_LIMIT,
            deviceTypes: deviceTypeIds,
        });
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        this.setState({ search: like, searchDevices: searchDevices?.map((d) => ({ ...d, tag: "device" })) });
    };

    render() {
        const { devicesLocationsTree, searchDevices, search, selectedCameraFrame, updateTreeId } = this.state;

        const {
            strings,
            stream: { activeDesktop, frames, showCameraInfo },
            generalInfo,
        } = this.props;

        const source = search ? searchDevices : devicesLocationsTree;

        return (
            <LayoutPage>
                <Sidebar
                    key={updateTreeId}
                    id={nanoid()}
                    search={search}
                    setSearch={(like) => this.setSearch(like, generalInfo)}
                    frames={frames}
                    setFrames={this.setFrames}
                    selectedCameraFrame={selectedCameraFrame}
                    setSelectedCameraFrame={this.setSelectedCameraFrame}
                    currentLayout={activeDesktop.layout}
                    devicesLocationsTree={source ?? []}
                    openLocation={(location) => this.openLocation(location, generalInfo)}
                    strings={strings}
                />
                <LayoutMain>
                    <Toolbar
                        frames={frames}
                        showCameraInfo={showCameraInfo}
                        setShowCameraInfo={this.setShowCameraInfo}
                        setFrames={this.setFrames}
                        activeDesktop={activeDesktop}
                        setCurrentLayout={this.setCurrentLayout}
                    />

                    <CameraFrames
                        presets={[]}
                        showCameraInfo={showCameraInfo}
                        selectedCameraFrame={selectedCameraFrame}
                        setSelectedCameraFrame={this.setSelectedCameraFrame}
                        frames={frames}
                        setFrames={this.setFrames}
                        currentLayout={activeDesktop.layout}
                    />
                </LayoutMain>
            </LayoutPage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        stream: state.stream,
    };
};

export default connect(mapStateToProps)(withSnackbar(withCultureContext(withGeneralContext(withRouter(Stream)))));

import React from "react";
import {
    Button,
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    TableAccordionItem,
    TableHoverContainer,
    Icon,
    Row,
    Space,
} from "headpoint-react-components";
import { EventsLayoutPrimaryInner, EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { formatUserCount } from "../../../../utilites/TextUtils";
import { GetUsers } from "../../../../services/users";
import PubSub from "pubsub-js";
import {
    ROLE_COPIED,
    ROLE_CREATED,
    ROLE_DELETED,
    ROLE_GROUP_CREATED,
    ROLE_GROUP_DELETED,
    ROLE_GROUP_EDITED,
    ROLE_UPDATED,
    SHOW_ROLE_DETAILS,
    SHOW_ROLE_GROUP_DETAILS,
} from "./rolesSettingsTopics";
import { GetRoles, GetRolesGroups, GetRolesTree } from "../../../../services/roles";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../CommonTablePagination";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class RoleTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            roles: [],
            rolesGroups: [],
            pageData: { start: 0, end: ITEMS_ON_PAGE },
        };
    }

    componentDidMount = async () => {
        const { strings } = this.props;

        this.rolesGroupCreated = PubSub.subscribe(ROLE_GROUP_CREATED, (msg, data) => {
            const { rolesGroups } = this.state;
            this.setState({
                rolesGroups: [...rolesGroups, data],
            });
        });

        this.rolesGroupEdited = PubSub.subscribe(ROLE_GROUP_EDITED, (msg, data) => {
            let { rolesGroups } = this.state;
            rolesGroups = rolesGroups?.filter((g) => g.id !== data.id);
            this.setState({
                rolesGroups: [...rolesGroups, data],
            });
        });

        this.rolesGroupDeleted = PubSub.subscribe(ROLE_GROUP_DELETED, (msg, data) => {
            const { rolesGroups } = this.state;
            this.setState({
                rolesGroups: rolesGroups?.filter((g) => g.id !== data.id) ?? [],
            });
        });

        this.roleCreated = PubSub.subscribe(ROLE_CREATED, (msg, data) => {
            const { roles } = this.state;
            this.setState({
                roles: [...roles, data],
            });
        });

        this.roleDeleted = PubSub.subscribe(ROLE_DELETED, (msg, data) => {
            const { roles } = this.state;
            this.setState({
                roles: roles?.filter((r) => r.id !== data.id) ?? [],
            });
        });

        this.roleEdited = PubSub.subscribe(ROLE_UPDATED, (msg, data) => {
            let { roles } = this.state;
            roles = roles?.filter((r) => r.id !== data.id);
            this.setState({
                roles: [...roles, data],
            });
        });

        this.roleCopied = PubSub.subscribe(ROLE_COPIED, async () => {
            const [rolesStatus, roles] = await GetRoles();
            if (!rolesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения списка ролей"), { variant: "error" });
                return;
            }

            this.setState({ roles });
        });

        const [usersStatus, users] = await GetUsers();
        if (!usersStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения пользователей"), { variant: "error" });
            return;
        }

        const [rolesStatus, roles] = await GetRoles();
        if (!rolesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения списка ролей"), { variant: "error" });
            return;
        }

        const [rolesGroupsStatus, rolesGroups] = await GetRolesGroups();
        if (!rolesGroupsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения списка групп ролей"), { variant: "error" });
            return;
        }

        this.setState({
            users,
            roles,
            rolesGroups,
        });
    };

    componentWillUnmount() {
        this.rolesGroupCreated && PubSub.unsubscribe(this.rolesGroupCreated);
        this.rolesGroupEdited && PubSub.unsubscribe(this.rolesGroupEdited);
        this.rolesGroupDeleted && PubSub.unsubscribe(this.rolesGroupDeleted);
        this.roleCreated && PubSub.unsubscribe(this.roleCreated);
        this.roleDeleted && PubSub.unsubscribe(this.roleDeleted);
        this.roleEdited && PubSub.unsubscribe(this.roleEdited);
        this.roleCopied && PubSub.unsubscribe(this.roleCopied);
    }

    flatten = (object) => {
        const getChild = (key, objectBit) => {
            return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
        };

        return Object.assign(
            {},
            ...(function _flatten(objectBit) {
                return [].concat(
                    ...Object.keys(objectBit).map((key) =>
                        typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                    )
                );
            })(object)
        );
    };

    RoleActions = ({ role }) => (
        <TableHoverContainer>
            <Button
                icon="info"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    PubSub.publish(SHOW_ROLE_DETAILS, role);
                }}
                title="Info"
                variant="ghost"
            />
        </TableHoverContainer>
    );

    GroupActions = ({ group }) => (
        <TableHoverContainer>
            <Button
                icon="info"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    PubSub.publish(SHOW_ROLE_GROUP_DETAILS, group);
                }}
                title="Info"
                variant="ghost"
            />
        </TableHoverContainer>
    );

    render() {
        const { selected, search, strings } = this.props;
        const { users, roles, rolesGroups, pageData } = this.state;

        let rolesTree = GetRolesTree(roles, rolesGroups);
        if (search) {
            const searchRoles = roles.filter((et) => et.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            rolesTree = GetRolesTree(searchRoles, rolesGroups).filter((group) => group.roles?.length > 0);
        }

        return (
            <>
                <EventsLayoutPrimaryInner>
                    <EventsLayoutTableWrapper>
                        <Table hasHover stickyHeader isFixed bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Роли")}</TableHeadCell>
                                    <TableHeadCell>{strings("Пользователи")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rolesTree.slice(pageData.start, pageData.end).map((roleGroup) => (
                                    <TableAccordionItem
                                        key={`${roleGroup.id}-${search}`}
                                        defaultClosed={!search}
                                        toggle={
                                            <>
                                                <TableCell verticalAlign="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        {roleGroup.name}
                                                    </Text>
                                                </TableCell>

                                                <TableCell verticalAlign="middle" noVerticalSpace />

                                                <TableCell verticalAlign="middle" noVerticalSpace>
                                                    <this.GroupActions group={roleGroup} />
                                                </TableCell>
                                            </>
                                        }
                                    >
                                        {roleGroup.roles &&
                                            roleGroup.roles.map((role) => (
                                                <TableRow
                                                    key={role.id}
                                                    isSelected={selected && role.id === selected.id}
                                                >
                                                    <TableCell verticalAlign="middle">
                                                        <Row align="middle">
                                                            <Text color="primary" variant="body-sm">
                                                                <Icon icon="settings-role" size={16} />
                                                            </Text>
                                                            <Space />
                                                            <Text color="primary" variant="body-sm">
                                                                {role.name}
                                                            </Text>
                                                        </Row>
                                                    </TableCell>

                                                    <TableCell verticalAlign="middle">
                                                        <Text color="primary" variant="body-sm">
                                                            {(() => {
                                                                let userCount = users.filter(
                                                                    (user) => user.roleId === role.id
                                                                )?.length;
                                                                return `${userCount} ${strings(
                                                                    formatUserCount(userCount)
                                                                )}`;
                                                            })()}
                                                        </Text>
                                                    </TableCell>

                                                    <TableCell verticalAlign="middle" noVerticalSpace>
                                                        <this.RoleActions role={role} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableAccordionItem>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                </EventsLayoutPrimaryInner>
                <CommonTablePagination
                    tableData={rolesTree}
                    setPaging={(pageData) => this.setState({ pageData })}
                    searchMode={search !== ""}
                />
            </>
        );
    }
}

export default withCultureContext(withSnackbar(RoleTable));

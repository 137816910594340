import { urlWithQuery } from "../utilites/UrlUtils";

const path = "/general-api/service/service.api.storage/files";

export const SendFile = async (file, fileId) => {
    const formData = new FormData();
    formData.append("", file);

    const url = `${path}/upload`;
    let params = new URLSearchParams();

    if (fileId) {
        params.append("fileId", fileId);
    }

    let response = await fetch(urlWithQuery(url, params), {
        method: "POST",
        body: formData,
    });

    if (response.status !== 200) {
        return [false, response.status];
    }

    let result = await response.json();
    return [response.status === 200, result?.fileId];
};

export const IsUploadedFileExists = async (fileId, fileName) => {
    const url = `${path}/check/${fileId}`;
    const token = window.localStorage.getItem("token");

    let params = new URLSearchParams();
    if (fileName) {
        params.append("fileName", fileName);
    }

    let response = await fetch(urlWithQuery(url, params), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return response.status === 200;
};

export const DownloadFile = async (fileId, fileName) => {
    const url = `${path}/${fileId}`;
    const token = window.localStorage.getItem("token");

    let params = new URLSearchParams();
    params.append("authToken", `Bearer ${token}`);
    if (fileName) {
        params.append("fileName", fileName);
    }

    try {
        const link = document.createElement("a");
        link.href = urlWithQuery(url, params);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    } catch (exc) {
        return false;
    }
};

export const GetArchiveFile = async (encryptedUrl, fileName) => {
    const url = `${path}/download/${encryptedUrl}`;
    const token = window.localStorage.getItem("token");

    let params = new URLSearchParams();
    params.append("authToken", `Bearer ${token}`);
    if (fileName) {
        params.append("fileName", fileName);
    }

    try {
        const link = document.createElement("a");
        link.href = urlWithQuery(url, params);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    } catch (exc) {
        return false;
    }
};

//TODO: can be use with GetArchiveFile() and DownloadFile()
export const DownloadByPath = (path) => {
    const link = document.createElement("a");
    link.href = path;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const CheckArchiveFile = async (encryptedUrl, fileName) => {
    const token = window.localStorage.getItem("token");
    const url = `${path}/check/${encryptedUrl}`;

    let params = new URLSearchParams();
    if (fileName) {
        params.append("fileName", fileName);
    }

    let response = await fetch(urlWithQuery(url, params), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return response.status === 200;
};

export const GetFileMetadata = async (id) => {
    const token = window.localStorage.getItem("token");
    const url = `${path}/metadata/${id}`;

    let response = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, response.status];
    }

    try {
        let result = await response.json();
        if (!result) {
            return [false, null];
        }

        return [true, result];
    } catch (ex) {
        return [false, null];
    }
};

import React from "react";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxContent,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../../../../components/DetailsBox";
import {
    Button,
    Dropdown,
    DropdownItem,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TabPanel,
    Text,
} from "headpoint-react-components";
import DetailsTabMain from "./DetailsTabMain";
import { GetDevices } from "../../../../../services/devices";
import { withSnackbar } from "notistack";
import { SHOW_DELETE_LOCATION, SHOW_UPDATE_LOCATION } from "../deviceSettingsTopics";
import PubSub from "pubsub-js";
import { GeneralContextConsumer } from "../../../../../contexts";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DeviceLocationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
            count: "...",
        };
    }

    async getDevices(locationId) {
        const [getDevicesStatus, devices] = await GetDevices(locationId);
        if (!getDevicesStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
        }

        return devices;
    }

    async componentDidMount() {
        const { location } = this.props;

        this.getDevices(location.id).then((devices) => this.setState({ count: devices?.length ?? "..." }));
    }

    render() {
        const { strings } = this.props;
        const { detailsTab } = this.state;
        const detailsTabs = [
            {
                title: strings("Общее"),
                key: "main",
                component: DetailsTabMain,
            },
        ];
        const location = this.props.location;
        const geolocation = location?.properties?.geolocation;

        const tab = detailsTabs.find((t) => t.key === detailsTab) ?? detailsTabs.find((s) => s.key === "main");
        const { key: actualDetailsTab } = tab;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {location.name}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                        <DropdownItem
                                            as="button"
                                            onClick={() =>
                                                PubSub.publish(SHOW_UPDATE_LOCATION, { location, generalInfo })
                                            }
                                        >
                                            {strings("Редактировать")}
                                        </DropdownItem>
                                        <DropdownItem
                                            as="button"
                                            colorVariant={"danger"}
                                            onClick={() => PubSub.publish(SHOW_DELETE_LOCATION, location)}
                                        >
                                            {strings("Удалить")}
                                        </DropdownItem>
                                    </Dropdown>
                                    <Button icon="close" variant="secondary" onClick={() => this.props.close()} />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    {detailsTabs?.map((tab) => (
                                        <Tab
                                            key={tab.title}
                                            label={tab.title}
                                            isActive={actualDetailsTab === tab.key}
                                            onClick={() => this.setState({ detailsTab: tab.key })}
                                        />
                                    ))}
                                </TabPanel>
                            </DetailsBoxTabs>
                            <DetailsBoxContent>
                                <Table isFixed noPadding>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{strings("Всего устройств")}</TableCell>
                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {this.state.count}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{strings("Адрес")}</TableCell>
                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {location?.properties?.address}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                        {geolocation?.latitude && geolocation?.longitude && (
                                            <>
                                                <TableRow>
                                                    <TableCell noBorder>{strings("Местоположение")}</TableCell>
                                                    <TableCell noBorder />
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell indent={30} noBorder>
                                                        {strings("Широта")}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Text color="primary" variant="body-sm">
                                                            {geolocation.latitude}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell indent={30}>{strings("Долгота")}</TableCell>
                                                    <TableCell>
                                                        <Text color="primary" variant="body-sm">
                                                            {geolocation.longitude}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )}
                                        <TableRow>
                                            <TableCell>{strings("Комментарий")}</TableCell>
                                            <TableCell>
                                                <Text scroll color="primary" variant="body-sm">
                                                    {location?.properties?.comment}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </DetailsBoxContent>
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </GeneralContextConsumer>
        );
    }
}
export default withCultureContext(withSnackbar(DeviceLocationDetails));

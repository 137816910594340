import React from "react";
import {
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    Space,
    TableCell,
    Text,
    Icon,
    TableHoverContainer,
    Button,
} from "headpoint-react-components";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";

export class DevicesGroupsTable extends React.Component {
    rowActions = (group) => (
        <TableHoverContainer>
            <Button
                icon="info"
                title="Info"
                variant="ghost"
                onClick={(e) => {
                    e.stopPropagation();
                    this.props.selectGroup(group);
                }}
            />
        </TableHoverContainer>
    );

    getGroupType = (group) => {
        const { strings } = this.props;

        switch (group?.properties?.groupType) {
            case "fixed":
                return strings("Фиксированная");

            case "dynamic":
                return strings("Динамическая");

            default:
                return strings("Неизвестный тип");
        }
    };

    groupName = (group) => {
        return (
            <Text color="primary" variant="body-sm">
                {group.name}
            </Text>
        );
    };

    render() {
        const { selectedGroup, deviceGroupList, strings } = this.props;
        return (
            <EventsLayoutTableWrapper>
                <Table isFixed hasHover stickyHeader bgColor="dark">
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col style={{ width: "80px" }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableHeadCell>{strings("Группа оборудования")}</TableHeadCell>
                            <TableHeadCell>{strings("Тип группы")}</TableHeadCell>
                            <TableHeadCell>{strings("Устройств")}</TableHeadCell>
                            <TableHeadCell></TableHeadCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {deviceGroupList?.map((group) => (
                            <TableRow key={group.id} isSelected={selectedGroup && group.id === selectedGroup.id}>
                                <TableCell verticalAlign="middle">
                                    <Text color="primary" variant="body-sm">
                                        <Icon icon="devicegroup" size={16} />
                                    </Text>
                                    <Space />
                                    {this.groupName(group)}
                                </TableCell>
                                <TableCell verticalAlign="middle">
                                    <Text color="primary" variant="body-sm">
                                        {this.getGroupType(group)}
                                    </Text>
                                </TableCell>
                                <TableCell verticalAlign="middle">
                                    <Text color="primary" variant="body-sm">
                                        {group?.count ?? "..."}
                                    </Text>
                                </TableCell>
                                <TableCell verticalAlign="middle" noVerticalSpace>
                                    {this.rowActions(group)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </EventsLayoutTableWrapper>
        );
    }
}

import React from "react";
import { withSnackbar } from "notistack";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownDivider,
    Text,
    Tab,
    TabPanel,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    Row,
    DeviceIcon,
    Space,
} from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxContent,
} from "../../../components/DetailsBox";
import { GetDeviceInfo } from "../../../../services/devices";
import { GetTemplates } from "../../../../services/scenarios";
import { convertUTCToLocal, getMonthName } from "../../../../utilites/TimeUtils";
import moment from "moment";
import uuid from "react-uuid";
import { CultureContextConsumer, withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { GeneralContextConsumer, getDeviceTypes, permissionExists } from "../../../../contexts";
import { connect } from "react-redux";
import { GetLocationList, GetLocationNames } from "../../../../services/locations";
import ScenariosDeviceTooltip from "./ScenariosDeviceTooltip";

class ScenariosDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            locations: [],
            scenarioTemplates: [],
        };
    }

    TimeMap = {
        all: "Всё время",
        time: "Точное время",
        interval: "Временной интервал",
        dateTime: "Точное время с датой",
    };

    DateMap = {
        all: "Все даты",
        date: "Точная дата",
        day: "Повтор каждый день",
        week: "Повтор каждую неделю",
        month: "Повтор каждый месяц",
        year: "Повтор каждый год",
    };

    timeItemMap = (item, timeType) => {
        let result = undefined;
        switch (timeType) {
            case "all":
                break;
            case "time":
                result = convertUTCToLocal(item);
                break;
            case "interval":
                result = `${convertUTCToLocal(item?.from)}-${convertUTCToLocal(item?.to)}`;
                break;
            case "dateTime":
                result = moment(item).format("DD-MM-YYYY hh:mm");
                break;
            default:
                result = item;
                break;
        }
        return result;
    };

    dateItemMap = (item, index, dateType) => {
        const dayLabels = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];
        let result = undefined;
        switch (dateType) {
            case "all":
            case "day":
                break;
            case "year":
                result = `${item?.day} ${getMonthName(item?.month)}`;
                break;
            case "week":
                if (item) {
                    result = dayLabels[index];
                }
                break;
            case "date":
                result = moment(item).format("DD-MM-YYYY");
                break;
            case "month":
            default:
                result = item;
                break;
        }
        return result;
    };

    async getTemplates() {
        const [status, templates] = await GetTemplates();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения шаблонов выполнения"), { variant: "error" });
        }

        return templates?.data?.templates;
    }

    async getDevicesInfo(deviceIds) {
        const [getDevicesStatus, devices] = await GetDeviceInfo(deviceIds);
        if (!getDevicesStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
        }

        return devices;
    }

    async componentDidMount() {
        const { enqueueSnackbar, strings } = this.props;

        let deviceIds = this.props.selectedScenario.deviceIds;
        const templates = await this.getTemplates();
        const devices = await this.getDevicesInfo(deviceIds);

        const deviceLocationsIds = devices.map((d) => d.locationId);

        let [locationsStatus, locations] = await GetLocationList({ ids: deviceLocationsIds });
        if (!locationsStatus) {
            enqueueSnackbar(strings("Ошибка при получении локаций"), { variant: "error" });
            return;
        }

        const parentIds = new Set();

        locations.forEach((device) => {
            const rootLocationId = device.parentIds[1];
            if (rootLocationId) {
                parentIds.add(rootLocationId);
            }
        });

        const [getNamesStatus, parentLocationIdNamePairs] = await GetLocationNames(parentIds);
        if (!getNamesStatus) {
            enqueueSnackbar(strings("Ошибка при получении названий локаций"), { variant: "error" });
            return;
        }

        const devicesWithLocations = devices.map((device) => {
            const deviceLocation = locations.find((loc) => loc.id === device.locationId);

            return {
                ...device,
                location: deviceLocation,
                fullLocationName: this.getFullLocationName(deviceLocation, parentLocationIdNamePairs),
            };
        });

        this.setState({
            scenarioTemplates: templates,
            devices: devicesWithLocations,
        });
    }

    getFullLocationName = (location, parentIdsMap) => {
        const rootLocationId = location.parentIds[1] ?? undefined;

        if (rootLocationId) {
            const parentLength = location.parentIds.length;

            if (parentLength === 2) {
                return `${parentIdsMap.get(rootLocationId)} / ${location.name}`;
            } else if (parentLength >= 3) {
                return `${parentIdsMap.get(rootLocationId)} / ... / ${location.name}`;
            }
        }

        return location.name;
    };

    handleCloseDetailedView = () => this.props.setSelectedScenario();

    DetailsTabMain = ({ selectedScenario }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Table isFixed noPadding>
                        <TableBody>
                            {selectedScenario.eventTypeIds?.map((item, i) => (
                                <TableRow key={item}>
                                    {i === 0 && (
                                        <TableCell noBorder={selectedScenario.eventTypeIds.length !== 1}>
                                            {strings("Открывающее событие")}
                                        </TableCell>
                                    )}

                                    {i !== 0 && i !== selectedScenario.eventTypeIds.length - 1 && (
                                        <TableCell noBorder />
                                    )}

                                    {i !== 0 && i === selectedScenario.eventTypeIds.length - 1 && <TableCell />}

                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            <Icon icon="settings-event" size={16} />
                                        </Text>
                                        {"\u00A0\u00A0"}
                                        <Text color="primary" variant="body-sm">
                                            {item}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>{strings("Шаблон реагирования")}</TableCell>
                                <TableCell>
                                    <Row verticalAlign="middle">
                                        <Text color="primary" variant="body-sm">
                                            <Icon icon="copy" size={16} />
                                        </Text>
                                        {"\u00A0\u00A0"}
                                        <Text color="primary" variant="body-sm">
                                            {
                                                this.state.scenarioTemplates.find(
                                                    (template) => template.id === selectedScenario.templateId
                                                )?.name
                                            }
                                        </Text>
                                    </Row>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{strings("Комментарий")}</TableCell>
                                <TableCell>
                                    <Row verticalAlign="middle">
                                        <Text color="primary" variant="body-sm">
                                            {selectedScenario?.comment}
                                        </Text>
                                    </Row>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Row marginTop={24} marginBottom={24}>
                        <Text color="primary" variant="body">
                            {strings("Интервалы выполнения")}
                        </Text>
                    </Row>
                    <Table isFixed noPadding>
                        <TableBody>
                            <TableRow>
                                <TableCell>{strings("Время")}</TableCell>
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {strings(this.TimeMap[selectedScenario.workPeriods?.timeType])}
                                    </Text>

                                    <Row marginTop={4}>
                                        <div>
                                            {selectedScenario.workPeriods?.times?.map((item) => (
                                                <div key={uuid()}>
                                                    {this.timeItemMap(item, selectedScenario.workPeriods?.timeType)}
                                                </div>
                                            ))}
                                        </div>
                                    </Row>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>{strings("Дата")}</TableCell>
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {strings(this.DateMap[selectedScenario.workPeriods?.dateType])}
                                    </Text>

                                    <Row marginTop={4}>
                                        <div>
                                            {selectedScenario.workPeriods?.dates?.map((item, index) => (
                                                <div key={uuid()}>
                                                    {this.dateItemMap(
                                                        item,
                                                        index,
                                                        selectedScenario.workPeriods?.dateType
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </Row>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    DetailsTabDevices = ({ selectedScenario }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Row marginTop={24} marginBottom={16}>
                        <Text color="primary" variant="body">
                            {`${strings("Сценарий реагирования распространяется на")} ${
                                selectedScenario.deviceIds.length
                            } ${strings("устройств")}`}
                        </Text>
                    </Row>

                    <Table bgColor="dark">
                        <TableBody>
                            {this.state.devices.map((device) => (
                                <TableRow key={device.id}>
                                    <TableCell verticalAlign="middle">
                                        <ScenariosDeviceTooltip device={device}>
                                            <Text color={device?.statusCode} variant="body-sm">
                                                <GeneralContextConsumer>
                                                    {(generalInfo) => {
                                                        const deviceTypes = getDeviceTypes(generalInfo);
                                                        const icon = deviceTypes.find(
                                                            (dt) => dt.value === device.typeId
                                                        )?.icon?.type;

                                                        return (
                                                            <>
                                                                <DeviceIcon icon={icon ?? "camera"} size={16} />
                                                            </>
                                                        );
                                                    }}
                                                </GeneralContextConsumer>
                                            </Text>
                                            <Space />
                                            <Space />
                                            <Text variant="body-sm" color="primary">
                                                {device?.name}
                                            </Text>
                                        </ScenariosDeviceTooltip>
                                    </TableCell>
                                    <TableCell verticalAlign="middle">
                                        <Text variant="body-sm" color="primary">
                                            {device?.location?.name}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    render() {
        const { selectedScenario, detailsTab, setDetailsTab, editScenario, playPauseScenario, strings, userInfo } =
            this.props;
        return (
            <EventsLayoutSecondary>
                <DetailsBox>
                    <DetailsBoxHeader sticky>
                        <DetailsBoxTitle>
                            <Text color="primary" variant="title" component="h2">
                                {selectedScenario.name}
                            </Text>
                        </DetailsBoxTitle>

                        <DetailsBoxHeaderActions>
                            {permissionExists(userInfo, [
                                "scenarios.service.access",
                                "scenarios.scenario.statusChange",
                            ]) ? (
                                <div>
                                    {selectedScenario?.enabled ? (
                                        <Button
                                            label={strings("Приостановить")}
                                            icon="pause"
                                            variant="secondary-warning"
                                            onClick={() => playPauseScenario(selectedScenario, false)}
                                        />
                                    ) : (
                                        <Button
                                            label={strings("Продолжить")}
                                            icon="refresh"
                                            variant="secondary-success"
                                            onClick={() => playPauseScenario(selectedScenario, true)}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div />
                            )}

                            {permissionExists(userInfo, [
                                "scenarios.service.access",
                                "scenarios.scenario.lifeCycle",
                            ]) ? (
                                <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                    <DropdownItem onClick={() => editScenario(selectedScenario)} as="button">
                                        {strings("Редактировать")}
                                    </DropdownItem>

                                    <DropdownDivider />

                                    <DropdownItem
                                        onClick={() => this.props.deleteScenario(selectedScenario)}
                                        as="button"
                                        colorVariant="danger"
                                    >
                                        {strings("Удалить")}
                                    </DropdownItem>
                                </Dropdown>
                            ) : (
                                <div />
                            )}

                            <Button icon="close" variant="secondary" onClick={() => this.handleCloseDetailedView()} />
                        </DetailsBoxHeaderActions>
                    </DetailsBoxHeader>

                    <DetailsBoxTabs>
                        <TabPanel>
                            <Tab
                                label={strings("Общее")}
                                isActive={detailsTab === "main"}
                                onClick={() => setDetailsTab("main")}
                            />
                            <Tab
                                label={strings("Оборудование")}
                                isActive={detailsTab === "devices"}
                                onClick={() => setDetailsTab("devices")}
                            />
                        </TabPanel>
                    </DetailsBoxTabs>

                    {detailsTab === "main" && this.DetailsTabMain({ selectedScenario })}

                    {detailsTab === "devices" && this.DetailsTabDevices({ selectedScenario })}
                </DetailsBox>
            </EventsLayoutSecondary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps)(withCultureContext(withSnackbar(ScenariosDetails)));

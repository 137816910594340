import React from "react";
import {
    Text,
    Table,
    TableHead,
    TableBody,
    TableHeadCell,
    TableRow,
    TableCell,
    Pagination,
    Button,
} from "headpoint-react-components";
import moment from "moment";
import { DetailsBoxContent } from "../DetailsBox";
import { EventsLayoutPaginationWrapper } from "../../../layout/EventsLayout";
import { withSnackbar } from "notistack";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import styled from "styled-components";

const TableScrollWrapper = styled.div`
    display: block;
    max-height: 70vh;
    overflow: auto;
    padding: 10px;
`;

class DetailsTabEvents extends React.Component {
    render() {
        const {
            events,
            eventsCount,
            countOnPage,
            locationNames,
            pageNumber,
            defaultEventTypes,
            handleShowMore,
            handlePage,
        } = this.props;

        const pagesCount = Math.ceil(eventsCount / countOnPage);
        let offset = Math.min(pageNumber > 3 ? pageNumber - 3 : 0, eventsCount - 5);
        offset < 0 && (offset = 0);

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <TableScrollWrapper>
                            <Table isFixed noPadding>
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCell>{strings("Источник")}</TableHeadCell>
                                        <TableHeadCell>{strings("Тип")}</TableHeadCell>
                                        <TableHeadCell>{strings("Дата")}</TableHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {events?.map((row) => {
                                        let rowColor = row.isRead ? "secondary" : "white";
                                        let nameColor;
                                        switch (row.severity) {
                                            case "danger":
                                                nameColor = "danger";
                                                break;

                                            case "warning":
                                                nameColor = "warning";
                                                break;

                                            default:
                                                nameColor = "white";
                                                break;
                                        }

                                        return (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Text color={nameColor} variant="body-sm">
                                                        {row.deviceName ?? row.deviceId}
                                                    </Text>
                                                    <br />
                                                    <Text color="secondary" variant="description">
                                                        {locationNames instanceof Map
                                                            ? locationNames.get(row.locationId)
                                                            : row.locationId}
                                                    </Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text color={rowColor} variant="body-sm">
                                                        {strings(
                                                            defaultEventTypes.find((et) => et.value === row.type)?.unit
                                                                ?.name ?? row.type
                                                        )}
                                                    </Text>
                                                </TableCell>
                                                <TableCell>
                                                    <Text color={rowColor} variant="body-sm">
                                                        {row.eventDate
                                                            ? moment(row.eventDate).format("DD-MM-YYYY")
                                                            : ""}
                                                    </Text>
                                                    <br />
                                                    <Text color="secondary" variant="description">
                                                        {row.eventDate ? moment(row.eventDate).format("HH:mm:ss") : ""}
                                                    </Text>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableScrollWrapper>
                        <EventsLayoutPaginationWrapper>
                            <Button variant="secondary" label={strings("Показать ещё")} onClick={handleShowMore} />

                            <Pagination offset={offset} page={pageNumber} count={pagesCount} onChange={handlePage} />
                        </EventsLayoutPaginationWrapper>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(DetailsTabEvents);

import React from "react";
import { getDataFromSource, getImageStorageUrl } from "../utilites";
import { AspectBox, DetailsBoxContent } from "../../../../components/DetailsBox";
import FullImageModal from "./FullImageModal";
import styled from "styled-components";

export class FirstImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullImage: false,
        };
    }

    render() {
        const { media, selectedEvent } = this.props;
        const sources = getDataFromSource(media.source, selectedEvent);

        const firstSource = sources?.find((s) => s?.type.startsWith("image"))
        if (!firstSource) {
            return null
        }

        const imageUrl = getImageStorageUrl(firstSource.id, media.storage);
        return (
            <>
                {this.state.isFullImage ? (
                    <FullImageModal imageUrl={imageUrl} handleClose={() => this.setState({ isFullImage: false })} />
                ) : null}
                <DetailsBoxContent>
                    <AspectBox aspect="16:9">
                        <ImageLink src={imageUrl} alt={imageUrl} onClick={() => this.setState({ isFullImage: true })} />
                    </AspectBox>
                </DetailsBoxContent>
            </>
        );
    }
}

const ImageLink = styled.img`
    cursor: pointer;
`;

import React from "react";
import { ServicesTable } from "./ServicesTable";
import { EventsLayoutPrimary, EventsLayoutHeader, EventsLayoutHeaderTitle } from "../../../../layout/EventsLayout";
import { Text } from "headpoint-react-components";
import ServiceDetails from "./ServiceDetails";
import { withSnackbar } from "notistack";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class ServicesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedService: null,
        };
    }

    render() {
        const { selectedService } = this.state;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <EventsLayoutPrimary hasSecondary={true}>
                            <EventsLayoutHeader>
                                <EventsLayoutHeaderTitle>
                                    <Text variant="title" component="h1">
                                        {strings("Активные сервисы")}
                                    </Text>
                                </EventsLayoutHeaderTitle>
                            </EventsLayoutHeader>
                            <ServicesTable
                                selectedService={selectedService}
                                select={(service) => this.setState({ selectedService: service })}
                            />
                        </EventsLayoutPrimary>
                        {selectedService && (
                            <ServiceDetails
                                selectedService={selectedService}
                                select={(service) => this.setState({ selectedService: service })}
                            />
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(ServicesSettings);

import React from "react";
import { withSnackbar } from "notistack";
import { DeviceTagsTable } from "./DeviceTagsTable";
import DeviceTagsDetails from "./details/DeviceTagsDetails";
import { DeleteModal } from "../DeleteModal";
import {
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
    EventsLayoutPrimary,
    EventsLayoutPrimaryInner,
} from "../../../../layout/EventsLayout";
import { Dropdown, DropdownItem, ExpandableSearch, Text } from "headpoint-react-components";
import { CreateTag, GetTags, UpdateTag, DeleteTag, GetTagEntities } from "../../../../services/tags";
import pLimit from "p-limit";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../CommonTablePagination";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { wizardSteps } from "./constants";
import { CreateUpdateWizard } from "../../../components/CreateUpdateWizard/CreateUpdateWizard";
import { WIZARD_CREATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const limit = pLimit(10);

const defaultValues = {
    name: "",
    entityIds: [],
    properties: {
        comment: "",
    },
};

class DeviceTagsSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            tags: [],
            search: "",
            showModal: false,
            mode: WIZARD_CREATE_MODE,
            values: defaultValues,
            pageData: { start: 0, end: ITEMS_ON_PAGE },
            isLoading: false,
            step: 1,
            activeStep: 1,
        };
    }

    componentDidMount = async () => {
        const { enqueueSnackbar, strings } = this.props;
        const [tagStatus, tags] = await GetTags();
        if (!tagStatus) {
            enqueueSnackbar(strings("Ошибка получения тегов оборудования"), { variant: "error" });
            return;
        }

        this.setState({
            tags: tags ?? [],
        });

        const input = tags?.map((t) => limit(async () => [t.id, ...(await GetTagEntities(t.id))])) ?? [];
        Promise.all(input).then((responses) => {
            const successTagEntitiesResponses = responses.filter(([_, status]) => status);
            const failedTagEntitiesResponses = responses.filter(([_, status]) => !status);

            if (failedTagEntitiesResponses?.length) {
                this.props.enqueueSnackbar(strings("Ошибка получения списка устройств обзора"), { variant: "error" });
                console.error(failedTagEntitiesResponses);
            }

            const tagsEntities = successTagEntitiesResponses.reduce(
                (tagsEntities, [id, _, entities]) => ({ ...tagsEntities, [id]: entities }),
                {}
            );
            const newTags = tags?.map((t) => ({ ...t, entityIds: tagsEntities[t.id] }));
            this.setState({ tags: newTags });
        });
    };

    setValues = (field, value) => {
        setTimeout(() => {
            const { values } = this.state;
            this.setState({ values: { ...values, [field]: value } });
        }, 0);
    };

    createHandler = async () => {
        const { enqueueSnackbar, strings } = this.props;
        const { values, tags } = this.state;
        if (!values.name) {
            enqueueSnackbar(strings("Тег нельзя сохранить без названия"), { variant: "error" });
            return;
        }

        this.setState({ isLoading: true });

        try {
            const [status, statusCode, id] = await CreateTag(values);
            if (status) {
                enqueueSnackbar(`${strings("Тег оборудования")} '${values.name}' ${strings("создан")}`, {
                    variant: "success",
                });
                tags.push({ ...values, id });
                this.setState({
                    tags,
                    showModal: false,
                    mode: WIZARD_CREATE_MODE,
                    values: defaultValues,
                    isLoading: false,
                    step: 1,
                    activeStep: 1,
                });
            } else {
                switch (statusCode) {
                    case 409:
                        enqueueSnackbar(strings("Название тега оборудования не должно повторяться"), {
                            variant: "warning",
                        });
                        break;
                    default:
                        enqueueSnackbar(`${strings("Ошибка создания тега оборудования")} '${values.name}'`, {
                            variant: "error",
                        });
                        break;
                }

                this.setState({
                    isLoading: false,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    updateHandler = async () => {
        const { enqueueSnackbar, strings } = this.props;
        let { values, tags, selected } = this.state;
        if (!values.name) {
            enqueueSnackbar(strings("Тег нельзя сохранить без названия"), { variant: "error" });
            return;
        }

        this.setState({ isLoading: true });

        try {
            const [status, statusCode] = await UpdateTag(values);
            if (status) {
                enqueueSnackbar(`${strings("Тег оборудования")} '${values.name}' ${strings("сохранен")}`, {
                    variant: "success",
                });
                tags = tags.filter((tag) => tag.id !== values.id);
                tags.push(values);
                this.setState({
                    tags,
                    showModal: false,
                    mode: WIZARD_CREATE_MODE,
                    values: defaultValues,
                    selected: selected ? values : null,
                    isLoading: false,
                    step: 1,
                    activeStep: 1,
                });
            } else {
                switch (statusCode) {
                    case 409:
                        enqueueSnackbar(strings("Название тега оборудования не должно повторяться"), {
                            variant: "warning",
                        });
                        break;
                    default:
                        enqueueSnackbar(`${strings("Ошибка сохранения тега оборудования")} '${values.name}'`, {
                            variant: "error",
                        });
                        break;
                }

                this.setState({
                    isLoading: false,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    removeHandler = async () => {
        let { values, tags } = this.state;
        const { enqueueSnackbar, strings } = this.props;
        const [status, statusCode] = await DeleteTag(values.id);
        if (!status) {
            switch (statusCode) {
                case 409:
                    enqueueSnackbar(strings("Нельзя удалить тег, который содержит устройства"), { variant: "warning" });
                    break;
                default:
                    enqueueSnackbar(`${strings("Ошибка удаления тега оборудования")} '${values.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        } else {
            enqueueSnackbar(`${strings("Тег оборудования")} '${values.name}' ${strings("удален")}`, {
                variant: "success",
            });
            tags = tags.filter((tag) => tag.id !== values.id);
            this.setState({
                tags,
                showModal: false,
                mode: WIZARD_CREATE_MODE,
                values: defaultValues,
                selected: null,
            });
        }
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    render() {
        let { selected, tags, search, showModal, mode, values, isLoading, pageData, step, activeStep } = this.state;
        const { strings } = this.props;
        tags = tags?.filter((t) => t?.name?.toLowerCase().includes(search.toLowerCase()));

        return (
            <>
                {isLoading && <LoaderAnimation message={strings("Применение настроек")} />}
                <CreateUpdateWizard
                    visible={showModal && mode !== "delete"}
                    CreateHandler={this.createHandler}
                    EditHandler={this.updateHandler}
                    CloseHandler={() =>
                        this.setState({
                            showModal: false,
                            mode: WIZARD_CREATE_MODE,
                            values: defaultValues,
                            step: 1,
                            activeStep: 1,
                        })
                    }
                    values={values}
                    title={mode === WIZARD_CREATE_MODE ? strings("Создать тег") : strings("Редактировать тег")}
                    step={step}
                    activeStep={activeStep}
                    NextStep={this.nextStep}
                    SetActiveStep={this.selectStep}
                    SetValuesProperty={this.setValues}
                    steps={wizardSteps}
                    Mode={mode}
                />

                <DeleteModal
                    visible={showModal && mode === "delete"}
                    RemoveHandler={this.removeHandler}
                    CloseHandler={() =>
                        this.setState({
                            showModal: false,
                            mode: WIZARD_CREATE_MODE,
                            values: defaultValues,
                        })
                    }
                    text={`${strings("Вы хотите удалить тэг")} "${values?.name}". ${strings(
                        "Удалённый тэг нельзя восстановить. Продолжить?"
                    )}`}
                />
                <EventsLayoutPrimary hasSecondary={!!selected}>
                    <EventsLayoutPrimaryInner>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings("Теги оборудования")}
                                </Text>
                                <ExpandableSearch
                                    placeholder={strings("Найти")}
                                    inHeader
                                    value={search}
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                />
                            </EventsLayoutHeaderTitle>
                            <EventsLayoutHeaderToolbox>
                                <EventsLayoutHeaderToolboxItem>
                                    <Dropdown
                                        toggleLabel={strings("Создать")}
                                        closeOnClickInside
                                        toggleVariant="secondary"
                                        horizontalAlignment="right"
                                    >
                                        <DropdownItem
                                            as="button"
                                            onClick={() =>
                                                this.setState({
                                                    showModal: true,
                                                    mode: WIZARD_CREATE_MODE,
                                                    values: defaultValues,
                                                })
                                            }
                                        >
                                            {strings("Тег")}
                                        </DropdownItem>
                                    </Dropdown>
                                </EventsLayoutHeaderToolboxItem>
                            </EventsLayoutHeaderToolbox>
                        </EventsLayoutHeader>
                        <DeviceTagsTable
                            tags={tags.slice(pageData.start, pageData.end)}
                            selected={selected}
                            setSelected={(tag) => this.setState({ selected: tag })}
                        />
                    </EventsLayoutPrimaryInner>
                    <CommonTablePagination
                        tableData={tags}
                        setPaging={(pageData) => this.setState({ pageData })}
                        searchMode={search !== ""}
                    />
                </EventsLayoutPrimary>
                {selected && (
                    <DeviceTagsDetails
                        key={`${selected?.id}/${selected?.entityIds?.join("/")}`}
                        tag={selected}
                        setSelected={(tag) => this.setState({ selected: tag })}
                        deleteHandler={() =>
                            this.setState({
                                showModal: true,
                                mode: "delete",
                                values: selected,
                            })
                        }
                        editHandler={() =>
                            this.setState({
                                showModal: true,
                                mode: "edit",
                                values: selected,
                            })
                        }
                    />
                )}
            </>
        );
    }
}

export default withCultureContext(withSnackbar(DeviceTagsSettings));

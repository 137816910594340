import React from "react";
import {
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Row,
    Text,
    Icon,
    TableHoverContainer,
    Button,
} from "headpoint-react-components";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { nanoid } from "nanoid";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class AdaptersTable extends React.Component {
    getIndexOf = (arr, k) => {
        for (let i = 0; i < arr.length; i += 1) {
            const child = arr[i] || {};

            if (child.id === k) {
                return {
                    childIndex: i,
                };
            }
        }

        return false;
    };

    handleShowDetails = (adapter) => {
        if (adapter) {
            this.props.setSelectedAdapter(adapter);
        }
    };

    render() {
        const { selectedAdapter } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("адаптеры")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.props.tableData
                                    .sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((adapter) => (
                                        <TableRow
                                            key={adapter.id ?? adapter.serviceId ?? nanoid()}
                                            isSelected={selectedAdapter && adapter.id === selectedAdapter.id}
                                        >
                                            <TableCell verticalAlign="middle">
                                                <Row align="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        <Icon icon="adapter" size={16} />
                                                        {"\u00A0\u00A0"}
                                                        {strings(adapter.name)}
                                                    </Text>
                                                </Row>
                                            </TableCell>
                                            <TableCell verticalAlign="middle">
                                                <TableHoverContainer>
                                                    <Row align="middle">
                                                        <Button
                                                            icon="info"
                                                            title="Info"
                                                            variant="ghost"
                                                            onClick={() => this.handleShowDetails(adapter)}
                                                        />
                                                    </Row>
                                                </TableHoverContainer>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { GetLocations, FillLocations, GetLocationList } from "../../../../../../services/locations";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { FilterAccordion } from "../../../../../components/Filters";
import { ModalSection, TextField, Space, Text, Row, Checkbox } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

const LOCATIONS_LIMIT = 10;

class LocationStepPosition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            filteredLocations: [],
            search: "",
        };
    }

    componentDidMount = async () => {
        const { strings } = this.props;
        const [status, locations] = await GetLocations();

        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        this.setState({
            devicesLocationsTree: FillLocations(locations, []),
        });
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const [status, locations] = await GetLocations();
            if (!status) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
            }

            this.setState({
                devicesLocationsTree: FillLocations(locations, []),
            });
        }
    };

    openLocation = async (locationNode) => {
        const { devicesLocationsTree } = this.state;
        const { values } = this.props;
        let [, children] = await GetLocations(locationNode.id);
        children = children.filter((l) => l.id !== values?.id);

        locationNode.children = FillLocations(children, []);

        this.setState({
            devicesLocationsTree: devicesLocationsTree,
        });
    };

    async setSearch(search) {
        const { strings } = this.props;

        if (search && search !== "") {
            let [locationsStatus, locations] = await GetLocationList({ like: search, limit: LOCATIONS_LIMIT });

            if (!locationsStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });

                return;
            }

            locations = locations.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });

            this.setState({ search, filteredLocations: locations });
        } else {
            this.setState({ search, filteredLocations: [] });
        }
    }

    render() {
        const { search, filteredLocations, devicesLocationsTree } = this.state;
        const { values, SetValuesProperty, locationExtensions, strings } = this.props;
        const extensions = locationExtensions?.filter((ext) => ext.settings.section === "position");

        return (
            <>
                <ModalSection>
                    <ModalFormItem>
                        <Text>{strings("Родительская локация:")}</Text>
                        <Space />
                        <Row marginBottom={12}>
                            <TextField
                                autoComplete={false}
                                colorVariant="light"
                                fullWidth
                                icon="search"
                                onChange={(e) => this.setSearch(e.target.value)}
                                placeholder={strings("Найти")}
                                type="search"
                                value={search}
                            />
                        </Row>
                        {search && search !== "" ? (
                            filteredLocations.map((location) => (
                                <Row marginBottom={12} key={location.id}>
                                    <Checkbox
                                        label={location.name}
                                        checked={values.parentId === location.id}
                                        onChange={() => {
                                            const parentId = values.parentId === location.id ? null : location.id;
                                            SetValuesProperty("parentId", parentId);
                                        }}
                                    />
                                </Row>
                            ))
                        ) : (
                            <FilterAccordion
                                items={devicesLocationsTree}
                                selected={[values.parentId]}
                                openItem={this.openLocation}
                                selectHandler={(items) => {
                                    let item = items.pop();
                                    SetValuesProperty("parentId", item);
                                }}
                            />
                        )}
                    </ModalFormItem>
                </ModalSection>
                <ModalSection>
                    <ModalFormItem>
                        <Row justify="between">
                            <TextField
                                label={strings("Широта")}
                                name="latitude"
                                type="number"
                                value={values.latitude}
                                onChange={(e) => SetValuesProperty("latitude", e.target.value)}
                                colorVariant="light"
                                error={
                                    (values.latitude && isNaN(values.latitude)) ||
                                    values.latitude < -90 ||
                                    values.latitude > 90
                                        ? "Значение должно быть числом в диапазоне от -90 до 90"
                                        : null
                                }
                                width={180}
                            />
                            <Space />
                            <TextField
                                label={strings("Долгота")}
                                name="longitude"
                                type="number"
                                value={values.longitude}
                                onChange={(e) => SetValuesProperty("longitude", e.target.value)}
                                colorVariant="light"
                                error={
                                    (values.longitude && isNaN(values.longitude)) ||
                                    values.longitude < -180 ||
                                    values.longitude > 180
                                        ? strings("Значение должно быть числом от -180 до 180")
                                        : null
                                }
                                width={180}
                            />
                        </Row>
                    </ModalFormItem>
                </ModalSection>
                {!!extensions?.length && (
                    <ModalSection>
                        <ModalFormItem>
                            {extensions.map((ext) => (
                                <ModalFormItem key={`${ext.entity}-${ext.name}`}>
                                    <Text>{ext.title ?? ext.name}</Text>
                                    <Space />
                                    <TextField
                                        name={ext.name}
                                        type={ext.type}
                                        value={values[ext.name] ?? ext.settings?.default}
                                        onChange={(e) => SetValuesProperty(ext.name, e.target.value)}
                                        colorVariant="light"
                                        fullWidth
                                        error={
                                            ext.settings?.required && !values[ext.name] ? ext.settings?.errorText : null // strings(ext.settings?.errorText)
                                        }
                                    />
                                </ModalFormItem>
                            ))}
                        </ModalFormItem>
                    </ModalSection>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(LocationStepPosition)));

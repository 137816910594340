export const GET_SCHEDULES = "/scheduler-api/schedule/list?";
export const GET_SCHEDULE = "/scheduler-api/schedule";
export const PLAY_SCHEDULE = "/scheduler-api/schedule/play";
export const PAUSE_SCHEDULE = "/scheduler-api/schedule/pause";

export const GetSchedules = async (filter) => {
    const token = window.localStorage.getItem("token");

    const queryParams = new URLSearchParams({
        countOnPage: filter?.countOnPage,
        pageNumber: filter?.pageNumber,
        like: filter?.like,
    });

    if (filter?.status.completed) {
        queryParams.append("statuses", "Completed");
    }
    if (filter?.status.paused) {
        queryParams.append("statuses", "Paused");
    }
    if (filter?.status.active) {
        queryParams.append("statuses", "Active");
    }

    let response = await fetch(GET_SCHEDULES + queryParams, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();
        return [true, responseBody.data, responseBody.count];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetScheduleInfo = async (schedule) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`/general-api/service/${schedule.serviceId}/schedule/${schedule.id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();
        return [true, responseBody];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetSchedule = async (id) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${GET_SCHEDULE}/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();
        return [true, responseBody.data];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const CreateSchedule = async (schedule, serviceId) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`/general-api/service/${serviceId}/schedule`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(schedule),
        });

        let responseBody = await response.json();
        return [response.status, responseBody];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const UpdateSchedule = async (schedule, serviceId) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`/general-api/service/${serviceId}/schedule`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(schedule),
        });

        let responseBody = await response.json();
        return [response.status, responseBody];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const PlaySchedule = async (id) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${PLAY_SCHEDULE}/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
};

export const PauseSchedule = async (id) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${PAUSE_SCHEDULE}/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status === 200) {
        return true;
    } else {
        return false;
    }
};

export const DeleteSchedule = async (schedule) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`/general-api/service/${schedule.serviceId}/schedule/${schedule.id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, response.status];
        }

        return [response.status];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

import React from "react";
import {
    ChartWrapper,
    CustomizedYTick,
    CustomizedXTick,
    CustomTooltipWrapper,
    CustomTooltipTitle,
    CustomTooltipRow,
    CustomTooltipRowLabel,
    TableCell,
    Select,
    Button,
    Row,
} from "headpoint-react-components";
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts";
import { CustomBrush } from "./customRecharts/CustomBrush";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChart, saveDates, saveSection } from "../../../../app/reducers/reportsReducer";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { withSnackbar } from "notistack";
import moment from "moment";

const Chart = ({ selectedPlugin, rowId, sections, enqueueSnackbar, strings }) => {
    const dispatch = useDispatch();

    const data = useSelector((state) => state.reports.chartData);
    const dates = useSelector((state) => state.reports.dates);
    const selectedSection = useSelector((state) => state.reports.chartSection);

    const [chartIndex, setChartIndex] = useState();

    const timelineKey = selectedSection?.values?.find((val) => val.type === "label")?.key;
    const lineKeys = selectedSection?.values?.filter((val) => val.type === "line");

    useEffect(() => {
        if (selectedSection) {
            dispatch(
                fetchChart({
                    id: selectedPlugin.id,
                    serviceCode: selectedPlugin.service,
                    item: rowId,
                    dates: dates,
                    sectionType: selectedSection?.typeId,
                    enqueueSnackbar,
                    strings,
                })
            );
        } else {
            dispatch(saveSection(sections[0]));
        }
    }, [selectedPlugin, dates, selectedSection, rowId, sections, dispatch, enqueueSnackbar, strings]);

    const CustomTooltip = ({ active, payload, label }) =>
        active && payload ? (
            <CustomTooltipWrapper>
                <CustomTooltipTitle>{payload[0]?.payload[timelineKey]}</CustomTooltipTitle>
                {payload.map((pl) => (
                    <CustomTooltipRow key={pl.dataKey}>
                        <CustomTooltipRowLabel>
                            {strings(lineKeys?.find((key) => key.key === pl.dataKey)?.name)}
                        </CustomTooltipRowLabel>
                        <div style={{ color: pl?.stroke === "#FFFFFF" ? null : pl?.stroke }}>
                            {pl?.value % 1 === 0 ? pl?.value : pl?.value?.toFixed(2)}
                        </div>
                    </CustomTooltipRow>
                ))}
            </CustomTooltipWrapper>
        ) : null;

    return (
        <>
            <TableCell colSpan="2">
                <Row marginBottom={12} align={"bottom"} justify={"between"}>
                    <Select
                        placeholder={"Выбрать параметр"}
                        value={selectedSection?.typeId}
                        onChange={(typeId) => {
                            dispatch(saveSection(sections.find((s) => s.typeId === typeId)));
                        }}
                        options={sections.map((section) => ({
                            value: section.typeId,
                            label: strings(section.name),
                        }))}
                        horizontalAlignment="left"
                        isInline
                    />
                    <Button
                        icon="check"
                        variant="secondary"
                        onClick={() => {
                            if (!chartIndex) {
                                return;
                            }

                            dispatch(
                                saveDates({
                                    from:
                                        chartIndex?.startIndex > 0
                                            ? moment(data[chartIndex.startIndex][timelineKey], "DD.MM.YYYY HH:mm")._d
                                            : dates.from,
                                    to:
                                        chartIndex?.endIndex < data.length - 1
                                            ? moment(data[chartIndex.endIndex][timelineKey], "DD.MM.YYYY HH:mm")._d
                                            : dates.to,
                                })
                            );
                        }}
                    />
                </Row>
                <ChartWrapper style={{ width: "100%", height: 500 }}>
                    <ResponsiveContainer>
                        <AreaChart data={data}>
                            <defs>
                                <linearGradient id="gradientWhite" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="rgb(255, 255, 255)" stopOpacity={0.04} />
                                    <stop offset="95%" stopColor="rgb(255, 255, 255)" stopOpacity={0} />
                                </linearGradient>

                                <linearGradient id="gradientYellow" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="rgb(45, 201, 145)" stopOpacity={0.05} />
                                    <stop offset="95%" stopColor="rgb(45, 201, 145)" stopOpacity={0} />
                                </linearGradient>

                                <linearGradient id="gradientRed" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="rgb(255, 69, 102)" stopOpacity={0.12} />
                                    <stop offset="95%" stopColor="rgb(255, 69, 102)" stopOpacity={0} />
                                </linearGradient>
                            </defs>

                            {lineKeys?.map((val) => (
                                <Area
                                    key={val.key}
                                    dataKey={val.key}
                                    isAnimationActive={false}
                                    stroke={val.color}
                                    strokeWidth={2}
                                    fillOpacity={1}
                                    fill={`url(#${val.gradient})`}
                                />
                            ))}

                            <XAxis dataKey={timelineKey} mirror={true} tickSize={0} tick={<CustomizedXTick />} />
                            <YAxis mirror={true} tickSize={0} tick={<CustomizedYTick />} />
                            <CartesianGrid />
                            <Tooltip content={<CustomTooltip />} animationDuration={0} />
                            <CustomBrush dataKey={timelineKey} height={24} onChange={(index) => setChartIndex(index)} />
                        </AreaChart>
                    </ResponsiveContainer>
                </ChartWrapper>
            </TableCell>
        </>
    );
};

export default withCultureContext(withSnackbar(Chart));

import React from "react";
import { DetailsTabMain } from "./DetailsTabMain";
import { Button, Dropdown, DropdownItem, DropdownDivider, Text, Tab, TabPanel } from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
} from "../../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class VariableDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "main",
        };
    }

    handleCloseDetailedView = () => this.props.setSelectedVariable();

    render() {
        const { selected, editVariable, deleteVariable, setSelected } = this.props;
        const { tab } = this.state;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {strings(selected?.name) ?? "..."}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                        <DropdownItem as="button" onClick={() => editVariable(selected)}>
                                            {strings("Редактировать")}
                                        </DropdownItem>

                                        <DropdownDivider />

                                        <DropdownItem
                                            as="button"
                                            onClick={() => deleteVariable(selected)}
                                            colorVariant="danger"
                                        >
                                            {strings("Удалить")}
                                        </DropdownItem>
                                    </Dropdown>

                                    <Button icon="close" variant="secondary" onClick={() => setSelected(null)} />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    <Tab
                                        label={strings("Общее")}
                                        isActive={tab === "main"}
                                        onClick={() => this.setState({ tab: "main" })}
                                    />
                                </TabPanel>
                            </DetailsBoxTabs>

                            {tab === "main" && <DetailsTabMain selected={selected} />}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

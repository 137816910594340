import moment from "moment";

export const getTimeRange = (preset) => {
    let newDates = {};

    switch (preset) {
        case "lastHour":
            newDates = {
                from: moment().subtract(1, "hours")._d,
                to: moment()._d,
            };
            break;

        case "lastSixHours":
            newDates = {
                from: moment().subtract(6, "hours")._d,
                to: moment()._d,
            };
            break;

        case "lastDay":
            newDates = {
                from: moment().subtract(24, "hours")._d,
                to: moment()._d,
            };
            break;

        case "lastMonth":
            newDates = {
                from: moment().subtract(1, "months")._d,
                to: moment()._d,
            };
            break;

        default:
            newDates = {
                form: undefined,
                to: undefined,
            };
    }

    return newDates;
};

export const convertLocalToUTC = (localTime) => {
    if (!localTime) {
        return localTime;
    }
    return new Date(`${new Date().toJSON().slice(0, 10)} ${localTime}`).toISOString().slice(11, 16);
};

export const convertUTCToLocal = (UTCTime) => {
    if (!UTCTime) {
        return UTCTime;
    }
    const currentUTCDate = new Date().toISOString().substring(0, 10);
    const inputUTCDateTime = `${currentUTCDate}T${UTCTime}:00.000Z`;
    const localDate = new Date(inputUTCDateTime);
    return moment(localDate).format("HH:mm");
};

export const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber);

    // Using the browser's default locale.
    return date.toLocaleString([], { month: "long" });
};

export const getTimeInterval = (interval) => {
    if (interval.repeatType === "m") {
        return (
            getNoun(interval?.repeatValue, "каждую ", "каждые ", "каждые ") +
            interval?.repeatValue +
            getNoun(interval?.repeatValue, " минуту", " минуты", " минут")
        );
    } else {
        return (
            getNoun(interval?.repeatValue, "каждый ", "каждые ", "каждые ") +
            interval?.repeatValue +
            getNoun(interval?.repeatValue, " час", " часа", " часов")
        );
    }
};

const getNoun = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};

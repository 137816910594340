import React, { Component } from "react";
import styled from "styled-components";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Icon,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Text,
    Tooltip,
    Pagination,
    ExpandableSearch,
    Dropdown,
    DropdownItem,
    DropdownDivider,
} from "headpoint-react-components";
import { CancelArchiveOrder, CountArchiveOrders, GetArchiveOrder, GetArchiveOrders } from "../../../services/archive";
import { GetLocationNames } from "../../../services/locations";
import { withSnackbar } from "notistack";
import { GetDeviceInfo } from "../../../services/devices";
import { CheckArchiveFile, GetArchiveFile, GetFileMetadata } from "../../../services/storage";
import { permissionExists } from "../../../contexts";
import moment from "moment/moment";
import ArchiveConverterModal from "./ArchiveConverterModal";
import { CreateConverterOrder } from "../../../services/converter";
import { connect } from "react-redux";

const ORDERS_ON_PAGE = 10;

class ArchiveOrderListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countOnPage: ORDERS_ON_PAGE,
            archiveOrders: [],
            count: 0,
            currentPage: 1,
            search: "",
            selectedArchiveRecord: null,
            selectedCameraName: null,
        };
    }

    async componentDidMount() {
        const [count, archiveOrders] = await this.getOrdersAndCount();
        this.setState({ archiveOrders, count });
    }

    async getOrdersAndCount(pageNumber, limit, searchString) {
        const { strings } = this.props;

        const [countStatus, count] = await CountArchiveOrders(searchString);
        const [orderStatus, archiveOrders] = await GetArchiveOrders(
            pageNumber ?? 1,
            limit ?? ORDERS_ON_PAGE,
            searchString
        );

        if (!countStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить количество заказов"), { variant: "error" });
        }

        if (!orderStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить очередь заказов"), { variant: "error" });
        }

        await this.updateCameraNamesAndLocations(archiveOrders?.map((t) => t.cameraId));
        return [count, archiveOrders];
    }

    handleShowMore = async () => {
        let { countOnPage, search } = this.state;
        const [count, archiveOrders] = await this.getOrdersAndCount(1, countOnPage + ORDERS_ON_PAGE, search);

        this.setState({
            countOnPage: countOnPage + ORDERS_ON_PAGE,
            archiveOrders: archiveOrders,
            count,
            currentPage: 1,
        });
    };

    handlePage = async (page) => {
        const { currentPage, countOnPage, search } = this.state;

        if (currentPage === page) {
            return;
        }

        const [count, orders] = await this.getOrdersAndCount(page, countOnPage, search);

        this.setState({ currentPage: page, archiveOrders: orders, count });
    };

    handleRefresh = async () => {
        const [count, archiveOrders] = await this.getOrdersAndCount();

        this.setState({ archiveOrders, count, currentPage: 1, countOnPage: ORDERS_ON_PAGE, search: "" });
    };

    async updateCameraNamesAndLocations(cameraIds) {
        const camerasWithLocations = await this.getCameraNamesAndLocationIds(cameraIds);
        const cameras = camerasWithLocations ? Array.from(camerasWithLocations?.values()) : [];
        const locationIds = cameras.map((c) => c.locationId);
        const locationNames = await this.updateLocationNames(locationIds);

        this.setState({ camerasWithLocations, locationNames });
    }

    async getCameraNamesAndLocationIds(cameraIds) {
        const { camerasWithLocations } = this.state;
        const { strings } = this.props;

        const uniqueIds = [...new Set(cameraIds)];

        const newIds = camerasWithLocations ? uniqueIds.filter((id) => !camerasWithLocations?.has(id)) : uniqueIds;

        if (newIds?.length === 0) {
            return camerasWithLocations ?? new Map();
        }

        const [status, devices] = await GetDeviceInfo(newIds);
        if (!status) {
            this.props.enqueueSnackbar(strings("Не удалось получить камеры"), { variant: "error" });
        }

        const names = new Map(
            devices.map((d) => {
                return [d.id, { name: d.name, locationId: d.locationId }];
            })
        );

        if (camerasWithLocations) {
            return new Map([...camerasWithLocations, ...names]);
        }

        return names;
    }

    async updateLocationNames(locationIds) {
        const { generalInfo, strings } = this.props;
        const { locationNames } = this.state;
        if (!generalInfo.services.some((s) => s.code === "service.api.locations")) {
            return;
        }

        const uniqueIds = [...new Set(locationIds)].filter((id) => id !== null);

        const newIds = locationNames ? uniqueIds.filter((id) => !locationNames.has(id)) : uniqueIds;

        if (newIds.length === 0) {
            return locationNames ? locationNames : new Map();
        }

        const [status, names] = await GetLocationNames(newIds);
        if (!status) {
            this.props.enqueueSnackbar(strings("Не удалось получить локации"), { variant: "error" });
        }

        if (locationNames) {
            return new Map([...locationNames, ...names]);
        }

        return names;
    }

    async handleDownload(archiveOrder, cameraName) {
        const { strings } = this.props;

        const fileUrl = archiveOrder?.properties?.archiveUrl;
        const fileName = this.buildFileName(cameraName, archiveOrder);

        if (!fileUrl) {
            this.props.enqueueSnackbar(`${strings("Не удалось получить ссылку для загрузки файла")} '${fileName}'`, {
                variant: "error",
            });
            return;
        }

        const fileExists = await CheckArchiveFile(fileUrl);
        if (!fileExists) {
            this.props.enqueueSnackbar(`${strings("Файл")} '${fileName}' ${strings("не найден")}`, {
                variant: "error",
            });
            return;
        }

        const [fileMetadataStatus, fileMetadata] = await GetFileMetadata(archiveOrder.id);
        if (!fileMetadataStatus) {
            this.props.enqueueSnackbar(`${strings("Не удалось получить metadata файла")} '${fileName}'`, {
                variant: "error",
            });
            return;
        }

        const fullFileName = this.buildFullFileName(cameraName, archiveOrder, fileMetadata);
        await GetArchiveFile(fileUrl, fullFileName);
        this.props.enqueueSnackbar(`${strings("Загрузка файла")} '${fileName}' ${strings("запущена")}`, {
            variant: "success",
        });
    }

    buildFullFileName(cameraName, archiveOrder, fileMetadata) {
        const fileName = this.buildFileName(cameraName, archiveOrder);
        const extension = this.getFileExtension(fileMetadata);
        return fileName + extension;
    }

    buildFileName(cameraName, archiveOrder) {
        const timeFrom = moment(archiveOrder?.from).local().format("YYYY-MM-DDHH:mm:ss");
        const timeTo = moment(archiveOrder?.to).local().format("YYYY-MM-DDHH:mm:ss");
        return `${cameraName}_${timeFrom} - ${timeTo}`;
    }

    getFileExtension(fileMetadata) {
        const pointIndex = fileMetadata?.fileName?.lastIndexOf(".") ?? -1;
        if (pointIndex >= 0 && pointIndex < fileMetadata?.fileName?.length - 1) {
            return fileMetadata.fileName.substring(pointIndex);
        }

        return ".unk";
    }

    async handleCancel(orderId, serviceId) {
        let { archiveOrders } = this.state;

        const [status, code] = await CancelArchiveOrder(orderId, serviceId);

        if (!status && code === 404) {
            const [orderReceived, archiveOrder] = await GetArchiveOrder(orderId);

            if (orderReceived) {
                const index = archiveOrders.indexOf((at) => at.id === orderId);
                archiveOrders[index] = archiveOrder;

                this.setState({ archiveOrders });
            }
        }
    }

    async setSearch(search) {
        const [count, archiveOrders] = await this.getOrdersAndCount(1, ORDERS_ON_PAGE, search);

        this.setState({
            countOnPage: ORDERS_ON_PAGE,
            archiveOrders: archiveOrders,
            count,
            currentPage: 1,
            search,
        });
    }

    getArchiveOrderStatusAndColor(orderStatus) {
        const { strings } = this.props;

        let statusColor;
        let status = orderStatus ?? "Unknown";

        switch (status.toLowerCase()) {
            case "error":
                status = strings("Ошибка");
                statusColor = "danger";
                break;
            case "inprogress":
                status = strings("Подготовка");
                statusColor = "warning";
                break;
            case "done":
                status = strings("Готово");
                statusColor = "success";
                break;
            case "cancelled":
                status = strings("Отменен");
                statusColor = "secondary";
                break;
            default:
                statusColor = "secondary";
                break;
        }
        return [status, statusColor];
    }

    showConverterDialog(archive, cameraName) {
        return (
            <ArchiveConverterModal
                archive={archive}
                cameraName={cameraName}
                handleClose={() => this.setState({ isConverterDialogOpen: false })}
                strings={this.props.strings}
            ></ArchiveConverterModal>
        );
    }

    async createConverterOrder(cameraId, fileId, outputFormat, reason) {
        const order = {
            cameraId: cameraId,
            fileId: fileId,
            outputFormat: outputFormat,
            reason: reason,
        };

        return await CreateConverterOrder(order);
    }

    async handleConvertToAvi(row) {
        const { strings } = this.props;

        const [status] = await this.createConverterOrder(row.cameraId, row.id, "avi", "");

        if (status) {
            const cameraName = this.getCameraName(row.cameraId) ?? row.cameraId;
            const period = this.formatPeriod(row.from, row.to);
            this.props.enqueueSnackbar(
                `${strings("Заказ на конвертацию для")} '${cameraName}' ${strings("за")} ${period} ${strings(
                    "создан"
                )}`,
                {
                    variant: "success",
                }
            );
        } else {
            this.props.enqueueSnackbar(strings("Ошибка при создании заказа"), {
                variant: "error",
            });
        }
    }

    getCameraName(cameraId) {
        const cameraNameAndLocation = this.state.camerasWithLocations.get(cameraId);
        return cameraNameAndLocation?.name;
    }

    formatDate(date) {
        return moment(date).local().format("YYYY-MM-DD HH:mm:ss");
    }

    formatPeriod(from, to) {
        return `${this.formatDate(from)} — ${this.formatDate(to)}`;
    }

    formatOrderNumber = (row) => `${row.orderNumber}${row.orderSubnumber ? `-${row.orderSubnumber}` : ""}`;

    translateError(message) {
        const { strings } = this.props;

        if (message?.match("Не удалось выгрузить файл .+")) {
            const split = message.split(" ");
            return `${strings("Не удалось выгрузить файл")} ${split[4]} ${strings("камеры")} ${split[6]} ${strings(
                "из сервиса"
            )} ${split[9]}`;
        }

        if (message?.match("Не удалось загрузить файл .+")) {
            const split = message.split(" ");
            return `${strings("Не удалось загрузить файл")} ${split[4]} ${strings("камеры")} ${split[6]} ${strings(
                "на конечное хранилище"
            )}`;
        }

        if (message?.match("Не удалось авторизоваться на устройстве .+")) {
            const split = message.split(" ");
            return `${strings("Не удалось авторизоваться на устройстве")} ${split[5]}`;
        }

        return message;
    }

    render() {
        const { archiveOrders, currentPage, count, countOnPage, camerasWithLocations, locationNames, search } =
            this.state;
        const { strings } = this.props;

        const { userInfo } = this.props;

        const countPages = Math.ceil(count / countOnPage) ?? 0;
        let pagesOffset = Math.min(currentPage > 3 ? currentPage - 3 : 0, countPages - 5);
        pagesOffset < 0 && (pagesOffset = 0);

        return (
            <>
                {this.state.isConverterDialogOpen ? (
                    <ConverterModalWindow>
                        {this.showConverterDialog(this.state.selectedArchiveRecord, this.state.selectedCameraName)}
                    </ConverterModalWindow>
                ) : null}
                <Modal size="xlg" closeHandler={this.handleClose}>
                    <ModalHeader
                        title={strings("История заказов записей")}
                        closeHandler={this.props.handleClose}
                        actions={
                            <>
                                <ModalHeaderActionItem>
                                    <ExpandableSearch
                                        value={search}
                                        onChange={(e) => this.setSearch(e.target.value)}
                                        onSubmit={() => {}}
                                        placeholder={strings("Найти")}
                                        colorVariant="dark"
                                    />
                                </ModalHeaderActionItem>

                                <ModalHeaderActionItem>
                                    <Button variant="secondary" icon="refresh" onClick={this.handleRefresh} />
                                </ModalHeaderActionItem>
                            </>
                        }
                    />

                    <ModalBody>
                        <ModalTableWrapper>
                            <Table isFixed hasHover stickyHeader bgColor="light">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCell>№</TableHeadCell>
                                        <TableHeadCell>{strings("Дата заказа")}</TableHeadCell>
                                        <TableHeadCell>{strings("Камера")}</TableHeadCell>
                                        <TableHeadCell>{strings("Период")}</TableHeadCell>
                                        <TableHeadCell align="center">{strings("Причина")}</TableHeadCell>
                                        <TableHeadCell align="center">{strings("Статус")}</TableHeadCell>
                                        <TableHeadCell align="right">{strings("Действие")}</TableHeadCell>
                                        <TableHeadCell />
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {archiveOrders?.map((row) => {
                                        const isComplete = row.status.toLowerCase() === "done";
                                        const inProgress = row.status.toLowerCase() === "inprogress";
                                        const [status, statusColor] = this.getArchiveOrderStatusAndColor(row?.status);
                                        const cameraNameAndLocation =
                                            camerasWithLocations instanceof Map
                                                ? camerasWithLocations.get(row.cameraId)
                                                : {};
                                        const cameraName = cameraNameAndLocation?.name ?? row.cameraId;
                                        const locationName =
                                            locationNames instanceof Map
                                                ? locationNames.get(cameraNameAndLocation?.locationId)
                                                : "";

                                        return (
                                            <TableRow key={this.formatOrderNumber(row)}>
                                                <TableCell>{this.formatOrderNumber(row)}</TableCell>

                                                <TableCell>
                                                    <Text
                                                        color={isComplete ? "primary" : "secondary"}
                                                        variant="body-sm"
                                                    >
                                                        {moment(row?.orderDate).local().format("YYYY-MM-DD")}
                                                    </Text>
                                                    <br />
                                                    <Text color="secondary" variant="description">
                                                        {moment(row?.orderDate).local().format("HH:mm:ss")}
                                                    </Text>
                                                </TableCell>

                                                <TableCell>
                                                    <Text
                                                        color={isComplete ? "primary" : "secondary"}
                                                        variant="body-sm"
                                                    >
                                                        {cameraName}
                                                    </Text>
                                                    <br />
                                                    <Text color="secondary" variant="description">
                                                        {locationName}
                                                    </Text>
                                                </TableCell>

                                                <TableCell>
                                                    <Text
                                                        color={isComplete ? "primary" : "secondary"}
                                                        variant="body-sm"
                                                    >
                                                        {this.formatPeriod(row?.from, row?.to)}
                                                    </Text>
                                                </TableCell>

                                                <TableCell align="center" verticalAlign="middle">
                                                    {row.reason && (
                                                        <Tooltip
                                                            tooltip={
                                                                <span
                                                                    style={{
                                                                        display: "block",
                                                                        wordWrap: "break-word",
                                                                        width: 160,
                                                                    }}
                                                                >
                                                                    {row.reason}
                                                                </span>
                                                            }
                                                        >
                                                            <Icon icon="comment-full" size={16} />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>

                                                <TableCell align="center" verticalAlign="middle">
                                                    {row.status.toLowerCase() === "error" ? (
                                                        row.status && (
                                                            <Tooltip
                                                                tooltip={
                                                                    this.translateError(row.properties?.errorMessage) ??
                                                                    ""
                                                                }
                                                            >
                                                                <Text color={statusColor} variant="body-sm">
                                                                    {status}
                                                                </Text>
                                                            </Tooltip>
                                                        )
                                                    ) : (
                                                        <Text color={statusColor} variant="body-sm">
                                                            {status}
                                                        </Text>
                                                    )}
                                                </TableCell>

                                                <TableCell align="right" verticalAlign="middle">
                                                    {inProgress && (
                                                        <Button
                                                            variant="ghost"
                                                            label={strings("Отменить")}
                                                            onClick={() => this.handleCancel(row.id, row.serviceId)}
                                                        />
                                                    )}
                                                    {permissionExists(userInfo, ["video.archive.access"]) &&
                                                        isComplete && (
                                                            <Button
                                                                variant="secondary"
                                                                label={strings("Скачать")}
                                                                icon="download"
                                                                onClick={() => this.handleDownload(row, cameraName)}
                                                            />
                                                        )}
                                                    {row.isCanceled && (
                                                        <Button variant="ghost" label={strings("Отменен")} disabled />
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {permissionExists(userInfo, [
                                                        "video.archive.access",
                                                        "video.archive.download",
                                                        "video.archive.convert",
                                                    ]) &&
                                                        isComplete && (
                                                            <Dropdown
                                                                closeOnClickInside
                                                                toggleVariant="secondary"
                                                                horizontalAlignment="right"
                                                            >
                                                                <DropdownItem
                                                                    as="button"
                                                                    onClick={() => {
                                                                        this.handleConvertToAvi(row);
                                                                    }}
                                                                >
                                                                    {strings("Конвертировать в .AVI")}
                                                                </DropdownItem>
                                                                <DropdownDivider />
                                                                <DropdownItem
                                                                    as="button"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            isConverterDialogOpen: true,
                                                                            selectedArchiveRecord: row,
                                                                            selectedCameraName: cameraName,
                                                                        })
                                                                    }
                                                                >
                                                                    {strings("Конвертация")}
                                                                </DropdownItem>
                                                            </Dropdown>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </ModalTableWrapper>
                    </ModalBody>

                    <ArchiveOrderListModalFooter>
                        <Button variant="secondary" label={strings("Показать ещё")} onClick={this.handleShowMore} />

                        <Pagination
                            offset={pagesOffset}
                            page={currentPage}
                            count={countPages}
                            onChange={(page) => this.handlePage(page)}
                        />
                    </ArchiveOrderListModalFooter>
                </Modal>
            </>
        );
    }
}

const ConverterModalWindow = styled.div`
    z-index: 100;
    position: absolute;
`;

const ModalHeaderActionItem = styled.div`
    margin-right: 8px;
`;

const ModalTableWrapper = styled.div`
    width: 100%;
    padding: 16px 24px;
`;

const ArchiveOrderListModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 32px 24px;
`;

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(ArchiveOrderListModal));

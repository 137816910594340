import React from "react";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";
import { getSystemVariableValue } from "../../../../../contexts";
import { withGeneralContext } from "../../../../../contexts/GeneralContext";

class ModalStepSchedule extends React.Component {
    handleInputChange = (e) => {
        const fieldName = e.rawEvent.target.name;
        this.props.SetValuesProperty(fieldName, e.target.value);
    };

    render() {
        const { values, generalInfo } = this.props;

        const aggregationTime = getSystemVariableValue(
            generalInfo,
            "service.rtsp.quality",
            "rtsp.quality.aggregation.time",
            1
        );

        const analyseDurationErrors = [
            !values.analyseDurationSec || values.analyseDurationSec === 0
                ? "Поле не может быть пустым" : null,
            values.analyseDurationSec < 10 ? "Минимальное значение 10" : null,
            aggregationTime >= values.analyseDurationSec ?
                `Значение не может быть больше или равнным системной переменной "Время сбора статистики"` : null,
        ]

        const startIntervalErrors = [
            !values.startInterval || values.startInterval === 0 ? "Поле не может быть пустым" : null,
            values.startInterval < values.analyseDurationSec ? "Значение не может быть меньше длительности проверки" : null
        ]

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <ModalFormItem>
                                <TextField
                                    id="analyseDurationSec"
                                    label={strings("Длительность проверки видеопотока - сек")}
                                    name="analyseDurationSec"
                                    type="uint"
                                    value={values.analyseDurationSec}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={analyseDurationErrors.find(e => e !== null) ?? null}
                                />
                            </ModalFormItem>
                            <ModalFormItem>
                                <TextField
                                    id="startInterval"
                                    label={strings("Частота выполнения проверки - сек")}
                                    name="startInterval"
                                    type="uint"
                                    value={values.startInterval}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={startIntervalErrors.find(e => e !== null) ?? null}
                                />
                            </ModalFormItem>
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withGeneralContext(ModalStepSchedule)

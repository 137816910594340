import React from "react";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import {
    Button,
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    Row,
    TableHoverContainer,
    Space,
} from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class VariableTable extends React.Component {
    RowActions = (variable) => (
        <TableHoverContainer>
            <Button
                icon="info"
                title="Info"
                variant="ghost"
                onClick={(e) => {
                    e.stopPropagation();
                    this.props.setselected(variable);
                }}
            />
        </TableHoverContainer>
    );

    render() {
        const { selected, variables } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Переменные")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {variables.map((variable) => (
                                    <TableRow
                                        key={`${variable.serviceCode}__${variable.code}`}
                                        isSelected={
                                            selected &&
                                            variable.serviceCode === selected.serviceCode &&
                                            variable.code === selected.code
                                        }
                                    >
                                        <TableCell verticalAlign="middle">
                                            <Row verticalAlign="middle">
                                                <Text color="primary" variant="body-sm">
                                                    <Icon icon="variable" size={16} />
                                                </Text>
                                                <Space />
                                                <Text color="primary" variant="body-sm">
                                                    {strings(variable.name) ?? "..."}
                                                </Text>
                                            </Row>
                                        </TableCell>
                                        <TableCell verticalAlign="middle" noVerticalSpace>
                                            {this.RowActions(variable)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { nanoid } from "nanoid";
import { getFormatDataFromSource } from "./utilites";
import { ImageBlock } from "./UiBlocks/ImageBlock";
import { EventMedia } from "./UiBlocks/EventMedia";
import FilesBlock from "./UiBlocks/FilesBlock";
import TableListBlock from "./UiBlocks/TableListBlock";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabProperties extends React.Component {
    render() {
        const { tabSet, selectedEvent, strings } = this.props;
        return (
            <DetailsBoxContent>
                {tabSet?.media?.map((m, index) => (
                    <EventMedia key={`${selectedEvent.id}_${index}`} media={m} selectedEvent={selectedEvent} />
                ))}
                <Table isFixed noPadding>
                    <TableBody>
                        {tabSet?.fields?.map((f) => (
                            <React.Fragment key={nanoid()}>
                                {f.type === "list-table" ? (
                                    <>
                                        <TableListBlock selectedEvent={selectedEvent} data={f} />
                                    </>
                                ) : (
                                    <TableRow key={nanoid()}>
                                        <TableCell style={{ whiteSpace: "normal" }}>{strings(f.name)}</TableCell>
                                        <TableCell>
                                            <Text variant="body-sm">
                                                {(() => {
                                                    switch (f.type) {
                                                        case "image":
                                                            return (
                                                                <ImageBlock image={f} selectedEvent={selectedEvent} />
                                                            );

                                                        case "files":
                                                            return (
                                                                <FilesBlock files={f} selectedEvent={selectedEvent} />
                                                            );

                                                        default:
                                                            return strings(getFormatDataFromSource(f, selectedEvent));
                                                    }
                                                })()}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(DetailsTabProperties);

import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField, Text, Space } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

class LocationStepDefault extends React.Component {
    componentDidMount() {
        const { locationExtensions, values, SetValuesProperty } = this.props;
        const extensions = locationExtensions?.filter((ext) => ext.settings.section === "general");

        extensions.forEach((ext) => {
            if ((values[ext.name] === null || values[ext.name] === undefined) && !!ext.settings?.default) {
                SetValuesProperty(ext.name, ext.settings?.default);
            }
        });
    }

    render() {
        const { locationExtensions, values, SetValuesProperty } = this.props;

        const extensions = locationExtensions?.filter((ext) => ext.settings.section === "general");
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <ModalFormItem>
                                <Text>{strings("Имя локации:")}</Text>
                                <Space />
                                <TextField
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => SetValuesProperty("name", e.target.value)}
                                    colorVariant="light"
                                    fullWidth
                                    error={!values.name ? strings("Поле не может быть пустым") : null}
                                />
                            </ModalFormItem>
                            <ModalFormItem>
                                <TextField
                                    label={strings("Комментарий")}
                                    name="comment"
                                    type="text"
                                    colorVariant="light"
                                    fullWidth
                                    inputProps={{
                                        as: "textarea",
                                        rows: 3,
                                    }}
                                    value={values.comment}
                                    onChange={(e) => SetValuesProperty("comment", e.target.value)}
                                />
                            </ModalFormItem>
                        </ModalSection>
                        {!!extensions?.length && (
                            <ModalSection>
                                <ModalFormItem>
                                    {extensions.map((ext) => {
                                        const error =
                                            ext.settings?.required &&
                                            (values[ext.name] === null ||
                                                values[ext.name] === undefined ||
                                                values[ext.name] === "")
                                                ? strings(ext.settings?.errorText)
                                                : null;

                                        return (
                                            <ModalFormItem key={`${ext.entity}-${ext.name}`}>
                                                <Text>{strings(ext.title) ?? strings(ext.name)}</Text>
                                                <Space />
                                                <TextField
                                                    name={strings(ext.name)}
                                                    type={ext.type}
                                                    value={values[ext.name]}
                                                    onChange={(e) =>
                                                        SetValuesProperty(strings(ext.name), e.target.value)
                                                    }
                                                    colorVariant="light"
                                                    fullWidth
                                                    error={error}
                                                />
                                            </ModalFormItem>
                                        );
                                    })}
                                </ModalFormItem>
                            </ModalSection>
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(LocationStepDefault);

import React from "react";
import { ImageBlock } from "./ImageBlock";
import { Image2Similarity } from "./Image2Similarity";
import { FirstImage } from "./FirstImage";
import { ArrayImage } from "./ArrayImage";

export class EventMedia extends React.Component {
    render() {
        const { selectedEvent, media } = this.props;
        switch (media.type) {
            case "image":
                return <ImageBlock image={media} selectedEvent={selectedEvent} />;

            case "image2similarity":
                return <Image2Similarity media={media} selectedEvent={selectedEvent} />;

            case "arrayImage":
                return <ArrayImage media={media} selectedEvent={selectedEvent} />;

            case "firstImage":
                return <FirstImage media={media} selectedEvent={selectedEvent} />;

            default:
                return null;
        }
    }
}

import React from "react";
import { LayoutSidebarBody, LayoutSidebarItem } from "../../../layout/MainLayout";
import {
    FilterWrapper,
    FilterGroup,
    FilterGroupList,
    FilterItem,
    FilterDivider,
} from "../../components/FiltersPartion";
import { Checkbox, TextField, Icon, DateRangePicker } from "headpoint-react-components";
import { getTimeRange } from "../../../utilites/TimeUtils";
import { nanoid } from "nanoid";
import { checkNodeName } from "../../../utilites/TreeUtils";
import { GeneralContextConsumer, permissionExists, serviceExists } from "../../../contexts";
import DeviceLocationFilter from "../../components/DeviceLocationFilter";
import AdapterFilters from "./AdapterFilters";
import { EVENTS_DEVICE_LOCATIONS_FILTER } from "./eventsTopics";
import { connect } from "react-redux";
import {
    saveControlZonesFilter,
    saveDates,
    saveDeviceGroups,
    saveEventFilters,
    saveEventTypes,
} from "../../../app/reducers/eventsReducer";
import DropDownFilter from "../../components/DropDownFilter/DropDownFilter";

class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeSearch: "",
            devGroupSearch: "",
            controlZonesSearch: "",
            checkedSources: [],
        };
    }

    handleCheckboxChange = (array, setter, e) => {
        let newArray = [...array];
        if (newArray.includes(e)) {
            newArray = newArray.filter((et) => et !== e);
        } else {
            newArray = [...newArray, e];
        }

        setter(newArray);
    };

    handleDateRangePreset = (preset) => {
        const newDates = getTimeRange(preset);
        this.props.setValue("dates", newDates);
    };

    findAllNodes = (tree, searchName) => {
        let treeNodes = [];
        tree.forEach((node) => treeNodes.push(...checkNodeName(node, searchName)));
        return treeNodes;
    };

    render() {
        const {
            defaultEventTypes,
            eventTypesFilter,
            deviceGroups,
            controlZones,
            controlZonesFilter,
            deviceGroupsFilter,
            devicesFilter,
            locationsFilter,
            adapterFilters,
            setDeviceLocationFilter,
            resetdeviceLocationFilter,
            eventsFilters,
            handleOpen,
            handleUpdate,
            loadFilter,
            deleteFilter,
            selectedFilter,
            userInfo,
            dropFilter,
            disabled,
            strings,
        } = this.props;

        const { typeSearch, devGroupSearch, controlZonesSearch } = this.state;

        const availableEventTypes = defaultEventTypes?.filter((t) => permissionExists(userInfo, t.value)) ?? [];

        const eventTypes = typeSearch
            ? availableEventTypes?.filter((t) => t.label.toLowerCase().includes(typeSearch.toLowerCase()))
            : availableEventTypes;

        const devGroups = devGroupSearch
            ? deviceGroups?.filter((dg) => dg.name.toLowerCase().includes(devGroupSearch.toLowerCase()))
            : deviceGroups;

        const controlZonesSource = controlZonesSearch
            ? controlZones?.filter((dg) => dg.name.toLowerCase().includes(controlZonesSearch.toLowerCase()))
            : controlZones;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <LayoutSidebarBody>
                            <LayoutSidebarItem>
                                <FilterItem>
                                    <Checkbox
                                        label={strings("Тревожные")}
                                        checked={this.props.showDanger}
                                        onChange={() => {
                                            this.props.dispatch(
                                                saveEventFilters({ showDanger: !this.props.showDanger })
                                            );
                                            this.props.setValue("showDanger", !this.props.showDanger);
                                        }}
                                        colorVariant="danger"
                                    />
                                </FilterItem>
                                <FilterItem>
                                    <Checkbox
                                        label={strings("Требующие внимания")}
                                        checked={this.props.showWarning}
                                        onChange={() => {
                                            this.props.dispatch(
                                                this.props.dispatch(
                                                    saveEventFilters({ showWarning: !this.props.showWarning })
                                                )
                                            );
                                            this.props.setValue("showWarning", !this.props.showWarning);
                                        }}
                                        colorVariant="warning"
                                    />
                                </FilterItem>
                                <FilterItem>
                                    <Checkbox
                                        label={strings("Обычные")}
                                        checked={this.props.showInfo}
                                        onChange={() => {
                                            this.props.dispatch(saveEventFilters({ showInfo: !this.props.showInfo }));
                                            this.props.setValue("showInfo", !this.props.showInfo);
                                        }}
                                        colorVariant="primary"
                                    />
                                </FilterItem>
                                <FilterDivider />
                                <FilterItem>
                                    <Checkbox
                                        label={strings("Показывать просмотренные")}
                                        checked={this.props.showRead}
                                        onChange={() => {
                                            this.props.dispatch(saveEventFilters({ showRead: !this.props.showRead }));
                                            this.props.setValue("showRead", !this.props.showRead);
                                        }}
                                        colorVariant="primary"
                                    />
                                </FilterItem>
                            </LayoutSidebarItem>

                            <FilterWrapper>
                                <FilterGroup
                                    title={strings("Тип события")}
                                    hasValue={!!this.props.eventTypesFilter?.length}
                                    onClear={() => {
                                        this.setState({ typeSearch: "" });
                                        this.props.setValue("eventTypesFilter", []);
                                    }}
                                    isMuted={!this.props.defaultEventTypes.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ typeSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={this.state.typeSearch}
                                    />

                                    <FilterGroupList>
                                        {eventTypes.map((et) => (
                                            <FilterItem key={nanoid()}>
                                                <Checkbox
                                                    label={strings(et.label)}
                                                    checked={eventTypesFilter.includes(et.value)}
                                                    onChange={() =>
                                                        this.handleCheckboxChange(
                                                            eventTypesFilter,
                                                            (arr) => {
                                                                this.props.dispatch(saveEventTypes(arr));
                                                                this.props.setValue("eventTypesFilter", arr);
                                                            },
                                                            et.value
                                                        )
                                                    }
                                                />
                                            </FilterItem>
                                        ))}
                                    </FilterGroupList>
                                </FilterGroup>
                                {serviceExists(generalInfo, ["service.api.locations", "service.api.devices"]) &&
                                permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
                                    <DeviceLocationFilter
                                        locationsFilter={locationsFilter}
                                        devicesFilter={devicesFilter}
                                        setDeviceLocationFilter={setDeviceLocationFilter}
                                        resetFilter={resetdeviceLocationFilter}
                                        cmdChannelTopic={EVENTS_DEVICE_LOCATIONS_FILTER}
                                    />
                                ) : (
                                    <div />
                                )}

                                {serviceExists(generalInfo, ["service.api.devices"]) ? (
                                    <FilterGroup
                                        title={strings("Группа")}
                                        hasValue={!!deviceGroupsFilter?.length}
                                        onClear={() => {
                                            this.setState({ devGroupSearch: "" });

                                            this.props.dispatch(saveDeviceGroups([]));
                                            this.props.setValue("deviceGroupsFilter", []);
                                        }}
                                        isMuted={!devGroups?.length}
                                    >
                                        <TextField
                                            colorVariant="light"
                                            fullWidth
                                            onChange={(e) => this.setState({ devGroupSearch: e.target?.value })}
                                            placeholder={strings("Найти")}
                                            type="text"
                                            value={this.state.devGroupSearch}
                                        />
                                        <FilterGroupList>
                                            {devGroups.map((dg) => (
                                                <FilterItem key={dg.id}>
                                                    <Checkbox
                                                        label={dg.name}
                                                        checked={deviceGroupsFilter.includes(dg.id)}
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                deviceGroupsFilter,
                                                                (arr) => {
                                                                    this.props.dispatch(saveDeviceGroups(arr));

                                                                    this.props.setValue("deviceGroupsFilter", arr);
                                                                },
                                                                dg.id
                                                            )
                                                        }
                                                    />
                                                </FilterItem>
                                            ))}
                                        </FilterGroupList>
                                    </FilterGroup>
                                ) : (
                                    <div />
                                )}

                                <FilterGroup
                                    title={strings("Дата")}
                                    hasValue={!!this.props.dates.from || !!this.props.dates.to}
                                    onClear={() => this.props.setValue("dates", { from: undefined, to: undefined })}
                                    isMuted={!this.props.dates.from && !this.props.dates.to}
                                >
                                    <DateRangePicker
                                        dates={this.props.dates}
                                        onChange={(d) => {
                                            this.props.dispatch(saveDates(d));
                                            this.props.setValue("dates", d);
                                        }}
                                        placeholder={strings("Выбрать период")}
                                        colorVariant="transparent"
                                        inputFormat="D.MM.YYYY HH:mm"
                                        presets={[
                                            {
                                                label: strings("Последний час"),
                                                handler: () => this.handleDateRangePreset("lastHour"),
                                            },
                                            {
                                                label: strings("Последние 6 часов"),
                                                handler: () => this.handleDateRangePreset("lastSixHours"),
                                            },
                                            {
                                                label: strings("Последние сутки"),
                                                handler: () => this.handleDateRangePreset("lastDay"),
                                            },
                                        ]}
                                        withTime
                                        customInputLabels={{
                                            hours: strings("Часы"),
                                            minutes: strings("Минуты"),
                                        }}
                                        customIcon={<Icon icon="calendar-clear" />}
                                        fullWidth
                                        positionFixed
                                    />
                                </FilterGroup>
                                <AdapterFilters
                                    generalInfo={generalInfo}
                                    adapterFilters={adapterFilters}
                                    setValue={this.props.setValue}
                                />
                                {serviceExists(generalInfo, ["service.api.controlZones"]) ? (
                                    <FilterGroup
                                        title={strings("Зоны контроля")}
                                        hasValue={!!controlZonesFilter?.length}
                                        onClear={() => {
                                            this.setState({ controlZonesSearch: "" });

                                            this.props.dispatch(saveControlZonesFilter([]));
                                            this.props.setValue("controlZonesFilter", []);
                                        }}
                                        isMuted={!controlZonesSource?.length}
                                    >
                                        <TextField
                                            colorVariant="light"
                                            fullWidth
                                            onChange={(e) => this.setState({ controlZonesSearch: e.target?.value })}
                                            placeholder={strings("Найти")}
                                            type="text"
                                            value={this.state.controlZonesSearch}
                                        />
                                        <FilterGroupList>
                                            {controlZonesSource?.map((cZ) => (
                                                <FilterItem key={cZ.id}>
                                                    <Checkbox
                                                        label={cZ.name}
                                                        checked={controlZonesFilter.includes(cZ.id)}
                                                        onChange={() =>
                                                            this.handleCheckboxChange(
                                                                controlZonesFilter,
                                                                (arr) => {
                                                                    this.props.dispatch(saveControlZonesFilter(arr));
                                                                    this.props.setValue("controlZonesFilter", arr);
                                                                },
                                                                cZ.id
                                                            )
                                                        }
                                                    />
                                                </FilterItem>
                                            ))}
                                        </FilterGroupList>
                                    </FilterGroup>
                                ) : (
                                    <div />
                                )}
                            </FilterWrapper>
                        </LayoutSidebarBody>
                        <DropDownFilter
                            selectedFilter={selectedFilter}
                            filters={eventsFilters}
                            createFilter={handleOpen}
                            updateFilter={handleUpdate}
                            selectFilter={loadFilter}
                            deleteFilter={deleteFilter}
                            dropFilter={dropFilter}
                            disabled={disabled}
                        />
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        events: state.events,
    };
};

export default connect(mapStateToProps)(Filters);

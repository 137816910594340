import moment from "moment/moment";

export const getDataFromSource = (path, source) => {
    if (!source || !path) {
        return null;
    }

    const sourcePath = path.split(".");
    let result;
    switch (sourcePath[0]) {
        case "$event":
            result = source;
            break;

        case "$data":
            result = source.properties;
            break;

        default:
            return null;
    }

    sourcePath.splice(0, 1);
    while (sourcePath.length) {
        result = result?.[sourcePath[0]];
        sourcePath.splice(0, 1);
    }

    return result;
};

export const getFormatDataFromSource = (field, source) => {
    let text = getDataFromSource(field.source, source);
    switch (field.type) {
        case "datetime":
            return field.format?.value ? moment(text).format(field.format.value) : text;

        default:
            return text;
    }
};

export const getImageStorageUrl = (source, storage) => {
    switch (storage) {
        case "inone":
            return `storage-api/files/${source}`;
        default:
            return source;
    }
};

import React from "react";
import styled from "styled-components";
import { Button, Modal, ModalBody, Row, Text } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

const ModalFormItem = styled.div`
    width: 100%;

    & + & {
        margin-top: 25px;
        margin-bottom: 25px;
    }
`;

const ModalButtonsContainer = styled.div`
    position: absolute;
    float: right;
    display: flex;
    align-items: center;
`;

const ModalTextContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 30px 0 30px;
`;

const ModalButton = styled.div`
    display: flex;
    margin: 5px;
`;

const ModalHeader = styled.div`
    position: relative;
    min-height: 73px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: ${({ align }) => (align === "center" ? "24px 80px" : "24px 80px 24px 48px")};
`;

const ModalHeaderTitle = styled.div`
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
`;

const ModalFooter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 96px;
    border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 24px 24px;
`;

const ModalSection = styled.div`
    width: 100%;
    padding: 24px 24px;

    & + & {
        border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    }
`;

export class LicenseInfoModal extends React.Component {
    render() {
        const { licenseInfo } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <Modal size="md">
                        <ModalHeader>
                            <ModalHeaderTitle>{strings("Информация о лицензиях")}</ModalHeaderTitle>
                        </ModalHeader>
                        <ModalBody>
                            <ModalSection>
                                <ModalFormItem>
                                    <Row justify="left" align="bottom">
                                        <ModalTextContainer>
                                            <Text variant="body-sm" color="secondary" align={"left"}>
                                                {strings("id лицензии")}
                                            </Text>
                                            <Text variant="body" color="primary" align={"left"}>
                                                {licenseInfo?.id ?? "-"}
                                            </Text>
                                        </ModalTextContainer>
                                    </Row>
                                </ModalFormItem>
                                <ModalFormItem>
                                    <Row justify="left" align="bottom">
                                        <ModalTextContainer>
                                            <Text variant="body-sm" color="secondary" align={"left"}>
                                                {strings("Конечный пользователь")}
                                            </Text>
                                            <Text variant="body" color="primary" align={"left"}>
                                                {licenseInfo?.client ?? "-"}
                                            </Text>
                                        </ModalTextContainer>
                                    </Row>
                                </ModalFormItem>
                                <ModalFormItem>
                                    <Row justify="left" align="bottom">
                                        <ModalTextContainer>
                                            <Text variant="body-sm" color="secondary" align={"left"}>
                                                {strings("Разработчик")}
                                            </Text>
                                            <Text variant="body" color="primary" align={"left"}>
                                                {strings('ООО "Хэд пойнт"')}
                                            </Text>
                                        </ModalTextContainer>
                                    </Row>
                                </ModalFormItem>
                                <ModalFormItem>
                                    <Row justify="left" align="bottom">
                                        <ModalTextContainer>
                                            <Text variant="body-sm" color="secondary" align={"left"}>
                                                {strings("Сайт производителя")}
                                            </Text>
                                            <Text variant="body" color="primary" align={"left"}>
                                                <a
                                                    href="https://head-point.ru/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    https://head-point.ru/
                                                </a>
                                            </Text>
                                        </ModalTextContainer>
                                    </Row>
                                </ModalFormItem>
                                <ModalFormItem>
                                    <Row justify="left" align="bottom">
                                        <ModalTextContainer>
                                            <Text variant="body-sm" color="secondary" align={"left"}>
                                                {strings("Территория действия прав")}
                                            </Text>
                                            <Text variant="body" color="primary" align={"left"}>
                                                {strings("РФ")}
                                            </Text>
                                        </ModalTextContainer>
                                    </Row>
                                </ModalFormItem>
                                <ModalFormItem>
                                    <Row justify="left" align="bottom">
                                        <ModalTextContainer>
                                            <Text variant="body-sm" color="secondary" align={"left"}>
                                                {strings("Срок действия лицензии")}
                                            </Text>
                                            <Text variant="body" color="primary" align={"left"}>
                                                {licenseInfo?.expiryDate ?? "-"}
                                            </Text>
                                        </ModalTextContainer>
                                    </Row>
                                </ModalFormItem>
                            </ModalSection>
                        </ModalBody>
                        <ModalFooter>
                            <ModalButtonsContainer>
                                <ModalButton>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        label="Ок"
                                        width="50px"
                                        display="right"
                                        onClick={this.props.CloseHandler}
                                    />
                                </ModalButton>
                            </ModalButtonsContainer>
                        </ModalFooter>
                    </Modal>
                )}
            </CultureContextConsumer>
        );
    }
}

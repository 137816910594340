import { ModalStepDefault } from "./ModalStepDefault";
import { withSnackbar } from "notistack";
import ModalStepDevices from "./ModalStepDevices";
import { DevicesGroupModal } from "./DevicesGroupModal";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE } from "../../../../components/CreateUpdateWizard/constants";

class FixedGroupModal extends DevicesGroupModal {
    constructor(props) {
        const { strings } = props;

        super(props);
        this.steps = [
            { name: strings("Общее"), component: ModalStepDefault },
            { name: strings("Оборудование"), component: ModalStepDevices },
        ];

        this.title = `${props.mode === WIZARD_CREATE_MODE ? strings("Создать") : strings("Изменить")} ${strings(
            "фиксированную группу"
        )}`;
        this.state = {
            ...this.state,
            step: props?.mode === WIZARD_CREATE_MODE ? 1 : this.steps.length,
        };
    }
}

export default withCultureContext(withSnackbar(FixedGroupModal));

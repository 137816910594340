const pathTags = "/tags-api/tags";
const pathTagsByEntity = "/tags-api/tags/by-entity";
const pathTagsEntities = "/tags-api/tags/entities";

export const CompareTags = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const GetTags = async () => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(pathTags, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, (await response.json()) ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetTagsByEntity = async (entityId) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathTagsByEntity}/${entityId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, (await response.json()) ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const UpdateTagsByEntity = async (entityId, tags) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathTagsByEntity}/${entityId}`, {
            method: "PUT",
            body: JSON.stringify(tags),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return response.status === 200;
    } catch (ex) {
        console.error(ex);
        return false;
    }
};

export const CreateTag = async (tag) => {
    const token = window.localStorage.getItem("token");
    const response = await fetch(pathTags, {
        method: "POST",
        body: JSON.stringify(tag),
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status === 200, response.status];
    }

    return [response.status === 200, response.status, (await response.json()) ?? ""];
};

export const DeleteTag = async (tagId) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathTags}/${tagId}`, {
            method: "DELETE",
            body: JSON.stringify({ id: tagId }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return [response.status === 200, response.status];
    } catch (ex) {
        console.error(ex);
        return [false, undefined];
    }
};

export const UpdateTag = async (tag) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathTags}/${tag.id}`, {
        method: "PUT",
        body: JSON.stringify(tag),
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response.status === 200, response.status];
};

export const GetTagEntities = async (tagId) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathTagsEntities}/${tagId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, (await response.json()) ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

import React from "react";
import { ModalBodyItem, Row, Text, TextField } from "headpoint-react-components";
import { permissionExists } from "../../../../../contexts";
import { GetDevices, SearchDevices } from "../../../../../services/devices";
import { FillLocations, FillNode, GetLocations } from "../../../../../services/locations";
import { AccordionListChild, AccordionListGroup } from "../../../../components/Sidebar/elements";
import { AccordionListWrapper } from "../../../../components/AccordionList";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

const DEVICES_LIMIT = 100;

class ModalStepDevicesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            searchList: [],
            devicesLocationsTree: [],
            devices: [],
        };
    }

    async componentDidMount() {
        const { strings } = this.props;

        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        const tree = FillLocations(locations, []);
        this.setState({
            devicesLocationsTree: tree ?? [],
        });
    }

    isEmptySelected(values) {
        return !values?.properties?.types?.length && !values?.properties?.tags?.length;
    }

    searchNodes = async (like) => {
        const { values, strings } = this.props;

        if (!this.isEmptySelected(values)) {
            let filter = { like, limit: DEVICES_LIMIT };
            if (values?.properties?.types?.length) {
                filter = { ...filter, deviceTypes: values?.properties?.types ?? [] };
            }

            if (values?.properties?.tags?.length) {
                filter = { ...filter, deviceTags: values?.properties?.tags ?? [] };
            }

            const [devicesStatus, devices] = await SearchDevices(filter);
            if (!devicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            let nodes = devices?.map((d) => ({ ...d, tag: "device" })) ?? [];
            this.setState({ search: like, searchList: nodes });
        } else {
            this.setState({ search: like });
        }
    };

    openLocation = async (locationNode) => {
        const { devicesLocationsTree, devices } = this.state;
        const { values, strings } = this.props;

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (!this.isEmptySelected(values)) {
            const [getDevicesStatus, newDevices] = await GetDevices(
                locationNode.id,
                values?.properties?.types ?? [],
                null,
                values?.properties?.tags ?? []
            );

            if (!getDevicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            if (locationNode.isClosed) {
                return;
            }

            FillNode(locationNode, children, newDevices);
            newDevices.forEach((nd) => {
                if (devices.find((d) => d.id === nd.id)) {
                    return;
                }

                devices.push(nd);
            });

            this.setState({
                devicesLocationsTree,
                devices,
            });
        } else {
            if (locationNode.isClosed) {
                return;
            }

            FillNode(locationNode, children, []);
        }
    };

    renderChildren = (childrenList) => {
        return childrenList.map((item) =>
            item.children ? (
                <AccordionListGroup
                    key={item.id}
                    label={item.label ?? item.name}
                    onOpenLocation={this.openLocation}
                    location={item}
                >
                    {this.renderChildren(item.children)}
                </AccordionListGroup>
            ) : (
                <AccordionListChild style={{ cursor: "default" }} key={item.id}>
                    <Text variant={"body-sm"}>{item.label ?? item.name}</Text>
                </AccordionListChild>
            )
        );
    };

    render() {
        const { search, devicesLocationsTree, searchList } = this.state;
        const { strings } = this.props;
        const sources = search ? searchList : devicesLocationsTree;
        const { userInfo } = this.props;

        return permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
            <ModalBodyItem>
                <Row justify="between" align="bottom" marginBottom={24}>
                    <Text variant="body">{strings("Устройства")}</Text>
                </Row>
                <Row marginBottom={12}>
                    <TextField
                        autoComplete={false}
                        colorVariant="light"
                        fullWidth
                        icon="search"
                        onChange={(e) => this.searchNodes(e.target.value)}
                        placeholder={strings("Найти")}
                        type="search"
                        value={this.state.search}
                    />
                </Row>
                <AccordionListWrapper>{this.renderChildren(sources)}</AccordionListWrapper>
            </ModalBodyItem>
        ) : (
            <div />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(ModalStepDevicesView));

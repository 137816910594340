import React from "react";
import {
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
    EventsLayoutPaginationWrapper,
    EventsLayoutPrimary,
    EventsLayoutTableWrapper,
} from "../../../layout/EventsLayout";
import {
    Button,
    DateRangePicker,
    Dropdown,
    DropdownItem,
    Icon,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    Text,
} from "headpoint-react-components";
import { nanoid } from "nanoid";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import { flatten } from "../../../utilites/TreeUtils";
import { ActionTypes, EntityTypes } from "../constants";
import { getTimeRange } from "../../../utilites/TimeUtils";
import moment from "moment";

export class ReportView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pluginTableData: [],
            showDateModal: false,
            dateModalDownloadType: undefined,
            dates: undefined,
        };
    }

    handleShowDetails = (row) => {
        const { detailsEnabled } = this.props;

        if (!row || !detailsEnabled) {
            return;
        }

        this.props.setValue("selectedRow", row);
    };

    handlePage = (p) => {
        if (p <= this.props.pagesCount) {
            this.props.setValue("pageNumber", p);
        }
    };

    getFormattedColumn = (column, isRead, strings) => {
        let color = isRead ? "secondary" : "white";
        color =
            column.properties?.color === "Warning" || column.properties?.color === "Danger"
                ? column.properties.color.toLowerCase()
                : color;

        switch (column.id) {
            case "recordDate":
                return (
                    <>
                        <Text color="primary" variant="body-sm">
                            {moment(column?.value).local().format("YYYY-MM-DD")}
                        </Text>
                        <br />
                        <Text color="secondary" variant="description">
                            {moment(column?.value).local().format("HH:mm:ss")}
                        </Text>
                    </>
                );

            case "action":
                const filteredTypes = flatten(ActionTypes)[column.value];
                const action = filteredTypes ? filteredTypes.name : column.value;
                return (
                    <>
                        <Text color={color} variant="body-sm">
                            {strings(action)}
                        </Text>
                    </>
                );

            case "message":
                const message = column.value === "{}" ? null : column.value;
                return (
                    <>
                        <Text color={color} variant="body-sm">
                            {strings(message)}
                        </Text>
                    </>
                );

            case "status":
                return (
                    <>
                        <Text color={color} variant="body-sm">
                            {strings(column.value)}
                        </Text>
                    </>
                );

            case "entity":
                const filteredEntities = EntityTypes.filter((e) => e.id === column.value);
                const entity = filteredEntities?.length ? filteredEntities[0].name : column.value;
                return (
                    <>
                        <Text color={color} variant="body-sm">
                            {strings(entity)}
                        </Text>
                    </>
                );

            default:
                return (
                    <Text color={color} variant="body-sm">
                        {strings(column.value)}
                    </Text>
                );
        }
    };

    showDateModal = (type) => {
        this.setState({ showDateModal: true, dateModalDownloadType: type });
    };

    closeDateModal = () => {
        this.setState({ showDateModal: false });
    };

    handleDateRangePreset = (preset) => {
        const dates = getTimeRange(preset);
        this.setState({ dates });
    };

    handleDownload = (d) => {
        if (d.dateFilter) {
            this.showDateModal(d.type);
        } else {
            this.props.downloadReport(d.type);
        }
    };

    downloadFromDatePicker = () => {
        const { dateModalDownloadType, dates } = this.state;

        if (dateModalDownloadType === undefined) {
            return;
        }

        if (dates?.from === undefined || dates?.to === undefined) {
            return;
        }

        const dateTime = {
            from: moment(dates.from).toISOString(),
            to: moment(dates.to).toISOString(),
        };

        this.props.downloadReport(dateModalDownloadType, dateTime);
        this.closeDateModal();
    };

    render() {
        const { showDateModal, dates } = this.state;
        const { pluginData, page, pagesCount, rows } = this.props;

        const tableColumns = pluginData?.columns ?? [];
        let offset = Math.min(page > 3 ? page - 3 : 0, pagesCount - 5);
        if (offset < 0) {
            offset = 0;
        }

        const hasMultipleReports = pluginData?.service && pluginData?.downloads;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutPrimary hasSecondary={this.props.hasSecondary}>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings(pluginData.description)}
                                </Text>
                            </EventsLayoutHeaderTitle>

                            <EventsLayoutHeaderToolbox>
                                <EventsLayoutHeaderToolboxItem>
                                    {hasMultipleReports ? (
                                        <Dropdown
                                            styleIcon="download"
                                            closeOnClickInside
                                            toggleVariant="secondary"
                                            horizontalAlignment="right"
                                        >
                                            {pluginData?.downloads?.map((d, i) => {
                                                return (
                                                    <DropdownItem
                                                        key={i}
                                                        as="button"
                                                        onClick={() => this.handleDownload(d)}
                                                    >
                                                        {strings(d?.name)}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </Dropdown>
                                    ) : (
                                        <Button
                                            variant="secondary"
                                            icon="download"
                                            onClick={this.props.downloadReport}
                                        />
                                    )}
                                </EventsLayoutHeaderToolboxItem>
                            </EventsLayoutHeaderToolbox>
                        </EventsLayoutHeader>

                        <EventsLayoutTableWrapper>
                            <Table hasHover stickyHeader isFixed bgColor="dark">
                                <TableHead>
                                    <TableRow key={nanoid()}>
                                        {tableColumns.map((td) => (
                                            <TableHeadCell key={nanoid()}>{strings(td.name)}</TableHeadCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={nanoid()}
                                            isSelected={
                                                this.props.selectedEvent && row.id === this.props.selectedEvent.id
                                            }
                                            onClick={() => this.handleShowDetails(row)}
                                        >
                                            {tableColumns.map((tableColumn) => {
                                                let column = row.find((col) => col.id === tableColumn.id);
                                                return (
                                                    <TableCell key={nanoid()}>
                                                        {column ? (
                                                            this.getFormattedColumn(column, row.isRead, strings)
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </EventsLayoutTableWrapper>
                        <EventsLayoutPaginationWrapper>
                            <Button
                                variant="secondary"
                                label={strings("Показать ещё")}
                                onClick={this.props.handleShowMore}
                            />

                            <Pagination
                                offset={offset}
                                page={page}
                                count={pagesCount}
                                onChange={(e) => this.handlePage(e)}
                            />
                        </EventsLayoutPaginationWrapper>

                        {showDateModal && (
                            <Modal size="sm" closeHandler={this.closeDateModal}>
                                <ModalHeader
                                    title={strings("Укажите период отчета")}
                                    align="center"
                                    closeHandler={this.closeDateModal}
                                />
                                <ModalBody>
                                    <ModalBodyItem>
                                        <DateRangePicker
                                            dates={dates}
                                            onChange={(range) => {
                                                this.setState({ dates: range });
                                            }}
                                            placeholder={strings("Выбрать период")}
                                            colorVariant="transparent"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            withTime
                                            presets={[
                                                {
                                                    label: strings("Последний час"),
                                                    handler: () => this.handleDateRangePreset("lastHour"),
                                                },
                                                {
                                                    label: strings("Последние сутки"),
                                                    handler: () => this.handleDateRangePreset("lastDay"),
                                                },
                                                {
                                                    label: strings("Последний месяц"),
                                                    handler: () => this.handleDateRangePreset("lastMonth"),
                                                },
                                            ]}
                                            customInputLabels={{
                                                hours: "Часы",
                                                minutes: strings("Минуты"),
                                            }}
                                            customIcon={<Icon icon="calendar-clear" />}
                                            calendarProps={{
                                                disabledDays: {
                                                    after: new Date(),
                                                },
                                            }}
                                        />
                                    </ModalBodyItem>
                                </ModalBody>
                                <ModalFooter>
                                    <ModalFooterItem>
                                        <Button
                                            type="button"
                                            variant="primary"
                                            label={strings("Скачать")}
                                            onClick={this.downloadFromDatePicker}
                                        />
                                    </ModalFooterItem>
                                    <ModalFooterItem>
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            label={strings("Отмена")}
                                            onClick={this.closeDateModal}
                                        />
                                    </ModalFooterItem>
                                </ModalFooter>
                            </Modal>
                        )}
                    </EventsLayoutPrimary>
                )}
            </CultureContextConsumer>
        );
    }
}

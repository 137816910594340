import React from "react";
import Sidebar from "./Sidebar";
import { LayoutMain, LayoutPage } from "../../../layout/MainLayout";
import { EventsLayout } from "../../../layout/EventsLayout";
import { GeneralContextConsumer, permissionExists, serviceExists } from "../../../contexts";
import { pages } from "./pages";
import { CustomArea } from "./customArea";
import { withRouter } from "react-router-dom";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            features: [],
            updateTreeId: nanoid(),
        };
    }

    componentDidMount() {
        const { history } = this.props;

        const data = history.location.state;
        if (data) {
            history.replace({ ...history, state: undefined });
            this.openSettingsFromHistory(history, data);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const data = this.props.history.location.state;

            if (data) {
                this.openSettingsFromHistory(this.props.history, data);
            }
        }
    }

    openSettingsFromHistory = (history, data) => {
        switch (data?.type) {
            case "SelectDevice":
                this.props.history.replace(`${history.location.pathname}${data.partition}`, {
                    selectedDevice: data.device,
                    locationId: data.device.locationId,
                });

                this.setState({ updateTreeId: nanoid() });
                break;

            case "SelectLocation":
                this.props.history.replace(`${history.location.pathname}${data.partition}`, {
                    selectedLocation: data.location,
                    locationId: data.location.id,
                });

                this.setState({ updateTreeId: nanoid() });
                break;

            default:
                break;
        }
    };

    getCustoms = (generalInfo) => {
        const { strings } = this.props;

        return (
            generalInfo?.services
                ?.filter((s) => s.properties?.custom?.placement === "general.settings")
                .map((s) => ({
                    label: strings(s.properties.custom.label),
                    permissions: s.properties.custom.permissions,
                    servicesNames: s.code,
                    url: `/settings/${s.code}`,
                    content: `/general-api/service/${s.code}`,
                    serviceId: s.code,
                    control: CustomArea,
                })) ?? []
        );
    };

    getPages = (generalInfo) => [...pages, ...this.getCustoms(generalInfo)];

    isRouteAllowed = (page, permissions, generalInfo, userInfo) =>
        this.props.location.pathname === page.url &&
        permissionExists(userInfo, permissions) &&
        serviceExists(generalInfo, page.servicesNames);

    render() {
        const { userInfo } = this.props;

        const { updateTreeId } = this.state;

        return (
            <LayoutPage>
                <GeneralContextConsumer>
                    {(generalInfo) => (
                        <>
                            <Sidebar generalInfo={generalInfo} pages={this.getPages(generalInfo)} />
                            <LayoutMain>
                                <EventsLayout>
                                    {(() => {
                                        let pages = this.getPages(generalInfo);

                                        const permissions = pages.find(
                                            (p) => p.url === this.props.location.pathname
                                        )?.permissions;

                                        for (const page of pages) {
                                            switch (page.type) {
                                                case "nested":
                                                    for (const nestedPage of page.pages) {
                                                        if (
                                                            this.isRouteAllowed(
                                                                nestedPage,
                                                                nestedPage.permissions,
                                                                generalInfo,
                                                                userInfo
                                                            ) &&
                                                            nestedPage.control
                                                        ) {
                                                            return React.createElement(nestedPage.control, {
                                                                key: updateTreeId,
                                                                ...nestedPage,
                                                                control: undefined,
                                                            });
                                                        }
                                                    }
                                                    break;
                                                default:
                                                    if (
                                                        this.isRouteAllowed(page, permissions, generalInfo, userInfo) &&
                                                        page.control
                                                    ) {
                                                        return React.createElement(page.control, {
                                                            key: updateTreeId,
                                                            ...page,
                                                            control: undefined,
                                                        });
                                                    }
                                                    break;
                                            }
                                        }
                                    })()}
                                </EventsLayout>
                            </LayoutMain>
                        </>
                    )}
                </GeneralContextConsumer>
            </LayoutPage>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withRouter(Settings)));

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetSchedules, GetSchedule } from "../../services/screenshot";
import { SearchDevices } from "../../services/devices";

const initialState = {
    activeDesktop: {
        layout: "1",
    },
    framesCount: 1,
    frames: {},
    screenshotsDates: [],
    screenshotsInfo: [],
    dates: {
        from: undefined,
        to: undefined,
    },
    offsetTime: 0,
    schedules: [],
    scheduleInfo: null,
    deviceOffset: 0,
    deviceMode: false,
    selectedCameraFrame: null,
    showCameraInfo: false,
};

export const fetchSchedules = createAsyncThunk(
    "schedules/fetchSchedules",
    async ({ filter, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [getSchedulesStatus, newSchedules, count] = await GetSchedules(filter);

        if (!getSchedulesStatus) {
            enqueueSnackbar(strings("Не удалось получить список расписаний"), { variant: "error" });
            return rejectWithValue();
        }

        return { newSchedules, count };
    }
);

export const fetchSchedule = createAsyncThunk(
    "schedules/fetchSchedule",
    async ({ schedule, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [getScheduleStatus, scheduleInfo] = await GetSchedule(schedule);

        if (!getScheduleStatus) {
            enqueueSnackbar(strings("Не удалось получить список устройств расписания"), { variant: "error" });
            return rejectWithValue();
        }

        const filter = {
            devicesTypes: scheduleInfo?.filters?.filters?.typeIds ?? [],
            deviceTags: scheduleInfo?.filters?.filters?.tagsIds ?? [],
            deviceGroups: scheduleInfo?.filters?.filters?.groupIds ?? [],
            ids: scheduleInfo?.filters?.deviceIds ?? [],
        };

        const [devicesStatus, frames] = await SearchDevices(filter);

        if (!devicesStatus) {
            enqueueSnackbar(strings("Не удалось получить информацию об устройствах из расписания"), {
                variant: "error",
            });
            return rejectWithValue();
        }

        return { scheduleInfo: { ...scheduleInfo, frames } };
    }
);

export const gallerySlice = createSlice({
    name: "gallery",
    initialState: initialState,
    reducers: {
        resetScheduleInfo: (state, action) => {
            state.scheduleInfo = null;
            state.deviceOffset = 0;
            state.screenshotsInfo = [];
            state.offsetTime = 0;
            state.deviceMode = false;
            state.selectedCameraFrame = null;
        },
        saveActiveDesktop: (state, action) => {
            state.activeDesktop = action.payload;
        },
        saveScreenshotsDates: (state, action) => {
            state.screenshotsDates = action.payload;
        },
        saveScreenshotsInfo: (state, action) => {
            state.screenshotsInfo = action.payload;
        },
        saveFrames: (state, action) => {
            state.frames = action.payload;
        },
        saveDates: (state, action) => {
            state.dates = action.payload;
        },
        saveOffsetTime: (state, action) => {
            state.offsetTime = action.payload;
        },
        saveDeviceOffset: (state, action) => {
            state.deviceOffset = action.payload;
        },
        saveDeviceMode: (state, action) => {
            state.deviceMode = action.payload;
        },
        saveSelectedCameraFrame: (state, action) => {
            state.selectedCameraFrame = action.payload;
        },
        saveShowCameraInfo: (state, action) => {
            state.showCameraInfo = action.payload;
        },
        saveFramesCount: (state, action) => {
            state.framesCount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedules.fulfilled, (state, action) => {
                state.schedules = action.payload.newSchedules;
            })
            .addCase(fetchSchedule.fulfilled, (state, action) => {
                state.scheduleInfo = action.payload.scheduleInfo;
            })
            .addCase(fetchSchedule.pending, (state) => {
                state.scheduleInfo = undefined;
                state.deviceOffset = 0;
                state.offsetTime = 0;
            });
    },
});

export const {
    saveActiveDesktop,
    saveFrames,
    saveScreenshotsDates,
    saveScreenshotsInfo,
    saveDates,
    saveOffsetTime,
    resetScheduleInfo,
    saveDeviceOffset,
    saveDeviceMode,
    saveSelectedCameraFrame,
    saveShowCameraInfo,
    saveFramesCount,
} = gallerySlice.actions;

export default gallerySlice.reducer;

import React from "react";
import { withSnackbar } from "notistack";
import { DeviceLocationFilter } from "./DeviceLocationFilter";
import { CultureContextConsumer, withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";

class DeviceLocationFilterWrapper extends React.Component {
    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => React.createElement(DeviceLocationFilter, { ...this.props, strings })}
            </CultureContextConsumer>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(DeviceLocationFilterWrapper)));

import React from "react";
import { withSnackbar } from "notistack";
import {
    Button,
    Text,
    Tab,
    TabPanel,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Row,
    TableHead,
    TableHeadCell,
} from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxContent,
} from "../../../components/DetailsBox";
import { nanoid } from "nanoid";
import { CultureContextConsumer, withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class DeviceTypesDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedType: undefined,
        };
    }

    handleCloseDetailedView = () => this.props.closeDetailsTab();

    DetailsTabMain = ({ selectedType }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Table isFixed noPadding>
                        <TableBody>
                            <TableRow>
                                <TableCell>{strings("Название")}</TableCell>
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {strings(selectedType?.label)}
                                    </Text>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{strings("ID")}</TableCell>
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {selectedType.value}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    DetailsTabProperties = (properties) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent key={nanoid()}>
                    <Row marginTop={24} marginBottom={16}>
                        <Text color="primary" variant="body">
                            {strings(properties?.name)}
                        </Text>
                    </Row>
                    <Table hasHover isFixed bgColor="dark">
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>{strings("Название поля")}</TableHeadCell>
                                <TableHeadCell>{strings("Назначение поля")}</TableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties?.settings?.map((field) => (
                                <TableRow key={nanoid()}>
                                    <TableCell verticalAlign="middle">
                                        <Text color="primary" variant="body-sm">
                                            {strings(field.name)}
                                        </Text>
                                    </TableCell>
                                    <TableCell verticalAlign="middle">
                                        <Text color="primary" variant="body-sm">
                                            {strings(field.info)}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    render() {
        const { selectedType, detailsTab, setDetailsTab, strings } = this.props;
        let properties = [{ settings: selectedType?.settings?.general, name: strings("Основные поля") }];

        if (selectedType?.settings?.sections) {
            properties = [...properties, selectedType?.settings?.sections].flat();
        }

        const showPropertiesTab = properties.some((p) => p.settings?.length > 0);

        return (
            <EventsLayoutSecondary>
                <DetailsBox>
                    <DetailsBoxHeader sticky>
                        <DetailsBoxTitle>
                            <Text color="primary" variant="title" component="h2">
                                {strings(selectedType.label)}
                            </Text>
                        </DetailsBoxTitle>

                        <DetailsBoxHeaderActions>
                            <Button icon="close" variant="secondary" onClick={() => this.handleCloseDetailedView()} />
                        </DetailsBoxHeaderActions>
                    </DetailsBoxHeader>

                    <DetailsBoxTabs>
                        <TabPanel>
                            <Tab
                                label={strings("Общее")}
                                isActive={detailsTab === "main"}
                                onClick={() => setDetailsTab("main")}
                            />
                            {showPropertiesTab && (
                                <Tab
                                    label={strings("Список полей")}
                                    isActive={detailsTab === "properties"}
                                    onClick={() => setDetailsTab("properties")}
                                />
                            )}
                        </TabPanel>
                    </DetailsBoxTabs>
                    {detailsTab === "main" && this.DetailsTabMain({ selectedType })}
                    {detailsTab === "properties" && properties.map((p) => this.DetailsTabProperties(p))}
                </DetailsBox>
            </EventsLayoutSecondary>
        );
    }
}

export default withCultureContext(withSnackbar(DeviceTypesDetails));

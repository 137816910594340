const path = "/variables-api/variables";

export const UpdateVariable = async (variable) => {
    const { serviceCode, code, properties } = variable;
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${serviceCode}/${code}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ properties }),
    });

    return [response.status === 200, response.status];
};

export const DeleteVariable = async (serviceCode, code) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${serviceCode}/${code}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response.status === 200, response.status];
};

export const GetVariables = async () => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();
        return [true, res?.items];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const CreateVariable = async (variable) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(path, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(variable),
        });

        if (response.status !== 200) {
            return [false, response.status];
        }

        return [response.status === 200, response.status];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

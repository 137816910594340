export const SHOW_CREATE_DEVICE = "deviceSettings.showCreateDevice.topic";
export const SHOW_UPDATE_DEVICE = "deviceSettings.showUpdateDevice.topic";
export const SHOW_DELETE_DEVICE = "deviceSettings.showDeleteDevice.topic";

export const SHOW_CREATE_LOCATION = "deviceSettings.showCreateLocation.topic";
export const SHOW_UPDATE_LOCATION = "deviceSettings.showUpdateLocation.topic";
export const SHOW_DELETE_LOCATION = "deviceSettings.showDeleteLocation.topic";

export const SHOW_IMPORT_FILE_MODAL = "deviceSettings.showImportFileModal.topic";

export const COPY_RTSP_URL = "deviceSettings.copyRtspUrl";

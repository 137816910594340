import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { Text, Table, TableBody, TableRow, TableCell, Row, TableHead, TableHeadCell } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class DetailsTabMain extends React.Component {
    render() {
        const { selectedRule } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Длительность проверки видеопотока")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {`${selectedRule?.properties?.analyseDurationSec} ${strings("сек")}`}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Частота выполнения проверки")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {`${selectedRule?.properties?.startInterval} ${strings("сек")}`}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Постоянная проверка")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedRule?.properties?.permanent ? strings("Да") : strings("Нет")}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Создание событий")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedRule?.properties?.createEvent ? strings("Да") : strings("Нет")}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Комментарий")}</TableCell>
                                    <TableCell>
                                        <Text scroll color="primary" variant="body-sm">
                                            {selectedRule?.properties?.comment}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Row marginTop={24} marginBottom={24}>
                            <Text color="primary" variant="body">
                                {strings("Правила проверки")}
                            </Text>
                        </Row>

                        <Table isFixed noPadding>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell />
                                    <TableHeadCell />
                                    <TableHeadCell>{strings("Допустимое")}</TableHeadCell>
                                    <TableHeadCell>{strings("Предельное")}</TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Колебание битрейта - %")}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Text color="warning" variant="body-sm">
                                            {selectedRule?.properties?.bitrateViolation.warningViolation}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text color="danger" variant="body-sm">
                                            {selectedRule?.properties?.bitrateViolation.criticalViolation}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Потери кадров - %")}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Text color="warning" variant="body-sm">
                                            {selectedRule?.properties?.packetLossViolation.warningViolation}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text color="danger" variant="body-sm">
                                            {selectedRule?.properties?.packetLossViolation.criticalViolation}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Колебание частоты кадров - %")}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Text color="warning" variant="body-sm">
                                            {selectedRule?.properties?.fpsViolation.warningViolation}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        <Text color="danger" variant="body-sm">
                                            {selectedRule?.properties?.fpsViolation.criticalViolation}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

import { Button, Modal } from "headpoint-react-components";
import React, { useState } from "react";
import styled from "styled-components";

const LicenseAgreementModal = ({ Close, Ok, strings }) => {
    const [scrolled, setScrolled] = useState(false);

    return (
        <Modal size="lg" closeHandler={Close}>
            <LicenseModalHeader>
                <CloseButton type="button" variant="secondary" icon="close" onClick={Close} />
                <HeaderTitle>{strings("Лицензионное соглашение")}</HeaderTitle>
            </LicenseModalHeader>
            <LicenseModalBody
                onScroll={(e) => {
                    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
                    if (bottom) {
                        setScrolled(true);
                    }
                }}
            >
                <h2>ПРАВИЛА (ЛИЦЕНЗИОННЫЕ УСЛОВИЯ) ИСПОЛЬЗОВАНИЯ ПРОДУКТОВ ХЭД ПОЙНТ ДЛЯ КОНЕЧНОГО ПОЛЬЗОВАТЕЛЯ</h2>
                <h3>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
                <br />
                <p>
                    <b>Конечный Пользователь (Пользователь, Лицензиат)</b> – юридическое или физическое лицо, получившее
                    право использования Продукта для его внутренней деятельности без права предоставления лицензий
                    другим лицам и дальнейшего распространения Продукта. Использование Продукта Конечным пользователем
                    допускается ТОЛЬКО при условии соблюдения Лицензионных условий использования продуктов Хэд Пойнт с
                    конечным пользователем (приведенными ниже).
                </p>
                <p>
                    <b>Правообладатель продуктов Хэд Пойнт (далее - Правообладатель)</b> – ООО «Хэд Пойнт».
                </p>
                <p>
                    <b>Продукт Хэд Пойнт (Продукт, Продукты)</b> – программа или совокупность программ для ЭВМ, для
                    предоставления Конечным Пользователям под определённым коммерческим наименованием как отдельно, так
                    и совместно с другими программами.
                </p>
                <p>
                    <b>Программа для ЭВМ</b> – представленная в объективной форме совокупность данных и команд (могут
                    быть выражены на любом языке и в любой форме, включая исходный текст и объектный код),
                    предназначенных для функционирования электронной вычислительной машины (ЭВМ) и других компьютерных
                    устройств, в том числе мобильных, в целях получения определенного результата, включая
                    подготовительные материалы, полученные в ходе разработки программы для ЭВМ, и порождаемые ею
                    аудиовизуальные отображения.
                </p>
                <p>
                    <b>Использование Продукта</b> – воспроизведение и применение Продукта по его функциональному
                    назначению (инсталляция, запуск и другие разрешенные действия) в соответствии с Документацией на
                    Продукт, а так же иные предусмотренные настоящими условиями способы использования, разрешения и
                    ограничения.
                </p>
                <p>
                    <b>Документация</b> – сопроводительные материалы, включая файлы, являющиеся неотъемлемой частью
                    соответствующего Продукта и содержащие описание Продукта, его возможностей, компонентов,
                    особенностей настройки и применения по функциональному назначению. Документация доступна Конечному
                    Пользователю на Сайте Правообладателя по адресу в сети Интернет:
                    <a href="https://head-point.ru/about/documentation">
                        {" https://head-point.ru/about/documentation"}
                    </a>
                    .
                </p>
                <p>
                    <b>Интернет</b> – совокупность международных автоматизированных систем информации, связи и сервисов,
                    наложенных на технологическую среду сетей электросвязи.
                </p>
                <p>
                    <b>Лицензия</b> – предоставляемое право использования Продукта с сохранением за Правообладателем
                    права выдачи лицензий другим лицам (простая (неисключительная) лицензия). Обновление(-я) – все
                    улучшения, исправления, расширения и / или модификации Продукта, не приводящие к созданию нового
                    Продукта.
                </p>
                <p>
                    <b>Версия Продукта</b> – цифровое обозначение Продукта, состоящее из двух или более чисел,
                    разделяемых символом «.». Первое число, является основным обозначением версии Продукта. Последующее
                    числовые обозначения обозначают обновления и исправления.
                </p>
                <p>
                    <b>Сайт Правообладателя</b> – информационный ресурс Правообладателя, доступный в сети Интернет по
                    адресу:
                    <a href="http://www.head-point.ru">{" http://www.head-point.ru"}</a>. Территория (Географический
                    регион Лицензиата): территория Российской Федерации.
                </p>
                <h3>2. ЛИЦЕНЗИОННЫЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</h3>
                <p>Пользуясь Продуктом, Пользователь соглашается с тем, что:</p>
                <ul className="cyrilic">
                    <Li point="а) ">
                        ознакомился с Лицензионными условиями в полном объеме до начала использования Продукта.
                    </Li>
                    <Li point="б) ">
                        принимает Лицензионные условия в полном объеме без каких-либо изъятий и ограничений с его
                        стороны. Использование Продукта на иных условиях не допускается
                    </Li>
                    <Li point="в) ">
                        Лицензионные условия могут быть изменены Правообладателем без какого-либо специального
                        уведомления Пользователя. Новая редакция вступает в силу с момента ее размещения на Сайте
                        Правообладателя, если иное не предусмотрено такой редакцией.
                    </Li>
                </ul>
                <p>
                    Если Пользователь не согласен с Лицензионными условиями, ему следует незамедлительно прекратить
                    любое использование Продукта и удалить копию Продукта.
                </p>
                <p>
                    Пользователю предоставлена простая (неисключительная) лицензия на использование определенных
                    Продуктов и Документации в течение срока действия исключительных прав, включая любые расширения и
                    обновления Продукта, на условиях, указанных в настоящем документе, без права на сублицензирование.
                    Пользователь должен использовать Продукт исключительно для своих внутренних деловых целей.
                </p>
                <p>
                    <b>Пользователю предоставлено право использовать Продукт следующими способами:</b>
                </p>
                <ul className="cyrilic">
                    <Li point="-">
                        устанавливать и использовать Продукт только в соответствии с их функциональным назначением,
                        определенным Документацией;
                    </Li>
                    <Li point="-">
                        модифицировать лишь те части Продукта, которые определены в Документации, как «настраиваемые»;
                    </Li>
                    <Li point="-">
                        копировать любую часть Продукта или Продукт в целом, в том числе Документацию, исключительно с
                        целью создания разумного числа копий для нормального резервирования и архивирования.
                    </Li>
                </ul>

                <p>
                    <b>Конечный пользователь не вправе осуществлять следующие действия в отношении Продукта:</b>
                </p>
                <ul className="cyrilic">
                    <Li point="-">
                        копировать Продукт отдельными частями, вносить какие-либо изменения или дополнения при
                        копировании Продукта, а также любым иным образом нарушать форму Продукта, установленную
                        Правообладателем. Конечный пользователь не должен разрешать другим делать какие-либо копии
                        Продукта, не разрешать другим копировать Документацию без предварительного письменного согласия
                        Правообладателя;
                    </Li>
                    <Li point="-">
                        инсталлировать и применять Продукт иными способами, кроме как определенных в Документации и в
                        настоящем документе;
                    </Li>
                    <Li point="-">
                        производить модификацию, изменение Продукта, его анализ, за исключением тех частей Продукта,
                        которые определены Документацией как «настраиваемые»;
                    </Li>
                    <Li point="-">
                        адаптировать, разбирать на составляющие Продукт, изменять, декомпилировать, дизассемблировать,
                        дешифровать и производить иные действия с Продуктом, имеющие целью получение информации о
                        взаимодействии любых компонентов Продукта и реализации алгоритмов, используемых в Продукте;
                    </Li>
                    <Li point="-">
                        создавать производные произведения с использованием Продукта, а также осуществлять (разрешать
                        осуществлять) иное использование Продукта или любых компонентов Продукта, без письменного
                        согласия Правообладателя, кроме случаев, специально оговоренных действующим законодательством
                        Российской Федерации;
                    </Li>
                    <Li point="-">обременять, уступать или сдавать в аренду Продукт;</Li>
                    <Li point="-">распространять, публиковать, сбывать или продавать любую часть Продукта;</Li>
                    <Li point="-">
                        передавать право использования Продукта третьим лицам, если это не было явно разрешено
                        Правообладателем.
                    </Li>
                </ul>
                <p>
                    Любая копия Продукта на любом материальном носителе, выполненная и применяемая с нарушением условий
                    настоящих Правил, должна быть немедленно уничтожена. Обладание таким экземпляром (копией) не
                    считается правомерным владением экземпляром.
                </p>
                <p>
                    Конечный пользователь обязан осуществлять и поддерживать адекватные условия безопасности для защиты
                    Продукта и всех находящихся у него копий, а так же Документации, от неавторизованного доступа.
                </p>

                <h3>3. ПРАВА СОБСТВЕННОСТИ</h3>
                <p>
                    Правообладатель сохраняют за собой все права на Продукт и Документацию к нему и любые изменения к
                    ним, включая правовой титул, право собственности и права интеллектуальной собственности, а также все
                    прочие права и интересы. Конечный пользователь владеет только оборудованием или носителями, на
                    которых хранятся Продукт и Документация (при наличии таковых).
                </p>
                <p>
                    Конечный пользователь обязуется не удалять, не подавлять или не модифицировать любым способом любую
                    фирменную маркировку, включая любую торговую марку или уведомления об авторском праве, или в
                    Продукте, или которые видны в процессе его эксплуатации, или которые находятся на его носителях.
                    Конечный пользователь должен включать такие отметки о собственности на любые резервные копии
                    Продукции.
                </p>
                <p>
                    Конечный пользователь должен немедленно уведомить Правообладателя, если ему становится известно о
                    любом несанкционированном доступе к использованию или копированию Продукта со стороны любого лица.
                </p>

                <h3>4. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ</h3>
                <p>
                    Продукт предоставляется на условиях «как есть» (as is). Правообладатель не предоставляет никаких
                    гарантий в отношении безошибочной и бесперебойной работы Продукта, соответствия Продукта конкретным
                    целям и ожиданиям Конечного пользователя, а также не предоставляет никаких иных гарантий, прямо не
                    указанных в настоящих лицензионных условиях использования.
                </p>
                <p>
                    Все риски и ответственность за использование Продукта несет Конечный пользователь. Конечный
                    пользователь соглашается с тем, что он сам несет ответственность за выбор Продукта для достижения
                    нужных результатов, за установку и применение Продукта по функциональному назначению, а также за
                    результаты, полученные с его помощью.
                </p>
                <p>
                    Ни при каких обстоятельствах Правообладатель не несет ответственность за упущенную выгоду,
                    вытекающую из предоставленного права использования Продукта.
                </p>
                <p>
                    Правообладатель не несет ответственность за какие-либо убытки, ущерб, причиненные вследствие
                    ненадлежащего использования или невозможности использования Продукта, возникшие по вине Конечного
                    пользователя.
                </p>
                <p>
                    Необходимость и периодичность выпуска Обновлений и исправлений определяется Правообладателем по его
                    собственному усмотрению.
                </p>
                <p>
                    Конечный пользователь вправе получать исправления Продукта 1 (один) год с момента предоставления
                    прав, если иной срок не предусмотрен Правообладателем.
                </p>
                <p>
                    Правообладатель может по собственному усмотрению прекратить обновления и исправления для любой
                    версии Продукта, кроме наиболее актуальной версии, или же обновления, которые поддерживают
                    использование Продукта в связи с любой версией операционных систем, программ, браузеров и другого
                    программного обеспечения.
                </p>
            </LicenseModalBody>
            <LicenseModalFooter>
                <Button
                    disabled={!scrolled}
                    variant="secondary-on-white"
                    label={strings("Я принимаю условия лицензионного соглашения")}
                    onClick={Ok}
                />
            </LicenseModalFooter>
        </Modal>
    );
};

const Li = styled.li`
    list-style-type: none;
    font-size: 16px;
    line-height: 22px;
    padding: 0;
    margin: 0;

    &:before {
        content: "${(props) => props.point}";
    }
`;

const LicenseModalHeader = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blackText};
    padding: 40px;
`;

const LicenseModalBody = styled.div`
    font-family: ${({ theme }) => theme.fonts.medium};

    width: 100%;
    display: block;
    padding: 0 40px 0 40px;
    color: ${({ theme }) => theme.colors.blackText};
    background-color: ${({ theme }) => theme.colors.white};
    height: 65vh;
    overflow-y: scroll;

    ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    & p {
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        margin: 0;
    }

    & p + p {
        margin-top: 26px;
    }
`;

const LicenseModalFooter = styled.div`
    width: 100%;
    display: grid;
    place-content: center;
    background-color: #f4f4f4;
    color: ${({ theme }) => theme.colors.blackText};
    height: 10vh;
`;

const HeaderTitle = styled.h2`
    margin: 0;
    display: block;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.regular};
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
`;

const CloseButton = styled(Button)`
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.75);
    border-color: rgba(13, 22, 35, 0.5);

    @media (hover: hover) {
        &:hover {
            color: rgba(0, 0, 0, 1);
            border-color: rgba(13, 22, 35, 0.75);
        }
    }
`;

export default LicenseAgreementModal;

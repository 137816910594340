import React from "react";
import { DeleteModal } from "../../../DeleteModal";
import { withSnackbar } from "notistack";
import PubSub from "pubsub-js";
import { CLOSE_ALL_MODALS, ROLE_GROUP_DELETED } from "../../rolesSettingsTopics";
import { DeleteRoleGroup } from "../../../../../../services/roles";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class DeleteRolesGroupModal extends React.Component {
    removeRolesGroup = async () => {
        const { group, strings } = this.props;
        if (!group?.id) {
            return;
        }

        let [status, statusCode, response] = await DeleteRoleGroup(group.id);
        if (status) {
            this.props.enqueueSnackbar(`${strings("Группа ролей")} '${group.name}' ${strings("удалена")}`, {
                variant: "success",
            });
            PubSub.publish(ROLE_GROUP_DELETED, group);
            PubSub.publish(CLOSE_ALL_MODALS);
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(`${strings("Не хватает прав для удаления роли")} '${group.name}'`, {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    switch (response.code) {
                        case "conflict-children":
                            this.props.enqueueSnackbar(
                                `${strings("Нельзя удалить")} '${group.name}' ${strings("- роль имеет потомков")}`,
                                {
                                    variant: "warning",
                                }
                            );
                            break;
                        default:
                            this.props.enqueueSnackbar(strings("Неизвестный конфликт"), { variant: "warning" });
                            break;
                    }

                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось удалить роль")} '${group.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }
    };

    render() {
        const { strings } = this.props;

        return (
            <DeleteModal
                visible={true}
                CloseHandler={() => PubSub.publish(CLOSE_ALL_MODALS)}
                RemoveHandler={this.removeRolesGroup}
                text={`${strings("Вы хотите удалить группу ролей")} "${this.props.group?.name}". ${strings(
                    "Удалённую группу ролей нельзя восстановить. Продолжить?"
                )}`}
            />
        );
    }
}

export default withCultureContext(withSnackbar(DeleteRolesGroupModal));

import React from "react";
import styled from "styled-components";
import PlanComponent from "../../../../plans/components/PlanComponent";
import { PlanAccordionList } from "../../../../plans/components/PlanAccordionList";
import { nanoid } from "nanoid";
import { withSnackbar } from "notistack";
import { GetDevices } from "../../../../../services/devices";
import { GetLocations, FillLocations, FillNode } from "../../../../../services/locations";
import { GetPlans, GetPoints, UpdatePoint, DeletePoint, AddPoint, CompareNodes } from "../../../../../services/plans";
import { Modal, ModalHeader, ModalBody } from "headpoint-react-components";
import {
    FilterGroup,
    FilterGroupList,
    FilterAccordionPartion,
    FilterWrapper,
} from "../../../../components/FiltersPartion";
import { LayoutMain } from "../../../../../layout/MainLayout";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { FilterAccordion } from "../../../../components/Filters";

class DevicesOnPlanModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            points: props.points ?? [],
            devicesLocationsTree: [],
            plansLocationsTree: [],

            selectedPlan: props.selectedPlan ?? undefined,
            checkedDevices: [],
        };

        this.planIds = [];
        if (props.selectedPlan) {
            this.planIds.push(props.selectedPlan.id);
        }
    }

    async componentDidMount() {
        const [locationsStatus, locations] = await GetLocations();
        if (!locationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        this.setState({
            devicesLocationsTree: FillLocations(locations ?? [], []),
            plansLocationsTree: FillLocations(locations ?? [], []),
        });
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const [locationsStatus, locations] = await GetLocations();
            if (!locationsStatus) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
                return;
            }

            this.setState({
                devicesLocationsTree: FillLocations(locations ?? [], []),
                plansLocationsTree: FillLocations(locations ?? [], []),
                selectedPlan: undefined,
                checkedDevices: [],
                devices: [],
                points: [],
            });
        }
    };

    updateTree(tree) {
        tree?.forEach((node) => {
            node.accordionId = nanoid();
            if (node.children) {
                this.updateTree(node.children);
            }
        });
    }

    openLocationPlan = async (locationNode) => {
        const { strings } = this.props;
        const { plansLocationsTree } = this.state;
        const [locationsStatus, children] = await GetLocations(locationNode.id);
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [plansStatus, plans] = await GetPlans(locationNode.id);
        if (!plansStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения планов"), { variant: "error" });
            return;
        }

        locationNode.children =
            children?.map((l) => ({
                ...l,
                tag: "location",
                isClosed: true,
                children: [],
            })) ?? [];

        locationNode.children = locationNode.children.concat(
            plans?.map((p) => ({ ...p, viewId: nanoid(), deviceCount: 0, tag: "plan" })) ?? []
        );

        this.setState({ plansLocationsTree });
    };

    openDeviceLocation = async (locationNode) => {
        const { strings } = this.props;
        const { devicesLocationsTree, devices } = this.state;

        const [getDevicesStatus, newDevices] = await GetDevices(locationNode.id);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, newDevices);
        }

        this.updateTree(devicesLocationsTree);

        newDevices.forEach((nd) => {
            if (devices.find((d) => d.id === nd.id)) {
                return;
            }

            devices.push(nd);
        });

        this.setState({
            devicesLocationsTree,
            devices,
        });
    };

    onDragHandler = async (p, l) => {
        const { selectedPlan } = this.state;
        const lngBounds = (lng) => {
            if (lng > 180) return 180;
            if (lng < -180) return -180;
            return lng;
        };

        await UpdatePoint(p.id, {
            planId: selectedPlan.id,
            x: lngBounds(l.lng),
            y: l.lat,
        });

        const [pointsStatus, points] = await GetPoints(selectedPlan.id);
        if (!pointsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(`${strings("Ошибка получения объектов плана")} '${selectedPlan.name}'`, {
                variant: "error",
            });
        }

        this.setState({ points });
    };

    deleteDevicesHandler = async (items, nextPoints) => {
        const { strings } = this.props;
        const { selectedPlan } = this.state;
        const newIds = (nextPoints?.map((p) => p.id) ?? [])?.filter((e) => !items.includes(e));
        for (const id of newIds) {
            if (!(await DeletePoint(id))) {
                this.props.enqueueSnackbar(`${strings("Ошибка удаления устройства с плана")} '${selectedPlan.name}'`, {
                    variant: "error",
                });
            }

            const [pointsStatus, newPoints] = await GetPoints(selectedPlan.id);
            if (!pointsStatus) {
                this.props.enqueueSnackbar(`${strings("Ошибка получения объектов плана")} '${selectedPlan.name}'`, {
                    variant: "error",
                });
            }

            this.setState({ points: newPoints ?? [] });
        }
    };

    selectDevicesHandler = async (items, nextPoints) => {
        const { strings } = this.props;
        const { devices, selectedPlan } = this.state;
        if (!selectedPlan) {
            return;
        }

        const newIds = items?.filter((e) => !(nextPoints?.map((p) => p.id) ?? []).includes(e));
        const oldIds = items?.filter((e) => (nextPoints?.map((p) => p.id) ?? []).includes(e));

        await this.deleteDevicesHandler(oldIds, nextPoints);

        for (const id of newIds) {
            let device = devices.find((p) => p.id === id);
            if (!device) {
                continue;
            }

            let [result, statusCode] = await AddPoint({
                planId: selectedPlan.id,
                pointId: device.id,
                x: 0,
                y: 0,
            });

            if (!result) {
                switch (statusCode) {
                    case 409:
                        this.props.enqueueSnackbar(strings(`Устройство размещено на другом плане`), {
                            variant: "error",
                        });
                        break;
                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Ошибка добавления устройства на план")} '${selectedPlan.name}'`,
                            {
                                variant: "error",
                            }
                        );
                }
            }
        }

        const [pointsStatus, newPoints] = await GetPoints(selectedPlan.id);
        if (!pointsStatus) {
            this.props.enqueueSnackbar(`${strings("Ошибка получения объектов плана")} '${selectedPlan.name}'`, {
                variant: "error",
            });
            return;
        }

        this.setState({ points: newPoints ?? [] });
    };

    handleSelectPlan = async (plan) => {
        const [pointsStatus, points] = await GetPoints(plan.id);
        if (!pointsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(`${strings("Ошибка получения объектов плана")} '${plan.name}'`, {
                variant: "error",
            });
        }

        if (!this.planIds.includes(plan.id)) {
            this.planIds.push(plan.id);
        }

        this.setState({ selectedPlan: plan, points: points ?? [] });
    };

    render() {
        const { handleClose, strings } = this.props;
        const { devicesLocationsTree, plansLocationsTree, selectedPlan, points } = this.state;

        return (
            <Modal size="xlg" closeHandler={() => handleClose(this.planIds)}>
                <ModalHeader
                    title={strings("Размещение оборудования на плане")}
                    align="center"
                    closeHandler={() => handleClose(this.planIds)}
                />
                <ModalBody>
                    <ModalSidebar>
                        <FilterWrapper>
                            <FilterGroup
                                title={strings("План")}
                                hasValue={!!selectedPlan}
                                isMuted={!plansLocationsTree?.length}
                                onClear={() => {
                                    this.setState({ selectedPlan: undefined });
                                }}
                            >
                                <FilterGroupList>
                                    <PlanAccordionList
                                        list={plansLocationsTree ?? []}
                                        onSelect={(plan) => this.handleSelectPlan(plan)}
                                        selected={selectedPlan}
                                        openLocation={this.openLocationPlan}
                                    ></PlanAccordionList>
                                </FilterGroupList>
                            </FilterGroup>
                            <FilterGroup
                                title={strings("Оборудование")}
                                hasValue={!!points?.length}
                                isMuted={!devicesLocationsTree?.length}
                                onClear={async () => await this.deleteDevicesHandler([], points)}
                            >
                                <FilterGroupList>
                                    <FilterAccordion
                                        items={devicesLocationsTree ?? []}
                                        selected={points?.map((p) => p.id) ?? []}
                                        selectHandler={async (items) => await this.selectDevicesHandler(items, points)}
                                        openItem={this.openDeviceLocation}
                                        blockParentCheckbox={true}
                                    />
                                </FilterGroupList>
                            </FilterGroup>
                            <FilterGroup
                                title={strings("Выбранное оборудование")}
                                hasValue={!!points?.length}
                                isMuted={!points?.length}
                                onClear={async () => await this.deleteDevicesHandler([], points)}
                            >
                                <FilterGroupList>
                                    <FilterAccordionPartion
                                        items={points?.sort(CompareNodes) ?? []}
                                        selected={points?.map((p) => p.id) ?? []}
                                        selectHandler={async (items) => await this.deleteDevicesHandler(items, points)}
                                        openItem={this.openDeviceLocation}
                                    />
                                </FilterGroupList>
                            </FilterGroup>
                        </FilterWrapper>
                    </ModalSidebar>
                    {selectedPlan ? (
                        <PlanComponent plan={selectedPlan} points={points} onDrag={this.onDragHandler} />
                    ) : (
                        <LayoutMain style={{ minHeight: "800px" }} />
                    )}
                </ModalBody>
            </Modal>
        );
    }
}

const ModalSidebar = styled.div`
    flex: 0 1 256px;
    padding: 12px 16px;
    width: 256px;
    border-right: 1px solid ${({ theme }) => theme.colors.primary3};
`;

export const LayoutSidebarBody = styled.div`
    flex: 1;
    overflow: auto;
`;

export const LayoutSidebarItem = styled.div`
    margin-bottom: 16px;
`;

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(DevicesOnPlanModal)));

import { ModalStepDefault } from "./Modals/ModalStepDefault";
import { ModalStepCheckParameters } from "./Modals/ModalStepCheckParameters";
import ModalStepSchedule from "./Modals/ModalStepSchedule";

export function wizardSteps(values) {
    if (values?.permanent) {
        return [
            { name: "Общее", component: ModalStepDefault },
            { name: "Параметры проверки", component: ModalStepCheckParameters },
        ];
    } else {
        return [
            { name: "Общее", component: ModalStepDefault },
            { name: "Параметры проверки", component: ModalStepCheckParameters },
            { name: "Расписание", component: ModalStepSchedule },
        ];
    }
}

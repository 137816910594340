import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField, Button, Select, Row, Text } from "headpoint-react-components";
import { InputItem, InputItemRemoveBtn } from "../../../../../components/CreateUpdateWizard/Styled";
import uuid from "react-uuid";
import { GeneralContextConsumer, getEventTypes, permissionExists } from "../../../../../../contexts";
import { CompareNodes, GetTemplates } from "../../../../../../services/scenarios";
import { withSnackbar } from "notistack";
import {
    DateOption,
    DayOption,
    MonthOption,
    TimeDateOption,
    TimeIntervalOption,
    TimeOption,
    YearOption,
} from "../../../../../components/TimeOptions";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class ModalStepDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scenarioTemplates: [],
        };
    }

    async componentDidMount() {
        let templates = await this.getTemplates();

        this.setState({
            scenarioTemplates: templates,
        });
    }

    async getTemplates() {
        const [status, templates] = await GetTemplates();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения шаблонов выполнения"), { variant: "error" });
        }

        return templates?.data?.templates?.sort(CompareNodes);
    }

    handleClearDevices = () => this.props.setValues({ ...this.props.values, deviceIds: [] });

    handleActionChange = (key, value) => {
        const { values, setValues } = this.props;
        const nextActions = [...values.eventUnitCodes];

        const targetIndex = nextActions.findIndex((item) => item.id === key);

        nextActions[targetIndex] = { ...nextActions[targetIndex], title: value };

        setValues({ ...values, eventUnitCodes: nextActions });
    };

    handleAddAction = () => {
        const { values, setValues } = this.props;
        const nextActions = [...values.eventUnitCodes, { id: uuid(), title: "" }];

        setValues({ ...values, eventUnitCodes: nextActions });
    };

    handleRemoveItem = (id) => {
        const { values, setValues } = this.props;
        const nextActions = [...values.eventUnitCodes];

        const targetIndex = nextActions.findIndex((item) => item.id === id);

        nextActions.splice(targetIndex, 1);

        setValues({ ...values, eventUnitCodes: nextActions });
    };

    handleSelectChange = (key, val) => {
        const { values, setValues } = this.props;
        setValues({
            ...values,
            [key]: val,
        });
    };

    handleTimeChange = (key, timeType) => {
        const { values, setValues } = this.props;
        let dates = values.dates;
        let dateType = values.dateType;
        let defaultTime = [];
        switch (timeType) {
            case "dateTime":
                defaultTime = [new Date()];
                dates = [];
                dateType = "all";
                break;
            case "time":
                defaultTime = ["08:00"];
                break;
            case "interval":
                defaultTime = [
                    {
                        from: "08:00",
                        to: "11:00",
                    },
                ];
                break;
            default:
                defaultTime = [];
        }
        setValues({
            ...values,
            [key]: timeType,
            times: defaultTime,
            dates: dates,
            dateType: dateType,
        });
    };

    handleDateChange = (key, dateType) => {
        const { values, setValues } = this.props;
        let defaultDate = [];
        switch (dateType) {
            case "date":
                defaultDate = [new Date()];
                break;
            case "week":
                defaultDate = Array(7).fill(false);
                break;
            case "month":
                defaultDate = [1];
                break;
            case "year":
                defaultDate = [
                    {
                        day: 1,
                        month: 0,
                    },
                ];
                break;
            default:
                defaultDate = [];
        }
        setValues({
            ...values,
            [key]: dateType,
            dates: defaultDate,
        });
    };

    render() {
        const { userInfo, values, strings } = this.props;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <ModalSection>
                            <ModalFormItem>
                                <TextField
                                    label={strings("Название сценария реагирования")}
                                    name="title"
                                    type="text"
                                    value={values.name}
                                    onChange={(e) => this.handleSelectChange("name", e.target.value)}
                                    error={!this.props.values.name ? strings("Поле не может быть пустым") : null}
                                    colorVariant="light"
                                    fullWidth
                                />
                            </ModalFormItem>
                            <ModalFormItem>
                                <TextField
                                    label={strings("Комментарий")}
                                    name="comment"
                                    type="text"
                                    colorVariant="light"
                                    fullWidth
                                    inputProps={{
                                        as: "textarea",
                                        rows: 3,
                                    }}
                                    value={values.comment}
                                    onChange={(e) => this.handleSelectChange("comment", e.target.value)}
                                />
                            </ModalFormItem>
                            <ModalFormItem>
                                <Text color="secondary" variant="description">
                                    {strings("Открывающее событие")}
                                </Text>
                                {values.eventUnitCodes?.map((item, i) => (
                                    <Row key={item.id} justify="between" marginTop={4}>
                                        <InputItem inLine>
                                            {values.eventUnitCodes.length > 1 && (
                                                <InputItemRemoveBtn
                                                    type="button"
                                                    onClick={() => this.handleRemoveItem(item.id)}
                                                />
                                            )}

                                            <Select
                                                placeholder={strings("Выберите тип события")}
                                                value={item.title}
                                                onChange={(e) => this.handleActionChange(item.id, e)}
                                                options={getEventTypes(generalInfo)?.map((et) => {
                                                    return {
                                                        label: strings(et?.label),
                                                        value: et?.value,
                                                    };
                                                })}
                                                width={330}
                                            />
                                        </InputItem>

                                        {i === values.eventUnitCodes.length - 1 && (
                                            <Button
                                                elSize="md"
                                                icon="plus"
                                                onClick={this.handleAddAction}
                                                type="button"
                                                variant="secondary"
                                            />
                                        )}
                                    </Row>
                                ))}
                            </ModalFormItem>

                            {permissionExists(userInfo, ["scenarios.template.read"]) ? (
                                <ModalFormItem>
                                    <Select
                                        label={strings("Шаблон реагирования")}
                                        placeholder={strings("Выберите шаблон реагирования")}
                                        value={values.templateId}
                                        onChange={(e) => this.handleSelectChange("templateId", e)}
                                        options={this.state.scenarioTemplates?.map((template) => ({
                                            value: template.id,
                                            label: template.name,
                                        }))}
                                        error={
                                            this.props.values?.templateId
                                                ? null
                                                : strings("Укажите шаблон реагирования")
                                        }
                                        fullWidth
                                    />
                                </ModalFormItem>
                            ) : (
                                <div />
                            )}
                        </ModalSection>

                        <ModalSection>
                            <Row marginBottom={14}>
                                <Text color="primary" variant="body">
                                    {strings("Интервалы выполнения")}
                                </Text>
                            </Row>

                            <ModalFormItem>
                                <Select
                                    label={strings("Время")}
                                    value={values.timeType}
                                    onChange={(e) => this.handleTimeChange("timeType", e)}
                                    options={[
                                        [
                                            { value: "all", label: strings("Всё время") },
                                            { value: "time", label: strings("Точное время") },
                                            { value: "interval", label: strings("Временной интервал") },
                                        ],
                                        [{ value: "dateTime", label: strings("Точное время с датой") }],
                                    ]}
                                    fullWidth
                                />

                                {values.timeType === "time" && (
                                    <TimeOption
                                        times={values.times}
                                        setTimes={(e) => this.handleSelectChange("times", e)}
                                    />
                                )}
                                {values.timeType === "interval" && (
                                    <TimeIntervalOption
                                        intervals={values.times}
                                        setIntervals={(e) => this.handleSelectChange("times", e)}
                                    />
                                )}
                                {values.timeType === "dateTime" && (
                                    <TimeDateOption
                                        dates={values.times}
                                        setDates={(e) => this.handleSelectChange("times", e)}
                                    />
                                )}
                            </ModalFormItem>

                            {values.timeType !== "dateTime" && (
                                <ModalFormItem>
                                    <Select
                                        label={strings("Дата")}
                                        value={values.dateType}
                                        onChange={(e) => this.handleDateChange("dateType", e)}
                                        options={[
                                            { value: "all", label: strings("Все даты") },
                                            { value: "date", label: strings("Точная дата") },
                                            { value: "day", label: strings("Повтор каждый день") },
                                            { value: "week", label: strings("Повтор каждую неделю") },
                                            { value: "month", label: strings("Повтор каждый месяц") },
                                            { value: "year", label: strings("Повтор каждый год") },
                                        ]}
                                        fullWidth
                                    />

                                    {values.dateType === "date" && (
                                        <DateOption
                                            dates={values.dates}
                                            setDates={(e) => this.handleSelectChange("dates", e)}
                                        />
                                    )}
                                    {values.dateType === "week" && (
                                        <DayOption
                                            dates={values.dates}
                                            setDates={(e) => this.handleSelectChange("dates", e)}
                                        />
                                    )}
                                    {values.dateType === "month" && (
                                        <MonthOption
                                            dates={values.dates}
                                            setDates={(e) => this.handleSelectChange("dates", e)}
                                        />
                                    )}
                                    {values.dateType === "year" && (
                                        <YearOption
                                            dates={values.dates}
                                            setDates={(e) => this.handleSelectChange("dates", e)}
                                        />
                                    )}
                                </ModalFormItem>
                            )}
                        </ModalSection>
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(ModalStepDefault)));

import React from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabMain extends React.Component {
    render() {
        const { selectedEvent } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Адаптер")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {strings(selectedEvent.description.adapter)}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Событие адаптера")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {strings(selectedEvent.name)}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Нормализованный тип события")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {strings(selectedEvent.unit.name)}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

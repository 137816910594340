import React from "react";
import { Dropdown, DropdownDivider, DropdownItem, Button } from "headpoint-react-components";
import { permissionExists } from "../../../contexts";
import { CreateDesktop, GetDesktopInfo, GetDesktops, RemoveDesktop, UpdateDesktop } from "../../../services/desktops";
import { DeleteModal } from "../../settings/components/DeleteModal";
import { UpdateDesktopModal } from "./modals/UpdateDesktopModal";
import CreateDesktopModal from "./modals/CreateDesktopModal";
import { withSnackbar } from "notistack";
import { ACTIVATE_DESKTOP_TOPIC } from "./streamTopics";
import PubSub from "pubsub-js";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class Desktops extends React.Component {
    state = {
        desktops: [],
    };

    async componentDidMount() {
        const { strings } = this.props;

        const [getDesktopsStatus, desktops] = await GetDesktops();
        if (!getDesktopsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения рабочих столов"), { variant: "error" });
        }

        this.setState({
            desktops,
        });
    }

    desktopFromDto = (dto) => {
        let desktop = { ...dto };

        delete desktop.properties;

        if (dto.properties) {
            desktop = { ...desktop, ...dto.properties };
        }

        return desktop;
    };

    dtoFromDesktop = (desktop, frames, layout) => {
        let dto = { ...desktop };

        let cameras = {};
        for (let index in frames ?? {}) {
            if (frames[index]?.id) {
                cameras[index] = frames[index].id;
            }
        }

        dto.properties = {
            layout,
            cameras: cameras ?? {},
        };

        return dto;
    };

    selectDesktop = async (d) => {
        const { strings } = this.props;

        let [getDesktopStatus, desktop] = await GetDesktopInfo(d?.id);
        if (!getDesktopStatus || !desktop) {
            this.props.enqueueSnackbar(strings("Ошибка получения рабочего стола"), { variant: "error" });
            return;
        }

        desktop = this.desktopFromDto(desktop);

        PubSub.publish(ACTIVATE_DESKTOP_TOPIC, desktop);
    };

    createDesktopHandler = async () => {
        const { newDesktop, desktops } = this.state;
        const { frames, activeDesktop, strings } = this.props;

        const dto = this.dtoFromDesktop(newDesktop, frames, activeDesktop.layout);

        const [success, statusCodeCreate, id] = await CreateDesktop(dto);

        let desktop;

        if (success) {
            desktop = this.desktopFromDto({ ...dto, id });
            this.props.enqueueSnackbar(strings("Рабочий стол сохранен"), { variant: "success" });
        } else {
            switch (statusCodeCreate) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для сохранения рабочего стола"), {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    this.props.enqueueSnackbar(
                        strings("В одном контексте рабочие столы должны иметь уникальные имена"),
                        {
                            variant: "warning",
                        }
                    );
                    break;

                default:
                    this.props.enqueueSnackbar(strings("Не удалось сохранить рабочий стол"), { variant: "error" });
                    break;
            }

            this.setState({ newDesktop: undefined });

            return;
        }

        this.setState({
            desktops: [...desktops, desktop],
            newDesktop: undefined,
        });

        PubSub.publish(ACTIVATE_DESKTOP_TOPIC, desktop);
    };

    removeDesktopHandler = async () => {
        const { strings } = this.props;
        const { desktops, deleteDesktop } = this.state;

        if (await RemoveDesktop(deleteDesktop.id)) {
            this.setState({
                desktops: desktops?.filter((d) => d.id !== deleteDesktop.id),
                deleteDesktop: undefined,
            });
            this.props.enqueueSnackbar(`${strings("Рабочий стол")} ${deleteDesktop.name} ${strings("удален")}`, {
                variant: "success",
            });
        } else {
            this.setState({ deleteDesktop: undefined });
            this.props.enqueueSnackbar(strings("Не удалось удалить рабочий стол"), { variant: "error" });
        }
    };

    updateDesktopHandler = async () => {
        const { updateDesktop, desktops } = this.state;
        const { frames, activeDesktop, strings } = this.props;

        const dto = this.dtoFromDesktop(updateDesktop, frames, activeDesktop.layout);

        const [success, statusCodeCreate] = await UpdateDesktop(dto);
        if (success) {
            this.props.enqueueSnackbar(strings("Рабочий стол сохранен"), { variant: "success" });
        } else {
            switch (statusCodeCreate) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для сохранения рабочего стола"), {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    this.props.enqueueSnackbar(
                        strings("В одном контексте рабочие столы должны иметь уникальные имена"),
                        {
                            variant: "warning",
                        }
                    );
                    break;

                default:
                    this.props.enqueueSnackbar(strings("Не удалось сохранить рабочий стол"), { variant: "error" });
                    break;
            }

            this.setState({ updateDesktop: undefined });

            return;
        }

        const desktop = this.desktopFromDto(dto);

        const newDesktops = desktops.filter((d) => d.id !== desktop.id);

        this.setState({
            desktops: [...newDesktops, desktop],
            updateDesktop: undefined,
        });

        PubSub.publish(ACTIVATE_DESKTOP_TOPIC, desktop);
    };

    render() {
        const { desktops, newDesktop, deleteDesktop, updateDesktop } = this.state;
        const { userInfo, activeDesktop, strings } = this.props;

        return (
            <>
                <DeleteModal
                    visible={!!deleteDesktop}
                    CloseHandler={() => this.setState({ deleteDesktop: undefined })}
                    RemoveHandler={this.removeDesktopHandler}
                    text={`${strings("Вы хотите удалить рабочий стол")} "${deleteDesktop?.name}". ${strings(
                        "Удалённый рабочий стол нельзя восстановить. Продолжить?"
                    )}`}
                />

                <UpdateDesktopModal
                    visible={!!updateDesktop}
                    CloseHandler={() => this.setState({ updateDesktop: undefined })}
                    SaveHandler={this.updateDesktopHandler}
                    desktop={updateDesktop}
                />

                <CreateDesktopModal
                    visible={!!newDesktop}
                    desktop={newDesktop ?? {}}
                    CloseHandler={() => this.setState({ newDesktop: undefined })}
                    CreateHandler={this.createDesktopHandler}
                    SetDesktopProperty={(n, v) => this.setState({ newDesktop: { ...newDesktop, [n]: v } })}
                />

                <Dropdown
                    closeOnClickInside
                    dynamicContent
                    toggleLabel={activeDesktop?.name ?? strings("Выбрать рабочий стол")}
                    toggleVariant="secondary"
                    menuWidth={"300px"}
                    width={200}
                >
                    {desktops
                        ?.sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        })
                        .map((d) => (
                            <DropdownItem
                                key={d.id}
                                as="div"
                                colorVariant="default"
                                onClick={() => this.selectDesktop(d)}
                                withActions={
                                    <>
                                        <Button
                                            icon="save"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.setState({
                                                    updateDesktop: d,
                                                });
                                            }}
                                            variant="ghost-primary"
                                        />

                                        <Button
                                            icon="trash"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.setState({
                                                    deleteDesktop: d,
                                                });
                                            }}
                                            variant="ghost-danger"
                                        />
                                    </>
                                }
                            >
                                <span>{d.name}</span>
                            </DropdownItem>
                        ))}

                    {permissionExists(userInfo, "desktops.personal.lifeCycle") ||
                    permissionExists(userInfo, "desktops.group.lifeCycle") ||
                    permissionExists(userInfo, "desktops.system.lifeCycle") ? (
                        <>
                            {desktops?.length > 0 && <DropdownDivider />}
                            <DropdownItem
                                as="button"
                                colorVariant="default"
                                onClick={() => {
                                    this.setState({
                                        newDesktop: {},
                                    });
                                }}
                                tabIndex="0"
                            >
                                {strings("Новый рабочий стол")}
                            </DropdownItem>
                        </>
                    ) : (
                        <></>
                    )}
                </Dropdown>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(Desktops)));

import React from "react";
import { Button, Dropdown, DropdownDivider, DropdownItem, Tab, TabPanel, Text } from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../../../../components/DetailsBox";
import PubSub from "pubsub-js";
import { SHOW_DELETE_ROLE_GROUP, SHOW_ROLE_GROUP_DETAILS, SHOW_UPDATE_ROLE_GROUP } from "../rolesSettingsTopics";
import DetailsGroupTabMain from "./DetailsGroupTabMain";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class RolesGroupDetails extends React.Component {
    tabSets = [
        {
            title: "Общее",
            key: "main",
            component: DetailsGroupTabMain,
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
        };
    }

    render() {
        const { selected } = this.props;
        const { detailsTab } = this.state;

        const tabSet = this.tabSets.find((s) => s.key === detailsTab) ?? this.tabSets.find((s) => s.key === "devices");
        const { key: actualDetailsTab, component: actualDetailsComponent } = tabSet ?? {};

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {selected.name}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                        <DropdownItem
                                            as="button"
                                            onClick={() => PubSub.publish(SHOW_UPDATE_ROLE_GROUP, selected)}
                                        >
                                            {strings("Редактировать")}
                                        </DropdownItem>
                                        <DropdownDivider />
                                        <DropdownItem
                                            as="button"
                                            colorVariant="danger"
                                            onClick={() => PubSub.publish(SHOW_DELETE_ROLE_GROUP, selected)}
                                        >
                                            {strings("Удалить")}
                                        </DropdownItem>
                                    </Dropdown>

                                    <Button
                                        icon="close"
                                        variant="secondary"
                                        onClick={() => PubSub.publish(SHOW_ROLE_GROUP_DETAILS, undefined)}
                                    />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    {this.tabSets.map((tab) => (
                                        <Tab
                                            key={`${tab.key}`}
                                            label={strings(tab.title)}
                                            isActive={actualDetailsTab === tab.key}
                                            onClick={() => this.setState({ detailsTab: tab.key })}
                                        />
                                    ))}
                                </TabPanel>
                            </DetailsBoxTabs>

                            {actualDetailsComponent && React.createElement(actualDetailsComponent, { ...this.props })}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { Button, Text } from "headpoint-react-components";
import { GeneralContextConsumer, getDeviceTypes } from "../../../../contexts";
import { getEquipmentType } from "../../../../contexts/GeneralContext";
import { LeftSideTooltip } from "../../../components/DetailsTableTree/LeftSideTooltip";
import styled from "styled-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import { withRouter } from "react-router-dom";

class RtspQualityControlDeviceTooltip extends React.Component {
    deviceType = () => {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => {
                            const deviceType = getDeviceTypes(generalInfo).find(
                                (type) => type.value === this.props.device?.typeId
                            );
                            return (
                                <>
                                    <Text color="grey" variant="body-sm">
                                        {strings("Тип устройства")}
                                    </Text>
                                    <Text color="black" variant="body-sm">
                                        {strings(deviceType?.label)}
                                    </Text>
                                </>
                            );
                        }}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    };

    equipmentType = () => {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => {
                            return (
                                <>
                                    <Text color="grey" variant="body-sm">
                                        {strings("Тип оборудования")}
                                    </Text>
                                    <Text color="black" variant="body-sm">
                                        {strings(getEquipmentType(generalInfo, this.props.device))}
                                    </Text>
                                </>
                            );
                        }}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    };

    location = () => {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <Text color="grey" variant="body-sm">
                            {strings("Локация")}
                        </Text>
                        <Text color="black" variant="body-sm">
                            {this.props.device.fullLocationName}
                        </Text>
                    </>
                )}
            </CultureContextConsumer>
        );
    };

    tooltip = () => {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <TooltipBodyWrapper>
                            <TooltipBodyStyled>
                                {this.location()}
                                {this.equipmentType()}
                                {this.deviceType()}
                            </TooltipBodyStyled>
                            <TooltipButtonWrapper>
                                <Button
                                    onClick={() => this.toDevices()}
                                    label={strings("Карточка устройства")}
                                ></Button>
                            </TooltipButtonWrapper>
                        </TooltipBodyWrapper>
                    </>
                )}
            </CultureContextConsumer>
        );
    };

    toDevices = () => {
        const { device } = this.props;

        this.props.history.push({
            pathname: "/settings",
            state: {
                partition: "/devices",
                type: "SelectDevice",
                device,
            },
        });
    };

    render() {
        return <LeftSideTooltip tooltipBody={this.tooltip()}>{this.props.children}</LeftSideTooltip>;
    }
}

const TooltipBodyStyled = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 40px;
    row-gap: 10px;
`;

const TooltipBodyWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    padding: 12px 16px;
`;

const TooltipButtonWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: center;
`;

export default withRouter(RtspQualityControlDeviceTooltip);

import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import uuid from "react-uuid";
import { ModalSection, Text, Button, Row, Select } from "headpoint-react-components";
import { FunctionComponents, TextFieldType } from "../constants";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

export class ModalStepFunctions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scenarioFunctionsList: props.scenarioFunctionsList,
        };
    }

    handleRemoveFunction = (id) => {
        const { values, SetValuesPropertie } = this.props;

        const nextFunctions = [...values.functions];

        const targetIndex = nextFunctions.findIndex((item) => item.id === id);

        nextFunctions.splice(targetIndex, 1);

        SetValuesPropertie("functions", nextFunctions);
    };

    handleAddFunction = () => {
        const { values, SetValuesPropertie } = this.props;

        const nextFunctions = [...values.functions, { id: uuid(), name: "", parameters: {} }];

        SetValuesPropertie("functions", nextFunctions);
    };

    handleFunctionSelect = (key, name, value) => {
        const { values, SetValuesPropertie } = this.props;

        const nextFunctions = [...values.functions];

        const targetIndex = nextFunctions.findIndex((item) => item.id === key);

        nextFunctions[targetIndex] = {
            ...nextFunctions[targetIndex],
            [name]: value,
        };

        SetValuesPropertie("functions", nextFunctions);
    };

    handleAddParameters = (key, name, value) => {
        const { values, SetValuesPropertie } = this.props;

        const nextFunctions = [...values.functions];

        const targetIndex = nextFunctions.findIndex((item) => item.id === key);

        nextFunctions[targetIndex].parameters[name] = value;

        SetValuesPropertie("functions", nextFunctions);
    };

    render() {
        const { scenarioFunctionsList } = this.state;
        const { values } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        {values.functions.map((item, i) => (
                            <ModalSection key={item.id}>
                                <Row marginBottom={14} justify="between" align="middle">
                                    <Text color="primary" variant="body">
                                        {`${strings("Функция")} ${i + 1}`}
                                    </Text>

                                    {values.functions.length > 1 && (
                                        <Button
                                            elSize="lg"
                                            icon="trash"
                                            onClick={() => this.handleRemoveFunction(item.id)}
                                            type="button"
                                            variant="ghost-danger"
                                            noPadding
                                        />
                                    )}
                                </Row>

                                <ModalFormItem>
                                    <Select
                                        label={strings("Функция")}
                                        placeholder={strings("Выберите функцию")}
                                        value={item.name}
                                        onChange={(e) => {
                                            this.handleFunctionSelect(item.id, "name", e);
                                        }}
                                        options={
                                            scenarioFunctionsList?.map((x) => ({
                                                value: x.name,
                                                label: x.name,
                                            })) ?? []
                                        }
                                        error={
                                            values?.functions?.some((f) => f?.name === item?.name && f?.name !== "")
                                                ? null
                                                : strings("Выберите функцию")
                                        }
                                        fullWidth
                                    />
                                </ModalFormItem>

                                {scenarioFunctionsList?.find((x) => x.name === item.name) &&
                                    scenarioFunctionsList
                                        ?.find((x) => x.name === item.name)
                                        ?.parameters?.map((x) => {
                                            let PropertyComponent = FunctionComponents[x.type];
                                            return (
                                                <PropertyComponent
                                                    key={`${item.id}${x.name}`}
                                                    type={TextFieldType[x.type] ?? x.type}
                                                    name={x.name}
                                                    description={x.description}
                                                    itemId={item.id}
                                                    handleAddParameters={this.handleAddParameters}
                                                    enumValues={x.enumValues}
                                                    values={values}
                                                />
                                            );
                                        })}
                            </ModalSection>
                        ))}

                        <ModalSection>
                            <Button
                                elSize="lg"
                                label={strings("Добавить ещё функцию")}
                                noPadding
                                onClick={this.handleAddFunction}
                                title={strings("Добавить ещё функцию")}
                                type="button"
                                variant="ghost"
                            />
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

import { ModalSection, Row, Select, Space, Text, TextField } from "headpoint-react-components";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { ModalFormItem } from "../../components/CreateUpdateWizard/Styled";
import { useEffect, useState } from "react";
import { getServicesByFeatures, withGeneralContext } from "../../../contexts/GeneralContext";
import { GROUP_SCHEDULE_TYPE, PERSONAL_SCHEDULE_TYPE, SYSTEM_SCHEDULE_TYPE } from "../constants";
import { FilterAccordionStatic } from "../../components/FiltersStatic";
import { GetUsersGroups } from "../../../services/users";
import { saveScheduleState } from "../../../app/reducers/scheduleReducer";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar } from "notistack";
import { SelectedCounter } from "../../components/SelectedCounter/SelectedCounter";

const ModalStepDefault = ({ setScheduleType, strings, generalInfo, enqueueSnackbar }) => {
    const types = [
        {
            label: strings("Персональный"),
            value: PERSONAL_SCHEDULE_TYPE,
        },
        {
            label: strings("Групповой"),
            value: GROUP_SCHEDULE_TYPE,
        },
        {
            label: strings("Системный"),
            value: SYSTEM_SCHEDULE_TYPE,
        },
    ];

    const dispatch = useDispatch();

    const values = useSelector((state) => state.schedule.values);

    const [features, setFeatures] = useState([]);
    const [usersGroups, setUsersGroups] = useState([]);
    const [search, setSearch] = useState("");

    const saveValue = (name, value) => {
        dispatch(saveScheduleState({ values: { ...values, [name]: value } }));
    };

    useEffect(() => {
        const services = getServicesByFeatures(generalInfo, ["schedules"]);
        const features = services
            .map((s) => s.properties.features.map((f) => ({ ...f, serviceId: s.serviceId })))
            .flat();
        setFeatures(features);

        const fetchUsersGroups = async () => {
            const [status, usersGroups] = await GetUsersGroups();
            if (!status) {
                enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
                return;
            }
            setUsersGroups(usersGroups);
        };

        fetchUsersGroups();
    }, [generalInfo, strings, enqueueSnackbar]);

    const groups = usersGroups?.filter((ug) => ug?.name?.toLowerCase().includes(search?.toLowerCase()));
    return (
        <ModalSection>
            <ModalFormItem>
                <TextField
                    label={strings("Название расписания")}
                    name="name"
                    type="text"
                    colorVariant="light"
                    fullWidth
                    value={values.name}
                    onChange={(e) => saveValue("name", e.target.value)}
                    error={!values.name ? strings("Поле не может быть пустым") : null}
                />
            </ModalFormItem>

            <ModalFormItem>
                <Select
                    label={strings("Вид расписания")}
                    placeholder={
                        values?.feature?.featureClassName
                            ? strings(values.feature.featureClassName)
                            : strings("Выбрать вид")
                    }
                    value={values?.feature}
                    options={features?.map((feature) => ({
                        value: feature,
                        label: strings(feature.featureClassName),
                    }))}
                    onChange={setScheduleType}
                    colorVariant="default"
                    horizontalAlignment="left"
                    verticalAlignment="bottom"
                    fullWidth
                    error={!values.feature ? strings("Поле не может быть пустым") : null}
                />
            </ModalFormItem>

            <ModalFormItem>
                <Select
                    label={strings("Тип расписания")}
                    placeholder={strings("Выбрать тип")}
                    value={values?.type}
                    onChange={(e) => saveValue("type", e)}
                    options={types.map((t) => ({
                        value: t.value,
                        label: strings(t.label),
                    }))}
                    fullWidth
                    error={!values.type ? strings("Поле не может быть пустым") : null}
                />
                {values?.type === GROUP_SCHEDULE_TYPE && (
                    <>
                        <Space />
                        <ModalFormItem>
                            <Row justify="between" align="bottom" marginBottom={24}>
                                <Text variant="body">{strings("Группы пользователей")}</Text>
                                <SelectedCounter
                                    count={values?.groupIds?.length ?? 0}
                                    onClear={() => saveValue("groupIds", [])}
                                    strings={strings}
                                />
                            </Row>
                            <Row marginBottom={12}>
                                <TextField
                                    autoComplete={false}
                                    colorVariant="light"
                                    fullWidth
                                    icon="search"
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder={strings("Найти")}
                                    type="search"
                                    value={search}
                                />
                            </Row>
                            <FilterAccordionStatic
                                key={!!search}
                                preOpen={!!search}
                                items={groups}
                                selected={values?.groupIds ?? []}
                                selectHandler={(e) => saveValue("groupIds", e)}
                            />
                        </ModalFormItem>
                    </>
                )}
            </ModalFormItem>

            <ModalFormItem>
                <TextField
                    label={strings("Комментарий")}
                    name="comment"
                    type="text"
                    colorVariant="light"
                    fullWidth
                    inputProps={{
                        as: "textarea",
                        rows: 3,
                    }}
                    value={values.comment}
                    onChange={(e) => saveValue("comment", e.target.value)}
                />
            </ModalFormItem>
        </ModalSection>
    );
};

export default withSnackbar(withCultureContext(withGeneralContext(ModalStepDefault)));

export const CLOSE_ALL_MODALS = "rolesSettings.closeModals.topic";

export const SHOW_CREATE_ROLE = "rolesSettings.showCreateRole.topic";
export const SHOW_ROLE_DETAILS = "rolesSettings.showRoleDetails.topic";
export const SHOW_ROLE_GROUP_DETAILS = "rolesSettings.showRoleGropuDetails.topic";
export const SHOW_UPDATE_ROLE = "rolesSettings.showUpdateRole.topic";
export const SHOW_COPY_ROLE = "rolesSettings.showCopyRole.topic";
export const SHOW_DELETE_ROLE = "rolesSettings.showDeleteRole.topic";
export const ROLE_CREATED = "rolesSettings.roleCreated.topic";
export const ROLE_UPDATED = "rolesSettings.roleUpdated.topic";
export const ROLE_DELETED = "rolesSettings.roleDeleted.topic";
export const ROLE_COPIED = "rolesSettings.roleCopied.topic";

export const SHOW_CREATE_ROLE_GROUP = "rolesSettings.showCreateRoleGroup.topic";
export const SHOW_UPDATE_ROLE_GROUP = "rolesSettings.showUpdateRoleGroup.topic";
export const SHOW_DELETE_ROLE_GROUP = "rolesSettings.showDeleteRoleGroup.topic";
export const ROLE_GROUP_CREATED = "rolesSettings.roleGroupCreated.topic";
export const ROLE_GROUP_DELETED = "rolesSettings.roleGroupDeleted.topic";
export const ROLE_GROUP_EDITED = "rolesSettings.roleGroupEdited.topic";

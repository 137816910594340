import React, { useRef, useState, useMemo } from "react";
import { Marker as LeafletMarker } from "react-leaflet";
import { useLeafletIcon } from "react-leaflet-icon";
import { PlanPoint } from "headpoint-react-components";

const Marker = ({ position, draggable, onClick, onDragEnd, isMuted, eventVariant, ...rest }) => {
    const markerRef = useRef(null);
    const [isMoving, setIsMoving] = useState(false);
    const [markerIcon, MarkerIcon] = useLeafletIcon();

    const eventHandlers = useMemo(
        () => ({
            dragstart() {
                setIsMoving(true);
            },
            dragend() {
                const marker = markerRef.current;
                if (marker != null && onDragEnd) {
                    onDragEnd(marker.getLatLng());
                }

                setIsMoving(false);
            },
            mouseup() {
                if (!isMoving && onClick) onClick();
            },
        }),
        [isMoving, onDragEnd, onClick]
    );

    return (
        <>
            <MarkerIcon>
                <PlanPoint
                    position={[0, 0]}
                    isMoving={isMoving}
                    isMuted={isMuted}
                    eventVariant={eventVariant}
                    {...rest}
                />
            </MarkerIcon>

            <LeafletMarker
                ref={markerRef}
                position={position}
                icon={markerIcon}
                eventHandlers={eventHandlers}
                zIndexOffset={isMoving ? 1000 : 0}
                draggable={draggable}
            />
        </>
    );
};

export default Marker;

import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class GroupDetailsTabMain extends React.Component {
    getUserGroupRoleName = (roleId) => {
        if (!this.props.availableRoles) {
            return "";
        }

        return this.props.availableRoles.find((role) => role.id === roleId)?.name ?? "";
    };

    render() {
        const { selectedGroup } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Роль")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {this.getUserGroupRoleName(selectedGroup?.properties?.roleId)}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Пользователи")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedGroup?.users?.length}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Комментарий")}</TableCell>
                                    <TableCell>
                                        <Text scroll color="primary" variant="body-sm">
                                            {selectedGroup?.properties?.comment}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { LayoutMain, LayoutPage, LayoutSidebar } from "../../../layout/MainLayout";
import {
    DownloadServiceCardReport,
    DownloadServiceReport,
    GetAvailabilityRules,
    GetDevicesGroups,
    GetPlugins,
    GetQualityRules,
    GetReport,
    GetReportCount,
    GetReportFileUrl,
    serializeObjToQuery,
} from "../../../services/reports";
import Filters from "./Filters";
import { FillLocations, FillNode, GetLocations } from "../../../services/locations";
import { GetDevices } from "../../../services/devices";
import { ReportView } from "./ReportView";
import { CompareObjects } from "../../../utilites/Comparer";
import { nanoid } from "nanoid";
import { ActionTypes, EntityTypes, ReporterFilterTypes, StatusTypes } from "../constants";
import { withSnackbar } from "notistack";
import { permissionExists, getDeviceTypes, GeneralContextConsumer } from "../../../contexts";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { GetUserAccordion, GetUsers, GetUsersGroups } from "../../../services/users";
import RowDetails from "./RowDetails/RowDetails";
import { EventsLayout } from "../../../layout/EventsLayout";
import { saveReportState } from "../../../app/reducers/reportsReducer";

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plugins: [],
            devicesLocationsTree: [],
            sourceFilter: [],
            selectedPlugin: {},
            pageNumber: 1,
            countOnPage: 10,
            count: 0,
            rows: [],
            devices: [],
            dates: {
                from: undefined,
                to: undefined,
            },
            userFilter: [],
            userTree: [],
            statusTree: [],
            statusFilter: [],
            actionTree: [],
            actionFilter: [],
            entityTree: [],
            entityFilter: [],
            showDanger: false,
            showWarning: false,
            showNormal: false,
            deviceTypeFilter: [],
            availabilityRulesFilter: [],
            qualityRulesFilter: [],
            availabilityRules: [],
            qualityRules: [],
            deviceGroups: [],
            deviceGroupsFilter: [],
            selectedRow: null,
        };
    }

    async componentDidMount() {
        const { userInfo, strings } = this.props;
        if (!permissionExists(userInfo, "reports.service.access")) {
            return;
        }

        const [getPluginsStatus, reporterPlugins] = await GetPlugins();
        if (!getPluginsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка при получении списка плагинов"), { variant: "error" });
        }

        const allowedPlugins = reporterPlugins?.filter(
            (p) => p.permissions === null || permissionExists(userInfo, p.permissions)
        );

        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        const [getRulesStatus, availabilityRulesRaw] = await GetAvailabilityRules();

        if (!getRulesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения правил доступности"), {
                variant: "error",
            });
        }
        let availabilityRules = availabilityRulesRaw.map((rule) => {
            return {
                label: rule.name,
                value: rule.id,
            };
        });

        const [getQualityRulesStatus, qualityRulesRaw] = await GetQualityRules();

        if (!getQualityRulesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения правил проверки качества видеоизображений"), {
                variant: "error",
            });
        }

        let qualityRules = qualityRulesRaw.map((rule) => {
            return {
                label: rule.name,
                value: rule.id,
            };
        });

        const [getDevicesGroupsStatus, deviceGroupsRaw] = await GetDevicesGroups();
        if (!getDevicesGroupsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
        }
        let deviceGroups = deviceGroupsRaw.map((group) => {
            return {
                label: group.name,
                value: group.id,
            };
        });

        const tree = FillLocations(locations, []);

        const [getUserGroupsStatus, groups] = await GetUsersGroups();
        if (!getUserGroupsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
        }

        const [getUsersStatus, users] = await GetUsers();
        if (!getUsersStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения пользователей"), { variant: "error" });
        }

        const userGroups = GetUserAccordion(users, groups);

        this.setState({
            plugins: allowedPlugins,
            deviceGroups,
            availabilityRules,
            qualityRules,
            devicesLocationsTree: tree,
            userTree: userGroups,
            actionTree: ActionTypes,
            entityTree: EntityTypes,
            statusTree: StatusTypes,
        });
    }

    async componentDidUpdate(_, prevState, __) {
        const {
            pageNumber,
            sourceFilter,
            dates,
            actionFilter,
            entityFilter,
            statusFilter,
            userFilter,
            selectedPlugin,
            countOnPage,
            showNormal,
            showWarning,
            showDanger,
            deviceTypeFilter,
            availabilityRulesFilter,
            qualityRulesFilter,
            deviceGroupsFilter,
        } = this.state;

        const compareFrom = {
            pageNumber,
            sourceFilter,
            dates,
            actionFilter,
            entityFilter,
            statusFilter,
            userFilter,
            selectedPlugin,
            countOnPage,
            showNormal,
            showWarning,
            showDanger,
            deviceTypeFilter,
            availabilityRulesFilter,
            qualityRulesFilter,
            deviceGroupsFilter,
        };

        if (prevState.selectedPlugin !== selectedPlugin) {
            this.setState({
                sourceFilter: [],
                actionFilter: [],
                entityFilter: [],
                statusFilter: [],
                userFilter: [],
                dates: {
                    from: undefined,
                    to: undefined,
                },
                selectedRow: null,
            });
            this.props.dispatch(saveReportState({ chartSection: null }));

            return;
        }

        if (!CompareObjects(compareFrom, prevState) && selectedPlugin?.hasOwnProperty("id")) {
            let page = compareFrom.pageNumber;

            if (compareFrom.pageNumber === prevState.pageNumber) {
                this.setState({ pageNumber: 1 });
                page = 1;
            }

            await this.loadData(page);
        }
    }

    createFilters() {
        const {
            sourceFilter,
            dates,
            selectedPlugin,
            userFilter,
            actionFilter,
            entityFilter,
            statusFilter,
            showNormal,
            showWarning,
            showDanger,
            deviceTypeFilter,
            availabilityRulesFilter,
            qualityRulesFilter,
            deviceGroupsFilter,
        } = this.state;
        let filtersForRequest = [];

        selectedPlugin.filters.forEach((f) => {
            switch (f.type) {
                case ReporterFilterTypes.DateTime:
                    if (dates.from && dates.to) {
                        f.value = JSON.stringify(dates);
                        filtersForRequest.push({
                            value: JSON.stringify(dates),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.DeviceIds:
                    if (sourceFilter && sourceFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(sourceFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.Severity:
                    let severity = [];
                    showNormal && severity.push("normal");
                    showWarning && severity.push("warning");
                    showDanger && severity.push("danger");

                    if (severity.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(severity),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.DeviceType:
                    if (deviceTypeFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(deviceTypeFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.AvailabilityRule:
                    if (availabilityRulesFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(availabilityRulesFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.QualityRule:
                    if (qualityRulesFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(qualityRulesFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.DeviceGroups:
                    if (deviceGroupsFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(deviceGroupsFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.UserIds:
                    if (userFilter && userFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(userFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.Actions:
                    if (actionFilter && actionFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(actionFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.Entities:
                    if (entityFilter && entityFilter.length > 0) {
                        filtersForRequest.push({
                            value: JSON.stringify(entityFilter),
                            code: f.code,
                        });
                    }
                    break;
                case ReporterFilterTypes.Statuses:
                    if (statusFilter && statusFilter.length > 0) {
                        const statusFilterObj = {
                            ok: statusFilter.includes("ok"),
                            bad: statusFilter.includes("bad"),
                        };

                        filtersForRequest.push({
                            value: JSON.stringify(statusFilterObj),
                            code: f.code,
                        });
                    }
                    break;

                default: {
                }
            }
        });

        return filtersForRequest;
    }

    async loadData(pageNumber) {
        const { data, count } = await this.getReportData(pageNumber);
        this.setState({ rows: data, count: count });
    }

    async getReportData(nextPageNumber) {
        const { strings } = this.props;
        const { selectedPlugin, countOnPage } = this.state;

        let filters = this.createFilters();

        const paginationFilter = {
            pageNumber: nextPageNumber,
            pageSize: countOnPage,
        };
        const [status, data] = await GetReport(selectedPlugin.id, selectedPlugin.service, filters, paginationFilter);
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка при получении отчета"), { variant: "error" });
        }

        const [countStatus, count] = await GetReportCount(
            selectedPlugin.id,
            selectedPlugin.service,
            filters,
            paginationFilter
        );
        if (!countStatus) {
            this.props.enqueueSnackbar(strings("Ошибка при получении количества строк отчета"), { variant: "error" });
        }

        return { data, count };
    }

    downloadReport = async (type, dates) => {
        const { strings, enqueueSnackbar } = this.props;
        const { selectedPlugin, countOnPage, pageNumber } = this.state;

        let filters = this.createFilters();
        const pluginHasOnlyOneReport = selectedPlugin.downloads === null;

        if (pluginHasOnlyOneReport) {
            const token = window.localStorage.getItem("token");
            let filtersQuery = "";

            filters.forEach((f) => (filtersQuery += `&${serializeObjToQuery(f, "filters", filters.indexOf(f))}`));

            const link = document.createElement("a");
            link.href = `${GetReportFileUrl(selectedPlugin.service)}?id=${
                selectedPlugin.id
            }&token=${token}${filtersQuery}`;

            document.body.appendChild(link);
            link.download = "report.xlsx";
            link.click();
            document.body.removeChild(link);
        } else {
            const paginationFilter = {
                pageNumber: pageNumber,
                pageSize: countOnPage,
            };

            const statusCode = await DownloadServiceReport(
                selectedPlugin.id,
                selectedPlugin.service,
                type,
                filters,
                paginationFilter,
                dates
            );

            switch (statusCode) {
                case 404: // not found
                    enqueueSnackbar(strings("Отсутсвует отчет за выбранный период"), {
                        variant: "warning",
                    });
                    break;

                default:
                    break;
            }
        }
    };

    downloadCardReport = async (type, id, dates) => {
        const { strings, enqueueSnackbar } = this.props;

        if (id === null || id === undefined) {
            return;
        }

        const { selectedPlugin } = this.state;

        let filtersForRequest = [];

        selectedPlugin.filters.forEach((f) => {
            switch (f.type) {
                case ReporterFilterTypes.DeviceIds:
                    filtersForRequest.push({
                        value: JSON.stringify([id]),
                        code: f.code,
                    });
                    break;

                default:
                    break;
            }
        });

        const statusCode = await DownloadServiceCardReport(
            selectedPlugin.id,
            selectedPlugin.service,
            type,
            filtersForRequest,
            dates
        );

        switch (statusCode) {
            case 404: // not found
                enqueueSnackbar(strings("Отсутсвует отчет за выбранный период"), {
                    variant: "warning",
                });
                break;

            default:
                break;
        }
    };

    handleShowMore = async () => {
        const { countOnPage } = this.state;
        this.setState({
            countOnPage: countOnPage + countOnPage,
        });
    };

    updateTree(tree) {
        tree?.forEach((node) => {
            node.accordionId = nanoid();
            if (node.children) {
                this.updateTree(node.children);
            }
        });
    }

    openLocation = async (locationNode) => {
        const { strings } = this.props;
        const { devicesLocationsTree } = this.state;

        const [getDevicesStatus, devices] = await GetDevices(locationNode.id);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, devices);
        }

        this.updateTree(devicesLocationsTree);

        this.setState({
            devicesLocationsTree,
            devices,
        });
    };

    render() {
        const { generalInfo } = this.props;
        const {
            plugins,
            devicesLocationsTree,
            sourceFilter,
            dates,
            selectedPlugin,
            rows,
            pageNumber,
            countOnPage,
            count,
            userTree,
            userFilter,
            statusTree,
            statusFilter,
            actionTree,
            actionFilter,
            entityTree,
            entityFilter,
            showDanger,
            showWarning,
            showNormal,
            deviceTypeFilter,
            availabilityRulesFilter,
            qualityRulesFilter,
            availabilityRules,
            qualityRules,
            deviceGroupsFilter,
            deviceGroups,
            selectedRow,
        } = this.state;

        const showReport = selectedPlugin?.hasOwnProperty("id");
        const pagesCount = Math.ceil(count / countOnPage);
        const page = Math.min(pagesCount, pageNumber);
        const detailsEnabled = selectedPlugin?.details !== null;

        return (
            <LayoutPage>
                <LayoutSidebar>
                    <Filters
                        plugins={plugins}
                        defaultDeviceTypes={getDeviceTypes(generalInfo)}
                        defaultAvailabilityRules={availabilityRules}
                        defaultQualityRules={qualityRules}
                        defaultDeviceGroups={deviceGroups}
                        selectedPlugin={selectedPlugin}
                        sourcesTree={devicesLocationsTree}
                        actionTree={actionTree}
                        actionFilter={actionFilter}
                        statusTree={statusTree}
                        statusFilter={statusFilter}
                        userTree={userTree}
                        userFilter={userFilter}
                        entityTree={entityTree}
                        entityFilter={entityFilter}
                        openLocation={(location) => this.openLocation(location)}
                        sourceFilter={sourceFilter}
                        setValue={(name, val) => this.setState({ [name]: val })}
                        dates={dates}
                        showNormal={showNormal}
                        showWarning={showWarning}
                        showDanger={showDanger}
                        deviceTypeFilter={deviceTypeFilter}
                        availabilityRulesFilter={availabilityRulesFilter}
                        qualityRulesFilter={qualityRulesFilter}
                        deviceGroupsFilter={deviceGroupsFilter}
                    />
                </LayoutSidebar>
                <LayoutMain>
                    <EventsLayout>
                        {showReport ? (
                            <ReportView
                                rows={rows}
                                page={page ? page : 1}
                                pagesCount={pagesCount ? pagesCount : 1}
                                setValue={(name, val) => this.setState({ [name]: val })}
                                handleShowMore={this.handleShowMore}
                                pluginData={selectedPlugin}
                                downloadReport={this.downloadReport}
                                detailsEnabled={detailsEnabled}
                            />
                        ) : (
                            <div />
                        )}
                        {selectedRow && detailsEnabled && (
                            <RowDetails
                                selectedRow={selectedRow}
                                selectedPlugin={selectedPlugin}
                                setValue={(name, val) => this.setState({ [name]: val })}
                                downloadCardReport={this.downloadCardReport}
                            />
                        )}
                    </EventsLayout>
                </LayoutMain>
            </LayoutPage>
        );
    }
}

class ReportsWrapper extends React.Component {
    render() {
        return (
            <GeneralContextConsumer>
                {(generalInfo) => React.createElement(Reports, { ...this.props, generalInfo })}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(withCultureContext(ReportsWrapper)));

import React, { useCallback } from "react";
import { Dropdown } from "headpoint-react-components";

export default function AutoHideDropdown({ children }) {
    const CreateDropdown = useCallback((child) => {
        return (
            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                {child}
            </Dropdown>
        );
    }, []);

    if (children instanceof Array) {
        const components = children.filter((component) => component);

        if (components.length === 0) {
            return null;
        }

        return CreateDropdown(components);
    }

    if (!children) {
        return null;
    }

    return CreateDropdown(children);
}

import ModalStepDefault from "./Modals/UserCreateModals/ModalStepDefault";
import ModalStepRoles from "./Modals/UserCreateModals/ModalStepRoles";
import ModalStepLocations from "./Modals/commonSteps/ModalStepLocations";

export const wizardSteps = [
    { name: "Общее", component: ModalStepDefault },
    { name: "Роль", component: ModalStepRoles },
    { name: "Локации", component: ModalStepLocations },
];

export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

import React, { Component } from "react";
import { AuthLogo, AuthFooterLogo } from "../logo";
import { TextField, Button } from "headpoint-react-components";
import LoginAboutModal from "../about/LoginAboutModal";
import { SignIn } from "../../../services/authentication";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
    LoginLayout,
    LoginLayoutContainer,
    LoginLayoutLogoWrapper,
    LoginForm,
    LoginFormItem,
    LoginLayoutFooter,
    LoginLayoutFooterLinks,
    LoginLayoutFooterLogoWrapper,
} from "../../../layout/LoginLayout";

import { withSnackbar } from "notistack";
import LicenseAgreementModal from "../licenseAgreement/LicenseAgreementModal";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class LoginPage extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
            showModal: false,
            showLicense: false,
        };
    }

    handleChange = (e) => {
        const { name, value } = e.rawEvent.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (licenseAgreement) => {
        const { strings } = this.props;

        const result = await SignIn(this.state.login, this.state.password, licenseAgreement);
        if (!result) {
            this.props.enqueueSnackbar(strings("Ошибка доступа"), { variant: "error" });
            return;
        }

        if (result.status === 451) {
            this.setState({ showLicense: true });
            return;
        }

        if (!result.token) {
            this.props.enqueueSnackbar(strings("Неправильный логин или пароль"), { variant: "error" });
            return;
        }

        window.location.href = "/";
    };

    CloseAbout = () => this.setState({ showModal: false });
    ShowAbout = () => this.setState({ showModal: true });

    render() {
        const { strings, setCulture, code } = this.props;

        return (
            <div className="wrappper">
                <LoginLayout>
                    <LoginLayoutContainer>
                        <LoginLayoutLogoWrapper>
                            <AuthLogo />
                        </LoginLayoutLogoWrapper>

                        <LoginForm>
                            <LoginFormItem>
                                <TextField
                                    id="login"
                                    name="login"
                                    autoComplete={false}
                                    autoFocus={false}
                                    error=""
                                    onChange={this.handleChange}
                                    placeholder={strings("Имя пользователя")}
                                    required
                                    type="text"
                                    value={this.state.login}
                                    fullWidth
                                />
                            </LoginFormItem>

                            <LoginFormItem>
                                <TextField
                                    id="password"
                                    name="password"
                                    autoComplete={false}
                                    autoFocus={false}
                                    error=""
                                    onChange={this.handleChange}
                                    placeholder={strings("Пароль")}
                                    required
                                    type="password"
                                    withPassToggle
                                    value={this.state.password}
                                    fullWidth
                                />
                            </LoginFormItem>

                            <LoginFormItem>
                                <Button
                                    label={strings("Войти")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.handleSubmit();
                                    }}
                                    type="submit"
                                    variant="primary"
                                    fullWidth
                                />
                            </LoginFormItem>
                        </LoginForm>
                    </LoginLayoutContainer>

                    <LoginLayoutFooter>
                        <LoginLayoutFooterLinks>
                            <Button variant="ghost" label={strings("О платформе")} noPadding onClick={this.ShowAbout} />
                        </LoginLayoutFooterLinks>
                        <LoginLayoutFooterLogoWrapper>
                            <AuthFooterLogo />
                        </LoginLayoutFooterLogoWrapper>
                        <LoginLayoutFooterLinks textAlign="right">
                            <Button
                                variant="ghost"
                                onClick={() => setCulture(code === "ru" ? "en" : "ru")}
                                noPadding
                                label={code === "ru" ? "Switch to english" : "Переключить на русский"}
                            />
                        </LoginLayoutFooterLinks>
                    </LoginLayoutFooter>
                </LoginLayout>

                {this.state.showModal ? <LoginAboutModal Close={this.CloseAbout} strings={strings} /> : null}
                {this.state.showLicense && (
                    <LicenseAgreementModal
                        Close={() => {
                            this.setState({ showLicense: false });
                            this.props.enqueueSnackbar(
                                strings("Для работы с системой примите условия лицензионного соглашения"),
                                {
                                    variant: "error",
                                }
                            );
                        }}
                        Ok={() => {
                            this.setState({ showLicense: false });
                            this.handleSubmit(true);
                        }}
                        strings={strings}
                    />
                )}
            </div>
        );
    }
}

export default withCultureContext(withSnackbar(withRouter(LoginPage)));

import React from "react";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTitle,
    DetailsBoxTabs,
} from "../../../../components/DetailsBox";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import DetailsTabDevices from "./DetailsTabDevices";
import { Button, Text, TabPanel, Tab, Dropdown, DropdownItem, DropdownDivider } from "headpoint-react-components";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import DetailsTabMain from "./DetailsTabMain";

class DeviceTagsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
        };
    }

    render() {
        const { setSelected, tag, deleteHandler, editHandler, strings } = this.props;
        const { detailsTab } = this.state;

        const tabSets = [
            {
                title: "Общее",
                key: "main",
                component: DetailsTabMain,
            },
            {
                title: "Оборудование",
                key: "devices",
                component: DetailsTabDevices,
            },
        ];

        const tabSet = tabSets.find((s) => s.key === detailsTab) ?? tabSets.find((s) => s.key === "main");
        const { key: actualDetailsTab, component: actualDetailsComponent } = tabSet;

        return (
            <EventsLayoutSecondary>
                <DetailsBox>
                    <DetailsBoxHeader sticky>
                        <DetailsBoxTitle>
                            <Text color="primary" variant="title" component="h2">
                                {tag.name}
                            </Text>
                        </DetailsBoxTitle>
                        <DetailsBoxHeaderActions>
                            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                <DropdownItem onClick={() => editHandler(tag)} as="button">
                                    {strings("Редактировать")}
                                </DropdownItem>
                                <DropdownDivider />
                                <DropdownItem onClick={() => deleteHandler(tag)} as="button" colorVariant="danger">
                                    {strings("Удалить")}
                                </DropdownItem>
                            </Dropdown>
                            <Button icon="close" variant="secondary" onClick={() => setSelected(null)} />
                        </DetailsBoxHeaderActions>
                    </DetailsBoxHeader>
                    <DetailsBoxTabs>
                        <TabPanel>
                            {tag &&
                                tabSets.map((tab) => (
                                    <Tab
                                        key={`${tab.key}`}
                                        label={strings(tab.title)}
                                        isActive={actualDetailsTab === tab.key}
                                        onClick={() => this.setState({ detailsTab: tab.key })}
                                    />
                                ))}
                        </TabPanel>
                    </DetailsBoxTabs>
                    {actualDetailsComponent && React.createElement(actualDetailsComponent, { ...this.props })}
                </DetailsBox>
            </EventsLayoutSecondary>
        );
    }
}

export default withCultureContext(DeviceTagsDetails);

import React from "react";
import { Button, Text, Tab, TabPanel, Dropdown, DropdownItem, DropdownDivider } from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
} from "../../../../components/DetailsBox";
import { DetailsTabMain } from "./DetailsTabMain";
import DetailsTabAccesability from "./DetailsTabAccesability";
import { withSnackbar } from "notistack";
import { isStringEmpty } from "../../../../../utilites/TextUtils";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

class UserDetails extends React.Component {
    getFullName(user) {
        const userProps = user.properties;

        let fullName = [];

        if (userProps?.surname?.trim()) {
            fullName.push(userProps.surname.trim());
        }

        if (userProps?.name?.trim()) {
            fullName.push(userProps.name.trim());
        }

        if (userProps?.patronymic?.trim()) {
            fullName.push(userProps.patronymic.trim());
        }

        return fullName.join(" ");
    }

    getFullNameOrLogin(user) {
        const fullName = this.getFullName(user);

        if (isStringEmpty(fullName)) {
            return user.login;
        }

        return fullName;
    }

    render() {
        const {
            selectedUser,
            detailsTab,
            availableRoles,
            usersGroups,
            updateHandler,
            deleteHandler,
            roleGeneralPermissions,
            roleDeviceTypesPermissions,
            roleDGPermissions,
        } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {this.getFullNameOrLogin(selectedUser)}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                        <DropdownItem as="button" onClick={() => updateHandler(selectedUser)}>
                                            {strings("Редактировать")}
                                        </DropdownItem>
                                        <DropdownDivider />
                                        <DropdownItem
                                            as="button"
                                            colorVariant="danger"
                                            onClick={() => deleteHandler(selectedUser)}
                                        >
                                            {strings("Удалить")}
                                        </DropdownItem>
                                    </Dropdown>
                                    <Button
                                        icon="close"
                                        variant="secondary"
                                        onClick={() => this.props.setSelectedUser()}
                                    />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    <Tab
                                        label={strings("Общее")}
                                        isActive={detailsTab === "main"}
                                        onClick={() => this.props.setDetailsTab("main")}
                                    />
                                    <Tab
                                        label={strings("Доступность")}
                                        isActive={detailsTab === "accesability"}
                                        onClick={() => this.props.setDetailsTab("accesability")}
                                    />
                                </TabPanel>
                            </DetailsBoxTabs>

                            {detailsTab === "main" && (
                                <DetailsTabMain
                                    selectedUser={selectedUser}
                                    availableRoles={availableRoles}
                                    usersGroups={usersGroups}
                                />
                            )}

                            {detailsTab === "accesability" && (
                                <DetailsTabAccesability
                                    selectedUser={selectedUser}
                                    availableRoles={availableRoles}
                                    roleGeneralPermissions={roleGeneralPermissions}
                                    roleDeviceTypesPermissions={roleDeviceTypesPermissions}
                                    roleDGPermissions={roleDGPermissions}
                                    usersGroups={usersGroups}
                                />
                            )}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(UserDetails);

import React, { createRef } from "react";
import moment from "moment/moment";
import ArchivePlayer from "../../../archive/components/ArchivePlayer";
import { AspectBox } from "../../../components/AspectBox";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { GeneralContextConsumer, getAdapters, getDeviceTypes, getSystemVariableValue } from "../../../../contexts";
import { GetCameraLacunas } from "../../../../services/archive";
import {
    Button,
    DeviceIcon,
    ExpandableSearch,
    Pagination,
    Row,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Text,
} from "headpoint-react-components";
import styled from "styled-components";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { EventsLayoutHeaderTitle, EventsLayoutPaginationWrapper } from "../../../../layout/EventsLayout";
import LocationDeviceInfoTooltip from "../../../components/DetailsTableTree/LocationDeviceInfoTooltip";
import { GetMatchedDeviceIds } from "../../../../services/controlZones";
import { getDeviceTypesByFeature, withGeneralContext } from "../../../../contexts/GeneralContext";
import { SearchDevices } from "../../../../services/devices";
import { GetLocationNames } from "../../../../services/locations";
import { throttle } from "throttle-debounce";

class DetailsTabArchive extends React.Component {
    constructor(props) {
        super(props);
        this.archivePlayerRef = createRef();
        const defaultMessage = { text: props.strings("Загрузка архивной трансляции..."), textColor: "white" };

        this.state = {
            isLoading: true,
            resultMessage: defaultMessage,
            defaultMessage: defaultMessage,
            endOrNotFoundMessage: { text: props.strings("Отсутствует архивная запись"), textColor: "white" },
            search: "",
            searchList: [],
            devices: [],
            deviceIds: [],
            pageNumber: 1,
            pagesCount: 1,
            offset: 1,
            countOnPage: 10,
        };

        this.throttleSearchDevice = throttle(700, async (...args) => void this.searchDevice(...args), {
            noLeading: false,
            noTrailing: false,
        });
    }

    async componentDidMount() {
        await this.checkArchiveRangesForEvent();
        await this.getDevices();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedEvent !== prevProps.selectedEvent) {
            this.setState({ isLoading: true, resultMessage: this.state.defaultMessage });
            await this.checkArchiveRangesForEvent();
        }

        if (
            prevState.pageNumber !== this.state.pageNumber ||
            prevState.countOnPage !== this.state.countOnPage ||
            prevState.search !== this.state.search
        ) {
            if (this.state.search.length > 0) {
                this.throttleSearchDevice(this.state.search);
            } else {
                await this.getDevices();
            }
        }
    }

    getDevices = async () => {
        const { pageNumber, countOnPage } = this.state;
        const { strings, generalInfo, selectedEvent } = this.props;

        const adapters = getAdapters(generalInfo);
        const deviceTypes = getDeviceTypesByFeature(adapters, ["video.archive"]);

        const [getDeviceIdsStatus, data, deviceIdsCount] = await GetMatchedDeviceIds(selectedEvent.deviceId);
        if (!getDeviceIdsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств зоны контроля"), { variant: "error" });
            return;
        }

        if (data?.deviceIds?.length > 0) {
            const [devicesStatus, devices] = await SearchDevices({
                ids: data.deviceIds.slice(
                    (pageNumber - 1) * countOnPage,
                    Math.min((pageNumber - 1) * countOnPage + countOnPage, data.deviceIds.length)
                ),
                deviceTypes,
            });
            if (!devicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            const [locationStatus, locations] = await GetLocationNames(devices.map((d) => d.locationId));
            if (!locationStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            }

            devices.forEach((device) => {
                device.location = locations.get(device.locationId);
            });

            const device = devices.find((d) => d.id === selectedEvent.deviceId);

            devices.sort((x, y) => (x === device ? -1 : y === device ? 1 : 0));

            const deviceIds = data.deviceIds.sort((x, y) =>
                x === selectedEvent.deviceId ? -1 : y === selectedEvent.deviceId ? 1 : 0
            );

            this.setState({ deviceIds, devices });
        }

        const pagesCount = Math.ceil(deviceIdsCount / countOnPage);
        const offset = Math.min(pageNumber > 3 ? pageNumber - 3 : 0, deviceIdsCount);

        this.setState({ pagesCount, offset });
    };

    searchDevice = async (like) => {
        const { countOnPage, deviceIds, pageNumber } = this.state;
        const { strings, generalInfo } = this.props;

        console.log(like);

        const adapters = getAdapters(generalInfo);
        const deviceTypes = getDeviceTypesByFeature(adapters, ["video.archive"]);

        let filter = { like, ids: deviceIds, deviceTypes };

        const [devicesStatus, devices] = await SearchDevices(filter);
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [locationStatus, locations] = await GetLocationNames(devices.map((d) => d.locationId));
        if (!locationStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        devices.forEach((device) => {
            device.location = locations.get(device.locationId);
        });

        const pagesCount = Math.ceil(devices.length / countOnPage);
        const offset = Math.min(pageNumber > 3 ? pageNumber - 3 : 0, devices.length);

        this.setState({ search: like, searchList: devices, pagesCount, offset });
    };

    handlePage = (page) => {
        if (page <= this.state.pagesCount) {
            this.setState({ pageNumber: page });
        }
    };

    handleShowMore = () => {
        this.setState({ pageNumber: 1, countOnPage: this.state.countOnPage + 10 });
    };

    async checkArchiveRangesForEvent() {
        let lacunas = await this.getArchiveLacunas();
        if (!this.checkLacunas(lacunas)) {
            this.setState({ isLoading: true, resultMessage: this.state.endOrNotFoundMessage });
        } else {
            this.setState({ isLoading: false });
        }
    }

    async getArchiveLacunas() {
        const camera = this.props.device;
        const { strings } = this.props;
        const serviceCode = getDeviceTypes(this.props.generalInfo)?.find(
            (dt) => dt?.value === camera.typeId
        )?.serviceCode;

        if (!serviceCode) {
            this.props.enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), { variant: "error" });
            return;
        }

        let [getLacunasStatus, result, lacunaResponseStatus] = await GetCameraLacunas(serviceCode, camera.id);

        if (!getLacunasStatus) {
            switch (lacunaResponseStatus) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для получения частей архивной записи"), {
                        variant: "warning",
                    });
                    break;
                case 404: // notfound
                    this.props.enqueueSnackbar(strings("Отсутствует архивная запись"), { variant: "warning" });
                    break;
                default:
                    this.props.enqueueSnackbar(strings("Не удалось получить части архивной записи"), {
                        variant: "error",
                    });
                    break;
            }

            return [{ from: -1, to: -1 }];
        }

        return result?.lacunas;
    }

    checkLacunas(lacunas) {
        const { selectedEvent } = this.props;

        const eventTimeLeftBorder = this.getEventLeftBorder(selectedEvent);

        if (!lacunas || !Array.isArray(lacunas)) {
            return false;
        }

        return lacunas?.some((l) => l?.from <= eventTimeLeftBorder && l?.to >= eventTimeLeftBorder);
    }

    getCurrentFrame() {
        const { selectedEvent, device } = this.props;

        return {
            ...device,
            ranges: {
                from: this.getEventLeftBorder(selectedEvent),
                to: moment().unix(),
            },
        };
    }

    getEventLeftBorder(selectedEvent) {
        const eventDate = new Date(selectedEvent.eventDate);
        const archiveDelta = getSystemVariableValue(
            this.props.generalInfo,
            "service.api.events",
            "events.archive.delta",
            5
        );

        const fromDate = new Date(eventDate.getTime());

        fromDate.setSeconds(fromDate.getSeconds() - archiveDelta);

        return moment(fromDate).unix();
    }

    render() {
        const { isLoading, resultMessage, search, searchList, devices, offset, pageNumber, pagesCount } = this.state;
        const { strings } = this.props;

        const sources = search ? searchList : devices;

        const currentFrame = this.getCurrentFrame();
        const clientProtocol = currentFrame?.properties?.settings?.clientProtocol;
        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <DetailsBoxContent>
                        <>
                            {isLoading ? (
                                <AspectBox aspect="16:9">
                                    <StyledTabLoader>
                                        <Text color={resultMessage.textColor}>{resultMessage.text}</Text>
                                    </StyledTabLoader>
                                </AspectBox>
                            ) : (
                                <AspectBox aspect="16:9">
                                    <ArchivePlayer
                                        clientProtocol={clientProtocol}
                                        currentFrame={currentFrame}
                                        frameIndex={0}
                                        key={`${this.props.selectedEvent.id}`}
                                        generalInfo={generalInfo}
                                        ref={this.archivePlayerRef}
                                    />
                                </AspectBox>
                            )}
                        </>
                        <Row marginTop={24} marginBottom={24} justify="between" align="middle">
                            <EventsLayoutHeaderTitle>
                                <Text color="primary" variant="body">
                                    {strings("Камеры в зоне контроля")}
                                </Text>
                                <ExpandableSearch
                                    value={search}
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                    onSubmit={() => {}}
                                    placeholder={strings("Найти")}
                                    colorVariant="light"
                                    inHeader
                                />
                            </EventsLayoutHeaderTitle>
                        </Row>
                        <TableWrapper>
                            <Table hasHover stickyHeader isFixed bgColor="dark">
                                <TableBody>
                                    {sources?.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <LocationDeviceInfoTooltip device={row}>
                                                    <Text variant="body-sm">
                                                        <DeviceIcon icon={"camera"} size={16} />
                                                    </Text>
                                                    {"\u00A0\u00A0"}
                                                    <Text color="primary" variant="body-sm">
                                                        {row.name ?? "..."}
                                                    </Text>
                                                </LocationDeviceInfoTooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {row.location ?? "..."}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableWrapper>
                        <EventsLayoutPaginationWrapper>
                            <Button variant="secondary" label={strings("Показать ещё")} onClick={this.handleShowMore} />
                            <Pagination
                                offset={offset}
                                page={pageNumber}
                                count={pagesCount}
                                onChange={(e) => this.handlePage(e)}
                            />
                        </EventsLayoutPaginationWrapper>
                    </DetailsBoxContent>
                )}
            </GeneralContextConsumer>
        );
    }
}

const StyledTabLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: black;
    . {
        vertical-align: middle;
    }
`;

const TableWrapper = styled.div`
    height: 200px;
    overflow-y: scroll;
`;

export default withCultureContext(withGeneralContext(DetailsTabArchive));

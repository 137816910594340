import moment from "moment";
export const PLUGINS = "/reports-api/plugins";
export const REPORT = "/reports-api/data";
export const REPORT_COUNT = "/reports-api/dataTotal";

const AVAILABILITY_RULES = "/devices-availability-api/rules";
const QUALITY_RULES = "/rtsp-quality-api/rules";
const DEVICE_GROUPS = "/devices-api/deviceGroup";

export const GetReportFileUrl = (serviceCode) => {
    if (serviceCode) {
        return `/general-api/service/${serviceCode}/reports/dataFile`;
    }
    return "/reports-api/dataFile";
};

export const GetPlugins = async () => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(PLUGINS, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, await response.json()];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetReport = async (id, serviceCode, filters, paginationFilter) => {
    let url = REPORT;

    if (serviceCode) {
        url = `/general-api/service/${serviceCode}/reports/data`;
    }

    return await GetData(url, id, filters, paginationFilter);
};

export const GetReportCount = async (id, serviceCode, filters, paginationFilter) => {
    let url = REPORT_COUNT;

    if (serviceCode) {
        url = `/general-api/service/${serviceCode}/reports/dataTotal`;
    }

    return await GetData(url, id, filters, paginationFilter);
};

export const GetDetails = async (id, serviceCode, item, dates) => {
    if (!id || !serviceCode || !item) {
        return [false, []];
    }

    let datesQuery =
        (dates?.from ? `&from=${encodeURIComponent(moment(dates.from).format())}` : ``) +
        (dates?.to ? `&to=${encodeURIComponent(moment(dates.to).format())}` : ``);

    const url = `/general-api/service/${serviceCode}/reports/details`;
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${url}?id=${id}&item=${item}` + datesQuery, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, await response.json()];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetChart = async (id, serviceCode, item, dates, sectionType) => {
    if (!id || !serviceCode || !item || !sectionType) {
        return [false, []];
    }

    let datesQuery =
        (dates?.from ? `&from=${encodeURIComponent(moment(dates.from).format())}` : ``) +
        (dates?.to ? `&to=${encodeURIComponent(moment(dates.to).format())}` : ``);

    const url = `/general-api/service/${serviceCode}/reports/chart`;
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${url}?id=${id}&item=${item}&chartTypeId=${sectionType}` + datesQuery, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, await response.json()];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetBoundaries = async (id, serviceCode, item) => {
    if (!id || !serviceCode || !item) {
        return [false, null];
    }

    const url = `/general-api/service/${serviceCode}/reports/boundaries`;
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${url}?id=${id}&item=${item}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        return [true, await response.json()];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const DownloadServiceReport = async (id, serviceCode, type, filters, paginationFilter, dates) => {
    const token = window.localStorage.getItem("token");
    const url = `/general-api/service/${serviceCode}/reports/download/${type}`;

    let filtersQuery = "";
    let pagination = `${serializeObjToQuery(paginationFilter, "paginationFilter", null)}`;

    filters.forEach((f) => (filtersQuery += `&${serializeObjToQuery(f, "filters", filters.indexOf(f))}`));

    let finalUrl = `${url}?id=${id}&${filtersQuery}&${pagination}`;
    if (dates !== undefined) {
        finalUrl += `&from=${dates.from}&to=${dates.to}`;
    }

    const response = await fetch(finalUrl, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/xlsx",
        },
    });

    if (response.status === 200) {
        const blob = await response.blob()

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "report.xlsx";
        link.click();
        URL.revokeObjectURL(url);
    }

    return response.status
};

export const DownloadServiceCardReport = async (id, serviceCode, type, filters, dates) => {
    const token = window.localStorage.getItem("token");
    const url = `/general-api/service/${serviceCode}/reports/cardDownload/${type}`;

    let filtersQuery = "";

    filters.forEach((f) => (filtersQuery += `&${serializeObjToQuery(f, "filters", filters.indexOf(f))}`));

    let finalUrl = `${url}?id=${id}&${filtersQuery}`;

    if (dates !== undefined) {
        finalUrl += `&from=${moment(dates.from).toISOString()}&to=${moment(dates.to).toISOString()}`;
    }

    const response = await fetch(finalUrl, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/xlsx",
        },
    })

    if (response.status === 200) {
        const blob = await response.blob()

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "cardReport.xlsx";
        link.click();
        URL.revokeObjectURL(url);
    }

    return response.status
};


const GetData = async (url, id, filters, paginationFilter) => {
    const token = window.localStorage.getItem("token");
    let filtersQuery = "";
    let pagination = `${serializeObjToQuery(paginationFilter, "paginationFilter", null)}`;

    filters.forEach((f) => (filtersQuery += `&${serializeObjToQuery(f, "filters", filters.indexOf(f))}`));
    try {
        let response = await fetch(`${url}?id=${id}&token=${token}&${filtersQuery}&${pagination}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, await response.json()];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const serializeObjToQuery = function (obj, objName, objIndex) {
    let str = [];
    objName = objIndex !== null ? `filters[${objIndex}]` : objName;
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(`${objName}.${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
    }

    if (str.length === 1) {
        return str[0];
    }

    return str.join("&");
};

export const GetAvailabilityRules = async () => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${AVAILABILITY_RULES}/list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetQualityRules = async () => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${QUALITY_RULES}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

const CompareGroups = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const GetDevicesGroups = async () => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${DEVICE_GROUPS}/list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();
        return [true, res?.items?.sort(CompareGroups)];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

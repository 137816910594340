const pathScenarios = "/scenarios-api/scenarios";

export const CompareNodes = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const CreateTemplate = async (template) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${pathScenarios}/templates`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(template),
        });

        if (response.status !== 200) {
            return [false, response.status];
        }

        let res = await response.json();

        return [response.status === 200, response.status, res?.data?.id];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const CreateScenario = async (scenario) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${pathScenarios}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(scenario),
        });

        if (response.status !== 200) {
            return [false, response.status];
        }

        let res = await response.json();

        return [response.status === 200, response.status, res?.data?.id];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const DeleteTemplate = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathScenarios}/templates/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response.status === 200, response.status];
};

export const DeleteScenario = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathScenarios}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response.status === 200, response.status];
};

export const GetScenarios = async (params) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(
            `${pathScenarios}?` +
                new URLSearchParams({
                    skip: params?.skip ?? "",
                    limit: params?.limit ?? "",
                }),
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status !== 200) {
            console.log(response);
            return [false, []];
        }

        return [true, (await response.json()) ?? []];
    } catch (ex) {
        console.error(ex);
        return [];
    }
};

export const GetFunctions = async () => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathScenarios}/online`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            console.log(response);
            return [false, []];
        }

        return [true, (await response.json()) ?? []];
    } catch (ex) {
        console.error(ex);
        return [];
    }
};

export const UpdateScenario = async (scenario) => {
    let id = scenario.id;
    let data = { ...scenario };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathScenarios}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.status === 200, response.status];
};

export const UpdateTemplate = async (template) => {
    let id = template.id;
    let data = { ...template };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathScenarios}/templates/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.status === 200, response.status];
};

export const GetTemplates = async (params) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(
            `${pathScenarios}/templates?` +
                new URLSearchParams({
                    skip: params?.skip ?? "",
                    limit: params?.limit ?? "",
                }),
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status !== 200) {
            console.log(response);
            return [false, []];
        }

        return [true, (await response.json()) ?? []];
    } catch (ex) {
        console.error(ex);
        return [];
    }
};

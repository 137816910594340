const ALL_CONTROL_ZONES_GROUPS = "/control-zones-api/groups/list";
const CONTROL_ZONES_GROUPS = "/control-zones-api/groups";
const CONTROL_ZONES_DEVICES = "/control-zones-api/devices";
const CONTROL_ZONES = "/control-zones-api/zones";

export const CreateControlZonesGroup = async (group) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(CONTROL_ZONES_GROUPS, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(group),
        });

        if (response.status !== 200) {
            return [response.status];
        }

        let responseBody = await response.json();
        return [response.status, responseBody];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const EditControlZonesGroup = async (group) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${CONTROL_ZONES_GROUPS}/${group.id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(group),
        });

        if (response.status !== 200) {
            return [response.status];
        }

        let responseBody = await response.json();
        return [response.status, responseBody];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const CreateControlZone = async (zone) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(CONTROL_ZONES, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(zone),
        });

        if (response.status !== 200) {
            return [response.status];
        }

        let responseBody = await response.json();
        return [response.status, responseBody];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const EditControlZone = async (zone) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${CONTROL_ZONES}/${zone.id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(zone),
        });

        if (response.status !== 200) {
            return [response.status];
        }

        let responseBody = await response.json();
        return [response.status, responseBody];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const AddDeviceToControlZones = async (deviceId, zonesIds) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${CONTROL_ZONES}/device/${deviceId}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ deviceId, controlZoneIds: zonesIds }),
        });

        if (response.status !== 200) {
            return [response.status];
        }

        return [response.status];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const UpdateDeviceToControlZones = async (deviceId, zonesIds) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${CONTROL_ZONES}/device/${deviceId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ deviceId, controlZoneIds: zonesIds }),
        });

        if (response.status !== 200) {
            return [response.status];
        }

        return [response.status];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const GetDeviceIds = async (zoneId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${CONTROL_ZONES_DEVICES}/list/${zoneId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data, responseBody.count];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetZonesIdsByDevice = async (deviceId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${CONTROL_ZONES}/zonesIds/${deviceId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetMatchedDeviceIds = async (deviceId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${CONTROL_ZONES_DEVICES}/matched/${deviceId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data, responseBody.count];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetControlZones = async (id) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${CONTROL_ZONES}/list/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status, []];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data, responseBody.count];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetAllControlZonesGroups = async () => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(ALL_CONTROL_ZONES_GROUPS, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status, []];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetControlZonesGroups = async (filter) => {
    const token = window.localStorage.getItem("token");

    const queryParams = new URLSearchParams({
        countOnPage: filter?.countOnPage,
        page: filter?.page,
        like: filter?.like,
    });

    let response = await fetch(`${CONTROL_ZONES_GROUPS}?${queryParams}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status, []];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data, responseBody.count];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetGroupsByZones = async (filter) => {
    const token = window.localStorage.getItem("token");

    const queryParams = new URLSearchParams({
        zonesIds: filter?.zonesIds,
    });

    let response = await fetch(`${CONTROL_ZONES_GROUPS}/by-zones?${queryParams}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status, []];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetDevicesByZones = async (filter) => {
    const token = window.localStorage.getItem("token");

    const queryParams = new URLSearchParams();

    filter.zonesIds.forEach((id) => queryParams.append("controlZoneIds", id));

    let response = await fetch(`${CONTROL_ZONES_DEVICES}/list?${queryParams}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status, []];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetAllControlZones = async () => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${CONTROL_ZONES}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const SearchControlZones = async (search) => {
    const token = window.localStorage.getItem("token");

    const queryParams = new URLSearchParams({
        search,
    });

    let response = await fetch(`${CONTROL_ZONES}/search?${queryParams}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const SearchControlZonesList = async (filter) => {
    const token = window.localStorage.getItem("token");

    const queryParams = new URLSearchParams();
    filter?.countOnPage && queryParams.append("countOnPage", filter.countOnPage);
    filter?.page && queryParams.append("page", filter.page);
    filter?.like && queryParams.append("like", filter.like);
    filter?.deviceIds && queryParams.append("deviceIds", filter.deviceIds);

    let response = await fetch(`${CONTROL_ZONES}/list?${queryParams}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [response.status];
    }

    try {
        let responseBody = await response.json();
        return [response.status, responseBody.data, responseBody.count];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const DeleteControlZone = async (zone) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${CONTROL_ZONES}/${zone.id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return response.status;
    } catch (ex) {
        console.error(ex);
        return -1;
    }
};

export const DeleteControlZonesGroup = async (group) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${CONTROL_ZONES_GROUPS}/${group.id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return response.status;
    } catch (ex) {
        console.error(ex);
        return -1;
    }
};

export const fillZoneGroups = (zoneGroups, zones) => {
    return zoneGroups.map((g) => {
        let node = { ...g, tag: "zoneGroup", isClosed: true };
        node.children = zones
            ?.filter((z) => z.groupId === g.id)
            .sort(compareZones)
            .map((z) => ({ ...z, tag: "zone" }));
        return node;
    });
};

export const fillNode = (node, zones) => {
    node.children = zones
        ?.filter((z) => z.groupId === node.id)
        .map((z) => ({ ...z, tag: "zone" }))
        ?.sort(compareZones);
};

const compareZones = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

import React from "react";
import { CreateUpdateWizard } from "../../../../components/CreateUpdateWizard/CreateUpdateWizard";
import PubSub from "pubsub-js";
import { GROUP_CREATED_TOPIC, GROUP_EDITED_TOPIC, SHOW_CREATE_GROUP_MODAL } from "../devicesGroupsTopics";
import { CreateDevicesGroup, UpdateDevicesGroup } from "../../../../../services/devicesGroups";
import ModalStepDevices from "./ModalStepDevices";
import { LoaderAnimation } from "../../../../components/LoaderAnimation/LoaderAnimation";

export class DevicesGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.steps = [];
        this.title = "";
        this.state = {
            activeStep: 1,
            values: { ...props?.values },
            isLoading: false,
        };
    }

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    createHandler = async (values) => {
        const { strings } = this.props;

        if (!values?.name || values?.name?.trim() === "") {
            this.props.enqueueSnackbar(strings("Нельзя создать группу оборудования без имени"), { variant: "error" });
            return;
        }

        this.setState({ isLoading: true });

        try {
            let [status, statusCode, newGroupId] = await CreateDevicesGroup(values);
            if (status) {
                this.props.enqueueSnackbar(`${strings("Группа оборудования")} '${values.name}' ${strings("создана")}`, {
                    variant: "success",
                });

                this.setState({ isLoading: false });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(strings("Не хватает прав для создания группы оборудоваения"), {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("Группы оборудования должны иметь уникальные имена"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(strings("Не удалось создать группу оборудования"), {
                            variant: "error",
                        });
                        break;
                }

                this.setState({
                    activeStep: 1,
                    isLoading: false,
                });
                return;
            }

            let newGroup = { ...values, id: newGroupId, count: values.devices?.length ?? 0 };
            PubSub.publish(GROUP_CREATED_TOPIC, newGroup);
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    editHandler = async () => {
        const { strings } = this.props;

        let { values } = this.state;
        if (!values.name) {
            this.props.enqueueSnackbar(strings("Нельзя оставить группу оборудования без имени"), { variant: "error" });
            return;
        }

        this.setState({ isLoading: true });

        try {
            let [status, statusCode] = await UpdateDevicesGroup(values);
            if (status) {
                this.props.enqueueSnackbar(
                    `${strings("Группа оборудования")} '${values.name}' ${strings("обновлена")}`,
                    { variant: "success" }
                );

                this.setState({ isLoading: false });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(strings("Не хватает прав для изменения группы оборудоваения"), {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("Группы оборудования должны иметь уникальные имена"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(strings("Не удалось сохранить группу оборудования"), {
                            variant: "error",
                        });
                        break;
                }

                this.setState({
                    activeStep: 1,
                    isLoading: false,
                });
                return;
            }

            let updatedGroup = { ...values, count: values.devices?.length ?? 0 };
            PubSub.publish(GROUP_EDITED_TOPIC, updatedGroup);
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    render() {
        const [{ mode, strings }, { values, step, activeStep, isLoading }] = [this.props, this.state];
        return (
            <>
                {isLoading && <LoaderAnimation message={strings("Применение настроек")} />}
                <CreateUpdateWizard
                    size={this.steps[activeStep - 1]?.component === ModalStepDevices ? "lg" : null}
                    visible={true}
                    title={this.title}
                    values={values}
                    steps={this.steps}
                    step={step}
                    activeStep={activeStep}
                    NextStep={this.nextStep}
                    SetActiveStep={this.selectStep}
                    SetValuesProperty={(name, value) => this.setState({ values: { ...values, [name]: value } })}
                    CreateHandler={() => this.createHandler(values)}
                    EditHandler={this.editHandler}
                    CloseHandler={() => PubSub.publish(SHOW_CREATE_GROUP_MODAL, undefined)}
                    Mode={mode}
                />
            </>
        );
    }
}

import ModalStepDefault from "./Modals/ModalStepDefault";
import ModalStepIntervals from "./Modals/ModalStepIntervals";

export const FIXED_FILTER_TYPE = "fixed";
export const DYNAMIC_FILTER_TYPE = "dynamic";
export const PERSONAL_SCHEDULE_TYPE = "personal";
export const GROUP_SCHEDULE_TYPE = "group";
export const SYSTEM_SCHEDULE_TYPE = "system";

export const wizardScheduleDefault = [
    { name: "Общее", component: ModalStepDefault },
    { name: "Интервалы выполнения", component: ModalStepIntervals },
];

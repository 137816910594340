import React from "react";
import styled from "styled-components";
import { withSnackbar } from "notistack";
import { getImageSize } from "../../../../../utilites/ImageUtils";
import { FileInput, TextField, ModalSection } from "headpoint-react-components";
import { CultureContextConsumer, withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

const ModalFormItem = styled.div`
    & + & {
        margin-top: 16px;
    }
`;

const FileInputComponent = React.memo(({ setFile }) => (
    <CultureContextConsumer>
        {({ strings }) => (
            <FileInput
                onChange={setFile}
                label={strings("Графическая подложка плана")}
                placeholder={strings("Выберите файл или перенесите его сюда")}
                fullWidth
                height={220}
                withPreview
                validTypes={["image/jpeg", "image/jpg", "image/png"]}
                errorLabels={{
                    typeError: strings("Неправильный формат файла"),
                    sizeError: strings("Файл слишком большой"),
                }}
                comment={strings("PNG или JPG, от 256×256 пикселей")}
            />
        )}
    </CultureContextConsumer>
));

class ModalStepDefault extends React.Component {
    setImage = async (files) => {
        let { setValues } = this.props;
        const file = files[0];
        if (file) {
            const size = await getImageSize(file);
            const image = {
                file,
                imageName: file.name,
                imageWidth: size.width,
                imageHeight: size.height,
            };

            setValues("image", image);
        }
    };

    render() {
        const { visible, values, setValues, strings } = this.props;
        if (!visible) {
            return null;
        }

        return (
            <ModalSection>
                <ModalFormItem>
                    <TextField
                        label={strings("Название")}
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={(e) => setValues("name", e.target.value)}
                        colorVariant="light"
                        fullWidth
                        error={!values.name ? strings("Поле не может быть пустым") : null}
                    />
                </ModalFormItem>
                <ModalFormItem>
                    <TextField
                        label={strings("Комментарий")}
                        name="comment"
                        type="text"
                        colorVariant="light"
                        fullWidth
                        inputProps={{
                            as: "textarea",
                            rows: 3,
                        }}
                        value={values.properties?.comment}
                        onChange={(e) => setValues("properties", { ...values.properties, comment: e.target.value })}
                    />
                </ModalFormItem>
                <ModalFormItem>
                    <FileInputComponent id="file_input" setFile={(files) => this.setImage(files)} />
                </ModalFormItem>
            </ModalSection>
        );
    }
}

export default withCultureContext(withSnackbar(ModalStepDefault));

import React, { useState } from "react";
import styled, { css } from "styled-components";

export const AccordionListGroup = ({ children, label, hasSelected, isSelected, preOpen = false }) => {
    const [isOpen, setOpen] = useState(preOpen);

    return (
        <AccordionListItem>
            <AccordionListItemToggle
                type="button"
                onClick={() => setOpen(!isOpen)}
                isOpen={isOpen}
                hasSelected={hasSelected}
                isSelected={isSelected}
            >
                {label}
            </AccordionListItemToggle>

            <AccordionListChildren isOpen={isOpen}>{children}</AccordionListChildren>
        </AccordionListItem>
    );
};

export const AccordionListWrapper = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const AccordionListItem = styled.li`
    list-style: none;
`;

const AccordionListChildren = styled.ul`
    list-style: none;
    padding: 0 0 0 12px;
    margin: 0;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: opacity 0.16s linear;

    ${({ isOpen }) =>
        isOpen &&
        css`
            max-height: 100%;
            opacity: 1;
        `}
`;

export const AccordionListChild = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 0 6px 18px;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    transition: opacity 0.16s linear, color 0.16s linear;

    ${({ hasSelected }) =>
        hasSelected &&
        css`
            opacity: 0.5;
        `};

    ${({ isSelected }) =>
        isSelected &&
        css`
            color: ${({ theme }) => theme.colors.white};
            opacity: 1;
        `}

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${({ theme }) => theme.colors.blue};
        `}

  & i {
        position: absolute;
        top: 6px;
        left: 0;
        z-index: 1;
    }
`;

const getStatusColor = (theme, status) => {
    switch (status) {
        case "active":
            return theme.colors.green;

        case "disabled":
            return theme.colors.red;

        default:
            return "";
    }
};

export const CameraStatusIndicator = styled.span`
    position: absolute;
    top: 11px;
    left: 5px;
    z-index: 1;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme, status }) => getStatusColor(theme, status)};
`;

const AccordionListItemToggle = styled.button`
    position: relative;
    display: inline-block;
    background: none;
    padding: 0 0 0 18px;
    margin: 6px 0;
    border: none;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    cursor: pointer;
    transition: opacity 0.16s linear, color 0.16s linear;

    ${({ hasSelected }) =>
        hasSelected &&
        css`
            opacity: 0.5;
        `};

    ${({ isSelected }) =>
        isSelected &&
        css`
            color: ${({ theme }) => theme.colors.white};
            opacity: 1;
        `}

    &:before {
        content: "${({ isOpen }) => (isOpen ? "\\e930" : "\\e931")}";
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

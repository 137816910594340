import React, { useMemo } from "react";
import { Text, ExpandableSearch, Dropdown, DropdownItem, DropdownDivider } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
} from "../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import ControlZonesTable from "./ControlZonesTable";
import { useDispatch, useSelector } from "react-redux";
import {
    saveControlZonesState,
    showCreateGroupWizard,
    showCreateZoneWizard,
} from "../../../../app/reducers/controlZonesReducer";
import ControlZonesCreateUpdateWizard from "./modals/ControlZonesCreateUpdateWizard";
import ControlZonesGroupDetails from "./details/ControlZonesGroupDetails";
import ControlZonesDetails from "./details/ControlZonesDetails";
import ControlZonesDeleteWizard from "./modals/ControlZonesDeleteWizard";
import { throttle } from "throttle-debounce";
import { permissionExists } from "../../../../contexts";

const ControlZones = ({ strings, enqueueSnackbar }) => {
    const dispatch = useDispatch();
    const { search, showCreateWizard, showDeleteWizard, selectedGroup, selectedZone } = useSelector(
        (state) => state.controlZones
    );
    const { userInfo } = useSelector((state) => state.persistedReducer);

    const setSearch = (search) => {
        dispatch(saveControlZonesState({ search, pageNumber: 1 }));
    };

    const throttleSetSearch = useMemo(
        () =>
            throttle(700, (search) => void setSearch(search), {
                noLeading: false,
                noTrailing: false,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const showCreateZoneModal = () => {
        dispatch(showCreateZoneWizard());
    };

    const showCreateZonesGroupModal = () => {
        dispatch(showCreateGroupWizard());
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <>
                    <EventsLayoutPrimary hasSecondary={false}>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings("Зоны контроля")}
                                </Text>

                                <ExpandableSearch
                                    value={search}
                                    onChange={(e) => throttleSetSearch(e.target.value)}
                                    onSubmit={() => {
                                        /* ignore enter */
                                    }}
                                    placeholder={strings("Найти")}
                                    colorVariant="dark"
                                    inHeader
                                />
                            </EventsLayoutHeaderTitle>
                            <EventsLayoutHeaderToolbox>
                                <EventsLayoutHeaderToolboxItem>
                                    {permissionExists(userInfo, ["controlZone.lifeCycle"]) ? (
                                        <Dropdown
                                            toggleLabel={strings("Создать")}
                                            closeOnClickInside
                                            toggleVariant="secondary"
                                            horizontalAlignment="right"
                                        >
                                            <DropdownItem as="button" onClick={showCreateZoneModal}>
                                                {strings("Новую зону")}
                                            </DropdownItem>
                                            <DropdownDivider />
                                            <DropdownItem as="button" onClick={showCreateZonesGroupModal}>
                                                {strings("Новую группу зон")}
                                            </DropdownItem>
                                        </Dropdown>
                                    ) : null}
                                </EventsLayoutHeaderToolboxItem>
                            </EventsLayoutHeaderToolbox>
                        </EventsLayoutHeader>

                        <ControlZonesTable defaultClosed={!search} />

                        <ControlZonesCreateUpdateWizard visible={showCreateWizard} />
                        <ControlZonesDeleteWizard visible={showDeleteWizard} />
                    </EventsLayoutPrimary>
                    {selectedGroup && <ControlZonesGroupDetails selectedGroup={selectedGroup} />}
                    {selectedZone && <ControlZonesDetails selectedZone={selectedZone} />}
                </>
            )}
        </CultureContextConsumer>
    );
};

export default withSnackbar(ControlZones);

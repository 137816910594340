const englishMap = new Map();

// Archive.jsx
englishMap.set("Архив", "Archive");
englishMap.set("Ошибка получения локаций", "Failed to get locations");
englishMap.set("Не удалось найти локацию с идентификатором", "Couldn't find locations with id");
englishMap.set("Ошибка получения адаптеров", "Failed to get adapters");
englishMap.set("Не найдено адаптеров поддерживающих архив", "No adapters found that support archive");
englishMap.set("Ошибка получения устройств", "Failed to get devices");
englishMap.set("Камера не выбрана!", "No camera selected!");
englishMap.set("Не удалось найти сервис по типу оборудования", "Couldn't find service by device type");
englishMap.set("Не хватает прав для получения архивной записи для", "Not enough rights to get archive record for");
englishMap.set("Не удалось получить архивную запись для", "Couldn't get archive record for");
englishMap.set(
    "Не хватает прав для получения частей архивной записи для",
    "Not enough rights to get archive record parts for"
);

// ArchiveConverterModal.jsx
englishMap.set("Не удалось получить части архивной записи для", "Failed to get archive record parts for");
englishMap.set("Формат", "Format");
englishMap.set("Не удалось получить количество заказов", "Failed to get orders count");
englishMap.set("Не удалось получить очередь заказов", "Failed to get orders queue");
englishMap.set("Ошибка", "Error");
englishMap.set("Подготовка", "In progress");
englishMap.set("Готово", "Done");
englishMap.set("Отменен", "Cancelled");
englishMap.set(
    "Не удалось получить convertedFileId для загрузки файла",
    "Failed to get convertedFileId to download file"
);
englishMap.set("Не удалось получить metadata файла", "Failed to get metadata of file");
englishMap.set("Файл", "File");
englishMap.set("не найден", "is not found");
englishMap.set("Загрузка файла", "File download");
englishMap.set("запущена", "has started");
englishMap.set("Заказ", "Order");
englishMap.set("отменяется", "is cancelling");
englishMap.set("Произошла ошибка при отмене заказа", "Failed to cancel order");
englishMap.set("Отменить", "Cancel");
englishMap.set("Скачать", "Download");
englishMap.set("Показать ещё", "Show more");

// ArchiveOrderListModal.jsx
englishMap.set("Не удалось получить камеры", "Failed to get cameras");
englishMap.set("Не удалось получить локации", "Failed to get locations");
englishMap.set("Не удалось получить ссылку для загрузки файла", "Failed to get link to download file");
englishMap.set("Заказ на конвертацию для", "Conversion order for");
englishMap.set("за", "for");
englishMap.set("создан", "created");
englishMap.set("создано", "created");
englishMap.set("создана", "created");
englishMap.set("Очередь заказа записей", "Queue of record orders");
englishMap.set("История заказов записей", "Record orders history");
englishMap.set("Найти", "Search");
englishMap.set("Дата заказа", "Order date");
englishMap.set("Камера", "Camera");
englishMap.set("Устройство", "Device");
englishMap.set("Период", "Period");
englishMap.set("Причина", "Reason");
englishMap.set("Статус", "Status");
englishMap.set("Конвертировать в .AVI", "Convert to .AVI");
englishMap.set("Конвертация", "Conversion");
englishMap.set("История заказов фотоизображений", "Screenshot order history");

// ArchivePlayer.jsx
englishMap.set("Вперед", "Forward");
englishMap.set("Назад", "Backward");
englishMap.set("Покадровый", "Frame-by-frame");
englishMap.set("Обычный", "Default");
englishMap.set("Не удалось получить список событий", "Failed to get event list");
englishMap.set("Тип события", "Event type");
englishMap.set("Дата", "Date");
englishMap.set("Перейти в журнал", "To event log");
englishMap.set("Текущее время архива не определено", "Current archive time is undefined");
englishMap.set(
    "Дождитесь начала трансляции для снятия скриншота",
    "Please wait until the broadcast starts to take a screenshot"
);
englishMap.set("Не удалось сохранить скриншот", "Failed to save screenshot");
englishMap.set("Отсутствует конфигурация RTCPeerConnection", "No RTCPeerConnection configuration found");
englishMap.set("Ошибка получения URL архива", "Failed to get archive URL");
englishMap.set("Отсутствует модуль WebAssembly", "No WebAssembly module found");
englishMap.set("Нет доступа к устройству", "No access to device");
englishMap.set("Ошибка поключения", "Connection error");
englishMap.set("Неподдерживаемый кодек трансляции", "Unsupported broadcast codec");
englishMap.set("Таймаут подключения к трансляции", "Broadcast connection timeout");
englishMap.set("Ошибка подключения к трансляции", "Broadcast connection error");
englishMap.set("Неизвестная ошибка подключения", "Unknown connection error");
englishMap.set("Пауза", "Pause");
englishMap.set("Начать", "Play");
englishMap.set("Снимок экрана", "Screenshot");
englishMap.set("Создать событие", "Create event");
englishMap.set("Настройки", "Settings");
englishMap.set("скорость", "frame rate");
englishMap.set("Направление", "Direction");
englishMap.set("Режим", "Mode");
englishMap.set("На весь экран", "Fullscreen");

// ArchivePlayerWrapper.jsx
englishMap.set("Камера не выбрана", "Camera not selected");
englishMap.set("Выберите камеру", "Select a camera");

// Toolbar.jsx
englishMap.set("Выбрать период", "Select a period");
englishMap.set("Последний час", "Last hour");
englishMap.set("Последние 6 часов", "Last 6 hours");
englishMap.set("Последние сутки", "Last 24 hours");
englishMap.set("Часы", "Hours");
englishMap.set("Минуты", "Minutes");
englishMap.set("Заказать запись", "Order a record");
englishMap.set("Заказы", "Orders");
englishMap.set("Скрыть информацию о камерах", "Hide cameras info");
englishMap.set("Показать информацию о камерах", "Show cameras info");
englishMap.set("Включить синхронный просмотр", "Enable synchronous viewing");
englishMap.set("Отключить синхронный просмотр", "Disable synchronous viewing");

// CreatedOrdersListModal.jsx
englishMap.set("Номер заказа", "Order number");
englishMap.set("Закрыть", "Close");

// OrderArchiveModal.jsx
englishMap.set("Не выбрано ни одной камеры", "No cameras selected");
englishMap.set("Укажите дату начала архива", "Select archive start date");
englishMap.set("Укажите дату конца архива", "Select archive end date");
englishMap.set("Дата начала не может быть позже даты конца архива", "Start date can't be later than end date");
englishMap.set("Невозможно получить информацию об адаптере для камеры:", "Impossible to get adapter info for camera:");
englishMap.set(
    "Нет подкюченных адаптеров для выгрузки архива камеры",
    "There are no connected adapters for camera archive download"
);
englishMap.set("Не удалось создать заказ архивной записи камеры", "Failed to create archive order");
englishMap.set("Отправка архивных заданий", "Sending archive tasks");
englishMap.set("Сохранить", "Save");
englishMap.set("Причина заказа", "Order reason");
englishMap.set("Заказать", "Order");
englishMap.set("Выбрано", "Selected");
englishMap.set("Заказать фотоизображение", "Order a screenshot");

// EventList.jsx
englishMap.set("События", "Events");
englishMap.set("Создать", "Create");
englishMap.set("Старт", "Play");

// events constants.js
englishMap.set("Обычные", "Primary");
englishMap.set("Требующие внимания", "Warning");
englishMap.set("Тревожные", "Danger");

// Events.jsx
englishMap.set("Не удалось получить список событий", "Failed to get events list");
englishMap.set("Не удалось получить количество событий", "Failed to get events count");
englishMap.set("Не удалось просмотреть событие", "Failed to mark event as viewed");
englishMap.set("Не удалось получить обновленное событие", "Failed to get refreshed event");
englishMap.set("Ошибка получения групп устройств", "Failed to get device groups");
englishMap.set("Ошибка получения фильтров событий", "Failed to get events filters");
englishMap.set("Применён фильтр", "Filter applied");
englishMap.set("Не удалось загрузить фильтр", "Failed to load filter");
englishMap.set("Не удалось сохранить фильтр", "Failed to save filter");
englishMap.set("Фильтр", "Filter");
englishMap.set("сохранен", "saved");
englishMap.set("сохранено", "saved");
englishMap.set("сохранена", "saved");
englishMap.set("Не удалось редактировать фильтр", "Failed to edit filter");
englishMap.set("Фильтр событий", "Events filter");
englishMap.set("удален", "deleted");
englishMap.set("удалено", "deleted");
englishMap.set("удалена", "deleted");
englishMap.set("Не удалось удалить фильтр событий", "Failed to delete events filter");
englishMap.set("Вы хотите удалить фильтр событий", "You are going to delete an event filter");
englishMap.set(
    "Удалённый фильтр событий нельзя восстановить. Продолжить?",
    "A deleted event filter cannot be restored. Continue?"
);
englishMap.set(
    "В одном контексте фильтры событий должны иметь уникальные имена",
    "Event filters must have unique names in same context"
);

// events Filters.jsx
englishMap.set("Показывать просмотренные", "Show viewed");
englishMap.set("Тип", "Type");
englishMap.set("Группа", "Group");
englishMap.set("Выйти", "Log out");

// EventsFilterModal.jsx
englishMap.set("Персональный", "Personal");
englishMap.set("Групповой", "Group");
englishMap.set("Системный", "System");
englishMap.set("Ошибка получения групп пользователей", "Failed to get users groups ");
englishMap.set("Имя фильтра события не может быть пустым", "Event filter's name cannot be empty");
englishMap.set("Укажите тип фильтра события", "Please select event filter type");
englishMap.set("Выберите группу пользователя", "Please select user group");
englishMap.set("Новый фильтр", "New filter");
englishMap.set("Название", "Name");
englishMap.set("Комментарий", "Comment");
englishMap.set("Тип фильтра", "Filter type");
englishMap.set("Выбрать тип фильтра событий", "Select event filter type");
englishMap.set("Поле не может быть пустым", "Field cannot be empty");
englishMap.set("Выбрано:", "Selected:");

// EventsNewEventModal.jsx
englishMap.set("Добавить еще...", "Add more...");
englishMap.set("Выберите файл или перенесите его сюда", "Select file or drag and drop it here");
englishMap.set("Размер файла превышает допустимый предел в", "File size exceeds");
englishMap.set("МБ. Обратитесь к администратору", "MB limit. Please contact your administrator");
englishMap.set("Укажите устройство", "Please select device");
englishMap.set("Адаптер пользовательских событий не найден!", "User events adapter not found!");
englishMap.set("Невозможно создать событие в будущем времени", "Cannot create event in future");
englishMap.set("Загрузка файлов...", "Uploading files...");
englishMap.set("Ошибка загрузки файлов", "Failed to upload files");
englishMap.set("Отправка события. Подождите...", "Sending");
englishMap.set("Событие создано", "Event created");
englishMap.set("Устройство пользовательских событий не найдено", "User event device not found");
englishMap.set("Ошибка при создании пользовательского события", "Failed to create user event");
englishMap.set("Не удалось найти устройство с идентификатором", "Couldn't find device with id");
englishMap.set("Краткая информация", "Brief info");
englishMap.set("Описание", "Description");
englishMap.set("устройство", "device");
englishMap.set("Дата наступления", "Event date");
englishMap.set("Секунды", "Seconds");

// LargeFilesModal.jsx
englishMap.set("Б", "B");
englishMap.set("КБ", "KB");
englishMap.set("МБ", "MB");
englishMap.set("Предупреждение", "Warning");
englishMap.set("Вы пытаетесь сохранить файл размером", "You are trying to save a file with size of");
englishMap.set("Вы уверены?", "Are you sure?");

// UpdateFilterModal.jsx
englishMap.set("Сохранить изменения для фильтра событий", "Save changes for events filter");
englishMap.set("Да, сохранить", "Yes, save");

// DetailsTabArchive.jsx
englishMap.set("Загрузка архивной трансляции...", "Loading archive broadcast...");
englishMap.set("Отсутствует архивная запись", "Archive record missing");
englishMap.set(
    "Не хватает прав для получения частей архивной записи",
    "There are not enough rights to get archive record parts"
);
englishMap.set("Не удалось получить части архивной записи", "Failed to get archive record parts");

// DetailsTabInfo
englishMap.set("Дата регистрации события", "Event registration date");
englishMap.set("Дата наступления события", "Event date");

// DetailsTabOnMap
englishMap.set("Перейти на карту", "To map");

// DetailsTabProperties
englishMap.set("Не удалось получить устройство", "Failed to get device");

// EventDetails
englishMap.set("На плане", "On plan");
englishMap.set("На карте", "On map");
englishMap.set("Общая информация", "General info");
englishMap.set("Просмотрено", "Viewed");
englishMap.set("Просмотреть", "View");
englishMap.set("Просмотреть для себя", "View for myself");
englishMap.set("Просмотреть для группы", "View for group");
englishMap.set("Просмотреть для всех", "View for everyone");
englishMap.set("Видеоархив", "Archive");

// EventDetailsMap.jsx
englishMap.set("Ошибка получения зоны обзора", "Failed to get view zone");

// FilesBlock.jsx
englishMap.set("Файл не найден", "File not found");
englishMap.set("Загрузка запущена", "Download started");

// Image2Similarity.jsx
englishMap.set("Сходство", "Similarity");

// Gallery.jsx
englishMap.set("Не найдено адаптеров поддерживающих стрим", "Adapters that support the stream could not be found");
englishMap.set("Ошибка получения скриншотов", "Failed to get screenshots");
englishMap.set("Ошибка получения времени скриншотов", "Failed to get screenshot dates");
englishMap.set("Отсутствует фотоизображение", "Screenshot missing");
englishMap.set("История заказов", "Order history");
englishMap.set("Не удалось получить список расписаний", "Failed to fetch schedules");
englishMap.set("Не удалось получить список устройств расписания", "Failed to fetch schedule's devices");
englishMap.set("Не удалось получить информацию об устройствах из расписания", "Failed to fetch schedule's device info");

//LoginAboutModal
englishMap.set('ПО "Система ситуационного видеомониторинга"', "Situational video monitoring system");
englishMap.set("Интеграционная IoT‑платформа inOne, версия 2.0", "Integration IoT-platform inOne, version 2.0");
englishMap.set(
    "InOne – цифровая интеграционная платформа, позволяющая эффективно решать задачи мониторинга,\n" +
        "                            диспетчеризации и автоматизации процессов реагирования для организаций различного масштаба,\n" +
        "                            имеющих территориально-распределенную структуру или большое количество подключенных\n" +
        "                            источников цифровых данных.",
    "InOne - digital integration platform, which allows user to effectively solve the problems of monitoring,\n" +
        "                            dispatching and automating response processes for organizations of various sizes,\n" +
        "                            that have a geographically distributed structure of a large number of\n" +
        "                            connected sources of digital data."
);
englishMap.set(
    "Включена в единый реестр российских программ Минкомсвязи РФ",
    "Included in the unified register of Russian programs of the Ministry of Telecom and Mass Communications of the Russian Federation"
);
englishMap.set(
    "Узнать больше о платформе на сайте Head-Point",
    "Find out more about the platform on the Head-Point website"
);

// LoginPage.jsx
englishMap.set("Ошибка доступа", "Access error");
englishMap.set("Неправильный логин или пароль", "Incorrect login or password");
englishMap.set("Имя пользователя", "Username");
englishMap.set("Пароль", "Password");
englishMap.set("Войти", "Log In");
englishMap.set("О платформе", "About the platform");
englishMap.set("О платформе InOne", "About InOne platform");
englishMap.set(
    "Для работы с системой примите условия лицензионного соглашения",
    "Accept the terms of the license agreement to work with the system"
);

// LicenseAgreementModal.jsx
englishMap.set("Я принимаю условия лицензионного соглашения", "I accept the terms of the license agreement");

// map Filters.jsx
englishMap.set("Интервал", "Interval");
englishMap.set("Все события", "All events");
englishMap.set("Последние события", "Last events");
englishMap.set("Новые события", "New events");

// Map.jsx
englishMap.set(
    "Не удалось найти точку на карте - очистите фильтры",
    "Couldn't find a point on the map - clear filters"
);
englishMap.set("Ошибка получения объектов", "Failed to get objects");
englishMap.set("Не удалось получить события", "Failed to get events");

// MapPoints.jsx
englishMap.set("Ошибка получения зоны обзора для точки", "Failed to get view zone for point");
englishMap.set("В объекте отсутствует информация!", "The object has no information!");
englishMap.set("Не хватает прав для сохранения зоны обзора", "Not enough rights to save view zone");
englishMap.set("Не удалось сохранить зону обзора", "Failed to save view zone");
englishMap.set(
    "Ошибка получения объекта, информация в карточке устарела",
    "Failed to get object, the information in card is outdated"
);
englishMap.set("Зона обзора не изменялась", "View zone didn't change");
englishMap.set("Зона обзора сохранена", "View zone saved");
englishMap.set("Локация", "Location");
englishMap.set("Локацию", "Location");
englishMap.set("локацию", "location");
englishMap.set("Объекты наблюдения", "Surveillance objects");
englishMap.set("Не удалось получить количество тревожных событий", "Failed to get danger events count");
englishMap.set("Не удалось получить количество событий, требующих внимания", "Failed to get warning events count");
englishMap.set("Не удалось получить планы для локации", "Failed to get plans for the location");
englishMap.set("Планы", "Plans");
englishMap.set("Журнал событий", "Event log");
englishMap.set("Карточка локации", "Location card");
englishMap.set("Общее", "General");

// DetailsTabMain.jsx
englishMap.set("Тип объекта", "Object type");
englishMap.set("Всего непросмотренных событий", "Total unviewed events");
englishMap.set("Тревожных", "Danger");
englishMap.set("Требующих внимания", "Warning");

// DeviceDetails.jsx
englishMap.set("Не удалось получить локацию", "Failed to get location");
englishMap.set("Видеонаблюдение", "CCTV");
englishMap.set("Видеоархив", "Archive");
englishMap.set("Карточка оборудования", "Device card");

// DetailsTabExtension.jsx
englishMap.set("Всего устройств", "Total devices");

// plans Filters.jsx
englishMap.set("Ошибка получения планов", "Failed to get plans");
englishMap.set("План", "Plan");

// Plans.jsx
englishMap.set("Ошибка получения объектов плана", "Failed to get plan objects");
englishMap.set("Выберите план", "Choose a plan");

// plans constants.js
englishMap.set("В эксплуатации", "In use");
englishMap.set("Ошибка регистрации", "Registration error");
englishMap.set("Стадия подключения", "Connection stage");

// Reports.jsx
englishMap.set("Ошибка при получении списка плагинов", "Failed to get plugin list");
englishMap.set("Ошибка при получении отчета", "Failed to get report");
englishMap.set("Ошибка при получении количества строк отчета", "Failed to get report lines count");
englishMap.set("Тестовый отчет по событиям", "Test events report");
englishMap.set("Тестовый отчет по аудиту", "Test audit report");
englishMap.set("Правила", "Rules");
englishMap.set("Правило", "Rule");
englishMap.set("Сетевая доступность устройств", "Device availability");
englishMap.set("Требует внимания", "Warning");
englishMap.set("Недоступно", "Unavailable");
englishMap.set("Пинг", "Ping");
englishMap.set("График", "Chart");
englishMap.set("Текущий статус", "Current status");
englishMap.set("Дата последней проверки", "Last check date");
englishMap.set("Сводная информация о доступности устройства за период", "Availability summary for the period");
englishMap.set("Ошибка получения детального отчета", "Can't fetch report details");
englishMap.set("Ошибка получения данных для построения графика", "Can't fetch chart data");
englishMap.set("Ошибка получения границ детального отчета", "Can't fetch details boundaries");

// AdaptersDetails.jsx
englishMap.set("Подключить", "Connect");
englishMap.set("Типы событий", "Event types");
englishMap.set("Адаптеры", "Adapters");
englishMap.set("адаптеры", "adapters");
englishMap.set("событий адаптера", "adapter events");
englishMap.set("события адаптера", "adapter events");
englishMap.set("ID", "ID");
englishMap.set("важность", "severity");
englishMap.set("ID сервиса", "Service ID");
englishMap.set("Имя сервиса", "Service name");
englishMap.set("Поддерживаемые устройства", "Supported devices");
englishMap.set("Создать тип события", "Create event type");
englishMap.set("Не удалось создать тип события", "Failed to create event type");
englishMap.set("Название типа события", "Event type name");
englishMap.set("Идентификатор события", "Event ID");
englishMap.set("Не удалось получить новый код события", "Failed to get a new event code");
englishMap.set("Не удалось удалить тип события", "Failed to delete event type");
englishMap.set("Удалить тип события", "Delete event type");
englishMap.set("Выбрать тип события", "Select event type");
englishMap.set("Не удалось обновить тип события", "Failed to update event type");
englishMap.set("Изменить тип события", "Edit event type");

// ConnectDeviceModal.jsx
englishMap.set("Не удалось получить зарегистрированные устройства", "Failed to get registered devices");
englishMap.set(
    "Достигнут лимит лицензии на регистрацию новых устройств в адаптере",
    "The license limit for registering new devices in the adapter has been reached"
);
englishMap.set("Не удалось зарегистрировать устройство", "Failed to register device");
englishMap.set("Не удалось снять с регистрации устройство", "Failed to deregister device");
englishMap.set("Подключение устройств. Подождите...", "Connecting devices. Please wait...");
englishMap.set("Подключение оборудования", "Connecting devices");

// DeviceTooltip.jsx
englishMap.set("Тип устройства", "Device type");
englishMap.set("Карточка устройства", "Device card");

// AvailabilityControlSettings.jsx
englishMap.set("Ошибка получения правил доступности", "Failed to get access rules");
englishMap.set("Ошибка получения списка доступных устройств", "Failed to get list of available devices");
englishMap.set("Правило создано", "Rule created");
englishMap.set("Не хватает прав для создания правила", "Not enough rights to create rule");
englishMap.set("У правила должно быть уникальное название", "Rule must have a unique name");
englishMap.set("Не удалось создать правило", "Failed to create rule");
englishMap.set("Правило успешно изменено", "Rule updated successfully");
englishMap.set("Не хватает прав для изменения правила", "Not enough rights to update rules");
englishMap.set("Не удалось сохранить правило", "Failed to save rule");
englishMap.set("Правило успешно удалено", "Rule deleted successfully");
englishMap.set("Проверка доступности", "Availability check");
englishMap.set("Правило проверки доступности", "Availability check rule");
englishMap.set("Создать правило проверки сетевой доступности", "Create network availability check rule");
englishMap.set("Изменить правило проверки сетевой доступности", "Update network availability check rule");
englishMap.set("Вы хотите удалить правило", "You are going to delete rule");
englishMap.set("Удалённое правило нельзя восстановить. Продолжить?", "A deleted rule cannot be restored. Continue?");

// RuleTable.jsx
englishMap.set("правило проверки доступности", "availability check rule");
englishMap.set("диапазоны откликов, мс", "response ranges, ms");
englishMap.set("макс потерянных пакетов", "max lost packets");

// ModalStepDefault.jsx
englishMap.set("Название правила", "Rule name");
englishMap.set("Название расписания", "Schedule name");
englishMap.set("Вид расписания", "Schedule kind");
englishMap.set("Тип расписания", "Schedule type");
englishMap.set("Создание событий", "Creating events");
englishMap.set("Кол-во пакетов на отправку - шт.", "Number of packages per shipment - pcs.");
englishMap.set("Интервал опроса - с.", "Polling interval - sec.");
englishMap.set("Макс. процент ошибок - %", "Max. error percentage - %");
englishMap.set("Поле не может быть больше 100", "Field cannot be greater than 100");
englishMap.set("Допустимое время ожидания - мс.", "Allowable waiting time - ms.");
englishMap.set("Предельное время ожидания - мс.", "Max waiting time - ms.");
englishMap.set("Дата завершения должна быть больше даты начала", "The end date must be greater than the start date");

// DetailsTabDevices.jsx
englishMap.set("Ошибка при получении названий локаций", "Failed to get location names");
englishMap.set("Правилу", "Rule");

// DetailsTabMain.jsx
englishMap.set("Кол-во пакетов на отправку", "Number of packages per shipment");
englishMap.set("Макс. отклонение", "Max. deviation");
englishMap.set("Диапазоны откликов", "Response ranges");
englishMap.set("Желтый", "Yellow");
englishMap.set("Красный", "Red");

// RuleDetails.jsx
englishMap.set("Редактировать", "Edit");
englishMap.set("Удалить", "Delete");

// DevicesSettings
englishMap.set("Копировать RTSP URL", "Copy RTSP URL");
englishMap.set("Ошибка копирования URL трансляции", "Failed to copy RTSP URL");
englishMap.set("URL трансляции скопирован", "RTSP URL is copied");
englishMap.set("Копирование URL трансляции требует HTTPS", "RTSP URL requires HTTPS");

// DevicesGroupsSettings
englishMap.set("Ошибка получения количества устройств", "Failed to get devices count");
englishMap.set("Группы оборудования", "Device groups");
englishMap.set("Фиксированную группу", "Fixed group");
englishMap.set("Динамическую группу", "Dynamic group");
englishMap.set("Фиксированная", "Fixed");
englishMap.set("Динамическая", "Dynamic");
englishMap.set("Неизвестный тип", "Unknown type");

// DeviceGroupsTable.jsx
englishMap.set("Группа оборудования", "Device group");
englishMap.set("Тип группы", "Group type");
englishMap.set("Устройств", "Devices count");

// DetailsTabDevices.jsx
englishMap.set("В группе", "In group");
englishMap.set("Тип группы оборудования", "Device group type");

// DetailsTabTagAndTypes.jsx
englishMap.set("Ошибка получения тегов оборудования", "Failed to get devices");
englishMap.set("В группу включено оборудование", "The group includes");
englishMap.set("В группу включено оборудование с хотя бы одним из", "The group includes device with at least one of");

// DetailsTabTagsAndTypes.jsx
englishMap.set("Типы и теги оборудования", "Devices types and tags");
englishMap.set("Оборудование", "Devices");

// DeleteModal.jsx
englishMap.set("Нельзя удалить группу, которая содержит оборудование", "Cannot delete a group that contains devices");
englishMap.set("Не удалось удалить группу оборудования", "Failed to delete device group");
englishMap.set("Вы хотите удалить группу", "You are going to delete group");
englishMap.set(
    "Удалённую группу ролей нельзя восстановить. Продолжить?",
    "A deleted role group cannot be restored. Continue?"
);

// DeviceGroupModal.jsx
englishMap.set("Нельзя создать группу оборудования без имени", "Cannot create a device group without name");
englishMap.set("Не хватает прав для создания группы оборудоваения", "Not enough rights to create device group");
englishMap.set("Группы оборудования должны иметь уникальные имена", "Device groups must have unique names");
englishMap.set("Не удалось создать группу оборудования", "Failed to create device group");
englishMap.set("Нельзя оставить группу оборудования без имени", "Cannot leave a device group without name");
englishMap.set("обновлен", "updated");
englishMap.set("обновлена", "updated");
englishMap.set("Не хватает прав для изменения группы оборудоваения", "Not enough rights to edit device group");
englishMap.set("Не удалось сохранить группу оборудования", "Failed to save device group");
englishMap.set("Применение настроек", "Applying settings");

// DynamicGroupModal
englishMap.set("Изменить", "Edit");
englishMap.set("динамическую группу", "dynamic group");
englishMap.set("фиксированную группу", "fixed group");

// CameraPreview.jsx
englishMap.set("URL трансляции отсутствует", "Broadcast URL missing");
englishMap.set("Ошибка подключения", "Connection error");

// device settings constants.js
englishMap.set("Расположение", "Location");
englishMap.set("Фиксированные группы оборудования", "Fixed device groups");
englishMap.set("Теги оборудования", "Device tags");

// DeviceList.jsx
englishMap.set("Показывать превью камер", "Show camera preview");
englishMap.set("Не хватает прав для удаления локации", "Not enough rights to delete location");
englishMap.set("Нельзя удалить", "Cannot delete");
englishMap.set("- локация содержит потомков", "location has children");
englishMap.set("Не удалось удалить локацию", "Failed to delete location");
englishMap.set("Не удалось удалить устройство", "Failed to delete device");
englishMap.set("Ошибка получения лицензий", "Failed to get licenses");
englishMap.set("Отсутствует лицензия на запись архива", "Archive recording license missing");
englishMap.set("Достигнут лимит лицензии на запись архива", "Archive recording license limit reached");
englishMap.set("Нельзя сохранить устройство без имени", "Cannot save device without name");
englishMap.set("Нельзя сохранить устройство без типа", "Cannot save device without type");
englishMap.set("Значение долготы должно быть числом от -180 до 180", "Longitude value must be between -180 and 180");
englishMap.set(
    "Значение широты должно быть числом в диапазоне от -90 до 90",
    "Latitude value must be between -90 and 90"
);
englishMap.set("Не выбран функционал для", "No functionality selected for");
englishMap.set("Не заполнено поле", "Empty field");
englishMap.set("для функции", "in function");
englishMap.set("Не хватает прав для создания устройства", "Not enough rights to create device");
englishMap.set("Достигнут лимит лицензии на создание новых устройств", "Device creating license limit reached");
englishMap.set("Не удалось создать устройство", "Failed to create device");
englishMap.set("Не хватает прав для изменения устройства", "Not enough rights to edit device");
englishMap.set(
    "В одной локации должны быть устройства с разными именами",
    "There must be devices with different names in the same location" //"Locations must have devices with distinct names"
);
englishMap.set("Не удалось изменить устройство", "Failed to edit device");
englishMap.set("Не удалось обновить тэги", "Failed to update tags");
englishMap.set("Не удалось обновить группы оборудования", "Failed to update device groups");
englishMap.set("Отсутствует адаптер данного типа оборудования", "Adapter for this device type is missing");
englishMap.set("Нельзя создать локацию без имени", "Cannot create location without name");
englishMap.set("Заполните значения широты и долготы", "Fill latitude and longitude values");
englishMap.set("Заполните значение", "Fill value of ");
englishMap.set("Нельзя сохранить локацию без имени", "Cannot save location without name");
englishMap.set("Локация сохранена", "Location saved");
englishMap.set("Не хватает прав для изменения локации", "Not enough rights to edit location");
englishMap.set(
    "В одной локации должны быть подлокации с разными именами",
    "There must be sublocations with different names in the same location"
);
englishMap.set("Нельзя установить эту локацию в качестве родительской", "Cannot set this location as parent");
englishMap.set("Не удалось изменить локацию", "Failed to edit location");
englishMap.set("Локация создана", "Location created");
englishMap.set("Не хватает прав для создания локации", "Not enough rights to create locations");
englishMap.set("Не удалось создать локацию", "Failed to create location");
englishMap.set("Вы хотите удалить устройство", "You are going to delete device");
englishMap.set(
    "Удалённое устройство нельзя восстановить. Продолжить?",
    "A deleted device cannot be restored. Continue?"
);
englishMap.set("Вы хотите удалить локацию", "You are going to delete location");
englishMap.set(
    "Удалённую локацию нельзя восстановить. Продолжить?",
    "A deleted location cannot be restored. Continue?"
);
englishMap.set("Импорт из файла", "Import from a file");
englishMap.set("Скачать отчет", "Download the report");
englishMap.set("Расширение файла: XML", "File extension: XML");
englishMap.set("Не удалось импортировать файл", "Failed to import file");
englishMap.set("Скачать шаблон", "Download template");
englishMap.set("Импортировать", "Import");
englishMap.set("Не удалось получить статус задачи", "Failed to get task status");
englishMap.set("Прогресс", "Progress");
englishMap.set("Файл сохранен", "File saved");
englishMap.set("Проверка пройдена", "Validation passed");
englishMap.set("Записи сохранены", "Rows saved");
englishMap.set("Выполнено", "Processed");
englishMap.set("Завершено", "Finished");
englishMap.set("Обработка", "Processing");
englishMap.set("Результаты выполнения массовой опреации", "Results of mass operation");
englishMap.set("Успешно создано устройств", "Successfully created devices");
englishMap.set("Успешно отредактированно устройств", "Successfully edited devices");
englishMap.set("Успешно удалено устройств", "Successfully deleted devices");
englishMap.set(
    "Вы можете скачать отчет с  детализацией результатов выполнения массовой операции.",
    "You can download a report detailing the results of a mass operation."
);
englishMap.set("Ок", "OK");
englishMap.set("Результаты проверки", "Validation results");
englishMap.set("Файл заполнен некорректно.", "The file is filled in incorrectly.");
englishMap.set("Вы можете скачать отчет с детализацией ошибок.", "You can download a report detailing the errors.");
englishMap.set("Некорректное расширение файла.", "Invalid file extension.");
englishMap.set(
    "Пожалуйста убедитесь, что расширение загружаемого файла - .xls/.xlsx",
    "Please make sure that the extension of the uploaded file is .xls/.xlsx"
);

// DeviceTable.jsx
englishMap.set("Локации и устройства", "Locations and devices");
englishMap.set("Превью", "Preview");

// DeviceTabMain.jsx
englishMap.set("Ошибка получения групп устройства", "Failed to get device's groups");
englishMap.set("Ошибка получения зон контроля", "Failed to get device's control zones");
englishMap.set("Ошибка получения зон", "Failed to get device zones");
englishMap.set("Местоположение", "Geolocation");
englishMap.set("Широта", "Latitude");
englishMap.set("Долгота", "Longitude");
englishMap.set("Тип оборудования", "Equipment type");
englishMap.set("Зоны контроля", "Control zones");
englishMap.set("Нельзя сохранить устройство без локации", "Cannot save device without location");

// DeviceLocationDetails.jsx
englishMap.set("Адрес", "Address");

// DeviceTypesSettings.jsx
englishMap.set("Выбрать тип", "Select type");

// ModalStepAvailability
englishMap.set("Проверять доступность", "Check availability");
englishMap.set("Выбрать правило", "Select rule");
englishMap.set("Макс. потерянных пакетов", "Max. lost packets");
englishMap.set("Макс. время отклика", "Max. response time");
englishMap.set("Интервал опроса", "Polling interval");
englishMap.set("Зеленый", "Green");

// ModalStepCameraConnection
englishMap.set("Тип подключения", "Connection type");
englishMap.set("Выбрать тип подключения", "Select connection type");
englishMap.set("Регистратор", "DVR"); // digital video recorder
englishMap.set("Выбрать регистратор", "Select DVR");
englishMap.set("Порт камеры на видеосервере", "Camera port on video server");
englishMap.set("Не обязательно", "Not necessary");
englishMap.set("Проверить подключение", "Check connection");
englishMap.set("Подключение доступно", "Connection available");
englishMap.set("Не удалось подключиться", "Connection failed");
englishMap.set("Попробовать ещё раз", "Try again");
englishMap.set("Ссылка на консоль управления", "Link to management console");
englishMap.set("Ссылка на трансляцию", "Link to broadcast");
englishMap.set("Профиль", "Profile");
englishMap.set("Выбрать", "Select");
englishMap.set("Битрейт", "Bitrate");
englishMap.set("Протокол", "Protocol");
englishMap.set("Показать поток", "Show stream");
englishMap.set("Добавить ещё профиль", "Add one more profile");
englishMap.set("Добавить профиль", "Add profile");

// ModalStepCameraPtz
englishMap.set("Функции PTZ", "PTZ Functions");
englishMap.set("Драйвер", "Driver");
englishMap.set("Выбрать драйвер", "Select driver");
englishMap.set("Пресет", "Preset");
englishMap.set("Название пресета", "Preset name");
englishMap.set("Фокус", "Focus");
englishMap.set("Добавить ещё пресет", "Add one more preset");
englishMap.set("Добавить пресет", "Add preset");

// ModalStepCameraRelatedDevices.jsx
englishMap.set("Зона контроля", "Control zone");
englishMap.set("Выбрать зону контроля", "Select control zone");
englishMap.set("Название зоны контроля", "Control zone name");
englishMap.set("Устройства", "Devices");
englishMap.set("Выбрать устройства", "Select devices");
englishMap.set("Добавить ещё зону контроля", "Add one more control zone");
englishMap.set("Добавить зону контроля", "Add control zone");

// ModalStepCameraVideoanalytics
englishMap.set("Подключенные детекторы видеоаналитики", "Connected video analytics detectors");
englishMap.set("Детектор", "Detectors");
englishMap.set("Адаптер аналитики", "Analytics adapter");
englishMap.set("Выбрать адаптер", "Select adapter");
englishMap.set("Тип детектора", "Detector type");
englishMap.set("Выбрать список событий", "Select events list");
englishMap.set("ID камеры в системе видеоаналитики", "Camera ID in video analytics system");
englishMap.set("Добавить ещё детектор", "Add one more detector");
englishMap.set("Добавить детектор", "Add detector");

// ModalStepConnection.jsx
englishMap.set("Адаптер", "Adapter");
englishMap.set("Логин", "Login");
englishMap.set("Выберите тип оборудования", "Select equipment type");
englishMap.set("Внешний ID", "External ID");
englishMap.set("Название устройства", "Device name");

// ModalStepSensorTelemetry.jsx
englishMap.set("ID параметра вендора", "Vendor parameter ID");
englishMap.set("Ед. измерения", "Units");
englishMap.set("Нормализованный параметр", "Normalized parameter");
englishMap.set("Ед. изм. нормализованная", "Units normalized");
englishMap.set("Правило контроля параметра", "Parameter control rule");
englishMap.set("Выбрать шаблон", "Select template");
englishMap.set("Тип моментального виджета", "Instant widget type");
englishMap.set("Тип исторического виджета", "Historical widget type");

// LocationStepPosition.jsx
englishMap.set("Родительская локация:", "Parent location:");

// CreateOrUpdateModal.jsx
englishMap.set("Создать тег", "Create device tag");
englishMap.set("Редактировать тег", "Edit device tag");
englishMap.set("Название тега", "Tag name");
englishMap.set("Тег не может быть пустым", "Tag cannot be empty");

// DeviceTagsSettings.jsx
englishMap.set("Ошибка получения списка устройств обзора", "Failed to get view device list");
englishMap.set("Тег нельзя сохранить без названия", "Tag cannot be saved without name");
englishMap.set("Тег оборудования", "Device tag");
englishMap.set("Название тега оборудования не должно повторяться", "Device tag name must be distinct");
englishMap.set("Ошибка создания тега оборудования", "Failed to create device tag");
englishMap.set("Нельзя удалить тег, который содержит устройства", "Cannot delete tag containing devices");
englishMap.set("Вы хотите удалить тэг", "You are going to delete tag");
englishMap.set("Удалённый тэг нельзя восстановить. Продолжить?", "Deleted tag cannot be restored. Continue?");
englishMap.set("Тег", "Tag");
englishMap.set("Изменить тег оборудования", "Edit device tag");

// DetailsTabDevices.jsx
englishMap.set("назначен тег оборудования", "assigned a device tag");

// DeviceTypesDetails.jsx
englishMap.set("Название поля", "Field name");
englishMap.set("Назначение поля", "Field purpose");
englishMap.set("Список полей", "Fields list");

// DeviceTypesSettings.jsx
englishMap.set("Типы оборудования", "Device types");

// DetailsTabEvents.jsx
englishMap.set("Не удалось установить важность события", "Failed to set event severity");
englishMap.set("Событие адаптера", "Adapter event");
englishMap.set("Нормализованный тип события", "Normalized event type");

// EventTypesTable.jsx
englishMap.set("Нормализованные события и их типы", "Normalized events and their types");

// LicenseInfoModal
englishMap.set("Информация о лицензиях", "License information");
englishMap.set("id лицензии", "license id");
englishMap.set("Конечный пользователь", "End user");
englishMap.set("Разработчик", "Developer");
englishMap.set('ООО "Хэд пойнт"', '"Head point" LLC (ООО "Хэд пойнт")');
englishMap.set("Сайт производителя", "Developer's web-site");
englishMap.set("https://head-point.ru/", "https://head-point.ru/");
englishMap.set("Территория действия прав", "Territory of rights");
englishMap.set("РФ", "RU");
englishMap.set("Срок действия лицензии", "License validity period");

//LicenseAgreementModal.jsx
englishMap.set("Лицензионное соглашение", "License agreement");
englishMap.set("Я принимаю условия лицензионного соглашения", "I accept the terms of the license agreement");

// LicenseSettings.jsx
englishMap.set("Ошибка получения лицензий", "Failed to get license");
englishMap.set("Лицензии", "Licenses");
englishMap.set("лицензия", "license");
englishMap.set("активные", "active");
englishMap.set("доступно", "available");
englishMap.set("Загрузить лицензию", "Load license");
englishMap.set("Информация о текущей лицензии", "Information about current license");

// PlanDetails.jsx
englishMap.set("Идентификатор плана", "Plan ID");
englishMap.set("Графическая подложка плана", "Graphic background of the plan");
englishMap.set("Редактировать размещение оборудования", "Edit device location");

// PlanSettings.jsx
englishMap.set("Нельзя сохранить план без имени", "Cannot save plan without name");
englishMap.set("не найден в кэше", "is not found in cache");
englishMap.set("Не хватает прав для сохранения плана", "Not enough rights to save plan");
englishMap.set("План с именем", "Plan with name");
englishMap.set("уже существует в выбранной локации", "is already exists in selected location");
englishMap.set("Не удалось сохранить план", "Failed to save plan");
englishMap.set("Нельзя создать план без имени", "Cannot create plan without name");
englishMap.set("Нельзя создать план без картинки", "Cannot create plan without image");
englishMap.set("Нельзя создать план без локации", "Cannot create plan without location");
englishMap.set("Не хватает прав для создания плана", "Not enough rights to create plan");
englishMap.set("Не удалось создать план", "Failed to create plan");
englishMap.set("Ошибка получения имен локаций для планов", "Failed to get location names for plans");
englishMap.set("Отправка плана. Подождите...", "Sending a plan. Please wait...");
englishMap.set("Вы хотите удалить план", "You are going to delete plan");
englishMap.set("Удалённый план нельзя восстановить. Продолжить?", "Deleted plan cannot be restored. Continue?");
englishMap.set("Размещение на плане", "Placement on the plan");
englishMap.set("Новый план", "New plan");
englishMap.set("Локации и планы", "Locations and plans");
englishMap.set("Количество устройств", "Device count");

englishMap.set("Неправильный формат файла", "Wrong file extension");
englishMap.set("Файл слишком большой", "File size is too large");
englishMap.set("PNG или JPG, от 256×256 пикселей", "PNG or JPG, from 256×256 pixels");
englishMap.set("Создать план", "Create plan");
englishMap.set("Редактировать план", "Edit plan");
englishMap.set("Локация:", "Location:");

// DevicesOnPlanModal.jsx
englishMap.set("Устройство размещено на другом плане", "The device is placed on a different plan");
englishMap.set("Ошибка добавления устройства на план", "Failed to place the device on the plan");
englishMap.set("Размещение оборудования на плане", "Placement of devices on plan");
englishMap.set("Выбранное оборудование", "Selected devices");

// roleSettings/constants.js
englishMap.set("Функции системы", "System functions");
englishMap.set("Минимальный", "Minimal");
englishMap.set("Общий", "General");
englishMap.set("Средний", "Middle");
englishMap.set("Системный", "System");
englishMap.set("Высокий", "High");
englishMap.set("Высший", "Critical");

// RolesSettings.jsx
englishMap.set("Создать роль", "Create role");
englishMap.set("Копировать роль", "Copy role");
englishMap.set("Создать группу ролей", "Create role group");
englishMap.set("Стрим", "Stream");
englishMap.set("Проксирование RTSP трансляции", "Proxy RTSP broadcast");
englishMap.set("Трансляция по WebRTC", "Broadcast with WebRTC");
englishMap.set("Трансляция по Websocket", "Broadcast with Websocket");
englishMap.set("Трансляция по MPEG DASH", "Broadcast with MPEG DASH");
englishMap.set("Просмотр видеоархива", "Watch archive");
englishMap.set("Выгрузка видеоархива", "Upload archive");

// RoleTable.jsx
englishMap.set("Ошибка получения списка ролей", "Failed to get roles list");
englishMap.set("Ошибка получения пользователей", "Failed to get users");
englishMap.set("Ошибка получения списка групп ролей", "Failed to get role groups list");
englishMap.set("Роли", "Roles");
englishMap.set("Пользователи", "Users");

// DetailsDeviceGroupsTab.jsx
englishMap.set("Доступное оборудование", "Available devices");

// DetailsSystemFunctionsTab.jsx
englishMap.set("Доступно", "Available");
englishMap.set("системы", "of system");

// DetailsTabMain.jsx
englishMap.set("Приоритет", "Priority");

// DetailsUsersTab.jsx
englishMap.set("Роль назначена", "Role is assigned to");

// CreateUpdateRoleModal.jsx
englishMap.set("Нельзя сохранить роль без имени", "Cannot save role without name");
englishMap.set("Нельзя сохранить роль без группы", "Cannot save role without group");
englishMap.set("Нельзя сохранить роль без приоритета", "Cannot save role without priority");
englishMap.set("Роль", "Role");
englishMap.set("Не хватает прав для создания роли", "Not enough rights to create role");
englishMap.set("Роль с именем", "Role with name");
englishMap.set("уже существует", "is already exists");
englishMap.set("Не удалось создать роль", "Failed to create role");
englishMap.set("успешно изменен", "is successfully updated");
englishMap.set("успешно изменена", "is successfully updated");
englishMap.set("Не хватает прав для изменеения роли", "not enough rights to edit role");
englishMap.set("Обновить роль", "Update role");

// DeleteRoleModal.jsx
englishMap.set("Не хватает прав для удаления роли", "Not enough rights to delete role");
englishMap.set("- роль назначена пользователям", "- role assigned to users");
englishMap.set("Неизвестный конфликт", "Unknown conflict");
englishMap.set("Не удалось удалить роль", "Failed to delete role");
englishMap.set("Вы хотите удалить роль", "You are going to delete role");
englishMap.set("Удалённую роль нельзя восстановить. Продолжить?", "Deleted role cannot be restored");

// ModalRoleCopy.jsx
englishMap.set("Нельзя сохранить копию без базовой роли", "Cannot save copy without base role");
englishMap.set("Скопировать существующую роль", "Copy existing role");
englishMap.set("Название роли", "Role name");
englishMap.set("Выберите роль", "Select role");
englishMap.set("Создать копию", "Create copy");

// ModalStepDefault.jsx
englishMap.set("Группа ролей", "Role group");
englishMap.set("Выбрать группу ролей", "Select role group");
englishMap.set("Выбрать приоритет", "Select priority");
englishMap.set("Описание роли", "Role description");
englishMap.set("Ошибка получения групп устройств", "Role description");

// ModalStepDGPermission.jsx
englishMap.set("Выбор группы оборудования", "Device group selection");
englishMap.set("Выберите тип группы оборудования", "Select device group type");
englishMap.set("Выберите группу оборудования", "Select device group");
englishMap.set("+ Добавить группу оборудования", "+ Add device group");

// CreateRolesGroupModal.jsx
englishMap.set("Не хватает прав для создания группы ролей", "Not enough rights to create role group");
englishMap.set("Группа ролей с именем", "Role group with name");
englishMap.set("Не удалось создать группу ролей", "Failed to create role group");
englishMap.set("- роль имеет потомков", "- role has children");
englishMap.set("Вы хотите удалить группу ролей", "You are going to delete role group");
englishMap.set(
    "Удалённую группу ролей нельзя восстановить. Продолжить?",
    "Deleted role goup cannot be restored. Continue?"
);

// EditRolesGroupModal.jsx
englishMap.set("Изменить группу ролей", "Edit role group");
englishMap.set("Не хватает прав для изменения группы ролей", "Not enough rights to edit role group");
englishMap.set("Группа ролей с именем", "Not enough rights to edit role group");
englishMap.set("Не удалось сохранить группу ролей", "Failed to create role group");

// RolesGroupsModal.jsx
englishMap.set("Название группы ролей", "Role group name");

// ScenariosDetails.jsx
englishMap.set("Всё время", "All time");
englishMap.set("Точное время", "Exact time");
englishMap.set("Временной интервал", "Time interval");
englishMap.set("Точное время с датой", "Exact time with date");
englishMap.set("Все даты", "All dates");
englishMap.set("Точная дата", "Exact date");
englishMap.set("Повтор каждый день", "Repeat every day");
englishMap.set("Повтор каждую неделю", "Repeat every week");
englishMap.set("Еженедельно, дни недели", "Repeat every week");
englishMap.set("Повтор каждый месяц", "Repeat every month");
englishMap.set("Ежемесячно, дни месяца", "Repeat every month");
englishMap.set("Повтор каждый год", "Repeat every year");
englishMap.set("Понедельник", "Monday");
englishMap.set("Вторник", "Tuesday");
englishMap.set("Среда", "Wednesday");
englishMap.set("Четверг", "Thursday");
englishMap.set("Пятница", "Friday");
englishMap.set("Суббота", "Saturday");
englishMap.set("Воскресенье", "Sunday");
englishMap.set("Ошибка получения шаблонов выполнения", "Failed to get execution templates");
englishMap.set("Открывающее событие", "Opening event");
englishMap.set("Шаблон реагирования", "Response template");
englishMap.set("Интервалы выполнения", "Executions intervals");
englishMap.set("Время", "Time");
englishMap.set("Сценарий реагирования распространяется на", "Response scenario covers");
englishMap.set("устройств", "devices");
englishMap.set("Приостановить", "Pause");

// ScenarioSettings.jsx
englishMap.set("Сценарий реагирования", "Response scenario");
englishMap.set("Сценарии реагирования", "Response scenarios");
englishMap.set("сценарий реагирования", "response scenario");
englishMap.set("Не хватает прав для удаления сценария реагирования", "Not enough rights to delete response scenario");
englishMap.set("Не удалось удалить сценарий реагирования", "Failed to delete response scenario");
englishMap.set("Сценарий", "Scenario");
englishMap.set("успешно", "successfully");
englishMap.set("возобновлен", "resumed");
englishMap.set("приостановлен", "paused");
englishMap.set("Не хватает прав для изменения сценария реагирования", "Not enough rights to edit response scenario");
englishMap.set("Не удалось изменить сценарий реагирования", "Failed to edit response scenario");
englishMap.set("Сценарии реагирования должны иметь уникальные имена", "Response scenarios must have unique names");
englishMap.set("Нельзя создать сценарий реагирования без имени", "Cannot create response scenario without name");
englishMap.set(
    "Нельзя создать сценарий реагирования без привязанного шаблона",
    "Cannot create response scenario without linked template"
);
englishMap.set("Не хватает прав для создания сценария реагирования", "Not enough rights to create response scenario");
englishMap.set("Не удалось создать сценарий реагирования", "Failed to create response scenario");
englishMap.set("Вы хотите удалить сценарий реагирования", "You are going to delete response scenario");
englishMap.set(
    "Удалённый сценарий реагирования нельзя восстановить. Продолжить?",
    "Deleted response scenario cannot be restored. Continue?"
);
englishMap.set("Создать новый", "Create new");

// ModalStepsDefault.jsx
englishMap.set("Приостановлено", "Paused");
englishMap.set("Название сценария реагирования", "Response scenario name");
englishMap.set("Выберите тип события", "Select event type");
englishMap.set("Выберите шаблон реагирования", "Select response template");
englishMap.set("Укажите шаблон реагирования", "Set response template");

// ScenarioTemplatesSettings/constants.jsx
englishMap.set("Функции", "Functions");

// ScenarioTemplatesDetails.jsx
englishMap.set("Шаблон используется в", "Template is used in");
englishMap.set("сценариях реагирования", "response scenarios");

// ScenarioTemplatesSettings.jsx
englishMap.set("Нельзя создать шаблон реагирования без имени", "Cannot create response template without name");
englishMap.set(
    "Нельзя создать шаблон реагирования без указанных функций",
    "Cannot create response template without specified functions"
);
englishMap.set("Не хватает прав для создания шаблона", "Not enough rights to create template");
englishMap.set("Шаблоны реагирования должны иметь уникальные имена", "Response templates must have unique names");
englishMap.set("Не удалось создать шаблон реагирования", "Failed to create response template");
englishMap.set("Не хватает прав для изменения шаблона реагирования", "Not enough rights to edit response template");
englishMap.set("Не удалось изменить шаблон реагирования", "Failed to edit response template");
englishMap.set("Не хватает прав для удаления шаблона реагирования", "Not enough rights to delete response template");
englishMap.set("Не удалось удалить шаблон реагирования", "Failed to delete response template");
englishMap.set("Вы хотите удалить шаблон реагирования", "You are going to delete response template");
englishMap.set(
    "Удалённый шаблон реагирования нельзя восстановить. Продолжить?",
    "Deleted template cannot be restored. Continue?"
);
englishMap.set("шаблон реагирования", "response template");
englishMap.set("Шаблоны реагирования", "Response templates");
englishMap.set("шаблон", "template");
englishMap.set("кол-во функций", "functions count");

// ModalFormStringProperty.jsx
englishMap.set("Введите текст", "Enter text");

// ModalFormUserGroupsTreeProperty.jsx
englishMap.set("Группы пользователей", "User groups");

// ModalStepFunctions.jsx
englishMap.set("Выберите функцию", "Select function");
englishMap.set("Добавить ещё функцию", "Add another function");

// DetailsTabDevices.jsx
englishMap.set("устройств адаптера", "adapter devices");

// DetailsTabMain.jsx !!!!!!!
englishMap.set("Code", "Code");
englishMap.set("Name", "Name");
englishMap.set("Instances", "Instances");

// ServiceSettings.jsx
englishMap.set("Активные сервисы", "Active services");
englishMap.set("Сервис", "Services");
englishMap.set("Количество экземпляров", "Number of instances");

// SystemVariablesSettings.jsx
englishMap.set("Ошибка получения системных переменных", "Failed to get system variables");
englishMap.set("Нельзя создать переменную без значения", "Cannot create variable without value");
englishMap.set("Системная переменная", "System variable");
englishMap.set("Не хватает прав для создания системной переменной", "Not enough rights to create system variable");
englishMap.set("Системная переменная с именем", "System variable with name");
englishMap.set("Не удалось создать системную переменную", "Failed to create system variable");
englishMap.set("Нельзя сохранить переменную без значения", "Cannot save system variable without value");
englishMap.set("Не удалось изменить системную переменную", "Failed to edit system variable");
englishMap.set("Не хватает прав для изменения системной переменной", "Not enough rights to edit system variable");
englishMap.set("Не хватает прав для удаления системной переменной", "Not enough rights to delete system variable");
englishMap.set("Не удалось удалить системную переменную", "Failed to delete system variable");
englishMap.set("Вы хотите удалить системную переменную", "You are going to delete system variable");
englishMap.set(
    "Удалённую переменную нельзя восстановить. Продолжить?",
    "Deleted system variable cannot be restored. Continue?"
);
englishMap.set("Системные переменные", "System variables");
englishMap.set("Новую системную переменную", "New system variable");

// SystemVariablesTable.jsx
englishMap.set("Переменные", "Variables");

// DetailsTabMain.jsx
englishMap.set("Код сервиса", "Service code");
englishMap.set("Код", "Code");
englishMap.set("Значение", "Value");

// SystemVariablesModal.jsx
englishMap.set("Создать системную переменную", "Create system variable");
englishMap.set("Изменить системную переменную", "Edit system variable");
englishMap.set("Выбрать переменную из доступных", "Select variable from available ones");

// userSettings/constants.jsx
englishMap.set("Локации", "Locations");

// UserSettings.jsx
englishMap.set("Ошибка получения списка локаций", "Failed to get locations list");
englishMap.set("Нельзя создать пользователя без логина", "Failed to create user without login");
englishMap.set("Нельзя создать пользователя без пароля", "Failed to create user without password");
englishMap.set("Нельзя создать пользователя без группы", "Failed to create user without group");
englishMap.set("Нельзя создать пользователя без роли", "Failed to create user without role");
englishMap.set("Пользователь", "User");
englishMap.set("Не хватает прав для создания пользователя", "Not enough rights to create user");
englishMap.set("Пользователь с логином", "User with login");
englishMap.set(
    "Достигнут лимит лицензии на создание новых пользователей",
    "The license limit for creating new users has been reached"
);
englishMap.set("Не удалось создать пользователя", "Failed to create user");
englishMap.set("Нельзя сохранить пользователя без логина", "Cannot save user without login");
englishMap.set("Нельзя сохранить пользователя без группы", "Cannot save user without group");
englishMap.set("Нельзя сохранить пользователя без роли", "Cannot save user without role");
englishMap.set("Не хватает прав для изменения пользователя", "Not enough rights to edit user");
englishMap.set("У пользователей должен быть уникальный login", "Users must have unique logins");
englishMap.set("Не удалось сохранить пользователя", "Failed to save user");
englishMap.set("Не хватает прав для удаления пользователя", "Not enough rights to delete user");
englishMap.set("Удаление последнего superuser запрещено", "Removing the last SuperUser is prohibited");
englishMap.set("Запрещено удаление самого себя", "Removing yourself is prohibited");
englishMap.set("Конфликт помешал удалению пользователя", "Conflict prevented deletion of user");
englishMap.set("Не удалось удалить пользователя", "Failed to delete user");
englishMap.set("Группа пользователей", "User group");
englishMap.set("Не хватает прав для создания группы пользователей", "Not enough rights to create user group");
englishMap.set("У групп пользователей должно быть уникальное имя", "User groups must have unique names");
englishMap.set("Не удалось создать группу пользователей", "Failed to create user group");
englishMap.set("Не хватает прав для изменения группы пользователей", "Not enough rules to edit user group");
englishMap.set("Не удалось сохранить группу пользователей", "Failed to save user group");
englishMap.set("Не хватает прав для удаления группы пользователей", "Not enough rights to save user group");
englishMap.set("- группа содержит пользователей", "- group contains users");
englishMap.set("Не удалось удалить группу пользователей", "Failed to delete user group");
englishMap.set("Вы хотите удалить пользователя", "You are going to delete user");
englishMap.set(
    "Удалённого пользователя нельзя восстановить. Продолжить?",
    "Deleted user cannot be restored. Continue?"
);
englishMap.set("Вы хотите удалить группу пользователей", "You are going to delete user group");
englishMap.set(
    "Удалённый раздел пользователей нельзя восстановить. Продолжить?",
    "Deleted user section cannot be restored. Continue?"
); //???
englishMap.set("Наследование", "Inheritance");

// UserTable.jsx
englishMap.set("группы и пользователи", "groups and users");
englishMap.set("телефон", "phone");
englishMap.set("роль", "role");

// DetailsTabAccesability.jsx typo in name
englishMap.set("Доступны все локации системы", "All system locations available");
englishMap.set("Нет доступных локаций", "No locations available");
englishMap.set("Доступны все функции системы", "All system functions available");
englishMap.set("Нет доступных функций", "No functions available");
englishMap.set("Доступны все типы оборудования системы", "All system device types available");
englishMap.set("Нет доступных типов оборудования", "No device types available");
englishMap.set("Доступны все группы оборудования системы", "All system device groups available");
englishMap.set("Нет доступных групп оборудования", "No device groups available");
englishMap.set("Функции интерфейса", "Interface functions"); // UI? !!!

// DetailsTabName.jsx
englishMap.set("ID пользователя", "User ID");
englishMap.set("Фамилия", "Surname");
englishMap.set("Имя", "Name");
englishMap.set("Отчество", "Patronymic"); // Second name? !!!
englishMap.set("Номер телефона", "Phone number");
englishMap.set("Почта", "Email");
englishMap.set("Email", "Email");

// ModalStepLocations.jsx
englishMap.set("Доступные локации", "Available locations");

// UserCreateModals/ModalStepDefault
englishMap.set("Должность", "Job title");
englishMap.set("Особые отметки", "Special marks");
englishMap.set("Выберите группу", "Select group");
englishMap.set("Для смены группы необходимо выбрать роль!", "Select role to change group!");
englishMap.set("Необходимо выбрать группу пользователей", "Please select user group");
englishMap.set("Наследовать права группы", "Inherit group rights");

// ModalStepRoles.jsx
englishMap.set("Без роли", "Without role");

// ReplacePropertiesModal.jsx
englishMap.set("Заменить Роль и Локацию пользователя на", "Replace user's Role and Location with");
englishMap.set("действующие в выбранной группе?", "those from the selected group?");
englishMap.set("Заменить", "Replace");
englishMap.set("Оставить текущие", "Leave current");

// userGroupCreateModals/ModalStepDefault.jsx
englishMap.set("Название группы пользователей", "User group name");
englishMap.set("Название группы", "Group name");

// UpdateAllUsersModal.jsx
englishMap.set("К каким пользователям группы необходимо", "To which group users should");
englishMap.set("применить измененные Роль и Локацию?", "the changed Role and Location applied?");
englishMap.set("Ко всем", "To all");
englishMap.set("С включенным наследованием", "With inheritance enabled");

// UsersGroupModal.jsx
englishMap.set("Создать группу пользователей", "Create user group");
englishMap.set("Обновить группу пользователей", "Update user group");

// Desktops.jsx
englishMap.set("Ошибка получения рабочих столов", "Failed to get desktops");
englishMap.set("Ошибка получения рабочего стола", "Failed to get desktop");
englishMap.set("Рабочий стол сохранен", "Desktop saved");
englishMap.set("Не хватает прав для сохранения рабочего стола", "Not enough rights to save desktop");
englishMap.set(
    "В одном контексте рабочие столы должны иметь уникальные имена",
    "Desktops must have unique names in same context"
);
englishMap.set("Рабочий стол", "Desktop");
englishMap.set("Не удалось удалить рабочий стол", "Failed to delete desktop");
englishMap.set("Рабочий стол сохранен", "Desktop saved");
englishMap.set("Не хватает прав для сохранения рабочего стола", "Not enough rights to save desktop");
englishMap.set("Не удалось сохранить рабочий стол", "Failed to save desktop");
englishMap.set("Вы хотите удалить рабочий стол", "You are going to delete desktop");
englishMap.set("Удалённый рабочий стол нельзя восстановить. Продолжить?", "Deleted desktop cannot be restored");
englishMap.set("Выбрать рабочий стол", "Select desktop");
englishMap.set("Новый рабочий стол", "New desktop");

// Presets.jsx
englishMap.set("Неизвестный тип операции:", "Unknown operation type:");
englishMap.set("Для загрузки пресетов камера должна иметь ID!", "Camera must have an ID to load presets!");
englishMap.set("Ошибка получения пресетов", "Failed to get presets!");
englishMap.set("Управление камерой заблокировано", "Camera control is blocked");
englishMap.set("Не удалось проверить приоритет пользователя", "Failed to check user priority");
englishMap.set("Выбрать пресет", "Select preset");
englishMap.set("Создать пресет", "Create preset");

// Ptz.jsx
englishMap.set("Не удалось найти сервис с кодом", "Failed to find service with code");
englishMap.set("и фичей", "and feature");
englishMap.set("Неизвестная ptz команда", "Unknown ptz command");
englishMap.set("Невозможно изменить зум камеры", "Cannot change camera zoom");
englishMap.set("Команда", "Command");
englishMap.set("отсутствует у адаптера", "is not defined in adapter");
englishMap.set("Захват управления сброшен", "Control capture reset");
englishMap.set("Не удалось сбросить управление", "Failed to reset control");
englishMap.set("Сброс", "reset");
englishMap.set("Зум", "Zoom");

// StreamPlayer.jsx
englishMap.set("Ошибка подключения к устройству", "Failed to connect to device");
englishMap.set("Неподдерживаемый кодек трансляции для устройства", "Unsupported broadcast codec for device");
englishMap.set("Таймаут подключения к трансляции для устройства", "Broadcast connection timeout for device");
englishMap.set("Ошибка подключения к трансляции для устройства", "Broadcast connection error for device");
englishMap.set("Неизвестная ошибка подключения для устройства", "Unknown connection error for device");
englishMap.set("Ошибка получения URL трансляции", "Failed to get broadcast URL");
englishMap.set("Пресет удален", "Preset deleted");
englishMap.set("Не хватает прав для удаления пресета", "Not enough rights to delete preset");
englishMap.set("Не удалось удалить пресет", "Failed to delete preset");
englishMap.set("Вы хотите удалить пресет", "You are going to delete preset");
englishMap.set("Удалённый пресет нельзя восстановить. Продолжить?", "Deleted preset cannot be restored. Continue?");

// stream/Toolbar.jsx
englishMap.set("Ошибка при загрузке пресета", "Failed to load preset");
englishMap.set("Отправка PTZ камере...", "Sending PTZ to camera...");
englishMap.set("Пресет применен", "Preset applied");
englishMap.set("Невозможно установить новое положение камеры", "Cannot set new camera position");

// CreateDesktopModal.jsx
englishMap.set("Передан пустой объект", "Empty object passed");
englishMap.set("Имя рабочего стола не может быть пустым", "Desktop name cannot be empty");
englishMap.set("Необходимо выбрать тип рабочего стола", "Please select desktop type ");
englishMap.set("Необходимо выбрать хотя бы одну группу пользователей", "Please select at least one user group");
englishMap.set("Выбрать тип рабочего стола", "Select desktop type");
englishMap.set("Тип рабочего стола", "Desktop type");
englishMap.set("Нет прав для просмотра групп пользователей!", "Not enough rights to view user groups");

// CreatePresetModal,jsx
englishMap.set("Пресет создан", "Preset created");
englishMap.set("Не хватает прав для создания пресета", "Not enough rights to create preset");
englishMap.set(
    "В одном пространстве должны быть пресеты с разными именами",
    "Presets must have unique names in the same environment"
);
englishMap.set("Не удалось создать пресет", "Failed to create preset");
englishMap.set("Отсутствуют настройки PTZ", "PTZ settings missing");
englishMap.set("Невозможно получить позицию камеры", "Failed to get camera position");
englishMap.set("отсутствует у адаптера", "is missing from adapter");
englishMap.set("Загрузка текущей позиции камеры...", "Loading current camera position...");

// CreatePresetModal.jsx
englishMap.set("Pan", "Панорамирование");
englishMap.set("Tilt", "Наклон");
englishMap.set("Имя пресета не может быть пустым", "Preset name cannot be empty");
englishMap.set("Необходимо выбрать тип пресета", "Please select preset type");
englishMap.set("Тип пресета", "Preset type");

// UpdateDesktopModal.jsx
englishMap.set("Сохранить изменения для рабочего стола", "Save changes for desktop");

// UpdatePresetModal.jsx
englishMap.set("Не удалось сохранить пресет", "Failed to save preset");
englishMap.set("Сохранить изменения для пресета", "Save changes for preset?");

// pages.js
englishMap.set("Отчеты", "Reports");
englishMap.set("Карта", "Map");
englishMap.set("Фотогалерея", "Gallery");

// DialogNotified.jsx
englishMap.set("Неизвестный тип диалога:", "Unknown dialog type:");

// archiveErrorMessages.js
englishMap.set("Отсутствует архивная запись для", "Archive record is missing for");
englishMap.set("Закончилась или отсутствует архивная запись для", "Archive record is ended or missing for");
englishMap.set(
    "Начало архивной трансляции, перемотка не возможна",
    "It's archive record start, rewinding is not possible"
);

// license.js
englishMap.set("Лицензия успешно загружена", "License is successfully loaded");
englishMap.set("Невалидный файл лицензии", "Invalid license file");
englishMap.set("Не удалось загрузить файл лицензии", "Failed to load license file");

// TextUtils.js
englishMap.set("пользователей", "users");
englishMap.set("пользователь", "user(s)");
englishMap.set("пользователю", "user(s)");
englishMap.set("пользователя", "users");
englishMap.set("пользователям", "users");
englishMap.set("функций", "functions");
englishMap.set("Функция", "Function");
englishMap.set("функция", "function(s)");
englishMap.set("функции", "functions");
englishMap.set("устройства", "devices");
englishMap.set("устройств", "devices");
englishMap.set("устройствам", "devices");
englishMap.set("устройству", "device");
englishMap.set("тегов", "tags");
englishMap.set("тега", "tag");
englishMap.set("типа", "type");
englishMap.set("типов", "types");
englishMap.set("следуют", "follow");
englishMap.set("следует", "follow");

// Core.jsx
englishMap.set("Неизвестный тип сообщения", "Unknown message type");

// DeviceLocationFilter.jsx
englishMap.set("Неверные данные для cmdFilterBySingleDevice", "Wrong data for cmdFilterBySingleDevice");
englishMap.set("Загрузка дерева", "Loading tree");
englishMap.set("Источник", "Source");

// DropDownFilter.jsx
englishMap.set("Выбрать фильтр событий", "Select event filter");
englishMap.set("Новый фильтр событий", "New event filter");
englishMap.set("Сбросить фильтры", "Clear filter");

// CameraFrames.jsx
englishMap.set("с", "s");
englishMap.set("интервал", "interval");
englishMap.set("скорость", "frame rate");
englishMap.set("По времени", "By time");
englishMap.set("По камерам", "By devices");

// settings/sidebar.jsx
englishMap.set("Системные логи", "System logs");
englishMap.set("System logs", "System logs");

// ModalSteps.jsx
englishMap.set("Продолжить", "Continue");

//ModalStepAvailability.jsx
englishMap.set("шт.", "pcs.");
englishMap.set("мс", "ms");
englishMap.set("с.", "s.");

// ModalStepCameraConnection
englishMap.set("RTSP URL", "RTSP URL");

// DeviceTypesDetails.jsx
englishMap.set("Основные поля", "Main fields");

// Dahua fields adapter
englishMap.set("Ip адрес Dahua", "Dahua IP address");
englishMap.set("Порт Dahua", "Dahua port");
englishMap.set("Логин для авторизации в Dahua", "Dahua authorization login");
englishMap.set("Пароль для авторизации в Dahua", "Dahua authorization password");
englishMap.set("Идентификатор камеры в Dahua", "Dahua camera ID");
englishMap.set("Транспортный протокол RTSP", "Transport protocol RTSP");
englishMap.set("Номер трансляции камеры на хосте", "Camera broadcast number on host");
englishMap.set("Ip Dahua", "Ip Dahua");
englishMap.set("Stream Type", "Stream Type");
englishMap.set("Port", "Port");
englishMap.set("Login", "Login");
englishMap.set("Password", "Password");
englishMap.set("RTSP Api Port", "RTSP Api Port");
englishMap.set("Camera Channel", "RTSP Api Port");

// Hikvision fields adapter
englishMap.set("IP адрес Hikvision рекордера", "Hikvision recorder IP address ");
englishMap.set("Порт Hikvision рекордера", "Hikvision recorder port");
englishMap.set("Логин для авторизации в Hikvision рекордере", "Authorization login in Hikvision recorder");
englishMap.set("Пароль для авторизации в Hikvision рекордере", "Authorization password in Hikvision recorder");
englishMap.set("Порт энкодера (камеры)", "Encoder (camera) port");
englishMap.set("Порт АПИ RTSP рекордера Hikvision", "Hikvision recorder RTSP API port");
englishMap.set("Тип передачи", "Transfer type");
englishMap.set("Ip Hikvision рекордера", "Ip Hikvision recorder");
englishMap.set("Порт Hikvision рекордера", "Порт Hikvision рекордера");

// ISS fields adapter
englishMap.set("Ip ISS", "IP ISS");
englishMap.set("Ip адрес ISS", "ISS IP address");
englishMap.set("Порт ISS", "ISS port");
englishMap.set("Логин для авторизации в ISS", "ISS authorization login");
englishMap.set("Пароль для авторизации в ISS", "ISS authorization password");
englishMap.set("Идентификатор камеры в ISS", "ISS camera id");
englishMap.set("Порт АПИ RTSP сервера ISS", "ISS host RTSP API port");
englishMap.set("Часовой пояс сервера ISS", "ISS host timezone");
englishMap.set("Camera Id", "Camera Id");
englishMap.set("Rtsp Api Port", "Rtsp Api Port");
englishMap.set("Rtsp transport", "Rtsp transport");
englishMap.set("TimeZone", "TimeZone");

// InOne fields adapter
englishMap.set("RTSP ссылка на трансляцию камеры", "Camera broadcast RTSP link");
englishMap.set("Подтверждение записи архива", "Archive recording confirmation");
englishMap.set("Глубина хранения видеоархива в ГБ", "Video archive storage depth in GB");
englishMap.set("Запись архива", "Archive recording");
englishMap.set("Глубина хранения архива", "Archive storage depth");
englishMap.set("RTSP Transport", "RTSP Transport");
englishMap.set("Client Protocol", "Client Protocol");
englishMap.set("Ориентировочная глубина записи", "Approximate recording depth");
englishMap.set("ГБ", "Gb");
englishMap.set("Дн", "Days");
englishMap.set("Количество кадров в секунду", "Frames per second");
englishMap.set("Битрейт - Мбит/с", "Bitrate - Mbit/s");
englishMap.set("Типовое количество кадров в секунду", "Typical frames per second");
englishMap.set("Типовой битрейт", "Typical bitrate");
englishMap.set("Поле не может быть равно 0", "The field cannot be equal to 0");

// Findface events types
englishMap.set("События адаптера FindFace", "FindFace adapter event");
englishMap.set("Событие адаптера FindFace лицо распознано", "FindFace event. Face recognized");
englishMap.set("Адаптер сервиса FindFace", "FindFace service adapter");
englishMap.set("Фильтры адаптера FindFace", "FindFace adapter filters");
englishMap.set("Название списка", "List name");
englishMap.set("ID досье", "Dossier ID");
englishMap.set("Название досье", "Dossier name");
englishMap.set("Комментарий из досье", "Dossier comment");
englishMap.set("Процент схожести", "Similarity percent");

// network availability adapter
englishMap.set("Превышение допустимого времени ожидания", "Exceeding the permissible waiting time");
englishMap.set("Адаптер сетевой доступности", "Network availability adapter");
englishMap.set("События адаптера сетевой доступности", "Network availability adapter events");
englishMap.set("Превышение предельного времени ожидания", "Waiting time limit exceeded");
englishMap.set("Устройство доступно", "Device available");
englishMap.set("Тревожное", "Danger");
englishMap.set("Требующее внимания", "Warning");
englishMap.set("день", "day");
englishMap.set("дня", "days");
englishMap.set("дней", "days");
englishMap.set("час", "hour");
englishMap.set("часа", "hours");
englishMap.set("часов", "hours");
englishMap.set("минута", "minute");
englishMap.set("минуты", "minutes");
englishMap.set("минут", "minutes");
englishMap.set("Несколько секунд", "A few seconds");
englishMap.set("Название правила проверки доступности", "Availability rule name");
englishMap.set("Допустимый процент нарушений по правилу", "Rule's permissible waiting time");
englishMap.set("Проверка отправки icmp - пакетов", "icmp packets ping");
englishMap.set("Пакетов отправлено", "Packets sent");
englishMap.set("Пакетов получено", "Packets received");
englishMap.set("Процент потерь", "Loss percentage");
englishMap.set("Проверка времени отклика", "Response time check");
englishMap.set("Допустимое значение отклика", "Permissible response time");
englishMap.set("Минимальное значение отклика", "Minimum response time");
englishMap.set("Максимальное значение отклика", "Maximum response time");
englishMap.set("Последнее событие", "Last event");
englishMap.set("Время с последнего события", "Time since last event");
englishMap.set("Тип последнего события", "Last event type");

// video intelligence adapter
englishMap.set(
    "События адаптера Видеоинтеллекта. Детектор движения в запрещенной зоне.",
    "Video Intelligence Adapter Events. Motion detector in restricted area."
);
englishMap.set(
    "Событие адаптера Видеоинтеллекта. Детектор движения в запрещенной зоне.",
    "Video Intelligence Adapter Event. Motion detector in restricted area."
);
englishMap.set(
    "Событие адаптера Видеоинтеллекта. Детектор оставленных предметов.",
    "Video Intelligence adapter event. Abandoned object detector."
);
englishMap.set(
    "События адаптера Видеоинтеллекта. Детектор оставленных предметов.",
    "Video Intelligence adapter events. Abandoned object detector."
);
englishMap.set(
    "Событие адаптера Видеоинтеллекта. Детектор оставленных предметов на улице.",
    "Video Intelligence adapter event. Detector of objects left on the street."
);
englishMap.set(
    "События адаптера Видеоинтеллекта. Детектор оставленных предметов на улице.",
    "Video Intelligence adapter events. Detector of objects left on the street."
);
englishMap.set(
    "Событие адаптера Видеоинтеллекта. Детектор движения в запрещенном направлении.",
    "Video Intelligence adapter event. Motion detector in a prohibited direction."
);
englishMap.set(
    "События адаптера Видеоинтеллекта. Детектор движения в запрещенном направлении.",
    "Video Intelligence adapter events. Motion detector in a prohibited direction."
);
englishMap.set(
    "Событие адаптера Видеоинтеллекта. Детектор распознавания лиц.",
    "Video Intelligence adapter event. Face recognition detector."
);
englishMap.set(
    "События адаптера Видеоинтеллекта. Детектор распознавания лиц.",
    "Video Intelligence adapter events. Face recognition detector."
);
englishMap.set(
    "Событие адаптера Видеоинтеллекта. Детектор саботажа камеры.",
    "Video Intelligence adapter event. Camera sabotage detector."
);
englishMap.set(
    "События адаптера Видеоинтеллекта. Детектор саботажа камеры.",
    "Video Intelligence adapter events. Camera sabotage detector."
);
englishMap.set("События адаптера Видеоинтеллекта", "Video Intelligence adapter events");
englishMap.set("Адаптер Видеоинтеллекта", "Video Intelligence adapter");

// ISS events
englishMap.set("События адаптера видеоаналитики ISS", "ISS video analytics adapter events");
englishMap.set("Адаптер видеоаналитики автомобилей сервиса ISS", "ISS service vehicles video analytics adapter");
englishMap.set("Адаптер видеоаналитики лиц сервиса ISS", "ISS service face video analytics adapter");
englishMap.set("Событие адаптера ISS лицо обнаружено", "ISS Adapter Event. Face Detected");
englishMap.set("Событие адаптера ISS номер распознан", "ISS Adapter Event. License plate Detected");

// user events
englishMap.set("Пользовательское событие", "User event");
englishMap.set("Пользовательские события", "User events");
englishMap.set("Адаптер пользовательских событий", "User events adapter");

// camera events
englishMap.set("Событие адаптера камер", "Camera adapter event");
englishMap.set("События адаптера камер", "Camera adapter events");
englishMap.set("События адаптера", "Adapter events");
englishMap.set("Адаптер видеокамеры", "Camera adapter");

// System variables
englishMap.set(
    "Максимальный размер файла, который можно прикрепить к пользовательскому событию (МБ)",
    "Max file size that can be attached to user event (MB)"
);
englishMap.set("Максимальный размер прикрепляемых файлов к событию", "Max file size attached to event");
englishMap.set("Обновление журнала событий", "Event log refresh");
englishMap.set(
    "Периодичность обновления журнала событий в секундах.",
    "The frequency of updating the event log in seconds."
);
englishMap.set("Таймаут приоритета Ptz", "PTZ priority timeout");
englishMap.set(
    "Значение в секундах - через какое время приоритет теряет силу без активности.",
    "Value in seconds - after what time the priority expires without activity."
);
englishMap.set("Время сбора статистики", "Statistics collection time");
englishMap.set(
    "Время сбора статистики проверки качества видеоизображений в секундах.",
    "Time for collecting video quality check statistics in seconds."
);
englishMap.set("Отступ от границ архива", "Indentation from archive boundaries");
englishMap.set(
    "Отступ от границ архива в карточке события в секундах.",
    "The distance from the archive boundaries in the event card in seconds."
);
englishMap.set("Перезапуск трансляций по таймауту", "Broadcasts restart timeout");
englishMap.set(
    "Максимальное количество перепокдлючений трансляций остановленных по таймауту. При значении 0 перезапуск трансляции отключен.",
    "The maximum number of reconnections of broadcasts stopped by timeout. If set to 0, broadcast restart is disabled."
);
englishMap.set("Ограничение размера файлов", "File size limit");
englishMap.set("Ограничение размера файлов, прикрепляемых к событию.", "Size limit of files attached to an event");
englishMap.set("Количество событий в карточке", "Number of events in card");
englishMap.set(
    "Количество последних событий, выводимых в карточке объекта.",
    "Number of recent events displayed in object card"
);
englishMap.set(
    "Временной интервал для индикации событий на планах [мин.]",
    "Time interval in minutes for events indication on plan."
);
englishMap.set(
    "Временной интервал для индикации событий на планах [мин.].",
    "Time interval in minutes for events indication on plan."
);
englishMap.set("Частота проверки невыполненных заданий", "Frequency of checking unfinished tasks");
englishMap.set(
    "Периодичность проверки невыполненных заданий в секундах.",
    "The frequency of checking unfinished tasks in seconds."
);
englishMap.set("Количество попыток выполнения заданий", "Execute tasks tries count");
englishMap.set(
    "Сколько раз система будет пытаться выполнить задания.",
    "How many times the system attempts to execute tasks."
);
englishMap.set("Максимальное количество одновременных проверок", "Max number of simultaneous checks");
englishMap.set(
    "Максимальное количество одновременных проверок (по количеству проверяемых устройств) в одном экземпляре сервиса.",
    "How many devices will be checked in the same time in the single service instance"
);
englishMap.set("Количество проверок для создания события", "Checks count for event creation");
englishMap.set("Процент попыток для изменения состояния", "Attempts percent for state change");
englishMap.set("Интервал опроса в секундах", "Polling interval in seconds");
englishMap.set("Количество пакетов на отправку", "Number of packets to send");
englishMap.set("Количество отправляемых пакетов", "Sending packets count");
englishMap.set(
    "Временной интервал для индикации событий на картах [мин.]",
    "Time interval for events indication on maps [min.]"
);
englishMap.set(
    "Временной интервал для индикации событий на картах [мин.].",
    "Time interval for events indication on maps [min.]."
);
englishMap.set("Тревожный отклик устройства", "Device danger response");
englishMap.set("Тревожный отклик устройства (мс)", "Device danger response (ms)");
englishMap.set("Критический отклик устройства", "Device critical response");
englishMap.set("Критический отклик", "Critical response (ms)");
englishMap.set("Макс. отклонение", "Max deviation");
englishMap.set("Максимальное отклонение (процент)", "Maximum deviation (percent)");
englishMap.set("Остановка трансляции архива по таймауту", "Archive broadcast stop by timeout");
englishMap.set(
    "Сколько минут плеер должен быть неактивен, чтобы трансляция остановилась",
    "How many minutes player must be inactive to stop broadcast"
);
englishMap.set("Остановка трансляций по таймауту", "Live broadcasts stop by timeout ");

// UsersSettings.jsx
englishMap.set("Пользователя", "User");
englishMap.set("Группу пользователей", "User group");

// DeviceTypes
englishMap.set("Камера InOne", "InOne camera");
englishMap.set("Камера Dahua", "Dahua camera");
englishMap.set("Камера Hikvision", "Hikvision camera");
englishMap.set("Камера ISS", "ISS camera");

//Schedules.jxs
englishMap.set("Нельзя создать расписание без имени", "Failed to create schedule without name");
englishMap.set("Нельзя создать расписание без вида", "Failed to create schedule without type");
englishMap.set("Нельзя создать расписание без типа", "Failed to create schedule without type");
englishMap.set("Создание расписания", "Schedule creating");
englishMap.set("Вы хотите удалить расписание", "You are going to delete an schedule");
englishMap.set(
    "Удалённое расписание нельзя восстановить. Продолжить?",
    "A deleted schedule cannot be restored. Continue?"
);
englishMap.set(" расписание", " schedule");

//SchedulesList.jxs
englishMap.set("Расписания", "Schedules");
englishMap.set("Активно", "Active");
englishMap.set("Завершено", "Completed");
englishMap.set("Приостановлено", "Paused");

englishMap.set("Дата начала", "Start date");
englishMap.set("Дата завершения", "End date");

// ScheduleDetails.jsx
englishMap.set("Расписание", "Schedule");
englishMap.set("Однократное", "One-time");
englishMap.set("Повторение", "Repetition");
englishMap.set("Повтор каждые", "Repetition");
englishMap.set("Точное время", "Exact time");
englishMap.set("приостановлено", "has been suspended");
englishMap.set("возобновлено", "has been resumed");
englishMap.set("удалено", "has been deleted");
englishMap.set("Бесконечное расписание", "Endless schedule");
englishMap.set("Повтор каждый", "Repeat every");

// other
englishMap.set("Выберите приоритет", "Select priority");
englishMap.set("Доступ к разделу 'Видеоархив'", "Access to 'Archive' section");
englishMap.set("Создание заказа записи архивной трансляции", "Create archive record order");
englishMap.set("Доступ к разделу 'События'", "Access to 'Events' section");
englishMap.set("Конвертация архива", "Archive conversion");
englishMap.set("Просмотр локаций", "View locations");
englishMap.set("Просмотр оборудования", "View devices");
englishMap.set("PTZ-управление камерой", "Control PTZ cameras");
englishMap.set("Создание пользовательских событий", "Create user events");
englishMap.set("Просмотр адаптеров", "View adapters");
englishMap.set("Блокировка управления/перехват управления", "Control blocking/control interception");
englishMap.set("Приоритеты пользователей", "User priorities");
englishMap.set("Создание и изменение ролей", "Create and edit roles");
englishMap.set("Создание и изменение разделов", "Create and edit sections");
englishMap.set("Просмотр ролей", "View roles");
englishMap.set("Просмотр разделов", "View sections");
englishMap.set('Доступ к реестру "Лицензии"', 'Access to "Licenses" registry');
englishMap.set("Загрузка лицензии", "License upload");
englishMap.set("Создание и изменение переменных", "Create and edit variables");
englishMap.set("Просмотр переменных", "View variables");
englishMap.set("Создание и изменение локаций", "Create and edit locations");
englishMap.set("Создание и изменение оборудования", "Create and edit devices");
englishMap.set("Создание и изменение правил", "Create and edit rules");
englishMap.set("Просмотр правил", "View rules");
englishMap.set("Создание и изменение групп оборудования", "Create and edit device groups");
englishMap.set("Просмотр групп оборудования", "View device groups");
englishMap.set('Доступ к реестру "Сценарии"', 'Access to "Scenarios" registry');
englishMap.set("Создание и изменение сценариев", "Create and edit scenarios");
englishMap.set("Просмотр сценариев", "View scenarios");
englishMap.set("Изменение статуса сценариев", "Change scenarios status");
englishMap.set("Сценарии", "Scenarios");
englishMap.set("Создание и изменение шаблонов", "Create and edit templates");
englishMap.set("Просмотр шаблонов", "View templates");
englishMap.set("Создание и изменение пользователей", "Create and edit users");
englishMap.set("Просмотр пользователей", "View users");
englishMap.set("Создание и изменение групп пользователей", "Create and edit users groups");
englishMap.set("Просмотр групп пользователей", "View users groups");
englishMap.set("Доступ к разделу 'Системные логи'", "Access to 'System logs' section");
englishMap.set('Доступ в раздел "Настройки"', 'Access to "Settings" section');
englishMap.set('Доступ к справочнику "Типы событий"', 'Access to "Event types" directory');
englishMap.set("Просмотр пресетов", "View presets");
englishMap.set("Создание и изменение персональных пресетов", "Create and edit personal presets");
englishMap.set("Создание и изменение групповых пресетов", "Create and edit group presets");
englishMap.set("Создание и изменение системных пресетов", "Create and edit system presets");
englishMap.set("Пользовательские пресеты", "User presets");
englishMap.set("Доступ к разделу 'Видеонаблюдение'", "Access to 'CCTV' section");
englishMap.set("Просмотр рабочих столов", "View desktops section");
englishMap.set("Создание и изменение персональных рабочих столов", "Create and edit personal desktops");
englishMap.set("Создание и изменение групповых рабочих столов", "Create and edit group desktops");
englishMap.set("Создание и изменение системных рабочих столов", "Create and edit system desktops");
englishMap.set("Пользовательские рабочие столы", "User desktops");
englishMap.set("Доступ к разделу 'Планы'", "Access to 'Plans' section");
englishMap.set("Просмотр типов событий", "View event types");
englishMap.set("Просмотр фильтров", "View filters");
englishMap.set("Создание и изменение персональных фильтров", "Create and edit personal filters");
englishMap.set("Создание и изменение групповых фильтров", "Create and edit group filters");
englishMap.set("Создание и изменение системных фильтров", "Create and edit system filters");
englishMap.set(
    "Создание и изменение групповых фильтров для других групп",
    "Create and edit group filters for other groups"
);
englishMap.set("Просмотр зон обзора камер", "View cameras' viewing zones");
englishMap.set("Изменение зон обзора камер", "Edit cameras' viewing zones");
englishMap.set("Зоны обзора", "Edit cameras' viewing zones");
englishMap.set("Просмотр события для себя", "View event for myself");
englishMap.set("Просмотр события для группы пользователей", "View event for user group");
englishMap.set("Просмотр события для всех пользователей", "View event for all group");
englishMap.set("Просмотр события", "View event");
englishMap.set("Доступ к разделу 'Карта'", "Access to 'Map' section");
englishMap.set("Доступ к разделу 'Фотогалерея'", "Access to 'Gallery' section");
englishMap.set("Создание и изменение заказа фотоизображений", "Create and edit images order");
englishMap.set("Создание и изменение персонального заказа фотоизображений", "Create and edit personal images order");
englishMap.set("Создание и изменение группового заказа фотоизображений", "Create and edit group images order");
englishMap.set(
    "Создание и изменение группового заказа фотоизображений для других групп",
    "Create and edit group images order for other groups"
);
englishMap.set("Создание и изменение системного заказа фотоизображений", "Create and edit system images order");
englishMap.set("Просмотр заказов фотоизображений", "View images orders");
englishMap.set("Просмотр персональных заказов фотоизображений", "View personal images orders");
englishMap.set("Просмотр групповых заказов фотоизображений", "View group images orders");
englishMap.set("Просмотр системных заказов фотоизображений", "View system images orders");
englishMap.set("Просмотр истории заказов архивов фотоизображений", "View images archive orders history");
englishMap.set("Создание и изменение зон контроля", "Create and edit control zones");
englishMap.set("Создание и изменение групп зон контроля", "Create and edit control zones groups");
englishMap.set("Доступ к разделу 'Отчеты'", "Access to 'Reports' section");
englishMap.set("Доступ к отчету 'Сетевая доступность'", "Access to 'Network availability' report");
englishMap.set("Доступ к отчету 'Аудит'", "Access to 'Audit' report");
englishMap.set("Проверка качества RTSP", "RTSP quality check");
englishMap.set("Доступ к отчету 'Проверка качества видеоизображений'", "Access to 'Video quality check' report");
englishMap.set("Просмотр заказов записей", "Watch records order");
englishMap.set("Просмотр персональных заказов записей", "Watch personal records order");
englishMap.set("Просмотр групповых заказов записей", "Watch group records order");
englishMap.set("Просмотр системных заказов записей", "Watch system records order");
englishMap.set("Создание и изменение заказа записей", "Create and edit records order");
englishMap.set("Создание и изменение персонального заказа записей", "Create and edit personal records order");
englishMap.set("Создание и изменение группового заказа записей", "Create and edit group records order");
englishMap.set(
    "Создание и изменение группового заказа записей для других групп",
    "Create and edit group records order for other groups"
);
englishMap.set("Создание и изменение системного заказа записей", "Create and edit system records order");
englishMap.set("Шаблоны выполнения", "Scenario templates");
englishMap.set("Сетевая доступность", "Network availability");
englishMap.set("Фильтры", "Filters");
englishMap.set("Адаптер ONVIF-PTZ", "ONVIF-PTZ adapter");
englishMap.set("Обновить", "Refresh");
englishMap.set(
    "Создание и изменение групповых пресетов для других групп",
    "Create and edit group presets for other groups"
);
englishMap.set(
    "Создание и изменение групповых рабочих столов для других групп",
    "Create and edit group desktops for other groups"
);
englishMap.set("Отмена", "Cancel");
englishMap.set("Дата фиксации", "Fixation date");
englishMap.set("Дата события", "Event date");
englishMap.set("Уверенность", "Confidence");
englishMap.set("Пол", "Sex");
englishMap.set("Возраст", "Age");
englishMap.set("Национальность", "Nationality");
englishMap.set("Борода и усы", "Beard and mustaches");
englishMap.set("Очки", "Glasses");
englishMap.set("Прическа", "Haircut");
englishMap.set("Цвет волос", "Hair color");
englishMap.set("Головной убор", "Headdress");
englishMap.set("Информация о событии", "Event info");
englishMap.set("Важность", "Severity");
englishMap.set("ещё интервал", "add one more interval");
englishMap.set("Конец", "End");
englishMap.set("Начало", "Start");
englishMap.set("Прикрепленные файлы", "Attached files");
englishMap.set("Общий план", "General plan");
englishMap.set("Адрес для проверки сетевой доступности", "Address to check network availability");
englishMap.set("Адаптер камеры Dahua", "Dahua camera adapter");
englishMap.set("Адаптер камеры Hikvision", "Hikvision camera adapter");
englishMap.set("Адаптер камеры ISS", "ISS camera adapter");
englishMap.set("Адаптер камеры Axxon Next", "Axxon Next camera adapter");
englishMap.set("Логин не может быть пустым", "Login cannot be empty");
englishMap.set("Пароль не может быть пустым", "Password cannot be empty");
englishMap.set("Логин камеры", "Camera login");
englishMap.set("Адрес камеры", "Camera address");
englishMap.set("Пароль камеры", "Camera password");
englishMap.set("Инверсия управления", "Control inversion");
englishMap.set("Имя локации:", "Location name");
englishMap.set("Дополнительно", "Extra");
englishMap.set("Заметка", "Note");
englishMap.set("Количество чего-то", "Amount of something");
englishMap.set("Количество не может быть пустым", "Amount cannot be empty");
englishMap.set("Идентификатор", "ID");
englishMap.set("Действие", "Action");
englishMap.set("Свойства", "Properties");
englishMap.set("Не удалось выгрузить файл", "Failed to upload file");
englishMap.set("Не удалось загрузить файл", "Failed to download file");
englishMap.set("камеры", "of camera");
englishMap.set("из сервиса", "from service");
englishMap.set("на конечное хранилище", "to end storage");
englishMap.set("Не удалось авторизоваться на устройстве", "Failed to authorize on device");
englishMap.set("Очистить рабочий стол", "Clear desktop");
englishMap.set("Да", "Yes");
englishMap.set("Нет", "No");
englishMap.set("Камеры", "Cameras");
englishMap.set("Обычное", "Primary");
englishMap.set("Нет сущности", "No entity");
englishMap.set("у камеры", "in camera");
englishMap.set("ffmpeg не загружен", "ffmpeg is not loaded");
englishMap.set("Закончились пересечения архивных записей за", "No more intersections of archive records for");
englishMap.set("Таб с заметкой", "Tab with note");
englishMap.set("Управление камерой", "Camera controls");
englishMap.set("Доступ к раделу 'Расписания'", "Access to 'Schedules' section");
englishMap.set("Создание и изменение расписаний", "Create and update schedules");
englishMap.set("Создание и изменение персональных расписаний", "Create and update personal schedules");
englishMap.set("Создание и изменение групповых расписаний", "Create and update group schedules");
englishMap.set("Создание и изменение системных расписаний", "Create and update system schedules");
englishMap.set(
    "Создание и изменение групповых расписаний для других групп",
    "Create and update group schedules for other groups"
);
englishMap.set("Просмотр персональных расписаний", "View personal schedules");
englishMap.set("Просмотр групповых расписаний", "View group schedules");
englishMap.set("Просмотр системных расписаний", "View system schedules");
englishMap.set("Просмотр расписаний", "View  schedules");
englishMap.set("Выбрать вид", "Select kind");

// Control zones
englishMap.set("Группа создана", "Group created");
englishMap.set(
    "Не хватает прав для создания группы зон контроля",
    "Not enough permissions to create control zone group"
);
englishMap.set("Не удалось создать группу зон контроля", "Failed to create control zone group");
englishMap.set("Зона изменена", "Zone updated");
englishMap.set("Группы зон контроля должны иметь уникальные имена", "Control zone groups must have unique names");
englishMap.set("Зона создана", "Zone created");
englishMap.set("Не хватает прав для создания зоны контроля", "Not enough permissions to create control zone");
englishMap.set("Не удалось создать зону контроля", "Failed to create control zone");
englishMap.set("Не удалось получить зоны контроля", "Failed to get control zones");
englishMap.set("Не удалось получить группы зон контроля", "Failed to get control zones groups");
englishMap.set("Не удалось удалить группу зон контроля", "Failed to delete control zone group");
englishMap.set("Не удалось удалить зону контроля", "Failed to delete control zone");
englishMap.set("Не удалось выполнить поиск по зонам контроля", "Failed to search control zones");
englishMap.set("Нельзя создать группу без имени", "Cannot create group without name");
englishMap.set("Нельзя создать зону без имени", "Cannot create zone without name");
englishMap.set(" зону", " zone");
englishMap.set(" группу", " group");
englishMap.set("Название зоны", "Zone name");
englishMap.set("Зоны контроля", "Control zones");
englishMap.set("Новую зону", "New zone");
englishMap.set("Новую группу зон", "New zone group");
englishMap.set("В группе", "In group");
englishMap.set("В зоне контроля нет устройств", "No devices in control zone");
englishMap.set("Ошибка получения устройств зоны контроля", "Failed to get control zone devices");
englishMap.set("группы зон котроля и зоны", "control zone groups and zones");
englishMap.set("действия", "actions");
englishMap.set("Вы хотите удалить зону контроля", "You are going to delete control zone");
englishMap.set(
    "Удалённую зону контроля нельзя восстановить. Продолжить?",
    "Deleted control zone cannot be restored. Continue?"
);
englishMap.set("Вы хотите удалить группу", "You are going to delete group");
englishMap.set("Удалённую группу нельзя восстановить. Продолжить?", "Deleted group cannot be restored. Continue?");
englishMap.set("Выбрать группу", "Select group");
englishMap.set("Не хватает прав для получения зон контроля", "Not enough permissions to get control zones");
englishMap.set("Не хватает прав для получения групп зон контроля", "Not enough permissions to get control zone groups");
englishMap.set("Не хватает прав для удаления зоны контроля", "Not enough permissions to delete control zone");
englishMap.set(
    "Не хватает прав для удаления группы зоны контроля",
    "Not enough permissions to delete control zone group"
);
englishMap.set("Просмотр групп зон контроля", "View control zone groups");
englishMap.set('Доступ к реестру "Зоны контроля"', 'Access to "Control zones" registry');
englishMap.set("Доступ в реестр", "Access to registry");
englishMap.set("Редактирование/удаление групп зон контроля", "Update/delete control zone groups");
englishMap.set("Просмотр зон контроля", "View control zones");
englishMap.set("Редактирование/удаление зон контроля", "Update/delete control zones");
englishMap.set("Зона успешно удалена", "Zone deleted successfully");
englishMap.set("Группа успешно удалена", "Group deleted successfully");
englishMap.set("Нельзя удалить зону с устройствами", "Cannot delete zone with devices");
englishMap.set("Нельзя удалить группу с зонами", "Cannot delete group with zones");

// next section
englishMap.set("Дата однократного расписания не может быть в прошлом", "One-time schedule date cannot be in the past");
englishMap.set("Нельзя создать расписание без выбранной группы", "Cannot create schedule without selected group");
englishMap.set("Дата начала не может быть в прошлом", "Start date cannot be in the past");
englishMap.set("Дата и время начала", "Start date and time");
englishMap.set("Дата и время завершения", "End date and time");
englishMap.set("Нельзя создать расписание без фильтров!", "Cannot create schedule without filters!");
englishMap.set("Интервал повторения", "Repeat interval");
englishMap.set("Нельзя создать однократное расписание без даты", "Cannot create one-time schedule without date");
englishMap.set("Нельзя создать однократное расписание без времени", "Cannot create one-time schedule without time");
englishMap.set("Дата завершения не может быть в прошлом", "End date cannot be in the past");
englishMap.set("Проверка устройств", "Devices check");
englishMap.set("Проверка качества видеоизображений", "Video quality check");
englishMap.set("Высокая частота кадров", "High frame rate");
englishMap.set("Высокий битрейт", "High bitrate");
englishMap.set("Другое", "Other");
englishMap.set("Критические потери кадров", "Critical frame loss");
englishMap.set("Низкая частота кадров", "Low frame rate");
englishMap.set("Низкий битрейт", "Low bitrate");
englishMap.set("Отсутствует видеопоток", "Video stream missing");
englishMap.set("Повышенная частота кадров", "Increased frame rate");
englishMap.set("Повышенный битрейт", "Increased bitrate");
englishMap.set("Пониженный битрейт", "Reduced bitrate");
englishMap.set("Пониженная частота кадров", "Reduced frame rate");
englishMap.set("Превышение допустимых потерь кадров", "Exceeding permissible frame loss");
englishMap.set("правило проверки качества", "quality check rule");
englishMap.set("диапазоны колебания битрейта", "bitrate fluctuation ranges");
englishMap.set("диапазоны потерь кадров", "frame loss ranges");
englishMap.set("диапазоны колебания частоты кадров", "frame rate fluctuation ranges");
englishMap.set("Создать правило проверки качества видеоизображений", "Create video quality check rule");
englishMap.set("Правило проверки качества видеоизображений", "Video quality check rule");
englishMap.set("Параметры проверки", "Video quality check rule");
englishMap.set("Постоянная проверка", "Constant check");
englishMap.set("Колебание битрейта - %", "Bitrate fluctuation - %");
englishMap.set("Потери кадров - %", "Frame loss - %");
englishMap.set("Допустимое", "Acceptable");
englishMap.set("Предельное", "Limit");
englishMap.set("Колебание частоты кадров - %", "Frame rate fluctuation - %");
englishMap.set("Длительность проверки видеопотока - сек", "Duration of video stream check - sec");
englishMap.set("Частота выполнения проверки - сек", "Check frequency - sec");
englishMap.set("Адаптер провеки качества RTSP", "RTSP quality check adapter");
englishMap.set("События адаптера провеки качества RTSP", "RTSP quality check adapter events");
englishMap.set("Заказ архива", "Archive order");
englishMap.set("Заказ фото", "Screenshot order");
englishMap.set("Событие", "Event");
englishMap.set("Трансляция", "Broadcast");
englishMap.set("Зона обзора", "View zone");
englishMap.set("Адаптер камер ISS", "ISS cameras adapter");
englishMap.set("Адаптер камер FindFace", "FindFace cameras adapter");
englishMap.set("Адаптер камер InOne", "InOne cameras adapter");
englishMap.set("Адаптер камер Видеоинтеллекта", "Videointelligence cameras adapter");
englishMap.set("событие", "event");
englishMap.set("события", "events");
englishMap.set("адаптера", "of adapter");
englishMap.set("Тип формирования состава оборудования", "Equipment composition formation type");
englishMap.set("Динамический список", "Dynamic list");
englishMap.set("Фиксированный список", "Fixed list");
englishMap.set("Тип интервала выполнения", "Execution interval type");
englishMap.set("Автор", "Creator");
englishMap.set("Дата создания", "Creation date");
englishMap.set("Доступность", "Availability");
englishMap.set("Состав полномочий был изменен", "Permission list has changed");
englishMap.set(
    "Интервал отображения границ детального таймлайна в разделе Видеоархив",
    "Interval for displaying the boundaries of a detailed timeline in the Archive section"
);
englishMap.set(
    "Интервал отображения границ детального таймлайна видеоархива",
    "Interval for displaying boundaries of detailed archive timeline"
);
englishMap.set(
    "Интервал отображения границ детального таймлайна фотогалереи",
    "Interval for displaying borders of a detailed Gallery timeline"
);
englishMap.set(
    "Интервал отображения границ детального таймлайна в разделе Фотогалерея",
    "Interval for displaying the boundaries of a detailed timeline in the Gallery section"
);

// reports
englishMap.set("Отчет по аудиту", "Audit report");
englishMap.set("Отчет предоставляемый сервисом аудита", "Report provided by audit service");
englishMap.set("Вход в систему", "Login");
englishMap.set("Выход из системы", "Logout");
englishMap.set("Поворот камеры", "Camera move");
englishMap.set("Подключение устройства к адаптеру", "Device connected to adapter");
englishMap.set("Отключение устройства от адаптера", "Device disconnected from adapter");
englishMap.set("Создание заказа архива", "Create archive order");
englishMap.set("Создание рабочего стола", "Create desktop");
englishMap.set("Редактирование рабочего стола", "Update desktop");
englishMap.set("Удаление рабочего стола", "Delete desktop");
englishMap.set("Создание пользовательского события", "Create user event");
englishMap.set("Создание пресета", "Create preset");
englishMap.set("Редактирование пресета", "Create desktop");
englishMap.set("Удаление пресета", "Delete preset");
englishMap.set("Получение расписания", "Get schedules");
englishMap.set("Получение групп зон контроля", "Get control zones group");
englishMap.set("Успешно", "Success");
englishMap.set("Неуспешно", "Failure");
englishMap.set("Тип действия", "Action type");
englishMap.set("Аутентификация", "Authentication");
englishMap.set("ПТЗ", "PTZ");
englishMap.set("Подключение", "Connection");
englishMap.set("Объект в системе", "Object in system");
englishMap.set("Создание", "Create");
englishMap.set("Получение", "Get");
englishMap.set("Аудит", "Audit");
englishMap.set("Тип пользовательских событий", "User event type");
englishMap.set("Учетная запись", "Account");
englishMap.set("Файл хранилища", "Storage file");
englishMap.set("Группа зон контроля", "Control zone group");
englishMap.set("Пользовательский фильтр", "User filter");
englishMap.set("Фотоизображение", "Screenshot");
englishMap.set("Заказ ковертируемого архива", "Archive converter order");
englishMap.set("Правило сетевой доступности", "Device availability rule");
englishMap.set("Правило качества видеоизображения", "Device video quality rule");
englishMap.set("Удаление", "Delete");
englishMap.set("Редактирование", "Update");

// scheduleReducer.js
englishMap.set("Не хватает прав для создания расписания", "Not enough permissions to create schedule");
englishMap.set("Расписания должны иметь уникальные имена", "Schedules must have unique names");
englishMap.set("Не удалось создать расписание", "Failed to create schedule");
englishMap.set("Нельзя создать расписание без фильтров!", "Cannot create schedule with no filters!");
englishMap.set("Фотоизображения", "Screenshots");
englishMap.set("Вид", "Type");
englishMap.set(
    "Время выполнения расписания не может быть позже времени завершения расписания",
    "Schedule execution time cannot be later than its end"
);

const englishCulture = {
    code: "en",
    default: false,
    strings: englishMap,
};

export default englishCulture;

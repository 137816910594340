import React from "react";
import { DetailsBoxContent, DetailsBoxMedia } from "../../../components/DetailsBox";
import { Text, Button, Row } from "headpoint-react-components";
import { AspectBox } from "../../../components/AspectBox";
import { withRouter } from "react-router-dom";
import { GeneralContextConsumer, permissionExists, serviceExists } from "../../../../contexts";
import { pages } from "../../../../layout/Navbar/pages";
import { EventsLayout } from "../../../../layout/EventsLayout";
import EventDetailsPlan from "./EventDetailsPlan";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class DetailsTabInfo extends React.Component {
    navigateToPlans(path) {
        const { selectedPlan, selectedPoints, points } = this.props;

        this.props.history.push({
            pathname: path,
            state: { type: "ShowPlan", points, selectedPoints, selectedPlan },
        });
    }

    render() {
        let { userInfo, selectedPlan, selectedPoints, points } = this.props;

        const planPage = pages.find((p) => p.url === "/plans");
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => (
                            <DetailsBoxContent>
                                {serviceExists(generalInfo, planPage.serviceCodes) &&
                                permissionExists(userInfo, planPage.permissions) &&
                                !!selectedPlan ? (
                                    <>
                                        <Row marginTop={32} marginBottom={16}>
                                            <Text variant="title" component="h3">
                                                {strings("На плане")}
                                            </Text>
                                        </Row>
                                        <DetailsBoxMedia>
                                            <AspectBox aspect="16:9">
                                                <EventsLayout>
                                                    <EventDetailsPlan
                                                        plan={selectedPlan}
                                                        selected={selectedPoints}
                                                        points={points}
                                                        generalInfo={generalInfo}
                                                        strings={strings}
                                                    />
                                                </EventsLayout>
                                            </AspectBox>
                                        </DetailsBoxMedia>
                                        <Button
                                            variant="secondary"
                                            label={strings("Общий план")}
                                            icon="arrow-right"
                                            isIconAfter
                                            onClick={() => this.navigateToPlans(planPage.url)}
                                        />
                                    </>
                                ) : null}
                            </DetailsBoxContent>
                        )}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withRouter(DetailsTabInfo));

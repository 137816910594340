import { getDataFromSource, getImageStorageUrl } from "../utilites";
import { AspectBox, DetailsBoxContent } from "../../../../components/DetailsBox";
import React from "react";
import FullImageModal from "./FullImageModal";
import styled from "styled-components";

export class ImageBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullImage: false,
        };
    }
    render() {
        const { selectedEvent, image } = this.props;
        const source = getDataFromSource(image.source, selectedEvent);
        if (!source) {
            return null;
        }

        const imageUrl = getImageStorageUrl(source, image.storage);
        return (
            <>
                {this.state.isFullImage ? (
                    <FullImageModal imageUrl={imageUrl} handleClose={() => this.setState({ isFullImage: false })} />
                ) : null}
                <DetailsBoxContent>
                    <AspectBox aspect="16:9">
                        <ImageLink src={imageUrl} alt={imageUrl} onClick={() => this.setState({ isFullImage: true })} />
                    </AspectBox>
                </DetailsBoxContent>
            </>
        );
    }
}

const ImageLink = styled.img`
    cursor: pointer;
`;

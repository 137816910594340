import React from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownDivider,
    Text,
    Tab,
    TabPanel,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    Row,
} from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxContent,
} from "../../../components/DetailsBox";
import { GetScenarios } from "../../../../services/scenarios";
import { CultureContextConsumer, withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { formatRoleFunctionCount } from "../../../../utilites/TextUtils";

class TemplateDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scenarios: [],
        };
    }

    async componentDidMount() {
        const scenarios = await this.getScenarios();
        this.setState({
            scenarios: scenarios,
        });
    }

    matchScenarioTemplates = (selectedTemplate) => {
        return this.state.scenarios.filter((item) => selectedTemplate.id === item.templateId);
    };

    async getScenarios() {
        const [status, scenarios] = await GetScenarios();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения шаблонов выполнения"), { variant: "error" });
        }

        return scenarios.data.scenarios;
    }

    handleCloseDetailedView = () => this.props.setSelectedTemplate();

    DetailsTabFunctions = ({ selectedTemplate }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Row marginTop={24} marginBottom={16}>
                        <Text color="primary" variant="body">
                            {selectedTemplate.functions?.length}{" "}
                            {strings(formatRoleFunctionCount(selectedTemplate.functions?.length))}
                        </Text>
                    </Row>

                    <Table isFixed noPadding>
                        <TableBody>
                            {selectedTemplate.functions?.map((item, i) => (
                                <TableRow key={`${item}${i}`}>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {item.name}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    DetailsTabScenarios = ({ selectedTemplate }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Row marginTop={24} marginBottom={16}>
                        <Text color="primary" variant="body">
                            {`${strings("Шаблон используется в")} ${
                                this.matchScenarioTemplates(selectedTemplate).length
                            } ${strings("сценариях реагирования")}`}
                        </Text>
                    </Row>

                    <Table isFixed noPadding>
                        <TableBody>
                            {this.matchScenarioTemplates(selectedTemplate).map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <Row verticalAlign="middle">
                                            <Text color="primary" variant="body-sm">
                                                <Icon icon="scenario" size={16} />
                                            </Text>
                                            {"\u00A0\u00A0"}
                                            <Text color="primary" variant="body-sm">
                                                {item.name}
                                            </Text>
                                        </Row>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    DetailsTabMain = ({ selectedTemplate }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Table isFixed noPadding>
                        <TableBody>
                            <TableRow>
                                <TableCell>{strings("ID")}</TableCell>
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {selectedTemplate.id}
                                    </Text>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{strings("Комментарий")}</TableCell>
                                <TableCell>
                                    <Text scroll color="primary" variant="body-sm">
                                        {selectedTemplate?.comment}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    render() {
        const { setDetailsTab, selectedTemplate, detailsTab, editTemplate, strings } = this.props;
        return (
            <EventsLayoutSecondary>
                <DetailsBox>
                    <DetailsBoxHeader sticky>
                        <DetailsBoxTitle>
                            <Text color="primary" variant="title" component="h2">
                                {selectedTemplate.name}
                            </Text>
                        </DetailsBoxTitle>

                        <DetailsBoxHeaderActions>
                            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                <DropdownItem as="button" onClick={() => editTemplate(selectedTemplate)}>
                                    {strings("Редактировать")}
                                </DropdownItem>

                                <DropdownDivider />

                                <DropdownItem
                                    as="button"
                                    onClick={() => this.props.deleteTemplate(selectedTemplate)}
                                    colorVariant="danger"
                                >
                                    {strings("Удалить")}
                                </DropdownItem>
                            </Dropdown>

                            <Button icon="close" variant="secondary" onClick={this.handleCloseDetailedView} />
                        </DetailsBoxHeaderActions>
                    </DetailsBoxHeader>

                    <DetailsBoxTabs>
                        <TabPanel>
                            <Tab
                                label={strings("Общее")}
                                isActive={detailsTab === "main"}
                                onClick={() => setDetailsTab("main")}
                            />
                            <Tab
                                label={strings("Функции")}
                                isActive={detailsTab === "functions"}
                                onClick={() => setDetailsTab("functions")}
                            />
                            <Tab
                                label={strings("Сценарии реагирования")}
                                isActive={detailsTab === "scenarios"}
                                onClick={() => setDetailsTab("scenarios")}
                            />
                        </TabPanel>
                    </DetailsBoxTabs>

                    {detailsTab === "main" && this.DetailsTabMain({ selectedTemplate })}

                    {detailsTab === "functions" && this.DetailsTabFunctions({ selectedTemplate })}

                    {detailsTab === "scenarios" && this.DetailsTabScenarios({ selectedTemplate })}
                </DetailsBox>
            </EventsLayoutSecondary>
        );
    }
}

export default withCultureContext(TemplateDetails);

import React from "react";
import { DeviceTable } from "./DeviceTable";
import { Text, Dropdown, DropdownItem, Icon, ExpandableSearch } from "headpoint-react-components";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
} from "../../../../layout/EventsLayout";
import { permissionExists } from "../../../../contexts";
import DeviceDetails from "./details/DeviceDetails";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../CommonTablePagination";
import PubSub from "pubsub-js";
import { SHOW_CREATE_DEVICE, SHOW_CREATE_LOCATION, SHOW_IMPORT_FILE_MODAL } from "./deviceSettingsTopics";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class DeviceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: { start: 0, end: ITEMS_ON_PAGE },
        };
    }
    render() {
        const {
            showCameraPreview,
            previewCameraId,
            selectCameraId,
            sources,
            selectedDevice,
            setSelectedDevice,
            openLocation,
            showLocationHandler,
            setShowCameraPreview,
            hasSecondary,
            search,
            setSearch,
            generalInfo,
            userInfo,
        } = this.props;

        const { pageData } = this.state;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <EventsLayoutPrimary hasSecondary={hasSecondary}>
                            <EventsLayoutHeader>
                                <EventsLayoutHeaderTitle>
                                    <Text variant="title" component="h1">
                                        {strings("Оборудование")}
                                    </Text>
                                    <ExpandableSearch
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        onSubmit={() => {
                                            /* ignore enter */
                                        }}
                                        placeholder={strings("Найти")}
                                        colorVariant="dark"
                                        inHeader
                                    />
                                </EventsLayoutHeaderTitle>

                                {permissionExists(userInfo, [
                                    "locations.location.lifeCycle",
                                    "devices.device.lifeCycle",
                                ]) ? (
                                    <EventsLayoutHeaderToolbox>
                                        <EventsLayoutHeaderToolboxItem>
                                            <Dropdown
                                                toggleLabel={strings("Создать")}
                                                closeOnClickInside
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                {permissionExists(userInfo, "locations.location.lifeCycle") ? (
                                                    <DropdownItem
                                                        as="button"
                                                        colorVariant="grey"
                                                        onClick={() => PubSub.publish(SHOW_CREATE_LOCATION)}
                                                    >
                                                        {strings("Локацию")}
                                                    </DropdownItem>
                                                ) : (
                                                    <Text />
                                                )}
                                                {permissionExists(userInfo, "devices.device.lifeCycle") ? (
                                                    <DropdownItem
                                                        as="button"
                                                        onClick={() => PubSub.publish(SHOW_CREATE_DEVICE)}
                                                        colorVariant="grey"
                                                    >
                                                        {strings("Устройство")}
                                                    </DropdownItem>
                                                ) : (
                                                    <Text />
                                                )}
                                                <DropdownItem
                                                    as="button"
                                                    onClick={() => PubSub.publish(SHOW_IMPORT_FILE_MODAL)}
                                                    colorVariant="grey"
                                                >
                                                    {strings("Импорт из файла")}
                                                </DropdownItem>
                                            </Dropdown>
                                        </EventsLayoutHeaderToolboxItem>
                                        <EventsLayoutHeaderToolboxItem>
                                            <Dropdown
                                                closeOnClickInside
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                <DropdownItem
                                                    as="button"
                                                    colorVariant="grey"
                                                    onClick={() => setShowCameraPreview(!showCameraPreview)}
                                                    withIcon={
                                                        showCameraPreview ? <Icon icon="check" size={16} /> : true
                                                    }
                                                >
                                                    {strings("Показывать превью камер")}
                                                </DropdownItem>
                                            </Dropdown>
                                        </EventsLayoutHeaderToolboxItem>
                                    </EventsLayoutHeaderToolbox>
                                ) : (
                                    <div />
                                )}
                            </EventsLayoutHeader>
                            <DeviceTable
                                showCameraPreview={showCameraPreview}
                                previewCameraId={previewCameraId}
                                selectCameraId={selectCameraId}
                                selectedDevice={selectedDevice}
                                setSelectedDevice={setSelectedDevice}
                                devicesLocationsTree={sources.slice(pageData.start, pageData.end)}
                                openLocation={openLocation}
                                showLocationHandler={showLocationHandler}
                            />

                            <CommonTablePagination
                                tableData={sources}
                                setPaging={(pageData) => this.setState({ pageData })}
                                searchMode={search !== ""}
                            />
                        </EventsLayoutPrimary>
                        {selectedDevice && (
                            <DeviceDetails
                                key={selectedDevice?.id}
                                generalInfo={generalInfo}
                                selectedDevice={selectedDevice}
                                closeDetails={() => setSelectedDevice(undefined)}
                            />
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(DeviceList);

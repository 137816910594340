export const findNearestNumber = (targetNumber, numbers) => {
    let nearestDiff = Infinity;
    let nearestValue = null;

    numbers.forEach((number) => {
        const diff = Math.abs(number - targetNumber);
        if (diff < nearestDiff) {
            nearestDiff = diff;
            nearestValue = number;
        }
    });

    return nearestValue;
};

export const findNextValue = (array, targetValue) => {
    const targetIndex = array.lastIndexOf(targetValue);

    if (targetIndex === -1 || targetIndex === array.length - 1) {
        return null;
    }

    return array[targetIndex + 1];
};

export const findPreviousValue = (array, targetValue) => {
    const targetIndex = array.indexOf(targetValue);

    if (targetIndex <= 0) {
        return null;
    }

    return array[targetIndex - 1];
};

export const findNearestLeftNumber = (targetNumber, numbers) => {
    let nearestDiff = Infinity;
    let nearestValue = null;

    numbers.forEach((number) => {
        if (targetNumber >= number) {
            const diff = Math.abs(number - targetNumber);
            if (diff < nearestDiff) {
                nearestDiff = diff;
                nearestValue = number;
            }
        }
    });

    return nearestValue;
};

// example intervals = [{from: 0, to: 10}, {from: 2, to: 19}]
export const findRangesIntersection = (intervals) => {
    if (intervals.length === 0) {
        return null;
    }

    let intersection = { from: intervals[0].from, to: intervals[0].to };

    for (let i = 1; i < intervals.length; i++) {
        const currentInterval = intervals[i];

        intersection.from = Math.max(intersection.from, currentInterval.from);

        intersection.to = Math.min(intersection.to, currentInterval.to);

        if (intersection.from > intersection.to) {
            return null;
        }
    }

    return intersection;
};

import React from "react";
import { nanoid } from "nanoid";
import { ModalFormTreeProperty } from "./ModalFormTreeProperty";
import { withSnackbar } from "notistack";
import { GetRoles } from "../../../../../../services/roles";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class ModalFormRolesTreeProperty extends React.Component {
    async getRoles() {
        const { strings } = this.props;
        const [status, roles] = await GetRoles();

        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения ролей"), { variant: "error" });
            return [];
        }

        return [
            {
                id: nanoid(),
                name: strings("Роли"),
                children: roles ?? [],
            },
        ];
    }

    render() {
        const { itemId, handleAddParameters, name, values } = this.props;
        const selected = values?.functions.find((x) => x.id === itemId).parameters?.[name] ?? [];

        return (
            <ModalFormTreeProperty
                selected={selected}
                selectItems={(items) => handleAddParameters(itemId, name, items)}
                loadItems={() => this.getRoles()}
            />
        );
    }
}
export default withCultureContext(withSnackbar(ModalFormRolesTreeProperty));

import {
    Button,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Select,
} from "headpoint-react-components";
import React, { useCallback, useState } from "react";
import { InoneFileInput } from "../../../../../components/FileInput/InoneFileInput";
import styled from "styled-components";
import { GeneralContextConsumer, getAdapters, getDeviceTypes } from "../../../../../../contexts";
import { getDeviceTypesByFeature } from "../../../../../../contexts/GeneralContext";
import { DownloadTemplate, ImportFile } from "../../../../../../services/devices";
import { withSnackbar } from "notistack";
import TaskStatusModal from "./TaskStatusModal";
import TaskResultsModal from "./TaskResultsModal";
import { ErrorTaskModal } from "./ErrorTaskModal/ErrorTaskModal";

function ImportFileModal({ closeHandler, enqueueSnackbar, strings }) {
    const heightAsThreeHeaders = 220; //simple observation

    const inOneCameraTypeId = "c98c92b7-a6f5-49ad-953c-ef98257e66c6";
    const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState(inOneCameraTypeId);

    const [file, setFile] = useState(null);
    const [task, setTask] = useState(null);

    const getAvailableDeviceTypes = useCallback(
        (generalInfo) => {
            const adapters = getAdapters(generalInfo);
            const deviceTypeIdsWithMassOperations = getDeviceTypesByFeature(adapters, "massoperations");

            return getDeviceTypes(generalInfo)
                .filter((deviceType) => deviceTypeIdsWithMassOperations.includes(deviceType.value))
                .map((deviceType) => ({ ...deviceType, label: strings(deviceType.label) }));
        },
        [strings]
    );

    const getServiceId = useCallback(
        (generalInfo) => {
            const deviceTypeService = getAdapters(generalInfo).find((adapter) =>
                adapter.deviceTypes?.map((deviceType) => deviceType.id).includes(selectedDeviceTypeId)
            );

            return deviceTypeService.serviceId;
        },
        [selectedDeviceTypeId]
    );

    const importFile = useCallback(
        async (generalInfo) => {
            const [isSuccess, , task] = await ImportFile(file, selectedDeviceTypeId, getServiceId(generalInfo));
            if (!isSuccess) {
                enqueueSnackbar(strings("Не удалось импортировать файл"), { variant: "error" });
            } else {
                setTask(task);
            }
        },
        [file, selectedDeviceTypeId, getServiceId, enqueueSnackbar, strings]
    );

    if (task && task.info.status === "Error") {
        return (
            <ErrorTaskModal task={task} closeHandler={closeHandler} okHandler={() => setTask(null)} strings={strings} />
        );
    }

    if (task && task.info.status !== "Finished") {
        return <TaskStatusModal task={task} setTask={setTask} strings={strings} />;
    }

    if (task && task.info.status === "Finished") {
        return <TaskResultsModal task={task} closeHandler={closeHandler} strings={strings} />;
    }

    return (
        <GeneralContextConsumer>
            {(generalInfo) => {
                return (
                    <Modal size="sm" closeHandler={closeHandler}>
                        <ModalHeader title={strings("Импорт из файла")} align="center" closeHandler={closeHandler} />
                        <ModalBody>
                            <ModalBodyItem>
                                <SubItem>
                                    <Select
                                        label={strings("Тип устройства")}
                                        placeholder={strings("Выбрать тип")}
                                        value={selectedDeviceTypeId}
                                        options={getAvailableDeviceTypes(generalInfo)}
                                        onChange={setSelectedDeviceTypeId}
                                        colorVariant="default"
                                        horizontalAlignment="left"
                                        verticalAlignment="bottom"
                                        fullWidth
                                    />
                                </SubItem>
                                <SubItem>
                                    <InoneFileInput
                                        onChange={(files) => setFile(files[0])}
                                        label={strings("Файл")}
                                        comment={strings("Расширение файла: .xls/.xlsx")}
                                        placeholder={strings("Выберите файл или перенесите его сюда")}
                                        fullWidth
                                        height={heightAsThreeHeaders}
                                    />
                                </SubItem>

                                <SubItem>
                                    <Button
                                        variant="secondary"
                                        label={strings("Скачать шаблон")}
                                        icon="download"
                                        onClick={async () => await DownloadTemplate(selectedDeviceTypeId)}
                                    />
                                </SubItem>
                            </ModalBodyItem>
                        </ModalBody>
                        <ModalFooter>
                            <ModalFooterItem>
                                <Button
                                    type="button"
                                    variant="primary"
                                    disabled={!file}
                                    label={strings("Импортировать")}
                                    onClick={() => importFile(generalInfo)}
                                />
                            </ModalFooterItem>
                            <ModalFooterItem>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    label={strings("Отмена")}
                                    onClick={closeHandler}
                                />
                            </ModalFooterItem>
                        </ModalFooter>
                    </Modal>
                );
            }}
        </GeneralContextConsumer>
    );
}

const SubItem = styled.div`
    & + & {
        margin-top: 16px;
    }
`;

export default withSnackbar(ImportFileModal);

import React from "react";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import {
    Button,
    Icon,
    Space,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableHoverContainer,
    TableRow,
    Text,
} from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class RuleTable extends React.Component {
    render() {
        const { selectedRuleId, rules, setSelectedRule } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell style={{ whiteSpace: "normal" }}>
                                        {strings("правило проверки качества")}
                                    </TableHeadCell>

                                    <TableHeadCell style={{ whiteSpace: "normal" }}>
                                        {strings("диапазоны колебания битрейта")}
                                    </TableHeadCell>

                                    <TableHeadCell style={{ whiteSpace: "normal" }}>
                                        {strings("диапазоны потерь кадров")}
                                    </TableHeadCell>

                                    <TableHeadCell style={{ whiteSpace: "normal" }}>
                                        {strings("диапазоны колебания частоты кадров")}
                                    </TableHeadCell>

                                    <TableHeadCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rules?.map((rule, _) => (
                                    <TableRow key={rule.id} isSelected={selectedRuleId && rule.id === selectedRuleId}>
                                        <TableCell verticalAlign="middle">
                                            <Text color="primary" variant="body-sm">
                                                <Icon icon="ethernet" size={16} />
                                            </Text>
                                            <Space />
                                            <Space />
                                            <Text color="primary" variant="body-sm">
                                                {rule.name}
                                            </Text>
                                        </TableCell>

                                        <TableCell verticalAlign="middle">
                                            <Text color="warning" variant="body-sm">
                                                {rule.properties?.bitrateViolation.warningViolation}
                                            </Text>
                                            <Space />
                                            <Space />
                                            <Text color="danger" variant="body-sm">
                                                {rule.properties?.bitrateViolation.criticalViolation}
                                            </Text>
                                        </TableCell>

                                        <TableCell verticalAlign="middle">
                                            <Text color="warning" variant="body-sm">
                                                {rule.properties?.packetLossViolation.warningViolation}
                                            </Text>
                                            <Space />
                                            <Space />
                                            <Text color="danger" variant="body-sm">
                                                {rule.properties?.packetLossViolation.criticalViolation}
                                            </Text>
                                        </TableCell>

                                        <TableCell verticalAlign="middle">
                                            <Text color="warning" variant="body-sm">
                                                {rule.properties?.fpsViolation.warningViolation}
                                            </Text>
                                            <Space />
                                            <Space />
                                            <Text color="danger" variant="body-sm">
                                                {rule.properties?.fpsViolation.criticalViolation}
                                            </Text>
                                        </TableCell>

                                        <TableCell verticalAlign="middle" noVerticalSpace>
                                            <TableHoverContainer>
                                                <Button
                                                    icon="info"
                                                    title="Info"
                                                    variant="ghost"
                                                    onClick={() => setSelectedRule(rule)}
                                                />
                                            </TableHoverContainer>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { ModalBody, ModalBodyItem, Row, Text, TextField } from "headpoint-react-components";
import { permissionExists } from "../../../../../contexts";
import { nanoid } from "nanoid";
import { GetDevices, SearchDevices } from "../../../../../services/devices";
import { FillLocations, FillNode, GetLocations } from "../../../../../services/locations";
import DeviceTypesAndTagsFilter from "../../../../components/DeviceTypesAndTagsFilter/DeviceTypesAndTagsFilter";
import styled from "styled-components";
import { FilterAccordion } from "../../../../components/Filters";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { SelectedCounter } from "../../../../components/SelectedCounter/SelectedCounter";

const DEVICES_LIMIT = 100;

class ModalStepDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            searchList: [],
            treeId: nanoid(),
            devicesLocationsTree: [],
            devices: [],
            typesFilter: [],
            tagsFilter: [],
            selectedDevicesIds: [],
        };
    }

    async componentDidMount() {
        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        const tree = FillLocations(locations, []);
        this.setState({
            devicesLocationsTree: tree ?? [],
        });
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const [, locations] = await GetLocations();
            this.setState({
                devicesLocationsTree: FillLocations(locations, []),
            });
        }
    };

    filterSources = async () => {
        const { search } = this.state;
        if (search) {
            await this.searchNodes(search);
            return;
        }

        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
        }

        const tree = FillLocations(locations, []);
        this.setState({
            devicesLocationsTree: tree ?? [],
            treeId: nanoid(),
        });
    };

    searchNodes = async (like) => {
        const { typesFilter, tagsFilter } = this.state;

        let filter = { like, limit: DEVICES_LIMIT };
        if (typesFilter?.length) {
            filter = { ...filter, deviceTypes: typesFilter };
        }

        if (tagsFilter?.length) {
            filter = { ...filter, deviceTags: tagsFilter };
        }

        const [devicesStatus, devices] = await SearchDevices(filter);
        if (!devicesStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let nodes = devices?.map((d) => ({ ...d, tag: "device" })) ?? [];
        this.setState({ search: like, searchList: nodes });
    };

    handleClearDevices = () => this.props.SetValuesProperty("devices", []);

    updateTree(tree) {
        tree?.forEach((node) => {
            node.accordionId = nanoid();
            if (node.children) {
                this.updateTree(node.children);
            }
        });
    }

    openLocation = async (locationNode) => {
        const { strings } = this.props;

        const { devicesLocationsTree, devices, typesFilter, tagsFilter } = this.state;
        const [getDevicesStatus, newDevices] = await GetDevices(locationNode.id, typesFilter, null, tagsFilter);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, newDevices);
        }

        newDevices.forEach((nd) => {
            if (devices.find((d) => d.id === nd.id)) {
                return;
            }

            devices.push(nd);
        });

        this.setState({
            devicesLocationsTree,
            devices,
        });
    };

    render() {
        const { userInfo, values, SetValuesProperty, strings } = this.props;
        const { search, devicesLocationsTree, searchList, devices, typesFilter, tagsFilter, treeId } = this.state;
        const sources = search ? searchList : devicesLocationsTree;

        return (
            <ModalBody>
                <ModalBodyItemSm>
                    <DeviceTypesAndTagsFilter
                        typesFilter={typesFilter}
                        tagsFilter={tagsFilter}
                        setFilter={(name, filter) => {
                            this.setState({ [name]: filter });
                            setTimeout(this.filterSources, 0);
                        }}
                    />
                </ModalBodyItemSm>
                <ModalBodyItem>
                    {permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
                        <div style={{ maxWidth: "400px" }}>
                            <Row justify="between" align="bottom" marginBottom={24}>
                                <Text variant="body">{strings("Устройства")}</Text>
                                <SelectedCounter
                                    count={values?.devices?.length ?? 0}
                                    onClear={this.handleClearDevices}
                                    strings={strings}
                                />
                            </Row>
                            <Row marginBottom={12}>
                                <TextField
                                    autoComplete={false}
                                    colorVariant="light"
                                    fullWidth
                                    icon="search"
                                    onChange={(e) => this.searchNodes(e.target.value)}
                                    placeholder={strings("Найти")}
                                    type="search"
                                    value={this.state.search}
                                />
                            </Row>

                            <FilterAccordion
                                key={treeId}
                                items={sources}
                                selected={values?.devices ?? []}
                                blockParentCheckbox={true}
                                preOpen={true}
                                selectHandler={(e) =>
                                    SetValuesProperty(
                                        "devices",
                                        e?.filter((item) => devices.some((d) => d.id === item)) ?? []
                                    )
                                }
                                openItem={(location) => this.openLocation(location)}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                </ModalBodyItem>
            </ModalBody>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(ModalStepDevices));

const ModalBodyItemSm = styled.div`
    display: block;
    width: 33%;
    border-right: 1px solid ${({ theme }) => theme.colors.primary3};
`;

import React from "react";
import styled from "styled-components";

export const CameraAngleModeButton = ({ onClick, checked }) => (
    <Button type="button" onClick={onClick} checked={checked} style={{ top: "80px" }}>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 8.5L16 2V15L5 8.5Z" fill="url(#paint0_linear_6_1575)" />
            <path
                d="M9 8.5C9 10.9853 7.20958 13 5.00099 13C5.00099 13 5.00101 13 5.00022 8.5C4.99944 4.09813 5.00099 4 5.00099 4C7.20958 4 9 6.01472 9 8.5Z"
                fill="white"
            />
            <path
                d="M7.0006 8.49992C7.0006 10.1568 5.65745 11.4999 4.0006 11.4999C2.34374 11.4999 1.0006 10.1568 1.0006 8.49992C1.0006 6.84307 2.34374 5.49992 4.0006 5.49992C5.65745 5.49992 7.0006 6.84307 7.0006 8.49992Z"
                fill="#0D1623"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_6_1575"
                    x1="5"
                    y1="8.5"
                    x2="16"
                    y2="8.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.598958" stopColor="#0D1623" />
                    <stop offset="1" stopColor="#0D1623" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    </Button>
);

export const CameraEditAngleModeButton = ({ onClick, checked }) => (
    <Button type="button" onClick={onClick} checked={checked} style={{ top: "120px" }}>
        <svg width="17" height="17" viewBox="0 0 1000 900" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M159.98 96h152.236l511.998 512-152.234 152.236-512-512.002v-152.233zM63.98 287.997l608 608.003 287.997-288-607.997-608h-288v287.997z"
                fill="#0D1623"
            />
        </svg>
    </Button>
);

const Button = styled.button`
    position: absolute;
    left: 10px;
    z-index: 800;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    padding: 0;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme, checked }) => (checked ? theme.colors.green : "rgba(0, 0, 0, 0.2)")};
    background-clip: padding-box;
    cursor: pointer;
    transition: background-color 0.13s linear;

    @media (hover: hover) {
        &:hover {
            background-color: #f4f4f4;
        }
    }
`;

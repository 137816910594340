import React from "react";
import StreamPlayerWrapper from "./StreamPlayerWrapper";
import { CameraLayoutWrapper, CameraLayoutRow, CameraLayoutCol } from "../../components/CameraLayout";
import { nanoid } from "nanoid";

class CameraFrames extends React.Component {
    handleCameraFrameClear = (frameId) => {
        const nextFrames = { ...this.props.frames };
        delete nextFrames[frameId];
        this.props.setFrames(nextFrames);
    };

    render() {
        return (
            <CameraLayoutWrapper hasFullscreenToggle={Object.keys(this.props.frames).length > 1}>
                {this.props.currentLayout === "1" && (
                    <CameraLayoutRow>
                        <StreamPlayerWrapper
                            wasmModule={this.props.wasmModule}
                            key={this.props.frames[0]?.id ?? nanoid()}
                            frames={this.props.frames}
                            showCameraInfo={this.props.showCameraInfo}
                            frameIndex={0}
                            selectedCameraFrame={this.props.selectedCameraFrame}
                            handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                            handleCameraFrameClear={this.handleCameraFrameClear}
                            presets={this.props.presets}
                        />
                    </CameraLayoutRow>
                )}

                {this.props.currentLayout === "2" && (
                    <CameraLayoutRow>
                        {[0, 1].map((i) => (
                            <StreamPlayerWrapper
                                key={this.props.frames[i]?.id ?? nanoid()}
                                frames={this.props.frames}
                                showCameraInfo={this.props.showCameraInfo}
                                frameIndex={i}
                                selectedCameraFrame={this.props.selectedCameraFrame}
                                handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                handleCameraFrameClear={this.handleCameraFrameClear}
                                presets={this.props.presets}
                            />
                        ))}
                    </CameraLayoutRow>
                )}

                {this.props.currentLayout === "4" && (
                    <>
                        <CameraLayoutRow rowHeight="50%">
                            {[0, 1].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="50%">
                            {[2, 3].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "1-5" && (
                    <>
                        <CameraLayoutRow rowHeight="66.66%">
                            <StreamPlayerWrapper
                                key={this.props.frames[0]?.id ?? nanoid()}
                                frames={this.props.frames}
                                showCameraInfo={this.props.showCameraInfo}
                                frameIndex={0}
                                selectedCameraFrame={this.props.selectedCameraFrame}
                                handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                handleCameraFrameClear={this.handleCameraFrameClear}
                                presets={this.props.presets}
                            />

                            <CameraLayoutCol colWidth="33.33%">
                                {[1, 2].map((i) => (
                                    <StreamPlayerWrapper
                                        key={this.props.frames[i]?.id ?? nanoid()}
                                        frames={this.props.frames}
                                        showCameraInfo={this.props.showCameraInfo}
                                        frameIndex={i}
                                        selectedCameraFrame={this.props.selectedCameraFrame}
                                        handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                        handleCameraFrameClear={this.handleCameraFrameClear}
                                        presets={this.props.presets}
                                    />
                                ))}
                            </CameraLayoutCol>
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="33.33%">
                            {[3, 4, 5].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "1-7" && (
                    <>
                        <CameraLayoutRow rowHeight="75%">
                            <StreamPlayerWrapper
                                key={this.props.frames[0]?.id ?? nanoid()}
                                frames={this.props.frames}
                                showCameraInfo={this.props.showCameraInfo}
                                frameIndex={0}
                                selectedCameraFrame={this.props.selectedCameraFrame}
                                handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                handleCameraFrameClear={this.handleCameraFrameClear}
                                presets={this.props.presets}
                            />

                            <CameraLayoutCol colWidth="25%">
                                {[1, 2, 3].map((i) => (
                                    <StreamPlayerWrapper
                                        key={this.props.frames[i]?.id ?? nanoid()}
                                        frames={this.props.frames}
                                        showCameraInfo={this.props.showCameraInfo}
                                        frameIndex={i}
                                        selectedCameraFrame={this.props.selectedCameraFrame}
                                        handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                        handleCameraFrameClear={this.handleCameraFrameClear}
                                        presets={this.props.presets}
                                    />
                                ))}
                            </CameraLayoutCol>
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[4, 5, 6, 7].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "16" && (
                    <>
                        <CameraLayoutRow rowHeight="25%">
                            {[0, 1, 2, 3].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[4, 5, 6, 7].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[8, 9, 10, 11].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="25%">
                            {[12, 13, 14, 15].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}

                {this.props.currentLayout === "32" && (
                    <>
                        <CameraLayoutRow rowHeight="12.5%">
                            {[0, 1, 2, 3].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[4, 5, 6, 7].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[8, 9, 10, 11].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[12, 13, 14, 15].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[16, 17, 18, 19].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[20, 21, 22, 23].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>
                        <CameraLayoutRow rowHeight="12.5%">
                            {[24, 25, 26, 27].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="12.5%">
                            {[28, 29, 30, 31].map((i) => (
                                <StreamPlayerWrapper
                                    key={this.props.frames[i]?.id ?? nanoid()}
                                    frames={this.props.frames}
                                    showCameraInfo={this.props.showCameraInfo}
                                    frameIndex={i}
                                    selectedCameraFrame={this.props.selectedCameraFrame}
                                    handleCameraFrameSelect={this.props.setSelectedCameraFrame}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                    presets={this.props.presets}
                                />
                            ))}
                        </CameraLayoutRow>
                    </>
                )}
            </CameraLayoutWrapper>
        );
    }
}

export default CameraFrames;

import React from "react";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import DetailsTabMain from "./DetailsTabMain";
import { GeneralContextConsumer, getEventTypes, getExtensions, getSystemVariableValue } from "../../../../contexts";
import { GetEvents, GetCount } from "../../../../services/events";
import { GetDevicesCount } from "../../../../services/devices";
import { DetailsTabExtension } from "./DetailsTabExtension";
import { Button, Text, Tab, TabPanel, Dropdown, DropdownItem } from "headpoint-react-components";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
} from "../../../components/DetailsBox";
import { nanoid } from "nanoid";
import { withSnackbar } from "notistack";
import { GetPlans } from "../../../../services/plans";
import DetailsTabEvents from "../../../components/DetailsTabEvents/DetailsTabEvents";
import { withRouter } from "react-router-dom";

class LocationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
            events: [],
            eventsCount: -1,
            countWarning: -1,
            countDanger: -1,
            devicesCount: -1,
            pageNumber: 1,
            countOnPage: this.getLimit(),
            locationNames: new Map(),
            plans: [],
        };
    }

    getLimit() {
        return getSystemVariableValue(this.props.generalContext, "service.api.events", "events.count.on.page", 5);
    }

    loadEventCounts = async () => {
        const { selectedLocation, enqueueSnackbar, strings } = this.props;

        let filter = {
            locationIds: [selectedLocation.id],
            isRead: false,
        };

        const [statusDanger, countDanger] = await GetCount({ ...filter, severity: ["danger"] });
        if (!statusDanger) {
            enqueueSnackbar(strings("Не удалось получить количество тревожных событий"), { variant: "error" });
        }

        const [statusWarning, countWarning] = await GetCount({ ...filter, severity: ["warning"] });
        if (!statusWarning) {
            enqueueSnackbar(strings("Не удалось получить количество событий, требующих внимания"), {
                variant: "error",
            });
        }

        this.setState({ countDanger, countWarning });
    };

    loadDeviceCount = async () => {
        const { selectedLocation, enqueueSnackbar, strings } = this.props;
        if (!selectedLocation) {
            this.setState({ devicesCount: 0 });
            return;
        }

        const [status, count] = await GetDevicesCount(selectedLocation.id);
        if (!status) {
            enqueueSnackbar(strings("Не удалось получить количество событий"), { variant: "error" });
        }

        this.setState({ devicesCount: count });
    };

    loadPlans = async () => {
        const { selectedLocation, enqueueSnackbar, strings } = this.props;
        if (!selectedLocation) {
            return;
        }

        const [status, plans] = await GetPlans(selectedLocation.id);
        if (!status) {
            enqueueSnackbar(strings("Не удалось получить планы для локации"), { variant: "error" });
        }

        this.setState({ plans });
    };

    loadEvents = async (nextPageNumber, previousPageNumber) => {
        const { events, countOnPage } = this.state;
        const { selectedLocation, enqueueSnackbar, strings } = this.props;

        let skipNumber = 0;
        if (nextPageNumber !== undefined && previousPageNumber !== undefined) {
            skipNumber = nextPageNumber - previousPageNumber;
        }

        let markNumber = 0;
        if (skipNumber !== 0 && events && events?.length > 0) {
            markNumber = skipNumber > 0 ? events.pop().rowNumber : events[0].rowNumber;
        }

        let filter = {
            locationIds: [selectedLocation.id],
            isRead: false,
            pageNumber: skipNumber,
            rowNumber: markNumber,
            countOnPage,
        };

        const [status, newEvents] = await GetEvents(filter);
        if (!status) {
            enqueueSnackbar(strings("Не удалось получить список событий"), { variant: "error" });
            return;
        }

        const [statusCount, eventsCount] = await GetCount({ ...filter, severity: ["danger", "warning", "info"] });
        if (!statusCount) {
            enqueueSnackbar(strings("Не удалось получить количество событий"), { variant: "error" });
            return;
        }

        this.setState({ events: newEvents, eventsCount });
    };

    componentDidMount = async () => {
        await this.loadEventCounts();
        await this.loadDeviceCount();
        await this.loadEvents();
        await this.loadPlans();
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.selectedLocation?.id !== this.props.selectedLocation?.id) {
            await this.loadEventCounts();
            await this.loadDeviceCount();
            await this.loadEvents();
            await this.loadPlans();
        } else if (prevState.countOnPage !== this.state.countOnPage) {
            await this.loadEvents();
        } else if (prevState.pageNumber !== this.state.pageNumber) {
            await this.loadEvents(this.state.pageNumber, prevState.pageNumber);
        }
    };

    handleShowMore = async () => {
        let { countOnPage } = this.state;
        this.setState({
            countOnPage: countOnPage + this.getLimit(),
            events: [],
            pageNumber: 1,
        });
    };

    handlePage = async (pageNumber) => {
        const { eventsCount, countOnPage } = this.state;
        if (pageNumber <= Math.ceil(eventsCount / countOnPage)) {
            this.setState({ pageNumber });
        }
    };

    toEvents(location) {
        this.props.history.push({
            pathname: "/events",
            state: {
                type: "FilterBySingleLocation",
                location,
            },
        });
    }

    toDevicesSettings(location) {
        this.props.history.push({
            pathname: "/settings",
            state: {
                partition: "/devices",
                type: "SelectLocation",
                location,
            },
        });
    }

    toPlans(plan) {
        this.props.history.push({
            pathname: "/plans",
            state: {
                partition: "/devices",
                type: "SelectPlan",
                selectedPlan: plan,
            },
        });
    }

    render() {
        const {
            events,
            detailsTab,
            eventsCount,
            countWarning,
            countDanger,
            devicesCount,
            locationNames,
            pageNumber,
            countOnPage,
            plans,
        } = this.state;
        const { selectedLocation, closeHandler, strings } = this.props;

        return (
            <EventsLayoutSecondary>
                <GeneralContextConsumer>
                    {(generalInfo) => {
                        const locationsExtensions = getExtensions(generalInfo, "system.locations");
                        const extensionTabs = locationsExtensions?.reduce((result, e) => {
                            if (
                                e.details &&
                                e.details?.detailsTab !== "main" &&
                                e.details?.detailsTab !== "events" &&
                                !result?.some((tab) => tab?.detailsTab === e.details?.detailsTab)
                            ) {
                                return [...result, e.details];
                            }

                            return result;
                        }, []);

                        return (
                            <DetailsBox>
                                <DetailsBoxHeader sticky>
                                    <DetailsBoxTitle>
                                        <Text variant="title" component="h2">
                                            {selectedLocation.name}
                                        </Text>
                                    </DetailsBoxTitle>

                                    <DetailsBoxHeaderActions>
                                        {!!plans?.length && (
                                            <Dropdown
                                                toggleLabel={strings("Планы")}
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                {plans.map((p) => (
                                                    <DropdownItem
                                                        key={p.id ?? nanoid()}
                                                        as="button"
                                                        onClick={() => {
                                                            this.toPlans(p, selectedLocation);
                                                        }}
                                                    >
                                                        {p.name}
                                                    </DropdownItem>
                                                ))}
                                            </Dropdown>
                                        )}
                                        <Dropdown
                                            closeOnClickInside
                                            toggleVariant="secondary"
                                            horizontalAlignment="right"
                                        >
                                            <DropdownItem as="button" onClick={() => this.toEvents(selectedLocation)}>
                                                {strings("Журнал событий")}
                                            </DropdownItem>
                                            <DropdownItem
                                                as="button"
                                                onClick={() => this.toDevicesSettings(selectedLocation)}
                                            >
                                                {strings("Карточка локации")}
                                            </DropdownItem>
                                        </Dropdown>
                                        <Button icon="close" variant="secondary" onClick={closeHandler} />
                                    </DetailsBoxHeaderActions>
                                </DetailsBoxHeader>

                                <DetailsBoxTabs>
                                    <TabPanel>
                                        <Tab
                                            label={strings("Общее")}
                                            isActive={detailsTab === "main"}
                                            onClick={() => this.setState({ detailsTab: "main" })}
                                        />
                                        <Tab
                                            label={strings("Последние события")}
                                            isActive={detailsTab === "events"}
                                            onClick={() => this.setState({ detailsTab: "events" })}
                                        />
                                        {extensionTabs?.map((tab) => (
                                            <Tab
                                                key={nanoid()}
                                                label={strings(tab.detailsTabName ?? tab.detailsTab)}
                                                isActive={detailsTab === tab.detailsTab}
                                                onClick={() => this.setState({ detailsTab: tab.detailsTab })}
                                            />
                                        ))}
                                    </TabPanel>
                                </DetailsBoxTabs>

                                {detailsTab === "main" && (
                                    <DetailsTabMain
                                        devicesCount={devicesCount}
                                        countDanger={countDanger}
                                        countWarning={countWarning}
                                        location={selectedLocation}
                                        detailsTab={detailsTab}
                                        locationsExtensions={locationsExtensions}
                                    />
                                )}
                                {detailsTab === "events" && (
                                    <DetailsTabEvents
                                        events={events}
                                        eventsCount={eventsCount}
                                        countOnPage={countOnPage}
                                        locationNames={locationNames}
                                        pageNumber={pageNumber}
                                        handleShowMore={this.handleShowMore}
                                        handlePage={this.handlePage}
                                        defaultEventTypes={getEventTypes(generalInfo)}
                                    />
                                )}
                                {detailsTab !== "main" &&
                                    detailsTab !== "events" &&
                                    extensionTabs?.some((tab) => tab.detailsTab === detailsTab) && (
                                        <DetailsTabExtension
                                            location={selectedLocation}
                                            detailsTab={detailsTab}
                                            locationsExtensions={locationsExtensions}
                                        />
                                    )}
                            </DetailsBox>
                        );
                    }}
                </GeneralContextConsumer>
            </EventsLayoutSecondary>
        );
    }
}

export default withSnackbar(withRouter(LocationDetails));

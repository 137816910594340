import { ModalSection, TextField } from "headpoint-react-components";
import { useDispatch, useSelector } from "react-redux";
import { saveControlZonesState } from "../../../../../app/reducers/controlZonesReducer";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";

const ModalGroupStepDefault = ({ strings }) => {
    const dispatch = useDispatch();

    const values = useSelector((state) => state.controlZones.values);

    const saveValues = (name, value) => {
        dispatch(saveControlZonesState({ values: { ...values, [name]: value } }));
    };

    return (
        <ModalSection>
            <ModalFormItem>
                <TextField
                    label={strings("Название группы")}
                    name="name"
                    type="text"
                    colorVariant="light"
                    fullWidth
                    value={values.name}
                    onChange={(e) => saveValues("name", e.target.value)}
                    error={!values.name ? strings("Поле не может быть пустым") : null}
                />
            </ModalFormItem>

            <ModalFormItem>
                <TextField
                    label={strings("Комментарий")}
                    name="comment"
                    type="text"
                    colorVariant="light"
                    fullWidth
                    inputProps={{
                        as: "textarea",
                        rows: 3,
                    }}
                    value={values.comment}
                    onChange={(e) => saveValues("comment", e.target.value)}
                />
            </ModalFormItem>
        </ModalSection>
    );
};

export default withCultureContext(ModalGroupStepDefault);

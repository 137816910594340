import React from "react";
import { Modal, VideoControlBtn } from "headpoint-react-components";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

class FullImageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { imageId: nanoid() };
    }

    componentDidMount() {
        document.addEventListener("keydown", (e) => this.escFunction(e), false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", (e) => this.escFunction(e), false);
    }

    escFunction(event) {
        if (event.key === "Escape") {
            this.props?.handleClose();
        }
    }

    async toggleFullscreen(elementId) {
        if (!document.fullscreenElement) {
            await document.getElementById(elementId).requestFullscreen();
        } else if (document.exitFullscreen) {
            await document.exitFullscreen();
        }
    }

    render() {
        const { imageUrl, strings } = this.props;
        const { imageId } = this.state;

        return (
            <Modal size="lg" closeHandler={this.props.handleClose}>
                <img src={imageUrl} alt={imageUrl} id={imageId} />

                <ModalFooterWrapper>
                    <FullscreenBtn>
                        <VideoControlBtn
                            onClick={() => this.toggleFullscreen(imageId)}
                            title={strings("На весь экран")}
                            icon="fullsize"
                        />
                    </FullscreenBtn>
                </ModalFooterWrapper>
            </Modal>
        );
    }
}

const FullscreenBtn = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
`;

const ModalFooterWrapper = styled.div`
    position: relative;
    display: block;
    align-items: center;
    min-height: 0;
    padding: 0;
`;

class FullImageModalWrapper extends React.Component {
    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => React.createElement(FullImageModal, { ...this.props, strings })}
            </CultureContextConsumer>
        );
    }
}

export default FullImageModalWrapper;

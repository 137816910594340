import React from "react";
import { nanoid } from "nanoid";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { formatPlanDeviceCount } from "../../../../utilites/TextUtils";
import {
    Table,
    TableHead,
    TableRow,
    TableHeadCell,
    TableBody,
    Text,
    TableAccordionItem,
    TableCell,
    DeviceIcon,
    Button,
    TableHoverContainer,
    Space,
} from "headpoint-react-components";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class PlansTable extends React.Component {
    handleShowDetails = (id) => {
        const flattenAdapterList = this.flatten(this.props.tableData);
        const nextAdapter = flattenAdapterList[id];
        this.props.setSelectedAdapter(nextAdapter);
    };

    RowActions = (plan) => (
        <TableHoverContainer>
            <Button
                onClick={(e) => {
                    e.stopPropagation();
                    const { setSelectedPlan } = this.props;
                    if (setSelectedPlan) {
                        setSelectedPlan(plan);
                    }
                }}
                icon="info"
                title="Info"
                variant="ghost"
                noPadding
                width="32px"
            />
        </TableHoverContainer>
    );

    locationNode = (location) => (
        <TableAccordionItem
            key={location.id}
            defaultClosed={location.isClosed}
            onClick={() => {
                location.isClosed = !location.isClosed;
                this.props.openLocation(location);
            }}
            toggle={
                <>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {location.name}
                        </Text>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                </>
            }
        >
            {this.createRow(location.children)}
        </TableAccordionItem>
    );

    planNode = (plan) => {
        const { strings } = this.props;

        return (
            <TableRow key={plan.viewId} isSelected={plan?.id === this.props.selectedPlan?.id}>
                <TableCell>
                    <Text color={plan.statusCode} variant="body-sm">
                        <DeviceIcon icon={plan.icon} size={16} />
                    </Text>
                    <Space isDouble />
                    <Text color="primary" variant="body-sm">
                        {plan.name}
                    </Text>
                </TableCell>
                <TableCell verticalAlign="top">
                    {`${plan.deviceCount ?? 0} ${strings(formatPlanDeviceCount(plan.deviceCount ?? 0))}`}
                </TableCell>
                <TableCell verticalAlign="top">{this.RowActions(plan)}</TableCell>
            </TableRow>
        );
    };

    createRow = (tree) => {
        if (!tree) {
            return null;
        }

        return tree.map((obj) => (obj.tag === "location" ? this.locationNode(obj) : this.planNode(obj)));
    };

    render() {
        const { strings } = this.props;

        return (
            <EventsLayoutTableWrapper>
                <Table key={nanoid()} hasHover stickyHeader isFixed bgColor="dark">
                    <colgroup>
                        <col />
                        <col />
                        <col style={{ width: "80px" }} />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableHeadCell>{strings("Локации и планы")}</TableHeadCell>
                            <TableHeadCell>{strings("Количество устройств")}</TableHeadCell>
                            <TableHeadCell></TableHeadCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{this.createRow(this.props.plansLocationsTree)}</TableBody>
                </Table>
            </EventsLayoutTableWrapper>
        );
    }
}

export default withCultureContext(PlansTable);

const ROLES_PATH = "/roles-api/roles";
const ROLES_GROUPS_PATH = "/roles-api/rolesGroups";
const LIST_PATH = "list";

export const GetRolesTree = (roles, groups) => {
    return (
        groups?.sort(CompareRoles)?.map((group) => ({
            ...group,
            roles: roles.filter((r) => r.groupId === group.id).sort(CompareRoles),
        })) ?? []
    );
};

export const CompareRoles = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const GetRoles = async () => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${ROLES_PATH}/${LIST_PATH}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            return [false, []];
        }

        let res = await response.json();
        return [true, res?.items?.sort(CompareRoles) ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetRolesGroups = async () => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${ROLES_GROUPS_PATH}/${LIST_PATH}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items?.sort(CompareRoles) ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const CreateRole = async (role) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(ROLES_PATH, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(role),
    });

    const respBody = await response.text();
    return [response.ok, response.status, respBody ? JSON.parse(respBody) : response];
};

export const DeleteRole = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${ROLES_PATH}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    const respBody = await response.text();

    return [response.ok, response.status, respBody ? JSON.parse(respBody) : {}];
};

export const UpdateRole = async (role) => {
    let id = role.id;
    let data = { ...role };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${ROLES_PATH}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.ok, response.status];
};

export const CreateRolesGroup = async (group) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(ROLES_GROUPS_PATH, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(group),
    });

    return [response.ok, response.status, await response.json()];
};

export const DeleteRoleGroup = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${ROLES_GROUPS_PATH}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    const respBody = await response.text();

    return [response.ok, response.status, respBody ? JSON.parse(respBody) : {}];
};

export const UpdateRolesGroup = async (group) => {
    let id = group.id;
    let data = { ...group };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${ROLES_GROUPS_PATH}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.ok, response.status, await response.json()];
};

export const CreateRoleCopy = async (role) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${ROLES_PATH}/copy`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(role),
    });

    return [response.ok, response.status];
};

import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { Row, Text, TextField } from "headpoint-react-components";
import { FilterAccordion } from "../../../../components/Filters";
import { GetDevices, SearchDevices } from "../../../../../services/devices";
import { FillLocations, FillNode, GetLocations } from "../../../../../services/locations";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveScheduleState } from "../../../../../app/reducers/scheduleReducer";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { getAdapters } from "../../../../../contexts";
import { getDeviceTypesByFeature, withGeneralContext } from "../../../../../contexts/GeneralContext";
import { SelectedCounter } from "../../../../components/SelectedCounter/SelectedCounter";

const DEVICES_LIMIT = 100;

const DeviceLocationTree = ({ enqueueSnackbar, strings, selectedFeature, generalInfo }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [searchList, setSearchList] = useState("");
    const [devices, setDevices] = useState([]);
    const [devicesLocationsTree, setDevicesLocationsTree] = useState([]);

    const values = useSelector((state) => state.schedule.values);

    useEffect(() => {
        const fetchLocations = async () => {
            const [getLocationsStatus, locations] = await GetLocations();
            if (!getLocationsStatus) {
                enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            }
            const tree = FillLocations(locations, []);

            setDevicesLocationsTree(tree ?? []);
        };

        fetchLocations();
    }, [enqueueSnackbar, selectedFeature, dispatch, strings]);

    const saveValue = (name, value) => {
        dispatch(saveScheduleState({ values: { ...values, [name]: value } }));
    };

    const openLocation = async (locationNode) => {
        const adapters = getAdapters(generalInfo);
        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let [getDevicesStatus, newDevices] = [false, null];
        if (!selectedFeature.requiredFeatures) {
            [getDevicesStatus, newDevices] = await GetDevices(
                locationNode.id,
                values?.typesFilter ?? [],
                values?.groupsFilter ?? [],
                values?.tagsFilter ?? [],
                values?.deviceIdsFilter ?? []
            );
            if (!getDevicesStatus) {
                enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }
        } else {
            let deviceTypeIds = getDeviceTypesByFeature(adapters, selectedFeature.requiredFeatures);
            if (!deviceTypeIds || deviceTypeIds.length === 0) {
                this.props.enqueueSnackbar(strings("Не найдено адаптеров поддерживающих стрим"), {
                    variant: "warning",
                });
                return;
            }

            [getDevicesStatus, newDevices] = await GetDevices(
                locationNode.id,
                values?.typesFilter ?? [],
                values?.groupsFilter ?? [],
                values?.tagsFilter ?? [],
                values?.deviceIdsFilter ?? []
            );
            if (!getDevicesStatus) {
                enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, newDevices ?? []);
        }

        newDevices.forEach((nd) => {
            if (devices.find((d) => d.id === nd.id)) {
                return;
            }

            devices.push(nd);
        });

        setDevices(devices);
        setDevicesLocationsTree([...devicesLocationsTree]);
    };

    const searchNodes = async (like) => {
        let filter = { like, limit: DEVICES_LIMIT };

        const [devicesStatus, devices] = await SearchDevices(filter);
        if (!devicesStatus) {
            enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let nodes = devices?.map((d) => ({ ...d, tag: "device" })) ?? [];

        setSearchList(nodes);
        setSearch(like);
    };

    const sources = search ? searchList : devicesLocationsTree;
    return (
        <>
            <ModalFormItem>
                <Row justify="between" align="bottom">
                    <Text>{strings("Устройства")}</Text>
                    <SelectedCounter
                        count={values?.deviceIds?.length ?? 0}
                        onClear={() => saveValue("deviceIds", [])}
                        strings={strings}
                    />
                </Row>
            </ModalFormItem>
            <ModalFormItem>
                <TextField
                    autoComplete={false}
                    colorVariant="light"
                    fullWidth
                    icon="search"
                    onChange={(e) => searchNodes(e.target.value)}
                    placeholder={strings("Найти")}
                    type="search"
                    value={search}
                />
            </ModalFormItem>

            <ModalFormItem>
                <FilterAccordion
                    items={sources}
                    selected={values?.deviceIds ?? []}
                    blockParentCheckbox={true}
                    preOpen={true}
                    selectHandler={(e) =>
                        saveValue("deviceIds", e?.filter((item) => devices.some((d) => d.id === item)) ?? [])
                    }
                    openItem={(location) => openLocation(location)}
                />
            </ModalFormItem>
        </>
    );
};

export default withCultureContext(withGeneralContext(DeviceLocationTree));

import React from "react";
import styled from "styled-components";
import { Modal, Button, ModalBody, Row, Text } from "headpoint-react-components";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";

const ModalStepWrapper = styled.div`
    flex: 1;
`;

const ModalButtonsContainer = styled.div`
    position: absolute;
    float: right;
    display: flex;
    align-items: center;
`;

const ModalButton = styled.div`
    display: flex;
    margin: 5px;
`;

const ModalFooter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 96px;
    border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 24px 24px;
`;

const ModalSection = styled.div`
    width: 100%;
    padding: 24px 24px;

    & + & {
        border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    }
`;

const TextWrapper = styled.div`
    overflow-wrap: anywhere;
`;

export class UpdateFilterModal extends React.Component {
    render() {
        if (!this.props.visible) {
            return null;
        }
        const { strings } = this.props;

        return (
            <Modal size="sm" closeHandler={this.props.CloseHandler}>
                <ModalStepWrapper>
                    <ModalBody>
                        <ModalSection>
                            <ModalFormItem>
                                <Row justify="left" align="bottom">
                                    <TextWrapper>
                                        <Text variant="body" color="primary" align={"left"}>
                                            {`${strings("Сохранить изменения для фильтра событий")} «${
                                                this.props.filter.name
                                            }»?`}
                                        </Text>
                                    </TextWrapper>
                                </Row>
                            </ModalFormItem>
                        </ModalSection>
                    </ModalBody>
                    <ModalFooter>
                        <ModalButtonsContainer>
                            <ModalButton>
                                <Button
                                    type="button"
                                    variant="primary"
                                    label={strings("Да, сохранить")}
                                    width="150px"
                                    onClick={() => {
                                        this.props.SaveHandler();
                                        this.props.CloseHandler();
                                    }}
                                />
                            </ModalButton>
                            <ModalButton>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    label={strings("Отмена")}
                                    width="80"
                                    display="right"
                                    onClick={this.props.CloseHandler}
                                />
                            </ModalButton>
                        </ModalButtonsContainer>
                    </ModalFooter>
                </ModalStepWrapper>
            </Modal>
        );
    }
}

import { Button, Row, Text } from "headpoint-react-components";
import React from "react";
import InfoButton from "./InfoButton";
import { rowButtonsStyle, rowElementsStyle } from "./styles";

const CamerasList = (props) => {
    const getParentLocationName = (cameraId) => {
        let currentLevel = props.devicesLocationsTree;

        while (currentLevel.length > 0) {
            const findingLocation = currentLevel.find(
                (node) => node.tag === "location" && node.children.some((child) => child.id === cameraId)
            );

            if (findingLocation) {
                return findingLocation.name;
            }

            currentLevel = currentLevel
                .filter((node) => node.tag === "location")
                .map((location) => location.children)
                .flat();
        }

        return null;
    };

    return (
        <>
            {props.selectedCameras?.map((cameraId) => (
                <Row key={cameraId} justify={"between"} align={"middle"}>
                    <Text
                        style={rowElementsStyle}
                        marginLeft={"auto"}
                        color="secondary"
                        variant="body-sm"
                        align={"left"}
                    >
                        {props.camerasCache?.find((c) => c.id === cameraId)?.name ?? cameraId}
                    </Text>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <InfoButton locationName={getParentLocationName(cameraId)} />
                        <Button
                            style={rowButtonsStyle}
                            icon="trash"
                            variant="ghost"
                            onClick={() => props.selectCamera(cameraId)}
                        />
                    </div>
                </Row>
            ))}
        </>
    );
};

export default CamerasList;

import React from "react";
import { Checkbox, Row, Text, TextField } from "headpoint-react-components";
import { nanoid } from "nanoid";
import { FilterGroup, FilterGroupList } from "../Filters";
import { FilterItem } from "../FiltersPartion";
import { GeneralContextConsumer, getDeviceTypes } from "../../../contexts";
import { GetTags } from "../../../services/tags";
import { withSnackbar } from "notistack";
import styled from "styled-components";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class DeviceTypesAndTagsFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeSearch: "",
            tagSearch: "",
            tags: [],
        };
    }

    async componentDidMount() {
        const { strings } = this.props;

        const [tagStatus, tags] = await GetTags();
        if (!tagStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения тегов оборудования"), { variant: "error" });
            return;
        }

        this.setState({
            tags: tags ?? [],
        });
    }

    handleCheckboxChange = (filterName, id) => {
        let filterValue = this.props[filterName];
        this.props.setFilter(
            filterName,
            filterValue.includes(id) ? filterValue.filter((item) => item !== id) : [...filterValue, id]
        );
    };

    searchFilter = (search, data, keyExp) => {
        if (!search) {
            return data;
        }

        return data?.filter((t) => keyExp(t)?.toLowerCase()?.includes(search.toLowerCase()));
    };

    render() {
        const { typeSearch, tagSearch, tags } = this.state;
        const { typesFilter, tagsFilter, setFilter, showCount, strings } = this.props;
        return (
            <GeneralContextConsumer>
                {(generalInfo) => {
                    const deviceTypes = getDeviceTypes(generalInfo);
                    return (
                        <TypesAndTagsFilterWrapper>
                            <FilterGroup
                                title={
                                    <Row justify="between">
                                        <Text variant="body-sm">{strings("Типы оборудования")}</Text>
                                        {showCount && (
                                            <Text variant="body-sm" align="right">
                                                {`${strings("Выбрано:")} ${typesFilter?.length ?? 0}`}
                                            </Text>
                                        )}
                                    </Row>
                                }
                                hasValue={!!typesFilter?.length}
                                onClear={() => {
                                    this.setState({ typeSearch: "" });
                                    setFilter("typesFilter", []);
                                }}
                            >
                                <TextField
                                    colorVariant="light"
                                    fullWidth
                                    onChange={(e) => this.setState({ typeSearch: e.target?.value })}
                                    placeholder={strings("Найти")}
                                    type="text"
                                    value={typeSearch}
                                />

                                <FilterGroupList>
                                    {this.searchFilter(typeSearch, deviceTypes, (t) => {
                                        return strings(t.label);
                                    }).map((type) => (
                                        <FilterItem key={nanoid()}>
                                            <Checkbox
                                                label={strings(type.label)}
                                                checked={typesFilter.includes(type.value)}
                                                onChange={() => this.handleCheckboxChange("typesFilter", type.value)}
                                            />
                                        </FilterItem>
                                    ))}
                                </FilterGroupList>
                            </FilterGroup>
                            <FilterGroup
                                title={
                                    <Row justify="between">
                                        <Text variant="body-sm">{strings("Теги оборудования")}</Text>
                                        {showCount && (
                                            <Text variant="body-sm" align="right">
                                                {`${strings("Выбрано:")} ${tagsFilter?.length ?? 0}`}
                                            </Text>
                                        )}
                                    </Row>
                                }
                                hasValue={!!tagsFilter?.length}
                                onClear={() => {
                                    this.setState({ tagSearch: "" });
                                    setFilter("tagsFilter", []);
                                }}
                            >
                                <TextField
                                    colorVariant="light"
                                    fullWidth
                                    onChange={(e) => this.setState({ tagSearch: e.target?.value })}
                                    placeholder={strings("Найти")}
                                    type="text"
                                    value={tagSearch}
                                />

                                <FilterGroupList>
                                    {this.searchFilter(tagSearch, tags, (t) => t.name).map((tag) => (
                                        <FilterItem key={nanoid()}>
                                            <Checkbox
                                                label={tag.name}
                                                checked={tagsFilter.includes(tag.id)}
                                                onChange={() => this.handleCheckboxChange("tagsFilter", tag.id)}
                                            />
                                        </FilterItem>
                                    ))}
                                </FilterGroupList>
                            </FilterGroup>
                        </TypesAndTagsFilterWrapper>
                    );
                }}
            </GeneralContextConsumer>
        );
    }
}

const TypesAndTagsFilterWrapper = styled.div`
    min-height: 500px;
`;

export default withCultureContext(withSnackbar(DeviceTypesAndTagsFilter));

import React from "react";
import { TableCell, TableRow, Text } from "headpoint-react-components";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import moment from "moment/moment";
import {
    formatAvailabilityDaysCount,
    formatAvailabilityHoursCount,
    formatAvailabilityMinutesCount,
} from "../../../../utilites/TextUtils.js";

class TableListBlock extends React.Component {
    getFormatTimeSpan = (value, sourceValue) => {
        const { strings } = this.props;
        if (sourceValue === undefined || sourceValue === null) {
            return "...";
        }

        switch (value.type) {
            case "timespan":
                const duration = moment.duration(sourceValue);
                let timespanLabel = "";
                const dd = Math.trunc(duration.asDays());
                const hh = duration.hours();
                const mm = duration.minutes();

                if (dd > 0) {
                    timespanLabel += `${dd} ${strings(formatAvailabilityDaysCount(dd))} `;
                }

                if (hh > 0) {
                    timespanLabel += `${hh} ${strings(formatAvailabilityHoursCount(hh))} `;
                }

                if (mm > 0) {
                    timespanLabel += `${mm} ${strings(formatAvailabilityMinutesCount(mm))}`;
                }

                if (timespanLabel.length === 0) {
                    return strings("Несколько секунд");
                }

                return timespanLabel;
            default:
                return strings(sourceValue);
        }
    };

    render() {
        const { data, getValue, details, strings } = this.props;

        return (
            <>
                <TableRow>
                    <TableCell>{strings(data.name)}</TableCell>
                    <TableCell />
                </TableRow>

                {data.list?.map((value, id) => {
                    const sourceValue = getValue(value.source, details);
                    const color = sourceValue?.properties?.color?.toLowerCase() ?? value?.color ?? "primary";
                    return (
                        <TableRow key={id}>
                            <TableCell indent={30} noBorder>
                                <Text color={color}>{strings(value.name)}</Text>
                            </TableCell>
                            <TableCell noBorder>
                                <Text color={color} variant="body-sm">
                                    {this.getFormatTimeSpan(value, sourceValue?.value)}
                                </Text>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </>
        );
    }
}

export default withCultureContext(TableListBlock);

import React from "react";
import styled from "styled-components";
import { MapContainer, TileLayer } from "react-leaflet";
import Marker from "../../../components/Marker";
import { EventsLayoutPrimary } from "../../../../layout/EventsLayout";
import {
    checkFeatureByDeviceType,
    GeneralContextConsumer,
    getAdapters,
    getDeviceTypes,
    permissionExists,
    serviceExists,
} from "../../../../contexts";
import { CameraAngleModeButton } from "../../../components/CameraAngleButtons";
import { GetViewZone } from "../../../../services/viewZones";
import pLimit from "p-limit";
import MarkerViewAngle from "../../../components/MarkerViewAngle";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { Text } from "headpoint-react-components";
const PlanControlsWrapper = styled.div`
    position: absolute;
    left: 12px;
    bottom: 8px;
    z-index: 2;
    display: flex;

    & > * + * {
        margin-left: 8px;
    }
`;

const VIDEO_STREAM_FEATURE_CODE = "video.stream";
const limit = pLimit(10);

class EventDetailsPlan extends React.Component {
    constructor(props) {
        super(props);
        this.mapTileRef = React.createRef();
        this.mapRef = React.createRef();
        this.state = {
            isMultipleMode: false,
            showViewAngle: false,
            zones: [],
        };
    }

    componentDidMount() {
        this.loadPoints();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { plan } = this.props;
        if (this.mapRef._layersMaxZoom) {
            this.mapRef._layersMaxZoom = plan.maxZoom;
        }

        if (plan?.id && plan?.id !== prevProps.plan?.id) {
            const url = window.location.origin + "/plans-api/tiles/" + plan?.id + "/{z}/{x}/{y}.png";
            this.mapTileRef.current.setUrl(url);
            this.mapRef?.setView([0, 0], 0);
            this.loadPoints();
        }
    }

    loadPoints = () => {
        const { generalInfo, points, strings } = this.props;
        if (!serviceExists(generalInfo, "service.api.view.angle")) {
            return;
        }

        const adapters = getAdapters(generalInfo) ?? [];
        const deviceTypes = getDeviceTypes(generalInfo);
        const input =
            points
                ?.filter((p) => {
                    const deviceType = deviceTypes.find((type) => type.value === p?.typeId);
                    if (!deviceType) {
                        return false;
                    }

                    return checkFeatureByDeviceType(deviceType?.value, adapters, VIDEO_STREAM_FEATURE_CODE);
                })
                ?.map((p) => limit(async () => [p.id, ...(await GetViewZone(p.id))])) ?? [];

        Promise.all(input).then((responses) => {
            const successZoneResponses = responses.filter(([_, status]) => status);
            const failedZonesResponses = responses.filter(([_, status]) => !status);

            if (failedZonesResponses?.length) {
                this.props.enqueueSnackbar(strings("Ошибка получения зоны обзора"), { variant: "error" });
                console.error(failedZonesResponses);
            }

            const zones = successZoneResponses.reduce((zones, [id, _, diff]) => ({ ...zones, [id]: diff }), {});
            this.setState({ zones });
        });
    };

    percentToDegrees = (value) => {
        return (value * 360.0) % 360.0;
    };

    render() {
        const { userInfo, selected, points, plan, strings } = this.props;
        const { showViewAngle, zones } = this.state;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <EventsLayoutPrimary noPadding style={{ zIndex: 1 }} hasSecondary={!!selected.length}>
                <GeneralContextConsumer>
                    {(generalContext) => {
                        const deviceTypes = getDeviceTypes(generalContext);
                        const adapters = getAdapters(generalContext) ?? [];
                        return (
                            <MapContainer
                                whenCreated={(mapInstance) => {
                                    this.mapRef = mapInstance;
                                }}
                                center={[0, 0]}
                                zoom={0}
                                scrollWheelZoom={true}
                                doubleClickZoom={false}
                            >
                                <TileLayer
                                    ref={this.mapTileRef}
                                    url={window.location.origin + "/plans-api/tiles/" + plan?.id + "/{z}/{x}/{y}.png"}
                                    noWrap={true}
                                />

                                {serviceExists(generalContext, "service.api.view.angle") &&
                                    permissionExists(userInfo, "view.angles.access") && (
                                        <CameraAngleModeButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({ showViewAngle: !showViewAngle });
                                            }}
                                            checked={showViewAngle}
                                        />
                                    )}

                                {points.map((p) => {
                                    const type = deviceTypes?.find((t) => t.value === p.typeId);
                                    const icon = type?.icon?.type;
                                    const tooltip = [
                                        {
                                            label: strings(type?.label),
                                            text: (
                                                <Text color={"gray"} variant={"body-sm"}>
                                                    {p.name}
                                                </Text>
                                            ),
                                        },
                                    ];

                                    return showViewAngle &&
                                        type?.value &&
                                        checkFeatureByDeviceType(type?.value, adapters, VIDEO_STREAM_FEATURE_CODE) ? (
                                        <MarkerViewAngle
                                            key={p.id}
                                            icon={icon}
                                            angle={this.percentToDegrees(zones[p.id])}
                                            colorVariant={"success"}
                                            tooltip={tooltip}
                                            position={[p.y, p.x]}
                                        />
                                    ) : (
                                        <Marker
                                            key={p.id}
                                            position={[p.y, p.x]}
                                            icon={icon}
                                            colorVariant={"success"}
                                            tooltip={tooltip}
                                        />
                                    );
                                })}
                            </MapContainer>
                        );
                    }}
                </GeneralContextConsumer>
                <PlanControlsWrapper></PlanControlsWrapper>
            </EventsLayoutPrimary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(EventDetailsPlan));

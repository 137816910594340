import React, { createRef } from "react";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import { GetDeviceInfo } from "../../../../services/devices";
import { withSnackbar } from "notistack";
import { GetLocationList } from "../../../../services/locations";
import { GetEvents, GetCount } from "../../../../services/events";
import {
    GeneralContextConsumer,
    serviceExists,
    permissionExists,
    getEventTypes,
    getDeviceTypes,
    getAdapters,
    checkFeatureByDeviceType,
    getSystemVariableValue,
} from "../../../../contexts";
import {
    DetailsBoxTabs,
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
} from "../../../components/DetailsBox";
import { TabPanel, Tab, Text, Button, Dropdown, DropdownItem } from "headpoint-react-components";
import DetailsTabMain from "./DetailsTabMain";
import DetailsTabArchive from "./DetailsTabArchive";
import DetailsTabEvents from "../../../components/DetailsTabEvents/DetailsTabEvents";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class DeviceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.detailsTabRef = createRef();
        this.state = {
            detailsTab: "main",
            device: null,
            events: [],
            eventsCount: -1,
            countWarning: -1,
            countDanger: -1,
            pageNumber: 1,
            countOnPage: this.getLimit(),
        };
    }

    handleShowMore = async () => {
        const { countOnPage } = this.state;

        this.setState({
            countOnPage: countOnPage + this.getLimit(),
            events: [],
            pageNumber: 1,
        });
    };

    handlePage = async (pageNumber) => {
        const { eventsCount, countOnPage } = this.state;
        if (pageNumber <= Math.ceil(eventsCount / countOnPage)) {
            this.setState({ pageNumber });
        }
    };

    getLimit() {
        return getSystemVariableValue(this.props.generalContext, "service.api.events", "events.count.on.page", 5);
    }

    loadEvents = async (nextPageNumber, previousPageNumber) => {
        const { events, countOnPage } = this.state;
        const { device, strings } = this.props;
        const date = new Date();
        date.setDate(date.getDate() - 5);

        let skipNumber = 0;
        if (nextPageNumber !== undefined && previousPageNumber !== undefined) {
            skipNumber = nextPageNumber - previousPageNumber;
        }

        let markNumber = 0;
        if (skipNumber !== 0 && events && events?.length > 0) {
            markNumber = skipNumber > 0 ? events.pop().rowNumber : events[0].rowNumber;
        }

        let filter = {
            deviceIds: [device.id],
            isRead: false,
            pageNumber: skipNumber,
            rowNumber: markNumber,
            countOnPage: countOnPage,
            from: date,
        };

        const [status, newEvents] = await GetEvents(filter);
        if (!status) {
            this.props.enqueueSnackbar(strings("Не удалось получить список событий"), { variant: "error" });
            return;
        }

        let [statusCount, eventsCount] = await GetCount({ ...filter, severity: ["danger", "warning", "info"] });
        if (!statusCount) {
            this.props.enqueueSnackbar(strings("Не удалось получить количество событий"), { variant: "error" });
        }

        const [statusDanger, countDanger] = await GetCount({ ...filter, severity: ["danger"] });
        if (!statusDanger) {
            this.props.enqueueSnackbar(strings("Не удалось получить количество тревожных событий"), {
                variant: "error",
            });
        }

        const [statusWarning, countWarning] = await GetCount({ ...filter, severity: ["warning"] });
        if (!statusWarning) {
            this.props.enqueueSnackbar(strings("Не удалось получить количество событий, требующих внимания"), {
                variant: "error",
            });
        }

        this.setState({ events: newEvents, countDanger, countWarning, eventsCount });
    };

    load = async () => {
        const { device, enqueueSnackbar, strings } = this.props;
        const [infoStatus, info] = await GetDeviceInfo([device.id]);
        if (!infoStatus || !info?.length) {
            enqueueSnackbar(strings("Не удалось получить устройство"), { variant: "error" });
            return;
        }

        let state = { device: info[0], detailsTab: "main" };
        const [locationsStatus, locations] = await GetLocationList({ ids: [info[0].locationId] });
        if (locationsStatus && locations?.length) {
            state["location"] = locations[0];
        } else {
            enqueueSnackbar(strings("Не удалось получить локацию"), { variant: "error" });
            return;
        }

        await this.loadEvents();
        this.setState(state);
    };

    async componentDidMount() {
        await this.load();
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.device?.id !== prevState.device?.id) {
            await this.load();
        } else if (prevState.countOnPage !== this.state.countOnPage) {
            await this.loadEvents();
        } else if (prevState.pageNumber !== this.state.pageNumber) {
            await this.loadEvents(this.state.pageNumber, prevState.pageNumber);
        }
    };

    toStream(device) {
        this.props.history.push({
            pathname: "/stream",
            state: { type: "SelectCamera", device },
        });
    }

    toArchive(device) {
        this.props.history.push({
            pathname: "/archive",
            state: {
                type: "SelectCamera",
                device,
                dates: this.detailsTabRef.current?.state?.dates,
                captureTime: this.detailsTabRef.current?.archivePlayerRef.current?.state?.captureTime,
            },
        });
    }

    toDevicesSettings(device) {
        this.props.history.push({
            pathname: "/settings",
            state: { partition: "/devices", type: "SelectDevice", device },
        });
    }

    toEvents(device) {
        this.props.history.push({
            pathname: "/events",
            state: { type: "FilterBySingleDevice", device },
        });
    }

    render() {
        const { userInfo, closeHandler, strings } = this.props;
        const {
            detailsTab,
            device,
            location,
            events,
            eventsCount,
            countWarning,
            countDanger,
            countOnPage,
            pageNumber,
        } = this.state;

        return (
            <EventsLayoutSecondary>
                <GeneralContextConsumer>
                    {(generalInfo) => {
                        const adapters = getAdapters(generalInfo) ?? [];
                        const deviceType = getDeviceTypes(generalInfo).find((type) => type.value === device?.typeId);
                        const showEvents =
                            serviceExists(generalInfo, ["service.api.events"]) &&
                            permissionExists(userInfo, "events.service.access");
                        const hasStream =
                            checkFeatureByDeviceType(deviceType?.value, adapters, "video.stream") &&
                            permissionExists(userInfo, "video.stream.access") &&
                            serviceExists(generalInfo, [
                                "service.api.stream",
                                "service.api.locations",
                                "service.api.devices",
                                "video.webrtc.service",
                                "video.recorder.service",
                            ]);
                        const showArchive =
                            checkFeatureByDeviceType(deviceType?.value, adapters, "video.archive") &&
                            permissionExists(userInfo, "video.archive.access") &&
                            serviceExists(generalInfo, [
                                "service.api.archive",
                                "service.api.locations",
                                "service.api.devices",
                                "video.webrtc.service",
                                "video.recorder.service",
                            ]);

                        return (
                            <DetailsBox>
                                <DetailsBoxHeader sticky>
                                    <DetailsBoxTitle>
                                        <Text variant="title" component="h2">
                                            {device?.name ?? ""}
                                        </Text>
                                    </DetailsBoxTitle>
                                    <DetailsBoxHeaderActions>
                                        <Dropdown
                                            closeOnClickInside
                                            toggleVariant="secondary"
                                            horizontalAlignment="right"
                                        >
                                            {hasStream ? (
                                                <DropdownItem as="button" onClick={() => this.toStream(device)}>
                                                    {strings("Видеонаблюдение")}
                                                </DropdownItem>
                                            ) : (
                                                <></>
                                            )}
                                            <DropdownItem as="button" onClick={() => this.toArchive(device)}>
                                                {strings("Видеоархив")}
                                            </DropdownItem>
                                            <DropdownItem as="button" onClick={() => this.toEvents(device)}>
                                                {strings("Журнал событий")}
                                            </DropdownItem>
                                            <DropdownItem as="button" onClick={() => this.toDevicesSettings(device)}>
                                                {strings("Карточка оборудования")}
                                            </DropdownItem>
                                        </Dropdown>
                                        <Button icon="close" variant="secondary" onClick={closeHandler} />
                                    </DetailsBoxHeaderActions>
                                </DetailsBoxHeader>
                                <DetailsBoxTabs>
                                    <TabPanel>
                                        <Tab
                                            label={strings("Общее")}
                                            isActive={detailsTab === "main"}
                                            onClick={() => this.setState({ detailsTab: "main" })}
                                        />
                                        {showArchive && (
                                            <Tab
                                                label={strings("Архив")}
                                                isActive={detailsTab === "archive"}
                                                onClick={() => this.setState({ detailsTab: "archive" })}
                                            />
                                        )}
                                        {showEvents && (
                                            <Tab
                                                label={strings("Последние события")}
                                                isActive={detailsTab === "events"}
                                                onClick={() => this.setState({ detailsTab: "events" })}
                                            />
                                        )}
                                    </TabPanel>
                                </DetailsBoxTabs>

                                {detailsTab === "main" && device && (
                                    <DetailsTabMain
                                        device={device}
                                        eventsCount={eventsCount}
                                        countWarning={countWarning}
                                        countDanger={countDanger}
                                        location={location}
                                        userPermissions={userInfo}
                                        adapters={adapters}
                                        deviceType={deviceType}
                                        generalInfo={generalInfo}
                                        strings={strings}
                                    />
                                )}
                                {detailsTab === "archive" && device && showArchive && (
                                    <DetailsTabArchive
                                        device={device}
                                        generalInfo={generalInfo}
                                        ref={this.detailsTabRef}
                                        strings={strings}
                                    />
                                )}
                                {detailsTab === "events" && showEvents && (
                                    <DetailsTabEvents
                                        events={events}
                                        eventsCount={eventsCount}
                                        countOnPage={countOnPage}
                                        pageNumber={pageNumber}
                                        handleShowMore={this.handleShowMore}
                                        handlePage={this.handlePage}
                                        defaultEventTypes={getEventTypes(generalInfo)}
                                    />
                                )}
                            </DetailsBox>
                        );
                    }}
                </GeneralContextConsumer>
            </EventsLayoutSecondary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(withRouter(DeviceDetails)));

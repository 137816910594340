import React from "react";
import { Button } from "headpoint-react-components";
import { getDataFromSource } from "../utilites";
import styled from "styled-components";
import { IsUploadedFileExists, DownloadFile } from "../../../../../services/storage";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

const FileWrapper = styled.div`
    padding-bottom: ${({ size }) => size};
`;

class FilesBlock extends React.Component {
    async handleDownload(fileId, fileName) {
        const { strings } = this.props;

        const fileExists = await IsUploadedFileExists(fileId);
        if (!fileExists) {
            this.props.enqueueSnackbar(strings("Файл не найден"), { variant: "error" });
            return;
        }

        await DownloadFile(fileId, fileName);
        this.props.enqueueSnackbar(strings("Загрузка запущена"), { variant: "success" });
    }

    formatFileSize(bytes) {
        const { strings } = this.props;

        const megabytes = bytes / Math.pow(2, 20);
        const rounded = Math.round(megabytes * 100) / 100;

        return `${rounded} ${strings("МБ")}`;
    }

    formatFileButtonTitle(file) {
        if (!Number.isFinite(file.size)) {
            return file.name;
        }

        return `${file.name}, ${this.formatFileSize(file.size)}`;
    }

    render() {
        const { files, selectedEvent } = this.props;
        const source = getDataFromSource(files.source, selectedEvent);
        if (!source || source.length === 0) {
            return null;
        }

        return (
            <>
                {source.map((file, index) => (
                    <FileWrapper size={index !== source.length - 1 ? "10px" : "0px"} key={file.id}>
                        <Button
                            elSize="md"
                            label={file.name}
                            title={this.formatFileButtonTitle(file)}
                            onClick={() => this.handleDownload(file.id, file.name)}
                            type="button"
                            variant="ghost-link"
                            noPadding
                        />
                    </FileWrapper>
                ))}
            </>
        );
    }
}

export default withCultureContext(withSnackbar(FilesBlock));

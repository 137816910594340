import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { Row, Table, TableAccordionItem, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { GeneralContextConsumer, getDeviceGroupsPermissionsTree } from "../../../../../contexts";
import { GetDevicesGroups } from "../../../../../services/devicesGroups";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsDeviceGroupsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceGroups: [],
        };
    }

    componentDidMount = async () => {
        const [status, deviceGroups] = await GetDevicesGroups();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
            return;
        }

        this.setState({ deviceGroups });
    };

    flatten = (object) => {
        const getChild = (key, objectBit) => {
            return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
        };

        return Object.assign(
            {},
            ...(function _flatten(objectBit) {
                return [].concat(
                    ...Object.keys(objectBit).map((key) =>
                        typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                    )
                );
            })(object)
        );
    };

    matchRolesDGPermissions = (selectedRole, roleDGPermissions) => {
        return roleDGPermissions
            .map((group) => ({
                ...group,
                deviceTypes: group.children
                    ?.map((item) => ({
                        ...item,
                        items: Object.values(this.flatten(item)).filter((item) =>
                            selectedRole.properties?.permissions.includes(item.id)
                        ),
                    }))
                    .filter((item) => item.items?.length > 0),
            }))
            .filter((group) => group.deviceTypes?.length > 0);
    };

    render() {
        const { selected, strings } = this.props;
        const { deviceGroups } = this.state;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => {
                    const roleDGPermissions = getDeviceGroupsPermissionsTree(deviceGroups, generalInfo);
                    const rolesDG = this.matchRolesDGPermissions(selected, roleDGPermissions);

                    return (
                        <DetailsBoxContent>
                            <Row marginTop={24} marginBottom={16}>
                                <Text color="primary" variant="body">
                                    {strings("Доступное оборудование")}
                                </Text>
                            </Row>

                            <Table isFixed>
                                <TableBody>
                                    {rolesDG.map((row) => (
                                        <TableAccordionItem
                                            key={row.id}
                                            toggle={
                                                <TableCell>
                                                    <Text color="primary" variant="body-sm">
                                                        {row.name}
                                                    </Text>
                                                </TableCell>
                                            }
                                        >
                                            {row.deviceTypes?.map((deviceType) => (
                                                <TableAccordionItem
                                                    key={deviceType.id}
                                                    toggle={
                                                        <TableCell>
                                                            <Text color="primary" variant="body-sm">
                                                                {strings(deviceType.name)}
                                                            </Text>
                                                        </TableCell>
                                                    }
                                                >
                                                    {deviceType.items?.map((item) => (
                                                        <TableRow key={item.id}>
                                                            <TableCell>
                                                                <Text color="primary" variant="body-sm">
                                                                    {strings(item.name)}
                                                                </Text>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableAccordionItem>
                                            ))}
                                        </TableAccordionItem>
                                    ))}
                                </TableBody>
                            </Table>
                        </DetailsBoxContent>
                    );
                }}
            </GeneralContextConsumer>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsDeviceGroupsTab));

import React from "react";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField, Checkbox } from "headpoint-react-components";
import styled from "styled-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class ModalStepDefault extends React.Component {
    handleInputChange = (e) => {
        const fieldName = e.rawEvent.target.name;
        this.props.SetValuesProperty(fieldName, e.target.value);
    };

    render() {
        const { values } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <ModalFormItem>
                                <TextField
                                    id="name"
                                    label={strings("Название правила")}
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={!values.name ? strings("Поле не может быть пустым") : null}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <Checkbox
                                    id="createEvent"
                                    label={strings("Создание событий")}
                                    name="createEvent"
                                    checked={values.createEvent}
                                    onChange={() => {
                                        this.props.SetValuesProperty("createEvent", !values.createEvent);
                                    }}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="packetCount"
                                    label={strings("Кол-во пакетов на отправку - шт.")}
                                    name="packetCount"
                                    type="uint"
                                    value={values.packetCount}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={
                                        !values.packetCount || values.packetCount === 0
                                            ? strings("Поле не может быть пустым")
                                            : null
                                    }
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="startInterval"
                                    label={strings("Интервал опроса - с.")}
                                    name="startInterval"
                                    type="uint"
                                    value={values.startInterval}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={
                                        !values.startInterval || values.startInterval === 0
                                            ? strings("Поле не может быть пустым")
                                            : null
                                    }
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="violationPercent"
                                    label={strings("Макс. процент ошибок - %")}
                                    name="violationPercent"
                                    type="uint"
                                    value={values.violationPercent}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={
                                        !values.violationPercent
                                            ? strings("Поле не может быть пустым")
                                            : values.violationPercent > 100
                                            ? strings("Поле не может быть больше 100")
                                            : null
                                    }
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TimeoutInfo>
                                    <TextField
                                        id="warningTimeout"
                                        label={strings("Допустимое время ожидания - мс.")}
                                        name="warningTimeout"
                                        type="uint"
                                        value={values.warningTimeout}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        fullWidth
                                        error={
                                            !values.warningTimeout || values.warningTimeout === 0
                                                ? strings("Поле не может быть пустым")
                                                : null
                                        }
                                    />
                                </TimeoutInfo>
                            </ModalFormItem>

                            <ModalFormItem>
                                <TimeoutInfo>
                                    <TextField
                                        id="criticalTimeout"
                                        label={strings("Предельное время ожидания - мс.")}
                                        name="criticalTimeout"
                                        type="uint"
                                        value={values.criticalTimeout}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        fullWidth
                                        error={
                                            !values.criticalTimeout || values.criticalTimeout === 0
                                                ? strings("Поле не может быть пустым")
                                                : null
                                        }
                                    />
                                </TimeoutInfo>
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="comment"
                                    label={strings("Комментарий")}
                                    name="comment"
                                    type="text"
                                    value={values.comment}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    inputProps={{
                                        as: "textarea",
                                        rows: 4,
                                    }}
                                    fullWidth
                                />
                            </ModalFormItem>
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

export const TimeoutInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const SHOW_DETAILS_TOPIC = "map.showDetails";
export const FREEZE_MAP_TOPIC = "map.freeze.topic";
export const OPEN_CLUSTER_TOPIC = "map.clusters.open";
export const LOAD_CLUSTER_VIEW_TOPIC = "map.clusters.load.zone";
export const SHOW_CREATE_EVENTS_FILTER = "map.filters.show.create";
export const SHOW_DELETE_EVENTS_FILTER = "map.filters.show.delete";
export const SHOW_UPDATE_EVENTS_FILTER = "map.filters.show.update";
export const CREATE_EVENTS_FILTER = "map.filters.create";
export const UPDATE_EVENTS_FILTER = "map.filters.update";
export const LOAD_EVENTS_FILTER = "map.filters.load";
export const MAP_DEVICE_LOCATIONS_FILTER = "map.deviceLocations.filter";

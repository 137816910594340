import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, Text, Space, TextField } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

class LocationExtensionStep extends React.Component {
    render() {
        const { locationExtensions, activeStep, steps, values, SetValuesProperty } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <ModalFormItem>
                                {locationExtensions
                                    ?.filter((ext) => ext.settings.section === steps?.[activeStep - 1]?.section)
                                    .map((ext) => {
                                        const error =
                                            ext.settings?.required &&
                                            (values[ext.name] === null ||
                                                values[ext.name] === undefined ||
                                                values[ext.name] === "")
                                                ? strings(ext.settings?.errorText)
                                                : null;

                                        return (
                                            <ModalFormItem key={`${ext.entity}-${ext.name}`}>
                                                <Text>{strings(ext.title) ?? strings(ext.name)}</Text>
                                                <Space />
                                                <TextField
                                                    name={strings(ext.name)}
                                                    type={ext.type}
                                                    value={values[ext.name]}
                                                    onChange={(e) =>
                                                        SetValuesProperty(strings(ext.name), e.target.value)
                                                    }
                                                    colorVariant="light"
                                                    fullWidth
                                                    error={strings(error)}
                                                />
                                            </ModalFormItem>
                                        );
                                    })}
                            </ModalFormItem>
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(LocationExtensionStep);

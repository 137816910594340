import styled from "styled-components";
import { Text } from "headpoint-react-components";

export const ProgressBar = ({ min, max, current, text }) => {
    return (
        <ProgressBarWrapper>
            {text && <Text>{text}</Text>}
            <ProgressBarBackground>
                <ProgressBarLine value={(100 * (current - min)) / (max - min)} />
            </ProgressBarBackground>
        </ProgressBarWrapper>
    );
};

const ProgressBarWrapper = styled.div`
    display: block;
    justify-content: center;
    text-align: center;
    padding: 25px 15px;
`;

const ProgressBarBackground = styled.div`
    display: block;
    background-color: ${({ theme }) => theme.colors.inactive};
    border-radius: 18px;
    height: 36px;
    margin-top: 10px;
`;

const ProgressBarLine = styled.div`
    display: block;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 18px;
    height: 36px;
    width: ${(props) => `${Math.min(props.value, 100)}%`};
    min-width: 36px;
    margin: 0;
`;

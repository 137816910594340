import React from "react";
import { ExtensionErrorModal } from "./ExtensionErrorModal";
import { IncorrectFileErrorModal } from "./IncorrectFileErrorModal";

export function ErrorTaskModal({ task, closeHandler, okHandler, strings }) {
    const errorCode = task.info.properties.errorCode;

    switch (errorCode) {
        case "ExtensionError":
            return <ExtensionErrorModal closeHandler={closeHandler} okHandler={okHandler} strings={strings} />;
        default:
            return (
                <IncorrectFileErrorModal
                    task={task}
                    closeHandler={closeHandler}
                    okHandler={okHandler}
                    strings={strings}
                />
            );
    }
}

import styled, { css } from "styled-components";

export const ModalStepWrapper = styled.div`
    flex: 1;
    width: 70%;
`;

export const ModalFormItem = styled.div`
    width: 100%;

    & + & {
        margin-top: 16px;
    }
`;

export const InputItem = styled.div`
    position: relative;
    display: inline-block;
    margin: ${({ inLine }) => (inLine ? "0" : "8px")};
`;

export const InputItemRemoveBtn = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: none;
    text-align: center;
    cursor: pointer;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 26px;
    right: -4px;
    z-index: 2;

    &:after {
        content: "";
        width: 7px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.primary2};
        position: absolute;
        top: 6px;
        left: 3px;
        z-index: 1;
    }
`;

const ScrollWrapperStyled = styled.div`
    overflow: auto;
    height: ${({ height }) => height || 200}px;
    margin-bottom: -32px;
    padding-bottom: 16px;
`;

export const ScrollWrapper = ({ children, height }) => (
    <ScrollWrapperStyled height={height}>{children}</ScrollWrapperStyled>
);

const iconStyle = css`
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

export const ClearButton = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    color: ${({ theme }) => theme.colors.blueAlt};
    cursor: pointer;
    font-size: 16px;
    margin-left: 8px;
    transition: opacity 0.16s linear;

    &:before {
        content: "\\e934";

        ${iconStyle};
    }

    @media (hover: hover) {
        &:hover {
            opacity: 0.8;
        }
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

import { Component, createContext } from "react";
import { nanoid } from "nanoid";
import { pages } from "../layout/Navbar/pages";
import { withSnackbar } from "notistack";
import { CheckToken } from "../services/authentication";
import { store } from "../app/store";
import { saveUserInfo } from "../app/reducers/userReducer";
import { throttle } from "throttle-debounce";
import { withCultureContext } from "./cultureContext/CultureContext";

export const SUPER_USER_GROUP_ID = "929ea950-16e8-4365-9f09-e3160d220c46";
const path = "/general-api/info/connection";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function getSystemVariableValue(generalInfo, serviceCode, varCode, defaultValue) {
    const variablesService = generalInfo?.services?.find((s) => s.code === "service.api.variables");

    if (!variablesService) {
        return defaultValue;
    }

    const values = variablesService.properties?.values;

    if (!values) {
        return defaultValue;
    }

    const valueProperties = values.find((v) => v.serviceCode === serviceCode && v.code === varCode)?.properties;

    if (!valueProperties) {
        return getSystemVariableDefaultValue(generalInfo, serviceCode, varCode) ?? defaultValue;
    }

    const value = valueProperties?.value;

    if (value === undefined || value === null) {
        return defaultValue;
    }

    return value;
}

function getSystemVariableDefaultValue(generalInfo, serviceCode, varCode) {
    const service = generalInfo?.services?.find((s) => s.code === serviceCode);
    return service?.properties?.systemVariables?.find((sv) => sv.code === varCode)?.default;
}

export function getServiceConfig(generalInfo, serviceCode) {
    if (!serviceCode || serviceCode === "") {
        return {};
    }

    const service = generalInfo?.services?.find((s) => serviceCode === s.code);
    if (!service) {
        return {};
    }

    return service?.properties?.defaults ?? {};
}

export function getDeviceTypesByFeature(adapters, features) {
    if (!adapters || !features) {
        return [];
    }

    let result = adapters;
    if (Array.isArray(features)) {
        result = result.filter((item) => item.deviceTypes?.some((t) => t.features?.some((f) => features.includes(f))));
    } else {
        result = result.filter((item) => item.deviceTypes?.some((t) => t.features?.includes(features)));
    }

    return result
        .map((item) => item.deviceTypes ?? [])
        .flat()
        .map((item) => item.id);
}

export function getEquipmentType(generalInfo, device) {
    const adapters = getAdapters(generalInfo) ?? [];
    const isCamera = checkFeatureByDeviceType(device?.typeId, adapters, "video.stream");
    return isCamera ? "Камера" : "Устройство";
}

export function checkFeatureByDeviceType(deviceTypeValue, adapters, feature) {
    if (!adapters || !feature || !deviceTypeValue) {
        return false;
    }

    return adapters.some((adapter) =>
        adapter.deviceTypes?.some(
            (deviceType) => deviceType?.id === deviceTypeValue && deviceType?.features?.includes(feature)
        )
    );
}

export function checkManyFeaturesByDeviceType(deviceTypeValue, adapters, features) {
    if (!adapters || !features || !deviceTypeValue) {
        return false;
    }

    if (!Array.isArray(features)) {
        return false;
    }

    return adapters.some((adapter) =>
        adapter.deviceTypes?.some(
            (deviceType) =>
                deviceType?.id === deviceTypeValue && features.every((f) => deviceType?.features?.includes(f))
        )
    );
}

export const checkSuperUser = (userInfoOrUser) => {
    return userInfoOrUser?.groupId === SUPER_USER_GROUP_ID;
};

function addPermissionToSection(permissionsTo, section, permission) {
    const sectionPath = section?.sectionCode?.split(".") ?? [];
    let root = permissionsTo ?? [];
    for (let i = 0; i < sectionPath.length; ++i) {
        let child = root.find((p) => p.sectionCode === sectionPath[i]);
        if (!child) {
            child = {
                id: `group-${nanoid()}`,
                sectionCode: sectionPath[i],
                children: [],
            };
            root.push(child);
        }

        root = child.children;
        if (i === sectionPath.length - 1 && section.sectionName) {
            child.name = section.sectionName;
        }
    }

    root.push({
        id: permission.code,
        name: permission.name,
    });
}

function appendPermissions(permissionsTo, permissionsFrom) {
    permissionsFrom?.forEach((permission) => {
        if (permission.sections) {
            permission.sections?.forEach((section) => {
                addPermissionToSection(permissionsTo, section, permission);
            });
        }
    });
}

function appendEventPermissions(permissionsTo, permissionsFrom) {
    permissionsFrom?.forEach((eventType) => {
        if (eventType.permissionSections) {
            eventType.permissionSections?.forEach((section) => {
                addPermissionToSection(permissionsTo, section, eventType);
            });
        }
    });
}

export function fillAdapter(service) {
    if (!service.properties?.adapter) {
        return null;
    }

    let adapter = service.properties?.adapter;
    adapter.deviceTypes = service.properties?.deviceTypes;
    adapter.eventTypes = service.properties?.eventTypes;
    adapter.serviceId = service.serviceId;
    adapter.serviceName = service.serviceName;
    adapter.serviceCode = service.code;

    return adapter;
}

export function getAdapters(generalInfo) {
    let adapters = [];

    if (generalInfo?.services) {
        generalInfo?.services.forEach((service) => {
            const adapter = fillAdapter(service);
            if (adapter) {
                adapters.push(adapter);
            }
        });
    }

    return adapters;
}

export function getGeneralLicenses(generalInfo) {
    let licenses = [];

    generalInfo?.services?.forEach((service) => {
        if (service.properties?.license?.sectionCode && service.properties?.license?.licenseCode) {
            appendLicense(licenses, service.properties?.license);
        }
    });

    return licenses;
}

function appendLicense(licenses, license) {
    let existLicense = licenses.find((l) => l.sectionCode === license.sectionCode);

    if (existLicense && !existLicense.licenseCodes.includes(license.licenseCode)) {
        existLicense.licenseCodes.push(license.licenseCode);
        if (!existLicense.sectionName) {
            existLicense.sectionName = license.sectionName;
        }
    } else {
        let licenseToPush = {
            sectionName: license.sectionName,
            sectionCode: license.sectionCode,
            licenseCodes: [license.licenseCode],
        };

        licenses.push(licenseToPush);
    }
}

export function getGeneralPermissionsTree(generalInfo) {
    let permissions = [];

    if (generalInfo?.services) {
        generalInfo?.services.forEach((service) => {
            if (service.properties?.permissions) {
                appendPermissions(permissions, service.properties?.permissions);
            }

            if (service.properties?.eventTypes) {
                appendEventPermissions(permissions, service.properties.eventTypes);
            }
        });
    }

    if (generalInfo?.defaultPermissions?.general) {
        appendPermissions(permissions, generalInfo.defaultPermissions.general);
    }

    return permissions;
}

function addPermissionTree(tree, permissions, parentId) {
    permissions?.forEach((item) => {
        let section = tree.find((s) => s.id === item.section);
        if (!section) {
            section = {
                id: item.section,
                name: item.section,
                children: [],
            };

            tree.push(section);
        }

        if (!section.children) {
            section.children = [];
        }

        item.permissions?.forEach((p) => {
            let code = parentId ? `${parentId}.${p.code}` : p.code;
            if (!section.children.some((c) => c.id === code)) {
                section.children.push({
                    name: p.name,
                    id: parentId ? `${parentId}.${p.code}` : p.code,
                });
            }
        });
    });
}

export function getDeviceGroupsPermissionsTree(deviceGroups, generalInfo) {
    let services = generalInfo?.services?.filter((s) => s.properties?.deviceTypes) ?? [];
    let deviceTypes = [];
    services.forEach((s) => {
        deviceTypes = deviceTypes.concat(s.properties.deviceTypes);
    });

    return (
        deviceGroups?.map((dg) => {
            let permissions = [];
            deviceTypes.forEach((dt) => {
                if (dt.permissions) {
                    addPermissionTree(permissions, dt.permissions, dg.id);
                }
            });

            return {
                id: dg.id,
                name: dg.name,
                children: permissions,
            };
        }) ?? []
    );
}

export function getDevicesTypesPermissionsTree(generalInfo) {
    let services = generalInfo?.services?.filter((s) => s.properties?.deviceTypes) ?? [];
    let deviceTypes = [];
    services.forEach((s) => {
        deviceTypes = deviceTypes.concat(s.properties.deviceTypes);
    });

    return deviceTypes.map((dt) => {
        let id = `${dt.id}`;
        let permissions = [];
        if (dt.permissions) {
            addPermissionTree(permissions, dt.permissions, id);
        }

        return {
            id: id,
            name: dt.name,
            children: permissions,
        };
    });
}

export function getDeviceTypes(generalInfo) {
    if (generalInfo?.services && generalInfo?.services?.length > 0) {
        const services = generalInfo.services.filter((service) => service.properties?.deviceTypes);
        const deviceTypes = [];
        services.forEach((service) => {
            service?.properties?.deviceTypes.forEach((dt) => {
                deviceTypes.push({
                    label: dt.name,
                    value: dt.id,
                    settings: dt.settings ?? {},
                    icon: dt.icon,
                    serviceId: service.serviceId,
                    serviceCode: service.code,
                });
            });
        });

        return deviceTypes.flat().sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        });
    }

    return [];
}

export function getEventTypesSettings(generalInfo) {
    if (generalInfo?.services && generalInfo?.services?.length > 0) {
        let filteredServices = generalInfo.services
            .filter((service) => service.properties?.eventTypes)
            .filter((service) => service.properties?.adapter);

        let adapters = getAdapters(generalInfo);

        let eventItems = [];
        for (let service of filteredServices) {
            let adapter = service.properties.adapter;
            let adapterEvents = [];
            for (let currentAdapter of adapters) {
                if (currentAdapter.id === adapter.id) {
                    adapterEvents.push({
                        id: currentAdapter.id,
                        name: currentAdapter.name,
                        children: currentAdapter.eventTypes ?? [],
                    });
                }
            }

            let serviceEvents =
                service.properties?.eventTypes?.map((et) => {
                    return {
                        id: et.code,
                        group: et.group,
                        unit: et.unit,
                        name: et.name,
                        eventName: adapter.eventName,
                        code: et.code,
                        events: adapterEvents,
                        description: { adapter: adapter.name },
                        serviceCode: service.code,
                    };
                }) ?? [];

            eventItems.push(...serviceEvents);
        }

        let eventTypes = [];
        eventItems.forEach((event) => {
            if (!eventTypes[event.unit.code]) {
                eventTypes[event.unit.code] = {
                    id: event.unit.code,
                    name: event.eventName ?? event.unit.name,
                    code: event.unit.code,
                    children: [event],
                };
            } else {
                eventTypes[event.unit.code].children.push(event);
            }
        });

        return Object.values(eventTypes);
    }
    return [];
}

export function getEventTypes(generalInfo) {
    if (generalInfo?.services && generalInfo?.services?.length > 0) {
        return generalInfo.services
            .filter((service) => service.properties?.eventTypes)
            .map((service) => service.properties.eventTypes)
            .flat()
            .map((et) => {
                return {
                    group: et.group,
                    unit: et.unit,
                    label: et.name,
                    value: et.code,
                    model: et.model,
                };
            })
            .sort((a, b) => {
                if (a.label > b.label) return 1;
                if (a.label < b.label) return -1;
                return 0;
            });
    }
    return [];
}

export function serviceExists(generalInfo, servicesCodes) {
    if (!servicesCodes || servicesCodes === "") {
        return false;
    }

    if (servicesCodes === "alwaysAllow") {
        return true;
    }

    if (!Array.isArray(servicesCodes)) {
        servicesCodes = [servicesCodes];
    }

    return generalInfo?.services
        ? servicesCodes.every((sc) => generalInfo?.services.some((service) => service.code === sc))
        : false;
}

export function permissionExists(userInfo, permissions) {
    if (checkSuperUser(userInfo)) {
        return true;
    }

    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }
    const allUserPermissions = userInfo?.permissions;

    if (!(allUserPermissions && allUserPermissions.length >= 0)) {
        return false;
    }

    if (permissions.length === 0) {
        return true;
    }

    return permissions.every((p) => allUserPermissions.some((up) => up === p));
}

export function anyPermissionExists(userInfo, permissions) {
    if (checkSuperUser(userInfo)) {
        return true;
    }

    if (!Array.isArray(permissions)) {
        permissions = [permissions];
    }
    const allUserPermissions = userInfo?.permissions;

    if (!(allUserPermissions && allUserPermissions.length >= 0)) {
        return false;
    }

    if (permissions.length === 0) {
        return true;
    }

    return permissions.some((p) => allUserPermissions.some((up) => up === p));
}

export function defaultRoute(generalInfo, userInfo) {
    const token = localStorage.getItem("token");

    if (!token) {
        return "/login";
    }

    if (!generalInfo?.services) {
        return undefined;
    }

    const redirect = pages.find((route) => {
        return serviceExists(generalInfo, route?.serviceCodes) && permissionExists(userInfo, route?.permissions ?? []);
    });

    if (!redirect) {
        return "/settings";
    }

    return `${redirect.url}`;
}

export function getExtensions(generalInfo, entity) {
    return (
        generalInfo?.services
            ?.filter((s) => s.properties?.extensions?.some((e) => e.entity === entity))
            .map((s) => s.properties.extensions)
            .flat() ?? []
    );
}

export function getAllServiceFeatures(generalInfo) {
    if (generalInfo?.services && generalInfo?.services?.length > 0) {
        return generalInfo.services
            .filter((service) => service.properties?.features)
            .map((service) => service.properties.features)
            .flat()
            .sort((a, b) => {
                if (a.featureClassName > b.featureClassName) return 1;
                if (a.featureClassName < b.featureClassName) return -1;
                return 0;
            });
    }

    return [];
}

export function getServiceFeature(generalInfo, serviceCode, featureCode) {
    let serviceFeatures;

    if (generalInfo?.services && generalInfo?.services?.length > 0 && !!serviceCode && !!featureCode) {
        const service = generalInfo.services.find((service) => service.code === serviceCode);

        if (service?.properties?.features) {
            serviceFeatures = service?.properties?.features.find((feature) => feature.featureCode === featureCode);
        }
    }

    return serviceFeatures;
}

export function getServicesByFeatures(generalInfo, featureCodes) {
    return (
        generalInfo?.services?.filter((s) =>
            s?.properties?.features?.some((f) => featureCodes?.includes(f.featureCode))
        ) ?? []
    );
}

export function getFeatureByServiceId(generalInfo, serviceId) {
    return generalInfo?.services?.find((s) => s.serviceId === serviceId)?.properties?.features[0];
}

export function getAvailableSystemVariables(generalInfo) {
    return generalInfo?.services
        ?.filter((s) => s.properties?.systemVariables)
        .map((s) => s.properties?.systemVariables.map((v) => ({ ...v, serviceCode: s.code })))
        .flat();
}

export function getServiceFeaturesByServiceId(generalInfo, serviceId) {
    if (generalInfo?.services && generalInfo?.services?.length > 0) {
        return (
            generalInfo.services.find((service) => service.properties?.features && service.serviceId === serviceId)
                ?.properties?.features ?? []
        );
    }

    return [];
}

export function getAllEventFilters(generalInfo) {
    if (generalInfo?.services && generalInfo?.services?.length > 0) {
        return generalInfo.services
            .filter((service) => service.properties?.eventFilters)
            .map((service) => service.properties.eventFilters)
            .flat()
            .sort((a, b) => {
                if (a.featureClassName > b.featureClassName) return 1;
                if (a.featureClassName < b.featureClassName) return -1;
                return 0;
            });
    }

    return [];
}

export function getCommand(generalInfo, serviceCode, commandCode) {
    return generalInfo?.services
        ?.find((s) => s.code === serviceCode)
        ?.properties?.commands?.list?.find((c) => c.code === commandCode);
}

export const GeneralContext = createContext({});

class GeneralContextProviderClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generalInfo: {},
        };

        this.throttleShowMessage = throttle(
            1000,
            () =>
                this.props.enqueueSnackbar(this.props.strings("Состав полномочий был изменен"), { variant: "success" }),
            {
                noLeading: false,
                noTrailing: true,
            }
        );
    }

    createWebsocket() {
        const token = localStorage.getItem("token");

        const wsProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
        this.socket = new WebSocket(`${wsProtocol}${window.location.host}${path}`);
        this.socket.onopen = async () => {
            this.socket.send(JSON.stringify({ token }));
        };

        this.socket.onclose = async () => {
            this.setState({ generalInfo: {} });
            console.error(`Connection to ${path} closed.`);
            await sleep(1000);
            this.createWebsocket();
        };

        this.socket.onmessage = async (ev) => {
            if (!ev?.data) {
                console.error("general-info data is null!");
                return;
            }

            try {
                const generalInfo = JSON.parse(ev.data);

                if (generalInfo.isActualize) {
                    await CheckToken(token, (userInfo) => {
                        store.dispatch(saveUserInfo({ ...userInfo, updateId: nanoid() }));
                    });

                    this.throttleShowMessage();
                }

                this.setState({ generalInfo });
            } catch (ex) {
                console.error(`Invalid general-info data: ${ev.data}!`);
            }
        };
    }

    componentDidMount() {
        this.createWebsocket();
    }

    componentWillUnmount() {
        try {
            this?.socket.close();
        } catch (ex) {
            console.error(ex);
        }
    }

    render() {
        return <GeneralContext.Provider value={this.state.generalInfo}>{this.props.children}</GeneralContext.Provider>;
    }
}

export class GeneralContextConsumer extends Component {
    render() {
        return <GeneralContext.Consumer>{this.props.children}</GeneralContext.Consumer>;
    }
}

class GeneralContextWrapper extends Component {
    render() {
        const token = window.localStorage.getItem("token");

        if (token) {
            return (
                <GeneralContextProviderClassWithSnackBar>{this.props.children}</GeneralContextProviderClassWithSnackBar>
            );
        } else {
            return this.props.children;
        }
    }
}

export function withGeneralContext(WrappedComponent) {
    return class extends Component {
        render() {
            return (
                <GeneralContextConsumer>
                    {(generalInfo) => <WrappedComponent {...this.props} generalInfo={generalInfo} />}
                </GeneralContextConsumer>
            );
        }
    };
}

const GeneralContextProviderClassWithSnackBar = withCultureContext(withSnackbar(GeneralContextProviderClass));

export const GeneralContextProvider = withSnackbar(GeneralContextWrapper);

import React, { createRef } from "react";
import { CameraLayoutItem, CameraPlaceholder } from "../../../components/CameraLayout";
import { GetLocationList } from "../../../../services/locations";
import SyncArchivePlayer from "./SyncArchivePlayer";
import { GeneralContextConsumer } from "../../../../contexts";

export class SyncArchivePlayerWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.archiveRef = createRef();

        this.state = {
            selectedFrameLocationName: undefined,
        };
    }

    componentDidMount() {
        const currentFrame = this.props.frames[this.props.frameIndex];

        if (currentFrame) {
            this.getLocationById(currentFrame.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }

    getLocationById = async (locationId) => {
        if (!locationId) {
            return;
        }

        let [locationsStatus, locations] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locations?.length) {
            this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
            return;
        }

        return locations?.[0];
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevSelectedFrame = prevProps.frames[prevProps.frameIndex];
        const selectedFrame = this.props.frames[this.props.frameIndex];

        if (prevSelectedFrame?.id !== selectedFrame?.id) {
            this.getLocationById(selectedFrame?.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }

    render() {
        const {
            frameIndex,
            frames,
            selectedCameraFrame,
            handleCameraFrameSelect,
            handleCameraFrameClear,
            showCameraInfo,
            handleMainOffset,
            dates,
            mainOffset,
            intersection,
            scale,
            scaleDirection,
            playerMode,
            removeFromFfmpegMemory,
            writeToFfmpegMemory,
            convertToPng,
            readFromFfmpegMemory,
        } = this.props;

        const currentFrame = frames[frameIndex];
        const clientProtocol = currentFrame?.properties?.settings?.clientProtocol;

        return (
            <CameraLayoutItem
                isSelected={selectedCameraFrame === frameIndex}
                onSelect={() => handleCameraFrameSelect(frameIndex)}
                onClear={() => handleCameraFrameClear(frameIndex)}
                hasItem={currentFrame && currentFrame.id}
                label={currentFrame && currentFrame.name}
                locationName={this.state.selectedFrameLocationName}
                showCameraInfo={showCameraInfo}
                placeholderLabel="Камера не выбрана"
                selectedPlaceholderLabel="Выберите камеру"
            >
                {currentFrame && currentFrame.id && currentFrame?.ranges?.from && currentFrame?.ranges?.to ? (
                    <GeneralContextConsumer>
                        {(generalInfo) => (
                            <SyncArchivePlayer
                                removeFromFfmpegMemory={removeFromFfmpegMemory}
                                writeToFfmpegMemory={writeToFfmpegMemory}
                                convertToPng={convertToPng}
                                readFromFfmpegMemory={readFromFfmpegMemory}
                                intersection={intersection}
                                handleMainOffset={handleMainOffset}
                                nextRef={this.archiveRef}
                                playerMode={playerMode}
                                scale={scale}
                                scaleDirection={scaleDirection}
                                mainOffset={mainOffset}
                                dates={dates}
                                locationName={this.state.selectedFrameLocationName}
                                clientProtocol={clientProtocol}
                                currentFrame={currentFrame}
                                frameIndex={frameIndex}
                                key={playerMode}
                                generalInfo={generalInfo}
                            />
                        )}
                    </GeneralContextConsumer>
                ) : (
                    <CameraPlaceholder type="archive" />
                )}
            </CameraLayoutItem>
        );
    }
}

import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { Table, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsTabDeviceType extends React.Component {
    getRowText = (paramCode) => {
        const { selectedDevice, strings } = this.props;
        const settings = selectedDevice?.properties?.settings;

        const isArcive = settings?.isArchive === false || settings?.isArchive === undefined;
        switch (paramCode) {
            case "archiveDepth":
                if (isArcive) {
                    return "";
                }
                break;
            case "isArchive":
                if (isArcive) {
                    return strings("Нет");
                }
                break;
            default:
                break;
        }

        const code = settings?.[paramCode];
        switch (code) {
            case true:
                return strings("Да");
            case false:
                return strings("Нет");
            case undefined:
                return "";
            case null:
                return "";
            default:
                return code;
        }
    };

    render() {
        const { deviceGeneralSettings, strings, selectedDevice } = this.props;

        return (
            <DetailsBoxContent>
                <Table isFixed noPadding>
                    <TableBody>
                        {deviceGeneralSettings &&
                            deviceGeneralSettings
                                ?.filter((param) => !param?.code?.toLowerCase()?.includes("pass"))
                                ?.map((param) => {
                                    switch (param.type) {
                                        case "archive-select-input":
                                            const archiveTypeDepth = param?.select?.options.find(opt => opt.value === this.getRowText(param?.select?.code))?.label;
                                            const isArchive = selectedDevice?.properties?.settings[param?.checkbox?.code];

                                            return (
                                                <>
                                                    <TableRow key={param?.code}>
                                                        <TableCell>{strings(param?.checkbox?.name) ?? ""}</TableCell>
                                                        <TableCell>
                                                            <Text color="primary" variant="body-sm">
                                                                {`${param?.code ? this.getRowText(param?.checkbox?.code) : ""}`}
                                                            </Text>
                                                        </TableCell>
                                                    </TableRow>
                                                    {isArchive && (
                                                        <TableRow key={param?.code}>
                                                            <TableCell>{strings(param?.input?.name) ?? ""}</TableCell>
                                                            <TableCell>
                                                                <Text color="primary" variant="body-sm">
                                                                    {`${param?.code ? `${this.getRowText(param?.input?.code)} ${strings(archiveTypeDepth)}` : ""}`}
                                                                </Text>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </>

                                            );
                                        default:
                                            return (
                                                <TableRow key={param?.code}>
                                                    <TableCell>{strings(param?.name) ?? ""}</TableCell>
                                                    <TableCell>
                                                        <Text color="primary" variant="body-sm">
                                                            {`${param?.code ? this.getRowText(param.code) : ""}`}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                    }
                                })}
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(DetailsTabDeviceType);

import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import {
    TextField,
    Button,
    Row,
    TimeInput,
    Icon,
    Datepicker,
    Checkbox,
    DayMonthInput,
    Select,
} from "headpoint-react-components";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";

const getKey = (index) => `key-${index}`;

export const TimeOption = ({ times, setTimes }) => {
    const handleAddItem = () => {
        const newTimes = [...times, "00:00"];
        setTimes(newTimes);
    };

    const handleRemoveItem = (index) => {
        const newTimes = [...times];

        newTimes.splice(index, 1);
        setTimes(newTimes);
    };

    const handleChangeItem = (index, val) => {
        const newTimes = [...times];

        newTimes[index] = val;

        setTimes(newTimes);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <InputRow>
                        {times.map((item, i) => (
                            <InputItem key={getKey(i)}>
                                {times.length > 1 && (
                                    <InputItemRemoveBtn type="button" onClick={() => handleRemoveItem(i)} />
                                )}

                                <TimeInput
                                    value={item}
                                    onChange={(e) => handleChangeItem(i, e.target.value)}
                                    type="time"
                                    width={70}
                                    colorVariant="light"
                                />
                            </InputItem>
                        ))}

                        <InputItem>
                            <Button
                                variant="secondary"
                                icon="plus"
                                title={strings("Добавить")}
                                onClick={handleAddItem}
                            />
                        </InputItem>
                    </InputRow>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const RegularIntervalOption = ({ intervals, setIntervals }) => {
    const defaultInterval = {
        from: "08:00",
        to: "11:00",
        repeatValue: "30",
        repeatType: "m",
    };

    const handleChangeItem = (index, key, val) => {
        const newIntervals = [...intervals];
        const newInterval = newIntervals[index];
        newInterval[key] = val;
        setIntervals(newIntervals);
    };

    const handleRemoveItem = (index) => {
        const newIntervals = [...intervals];
        newIntervals.splice(index, 1);
        setIntervals(newIntervals);
    };

    const handleAddItem = () => {
        const newIntervals = [...intervals, { ...defaultInterval }];
        setIntervals(newIntervals);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <div>
                        {intervals.map((item, i) => (
                            <Row key={getKey(i)} marginLeft={-8} marginBottom={14} align="bottom">
                                <InputItemInline>
                                    <TimeInput
                                        label={strings("Начало")}
                                        value={item.from}
                                        onChange={(e) => handleChangeItem(i, "from", e.target.value)}
                                        type="time"
                                        width={70}
                                        colorVariant="light"
                                    />
                                </InputItemInline>

                                <FormaDateDivider>-</FormaDateDivider>

                                <InputItemInline>
                                    <TimeInput
                                        label={strings("Конец")}
                                        value={item.to}
                                        onChange={(e) => handleChangeItem(i, "to", e.target.value)}
                                        type="time"
                                        width={70}
                                        colorVariant="light"
                                    />
                                </InputItemInline>

                                <InputItemInline>
                                    <TextField
                                        label={strings("Повтор")}
                                        value={item.repeatValue}
                                        onChange={(e) => handleChangeItem(i, "repeatValue", e.target.value)}
                                        type="number"
                                        width={50}
                                        colorVariant="light"
                                    />
                                </InputItemInline>
                                <InputItemInline>
                                    <Select
                                        value={item.repeatType}
                                        options={[
                                            { value: "m", label: strings("Мин") },
                                            { value: "h", label: strings("Ч") },
                                        ]}
                                        onChange={(e) => handleChangeItem(i, "repeatType", e)}
                                        width={80}
                                    />
                                </InputItemInline>

                                {intervals.length > 1 && (
                                    <InputItemInline>
                                        <Button
                                            icon="trash"
                                            title="remove"
                                            variant="ghost-danger"
                                            onClick={() => handleRemoveItem(i)}
                                        />
                                    </InputItemInline>
                                )}
                            </Row>
                        ))}

                        <Row marginTop={16}>
                            <Button
                                icon="plus"
                                label={strings("ещё интервал")}
                                noPadding
                                onClick={handleAddItem}
                                title={strings("ещё интервал")}
                                variant="ghost"
                            />
                        </Row>
                    </div>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const TimeIntervalOption = ({ intervals, setIntervals }) => {
    const defaultInterval = {
        from: "08:00",
        to: "11:00",
    };

    const handleChangeItem = (index, key, val) => {
        const newIntervals = [...intervals];
        const newInterval = newIntervals[index];
        newInterval[key] = val;
        setIntervals(newIntervals);
    };

    const handleRemoveItem = (index) => {
        const newIntervals = [...intervals];
        newIntervals.splice(index, 1);
        setIntervals(newIntervals);
    };

    const handleAddItem = () => {
        const newIntervals = [...intervals, { ...defaultInterval }];
        setIntervals(newIntervals);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <div>
                        {intervals.map((item, i) => (
                            <Row key={getKey(i)} marginLeft={-8} marginBottom={14} align="bottom">
                                <InputItemInline>
                                    <TimeInput
                                        label={strings("Начало")}
                                        value={item.from}
                                        onChange={(e) => handleChangeItem(i, "from", e.target.value)}
                                        type="time"
                                        width={70}
                                        colorVariant="light"
                                    />
                                </InputItemInline>

                                <FormaDateDivider>—</FormaDateDivider>

                                <InputItemInline>
                                    <TimeInput
                                        label={strings("Конец")}
                                        value={item.to}
                                        onChange={(e) => handleChangeItem(i, "to", e.target.value)}
                                        type="time"
                                        width={70}
                                        colorVariant="light"
                                    />
                                </InputItemInline>

                                {intervals.length > 1 && (
                                    <InputItemInline>
                                        <Button
                                            icon="trash"
                                            title="remove"
                                            variant="ghost-danger"
                                            onClick={() => handleRemoveItem(i)}
                                        />
                                    </InputItemInline>
                                )}
                            </Row>
                        ))}

                        <Row marginTop={16}>
                            <Button
                                icon="plus"
                                label={strings("ещё интервал")}
                                noPadding
                                onClick={handleAddItem}
                                title={strings("ещё интервал")}
                                variant="ghost"
                            />
                        </Row>
                    </div>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const TimeDateOption = ({ dates, setDates }) => {
    const defaultDate = new Date();

    const handleAddItem = () => {
        const newDates = [...dates, defaultDate];
        setDates(newDates);
    };

    const handleRemoveItem = (index) => {
        const newDates = [...dates];
        newDates.splice(index, 1);
        setDates(newDates);
    };

    const handleChangeItem = (index, val) => {
        const newDates = [...dates];
        newDates[index] = val;
        setDates(newDates);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <InputRow>
                        {dates.map((item, i) => (
                            <InputItem key={getKey(i)}>
                                {dates.length > 1 && (
                                    <InputItemRemoveBtn type="button" onClick={() => handleRemoveItem(i)} />
                                )}

                                <Datepicker
                                    date={item}
                                    onChange={(e) => handleChangeItem(i, e)}
                                    colorVariant="transparent"
                                    width={170}
                                    inputFormat="DD.MM.YYYY HH:mm"
                                    customIcon={<Icon icon="calendar" />}
                                    withTime
                                    customInputLabels={{
                                        hours: strings("Часы"),
                                        minutes: strings("Минуты"),
                                    }}
                                />
                            </InputItem>
                        ))}

                        <InputItem>
                            <Button
                                variant="secondary"
                                icon="plus"
                                title={strings("Добавить")}
                                onClick={handleAddItem}
                            />
                        </InputItem>
                    </InputRow>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const DateOption = ({ dates, setDates }) => {
    const defaultDate = new Date();

    const handleAddItem = () => {
        const newDates = [...dates, defaultDate];
        setDates(newDates);
    };

    const handleRemoveItem = (index) => {
        const newDates = [...dates];
        newDates.splice(index, 1);
        setDates(newDates);
    };

    const handleChangeItem = (index, val) => {
        const newDates = [...dates];
        newDates[index] = val;
        setDates(newDates);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <InputRow>
                        {dates.map((item, i) => (
                            <InputItem key={getKey(i)}>
                                {dates.length > 1 && (
                                    <InputItemRemoveBtn type="button" onClick={() => handleRemoveItem(i)} />
                                )}

                                <Datepicker
                                    date={item}
                                    onChange={(e) => handleChangeItem(i, e)}
                                    colorVariant="transparent"
                                    width={144}
                                    inputFormat="DD.MM.YYYY"
                                    customIcon={<Icon icon="calendar" />}
                                />
                            </InputItem>
                        ))}

                        <InputItem>
                            <Button
                                variant="secondary"
                                icon="plus"
                                title={strings("Добавить")}
                                onClick={handleAddItem}
                            />
                        </InputItem>
                    </InputRow>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const RepeatDayOption = ({ dates, setDates }) => {
    const defaultDate = 1;

    const handleAddItem = () => {
        const newDates = [...dates, defaultDate];
        setDates(newDates);
    };

    const handleRemoveItem = (index) => {
        const newDates = [...dates];
        newDates.splice(index, 1);
        setDates(newDates);
    };

    const handleChangeItem = (index, val) => {
        const newDates = [...dates];
        const re = /^[0-9\s]*$/;
        if (!re.test(val) || val.length > 2 || val > 31 || val === "  ") return;
        newDates[index] = parseInt(val);
        setDates(newDates);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <div>
                        <InputRowLabel>Повтор каждый день</InputRowLabel>
                        <InputRow>
                            {dates.map((item, i) => (
                                <InputItem key={getKey(i)}>
                                    {dates.length > 1 && (
                                        <InputItemRemoveBtn type="button" onClick={() => handleRemoveItem(i)} />
                                    )}

                                    <TextField
                                        value={item}
                                        onChange={(e) => handleChangeItem(i, e.target.value)}
                                        type="text"
                                        width={60}
                                        colorVariant="light"
                                    />
                                </InputItem>
                            ))}

                            <InputItem>
                                <Button
                                    variant="secondary"
                                    icon="plus"
                                    title={strings("Добавить")}
                                    onClick={handleAddItem}
                                />
                            </InputItem>
                        </InputRow>
                    </div>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const DayOption = ({ dates, setDates }) => {
    const dayLabels = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];

    const handleChange = (val) => {
        const newDays = [...dates];
        newDays[val] = !newDays[val];
        setDates(newDays);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <DayOptionList>
                        {[...Array(7).keys()].map((day) => (
                            <DayOptionItem key={day}>
                                <Checkbox
                                    label={strings(dayLabels[day])}
                                    checked={dates[day]}
                                    onChange={() => handleChange(day)}
                                />
                            </DayOptionItem>
                        ))}
                    </DayOptionList>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const MonthOption = ({ dates, setDates }) => {
    const defaultDate = 1;

    const handleAddItem = () => {
        const newDates = [...dates, defaultDate];
        setDates(newDates);
    };

    const handleRemoveItem = (index) => {
        const newDates = [...dates];
        newDates.splice(index, 1);
        setDates(newDates);
    };

    const handleChangeItem = (index, val) => {
        const newDates = [...dates];
        const re = /^[0-9\s]*$/;
        if (!re.test(val) || val.length > 2 || val > 31 || val === "  ") return;
        newDates[index] = parseInt(val);
        setDates(newDates);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <div>
                        <InputRowLabel>Число месяца</InputRowLabel>
                        <InputRow>
                            {dates.map((item, i) => (
                                <InputItem key={getKey(i)}>
                                    {dates.length > 1 && (
                                        <InputItemRemoveBtn type="button" onClick={() => handleRemoveItem(i)} />
                                    )}

                                    <TextField
                                        value={item}
                                        onChange={(e) => handleChangeItem(i, e.target.value)}
                                        type="text"
                                        width={60}
                                        colorVariant="light"
                                    />
                                </InputItem>
                            ))}

                            <InputItem>
                                <Button
                                    variant="secondary"
                                    icon="plus"
                                    title={strings("Добавить")}
                                    onClick={handleAddItem}
                                />
                            </InputItem>
                        </InputRow>
                    </div>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

export const YearOption = ({ dates, setDates }) => {
    const defaultDay = {
        day: 1,
        month: 0,
    };

    const handleAddItem = () => {
        const newDays = [...dates, defaultDay];
        setDates(newDays);
    };

    const handleRemoveItem = (index) => {
        const newDays = [...dates];
        newDays.splice(index, 1);
        setDates(newDays);
    };

    const handleChangeItem = (index, val) => {
        const newDays = [...dates];
        newDays[index] = val;
        setDates(newDays);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Row marginTop={16}>
                    <InputRow>
                        {dates.map((item, i) => (
                            <InputItem key={getKey(i)}>
                                {dates.length > 1 && (
                                    <InputItemRemoveBtn type="button" onClick={() => handleRemoveItem(i)} />
                                )}

                                <DayMonthInput
                                    label={strings("Число и месяц")}
                                    value={item}
                                    onChange={(e) => handleChangeItem(i, e)}
                                    locale="ru"
                                />
                            </InputItem>
                        ))}

                        <InputItem>
                            <Button
                                variant="secondary"
                                icon="plus"
                                title={strings("Добавить")}
                                onClick={handleAddItem}
                            />
                        </InputItem>
                    </InputRow>
                </Row>
            )}
        </CultureContextConsumer>
    );
};

const DayOptionList = styled.div`
    column-count: 2;
`;

const DayOptionItem = styled.div`
    min-width: 160px;
    padding: 6px 0;
    -webkit-column-break-inside: avoid;
`;

const InputRowLabel = styled.div`
    display: block;
    font-family: ${({ theme }) => theme.fonts.regular};
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`;

const InputItem = styled.div`
    position: relative;
    display: inline-block;
    margin: ${({ inLine }) => (inLine ? "0" : "8px")};
`;

const InputItemInline = styled.div`
    position: relative;
    display: inline-block;
    margin: 0 8px;
`;

const InputItemRemoveBtn = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: none;
    text-align: center;
    cursor: pointer;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 26px;
    right: -4px;
    z-index: 2;

    &:after {
        content: "";
        width: 7px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.primary2};
        position: absolute;
        top: 6px;
        left: 3px;
        z-index: 1;
    }
`;

const InputRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: -8px;
`;

const FormaDateDivider = styled.span`
    height: 24px;
    margin-top: auto;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
`;

import React from "react";
import styled, { css } from "styled-components";
import Navbar from "./Navbar";

export const Layout = ({ children }) => (
    <LayoutWrapper>
        <Navbar />
        <LayoutInner>{children}</LayoutInner>
    </LayoutWrapper>
);

export const LayoutPage = styled.div`
    display: flex;
    flex: 1;
`;

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    min-width: 1280px;
`;

const LayoutInner = styled.div`
    display: flex;
    flex: 1;
    border-top: 1px solid ${({ theme }) => theme.colors.primary3};
`;

export const LayoutSidebar = styled.aside`
    display: flex;
    flex-direction: column;
    width: 256px;
    height: calc(100vh - 56px);
    background-color: ${({ theme }) => theme.colors.primary1};
    border-right: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 12px 0 32px 0;
`;

export const LayoutSidebarBody = styled.div`
    flex: 1;
    overflow: auto;
    max-height: calc(100vh - 200px);
`;

export const LayoutSidebarItem = styled.div`
    margin-bottom: 16px;
    padding: 0 16px;

    & > & {
        padding: 0;
    }

    ${({ hasHeight }) =>
        hasHeight &&
        css`
            max-height: ${hasHeight};
        `}

    ${({ hasScroll }) =>
        hasScroll &&
        css`
            overflow: auto;
        `}
`;

export const LayoutSidebarFooter = styled.div`
    margin-top: auto;
    padding-top: 16px;

    ${({ align }) =>
        align &&
        css`
            text-align: ${align};
        `}
`;

export const LayoutSidebarFooterFilters = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    & > * + * {
        margin-left: 8px;
    }
`;

export const LayoutMain = styled.main`
    display: flex;
    flex-direction: column;
    width: calc(100% - 257px);
`;

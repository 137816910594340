import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    CreateSchedule,
    DeleteSchedule,
    GetSchedule,
    GetScheduleInfo,
    GetSchedules,
    PauseSchedule,
    PlaySchedule,
    UpdateSchedule,
} from "../../services/shedule";
import { convertUTCToLocal } from "../../utilites/TimeUtils";
import { DYNAMIC_FILTER_TYPE, FIXED_FILTER_TYPE, wizardScheduleDefault } from "../../features/schedule/constants";
import { GetUserById } from "../../services/users";
import moment from "moment/moment";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../features/components/CreateUpdateWizard/constants";

export const SCHEDULES_ON_PAGE = 10;

const scheduleInitialValues = {
    intervalsType: "oneTime",
    filterType: undefined,
    deviceIdsFilter: [],
    typesFilter: [],
    tagsFilter: [],
    groupsFilter: [],
};

const initialState = {
    schedules: [],
    values: { ...scheduleInitialValues },
    selectedSchedule: undefined,
    scheduleInfo: undefined,
    detailsTab: "main",
    countOnPage: SCHEDULES_ON_PAGE,
    pageNumber: 1,
    pagesCount: 0,
    offset: 0,
    search: "",
    status: {
        active: false,
        paused: false,
        completed: false,
    },
    showCreateWizard: false,
    showDeleteScheduleModal: false,
    wizardMode: "create",
    steps: wizardScheduleDefault,
    step: 1,
    activeStep: 1,
    isLoading: false,
};

export const fetchSchedules = createAsyncThunk(
    "schedules/fetchSchedules",
    async ({ filter, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [getSchedulesStatus, newSchedules, count] = await GetSchedules(filter);

        if (!getSchedulesStatus) {
            enqueueSnackbar(strings("Не удалось получить список расписаний"), { variant: "error" });
            return rejectWithValue();
        }

        return { newSchedules, count };
    }
);

export const fetchSchedule = createAsyncThunk(
    "schedules/fetchSchedule",
    async ({ selectedSchedule, enqueueSnackbar }, { rejectWithValue }) => {
        const [infoStatus, scheduleInfo] = await GetScheduleInfo(selectedSchedule);
        const [scheduleStatus, schedule] = await GetSchedule(selectedSchedule.id);
        const [userStatus, user] = await GetUserById(schedule.userId);

        if (!infoStatus || !scheduleStatus) {
            enqueueSnackbar("Ошибка получения информации о расписании", { variant: "error" });
            return rejectWithValue();
        }
        if (!userStatus) {
            enqueueSnackbar("Ошибка получения информации о пользователе", { variant: "error" });
        }

        return { ...scheduleInfo, ...schedule, user: user };
    }
);

export const createSchedule = createAsyncThunk(
    "schedules/createSchedule",
    async ({ schedule, serviceId, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let [status, scheduleResponse] = await CreateSchedule(schedule, serviceId);

        if (status === 200) {
            enqueueSnackbar(strings("Расписание создано"), { variant: "success" });

            schedule.id = scheduleResponse.id;
            schedule.serviceId = scheduleResponse.serviceId;
            schedule.status = scheduleResponse.status;
            schedule.comment = scheduleResponse.comment;

            return schedule;
        } else {
            switch (status) {
                case 400:
                    enqueueSnackbar(strings(getMessageFromBadRequest(scheduleResponse)), { variant: "error" });
                    return rejectWithValue();
                case 403: // forbidden
                    enqueueSnackbar(strings("Не хватает прав для создания расписания"), { variant: "error" });
                    return rejectWithValue();
                case 409: // conflict
                    enqueueSnackbar(strings("Расписания должны иметь уникальные имена"), { variant: "warning" });
                    return rejectWithValue();
                default:
                    enqueueSnackbar(strings("Не удалось создать расписание"), { variant: "error" });
                    return rejectWithValue();
            }
        }
    }
);

export const updateSchedule = createAsyncThunk(
    "schedules/updateSchedule",
    async ({ schedule, serviceId, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let [status, scheduleResponse] = await UpdateSchedule(schedule, serviceId);

        if (status === 200) {
            enqueueSnackbar("Расписание обновлено", { variant: "success" });

            schedule.id = scheduleResponse.id;
            schedule.serviceId = scheduleResponse.serviceId;
            schedule.status = scheduleResponse.status;
            schedule.comment = scheduleResponse.comment;

            return schedule;
        } else {
            switch (status) {
                case 400:
                    enqueueSnackbar(strings(getMessageFromBadRequest(scheduleResponse)), { variant: "error" });
                    return rejectWithValue();
                case 403: // forbidden
                    enqueueSnackbar(strings("Не хватает прав для изменения расписания"), { variant: "error" });
                    return rejectWithValue();
                case 409: // conflict
                    enqueueSnackbar(strings("Расписания должны иметь уникальные имена"), { variant: "warning" });
                    return rejectWithValue();
                default:
                    enqueueSnackbar(strings("Не удалось изменить расписание"), { variant: "error" });
                    return rejectWithValue();
            }
        }
    }
);

const getMessageFromBadRequest = (response) => {
    if (response.code === "filters_empty") {
        return "Нельзя создать расписание без фильтров!";
    }

    if (response.code === "end_time") {
        return "Время выполнения расписания не может быть позже времени завершения расписания";
    }

    return "Не удалось создать расписание";
};

export const deleteSchedule = createAsyncThunk(
    "schedules/deleteSchedule",
    async ({ schedule, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let [statusCode] = await DeleteSchedule(schedule);

        if (statusCode === 200) {
            enqueueSnackbar(`${strings("Расписание")} ${schedule.name} ${strings("удалено")}`, { variant: "success" });
            return schedule.id;
        } else {
            enqueueSnackbar("Не удалось удалить расписание", { variant: "error" });
            return rejectWithValue();
        }
    }
);

export const playSchedule = createAsyncThunk(
    "schedules/playSchedule",
    async ({ schedule, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let status = await PlaySchedule(schedule.id);

        if (status === true) {
            enqueueSnackbar(`${strings("Расписание")} ${schedule.name} ${strings("возобновлено")}`, {
                variant: "success",
            });
            return schedule.id;
        } else {
            enqueueSnackbar(strings("Не удалось возобновить расписание"), { variant: "error" });
            return rejectWithValue();
        }
    }
);

export const pauseSchedule = createAsyncThunk(
    "schedules/pauseSchedule",
    async ({ schedule, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let status = await PauseSchedule(schedule.id);

        if (status === true) {
            enqueueSnackbar(`${strings("Расписание")} ${schedule.name} ${strings("приостановлено")}`, {
                variant: "success",
            });
            return schedule.id;
        } else {
            enqueueSnackbar("Не удалось приостановить расписание", { variant: "error" });
            return rejectWithValue();
        }
    }
);

const scheduleInfoToValues = (feature, scheduleInfo) => {
    return {
        ...scheduleInfo.properties,
        id: scheduleInfo.scheduleId,
        name: scheduleInfo.name,
        start: scheduleInfo.start,
        end: scheduleInfo.end,
        groupIds: scheduleInfo.groupIds,
        type: scheduleInfo.type,
        comment: scheduleInfo.comment,
        feature: {
            ...feature,
            serviceId: scheduleInfo.serviceId,
        },
        filterType: scheduleInfo?.filters.deviceIds?.length ? FIXED_FILTER_TYPE : DYNAMIC_FILTER_TYPE,
        deviceIdsFilter: scheduleInfo?.filters?.deviceIds ?? [],
        typesFilter: scheduleInfo?.filters?.filters?.typeIds ?? [],
        tagsFilter: scheduleInfo?.filters?.filters?.tagsIds ?? [],
        groupsFilter: scheduleInfo?.filters?.filters?.groupIds ?? [],
        dates: scheduleInfo?.properties?.dates?.map((d) => moment(d, "DD.MM.YYYY").toDate()),
        times: scheduleInfo?.properties?.times?.map((t) => convertUTCToLocal(t)),
    };
};

export const scheduleSlice = createSlice({
    name: "schedule",
    initialState: initialState,
    reducers: {
        saveScheduleState: (state, { payload }) => {
            for (const key in payload) {
                state[key] = payload[key];
            }
        },
        resetValues: (state) => {
            state.values = { ...scheduleInitialValues, intervalsType: state.values.intervalsType };
        },
        showCreateWizard: (state) => {
            state.wizardMode = WIZARD_CREATE_MODE;
            state.showCreateWizard = true;
            state.values = { ...scheduleInitialValues };
        },
        showEditWizard: (state, action) => {
            state.wizardMode = WIZARD_UPDATE_MODE;
            state.showCreateWizard = true;
            state.values = scheduleInfoToValues(action.payload.feature, state.scheduleInfo);
            state.steps = action.payload.steps;
        },
        closeWizard: (state) => {
            state.showCreateWizard = false;
            state.values = { ...scheduleInitialValues };
            state.activeStep = 1;
            state.step = 1;
            state.steps = wizardScheduleDefault;
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedules.fulfilled, (state, action) => {
                const schedulesCount = action.payload.count;
                const pagesCount = Math.ceil(schedulesCount / state.countOnPage);

                state.schedules = action.payload.newSchedules;
                state.pagesCount = pagesCount;
                state.offset = 0;
            })
            .addCase(fetchSchedule.fulfilled, (state, action) => {
                state.scheduleInfo = action.payload;
            })
            .addCase(fetchSchedule.pending, (state) => {
                state.scheduleInfo = undefined;
            })
            .addCase(createSchedule.fulfilled, (state, action) => {
                state.schedules = [...state.schedules, action.payload];
                state.showCreateWizard = false;
                state.values = { ...scheduleInitialValues };
                state.activeStep = 1;
                state.step = 1;
                state.isLoading = false;
            })
            .addCase(updateSchedule.fulfilled, (state, action) => {
                state.schedules = [...state.schedules.filter((s) => s.id !== action.payload.id), action.payload];
                state.showCreateWizard = false;
                state.values = { ...scheduleInitialValues };
                state.activeStep = 1;
                state.step = 1;
                state.isLoading = false;
                state.selectedSchedule = undefined;
            })
            .addCase(deleteSchedule.fulfilled, (state, action) => {
                state.schedules = state.schedules.filter((s) => s.id !== action.payload);
                state.selectedSchedule = undefined;
                state.showDeleteScheduleModal = false;
            })
            .addCase(playSchedule.fulfilled, (state, action) => {
                state.scheduleInfo.status = "Active";

                const index = state.schedules.findIndex((s) => s.id === action.payload);
                state.schedules[index].status = "Active";
            })
            .addCase(pauseSchedule.fulfilled, (state, action) => {
                state.scheduleInfo.status = "Paused";

                const index = state.schedules.findIndex((s) => s.id === action.payload);
                state.schedules[index].status = "Paused";
            });
    },
});

export const { saveScheduleState, resetValues, showCreateWizard, showEditWizard, closeWizard } = scheduleSlice.actions;

export default scheduleSlice.reducer;

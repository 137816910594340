import React from "react";
import {
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    Space,
    TableCell,
    Row,
    Text,
    Icon,
    TableHoverContainer,
    Button,
} from "headpoint-react-components";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DeviceTypesTable extends React.Component {
    render() {
        const { selectedType, deviceTypesList, selectType, search } = this.props;

        let deviceTypes = deviceTypesList;

        if (search && search !== "") {
            deviceTypes = deviceTypesList?.filter((dg) => dg.label?.toLowerCase()?.indexOf(search?.toLowerCase()) >= 0);
        }

        deviceTypes = deviceTypes.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Тип оборудования")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {deviceTypes?.map((type) => (
                                    <TableRow
                                        key={type.value}
                                        isSelected={selectedType && type.value === selectedType.id}
                                    >
                                        <TableCell verticalAlign="middle">
                                            <Row align="middle">
                                                <Text color="primary" variant="body-sm">
                                                    <Icon icon={"adapter"} size={16} />
                                                </Text>
                                                <Space />
                                                <Text color="primary" variant="body-sm">
                                                    {strings(type.label)}
                                                </Text>
                                            </Row>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell verticalAlign="middle" noVerticalSpace>
                                            <TableHoverContainer>
                                                <Button
                                                    icon="info"
                                                    title="Info"
                                                    variant="ghost"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        selectType(type);
                                                    }}
                                                />
                                            </TableHoverContainer>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

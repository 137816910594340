import React from "react";
import { LayoutSidebar, LayoutSidebarBody, LayoutSidebarFooter, LayoutSidebarItem } from "../../../layout/MainLayout";
import { Button, Icon, TableAccordionItem, TableRow } from "headpoint-react-components";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import { permissionExists, serviceExists } from "../../../contexts";
import { SidebarNavigationItem, SidebarNavigationNestedItem } from "../../components/SidebarNavigation";
import uuid from "react-uuid";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { nanoid } from "nanoid";

const TdCell = styled.td`
    position: relative;
    display: table-cell;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 16px;
    text-align: ${({ align }) => align || "left"};
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
    vertical-align: ${({ verticalAlign }) => verticalAlign || "top"};
    white-space: pre;

    ${({ isHandle }) =>
        isHandle &&
        css`
            cursor: move;

            & * {
                pointer-events: none;
            }
        `}

    ${({ indent }) =>
        indent &&
        css`
            &,
            table tr &:first-child {
                padding-left: ${indent}px;
            }
        `}

  ${({ noVerticalSpace }) =>
        noVerticalSpace &&
        css`
            padding-top: 0;
            padding-bottom: 0;
        `}
`;

class Sidebar extends React.Component {
    parsePage = (strings, item) => {
        const { userInfo } = this.props;

        switch (item?.type) {
            case "nested":
                return (
                    <>
                        {this.existNestedService(item?.pages) ? (
                            <div key={nanoid()} style={{ margin: "8px 0 8px 0" }}>
                                <TableAccordionItem
                                    onClick={() => {
                                        item.closed = !item.closed;
                                        item.icon = item.closed ? "arrow-down" : "arrow-up";
                                    }}
                                    key={uuid()}
                                    defaultClosed={item.closed}
                                    toggle={
                                        <>
                                            <SidebarNavigationNestedItem
                                                key={uuid()}
                                                icon={item.icon && <Icon icon={item.icon} size={16} />}
                                                label={strings(item.label)}
                                            />
                                        </>
                                    }
                                >
                                    {item?.pages.map((p) => {
                                        if (
                                            serviceExists(this.props.generalInfo, p.servicesNames) &&
                                            permissionExists(userInfo, p.permissions)
                                        ) {
                                            return (
                                                <TableRow key={nanoid()}>
                                                    <TdCell>
                                                        <SidebarNavigationItem
                                                            key={p.url}
                                                            icon={p.icon && <Icon icon={p.icon} size={16} />}
                                                            label={strings(p.label)}
                                                            url={p.url}
                                                        />
                                                    </TdCell>
                                                </TableRow>
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}
                                </TableAccordionItem>
                            </div>
                        ) : null}
                    </>
                );
            default:
                return (
                    <>
                        {serviceExists(this.props.generalInfo, item.servicesNames) &&
                        permissionExists(userInfo, item.permissions) ? (
                            <SidebarNavigationItem
                                key={item.url}
                                icon={item.icon && <Icon icon={item.icon} size={16} />}
                                label={strings(item.label)}
                                url={item.url}
                            />
                        ) : null}
                    </>
                );
        }
    };

    existNestedService = (nestedPages) => {
        if (nestedPages) {
            for (const page of nestedPages) {
                if (serviceExists(this.props.generalInfo, page.servicesNames)) {
                    return true;
                }
            }
        }

        return false;
    };

    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <LayoutSidebar>
                        <LayoutSidebarBody>
                            <LayoutSidebarItem>
                                {this.props.pages.map((item) => {
                                    return this.parsePage(strings, item);
                                })}
                            </LayoutSidebarItem>
                        </LayoutSidebarBody>
                        <LayoutSidebarFooter>
                            <CultureContextConsumer>
                                {({ _, code, setCulture }) => (
                                    <Button
                                        variant="ghost"
                                        fullWidth={true}
                                        onClick={() => setCulture(code === "ru" ? "en" : "ru")}
                                        label={code === "ru" ? "Switch to english" : "Переключить на русский"}
                                    ></Button>
                                )}
                            </CultureContextConsumer>
                        </LayoutSidebarFooter>
                    </LayoutSidebar>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(Sidebar);

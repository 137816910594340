import React from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class DetailsTabMain extends React.Component {
    render() {
        const { selected } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Название")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {strings(selected.name)}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Код сервиса")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selected.serviceCode}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Код")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selected.code}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Значение")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selected.value}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Комментарий")}</TableCell>
                                    <TableCell>
                                        <Text scroll color="primary" variant="body-sm">
                                            {strings(selected.description)}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

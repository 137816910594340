export const PERSONAL_ORDER_TYPE = {
    label: "Персональный",
    value: "personal",
};
export const GROUP_ORDER_TYPE = {
    label: "Групповой",
    value: "group",
};
export const SYSTEM_ORDER_TYPE = {
    label: "Системный",
    value: "system",
};

import { urlWithQuery } from "../utilites/UrlUtils";

const path = "/plans-api/plans";
const path_points = "/plans-api/points";

export const GetPointsCount = async (planId, filter) => {
    const token = window.localStorage.getItem("token");
    try {
        let path = `${path_points}/${planId}/count`;
        let params = new URLSearchParams();
        if (filter?.deviceIds?.length) {
            filter.deviceIds.forEach((id) => params.append("deviceIds", id));
        }

        if (filter?.locationIds?.length) {
            filter.locationIds.forEach((id) => params.append("locationIds", id));
        }

        let response = await fetch(urlWithQuery(path, params), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const CompareNodes = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const AddPlanToNode = (node, plan) => {
    if (!node || node.isClosed) {
        return;
    }

    if (node.children) {
        node.children.push({ ...plan, tag: "device" });
        node.children.sort(CompareNodes);
    } else {
        node.children = [{ ...plan, tag: "device" }];
    }
};

export const AddPoint = async (point) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(path_points, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(point),
    });

    return [response.status === 200, response.status];
};

export const DeletePoint = async (deviceId) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path_points}/${deviceId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return response.status === 200;
};

export const UpdatePoint = async (pointId, point) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path_points}/${pointId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(point),
        });

        return [response.status === 200, response.status];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const GetPoints = async (planId, filter) => {
    const token = window.localStorage.getItem("token");
    try {
        let path = `${path_points}/${planId}`;
        let params = new URLSearchParams();
        if (filter?.deviceIds?.length) {
            filter.deviceIds.forEach((id) => params.append("deviceIds", id));
        }

        if (filter?.locationIds?.length) {
            filter.locationIds.forEach((id) => params.append("locationIds", id));
        }

        let response = await fetch(urlWithQuery(path, params), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

const ComparePlans = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const GetPlans = async (locationId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/${locationId}/list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data?.sort(ComparePlans) ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const SearchPlans = async (filter) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/search?` + new URLSearchParams(filter), {
            method: "Get",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const RemovePlan = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.status === 200;
};

export const CreatePlan = async (plan) => {
    try {
        const formData = new FormData();
        formData.append("name", plan.name);
        formData.append("locationId", plan.locationId);
        formData.append("planImage", plan.planImage);
        formData.append("imageWidth", plan.imageWidth);
        formData.append("imageHeight", plan.imageHeight);
        formData.append("properties", JSON.stringify(plan?.properties));

        const token = window.localStorage.getItem("token");
        let response = await fetch(path, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        return [response.ok, response.status, response.ok ? (await response.json())?.data : null];
    } catch (e) {
        console.error(e);
        return [false, null, null];
    }
};

export const UpdatePlan = async (plan) => {
    try {
        let id = plan.id;
        const formData = new FormData();
        formData.append("name", plan.name);
        formData.append("locationId", plan.locationId);
        formData.append("properties", JSON.stringify(plan?.properties));

        if (plan.planImage) {
            formData.append("planImage", plan.planImage);
            formData.append("imageWidth", plan.imageWidth);
            formData.append("imageHeight", plan.imageHeight);
        }

        const token = window.localStorage.getItem("token");
        let response = await fetch(`${path}/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        return [response.ok, response.status];
    } catch (e) {
        console.error(e);
        return [false, null];
    }
};

export const GetPlanImageUrl = (planId) => {
    return `${path}/${planId}/image`;
};

export const GetPlanInfo = async (planId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/${planId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const GetPlanByDeviceId = async (deviceId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}?deviceId=${deviceId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        return [false, null];
    }
};

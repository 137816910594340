import React from "react";
import Marker from "../../components/Marker";
import {
    checkFeatureByDeviceType,
    getAdapters,
    getDeviceTypes,
    permissionExists,
    serviceExists,
} from "../../../contexts";
import PubSub from "pubsub-js";
import { GetPoints } from "../../../services/plans";
import { withSnackbar } from "notistack";
import { CameraAngleModeButton, CameraEditAngleModeButton } from "../../components/CameraAngleButtons";
import { FREEZE_PLAN_TOPIC, LOAD_CLUSTER_VIEW_TOPIC, SHOW_DETAILS_TOPIC } from "./plansTopics";
import MarkerViewAngle from "../../components/MarkerViewAngle";
import pLimit from "p-limit";
import { AddViewZoneDelta, GetViewZone } from "../../../services/viewZones";
import { isEqual as isDeepEqual } from "lodash";
import { ADJUST_VIEW_ZONE } from "../../stream/components/streamTopics";
import Cluster from "../../components/Cluster";
import { OPEN_CLUSTER_TOPIC } from "./plansTopics";
import { PlanPoint, PlanPointViewAngle, Text } from "headpoint-react-components";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { saveIsEditing, saveShowViewAngle } from "../../../app/reducers/planReducer";

const VIDEO_STREAM_FEATURE_CODE = "video.stream";
const limit = pLimit(10);

class PlanPoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zones: {},
            editingAngles: {},
        };
    }

    componentDidMount = () => {
        const { userInfo, generalContext, strings } = this.props;
        const { userId } = userInfo;

        if (serviceExists(generalContext, "service.api.view.angle")) {
            this.moveZoneTopic = PubSub.subscribe(ADJUST_VIEW_ZONE, async (msg, { data }) => {
                if (!data?.id) {
                    this.props.enqueueSnackbar(strings("В объекте отсутствует информация!"), { variant: "error" });
                    console.error(data);
                    return;
                }

                let { zones, editingAngles } = this.state;
                let {
                    storagePlan: { isEditing },
                } = this.props;

                if (!isEditing) {
                    zones[data.id] += data?.pan ?? 0.0;
                    const [success, code] = await AddViewZoneDelta(data.id, data?.pan, userId);
                    if (!success) {
                        switch (code) {
                            case 403: // forbiden
                                this.props.enqueueSnackbar(strings("Не хватает прав для сохранения зоны обзора"), {
                                    variant: "warning",
                                });
                                break;

                            default:
                                this.props.enqueueSnackbar(strings("Не удалось сохранить зону обзора"), {
                                    variant: "error",
                                });
                                break;
                        }
                    }
                } else {
                    editingAngles[data.id] =
                        (editingAngles[data.id] ?? this.percentToDegrees(zones[data.id] ?? 0.0)) +
                        this.percentToDegrees(data?.pan ?? 0.0);
                }

                this.setState({ zones, editingAngles });
            });
        }

        this.loadClusterViewZone = PubSub.subscribe(LOAD_CLUSTER_VIEW_TOPIC, async () => {
            this.loadPoints();
        });
    };

    componentWillUnmount() {
        if (this.loadClusterViewZone) {
            PubSub.unsubscribe(this.loadClusterViewZone);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isDeepEqual(prevProps.points, this.props.points)) {
            this.loadPoints();
        }
    }

    loadPoints = () => {
        const { generalContext, points, clusters, strings } = this.props;
        if (!serviceExists(generalContext, "service.api.view.angle")) {
            return;
        }

        const adapters = getAdapters(generalContext) ?? [];
        const deviceTypes = getDeviceTypes(generalContext);
        let input =
            points
                ?.filter((p) => {
                    const deviceType = deviceTypes.find((type) => type.value === p?.typeId);
                    if (!deviceType) {
                        return false;
                    }

                    return checkFeatureByDeviceType(deviceType?.value, adapters, VIDEO_STREAM_FEATURE_CODE);
                })
                ?.map((p) => limit(async () => [p.id, ...(await GetViewZone(p.id))])) ?? [];

        clusters?.forEach((c) => {
            input = input.concat(
                c?.points
                    ?.filter((p) => {
                        const deviceType = deviceTypes.find((type) => type.value === p?.typeId);
                        if (!deviceType) {
                            return false;
                        }

                        return checkFeatureByDeviceType(deviceType?.value, adapters, VIDEO_STREAM_FEATURE_CODE);
                    })
                    ?.map((p) => limit(async () => [p.id, ...(await GetViewZone(p.id))])) ?? []
            );
        });

        Promise.all(input).then((responses) => {
            const successZoneResponses = responses.filter(([_, status]) => status);
            const failedZonesResponses = responses.filter(([_, status]) => !status);

            if (failedZonesResponses?.length) {
                this.props.enqueueSnackbar(strings("Ошибка получения зоны обзора"), { variant: "error" });
                console.error(failedZonesResponses);
            }

            const zones = successZoneResponses.reduce((zones, [id, _, diff]) => ({ ...zones, [id]: diff }), {});
            this.setState({ zones });
        });
    };

    getPointInfo = async (point, plan) => {
        const { strings } = this.props;

        if (!point?.id) {
            return point;
        }

        const [getPointsStatus, points] = await GetPoints(plan.id, { deviceIds: [point.id] });
        if (getPointsStatus && points?.length) {
            return points.find((p) => p.id === point.id);
        }

        this.props.enqueueSnackbar(strings("Ошибка получения объекта, информация в карточке устарела"), {
            variant: "error",
        });
        return point;
    };

    percentToDegrees = (value) => {
        return (value * 360.0) % 360.0;
    };

    saveAngle = async (point, userId) => {
        const { strings } = this.props;
        const { editingAngles, zones } = this.state;

        if (!editingAngles[point.id]) {
            this.props.enqueueSnackbar(strings("Зона обзора не изменялась"), { variant: "success" });

            this.props.dispatch(saveIsEditing(false));
            PubSub.publish(FREEZE_PLAN_TOPIC, false);
            return;
        }

        const [success, code] = await AddViewZoneDelta(
            point.id,
            this.degreesToPercent(editingAngles[point.id] - this.percentToDegrees(zones[point.id] ?? 0.0)),
            userId
        );

        if (success) {
            zones[point.id] = this.degreesToPercent(editingAngles[point.id]);
            delete editingAngles[point.id];

            this.props.dispatch(saveIsEditing(false));
            this.setState({ editingAngles, zones });

            PubSub.publish(FREEZE_PLAN_TOPIC, false);
            this.props.enqueueSnackbar(strings("Зона обзора сохранена"), { variant: "success" });
        } else {
            switch (code) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для сохранения зоны обзора"), {
                        variant: "warning",
                    });
                    break;

                default:
                    this.props.enqueueSnackbar(strings("Не удалось сохранить зону обзора"), { variant: "error" });
                    break;
            }
        }
    };

    degreesToPercent = (value) => {
        return value / 360.0;
    };

    getEventVariant = (severities) => {
        if (severities?.includes("danger") && this.props.eventsFilter.showDanger) {
            return "danger";
        }

        if (severities?.includes("warning") && this.props.eventsFilter.showWarning) {
            return "warning";
        }
        return null;
    };

    getColorVariant = (severities, isViewAngle) => {
        if (severities?.includes("danger") && this.props.eventsFilter.showDanger) {
            return isViewAngle ? "red" : "danger";
        }

        if (severities?.includes("warning") && this.props.eventsFilter.showWarning) {
            return isViewAngle ? "yellow" : "warning";
        }

        return isViewAngle ? "green" : "success";
    };

    getClusterColorVariant = (severities) => {
        if (severities?.includes("danger") && this.props.eventsFilter.showDanger) {
            return "danger";
        }

        if (severities?.includes("warning") && this.props.eventsFilter.showWarning) {
            return "warning";
        }

        return "primary";
    };

    render() {
        const {
            userInfo,
            points,
            generalContext,
            onDrag,
            plan,
            selected,
            clusters,
            strings,
            storagePlan: { showViewAngle, isEditing },
        } = this.props;
        const { editingAngles, zones } = this.state;
        const deviceTypes = getDeviceTypes(generalContext);
        const adapters = getAdapters(generalContext) ?? [];
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <>
                {serviceExists(generalContext, "service.api.view.angle") &&
                    permissionExists(userInfo, "view.angles.access") && (
                        <CameraAngleModeButton
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.dispatch(saveShowViewAngle(!showViewAngle));
                                this.props.dispatch(saveIsEditing(false));
                                PubSub.publish(FREEZE_PLAN_TOPIC, false);
                            }}
                            checked={showViewAngle}
                        />
                    )}

                {showViewAngle &&
                    serviceExists(generalContext, "service.api.view.angle") &&
                    permissionExists(userInfo, ["view.angles.access", "view.angles.set"]) && (
                        <CameraEditAngleModeButton
                            onClick={(e) => {
                                e.stopPropagation();
                                let newIsEditing = !isEditing;

                                this.props.dispatch(saveIsEditing(newIsEditing));
                                this.setState({
                                    editingAngles: newIsEditing ? editingAngles : {},
                                });

                                PubSub.publish(FREEZE_PLAN_TOPIC, selected && newIsEditing);
                            }}
                            checked={isEditing}
                        />
                    )}

                {clusters?.map((c) => (
                    <Cluster
                        key={c.id}
                        position={[c.y ?? 0, c.x ?? 0]}
                        count={c.features?.length}
                        isOpen={c.isOpen}
                        openCluster={(isOpen) => PubSub.publish(OPEN_CLUSTER_TOPIC, { id: c.id, isOpen })}
                        isMuted={!!selected && !c.isOpen}
                        eventVariant={this.getEventVariant(c?.events?.map((e) => e.severity).flat() ?? [])}
                        colorVariant={this.getClusterColorVariant(c?.events?.map((e) => e.severity).flat() ?? [])}
                    >
                        {c.points?.map((p) => {
                            const type = deviceTypes?.find((t) => t.value === p.typeId);
                            const icon = type?.icon?.type;
                            const tooltip = [
                                {
                                    label: strings(type?.label),
                                    text: (
                                        <Text color={"gray"} variant={"body-sm"}>
                                            {p.name}
                                        </Text>
                                    ),
                                },
                            ];

                            const severity = c.events?.find((e) => e.id === p.id)?.severity ?? [];

                            if (p?.properties?.observedObjects) {
                                tooltip.push({
                                    label: strings("Объекты наблюдения"),
                                    text: (
                                        <Text color={"gray"} variant={"body-sm"}>
                                            {p?.properties?.observedObjects ?? ""}
                                        </Text>
                                    ),
                                });
                            }

                            return showViewAngle &&
                                type?.value &&
                                checkFeatureByDeviceType(type?.value, adapters, VIDEO_STREAM_FEATURE_CODE) ? (
                                <PlanPointViewAngle
                                    key={p.id}
                                    angle={editingAngles[p.id] ?? this.percentToDegrees(zones[p.id])}
                                    colorVariant={this.getColorVariant(severity, true)}
                                    isEditing={isEditing && selected?.id === p.id}
                                    onAccept={() => this.saveAngle(p, userInfo?.userId)}
                                    onChange={(angle) => {
                                        this.setState({ editingAngles: { ...editingAngles, [p.id]: angle } });
                                    }}
                                    onCancel={() => {
                                        delete editingAngles[p.id];

                                        this.props.dispatch(saveIsEditing(false));
                                        this.setState({ editingAngles });

                                        PubSub.publish(FREEZE_PLAN_TOPIC, false);
                                    }}
                                    point={
                                        <PlanPoint
                                            icon={icon}
                                            colorVariant={this.getColorVariant(severity)}
                                            eventVariant={this.getEventVariant(severity)}
                                            tooltip={tooltip}
                                            onClick={async () => {
                                                const point = await this.getPointInfo(p, plan);
                                                if (isEditing) {
                                                    PubSub.publish(FREEZE_PLAN_TOPIC, true);
                                                }

                                                PubSub.publish(SHOW_DETAILS_TOPIC, point);
                                            }}
                                        />
                                    }
                                />
                            ) : (
                                <PlanPoint
                                    onClick={async () =>
                                        PubSub.publish(SHOW_DETAILS_TOPIC, await this.getPointInfo(p, plan))
                                    }
                                    key={p.id}
                                    icon={icon}
                                    colorVariant={this.getColorVariant(severity)}
                                    eventVariant={this.getEventVariant(severity)}
                                    tooltip={tooltip}
                                    isMuted={!!selected && selected?.id !== p?.id}
                                />
                            );
                        })}
                    </Cluster>
                ))}

                {points?.map((p) => {
                    const type = deviceTypes?.find((t) => t.value === p.typeId);
                    const icon = type?.icon?.type;
                    const tooltip = [
                        {
                            label: strings(type?.label),
                            text: (
                                <Text color={"gray"} variant={"body-sm"}>
                                    {p.name}
                                </Text>
                            ),
                        },
                    ];

                    if (p?.properties?.observedObjects) {
                        tooltip.push({
                            label: strings("Объекты наблюдения"),
                            text: p?.properties?.observedObjects ?? "",
                        });
                    }

                    return showViewAngle &&
                        type?.value &&
                        checkFeatureByDeviceType(type?.value, adapters, VIDEO_STREAM_FEATURE_CODE) ? (
                        <MarkerViewAngle
                            key={p.id}
                            icon={icon}
                            angle={editingAngles[p.id] ?? this.percentToDegrees(zones[p.id])}
                            angleColorVariant={this.getColorVariant(p.severity, true)}
                            colorVariant={this.getColorVariant(p.severity)}
                            eventVariant={this.getEventVariant(p.severity)}
                            tooltip={tooltip}
                            isEditing={isEditing && selected?.id === p.id}
                            position={[p.y, p.x]}
                            onAccept={() => this.saveAngle(p, userInfo?.userId)}
                            onChange={(angle) => {
                                this.setState({ editingAngles: { ...editingAngles, [p.id]: angle } });
                            }}
                            onCancel={() => {
                                delete editingAngles[p.id];

                                this.props.dispatch(saveIsEditing(false));
                                this.setState({ editingAngles });

                                PubSub.publish(FREEZE_PLAN_TOPIC, false);
                            }}
                            onClick={async () => {
                                const point = await this.getPointInfo(p, plan);
                                if (isEditing) {
                                    PubSub.publish(FREEZE_PLAN_TOPIC, true);
                                }

                                PubSub.publish(SHOW_DETAILS_TOPIC, point);
                            }}
                            isMuted={!!selected && selected?.id !== p?.id}
                        />
                    ) : (
                        <Marker
                            key={p.id}
                            position={[p.y, p.x]}
                            icon={icon}
                            colorVariant={this.getColorVariant(p.severity)}
                            onDragEnd={(pos) => onDrag(p, pos)}
                            draggable={!!onDrag}
                            tooltip={tooltip}
                            onClick={async () => PubSub.publish(SHOW_DETAILS_TOPIC, await this.getPointInfo(p, plan))}
                            isMuted={!!selected && selected?.id !== p?.id}
                            eventVariant={this.getEventVariant(p.severity)}
                        />
                    );
                })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        storagePlan: state.plan,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(PlanPoints)));

import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField, Select, Row } from "headpoint-react-components";
import { rolePriorities } from "../../constants";
import { GetRolesGroups } from "../../../../../../services/roles";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class ModalStepDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rolesGroups: [],
        };
    }

    componentDidMount = async () => {
        const [status, rolesGroups] = await GetRolesGroups();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения списка групп ролей"), { variant: "error" });
        }

        this.setState({ rolesGroups: rolesGroups?.map((rg) => ({ value: rg.id, label: rg.name })) ?? [] });
    };

    render() {
        const { values, strings } = this.props;
        const { rolesGroups } = this.state;
        return (
            <>
                <ModalSection>
                    <ModalFormItem>
                        <Row>
                            <TextField
                                label={strings("Название роли")}
                                name="name"
                                type="text"
                                value={values.name}
                                onChange={(e) => this.props.SetValuesProperty("name", e.target.value)}
                                colorVariant="light"
                                fullWidth={true}
                                error={!values.name ? strings("Поле не может быть пустым") : null}
                            />
                        </Row>
                    </ModalFormItem>
                    <ModalFormItem>
                        <Row>
                            <Select
                                label={strings("Группа ролей")}
                                placeholder={strings("Выбрать группу ролей")}
                                value={values.groupId}
                                onChange={(e) => this.props.SetValuesProperty("groupId", e)}
                                options={rolesGroups}
                                fullWidth={true}
                                error={!values.groupId ? strings("Поле не может быть пустым") : null}
                            />
                        </Row>
                    </ModalFormItem>
                    <ModalFormItem>
                        <Row>
                            <Select
                                label={strings("Приоритет")}
                                placeholder={strings("Выбрать приоритет")}
                                value={values.priority}
                                onChange={(e) => this.props.SetValuesProperty("priority", e)}
                                options={rolePriorities.map((r) => {
                                    return { ...r, label: strings(r.label) };
                                })}
                                fullWidth={true}
                                error={values.priority === undefined ? strings("Выберите приоритет") : null}
                            />
                        </Row>
                    </ModalFormItem>
                    <ModalFormItem>
                        <TextField
                            label={strings("Комментарий")}
                            name="description"
                            type="text"
                            value={values.description}
                            onChange={(e) => this.props.SetValuesProperty("description", e.target.value)}
                            colorVariant="light"
                            fullWidth
                            inputProps={{
                                as: "textarea",
                                rows: 5,
                            }}
                        />
                    </ModalFormItem>
                </ModalSection>
            </>
        );
    }
}

export default withCultureContext(ModalStepDefault);

import React from "react";
import { Marker } from "react-leaflet";
import { useLeafletIcon } from "react-leaflet-icon";
import { PlanPoint, PlanPointViewAngle } from "headpoint-react-components";

const MarkerViewAngle = ({
    position,
    onClick,
    isEditing,
    angle,
    onChange,
    onAccept,
    onCancel,
    angleColorVariant,
    isMuted,
    ...rest
}) => {
    const [markerIcon, MarkerIcon] = useLeafletIcon();

    const handleOnChange = (nextAngle) => {
        if (onChange) onChange(nextAngle);
    };

    const handleAccept = (val) => {
        if (onAccept) onAccept(val);
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <MarkerIcon>
                <PlanPointViewAngle
                    angle={angle}
                    position={[0, 0]}
                    isEditing={isEditing}
                    onAccept={handleAccept}
                    onCancel={handleCancel}
                    onChange={handleOnChange}
                    colorVariant={angleColorVariant || "green"}
                    point={<PlanPoint {...rest} onClick={onClick} />}
                    isMuted={isMuted}
                />
            </MarkerIcon>

            <Marker position={position} icon={markerIcon} zIndexOffset={isEditing ? 1000 : 0} />
        </>
    );
};

export default MarkerViewAngle;

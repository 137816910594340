import React from "react";
import { LayoutSidebarBody, LayoutSidebarItem } from "../../../layout/MainLayout";
import { permissionExists } from "../../../contexts";
import { MapPlanEventsFilter } from "../../components/MapPlanFilter/MapPlanEventsFilter";
import DeviceLocationFilter from "../../components/DeviceLocationFilter";
import { FilterGroup, FilterWrapper } from "../../components/Filters";
import { FilterGroupList, FilterItem } from "../../components/FiltersPartion";
import { Checkbox, Radio, TextField } from "headpoint-react-components";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import { MAP_DEVICE_LOCATIONS_FILTER } from "./mapTopics";
import DropDownFilter from "../../components/DropDownFilter/DropDownFilter";
import GroupZoneFilter from "../../components/GroupZoneFilter/GroupZoneFilter";

class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sourceSearch: "",
            typeSearch: "",
        };
    }

    handleCheckboxChange = (array, setter, e) => {
        let newArray = [...array];
        if (newArray.includes(e)) {
            newArray = newArray.filter((et) => et !== e);
        } else {
            newArray = [...newArray, e];
        }

        setter(newArray);
    };

    render() {
        const { userInfo } = this.props;
        const { typeSearch } = this.state;
        const {
            devicesFilter,
            locationsFilter,
            setDeviceLocationFilter,
            resetdeviceLocationFilter,
            eventsFilter,
            setEventsFilter,
            defaultEventTypes,
            eventTypesFilter,
            setEventsTypesFilter,
            intervalFilter,
            setEventsIntervalFilter,
            eventsFilters,
            handleOpen,
            handleUpdate,
            loadFilter,
            deleteFilter,
            selectedFilter,
            dropFilter,
            disabled,
            strings,
            zonesFilter,
            groupsFilter,
            setGroupZoneFilter,
            resetGroupZoneFilter,
        } = this.props;

        const availableEventTypes = defaultEventTypes?.filter((t) => permissionExists(userInfo, t.value)) ?? [];

        const eventTypes = typeSearch
            ? availableEventTypes?.filter((t) => t.label.toLowerCase().includes(typeSearch.toLowerCase()))
            : availableEventTypes;

        return (
            <>
                <LayoutSidebarBody>
                    {eventsFilter && setEventsFilter && (
                        <LayoutSidebarItem>
                            <MapPlanEventsFilter eventsFilter={eventsFilter} setEventsFilter={setEventsFilter} />
                        </LayoutSidebarItem>
                    )}
                    <FilterWrapper>
                        {permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
                            <DeviceLocationFilter
                                locationsFilter={locationsFilter}
                                devicesFilter={devicesFilter}
                                setDeviceLocationFilter={setDeviceLocationFilter}
                                resetFilter={resetdeviceLocationFilter}
                                cmdChannelTopic={MAP_DEVICE_LOCATIONS_FILTER}
                            />
                        ) : (
                            <div />
                        )}
                        {permissionExists(userInfo, ["service.api.devices"]) ? (
                            <GroupZoneFilter
                                zonesFilter={zonesFilter}
                                groupsFilter={groupsFilter}
                                setGroupZoneFilter={setGroupZoneFilter}
                                resetFilter={resetGroupZoneFilter}
                            />
                        ) : (
                            <div />
                        )}

                        <FilterGroup
                            title={strings("Тип события")}
                            hasValue={!!eventTypesFilter?.length}
                            onClear={() => {
                                this.setState({ typeSearch: "" });
                                setEventsTypesFilter([]);
                            }}
                            isMuted={!defaultEventTypes.length}
                        >
                            <TextField
                                colorVariant="light"
                                fullWidth
                                onChange={(e) => this.setState({ typeSearch: e.target?.value })}
                                placeholder={strings("Найти")}
                                type="text"
                                value={typeSearch}
                            />

                            <FilterGroupList>
                                {eventTypes.map((et) => (
                                    <FilterItem key={nanoid()}>
                                        <Checkbox
                                            label={strings(et.label)}
                                            checked={eventTypesFilter.includes(et.value)}
                                            onChange={() =>
                                                this.handleCheckboxChange(
                                                    eventTypesFilter,
                                                    (arr) => setEventsTypesFilter(arr),
                                                    et.value
                                                )
                                            }
                                        />
                                    </FilterItem>
                                ))}
                            </FilterGroupList>
                        </FilterGroup>
                        <FilterGroup title={strings("Интервал")}>
                            <FilterGroupList>
                                <FilterItem>
                                    <Radio
                                        label={strings("Все события")}
                                        checked={intervalFilter === "all"}
                                        onChange={() => setEventsIntervalFilter("all")}
                                    />
                                </FilterItem>
                                <FilterItem>
                                    <Radio
                                        label={strings("Последние события")}
                                        checked={intervalFilter === "last"}
                                        onChange={() => setEventsIntervalFilter("last")}
                                    />
                                </FilterItem>
                                <FilterItem>
                                    <Radio
                                        label={strings("Новые события")}
                                        checked={intervalFilter === "new"}
                                        onChange={() => setEventsIntervalFilter("new")}
                                    />
                                </FilterItem>
                            </FilterGroupList>
                        </FilterGroup>
                    </FilterWrapper>
                </LayoutSidebarBody>
                <DropDownFilter
                    selectedFilter={selectedFilter}
                    filters={eventsFilters}
                    createFilter={handleOpen}
                    updateFilter={handleUpdate}
                    selectFilter={loadFilter}
                    deleteFilter={deleteFilter}
                    dropFilter={dropFilter}
                    disabled={disabled}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(Filters);

import React from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabMain extends React.Component {
    render() {
        const { selectedService } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Тип")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedService.properties?.adapter
                                                ? strings("Адаптер")
                                                : strings("Сервис")}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("ID")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedService.serviceId}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Code")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedService.code}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Name")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedService.serviceName}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Instances")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedService.instances}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

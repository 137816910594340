import React from "react";
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalFooterItem,
    ModalBody,
    ModalBodyItem,
    Button,
    Text,
} from "headpoint-react-components";

class DialogOk extends React.Component {
    handleSubmit = async (e) => {
        e.preventDefault();

        this.props.handleClose();
    };

    render() {
        return (
            <Modal size="sm" closeHandler={this.props.handleClose}>
                <ModalHeader title="Внимание" closeHandler={this.props.handleClose} />

                <ModalBody>
                    <ModalBodyItem>
                        <Text color="primary" variant="body-sm">
                            {this.props.text}
                        </Text>
                    </ModalBodyItem>
                </ModalBody>

                <ModalFooter>
                    <ModalFooterItem>
                        <Button variant="primary" label="Ок" onClick={this.handleSubmit} type="submit" />
                    </ModalFooterItem>
                </ModalFooter>
            </Modal>
        );
    }
}

export default DialogOk;

const path = "/stream-api";

export const getLiveUrls = async (serviceId, deviceId) => {
    const token = window.localStorage.getItem("token");
    console.log(`${path}/cameras/liveUrls?cameraIds=${deviceId}`);
    const response = await fetch(`${path}/cameras/liveUrls/${serviceId}?cameraIds=${deviceId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    return [true, await response.json()];
};

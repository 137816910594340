import React from "react";
import ArchivePlayer from "./ArchivePlayer";
import { CameraLayoutItem, CameraPlaceholder } from "../../components/CameraLayout";
import { GeneralContextConsumer } from "../../../contexts";
import { GetLocationList } from "../../../services/locations";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class ArchivePlayerWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFrameLocationName: undefined,
        };
    }

    componentDidMount() {
        const currentFrame = this.props.frames[this.props.frameIndex];
        if (currentFrame) {
            this.getLocationById(currentFrame.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }

    getLocationById = async (locationId) => {
        const { strings } = this.props;
        if (!locationId) {
            return;
        }

        let [locationsStatus, locations] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locations?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        return locations?.[0];
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevSelectedFrame = prevProps.frames[prevProps.frameIndex];
        const selectedFrame = this.props.frames[this.props.frameIndex];

        if (prevSelectedFrame?.id !== selectedFrame?.id) {
            this.getLocationById(selectedFrame?.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }
    render() {
        const {
            frameIndex,
            frames,
            selectedCameraFrame,
            handleCameraFrameSelect,
            handleCameraFrameClear,
            showCameraInfo,
            strings,
        } = this.props;

        const currentFrame = frames[frameIndex];
        if (currentFrame?.properties?.connection?.cameraConnectionProfiles && currentFrame?.properties?.url?.length) {
            currentFrame.url = currentFrame?.properties?.url;
        }

        if (currentFrame && currentFrame.ranges) {
            currentFrame.rangeStart = currentFrame.ranges.from;
            currentFrame.rangeEnd = currentFrame.ranges.to;
        }

        const clientProtocol = frames[frameIndex]?.properties?.settings?.clientProtocol;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <CameraLayoutItem
                            isSelected={selectedCameraFrame === frameIndex}
                            onSelect={() => handleCameraFrameSelect(frameIndex)}
                            onClear={() => handleCameraFrameClear(frameIndex)}
                            hasItem={currentFrame && currentFrame.id}
                            label={currentFrame && currentFrame.name}
                            locationName={this.state.selectedFrameLocationName}
                            showCameraInfo={showCameraInfo}
                            placeholderLabel={strings("Камера не выбрана")}
                            selectedPlaceholderLabel={strings("Выберите камеру")}
                        >
                            {currentFrame && currentFrame.id && currentFrame.rangeStart && currentFrame.rangeEnd ? (
                                <ArchivePlayer
                                    clientProtocol={clientProtocol}
                                    currentFrame={currentFrame}
                                    frameIndex={frameIndex}
                                    key={`${frames[frameIndex]?.ranges?.from}:${frames[frameIndex]?.ranges?.to}`}
                                    generalInfo={generalInfo}
                                />
                            ) : (
                                <CameraPlaceholder type="archive" />
                            )}
                        </CameraLayoutItem>
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

export default withCultureContext(ArchivePlayerWrapper);

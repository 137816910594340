import moment from "moment/moment";

export const noArchiveRecord = (device, strings) => {
    return `${strings("Отсутствует архивная запись для")} '${device.name}'`;
};

export const endedOrMissingArchiveRecord = (device, strings) => {
    const from = formatDate(device.ranges.from);
    const to = formatDate(device.ranges.to);
    return `${strings("Закончилась или отсутствует архивная запись для")} '${device.name}' ${strings(
        "за"
    )} ${from} - ${to}`;
};

export const BeginOfArchiveRecord = () => {
    return `Начало архивной трансляции, перемотка не возможна`;
};

export const formatDate = (unixTime) => moment.unix(unixTime).format("DD-MM-YYYY HH:mm:ss");

import Stream from "../../features/stream/components/Stream";
import Archive from "../../features/archive/components/Archive";
import Gallery from "../../features/gallery/components/Gallery";
import Events from "../../features/events/components/Events";
import Plans from "../../features/plans/components/Plans";
import Reports from "../../features/reports/components/Reports";
import Settings from "../../features/settings/components/Settings";
import Map from "../../features/map/components/Map";
import Schedules from "../../features/schedule";

export const pages = [
    {
        url: "/stream",
        label: "Видеонаблюдение",
        serviceCodes: [
            "service.api.stream",
            "service.api.locations",
            "service.api.devices",
            "video.webrtc.service",
            "rtsp.proxy.service",
        ],
        permissions: ["video.stream.access", "devices.device.read", "locations.location.read"],
        component: Stream,
    },
    {
        url: "/archive",
        label: "Видеоархив",
        serviceCodes: [
            "service.api.archive",
            "service.api.locations",
            "service.api.devices",
            "video.webrtc.service",
            "video.recorder.service",
        ],
        permissions: ["video.archive.access", "devices.device.read", "locations.location.read"],
        component: Archive,
    },
    {
        url: "/gallery",
        label: "Фотогалерея",
        serviceCodes: ["screenshot.service", "service.api.locations", "service.api.devices"],
        permissions: ["screenshot.access", "devices.device.read", "locations.location.read"],
        component: Gallery,
    },
    {
        url: "/schedules",
        label: "Расписания",
        serviceCodes: ["service.scheduler"],
        permissions: ["schedule.service.access"],
        component: Schedules,
    },
    {
        url: "/events",
        label: "События",
        serviceCodes: ["service.api.events", "service.api.devices"],
        permissions: ["events.service.access", "devices.deviceGroup.read"],
        component: Events,
    },
    {
        url: "/map",
        label: "Карта",
        serviceCodes: ["service.api.map", "service.api.locations", "service.api.devices"],
        permissions: ["maps.service.access", "devices.device.read", "locations.location.read"],
        component: Map,
    },
    {
        url: "/plans",
        label: "Планы",
        serviceCodes: ["service.api.plans", "service.api.locations", "service.api.devices", "service.api.tiles"],
        permissions: ["plans.service.access", "devices.device.read", "locations.location.read"],
        component: Plans,
    },
    {
        url: "/reports",
        label: "Отчеты",
        serviceCodes: ["service.api.reports", "service.api.locations", "service.api.devices"],
        permissions: [
            "reports.service.access",
            "devices.device.read",
            "locations.location.read",
            "devices.deviceGroup.read",
        ],
        component: Reports,
    },
    {
        url: "/settings",
        label: "Настройки",
        component: Settings,
    },
];

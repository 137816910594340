import React from "react";
import styled from "styled-components";
import { MapContainer, TileLayer } from "react-leaflet";
import Marker from "../../../components/Marker";
import { EventsLayoutPrimary } from "../../../../layout/EventsLayout";
import { GeneralContextConsumer, getDeviceTypes } from "../../../../contexts";
import PubSub from "pubsub-js";
import { PlanPoint, Text } from "headpoint-react-components";
import { OPEN_CLUSTER_TOPIC } from "../../../plans/components/plansTopics";
import clustersDbscan from "@turf/clusters-dbscan";
import { centroid, clusterReduce, featureCollection, point } from "@turf/turf";
import { nanoid } from "nanoid";
import Cluster from "../../../components/Cluster";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

const CLUSTER_RADIUS = 32;
const OPEN_CLUSTER_COUNT = 12;

const PlanControlsWrapper = styled.div`
    position: absolute;
    left: 12px;
    bottom: 8px;
    z-index: 2;
    display: flex;

    & > * + * {
        margin-left: 8px;
    }
`;

class PlanDetailsMap extends React.Component {
    constructor(props) {
        super(props);
        this.mapTileRef = React.createRef();
        this.mapRef = React.createRef();
        this.state = {
            isMultipleMode: false,
            points: [],
            clusters: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { plan, points } = this.props;

        this.mapRef._layersMaxZoom = plan.maxZoom;

        if (plan?.id && plan?.id !== prevProps.plan?.id) {
            const url = window.location.origin + "/plans-api/tiles/" + plan?.id + "/{z}/{x}/{y}.png";
            this.mapTileRef.current.setUrl(url);
            this.mapRef?.setView([0, 0], 0);
        }

        if (points !== prevProps.points) {
            this.getClusters();
        }
    }

    componentDidMount = async () => {
        this.openClusterTopic = PubSub.subscribe(OPEN_CLUSTER_TOPIC, async (msg, data) => {
            const { points } = this.props;
            const { clusters } = this.state;

            if (data.id === null || data.id === undefined || !clusters?.length) {
                return;
            }

            const cluster = clusters.find(({ id }) => data.id === id);
            if (cluster.points?.length > OPEN_CLUSTER_COUNT && this.mapRef._zoom < this.mapRef._layersMaxZoom) {
                this.mapRef?.setView([cluster.x, cluster.y], (this.mapRef._zoom ?? 0) + 1);
                return;
            }

            if (data.isOpen) {
                const clusterPoints = points.filter((p) => cluster.points?.map((p) => p.id).includes(p.id));

                if (clusterPoints) {
                    cluster.points = clusterPoints;
                    cluster.isOpen = data.isOpen;
                } else {
                    const { strings } = this.props;
                    this.props.enqueueSnackbar(strings("Ошибка получения объектов"), { variant: "error" });
                }
            } else {
                cluster.isOpen = false;
            }

            this.setState({ clusters });
        });
    };

    componentWillUnmount() {
        if (this.openClusterTopic) {
            PubSub.unsubscribe(this.openClusterTopic);
        }
    }

    getEpsilon = () => {
        if (!this.mapRef?.getBounds) {
            return CLUSTER_RADIUS;
        }

        const bounds = this.mapRef?.getBounds();
        const xScale = Math.min(bounds._southWest.lng - bounds._northEast.lng, 360) / this.mapRef?._size.x;
        const yScale = (bounds._southWest.lat - bounds._northEast.lat) / this.mapRef?._size.y;

        return Math.max(Math.abs(xScale), Math.abs(yScale)) * CLUSTER_RADIUS;
    };

    getClusters = async () => {
        const { points } = this.props;

        let clustered = clustersDbscan(
            featureCollection(points?.map((p) => point([(p.x ?? 0) / 2, (p.y ?? 0) / 2], p)) ?? []),
            this.getEpsilon() / 2,
            {
                minPoints: 2,
                units: "degrees",
            }
        );

        let newPoints = [...points];
        let clusters = clusterReduce(
            clustered,
            "cluster",
            (clusters, cluster) => {
                newPoints = newPoints?.filter((p) => !cluster.features?.some((f) => f.properties.id === p.id));
                const cent = centroid(cluster);
                return [
                    ...clusters,
                    {
                        ...cluster,
                        id: nanoid(),
                        points: points?.filter((p) => cluster.features?.some((f) => f.properties.id === p.id)),
                        y: (cent?.geometry?.coordinates?.[1] ?? 0) * 2,
                        x: (cent?.geometry?.coordinates?.[0] ?? 0) * 2,
                    },
                ];
            },
            []
        );

        this.setState({ points: newPoints, clusters });
    };

    render() {
        const { plan, strings } = this.props;
        const { points, clusters } = this.state;

        return (
            <EventsLayoutPrimary noPadding style={{ zIndex: 1 }}>
                <GeneralContextConsumer>
                    {(generalContext) => {
                        const deviceTypes = getDeviceTypes(generalContext);
                        return (
                            <MapContainer
                                whenCreated={(mapInstance) => {
                                    this.mapRef = mapInstance;
                                }}
                                center={[0, 0]}
                                zoom={0}
                                scrollWheelZoom={true}
                            >
                                <TileLayer
                                    ref={this.mapTileRef}
                                    url={window.location.origin + "/plans-api/tiles/" + plan?.id + "/{z}/{x}/{y}.png"}
                                    noWrap={true}
                                />
                                {clusters?.map((c) => (
                                    <Cluster
                                        key={c.id}
                                        position={[c.y ?? 0, c.x ?? 0]}
                                        count={c.features?.length}
                                        isOpen={c.isOpen}
                                        openCluster={(isOpen) =>
                                            PubSub.publish(OPEN_CLUSTER_TOPIC, { id: c.id, isOpen })
                                        }
                                        colorVariant={"primary"}
                                    >
                                        {c.points?.map((p) => {
                                            const type = deviceTypes?.find((t) => t.value === p.typeId);
                                            const icon = type?.icon?.type;
                                            const tooltip = [
                                                {
                                                    label: strings(type?.label),
                                                    text: (
                                                        <Text color={"gray"} variant={"body-sm"}>
                                                            {p.name}
                                                        </Text>
                                                    ),
                                                },
                                            ];

                                            if (p?.properties?.observedObjects) {
                                                tooltip.push({
                                                    label: strings("Объекты наблюдения"),
                                                    text: p?.properties?.observedObjects ?? "",
                                                });
                                            }

                                            return (
                                                <PlanPoint
                                                    key={p.id}
                                                    icon={icon}
                                                    colorVariant={"success"}
                                                    tooltip={tooltip}
                                                />
                                            );
                                        })}
                                    </Cluster>
                                ))}
                                {points.map((p) => {
                                    const type = deviceTypes?.find((t) => t.value === p.typeId);
                                    const icon = type?.icon?.type;
                                    const tooltip = [
                                        {
                                            label: strings(type?.label),
                                            text: (
                                                <Text color={"gray"} variant={"body-sm"}>
                                                    {p.name}
                                                </Text>
                                            ),
                                        },
                                    ];

                                    return (
                                        <Marker
                                            key={p.id}
                                            position={[p.y, p.x]}
                                            icon={icon}
                                            colorVariant={"success"}
                                            tooltip={tooltip}
                                        />
                                    );
                                })}
                            </MapContainer>
                        );
                    }}
                </GeneralContextConsumer>
                <PlanControlsWrapper></PlanControlsWrapper>
            </EventsLayoutPrimary>
        );
    }
}

export default withCultureContext(PlanDetailsMap);

import { objectToParams, urlWithQuery } from "../utilites/UrlUtils";

const path_clusters_devices = "/map-api/points/clusters";
const path_map_points = "/map-api/points";

export const GetPoints = async (filter) => {
    const token = window.localStorage.getItem("token");
    try {
        let params = new URLSearchParams();
        if (filter?.deviceIds?.length) {
            filter.deviceIds.forEach((id) => params.append("deviceIds", id));
        }

        if (filter?.locationIds?.length) {
            filter.locationIds.forEach((id) => params.append("locationIds", id));
        }

        if (filter?.viewZone) {
            objectToParams(filter?.viewZone, params, "viewZone.");
        }

        if (filter?.eps) {
            params.append("eps", filter.eps);
        }

        let response = await fetch(urlWithQuery("/map-api/points", params), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const GetClusters = async (filter) => {
    const token = window.localStorage.getItem("token");
    try {
        let params = new URLSearchParams();
        if (filter?.deviceIds?.length) {
            filter.deviceIds.forEach((id) => params.append("deviceIds", id));
        }

        if (filter?.locationIds?.length) {
            filter.locationIds.forEach((id) => params.append("locationIds", id));
        }

        if (filter?.eps) {
            params.append("eps", filter.eps);
        }

        if (filter?.viewZone) {
            objectToParams(filter?.viewZone, params, "viewZone.");
        }

        let response = await fetch(urlWithQuery(path_clusters_devices, params), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const UpdatePoint = async (pointId, point) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path_map_points}/${pointId}`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(point),
        });

        return [response.status === 200, response.status];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

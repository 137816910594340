import React from "react";
import { Table, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsTabMain extends React.Component {
    groupTypesNames = {
        dynamic: "Динамическая",
        fixed: "Фиксированная",
    };

    render() {
        const { selected, strings } = this.props;
        return (
            <DetailsBoxContent>
                <Table isFixed noPadding>
                    <TableBody>
                        <TableRow>
                            <TableCell>{strings("Тип группы оборудования")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {strings(this.groupTypesNames[selected?.properties?.groupType]) ?? ""}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Комментарий")}</TableCell>
                            <TableCell>
                                <Text scroll color="primary" variant="body-sm">
                                    {selected?.properties?.comment ?? ""}
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(DetailsTabMain);

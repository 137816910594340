export const ReporterFilterTypes = {
    DateTime: "dateTime",
    DeviceIds: "deviceIds",
    Severity: "severity",
    DeviceType: "deviceType",
    AvailabilityRule: "availabilityRule",
    QualityRule: "qualityRule",
    DeviceGroups: "deviceGroup",
    UserIds: "userIds",
    Actions: "actions",
    Entities: "entities",
    Statuses: "statuses",
};

export const StatusTypes = [
    { name: "Успешно", id: "ok" },
    { name: "Неуспешно", id: "bad" },
];

export const ActionTypes = [
    {
        id: "service.api.auth",
        name: "Аутентификация",
        children: [
            { id: "login", name: "Вход в систему" },
            { id: "logout", name: "Выход из системы" },
        ],
    },
    {
        id: "onvif.ptz.adapter",
        name: "ПТЗ",
        children: [
            { id: "ptz.zoom", name: "Зум" },
            { id: "ptz.move", name: "Поворот камеры" },
        ],
    },
    {
        id: "connection",
        name: "Подключение",
        children: [
            { id: "connect", name: "Подключение устройства к адаптеру" },
            { id: "disconnect", name: "Отключение устройства от адаптера" },
        ],
    },
    {
        id: "create",
        name: "Создание",
    },
    {
        id: "update",
        name: "Редактирование",
    },
    {
        id: "delete",
        name: "Удаление",
    },
    {
        id: "get",
        name: "Получение",
    },
];

export const EntityTypes = [
    { id: "archive.order", name: "Заказ архива" },
    { id: "screenshot.order", name: "Заказ фото" },
    { id: "archive.url", name: "Архив" },
    { id: "stream.url", name: "Трансляция" },
    { id: "device", name: "Устройство" },
    { id: "preset", name: "Пресет" },
    { id: "desktop", name: "Рабочий стол" },
    { id: "event.user", name: "Тип пользовательских событий" },
    { id: "auth", name: "Учетная запись" },
    { id: "schedule", name: "Расписание" },
    { id: "storage.file", name: "Файл хранилища" },
    { id: "control.zone", name: "Зона контроля" },
    { id: "control.zone.group", name: "Группа зон контроля" },
    { id: "device.group", name: "Группа оборудования" },
    { id: "view.zone", name: "Зона обзора" },
    { id: "event.filter", name: "Пользовательский фильтр" },
    { id: "screenshot", name: "Фотоизображение" },
    { id: "converter.order", name: "Заказ ковертируемого архива" },
    { id: "location", name: "Локация" },
    { id: "tag", name: "Тег оборудования" },
    { id: "device.availability.rule", name: "Правило сетевой доступности" },
    { id: "rtsp.quality.rule", name: "Правило качества видеоизображения" },
    { id: "user", name: "Пользователь" },
    { id: "user.group", name: "Группа пользователей" },
    { id: "role", name: "Роль" },
    { id: "role.group", name: "Группа ролей" },
    { id: "response.template", name: "Шаблон реагирования" },
    { id: "response.scenario", name: "Сценарий реагирования" },
    { id: "plan", name: "План" },
    { id: "system.variable", name: "Системная переменная" },
];

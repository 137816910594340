import OnClickBottomTooltip from "./OnClickBottomTooltip";
import { Button, Text } from "headpoint-react-components";
import styled from "styled-components";
import React from "react";
import { rowButtonsStyle } from "./styles";

const InfoButton = ({ locationName }) => {
    return (
        <OnClickBottomTooltip
            tooltipBody={
                <TooltipBodyStyled>
                    <Text variant="body-sm" color="grey">
                        {locationName}
                    </Text>
                </TooltipBodyStyled>
            }
        >
            <Button style={rowButtonsStyle} icon="info" variant="ghost" onClick={(e) => e.preventDefault()} />
        </OnClickBottomTooltip>
    );
};

const TooltipBodyStyled = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    padding: 8px 12px;
    max-width: 300px;
`;

export default InfoButton;

import React from "react";
import { Button, Text, Tab, TabPanel } from "headpoint-react-components";
import { DetailsTabMain } from "./DetailsTabMain";
import DetailsTabEvents from "./DetailsTabEvents";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
} from "../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class EventDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = async () => {
        const { selectedEvent } = this.state;

        this.setState({
            selectedEvent: selectedEvent ?? [],
        });
    };

    render() {
        const { selectedEvent, detailsTab } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {strings(selectedEvent.name)}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Button
                                        icon="close"
                                        variant="secondary"
                                        onClick={() => this.props.setSelectedEvent(undefined)}
                                    />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    <Tab
                                        label={strings("Общее")}
                                        isActive={detailsTab === "main"}
                                        onClick={() => this.props.setDetailsTab("main")}
                                    />
                                    <Tab
                                        label={strings("События адаптера")}
                                        isActive={detailsTab === "events"}
                                        onClick={() => this.props.setDetailsTab("events")}
                                    />
                                </TabPanel>
                            </DetailsBoxTabs>

                            {detailsTab === "main" && <DetailsTabMain selectedEvent={selectedEvent} />}

                            {detailsTab === "events" && <DetailsTabEvents selectedEvent={selectedEvent} />}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

import { Button, Dropdown, DropdownDivider, DropdownItem } from "headpoint-react-components";
import React from "react";
import styled from "styled-components";
import { permissionExists } from "../../../contexts";
import {
    EDIT_ALL_GROUP_FILTERS,
    EDIT_GROUP_FILTERS,
    EDIT_PERSONAL_FILTERS,
    EDIT_SYSTEM_FILTERS,
    GROUP_EVENT_TYPE,
    PERSONAL_EVENT_TYPE,
    SYSTEM_EVENT_TYPE,
    VIEW_FILTERS,
} from "../../events/components/constants";
import { checkSuperUser } from "../../../contexts/GeneralContext";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";

const DropDownSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
`;

const DropDownContainer = styled.div`
    margin-bottom: 15px;

    button > span {
        position: absolute;
        width: calc(100% - 40px);
        display: inline-block;
        margin-left: 0px;
        margin-right: 0px;
        margin: 1px;
    }
`;

const ButtonContainer = styled.div`
    button > i {
        margin-left: auto;
    }
`;

class DropDownFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isViewAllowed: false,
            isEditPersonalAllowed: false,
            isEditGroupAllowed: false,
            isEditAllGroupsAllowed: false,
            isEditSystemAllowed: false,
        };
    }

    checkSaveDeletePermissions(filter) {
        const { userInfo } = this.props;

        if (checkSuperUser(userInfo)) {
            return true;
        }

        if (filter.type.toLowerCase() === PERSONAL_EVENT_TYPE) {
            return this.state.isEditPersonalAllowed;
        }

        if (filter.type.toLowerCase() === GROUP_EVENT_TYPE) {
            return this.state.isEditGroupAllowed;
        }

        if (filter.type.toLowerCase() === SYSTEM_EVENT_TYPE) {
            return this.state.isEditSystemAllowed;
        }

        return false;
    }

    componentDidMount() {
        const { userInfo } = this.props;

        const isViewAllowed = permissionExists(userInfo, VIEW_FILTERS);
        const isEditPersonalAllowed = permissionExists(userInfo, EDIT_PERSONAL_FILTERS);
        const isEditGroupAllowed = permissionExists(userInfo, EDIT_GROUP_FILTERS);
        const isEditAllGroupsAllowed = permissionExists(userInfo, EDIT_ALL_GROUP_FILTERS);
        const isEditSystemAllowed = permissionExists(userInfo, EDIT_SYSTEM_FILTERS);

        this.setState({
            isViewAllowed,
            isEditPersonalAllowed,
            isEditGroupAllowed,
            isEditAllGroupsAllowed,
            isEditSystemAllowed,
        });
    }

    render() {
        const {
            filters,
            createFilter,
            selectFilter,
            updateFilter,
            deleteFilter,
            dropFilter,
            selectedFilter,
            disabled,
        } = this.props;

        const { isViewAllowed } = this.state;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DropDownSection>
                        <DropDownContainer>
                            <Dropdown
                                horizontalAlignment="left"
                                verticalAlignment="top"
                                closeOnClickInside
                                dynamicContent
                                toggleLabel={strings(selectedFilter?.name ?? "Выбрать фильтр событий")}
                                toggleVariant="secondary"
                                isInline
                                fullWidth
                                disabled={!isViewAllowed}
                                menuWidth={"300px"}
                            >
                                {filters
                                    ?.sort((a, b) => {
                                        if (a.name > b.name) return 1;
                                        if (a.name < b.name) return -1;
                                        return 0;
                                    })
                                    .map((filter) => (
                                        <DropdownItem
                                            key={filter.id}
                                            colorVariant="default"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                selectFilter(filter);
                                            }}
                                            hasWrap={true}
                                            withActions={
                                                <>
                                                    <Button
                                                        icon="save"
                                                        onClick={async (e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            updateFilter(filter);
                                                        }}
                                                        disabled={!this.checkSaveDeletePermissions(filter)}
                                                        variant="ghost-primary"
                                                    />

                                                    <Button
                                                        icon="trash"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            deleteFilter(filter);
                                                        }}
                                                        disabled={!this.checkSaveDeletePermissions(filter)}
                                                        variant="ghost-danger"
                                                    />
                                                </>
                                            }
                                        >
                                            <span>{filter.name}</span>
                                        </DropdownItem>
                                    ))}

                                {this.props.filters?.length > 0 && <DropdownDivider />}
                                <DropdownItem
                                    colorVariant="default"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        createFilter();
                                    }}
                                    tabIndex="0"
                                >
                                    {strings("Новый фильтр событий")}
                                </DropdownItem>
                            </Dropdown>
                        </DropDownContainer>
                        <ButtonContainer>
                            <Button
                                labelAlign={"left"}
                                isIconAfter
                                label={strings("Сбросить фильтры")}
                                icon="close"
                                variant="secondary"
                                title={strings("Сбросить фильтры")}
                                onClick={dropFilter}
                                fullWidth
                                disabled={disabled}
                            />
                        </ButtonContainer>
                    </DropDownSection>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps)(DropDownFilter);

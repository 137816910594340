import React from "react";
import moment from "moment";
import { Tab, Icon, DateRangePicker, Button, Dropdown, DropdownItem } from "headpoint-react-components";
import {
    StreamToolbar,
    StreamToolbarSecondary,
    StreamToolbarItem,
    StreamToolbarInnerItem,
    StreamToolbarPrimary,
} from "../../components/StreamToolbar";
import { getTimeRange } from "../../../utilites/TimeUtils";
import { anyPermissionExists } from "../../../contexts/GeneralContext";

export const Toolbar = (props) => {
    const {
        syncViewEnabled,
        setSyncViewEnabled,
        selectedCameraFrame,
        dates,
        setDates,
        frames,
        currentLayout,
        setCurrentLayout,
        showCameraInfo,
        setShowCameraInfo,
        userInfo,
        openOrderList,
        handleOrderRecordModal,
        strings,
    } = props;

    const handleDateRangePreset = (preset) => {
        const newDates = getTimeRange(preset);
        setDates(newDates);
    };

    const getFrameCount = (layout) => {
        let count = 1;

        switch (layout) {
            case "1":
                count = 1;
                break;

            case "2":
                count = 2;
                break;

            case "4":
                count = 4;
                break;

            default:
                count = 1;
        }

        return count;
    };

    const handleLayout = (layout) => {
        const count = getFrameCount(layout);

        setCurrentLayout(layout, count);
    };

    const getDays = (epochStart, epochEnd) => {
        const result = [];
        if (!epochStart || !epochEnd) {
            return result;
        }

        const startDate = moment(epochStart);
        const endDate = moment(epochEnd);
        while (startDate.isSameOrBefore(endDate)) {
            result.push(startDate.clone().toDate());
            startDate.add(1, "days");
        }

        return result;
    };

    let archiveRanges =
        frames[selectedCameraFrame]?.lacunas
            ?.map((lacuna) => getDays(moment.unix(lacuna.from)?._d, moment.unix(lacuna.to)?._d))
            .flat(1) ?? [];
    let uniqueArchiveRanges = [...new Set(archiveRanges.map((date) => date.toDateString()))].map(
        (string) => new Date(string)
    );

    return (
        <StreamToolbar>
            <StreamToolbarPrimary>
                <StreamToolbarItem>
                    <DateRangePicker
                        disabled={!frames?.[selectedCameraFrame]}
                        dates={dates}
                        onChange={setDates}
                        placeholder={strings("Выбрать период")}
                        colorVariant="transparent"
                        inputFormat="D.MM.YYYY HH:mm"
                        presets={[
                            {
                                label: strings("Последний час"),
                                handler: () => handleDateRangePreset("lastHour"),
                            },
                            {
                                label: strings("Последние 6 часов"),
                                handler: () => handleDateRangePreset("lastSixHours"),
                            },
                            {
                                label: strings("Последние сутки"),
                                handler: () => handleDateRangePreset("lastDay"),
                            },
                        ]}
                        withTime
                        customInputLabels={{
                            hours: strings("Часы"),
                            minutes: strings("Минуты"),
                        }}
                        customIcon={<Icon icon="calendar-clear" />}
                        events={uniqueArchiveRanges}
                    />
                </StreamToolbarItem>
            </StreamToolbarPrimary>

            <StreamToolbarSecondary>
                <>
                    {anyPermissionExists(userInfo, [
                        "archive.order.personal.edit",
                        "archive.order.group.edit",
                        "archive.order.group.other.edit",
                        "archive.order.system.edit",
                    ]) ? (
                        <StreamToolbarItem>
                            <Button label={strings("Заказать")} variant="primary" onClick={handleOrderRecordModal} />
                        </StreamToolbarItem>
                    ) : (
                        <></>
                    )}

                    {anyPermissionExists(userInfo, [
                        "archive.order.personal.view",
                        "archive.order.group.view",
                        "archive.order.system.view",
                    ]) || Object.keys(frames).length > 0 ? (
                        <StreamToolbarItem>
                            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                {anyPermissionExists(userInfo, [
                                    "archive.order.personal.view",
                                    "archive.order.group.view",
                                    "archive.order.system.view",
                                ]) ? (
                                    <DropdownItem as="button" onClick={openOrderList}>
                                        {strings("История заказов")}
                                    </DropdownItem>
                                ) : (
                                    <></>
                                )}
                                {Object.keys(frames).length > 0 ? (
                                    <>
                                        {showCameraInfo ? (
                                            <DropdownItem as="button" onClick={() => setShowCameraInfo(false)}>
                                                {strings("Скрыть информацию о камерах")}
                                            </DropdownItem>
                                        ) : (
                                            <DropdownItem as="button" onClick={() => setShowCameraInfo(true)}>
                                                {strings("Показать информацию о камерах")}
                                            </DropdownItem>
                                        )}
                                        {syncViewEnabled ? (
                                            <DropdownItem as="button" onClick={() => setSyncViewEnabled(false)}>
                                                {strings("Отключить синхронный просмотр")}
                                            </DropdownItem>
                                        ) : (
                                            <DropdownItem as="button" onClick={() => setSyncViewEnabled(true)}>
                                                {strings("Включить синхронный просмотр")}
                                            </DropdownItem>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Dropdown>
                        </StreamToolbarItem>
                    ) : (
                        <></>
                    )}
                </>
                <StreamToolbarItem>
                    {["1", "2", "4"].map((tab) => (
                        <StreamToolbarInnerItem key={tab}>
                            <Tab
                                label={<Icon icon={tab === "1" ? "window" : `windows-${tab}`} size={24} />}
                                iconTab
                                isActive={currentLayout === tab}
                                onClick={() => handleLayout(tab)}
                            />
                        </StreamToolbarInnerItem>
                    ))}
                </StreamToolbarItem>
            </StreamToolbarSecondary>
        </StreamToolbar>
    );
};

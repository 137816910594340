import { Brush } from "recharts";

export class CustomBrush extends Brush {
    handleSlideDrag(e) {
        const { slideMoveStartX, startX, endX } = this.state;
        const { x, width, travellerWidth, startIndex, endIndex, onChange } = this.props;
        let delta = e.pageX - slideMoveStartX;

        if (delta > 0) {
            delta = Math.min(delta, x + width - travellerWidth - endX, x + width - travellerWidth - startX);
        } else if (delta < 0) {
            delta = Math.max(delta, x - startX, x - endX);
        }
        const newIndex = this.getIndex({
            startX: startX + delta,
            endX: endX + delta,
        });

        if (newIndex.startIndex === newIndex.endIndex) {
            return;
        }

        if ((newIndex.startIndex !== startIndex || newIndex.endIndex !== endIndex) && onChange) {
            onChange(newIndex);
        }

        this.setState({
            startX: startX + delta,
            endX: endX + delta,
            slideMoveStartX: e.pageX,
        });
    }

    handleTravellerMove(e) {
        const { brushMoveStartX, movingTravellerId, endX, startX } = this.state;
        const prevValue = this.state[movingTravellerId];

        const { x, width, travellerWidth, onChange, gap, data } = this.props;
        const params = { startX: this.state.startX, endX: this.state.endX };

        let delta = e.pageX - brushMoveStartX;
        if (delta > 0) {
            delta = Math.min(delta, x + width - travellerWidth - prevValue);
        } else if (delta < 0) {
            delta = Math.max(delta, x - prevValue);
        }

        params[movingTravellerId] = prevValue + delta;

        const newIndex = this.getIndex(params);
        const { startIndex, endIndex } = newIndex;
        if (startIndex === endIndex) {
            return;
        }

        const isFullGap = () => {
            const lastIndex = data.length - 1;
            if (
                (movingTravellerId === "startX" && (endX > startX ? startIndex % gap === 0 : endIndex % gap === 0)) ||
                (endX < startX && endIndex === lastIndex) ||
                (movingTravellerId === "endX" && (endX > startX ? endIndex % gap === 0 : startIndex % gap === 0)) ||
                (endX > startX && endIndex === lastIndex)
            ) {
                return true;
            }
            return false;
        };

        this.setState(
            {
                [movingTravellerId]: prevValue + delta,
                brushMoveStartX: e.pageX,
            },
            () => {
                if (onChange) {
                    if (isFullGap()) {
                        onChange(newIndex);
                    }
                }
            }
        );
    }
}

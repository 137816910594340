import React from "react";
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalFooterItem,
    ModalBody,
    TextField,
    Button,
    Row,
    Select,
    Text,
    Space,
    ModalSection,
} from "headpoint-react-components";
import { withSnackbar } from "notistack";
import {
    EDIT_ALL_GROUP_FILTERS,
    EDIT_GROUP_FILTERS,
    EDIT_PERSONAL_FILTERS,
    EDIT_SYSTEM_FILTERS,
    GROUP_EVENT_TYPE,
    PERSONAL_EVENT_TYPE,
    SYSTEM_EVENT_TYPE,
} from "../constants";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";
import { FilterAccordionStatic } from "../../../components/FiltersStatic";
import { GetUsersGroups } from "../../../../services/users";
import { permissionExists } from "../../../../contexts";
import { SUPER_USER_GROUP_ID } from "../../../../contexts/GeneralContext";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";
import { SelectedCounter } from "../../../components/SelectedCounter/SelectedCounter";

class EventsFilterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            comment: "",
            groupSearch: "",
            entities: [],
            usersGroups: [],
            type: undefined,
            isEditPersonalAllowed: false,
            isEditGroupAllowed: false,
            isEditAllGroupsAllowed: false,
            isEditSystemAllowed: false,
            userGroup: null,
        };
    }

    types = [
        {
            label: this.props.strings("Персональный"),
            value: PERSONAL_EVENT_TYPE,
        },
        {
            label: this.props.strings("Групповой"),
            value: GROUP_EVENT_TYPE,
        },
        {
            label: this.props.strings("Системный"),
            value: SYSTEM_EVENT_TYPE,
        },
    ];

    componentDidMount = async () => {
        const { strings } = this.props;

        const [status, usersGroups] = await GetUsersGroups();
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
            return;
        }

        const { userInfo } = this.props;

        const isEditPersonalAllowed = permissionExists(userInfo, EDIT_PERSONAL_FILTERS);
        const isEditGroupAllowed = permissionExists(userInfo, EDIT_GROUP_FILTERS);
        const isEditAllGroupsAllowed = permissionExists(userInfo, EDIT_ALL_GROUP_FILTERS);
        const isEditSystemAllowed = permissionExists(userInfo, EDIT_SYSTEM_FILTERS);

        this.setState({
            userGroup: userInfo.groupId,
            usersGroups,
            isEditPersonalAllowed,
            isEditGroupAllowed,
            isEditAllGroupsAllowed,
            isEditSystemAllowed,
        });
    };

    handleSubmit = async (e) => {
        const { name, comment, type, entities } = this.state;

        const { createEventsFilter } = this.props;

        e.preventDefault();

        if (!this.validateOrderData()) {
            return;
        }

        let data = { name, comment, type, entities };

        createEventsFilter(data);
    };

    validateOrderData() {
        const { name, type, entities } = this.state;
        const { strings } = this.props;

        if (!name || name.length === 0) {
            this.props.enqueueSnackbar(strings("Имя фильтра события не может быть пустым"), { variant: "error" });
            return false;
        }

        if (!type) {
            this.props.enqueueSnackbar(strings("Укажите тип фильтра события"), { variant: "error" });
            return false;
        }

        if (type === GROUP_EVENT_TYPE && entities.length < 1) {
            this.props.enqueueSnackbar(strings("Выберите группу пользователя"), { variant: "error" });
            return false;
        }

        return true;
    }

    checkPermissions(type) {
        if (this.state.userGroup === SUPER_USER_GROUP_ID) {
            return true;
        }

        if (type.value === PERSONAL_EVENT_TYPE) {
            return this.state.isEditPersonalAllowed;
        }

        if (type.value === GROUP_EVENT_TYPE) {
            return this.state.isEditGroupAllowed;
        }

        if (type.value === SYSTEM_EVENT_TYPE) {
            return this.state.isEditSystemAllowed;
        }
    }

    filterGroupsByPermissions(group) {
        const { userGroup } = this.state;

        if (this.state.isEditAllGroupsAllowed || userGroup === SUPER_USER_GROUP_ID) {
            return true;
        }

        return userGroup === group.id;
    }

    render() {
        const { groupSearch, name, type, comment, entities, usersGroups } = this.state;

        const { handleClose, strings } = this.props;

        const groups =
            usersGroups
                ?.filter((ug) => this.filterGroupsByPermissions(ug))
                ?.filter((ug) => ug?.name?.toLowerCase().includes(groupSearch.toLowerCase())) ?? [];

        return (
            <Modal size="sm">
                <ModalHeader title={strings("Новый фильтр")} closeHandler={handleClose} />
                <form onSubmit={this.handleSubmit}>
                    <ModalBody>
                        <ModalSection>
                            <TextField
                                label={strings("Название")}
                                name="title"
                                type="text"
                                colorVariant="light"
                                fullWidth
                                value={name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                error={!name ? strings("Поле не может быть пустым") : null}
                            />
                            <Space />
                            <TextField
                                label={strings("Комментарий")}
                                name="comment"
                                type="text"
                                colorVariant="light"
                                fullWidth
                                inputProps={{
                                    as: "textarea",
                                    rows: 3,
                                }}
                                value={comment}
                                onChange={(e) => this.setState({ comment: e.target.value })}
                            />
                            <Space />
                            <Select
                                label={strings("Тип фильтра")}
                                placeholder={strings("Выбрать тип фильтра событий")}
                                options={this.types.filter((type) => this.checkPermissions(type))}
                                fullWidth
                                value={type}
                                onChange={(e) => this.setState({ type: e, entities: [] })}
                                error={type === undefined ? strings("Поле не может быть пустым") : null}
                            />
                            {type === GROUP_EVENT_TYPE && (
                                <>
                                    <Space />
                                    <ModalFormItem>
                                        <Row justify="between" align="bottom" marginBottom={24}>
                                            <Text marginLeft={"auto"} color="primary" variant="body-sm" align={"left"}>
                                                {strings("Группы пользователей")}
                                            </Text>
                                            <SelectedCounter
                                                count={entities?.length ?? 0}
                                                onClear={() => this.setState({ entities: [] })}
                                                strings={strings}
                                            />
                                        </Row>
                                        <Row marginBottom={12}>
                                            <TextField
                                                autoComplete={false}
                                                colorVariant="light"
                                                fullWidth
                                                icon="search"
                                                onChange={(e) => this.setState({ groupSearch: e.target.value })}
                                                placeholder={strings("Найти")}
                                                type="search"
                                                value={groupSearch}
                                            />
                                        </Row>
                                        <FilterAccordionStatic
                                            key={!!groupSearch}
                                            preOpen={!!groupSearch}
                                            items={groups}
                                            selected={entities ?? []}
                                            selectHandler={(e) => {
                                                this.setState({ entities: e });
                                                console.log(e);
                                            }}
                                        />
                                    </ModalFormItem>
                                </>
                            )}
                        </ModalSection>
                    </ModalBody>
                </form>
                <ModalFooter>
                    <ModalFooterItem>
                        <Button
                            variant="primary"
                            label={strings("Сохранить")}
                            onClick={this.handleSubmit}
                            type="submit"
                        />
                    </ModalFooterItem>

                    <ModalFooterItem>
                        <Button variant="ghost" label={strings("Отмена")} onClick={handleClose} type="button" />
                    </ModalFooterItem>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps)(withCultureContext(withSnackbar(EventsFilterModal)));

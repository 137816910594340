import { Button, Modal, ModalBody, ModalBodyItem, Text } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import styled from "styled-components";

export const ModalBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CreatedOrdersListModal = ({ orderNumbers, onClose }) => {
    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Modal size="sm" style={{ zIndex: 1 }}>
                    <ModalBody wrap>
                        <ModalBodyItem>
                            <ModalBodyWrapper>
                                {orderNumbers.map((orderNumber) => (
                                    <Text key={orderNumber} color="secondary">
                                        {`${strings("Номер заказа")} ${orderNumber}`}
                                        <br />
                                    </Text>
                                ))}
                                <Button style={{ marginTop: "2em" }} label={strings("Закрыть")} onClick={onClose} />
                            </ModalBodyWrapper>
                        </ModalBodyItem>
                    </ModalBody>
                </Modal>
            )}
        </CultureContextConsumer>
    );
};

export default CreatedOrdersListModal;

import { urlWithQuery } from "../utilites/UrlUtils";

const archiveTasksApi = "screenshots-api/archives";

export const CountArchiveOrders = async (searchString) => {
    const token = window.localStorage.getItem("token");

    let query = new URLSearchParams();

    if (searchString) {
        query.append("searchString", searchString);
    }

    let response = await fetch(urlWithQuery(`${archiveTasksApi}/count`, query), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, 0];
    }

    try {
        let request = await response.json();
        return [true, request?.total ?? 0];
    } catch (ex) {
        console.error(ex);

        return [false, 0];
    }
};

export const GetArchiveOrders = async (pageNumber, rowsLimit, searchString) => {
    const token = window.localStorage.getItem("token");
    let query = new URLSearchParams({ pageNumber, rowsLimit });

    if (searchString) {
        query.append("searchString", searchString);
    }

    let response = await fetch(urlWithQuery(archiveTasksApi, query), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let result = await response.json();

        return [true, result?.items];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetArchiveOrder = async (taskId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(archiveTasksApi + `/${taskId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, null];
    }

    try {
        let result = await response.json();

        return [true, result?.items];
    } catch (ex) {
        console.error(ex);

        return [false, null];
    }
};

export const CreateArchiveOrdersBatch = async (archiveOrder) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${archiveTasksApi}/batch`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(archiveOrder),
    });

    if (response.status !== 200) {
        return [false, response.status, null];
    }

    try {
        const batchResponse = await response.json();
        return [true, response.status, batchResponse];
    } catch (ex) {
        console.error(ex);

        return [false, response.status, null];
    }
};

export const CancelArchiveOrder = async (taskId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${archiveTasksApi}/${taskId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    let result = null;
    try {
        result = await response.json();
    } catch {
        // ignore
    }
    return [response.status === 200, response.status, result];
};

import React from "react";
import { withSnackbar } from "notistack";
import PubSub from "pubsub-js";
import { Text } from "headpoint-react-components";
import { Dropdown, DropdownItem } from "headpoint-react-components";
import {
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
    EventsLayoutPrimary,
    EventsLayoutPrimaryInner,
} from "../../../../layout/EventsLayout";
import { LicenseTable } from "./LicenseTable";
import { GetActiveLicenses, GetLicenseInfo, UploadLicense } from "../../../../services/license";
import { LicenseInfoModal } from "./LicenseInfoModal";
import { SHOW_LICENSE_INFO_MODAL } from "./licenseTopics";
import { permissionExists } from "../../../../contexts";
import { connect } from "react-redux";
import { CultureContextConsumer, withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class LicenseSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseEntities: undefined,
            licenseInfo: undefined,
            showLicenseInfoModal: false,
        };
    }

    componentDidMount = async () => {
        this.showInfoLicenseModal = PubSub.subscribe(SHOW_LICENSE_INFO_MODAL, async (msg, data) => {
            if (!data?.values) {
                this.setState({ showLicenseInfoModal: false });
                return;
            }

            this.setState({
                showLicenseInfoModal: true,
            });
        });

        await this.updateLicenses();
    };

    componentWillUnmount = () => {
        this.showInfoLicenseModal && PubSub.unsubscribe(this.showInfoLicenseModal);
    };

    updateLicenses = async () => {
        const licenseEntities = await this.getActiveLicense();
        const licenseInfo = await this.getLicenseInfo();

        this.setState({ licenseEntities, licenseInfo });
    };

    async getActiveLicense() {
        const [status, licenseEntities] = await GetActiveLicenses();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения лицензий"), { variant: "error" });
        }

        return licenseEntities;
    }

    async getLicenseInfo() {
        const [status, licenseInfo] = await GetLicenseInfo();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения лицензий"), { variant: "error" });
        }

        return licenseInfo;
    }

    handleUploadLicense = async () => {
        const input = document.getElementById("fileInput");
        if (!input) {
            document.createElement("fileInput");
        }

        input.onclick = function () {
            this.value = null;
        };

        await input.click();
    };

    uploadLicense = async (file) => {
        const { strings } = this.props;
        let { text, variant } = await UploadLicense(file);

        this.props.enqueueSnackbar(strings(text), { variant: variant });
        await this.updateLicenses();
    };

    render() {
        const { licenseEntities, licenseInfo, showLicenseInfoModal } = this.state;
        const { userInfo } = this.props;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        {showLicenseInfoModal && (
                            <LicenseInfoModal
                                licenseInfo={licenseInfo}
                                CloseHandler={() => PubSub.publish(SHOW_LICENSE_INFO_MODAL, undefined)}
                            />
                        )}
                        <EventsLayoutPrimary>
                            <EventsLayoutPrimaryInner>
                                <EventsLayoutHeader>
                                    <EventsLayoutHeaderTitle>
                                        <Text variant="title" component="h1">
                                            {strings("Лицензии")}
                                        </Text>
                                    </EventsLayoutHeaderTitle>
                                    <EventsLayoutHeaderToolbox>
                                        <EventsLayoutHeaderToolboxItem>
                                            <Dropdown
                                                closeOnClickInside
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                {permissionExists(userInfo, ["settings.licenses.push"]) ? (
                                                    <DropdownItem onClick={this.handleUploadLicense}>
                                                        <input
                                                            id={"fileInput"}
                                                            type="file"
                                                            style={{ display: "none" }}
                                                            onChange={async () => {
                                                                const input = document.getElementById("fileInput");
                                                                await this.uploadLicense(input.files[0]);
                                                            }}
                                                        />
                                                        {strings("Загрузить лицензию")}
                                                    </DropdownItem>
                                                ) : (
                                                    <></>
                                                )}
                                                <DropdownItem
                                                    onClick={() =>
                                                        PubSub.publish(SHOW_LICENSE_INFO_MODAL, {
                                                            values: { licenseInfo },
                                                        })
                                                    }
                                                >
                                                    {strings("Информация о текущей лицензии")}
                                                </DropdownItem>
                                            </Dropdown>
                                        </EventsLayoutHeaderToolboxItem>
                                    </EventsLayoutHeaderToolbox>
                                </EventsLayoutHeader>
                                <LicenseTable licenseEntities={licenseEntities} licenseInfo={licenseInfo} />
                            </EventsLayoutPrimaryInner>
                        </EventsLayoutPrimary>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(LicenseSettings)));

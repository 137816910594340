import React from "react";
import { withSnackbar } from "notistack";
import { DeleteModal } from "../DeleteModal";
import PlanDetails from "./PlanDetails";
import {
    GetPlans,
    RemovePlan,
    CreatePlan,
    UpdatePlan,
    AddPlanToNode,
    GetPointsCount,
    SearchPlans,
    GetPoints,
} from "../../../../services/plans";
import DevicesOnPlanModal from "./modals/DevicesOnPlanModal";
import {
    GetLocations,
    GetNodeById,
    RemoveFromNode,
    FillLocations,
    GetLocationNames,
} from "../../../../services/locations";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { Text, Dropdown, DropdownItem, Button, ExpandableSearch } from "headpoint-react-components";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
    EventsLayoutPrimaryInner,
} from "../../../../layout/EventsLayout";
import { nanoid } from "nanoid";
import pLimit from "p-limit";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../CommonTablePagination";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import PlansTable from "./PlansTable";
import { CreateUpdateWizard } from "../../../components/CreateUpdateWizard/CreateUpdateWizard";
import { wizardSteps } from "./constants";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const limit = pLimit(10);

const initialValues = {
    location: null,
    image: null,
    name: "",
};

class PlansSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlan: null,
            planToDelete: null,
            values: initialValues,
            plansLocationsTree: [],
            showDeleteModal: false,
            showCreateModal: false,
            showOnPlanModal: false,
            editDeviceMode: WIZARD_CREATE_MODE,
            loader: false,
            search: "",
            detailsTab: "main",
            points: [],
            planViews: [],
            pageData: { start: 0, end: ITEMS_ON_PAGE },
            step: 1,
            activeStep: 1,
        };
    }

    componentDidMount = async () => {
        const [locationsStatus, locations] = await GetLocations();
        if (!locationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        this.setState({
            plansLocationsTree: FillLocations(locations, []),
        });
    };

    componentDidUpdate = async (prevProps, _) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const [locationsStatus, locations] = await GetLocations();
            if (!locationsStatus) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
                return;
            }

            this.setState({
                plansLocationsTree: FillLocations(locations, []),
            });
        }
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    openLocation = async (locationNode) => {
        const { strings } = this.props;

        let { plansLocationsTree, planViews } = this.state;
        if (locationNode?.isClosed) {
            this.setState({ plansLocationsTree });
            return;
        }

        const [locationsStatus, children] = await GetLocations(locationNode.id);
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [plansStatus, plans] = await GetPlans(locationNode.id);
        if (!plansStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения планов"), { variant: "error" });
            return;
        }

        locationNode.children =
            children?.map((l) => ({
                ...l,
                tag: "location",
                isClosed: true,
            })) ?? [];

        const newPlanViews =
            plans?.map((p) => {
                let planView = { ...p, viewId: nanoid(), deviceCount: 0, tag: "plan", locationName: locationNode.name };
                limit(async () => {
                    const [result, count] = await GetPointsCount(p.id);
                    planView.viewId = nanoid();
                    planView.deviceCount = result ? count : 0;
                    this.setState({ plansLocationsTree });
                });

                return planView;
            }) ?? [];

        planViews = planViews?.filter((pw) => !newPlanViews.some((npw) => npw.id === pw.id)) ?? [];
        planViews = planViews.concat(newPlanViews);
        locationNode.children = locationNode.children.concat(newPlanViews);
        this.setState({ plansLocationsTree, planViews });
    };

    removePlanHandler = async () => {
        const { strings } = this.props;

        const { plansLocationsTree, planToDelete, selectedPlan } = this.state;
        if (!planToDelete) {
            return;
        }

        if (await RemovePlan(planToDelete.id)) {
            this.props.enqueueSnackbar(`${strings("План")} '${planToDelete.name}' ${strings("удалён")}`, {
                variant: "success",
            });
        } else {
            this.props.enqueueSnackbar(`${strings("Не удалось удалить план")} '${planToDelete.name}'`, {
                variant: "error",
            });
        }

        const location = GetNodeById(plansLocationsTree, planToDelete.locationId);
        RemoveFromNode(location, planToDelete.id);

        this.setState({
            plansLocationsTree,
            planToDelete: null,
            selectedPlan: selectedPlan?.id === planToDelete?.id ? null : selectedPlan,
        });
    };

    editPlanHandler = async (values) => {
        const { strings } = this.props;
        const { plansLocationsTree, selectedPlan } = this.state;
        let { planViews } = this.state;
        if (!values.name) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить план без имени"), { variant: "error" });
            return;
        }

        if (!values.location) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить план без локации"), { variant: "error" });
            return;
        }

        this.setState({ loader: true });

        try {
            const plan = this.planFromValues(values);
            const [success, statusCodeCreate] = await UpdatePlan(plan);
            if (success) {
                this.props.enqueueSnackbar(`${strings("План")} '${plan.name}' ${strings("сохранен")}`, {
                    variant: "success",
                });

                const oldPlan = planViews.find((p) => p.id === plan.id);
                if (!oldPlan) {
                    this.props.enqueueSnackbar(`${strings("План")} '${plan.name}' ${strings("не найден в кэше")}`, {
                        variant: "error",
                    });
                    this.setState({ loader: false, activeStep: 1 });
                    return;
                }

                const oldLocation = GetNodeById(plansLocationsTree, oldPlan.locationId);
                const newLocation = GetNodeById(plansLocationsTree, plan.locationId);

                plan.imageName = plan.planImage?.name ?? plan.imageName;
                plan.viewId = nanoid();
                // get location name
                const [nameStatus, names] = await GetLocationNames([plan.locationId]);
                if (nameStatus && names.size) {
                    plan.locationName = names.get(plan.locationId);
                }

                // update in tree
                RemoveFromNode(oldLocation, oldPlan.id);
                AddPlanToNode(newLocation, plan);
                // update plan in views
                planViews = planViews.filter((p) => p.id !== oldPlan.id);
                planViews.push(plan);

                this.setState({
                    showCreateModal: false,
                    planViews,
                    plansLocationsTree,
                    loader: false,
                    selectedPlan: !selectedPlan ? null : plan,
                    activeStep: 1,
                });
            } else {
                switch (statusCodeCreate) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для сохранения плана")} '${plan.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(
                            `${strings("План с именем")} '${plan.name}' ${strings(
                                "уже существует в выбранной локации"
                            )}`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    default:
                        this.props.enqueueSnackbar(`Не удалось сохранить план '${plan.name}'`, { variant: "error" });
                        break;
                }
                this.setState({ loader: false });
            }
        } catch (ex) {
            this.setState({ loader: false });
        }
    };

    createPlanHandler = async (values) => {
        const { strings } = this.props;
        const { plansLocationsTree } = this.state;

        if (!values.name) {
            this.props.enqueueSnackbar(strings("Нельзя создать план без имени"), { variant: "error" });
            return;
        }

        if (!values.image?.file) {
            this.props.enqueueSnackbar(strings("Нельзя создать план без картинки"), { variant: "error" });
            return;
        }

        if (!values.location) {
            this.props.enqueueSnackbar(strings("Нельзя создать план без локации"), { variant: "error" });
            return;
        }

        this.setState({ loader: true });

        try {
            const plan = this.planFromValues(values);
            const [success, statusCodeCreate, id] = await CreatePlan(plan);
            if (success) {
                this.props.enqueueSnackbar(`${strings("План")} '${plan.name}' ${strings("создан")}`, {
                    variant: "success",
                });

                // get location name
                const [nameStatus, names] = await GetLocationNames([plan.locationId]);
                if (nameStatus && names.size) {
                    plan.locationName = names.get(plan.locationId);
                }

                const location = GetNodeById(plansLocationsTree, plan.locationId);
                AddPlanToNode(location, { ...plan, id, viewId: nanoid() });
                this.setState({ showCreateModal: false, plansLocationsTree, loader: false, activeStep: 1 });
            } else {
                switch (statusCodeCreate) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(`${strings("Не хватает прав для создания плана")} '${plan.name}'`, {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(
                            `${strings("План с именем")} '${plan.name}' ${strings(
                                "уже существует в выбранной локации"
                            )}`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    default:
                        this.props.enqueueSnackbar(`${strings("Не удалось создать план")} '${plan.name}'`, {
                            variant: "error",
                        });
                        break;
                }
                this.setState({ loader: false, activeStep: 1 });
            }
        } catch (ex) {
            this.setState({ loader: false, activeStep: 1 });
        }
    };

    valuesFromPlan = (plan) => {
        return {
            id: plan.id,
            location: plan.locationId,
            name: plan.name,
            locationName: plan.locationName,
            deviceCount: plan.deviceCount,
            properties: plan.properties,

            image: {
                imageWidth: plan.imageWidth,
                imageHeight: plan.imageHeight,
                imageName: plan.imageName,
            },
        };
    };

    planFromValues = (values) => {
        return {
            id: values.id,
            locationId: values.location,
            name: values.name,
            locationName: values.locationName,
            deviceCount: values.deviceCount,
            properties: values.properties,

            planImage: values.image?.file,
            imageWidth: values.image?.imageWidth,
            imageHeight: values.image?.imageHeight,
            imageName: values.image?.imageName,
        };
    };

    searchHandler = async (search) => {
        const { strings } = this.props;
        this.setState({ search });
        const [plansResult, plans] = await SearchPlans({ like: search, limit: 100 });
        if (!plansResult) {
            this.props.enqueueSnackbar("Ошибка получения планов", { variant: "error" });
            return;
        }

        const searchResult = plans?.map((p) => {
            let planView = { ...p, viewId: nanoid(), deviceCount: 0, tag: "plan" };
            GetPointsCount(p.id).then((countResult) => {
                const [result, count] = countResult;
                planView.viewId = nanoid();
                planView.deviceCount = result ? count : 0;
                this.setState({ searchResult });
            });

            return planView;
        });

        GetLocationNames(plans?.filter((p) => !!p?.locationId).map((p) => p.locationId)).then(([status, names]) => {
            if (!status) {
                this.props.enqueueSnackbar(strings("Ошибка получения имен локаций для планов"), { variant: "error" });
                return;
            }

            searchResult?.forEach((p) => (p.locationName = names.get(p.locationId) ?? p.locationId));
        });

        this.setState({ searchResult });
    };

    render() {
        const {
            selectedPlan,
            plansLocationsTree,
            showDeleteModal,
            showCreateModal,
            showOnPlanModal,
            editDeviceMode,
            values,
            loader,
            search,
            searchResult,
            detailsTab,
            points,
            planViews,
            pageData,
            planToDelete,
            step,
            activeStep,
        } = this.state;
        const { strings } = this.props;

        return (
            <>
                {loader && <LoaderAnimation message={strings("Отправка плана. Подождите...")} />}
                <DeleteModal
                    visible={showDeleteModal}
                    CloseHandler={() => this.setState({ showDeleteModal: false })}
                    RemoveHandler={this.removePlanHandler}
                    text={`${strings("Вы хотите удалить план")} "${planToDelete?.name}". ${strings(
                        "Удалённый план нельзя восстановить. Продолжить?"
                    )}`}
                />

                <CreateUpdateWizard
                    visible={showCreateModal}
                    title={editDeviceMode === "create" ? strings("Создать план") : strings("Редактировать план")}
                    steps={wizardSteps}
                    step={step}
                    activeStep={activeStep}
                    values={values}
                    setValues={(name, value) => this.setState({ values: { ...values, [name]: value } })}
                    NextStep={this.nextStep}
                    SetActiveStep={this.selectStep}
                    CreateHandler={this.createPlanHandler}
                    EditHandler={this.editPlanHandler}
                    CloseHandler={() => this.setState({ showCreateModal: false })}
                    Mode={editDeviceMode}
                />

                {showOnPlanModal && (
                    <DevicesOnPlanModal
                        selectedPlan={selectedPlan}
                        points={points}
                        handleClose={(planIds) => {
                            const plans = planViews?.filter((pw) => planIds.some((id) => pw.id === id));
                            plans?.forEach((p) =>
                                limit(async () => {
                                    const [result, count] = await GetPointsCount(p.id);
                                    p.viewId = nanoid();
                                    p.deviceCount = result ? count : 0;
                                    this.setState({ plansLocationsTree });
                                })
                            );

                            this.setState({ showOnPlanModal: false });
                        }}
                    />
                )}

                <EventsLayoutPrimary>
                    <EventsLayoutPrimaryInner>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings("Планы")}
                                </Text>
                                <ExpandableSearch
                                    value={search}
                                    onChange={(e) => this.searchHandler(e.target.value)}
                                    onSubmit={() => {
                                        /* ignore enter */
                                    }}
                                    placeholder={strings("Найти")}
                                    colorVariant="dark"
                                    inHeader
                                />
                            </EventsLayoutHeaderTitle>
                            <EventsLayoutHeaderToolbox>
                                {!selectedPlan && (
                                    <EventsLayoutHeaderToolboxItem>
                                        <Button
                                            elSize="md"
                                            icon="globe"
                                            label={strings("Размещение на плане")}
                                            title={strings("Размещение на плане")}
                                            variant="secondary"
                                            onClick={async () => this.setState({ showOnPlanModal: true, points: null })}
                                        />
                                    </EventsLayoutHeaderToolboxItem>
                                )}

                                <EventsLayoutHeaderToolboxItem>
                                    <Dropdown
                                        toggleLabel={strings("Создать")}
                                        closeOnClickInside
                                        toggleVariant="secondary"
                                        horizontalAlignment="right"
                                    >
                                        <DropdownItem
                                            as="button"
                                            onClick={() =>
                                                this.setState({
                                                    showCreateModal: true,
                                                    values: initialValues,
                                                    editDeviceMode: WIZARD_CREATE_MODE,
                                                })
                                            }
                                        >
                                            {strings("Новый план")}
                                        </DropdownItem>
                                    </Dropdown>
                                </EventsLayoutHeaderToolboxItem>
                            </EventsLayoutHeaderToolbox>
                        </EventsLayoutHeader>
                        <PlansTable
                            plansLocationsTree={
                                !!search ? searchResult : plansLocationsTree.slice(pageData.start, pageData.end)
                            }
                            selectedPlan={selectedPlan}
                            setSelectedPlan={(plan) => this.setState({ selectedPlan: plan })}
                            openLocation={this.openLocation}
                            deletePlanHandler={(p) => this.setState({ showDeleteModal: true, planToDelete: p })}
                            editPlanHandler={(p) =>
                                this.setState({
                                    showCreateModal: true,
                                    values: this.valuesFromPlan(p),
                                    editDeviceMode: WIZARD_UPDATE_MODE,
                                })
                            }
                        />
                    </EventsLayoutPrimaryInner>
                    <CommonTablePagination
                        tableData={plansLocationsTree}
                        setPaging={(pageData) => this.setState({ pageData })}
                        searchMode={search !== ""}
                    />
                </EventsLayoutPrimary>
                {selectedPlan && (
                    <PlanDetails
                        selectedPlan={selectedPlan}
                        setSelectedPlan={(plan) => this.setState({ selectedPlan: plan })}
                        setDetailsTab={(detailsTab) => this.setState({ detailsTab: detailsTab })}
                        removePlan={(plan) => this.setState({ showDeleteModal: true, planToDelete: plan })}
                        editPlan={() =>
                            this.setState({
                                showCreateModal: true,
                                values: this.valuesFromPlan(selectedPlan),
                                editDeviceMode: WIZARD_UPDATE_MODE,
                            })
                        }
                        detailsTab={detailsTab}
                        openDevicesPlacement={async () => {
                            const [pointsStatus, points] = await GetPoints(selectedPlan.id);
                            if (!pointsStatus) {
                                this.props.enqueueSnackbar(
                                    `${strings("Ошибка получения объектов плана")} '${selectedPlan.name}'`,
                                    {
                                        variant: "error",
                                    }
                                );
                            }

                            this.setState({ showOnPlanModal: true, points });
                        }}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(PlansSettings)));

import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import Marker from "../../../components/Marker";
import { EventsLayoutPrimary } from "../../../../layout/EventsLayout";
import {
    checkFeatureByDeviceType,
    GeneralContextConsumer,
    getAdapters,
    getDeviceTypes,
    getServiceConfig,
    permissionExists,
    serviceExists,
} from "../../../../contexts";
import { CameraAngleModeButton } from "../../../components/CameraAngleButtons";
import { GetViewZone } from "../../../../services/viewZones";
import MarkerViewAngle from "../../../components/MarkerViewAngle";
import { connect } from "react-redux";
import { Text } from "headpoint-react-components";

const VIDEO_STREAM_FEATURE_CODE = "video.stream";

class EventDetailsMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showViewAngle: false,
        };
    }

    componentDidMount() {
        void this.loadViewZone();
    }

    loadViewZone = async () => {
        const { generalInfo, device, strings } = this.props;
        if (!serviceExists(generalInfo, "service.api.view.angle")) {
            return;
        }

        const adapters = getAdapters(generalInfo) ?? [];
        const deviceTypes = getDeviceTypes(generalInfo);
        const deviceType = deviceTypes.find((type) => type.value === device?.typeId);
        if (!deviceType || !checkFeatureByDeviceType(deviceType?.value, adapters, VIDEO_STREAM_FEATURE_CODE)) {
            return false;
        }

        const [status, zone] = await GetViewZone(device.id);
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения зоны обзора"), { variant: "error" });
            return;
        }

        this.setState({ zone });
    };

    percentToDegrees = (value) => {
        return (value * 360.0) % 360.0;
    };

    render() {
        const { device, strings, userInfo } = this.props;
        const { showViewAngle, zone } = this.state;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <EventsLayoutPrimary noPadding style={{ zIndex: 1 }}>
                <GeneralContextConsumer>
                    {(generalContext) => {
                        const { mapDefaultCenter, mapZoom } = getServiceConfig(generalContext, "service.api.map");
                        const deviceTypes = getDeviceTypes(generalContext);
                        const adapters = getAdapters(generalContext) ?? [];
                        const mapCenter =
                            device.properties?.geolocation?.latitude && device.properties?.geolocation?.longitude
                                ? [device.properties?.geolocation?.latitude, device.properties?.geolocation?.longitude]
                                : mapDefaultCenter;

                        const type = deviceTypes?.find((t) => t.value === device.typeId);
                        const icon = type?.icon?.type;
                        const tooltip = [
                            {
                                label: strings(type?.label),
                                text: (
                                    <Text color={"gray"} variant={"body-sm"}>
                                        {device.name}
                                    </Text>
                                ),
                            },
                        ];

                        return (
                            <MapContainer
                                key={device.id}
                                center={mapCenter}
                                zoom={mapZoom}
                                scrollWheelZoom={true}
                                doubleClickZoom={false}
                            >
                                <TileLayer url="/tile/{z}/{x}/{y}.png" />
                                {serviceExists(generalContext, "service.api.view.angle") &&
                                    permissionExists(userInfo, "view.angles.access") && (
                                        <CameraAngleModeButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({ showViewAngle: !showViewAngle });
                                            }}
                                            checked={showViewAngle}
                                        />
                                    )}
                                {showViewAngle &&
                                type?.value &&
                                checkFeatureByDeviceType(type?.value, adapters, VIDEO_STREAM_FEATURE_CODE) ? (
                                    <MarkerViewAngle
                                        key={device.id}
                                        position={[
                                            device.properties?.geolocation?.latitude ?? 0,
                                            device.properties?.geolocation?.longitude ?? 0,
                                        ]}
                                        icon={icon}
                                        colorVariant={"success"}
                                        tooltip={tooltip}
                                        angle={this.percentToDegrees(zone)}
                                    />
                                ) : (
                                    <Marker
                                        key={device.id}
                                        position={[
                                            device.properties?.geolocation?.latitude ?? 0,
                                            device.properties?.geolocation?.longitude ?? 0,
                                        ]}
                                        icon={icon}
                                        colorVariant={"success"}
                                        tooltip={tooltip}
                                    />
                                )}
                            </MapContainer>
                        );
                    }}
                </GeneralContextConsumer>
            </EventsLayoutPrimary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(EventDetailsMap);

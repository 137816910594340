import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { checkSuperUser } from "../../../../../../contexts/GeneralContext";
import { ModalSection, Select } from "headpoint-react-components";
import { EMPTY_UUID } from "../../constants";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class ModalStepRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleSystemFunctions: [],
        };
    }

    render() {
        let { userInfo, values, availableRoles, SetValuesProperties, strings } = this.props;

        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        const disabled = userInfo.userId === values.id;

        availableRoles = availableRoles?.map((r) => ({ value: r.id, label: r.name })) ?? [];
        if (checkSuperUser({ groupId: values.group })) {
            availableRoles = [{ value: EMPTY_UUID, label: strings("Без роли") }].concat(availableRoles);
        }

        return (
            <>
                <ModalSection>
                    <ModalFormItem>
                        <Select
                            colorVariant="default"
                            fullWidth
                            horizontalAlignment="left"
                            label={strings("Роль")}
                            placeholder={strings("Выберите роль")}
                            value={values.roleId}
                            onChange={(val) =>
                                SetValuesProperties({ roleId: val, inheritsGroupProperties: values.roleId === val })
                            }
                            options={availableRoles}
                            verticalAlignment="bottom"
                            error={!values.roleId ? strings("Поле не может быть пустым") : null}
                            disabled={disabled}
                        />
                    </ModalFormItem>
                </ModalSection>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(ModalStepRoles));
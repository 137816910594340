import React from "react";
import {
    Modal,
    ModalHeader,
    ModalSection,
    ModalFooter,
    ModalBody,
    TextField,
    Button,
    Select,
    Space,
} from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../../components/CreateUpdateWizard/constants";

export class SystemVariablesModal extends React.Component {
    formatValue = (value, type) => {
        switch (type) {
            case "int":
                const v = parseInt(value);
                if (!isNaN(v)) {
                    return v;
                }
                break;
            default:
                break;
        }

        return value;
    };

    isDisabled(values) {
        const editable = values?.editable ?? true;

        return (!values.code && !values.serviceCode) || !editable;
    }

    render() {
        const {
            avalibleVariables,
            values,
            SetValue,
            SetInfo,
            Mode,
            CreateHandler,
            EditHandler,
            CloseHandler,
            variables,
        } = this.props;

        let source = avalibleVariables ?? [];
        if (Mode === WIZARD_CREATE_MODE) {
            source = source?.filter(
                (info) =>
                    !variables.some((curent) => curent.code === info.code && curent.serviceCode === info.serviceCode)
            );
        }

        let textFieldType = values.type;
        if (values.type === "string") {
            textFieldType = "text";
        }

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <Modal size="sm">
                        <ModalHeader
                            title={
                                Mode === WIZARD_CREATE_MODE
                                    ? strings("Создать системную переменную")
                                    : strings("Изменить системную переменную")
                            }
                            align="center"
                            closeHandler={CloseHandler}
                        />
                        <ModalBody wrap>
                            <ModalSection>
                                <Select
                                    label={strings("Системная переменная")}
                                    placeholder={strings("Выбрать переменную из доступных")}
                                    value={values.code ? `${values.serviceCode}__${values.code}` : undefined}
                                    options={source?.map((v) => ({
                                        value: `${v.serviceCode}__${v.code}`,
                                        label: strings(v.name),
                                    }))}
                                    onChange={SetInfo}
                                    colorVariant="default"
                                    horizontalAlignment="left"
                                    verticalAlignment="bottom"
                                    fullWidth
                                    disabled={Mode === WIZARD_UPDATE_MODE}
                                />
                            </ModalSection>
                            <ModalSection>
                                <TextField
                                    label={strings("Код сервиса")}
                                    name="title"
                                    type="text"
                                    disabled
                                    value={values.serviceCode ?? ""}
                                    colorVariant="light"
                                    fullWidth
                                />
                                <Space />
                                <TextField
                                    label={strings("Код")}
                                    name="title"
                                    type="text"
                                    disabled
                                    value={values.code ?? ""}
                                    colorVariant="light"
                                    fullWidth
                                />
                                <Space />
                                <TextField
                                    label={strings("Комментарий")}
                                    name="title"
                                    type="text"
                                    disabled
                                    value={strings(values.description) ?? ""}
                                    colorVariant="light"
                                    fullWidth
                                    inputProps={{
                                        as: "textarea",
                                        rows: 3,
                                    }}
                                />
                                <Space />
                            </ModalSection>
                            <ModalSection>
                                <TextField
                                    label={strings("Значение")}
                                    name="title"
                                    type={textFieldType ?? "text"}
                                    value={values.value ?? ""}
                                    onChange={(e) => SetValue(this.formatValue(e.target.value, values.type))}
                                    colorVariant="light"
                                    fullWidth
                                    disabled={this.isDisabled(values)}
                                    error={
                                        values.value === null || values.value === undefined || values.value === ""
                                            ? strings("Поле не может быть пустым")
                                            : null
                                    }
                                />
                            </ModalSection>
                        </ModalBody>
                        <ModalFooter>
                            {Mode === WIZARD_CREATE_MODE ? (
                                <Button
                                    variant="primary"
                                    label={strings("Создать")}
                                    disabled={this.isDisabled(values)}
                                    onClick={CreateHandler}
                                />
                            ) : (
                                <Button variant="primary" label={strings("Сохранить")} onClick={EditHandler} />
                            )}
                        </ModalFooter>
                    </Modal>
                )}
            </CultureContextConsumer>
        );
    }
}

import { urlWithQuery } from "../utilites/UrlUtils";

const createOrderEndpoint = "/converter-api/converter/order";
const getOrdersEndpoint = "/converter-api/converter/orders";
const getCountEndpoint = "/converter-api/converter/count";
const converterApi = "/converter-api/converter";

export const GetConverterOrders = async (fileId, rowNumber, rowsLimit, offset) => {
    const token = window.localStorage.getItem("token");
    let query = new URLSearchParams({ rowNumber, rowsLimit, offset });

    let response = await fetch(urlWithQuery(`${getOrdersEndpoint}/${fileId}`, query), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let result = await response.json();

        return [true, result?.items];
    } catch (ex) {
        console.error(ex);

        return [false, null];
    }
};

export const CountConverterOrders = async (fileId) => {
    const token = window.localStorage.getItem("token");

    let query = new URLSearchParams();

    let response = await fetch(urlWithQuery(`${getCountEndpoint}/${fileId}`, query), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, 0];
    }

    try {
        let request = await response.json();
        return [true, request?.total ?? 0];
    } catch (ex) {
        console.error(ex);

        return [false, 0];
    }
};

export const CreateConverterOrder = async (converterOrder) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(createOrderEndpoint, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(converterOrder),
    });

    if (response.status !== 200) {
        return [false, response.status];
    }

    try {
        return [true, response.status];
    } catch (ex) {
        console.error(ex);

        return [false, response.status];
    }
};

export const CancelConverterOrder = async (taskId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(converterApi + `/${taskId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    let result = null;
    try {
        result = await response.json();
    } catch {
        // ignore
    }
    return [response.status === 200, response.status, result];
};

import React from "react";
import styled from "styled-components";
import {
    Modal,
    ModalHeader,
    ModalFooterItem,
    ModalBody,
    ModalBodyItem,
    Button,
    Row,
    Select,
    TextField,
} from "headpoint-react-components";
import PubSub from "pubsub-js";
import { CLOSE_ALL_MODALS, ROLE_COPIED } from "../../rolesSettingsTopics";
import { CreateRoleCopy, GetRoles } from "../../../../../../services/roles";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

export class RoleCopyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            rolesGroups: [],
            roleId: undefined,
            name: "",
        };
    }
    componentDidMount = async () => {
        const [rolesStatus, roles] = await GetRoles();
        if (!rolesStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения списка ролей"), { variant: "error" });
            return;
        }

        this.setState({ roles });
    };

    createHandler = async () => {
        const { name, roleId } = this.state;
        const { strings } = this.props;

        if (!name) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить роль без имени"), { variant: "error" });
            return;
        }

        if (!roleId) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить копию без базовой роли"), { variant: "error" });
            return;
        }

        let role = { name: name, baseRoleId: roleId };
        let [status, statusCode] = await CreateRoleCopy(role);

        if (status) {
            this.props.enqueueSnackbar(`${strings("Копия")} '${role.name}' ${strings("создана")}`, {
                variant: "success",
            });
            PubSub.publish(ROLE_COPIED);
            PubSub.publish(CLOSE_ALL_MODALS);
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(`${strings("Не хватает прав для создания роли")} '${role.name}'`, {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    this.props.enqueueSnackbar(
                        `${strings("Роль с именем")} '${role.name}' ${strings("уже существует")}`,
                        { variant: "warning" }
                    );
                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось создать роль")} '${role.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }
    };

    render() {
        const { roles, roleId, name } = this.state;
        const { strings } = this.props;
        const availableRoleNames = roles?.map((r) => {
            return { label: r.name, value: r.id };
        });

        return (
            <Modal size="sm">
                <ModalHeader
                    title={strings("Скопировать существующую роль")}
                    closeHandler={() => PubSub.publish(CLOSE_ALL_MODALS)}
                />
                <ModalBody>
                    <ModalBodyItem>
                        <ModalFormItem>
                            <TextField
                                label={strings("Название роли")}
                                name="name"
                                type="text"
                                value={name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                colorVariant="light"
                                fullWidth
                                error={!name ? strings("Поле не может быть пустым") : null}
                            />
                        </ModalFormItem>
                        <ModalFormItem>
                            <Select
                                colorVariant="default"
                                fullWidth
                                horizontalAlignment="left"
                                label={strings("Роль")}
                                placeholder={strings("Выберите роль")}
                                value={roleId}
                                onChange={(e) => this.setState({ roleId: e })}
                                options={availableRoleNames}
                                verticalAlignment="bottom"
                            />
                        </ModalFormItem>

                        <Row marginTop={32}>
                            <ModalFooterItem>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    label={strings("Создать копию")}
                                    onClick={this.createHandler}
                                />
                            </ModalFooterItem>

                            <ModalFooterItem>
                                <Button
                                    type="reset"
                                    variant="ghost"
                                    label="Отмена"
                                    onClick={() => PubSub.publish(CLOSE_ALL_MODALS)}
                                />
                            </ModalFooterItem>
                        </Row>
                    </ModalBodyItem>
                </ModalBody>
            </Modal>
        );
    }
}

const ModalFormItem = styled.div`
    & + & {
        margin-top: 16px;
    }
`;

export default withCultureContext(withSnackbar(RoleCopyModal));

import React from "react";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class ModalStepDefault extends React.Component {
    handleInputChange = (e) => {
        const fieldName = e.rawEvent.target.name;
        this.props.SetValuesProperty(fieldName, e.target.value);
    };

    handleCommentChange = (e) => {
        this.props.SetValuesProperty("properties", { comment: e.target.value });
    };

    render() {
        const { values } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <ModalFormItem>
                                <TextField
                                    id="name"
                                    label={strings("Название тега")}
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={!values.name ? strings("Поле не может быть пустым") : null}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="comment"
                                    label={strings("Комментарий")}
                                    name="comment"
                                    type="text"
                                    value={values?.properties?.comment ?? ""}
                                    onChange={this.handleCommentChange}
                                    colorVariant="light"
                                    inputProps={{
                                        as: "textarea",
                                        rows: 4,
                                    }}
                                    fullWidth
                                />
                            </ModalFormItem>
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

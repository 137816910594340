import React from "react";
import { Marker as LeafletMarker } from "react-leaflet";
import { useLeafletIcon } from "react-leaflet-icon";
import { PlanCluster } from "headpoint-react-components";

const Cluster = ({ position, isMuted, eventVariant, ...rest }) => {
    const [markerIcon, MarkerIcon] = useLeafletIcon();
    return (
        <>
            <MarkerIcon>
                <PlanCluster position={[0, 0]} isMuted={isMuted} eventVariant={eventVariant} {...rest} />
            </MarkerIcon>
            <LeafletMarker position={position} icon={markerIcon} />
        </>
    );
};

export default Cluster;

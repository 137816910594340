import React from "react";
import DeviceTypesAndTagsFilter from "../../../../components/DeviceTypesAndTagsFilter/DeviceTypesAndTagsFilter";

export class ModalStepTagsAndTypes extends React.Component {

    setTagsAndTypes = (name, filter) => {
        const { values, SetValuesProperty } = this.props;
        switch (name) {
            case "tagsFilter":
                SetValuesProperty("properties", { ...values.properties, tags: filter });
                break;
            case "typesFilter":
                SetValuesProperty("properties", { ...values.properties, types: filter });
                break;
            default:
                break;
        }
    }

    render() {
        const { values } = this.props;

        return (
            <DeviceTypesAndTagsFilter
                showCount={true}
                typesFilter={values?.properties?.types ?? []}
                tagsFilter={values?.properties?.tags ?? []}
                setFilter={this.setTagsAndTypes}
            />
        );
    }
}

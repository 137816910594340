export const PLANS_DEVICE_LOCATIONS_FILTER = "plans.deviceLocations.filter";
export const SHOW_DETAILS_TOPIC = "plans.showDetails";
export const FREEZE_PLAN_TOPIC = "plans.freeze.topic";
export const OPEN_CLUSTER_TOPIC = "plans.open.cluster.topic";
export const LOAD_CLUSTER_VIEW_TOPIC = "plans.clusters.load.zone";
export const GET_DEVICE_EVENTS_PLANS = "plans.getEvents.topic";
export const SHOW_CREATE_EVENTS_FILTER = "plans.filters.show.create";
export const SHOW_DELETE_EVENTS_FILTER = "plans.filters.show.delete";
export const SHOW_UPDATE_EVENTS_FILTER = "plans.filters.show.update";
export const CREATE_EVENTS_FILTER = "plans.filters.create";
export const UPDATE_EVENTS_FILTER = "plans.filters.update";
export const LOAD_EVENTS_FILTER = "plans.filters.load";

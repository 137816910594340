import React from "react";
import { permissionExists } from "../../../../contexts";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";
import { FilterAccordionStatic } from "../../../components/FiltersStatic";
import { GetUsersGroups } from "../../../../services/users";
import {
    Modal,
    ModalHeader,
    ModalSection,
    ModalFooter,
    ModalBody,
    TextField,
    Button,
    ModalFooterItem,
    Select,
    SteppedInput,
    Text,
    Space,
    Row,
} from "headpoint-react-components";
import styled from "styled-components";
import { withSnackbar } from "notistack";
import { PERSONAL_PRESET_TYPE, GROUP_PRESET_TYPE, SYSTEM_PRESET_TYPE } from "../constants";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { SUPER_USER_GROUP_ID } from "../../../../contexts/GeneralContext";
import { isNumber } from "../../../../utilites/CommonUtils";
import { SelectedCounter } from "../../../components/SelectedCounter/SelectedCounter";

const PtzBlockWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    position: relative;
    grid-template-columns: 33.3% 33.4% 33.3%;
    justify-items: center;
    justify-content: center;
    z-index: 1;
    grid-gap: 2px;
`;

const types = [
    {
        label: "Персональный",
        value: PERSONAL_PRESET_TYPE,
        permission: "presets.personal.lifeCycle",
    },
    {
        label: "Групповой",
        value: GROUP_PRESET_TYPE,
        permission: "presets.group.lifeCycle",
    },
    {
        label: "Системный",
        value: SYSTEM_PRESET_TYPE,
        permission: "presets.system.lifeCycle",
    },
];

class CreateUpdatePresetModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            usersGroups: [],
            isEditAllGroupsAllowed: false,
            userGroup: null,
        };
    }

    componentDidMount = async () => {
        const { userInfo, strings } = this.props;
        if (permissionExists(userInfo, "users.userGroup.read")) {
            const [status, usersGroups] = await GetUsersGroups();
            if (!status) {
                this.props.enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
                return;
            }

            const isEditAllGroupsAllowed = permissionExists(userInfo, "presets.groups.all.lifeCycle");

            this.setState({ usersGroups, isEditAllGroupsAllowed, userGroup: userInfo.groupId });
        }
    };

    toFixedIfNumber = (value) => {
        const precision = 6;
        return isNumber(value) ? Number(value.toFixed(precision)) : value;
    };

    ptzControl = () => {
        const { values, SetValuesProperty } = this.props;
        return (
            <PtzBlockWrapper>
                <SteppedInput
                    fullWidth
                    label="Pan"
                    colorVariant="light"
                    value={values.pan}
                    onChange={(e) => {
                        const pan = this.toFixedIfNumber(e);
                        SetValuesProperty("pan", pan);
                    }}
                    min={-1}
                    max={1}
                    step={0.1}
                />
                <SteppedInput
                    fullWidth
                    label="Tilt"
                    colorVariant="light"
                    value={values.tilt}
                    onChange={(e) => {
                        const tilt = this.toFixedIfNumber(e);
                        SetValuesProperty("tilt", tilt);
                    }}
                    min={-1}
                    max={1}
                    step={0.1}
                />
                <SteppedInput
                    fullWidth
                    label="Zoom"
                    colorVariant="light"
                    value={values.zoom}
                    onChange={(e) => {
                        const zoom = this.toFixedIfNumber(e);
                        SetValuesProperty("zoom", zoom);
                    }}
                    min={0}
                    max={1}
                    step={0.1}
                />
            </PtzBlockWrapper>
        );
    };

    filterGroupsByPermissions(group) {
        const { userGroup } = this.state;

        if (this.state.isEditAllGroupsAllowed || userGroup === SUPER_USER_GROUP_ID) {
            return true;
        }

        return userGroup === group.id;
    }
    validatePreset = (values) => {
        const { strings } = this.props;

        if (!values) {
            this.props.enqueueSnackbar(strings("Передан пустой объект"), { variant: "error" });
            return false;
        }

        if (!values.name || values.name.length === 0) {
            this.props.enqueueSnackbar(strings("Имя пресета не может быть пустым"), { variant: "error" });
            return false;
        }

        if (!values.type) {
            this.props.enqueueSnackbar(strings("Необходимо выбрать тип пресета"), { variant: "error" });
            return false;
        }

        switch (values.type) {
            case GROUP_PRESET_TYPE:
                if (!values.entities || values.entities.length === 0) {
                    this.props.enqueueSnackbar(strings("Необходимо выбрать хотя бы одну группу пользователей"), {
                        variant: "error",
                    });
                    return false;
                }
                break;
            default:
                break;
        }

        return true;
    };

    render() {
        const { userInfo, values, CloseHandler, SaveHandler, SetValuesProperty, title, strings } = this.props;
        const { search, usersGroups } = this.state;

        const groups =
            usersGroups
                ?.filter((ug) => this.filterGroupsByPermissions(ug))
                ?.filter((ug) => ug?.name?.toLowerCase().includes(search.toLowerCase())) ?? [];

        return (
            <Modal size="sm">
                <ModalHeader title={title} align="center" closeHandler={CloseHandler} />
                <ModalBody>
                    <ModalSection>
                        <ModalFormItem>
                            <TextField
                                label={strings("Название")}
                                name="title"
                                type="text"
                                value={values?.name ?? ""}
                                onChange={(e) => SetValuesProperty("name", e.target.value)}
                                colorVariant="light"
                                fullWidth
                                error={!values?.name ? strings("Поле не может быть пустым") : null}
                            />
                        </ModalFormItem>
                        <ModalFormItem>
                            <TextField
                                label={strings("Описание")}
                                name="description"
                                type="text"
                                value={values.description ?? ""}
                                onChange={(e) => SetValuesProperty("description", e.target.value)}
                                colorVariant="light"
                                fullWidth
                                inputProps={{
                                    as: "textarea",
                                    rows: 3,
                                }}
                            />
                        </ModalFormItem>
                        <ModalFormItem>{this.ptzControl()}</ModalFormItem>
                        <ModalFormItem>
                            <Select
                                label={strings("Тип пресета")}
                                placeholder={strings("Выбрать тип")}
                                value={values?.type}
                                onChange={(e) => SetValuesProperty("type", e)}
                                options={types
                                    .filter((t) => permissionExists(userInfo, t.permission))
                                    .map((t) => {
                                        return { ...t, label: strings(t.label) };
                                    })}
                                fullWidth
                                error={!values?.type ? strings("Поле не может быть пустым") : null}
                            />
                        </ModalFormItem>
                        <ModalFormItem>
                            {values?.type === GROUP_PRESET_TYPE &&
                                permissionExists(userInfo, "users.userGroup.read") && (
                                    <>
                                        <Space />
                                        <ModalFormItem>
                                            <Row justify="between" align="bottom" marginBottom={24}>
                                                <Text variant="body">{strings("Группы пользователей")}</Text>
                                                <SelectedCounter
                                                    count={values?.entities?.length ?? 0}
                                                    onClear={() => SetValuesProperty("entities", [])}
                                                    strings={strings}
                                                />
                                            </Row>
                                            <Row marginBottom={12}>
                                                <TextField
                                                    autoComplete={false}
                                                    colorVariant="light"
                                                    fullWidth
                                                    icon="search"
                                                    onChange={(e) => this.setState({ search: e.target.value })}
                                                    placeholder={strings("Найти")}
                                                    type="search"
                                                    value={search}
                                                />
                                            </Row>
                                            <FilterAccordionStatic
                                                key={!!search}
                                                preOpen={!!search}
                                                items={groups}
                                                selected={values?.entities ?? []}
                                                selectHandler={(e) => SetValuesProperty("entities", e)}
                                            />
                                        </ModalFormItem>
                                    </>
                                )}
                            {values?.type === GROUP_PRESET_TYPE &&
                                !permissionExists(userInfo, "users.userGroup.read") && (
                                    <>
                                        <Space />
                                        <ModalFormItem>
                                            <Row justify="between" align="bottom" marginBottom={24}>
                                                <Text variant="body" color="danger">
                                                    {strings("Нет прав для просмотра групп пользователей!")}
                                                </Text>
                                            </Row>
                                        </ModalFormItem>
                                    </>
                                )}
                        </ModalFormItem>
                    </ModalSection>
                </ModalBody>
                <ModalFooter>
                    <ModalFooterItem>
                        <Button
                            variant="primary"
                            label={strings("Сохранить")}
                            onClick={() => {
                                if (this.validatePreset(values)) {
                                    SaveHandler(values);
                                }
                            }}
                        />
                    </ModalFooterItem>
                    <ModalFooterItem>
                        <Button variant="secondary" label={strings("Отмена")} onClick={() => CloseHandler(values)} />
                    </ModalFooterItem>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(CreateUpdatePresetModal)));

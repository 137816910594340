import React from "react";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import {
    Button,
    Icon,
    Row,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableHoverContainer,
    TableRow,
    Text,
    Space,
} from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DeviceTagsTable extends React.Component {
    render() {
        const { setSelected, selected, tags } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Тег оборудования")}</TableHeadCell>
                                    <TableHeadCell>{strings("Устройств")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {tags?.map((tag) => (
                                    <TableRow key={tag.id} isSelected={selected && tag.id === selected.id}>
                                        <TableCell verticalAlign="middle">
                                            <Row align="middle">
                                                <Text color="primary" variant="body-sm">
                                                    <Icon icon="tag" size={16} />
                                                </Text>
                                                <Space />
                                                <Text color="primary" variant="body-sm">
                                                    {tag.name}
                                                </Text>
                                            </Row>
                                        </TableCell>
                                        <TableCell verticalAlign="middle">
                                            <Text color="primary" variant="body-sm">
                                                {tag.entityIds?.length ?? "..."}
                                            </Text>
                                        </TableCell>
                                        <TableCell verticalAlign="middle" noVerticalSpace>
                                            <TableHoverContainer>
                                                <Button
                                                    icon="info"
                                                    title="Info"
                                                    variant="ghost"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setSelected(tag);
                                                    }}
                                                />
                                            </TableHoverContainer>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import {
    Modal,
    ModalHeader,
    ModalSection,
    ModalFooter,
    ModalBody,
    TextField,
    Button,
} from "headpoint-react-components";
import PubSub from "pubsub-js";
import { CLOSE_ALL_MODALS } from "../../rolesSettingsTopics";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

class RolesGroupsModal extends React.Component {
    rolesGroupFromValues = (values) => {
        return {
            id: values.id,
            name: values.groupName,
            properties: values.properties,
        };
    };

    CloseHandler = () => {
        PubSub.publish(CLOSE_ALL_MODALS);
    };

    render() {
        const { values } = this.state;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <Modal size="sm">
                        <ModalHeader title={this.title} align="center" closeHandler={this.CloseHandler} />
                        <ModalBody>
                            <ModalSection>
                                <ModalFormItem>
                                    <TextField
                                        label={strings("Название группы ролей")}
                                        name="title"
                                        type="text"
                                        value={values.groupName}
                                        onChange={(e) =>
                                            this.setState({ values: { ...values, groupName: e.target.value } })
                                        }
                                        colorVariant="light"
                                        fullWidth
                                        error={!values.groupName ? strings("Поле не может быть пустым") : null}
                                    />
                                </ModalFormItem>
                                <ModalFormItem>
                                    <TextField
                                        label={strings("Комментарий")}
                                        name="comment"
                                        type="text"
                                        value={values.properties.comment}
                                        onChange={(e) =>
                                            this.setState({
                                                values: {
                                                    ...values,
                                                    properties: { ...values.properties, comment: e.target.value },
                                                },
                                            })
                                        }
                                        colorVariant="light"
                                        fullWidth
                                        inputProps={{
                                            as: "textarea",
                                            rows: 4,
                                        }}
                                    />
                                </ModalFormItem>
                            </ModalSection>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="primary" label={strings("Сохранить")} onClick={() => this.saveHandler()} />
                        </ModalFooter>
                    </Modal>
                )}
            </CultureContextConsumer>
        );
    }
}

export default RolesGroupsModal;

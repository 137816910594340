import React from "react";
import styled from "styled-components";
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalFooterItem,
    ModalBody,
    ModalBodyItem,
    TextField,
    Button,
    Row,
    Checkbox,
} from "headpoint-react-components";

import { withSnackbar } from "notistack";
import { FillLocations, FillNode, GetLocations } from "../../../../../services/locations";
import { getAdapters } from "../../../../../contexts";
import { GetDevices, SearchDevices } from "../../../../../services/devices";
import { getDeviceTypesByFeature } from "../../../../../contexts/GeneralContext";
import { FilterAccordion } from "../../../../components/Filters";
import pLimit from "p-limit";
import { ExecuteCommand } from "../../../../../services/featureCommands";
import { LoaderAnimation } from "../../../../components/LoaderAnimation/LoaderAnimation";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { SelectedCounter } from "../../../../components/SelectedCounter/SelectedCounter";

const limit = pLimit(10);
const CONNECT_DEVICE_COMMAND_NAME = "connect";
const DISCONNECT_DEVICE_COMMAND_NAME = "disconnect";

class ConnectDeviceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            filteredDevices: [],
            search: "",
            devices: [],
            selectedDeviceIds: [],
            connectedDeviceIds: [],
            isLoading: false,
        };
    }

    componentDidMount = async () => {
        const { strings } = this.props;

        const [status, locations] = await GetLocations();
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        let selectedDeviceIds = await this.getConnectedDevices();

        this.setState({
            devicesLocationsTree: FillLocations(locations, []),
            selectedDeviceIds,
            connectedDeviceIds: selectedDeviceIds,
        });
    };

    async getConnectedDevices() {
        const { strings } = this.props;
        const devicesCommand = this.props.serviceFeature.registration["devices"];
        const featureCode = this.props.serviceFeature.featureCode;
        const serviceCode = this.props.selectedAdapter.serviceCode;

        if (devicesCommand && devicesCommand.path && devicesCommand.method) {
            const [getSelectedDeviceIdsStatus, , connectedDeviceIds] = await ExecuteCommand(
                devicesCommand.method,
                serviceCode,
                featureCode,
                devicesCommand.path,
                {}
            );

            if (!getSelectedDeviceIdsStatus) {
                this.props.enqueueSnackbar(strings(`Не удалось получить зарегистрированные устройства`), {
                    variant: "error",
                });
            }

            return Object.keys(connectedDeviceIds);
        }

        return [];
    }

    openLocation = async (locationNode) => {
        const { devicesLocationsTree, devices } = this.state;
        const { generalInfo, strings } = this.props;

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        let adapters = getAdapters(generalInfo);

        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        const deviceTypeIds = [];
        this.props.serviceFeature?.compatibleDeviceFeatures?.forEach((f) => {
            const ids = getDeviceTypesByFeature(adapters, f);
            if (ids && ids.length > 0) {
                deviceTypeIds.push(...ids);
            }
        });

        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.setState({ devicesLocationsTree, devices });
            return;
        }

        const [getDevicesStatus, devicesFromLocation] = await GetDevices(locationNode.id, deviceTypeIds);

        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let newDevices = [
            ...devices,
            ...devicesFromLocation.filter((c) => devices.map((c) => c.id).includes(c.id) !== true),
        ];

        FillNode(locationNode, children, devicesFromLocation);

        this.setState({ devicesLocationsTree, devices: newDevices });
    };

    async setSearch(search) {
        const { strings } = this.props;

        if (search && search !== "") {
            const { generalInfo } = this.props;
            let adapters = getAdapters(generalInfo);

            if (!adapters) {
                this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
                return;
            }

            const deviceTypeIds = [];
            this.props.serviceFeature?.compatibleDeviceFeatures?.forEach((f) => {
                const ids = getDeviceTypesByFeature(adapters, f);
                if (ids && ids.length > 0) {
                    deviceTypeIds.push(...ids);
                }
            });

            if (!deviceTypeIds || deviceTypeIds.length === 0) {
                this.setState({ search, filteredDevices: [] });
                return;
            }

            let [devicesStatus, devices] = await SearchDevices({
                like: search,
                limit: 5,
                deviceTypes: deviceTypeIds,
            });

            if (!devicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            devices = devices.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });

            this.setState({ search, filteredDevices: devices });
        } else {
            this.setState({ search, filteredDevices: [] });
        }
    }

    selectDevice(deviceId) {
        const selectedDeviceIds = this.state.selectedDeviceIds;

        if (!selectedDeviceIds.includes(deviceId)) {
            this.setState({ selectedDeviceIds: [...selectedDeviceIds, deviceId] });
        } else {
            const newSelected = selectedDeviceIds.filter((id) => id !== deviceId);
            this.setState({ selectedDeviceIds: newSelected });
        }
    }

    connectDevices() {
        const { selectedDeviceIds, connectedDeviceIds } = this.state;
        const { strings } = this.props;

        const deviceIdsForDisconnect = connectedDeviceIds.filter((d) => !selectedDeviceIds.includes(d));

        if (deviceIdsForDisconnect.length < 1 && selectedDeviceIds.length < 1) {
            return;
        }

        const connectCommand = this.props.serviceFeature.registration[CONNECT_DEVICE_COMMAND_NAME];
        const disconnectCommand = this.props.serviceFeature.registration[DISCONNECT_DEVICE_COMMAND_NAME];

        const featureCode = this.props.serviceFeature.featureCode;
        const serviceCode = this.props.selectedAdapter.serviceCode;

        if (connectCommand && connectCommand.path && connectCommand.method) {
            this.setState({ isLoading: true });

            const requestQueue = selectedDeviceIds?.map((id) =>
                limit(async () => {
                    const parameters = { deviceId: id };
                    let [result, statusCode] = await ExecuteCommand(
                        connectCommand.method,
                        serviceCode,
                        featureCode,
                        connectCommand.path,
                        parameters
                    );

                    if (!result) {
                        switch (statusCode) {
                            case 402:
                                this.props.enqueueSnackbar(
                                    `${strings(
                                        "Достигнут лимит лицензии на регистрацию новых устройств в адаптере"
                                    )} '${this.props.selectedAdapter.name}'`,
                                    {
                                        variant: "warning",
                                    }
                                );
                                break;

                            default:
                                this.props.enqueueSnackbar(
                                    `${strings("Не удалось зарегистрировать устройство")} '${this.getDevice(id).name}'`,
                                    {
                                        variant: "error",
                                    }
                                );
                                break;
                        }
                    }
                })
            );

            deviceIdsForDisconnect.forEach((id) => {
                requestQueue.push(
                    limit(async () => {
                        const parameters = { deviceId: id };
                        let [result] = await ExecuteCommand(
                            disconnectCommand.method,
                            serviceCode,
                            featureCode,
                            disconnectCommand.path,
                            parameters
                        );

                        if (!result) {
                            this.props.enqueueSnackbar(
                                `${strings("Не удалось снять с регистрации устройство")} '${this.getDevice(id).name}'`,
                                {
                                    variant: "error",
                                }
                            );
                        }
                    })
                );
            });

            void Promise.all(requestQueue).then(() => this.props.closeConnectModal());
        }
    }

    getDevice = (id) => this.state.devices.find((device) => device.id === id);

    render() {
        const { search, filteredDevices, devicesLocationsTree, selectedDeviceIds, isLoading } = this.state;
        const { strings } = this.props;

        return (
            <>
                {isLoading ? (
                    <LoaderAnimation message={strings("Подключение устройств. Подождите...")} />
                ) : (
                    <Modal size="sm">
                        <ModalHeader
                            title={strings("Подключение оборудования")}
                            closeHandler={() => {
                                this.props.closeConnectModal();
                            }}
                        />

                        <ModalBody>
                            <ModalBodyItem>
                                <ModalFormItem>
                                    <Row justify="right" align="bottom">
                                        <SelectedCounter
                                            count={selectedDeviceIds?.length ?? 0}
                                            onClear={() => this.setState({ selectedDeviceIds: [] })}
                                            strings={strings}
                                        />
                                    </Row>
                                </ModalFormItem>
                                <ModalFormItem>
                                    <Row marginBottom={12}>
                                        <TextField
                                            autoComplete={false}
                                            colorVariant="light"
                                            fullWidth
                                            icon="search"
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            placeholder={strings("Найти")}
                                            type="search"
                                            value={search}
                                        />
                                    </Row>
                                    {search && search !== "" ? (
                                        filteredDevices?.map((device) => (
                                            <Row marginBottom={12} key={device.id}>
                                                <Checkbox
                                                    label={device.name}
                                                    checked={selectedDeviceIds?.includes(device.id)}
                                                    onChange={() => {
                                                        this.selectDevice(device.id);
                                                    }}
                                                />
                                            </Row>
                                        ))
                                    ) : (
                                        <FilterAccordion
                                            items={devicesLocationsTree}
                                            selected={selectedDeviceIds}
                                            openItem={this.openLocation}
                                            blockParentCheckbox={true}
                                            selectHandler={(_, item) => {
                                                this.selectDevice(item.id);
                                            }}
                                        />
                                    )}
                                </ModalFormItem>
                            </ModalBodyItem>
                        </ModalBody>

                        <ModalFooter>
                            <ModalFooterItem>
                                <Button
                                    variant="primary"
                                    label={strings("Подключить")}
                                    onClick={() => {
                                        this.connectDevices();
                                    }}
                                    type="submit"
                                />
                            </ModalFooterItem>

                            <ModalFooterItem>
                                <Button
                                    variant="ghost"
                                    label={strings("Отмена")}
                                    onClick={() => {
                                        this.props.closeConnectModal();
                                    }}
                                    type="button"
                                />
                            </ModalFooterItem>
                        </ModalFooter>
                    </Modal>
                )}
            </>
        );
    }
}

const ModalFormItem = styled.div`
    & + & {
        margin-top: 16px;
    }
`;

export default withCultureContext(withSnackbar(ConnectDeviceModal));

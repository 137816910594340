const pathUsers = "/users-api/users";
const pathUsersGroups = "/users-api/userGroups";

export const GetTableData = (users, groups) => {
    if (!groups || !groups.length) {
        return [];
    }

    return (
        groups?.map((group) => ({
            ...group,
            users: users.filter((u) => u.groupId === group.id),
        })) ?? []
    );
};

export const GetUserAccordion = (users, groups) => {
    if (!groups || !groups.length) {
        return [];
    }

    return (
        groups?.map((group) => ({
            ...group,
            tag: "userGroup",
            children: users
                .filter((u) => u.groupId === group.id)
                .map((u) => {
                    return { ...u, name: u.login, tag: "user" };
                }),
        })) ?? []
    );
};

export const FillUserGroups = (userGroups, users) => {
    return userGroups.map((g) => {
        let node = { ...g, tag: "userGroup", isClosed: true };
        node.children = users
            ?.filter((u) => u.groupId === g.id)
            .sort(CompareUsers)
            .map((u) => ({ ...u, tag: "user" }));
        return node;
    });
};

const CompareUsers = (a, b) =>
    (a.login ?? "").localeCompare(b.login ?? "", undefined, { numeric: true, sensitivity: "base" });

const CompareUserGroups = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const GetUsers = async () => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathUsers}/list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, ((await response.json()) ?? []).sort(CompareUsers)];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetUserById = async (id) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathUsers}/${id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        const responseBody = await response.json();

        return [true, responseBody];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const CreateUser = async (user) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(pathUsers, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    });

    return [response.status === 200, response.status];
};

export const UpdateUser = async (user) => {
    let id = user.id;
    let data = { ...user };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathUsers}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.status === 200, response.status];
};

export const DeleteUser = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathUsers}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    let result = null;
    try {
        result = await response.json();
    } catch {
        // ignore
    }

    return [response.status === 200, response.status, result?.code];
};

export const GetUsersGroups = async () => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${pathUsersGroups}/list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        return [true, ((await response.json()) ?? []).sort(CompareUserGroups)];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const CreateUserGroup = async (userGroup) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(pathUsersGroups, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(userGroup),
    });

    return [response.status === 200, response.status];
};

export const DeleteUsersGroup = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathUsersGroups}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response.status === 200, response.status];
};

export const UpdateUsersGroup = async (group) => {
    let id = group.id;
    let data = { ...group };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${pathUsersGroups}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.status === 200, response.status];
};

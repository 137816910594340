import React from "react";
import { nanoid } from "nanoid";
import { GeneralContextConsumer, getDeviceTypes } from "../../../../contexts";
import {
    Table,
    TableHead,
    TableRow,
    TableHeadCell,
    TableBody,
    Text,
    TableAccordionItem,
    TableCell,
    DeviceIcon,
    Button,
    TableHoverContainer,
    Space,
} from "headpoint-react-components";
import CameraPreview from "./CameraPreview";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import { WideEventsLayoutTableWrapper } from "../../../../layout/EventsLayout";

export class DeviceTable extends React.Component {
    RowActions = (device) => (
        <TableHoverContainer>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleSetSelectedDevice(device);
                }}
                icon="info"
                title="Location"
                variant="ghost"
                noPadding
                width="32px"
            />
        </TableHoverContainer>
    );

    RowActionsLocation = (location) => (
        <TableHoverContainer>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.showLocationHandler(location);
                }}
                icon="info"
                title="Location"
                variant="ghost"
                noPadding
                width="32px"
            />
        </TableHoverContainer>
    );

    locationNode = (location, generalInfo, showCameraPreview) =>
        !!this.props.openLocation ? (
            <TableAccordionItem
                key={location.id}
                children={null}
                defaultClosed={location.isClosed}
                onClick={() => {
                    location.isClosed = !location.isClosed;
                    this.props.openLocation(location);
                }}
                toggle={
                    <>
                        <TableCell>
                            <Text color="primary" variant="body-sm">
                                {location.name}
                            </Text>
                        </TableCell>
                        {!!showCameraPreview && <TableCell />}
                        <TableCell />
                        <TableCell>{this.RowActionsLocation(location)}</TableCell>
                    </>
                }
            >
                {this.createRow(location.children, generalInfo, showCameraPreview)}
            </TableAccordionItem>
        ) : (
            <TableRow key={location.id}>
                <TableCell>
                    <Text variant="body-sm">
                        <DeviceIcon icon={"building"} size={16} />
                    </Text>
                    <Space isDouble />
                    <Text color="primary" variant="body-sm">
                        {location.name}
                    </Text>
                </TableCell>
                {!!showCameraPreview && <TableCell />}
                <TableCell />
                <TableCell>{this.RowActionsLocation(location)}</TableCell>
            </TableRow>
        );

    deviceNode = (device, generalInfo, showCameraPreview) => {
        const { selectedDevice, previewCameraId, selectCameraId } = this.props;
        const type = getDeviceTypes(generalInfo)?.find((t) => device.typeId === t.value);
        const icon = type?.icon?.type ?? "shield";
        const isSelected = selectedDevice && device.id === selectedDevice.id;

        return (
            <TableRow key={device.id} isSelected={isSelected}>
                <TableCell>
                    <Text color={device.statusCode} variant="body-sm">
                        <DeviceIcon icon={icon} size={16} />
                    </Text>
                    <Space isDouble />
                    <Text color="primary" variant="body-sm">
                        {device.name}
                    </Text>
                </TableCell>
                {!!showCameraPreview && (
                    <TableCell>
                        {icon === "camera" && (
                            <CameraPreview
                                camera={device}
                                selectCameraId={selectCameraId}
                                previewCameraId={previewCameraId}
                                generalInfo={generalInfo}
                            />
                        )}
                    </TableCell>
                )}
                <TableCell />
                <TableCell verticalAlign="top">{this.RowActions(device, generalInfo)}</TableCell>
            </TableRow>
        );
    };

    createRow = (tree, generalInfo, showCameraPreview) => {
        if (!tree) {
            return null;
        }

        return tree.map((obj) =>
            obj.tag === "location"
                ? this.locationNode(obj, generalInfo, showCameraPreview)
                : this.deviceNode(obj, generalInfo, showCameraPreview)
        );
    };

    handleSetSelectedDevice = (device) => {
        if (device) {
            this.props.setSelectedDevice(device);
        }
    };

    render() {
        const { devicesLocationsTree, showCameraPreview } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <WideEventsLayoutTableWrapper>
                        <GeneralContextConsumer>
                            {(generalInfo) => (
                                <Table key={nanoid()} hasHover stickyHeader isFixed bgColor="dark">
                                    <colgroup>
                                        <col />
                                        <col />
                                        {!!showCameraPreview && <col />}
                                        <col style={{ width: "80px" }} />
                                    </colgroup>
                                    <TableHead style={{ zIndex: 50 }}>
                                        <TableRow>
                                            <TableHeadCell>{strings("Локации и устройства")}</TableHeadCell>
                                            {!!showCameraPreview && <TableHeadCell>{strings("Превью")}</TableHeadCell>}
                                            <TableHeadCell />
                                            <TableHeadCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.createRow(devicesLocationsTree, generalInfo, showCameraPreview)}
                                    </TableBody>
                                </Table>
                            )}
                        </GeneralContextConsumer>
                    </WideEventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import moment from "moment";
import fileDownload from "react-file-download";
import { closePeer, startPeer } from "../../../services/webrtc";
import ProgressWrapper from "../../components/ProgressWrapper";
import "moment-duration-format";
import { Mutex } from "async-mutex";
import {
    VideoControlBtn,
    Button,
    VideoControlsDivider,
    VideoControlsMenu,
    VideoControlsMenuItem,
    VideoControlsMenuTitle,
    VideoControlsTimecode,
    VideoWrapper,
} from "headpoint-react-components";
import { GetArchiveUrl } from "../../../services/archive";
import { withSnackbar } from "notistack";
import {
    checkFeatureByDeviceType,
    getAdapters,
    getDeviceTypes,
    getEventTypes,
    getServiceConfig,
    getSystemVariableValue,
    permissionExists,
    serviceExists,
} from "../../../contexts";
import { WebGLPlayer } from "../../../services/WebAssembly/webgl";
import { WsClient } from "../../../services/WebAssembly/ws_client";
import Module from "../../../services/WebAssembly/decoder.mjs";
import { GetEvents } from "../../../services/events";
import { nanoid } from "nanoid";
import { isCanvasBlank } from "../../../utilites/CanvasUtills";
import { WebCodecsClient } from "../../../services/WebCodecs/ws_client";
import {
    BeginOfArchiveRecord,
    endedOrMissingArchiveRecord,
} from "../../../services/ErrorMessages/archiveErrorMessages";
import { connect } from "react-redux";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { throttle } from "throttle-debounce";
import { CheckStatus, SaveScreenshot } from "../../../services/screenshot";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import PubSub from "pubsub-js";
import { SHOW_NEW_EVENT } from "../../../core/coreTopics";
import { withRouter } from "react-router-dom";
import { DownloadFile } from "../../../services/storage";

const ARCHIVE_SCALE_FEATURE_CODE = "video.scale";
const SCALE_OPTIONS = [0.5, 1, 1.5, 2, 4, 8, 16, 32];
const DIRECTION_SCALE_OPTIONS = [
    { label: "Вперед", value: 1 },
    { label: "Назад", value: -1 },
];

const DEFAULT_MODE = 1;
const FRAME_MODE = -1;
const PLAYER_MODE = [
    { label: "Покадровый", value: FRAME_MODE },
    { label: "Обычный", value: DEFAULT_MODE },
];
const OUTPUT_IMAGE = "output.png";
const SOURCE_IMAGE = "screenshot.h264";

const ffmpeg = createFFmpeg({ log: false, corePath: "/ffmpeg/ffmpeg-core.js" });

class ArchivePlayer extends React.Component {
    constructor(props) {
        super(props);
        this.videoTag = `videoCtrl${props.frameIndex}`;
        this.lock = new Mutex();
        this.clientProtocol = props.clientProtocol ?? "webrtc";

        const currentFrame = { ...props.currentFrame };
        const offset =
            currentFrame.ranges.offset && currentFrame.ranges.from
                ? currentFrame.ranges.offset - currentFrame.ranges.from
                : 0;

        this.throttleHandleNextScreenshotFrame = throttle(
            700,
            (...args) => void this.handleNextScreenshotFrame(...args),
            {
                noLeading: false,
                noTrailing: false,
            }
        );

        this.throttleHandlePreviousScreenshotFrame = throttle(
            700,
            (...args) => void this.handlePreviousScreenshotFrame(...args),
            {
                noLeading: false,
                noTrailing: false,
            }
        );

        this.state = {
            currentFrame: currentFrame,
            offsetTime: offset,
            scale: 1,
            scaleDirection: 1,
            captureTime: 0,
            manualOffset: undefined,
            wasmModule: null,
            marks: [],
            closeArchiveStreamTimer: null,
            playerMode: DEFAULT_MODE,
        };
    }

    toEvents(event, device) {
        this.props.history.push({
            pathname: "/events",
            state: { type: "ShowSpecificEventCard", event, device },
        });
    }

    async componentDidMount() {
        const { currentFrame } = this.state;

        if (this.clientProtocol === "websocket") {
            this.setState(
                { wasmModule: await Module() },
                async () => await this.start(currentFrame, this.props.generalInfo)
            );
        } else {
            await this.start(currentFrame, this.props.generalInfo);
        }

        let filter = {
            deviceIds: [currentFrame.id],
            from: moment.unix(currentFrame.ranges.from),
            to: moment.unix(currentFrame.ranges.to),
        };

        GetEvents(filter).then(([getEventsStatus, newEvents]) => {
            const { strings } = this.props;

            if (!getEventsStatus) {
                this.props.enqueueSnackbar(strings("Не удалось получить список событий"), { variant: "error" });
                return;
            }

            const eventMarks = newEvents.map((ev) => {
                const seekToEventDelta = getSystemVariableValue(
                    this.props.generalInfo,
                    "service.api.events",
                    "events.archive.delta",
                    5
                );
                const eventType = getEventTypes(this.props.generalInfo).find((info) => info.value === ev.type);
                const duration = currentFrame.ranges.to - currentFrame.ranges.from;
                const offset = moment(ev.eventDate).unix() - currentFrame.ranges.from;
                return {
                    id: nanoid(),
                    position: (100.0 * offset) / duration,
                    data: [
                        {
                            label: strings("Камера"),
                            value: ev.deviceName,
                        },
                        {
                            label: strings("Тип события"),
                            value: strings(eventType?.label),
                        },
                        {
                            label: strings("Дата"),
                            value: `${moment(ev.eventDate).format("DD.MM.YYYY HH:mm:ss")}`,
                        },
                        {
                            label: "",
                            value: (
                                <Button
                                    key={ev.id}
                                    onClick={() => this.toEvents(ev, currentFrame)}
                                    label={strings("Перейти в журнал")}
                                />
                            ),
                        },
                    ],
                    onClick: async () => {
                        const eventTimeCode = moment(ev.eventDate).unix() - currentFrame.ranges.from;
                        await this.seek(eventTimeCode - seekToEventDelta, true);
                    },
                };
            });

            this.setState({
                marks: eventMarks,
            });
        });

        if (!ffmpeg.isLoaded()) {
            await ffmpeg.load();
        }
    }

    async componentWillUnmount() {
        await this.stop(this.peer);
    }

    async setScale(scale) {
        const { currentFrame } = this.state;

        this.setState({ scale: scale });

        await this.stop(this.peer);
        await this.start(currentFrame, this.props.generalInfo);
    }

    async setScaleDirection(direction) {
        const { currentFrame } = this.state;

        this.setState({ scaleDirection: direction });

        await this.stop(this.peer);
        await this.start(currentFrame, this.props.generalInfo);
    }

    getManualCaptureTime = () => {
        const { currentFrame, manualOffset } = this.state;
        return manualOffset ? manualOffset + currentFrame.ranges.from : undefined;
    };

    async setPlayerMode(mode) {
        const { strings } = this.props;

        this.setState({ playerMode: mode });

        if (mode === FRAME_MODE) {
            await this.stop(this.peer);
            const img = document.getElementById(this.videoTag);
            img.style.height = "auto";
            img.src = "/img/archive-placeholder.png";

            this.frameTime = this.getManualCaptureTime() ?? this.state.captureTime;

            if (this.frameTime) {
                img.src = await this.convertImage(this.frameTime);
                img.style.height = "inherit";
            } else {
                this.props.enqueueSnackbar(strings("Текущее время архива не определено"), { variant: "error" });
                this.setState({ playerMode: DEFAULT_MODE });
            }
        }
    }

    async convertImage(screenshotTime) {
        const cameraId = this.state.currentFrame.id;
        const token = window.localStorage.getItem("token");

        const image = await fetchFile(
            `/balancer/recorder-${cameraId}/cameras/${cameraId}/screenshot?from=${screenshotTime}&token=${token}`
        );

        try {
            // удаляем предыдущий скриншот, чтобы не засорять память
            this.removeFromFfmpegMemory(OUTPUT_IMAGE);
        } catch (e) {
            // пропускаем ошибку удаления несуществуюшего файла, так как при первой конвертации нельзя проверить есть ли файл в памяти
        }

        this.writeToFfmpegMemory(image);
        await this.convertToPng();
        const data = this.readFromFfmpegMemory();
        const url = URL.createObjectURL(new Blob([data.buffer], { type: "image/png" }));
        this.removeFromFfmpegMemory(SOURCE_IMAGE);
        return url;
    }

    writeToFfmpegMemory(image) {
        ffmpeg.FS("writeFile", SOURCE_IMAGE, image);
    }

    async convertToPng() {
        await ffmpeg.run("-i", SOURCE_IMAGE, "-frames:", "1", OUTPUT_IMAGE);
    }

    readFromFfmpegMemory() {
        return ffmpeg.FS("readFile", OUTPUT_IMAGE);
    }

    removeFromFfmpegMemory(fileName) {
        ffmpeg.FS("unlink", fileName);
    }

    tryExitFullscreen() {
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else {
                alert("Exit fullscreen doesn't work");
            }
        }
    }

    async getSnapshot() {
        const { currentFrame } = this.state;
        const { enqueueSnackbar, strings } = this.props;

        const isFrameMode = this.state.playerMode === FRAME_MODE;

        const snapshotName = this.getSnapshotName();

        if (isFrameMode) {
            let snapshot = ffmpeg.FS("readFile", "output.png");
            fileDownload(new Blob([snapshot.buffer], { type: "image/png" }), snapshotName);
        } else {
            const deviceData = {
                deviceId: currentFrame.id,
                time: this.getManualCaptureTime() ?? this.state.captureTime,
            };
            const body = { devices: [deviceData] };

            const [success, orderIds] = await SaveScreenshot(body);
            if (!success) {
                enqueueSnackbar(strings("Не удалось сохранить скриншот"), { variant: "error" });
                return;
            }

            const orderId = orderIds[0];

            let interval = setInterval(async function () {
                const [successCheckStatus, order] = await CheckStatus(orderId);
                if (!successCheckStatus) {
                    enqueueSnackbar(strings("Не удалось получить статус скриншота"), { variant: "error" });
                    clearInterval(interval);
                    return;
                }
                switch (order.status) {
                    case "InProgress":
                        break;
                    case "Error":
                        enqueueSnackbar(strings("Ошибка при сохранении скриншота"), { variant: "error" });
                        clearInterval(interval);
                        break;
                    case "Succeed":
                        await DownloadFile(order.properties.screenshotFileId, snapshotName);
                        clearInterval(interval);
                        break;
                    default:
                        break;
                }
            }, 1000);
        }
    }

    getSnapshotName(manualCaptureTime) {
        const cameraName = this.state.currentFrame.name;

        return manualCaptureTime || this.state.captureTime
            ? `${cameraName}-${moment
                  .unix(manualCaptureTime ?? this.state.captureTime)
                  .format("YYYY-MM-DD_HH:mm:ss")}.png`
            : ``;
    }

    createEvent() {
        const { strings } = this.props;
        let canvas = this.getSnapshotCanvas();
        const isFrameMode = this.state.playerMode === FRAME_MODE;

        if (isCanvasBlank(canvas) && !isFrameMode) {
            this.props.enqueueSnackbar(strings("Дождитесь начала трансляции для снятия скриншота"), {
                variant: "warning",
            });
            return;
        }

        this.tryExitFullscreen();

        if (isFrameMode) {
            let snapshot = ffmpeg.FS("readFile", "output.png");
            let blob = new Blob([snapshot.buffer]);
            this.showNewEventDialog(blob);
        } else {
            canvas.toBlob((blob) => {
                this.showNewEventDialog(blob);
            });
        }
    }

    getScreenshotTime = () => {
        const { currentFrame, captureTime, playerMode } = this.state;

        const manualCaptureTime = this.getManualCaptureTime();

        let currentCaptureTime = manualCaptureTime || captureTime || currentFrame.ranges.from || currentFrame.ranges.to;

        if (playerMode === FRAME_MODE && this.frameTime) {
            currentCaptureTime = this.frameTime;
        }

        if (currentCaptureTime) {
            return moment.unix(currentCaptureTime).toDate();
        } else {
            return new Date();
        }
    };

    showNewEventDialog(blob) {
        const { currentFrame } = this.state;

        const cameraName = this.state.currentFrame.name;
        const screenshotTime = this.getScreenshotTime();

        let fileName = `${cameraName}-${moment(screenshotTime).format("YYYY-MM-DD_HH:mm:ss")}.png`;
        let file = new File([blob], fileName, { type: "image/png" });

        PubSub.publish(SHOW_NEW_EVENT, {
            locationId: currentFrame.locationId,
            deviceId: currentFrame.id,
            screenshotTime: screenshotTime,
            file,
        });
    }

    getSnapshotCanvas() {
        if (this.clientProtocol === "websocket") {
            return document.getElementById(this.videoTag);
        }

        const video = document.getElementById(this.videoTag);

        let canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas;
    }

    handleWebsocketTimestamp = (captureTime) => {
        const { strings } = this.props;

        const { currentFrame, scaleDirection } = this.state;
        const offset = captureTime - currentFrame.ranges.from;
        this.setState({
            offsetTime: offset,
            captureTime: captureTime,
        });
        if (captureTime >= currentFrame.ranges.to) {
            this.props.enqueueSnackbar(endedOrMissingArchiveRecord(currentFrame, strings), { variant: "success" });

            void this.playPause();
        } else if (captureTime <= currentFrame.ranges.from && scaleDirection === -1) {
            this.props.enqueueSnackbar(strings(BeginOfArchiveRecord()), {
                variant: "success",
            });
            void this.playPause();
        }
    };

    async start(currentFrame, generalInfo) {
        const { scale, scaleDirection, offsetTime } = this.state;
        const { enqueueSnackbar, strings } = this.props;

        if (offsetTime <= 0 && scaleDirection === -1) {
            this.props.enqueueSnackbar(strings(BeginOfArchiveRecord()), {
                variant: "success",
            });

            return;
        }

        const { rtcPeerConfig } = getServiceConfig(generalInfo, "service.api.archive");
        if (!rtcPeerConfig) {
            enqueueSnackbar(strings("Отсутствует конфигурация RTCPeerConnection"), { variant: "error" });
            return;
        }

        const release = await this.lock.acquire();
        try {
            let { offsetTime } = this.state;
            const { id } = currentFrame;

            if (this.peer) {
                await this.stop(this.peer);
            }

            const startTime = Math.round(currentFrame.ranges.from + offsetTime);
            const endTime = Math.round(currentFrame.ranges.to);

            const serviceCode = getDeviceTypes(generalInfo)?.find(
                (dt) => dt?.value === currentFrame.typeId
            )?.serviceCode;
            if (!serviceCode) {
                enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), { variant: "error" });
                return;
            }

            const scaleVideoFeatureExists = this.scaleVideoFeatureExists();
            const scaleToApply = scaleVideoFeatureExists ? scale * scaleDirection : undefined;

            let [status, urlFull] = await GetArchiveUrl(serviceCode, id, startTime, endTime, scaleToApply);
            if (!status) {
                enqueueSnackbar(strings("Ошибка получения URL архива"), { variant: "error" });
                return;
            }

            switch (this.clientProtocol) {
                case "webcodecs":
                    this.peer = new WebCodecsClient({
                        videoTag: this.videoTag,
                        handleWebsocketTimestamp: this.handleWebsocketTimestamp,
                    });

                    if (this.peer.isOpen()) {
                        this.peer.close();
                    }

                    this.peer.open({
                        url: `${WebCodecsClient.getUrl(id)}`,
                        rtsp: urlFull.url,
                        externalId: id,
                    });

                    break;

                case "websocket":
                    if (!this.state.wasmModule) {
                        enqueueSnackbar(strings("Отсутствует модуль WebAssembly"), { variant: "error" });
                        return;
                    }

                    this.peer = new WsClient({
                        handleWebsocketTimestamp: this.handleWebsocketTimestamp,
                        Module: this.state.wasmModule,
                    });

                    if (this.peer.isOpen()) {
                        this.peer.close();
                    }

                    const canvas = document.getElementById(this.videoTag);
                    let player = new WebGLPlayer(canvas);

                    this.peer.open({
                        url: `${WsClient.getUrl(id)}`,
                        player: player,
                        rtsp: urlFull.url,
                    });
                    break;

                default:
                    if (!document.getElementById(this.videoTag)) {
                        return;
                    }

                    const [startPeerStatus, pc, responseCode] = await startPeer(
                        urlFull.url,
                        id,
                        document.getElementById(this.videoTag),
                        JSON.parse(rtcPeerConfig)
                    );
                    if (!startPeerStatus) {
                        if (responseCode === "unauthorized") {
                            enqueueSnackbar(`${strings("Нет доступа к устройству")} '${currentFrame.name}'`, {
                                variant: "error",
                            });
                        } else {
                            enqueueSnackbar(strings("Ошибка подключения"), { variant: "error" });
                        }
                        return;
                    }

                    pc.ondatachannel = (event) => {
                        event.channel.onmessage = (ev) => {
                            const message = JSON.parse(ev.data);
                            if (message.error) {
                                switch (message.error) {
                                    case "unsupportedCodec":
                                        this.props.enqueueSnackbar(strings("Неподдерживаемый кодек трансляции"), {
                                            variant: "error",
                                        });
                                        break;
                                    case "cameraConnectionTimeout":
                                        this.props.enqueueSnackbar(strings("Таймаут подключения к трансляции"), {
                                            variant: "error",
                                        });
                                        break;
                                    case "cameraConnectionFailed":
                                    case "unknown":
                                        this.props.enqueueSnackbar(strings("Ошибка подключения к трансляции"), {
                                            variant: "error",
                                        });
                                        break;
                                    default:
                                        this.props.enqueueSnackbar(strings("Неизвестная ошибка подключения"), {
                                            variant: "error",
                                        });
                                        break;
                                }
                                console.error(message.message);
                                void this.stop();
                            }

                            const offset = message.captureTime - currentFrame.ranges.from;
                            this.setState({
                                offsetTime: offset,
                                captureTime: message.captureTime,
                            });
                            if (
                                message.captureTime >= currentFrame.ranges.to ||
                                message.captureTime < currentFrame.ranges.from
                            ) {
                                const video = document.getElementById(this.videoTag);
                                video.pause();

                                this.props.enqueueSnackbar(endedOrMissingArchiveRecord(currentFrame, strings), {
                                    variant: "success",
                                });
                                void this.playPause();
                            } else if (message.captureTime <= currentFrame.ranges.from && scaleDirection === -1) {
                                const video = document.getElementById(this.videoTag);
                                video.pause();

                                this.props.enqueueSnackbar(strings(BeginOfArchiveRecord()), {
                                    variant: "success",
                                });
                                void this.playPause();
                            }
                        };
                    };

                    console.log(!!this.peer);
                    this.peer = pc;
                    break;
            }
        } finally {
            release();
        }
    }

    async stop(peerToClose) {
        if (this.clientProtocol === "webrtc") {
            await closePeer(peerToClose);
        } else {
            if (peerToClose) {
                await peerToClose.close();
            }
        }
        this.peer = null;
    }

    playPause = async () => {
        const { currentFrame } = this.state;
        const video = document.getElementById(this.videoTag);

        if (this.peer) {
            if (this.clientProtocol === "webrtc") {
                video.pause();
            }

            await this.stop(this.peer);
        } else {
            if (this.clientProtocol === "webrtc") {
                video.play();
            }

            await this.start(currentFrame, this.props.generalInfo);
        }

        this.forceUpdate(); // Need to rerender pause/play button
    };

    seek = async (nextOffsetTime, isFinal) => {
        const { currentFrame, offsetTime, captureTime } = this.state;
        if (!nextOffsetTime || offsetTime === nextOffsetTime) {
            return;
        }
        this.setState({
            offsetTime: nextOffsetTime,
        });

        if (isFinal) {
            if (this.state.playerMode === FRAME_MODE) {
                this.frameTime = this.getManualCaptureTime() ?? captureTime;

                const img = document.getElementById(this.videoTag);
                img.src = await this.convertImage(this.frameTime);
            } else {
                await this.start(currentFrame, this.props.generalInfo);
            }
        }

        console.log(JSON.stringify({ nextOffsetTime, isFinal }));
    };

    rewindSeconds = async (s) => {
        let { currentFrame, offsetTime } = this.state;
        if (!offsetTime || offsetTime < 0) {
            offsetTime = 0;
        }

        offsetTime += s;
        this.setState({ offsetTime });
        await this.start(currentFrame, this.props.generalInfo);
    };

    scaleVideoFeatureExists() {
        const { generalInfo } = this.props;
        const { currentFrame } = this.state;

        const adapters = getAdapters(generalInfo) ?? [];
        return checkFeatureByDeviceType(currentFrame?.typeId, adapters, ARCHIVE_SCALE_FEATURE_CODE);
    }

    handlePreviousScreenshotFrame = async () => {
        let time = this.frameTime ?? this.state.captureTime;
        time = time - 1;
        this.frameTime = time;
        this.setState({ captureTime: time });
        const img = document.getElementById(this.videoTag);
        img.src = await this.convertImage(time);
    };

    handleNextScreenshotFrame = async () => {
        let time = this.frameTime ?? this.state.captureTime;
        time = time + 1;
        this.frameTime = time;
        this.setState({ captureTime: time });
        const img = document.getElementById(this.videoTag);
        img.src = await this.convertImage(time);
    };

    render() {
        const { currentFrame, offsetTime, captureTime, marks, manualOffset, scale, scaleDirection, playerMode } =
            this.state;
        const { strings } = this.props;

        const scaleVideoFeatureExists = this.scaleVideoFeatureExists();

        const manualCaptureTime = this.getManualCaptureTime();
        const startTime = currentFrame.ranges.from;
        const endTime = currentFrame.ranges.to;

        const detailInterval = getSystemVariableValue(
            this.props.generalInfo,
            "service.api.archive",
            "archive.interval.timeline",
            15
        );

        const { userInfo } = this.props;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <VideoWrapper
                videoElement={
                    playerMode === FRAME_MODE ? (
                        <img
                            id={this.videoTag}
                            alt="camera screenshot"
                            style={{
                                position: "absolute",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                width: "auto",
                                height: "auto",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    ) : this.clientProtocol === "websocket" ? (
                        <canvas
                            id={this.videoTag}
                            style={{
                                position: "absolute",
                                maxWidth: "100%",
                                maxHeight: "100%",
                                width: "auto",
                                height: "auto",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    ) : (
                        <video
                            id={this.videoTag}
                            autoPlay
                            style={{ position: "absolute", width: "100%", height: "100%" }}
                        />
                    )
                }
                progress={
                    <ProgressWrapper
                        rangeStart={startTime}
                        rangeEnd={endTime}
                        position={manualOffset ?? offsetTime}
                        seek={this.seek}
                        marks={marks}
                        setOffset={(offset) => this.setState({ manualOffset: offset })}
                        detailInterval={detailInterval}
                    />
                }
                mainControls={
                    <React.Fragment>
                        {playerMode === FRAME_MODE ? (
                            <>
                                <VideoControlBtn
                                    icon="arrow-left"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.throttleHandlePreviousScreenshotFrame();
                                    }}
                                    title="Prev"
                                />
                                <VideoControlBtn
                                    icon="arrow-right"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.throttleHandleNextScreenshotFrame();
                                    }}
                                    title="Next"
                                />
                            </>
                        ) : this.peer ? (
                            <VideoControlBtn
                                title={strings("Пауза")}
                                icon="pause"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.playPause();
                                }}
                            />
                        ) : (
                            <VideoControlBtn
                                title={strings("Начать")}
                                icon="play"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.playPause();
                                }}
                            />
                        )}
                        {playerMode === DEFAULT_MODE ? (
                            <>
                                <VideoControlBtn
                                    title={strings("Назад")}
                                    icon="s-rewind"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.rewindSeconds(-15);
                                    }}
                                />
                                <VideoControlBtn
                                    title={strings("Вперед")}
                                    icon="s-forward"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.rewindSeconds(15);
                                    }}
                                />
                            </>
                        ) : null}
                        <VideoControlsDivider />
                        <VideoControlBtn
                            title={strings("Снимок экрана")}
                            icon="screenshot"
                            onClick={(e) => {
                                e.preventDefault();
                                this.getSnapshot();
                            }}
                        />
                        {serviceExists(this.props.generalInfo, "service.adapters.userEvents") &&
                        permissionExists(userInfo, "events.user.post") ? (
                            <VideoControlBtn
                                title={strings("Создать событие")}
                                icon="addevent"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.createEvent();
                                }}
                            />
                        ) : (
                            <div />
                        )}
                        <VideoControlsTimecode
                            text={
                                manualCaptureTime || captureTime
                                    ? `${moment
                                          .unix(
                                              playerMode === FRAME_MODE
                                                  ? this.frameTime ?? manualCaptureTime ?? captureTime
                                                  : manualCaptureTime ?? captureTime
                                          )
                                          .format("DD.MM.YYYY HH:mm:ss")}`
                                    : ``
                            }
                        />
                    </React.Fragment>
                }
                toolbox={
                    <>
                        {scaleVideoFeatureExists && (
                            <VideoControlsMenu align="right" icon="settings" toggleTitle={strings("Настройки")}>
                                <>
                                    {playerMode !== FRAME_MODE && (
                                        <>
                                            <VideoControlsMenuTitle title={strings("скорость")} />
                                            {SCALE_OPTIONS.map((opt, id) => {
                                                return (
                                                    <VideoControlsMenuItem
                                                        key={id}
                                                        onClick={async () => await this.setScale(opt)}
                                                        label={`${opt.toString()}x`}
                                                        isSelected={opt === scale}
                                                    />
                                                );
                                            })}
                                            <VideoControlsMenuTitle title={strings("Направление")} />
                                            {DIRECTION_SCALE_OPTIONS.map((opt, id) => {
                                                return (
                                                    <VideoControlsMenuItem
                                                        key={id}
                                                        onClick={async () => await this.setScaleDirection(opt.value)}
                                                        label={strings(opt.label)}
                                                        isSelected={opt.value === scaleDirection}
                                                    />
                                                );
                                            })}
                                        </>
                                    )}
                                </>

                                <VideoControlsMenuTitle title={strings("Режим")} />
                                {PLAYER_MODE.map((opt, id) => {
                                    return (
                                        <VideoControlsMenuItem
                                            key={id}
                                            onClick={async () => await this.setPlayerMode(opt.value)}
                                            label={strings(opt.label)}
                                            isSelected={opt.value === playerMode}
                                        />
                                    );
                                })}
                            </VideoControlsMenu>
                        )}
                    </>
                }
                withFullscreen
                fullscreenToggleTitle={strings("На весь экран")}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(withRouter(ArchivePlayer))));

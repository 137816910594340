const path = "/devices-api/deviceGroup";

export const UpdateDevicesGroup = async (group) => {
    let id = group.id;
    let data = { ...group };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.status === 200, response.status];
};

export const DeleteDevicesGroup = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response.status === 200, response.status];
};

export const GetDevicesGroups = async () => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/list`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();
        return [true, res?.items?.sort(CompareGroups)];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

const CompareGroups = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const GetDevicesGroupsByDeviceId = async (deviceId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/by-device-id/${deviceId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();
        return [true, res?.items?.sort(CompareGroups)];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const UpdateDevicesGroupsByDeviceId = async (deviceId, deviceGroupsIds) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${path}/by-device-id/${deviceId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(deviceGroupsIds),
        });

        return response.status === 200;
    } catch (ex) {
        console.error(ex);
        return false;
    }
};

export const CreateDevicesGroup = async (deviceGroup) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(path, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(deviceGroup),
        });

        if (response.status !== 200) {
            return [false, response.status];
        }

        let res = await response.json();

        return [response.status === 200, response.status, res?.id];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const CountDevicesInGroup = async (id) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/count/${id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.count];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

import { FilterAccordion } from "../Filters";
import FilteredCameras from "./FilteredCameras";

const CamerasTree = (props) => {
    return (
        <>
            {props.search && props.search !== "" ? (
                <FilteredCameras
                    filteredCameras={props.filteredCameras}
                    selectedCameras={props.selectedCameras}
                    selectCamera={props.selectCamera}
                />
            ) : (
                <FilterAccordion
                    items={props.devicesLocationsTree}
                    selected={props.selectedCameras}
                    openItem={props.openItem}
                    blockParentCheckbox={props.blockParentCheckbox ?? true}
                    selectHandler={props.selectHandler}
                />
            )}
        </>
    );
};

export default CamerasTree;

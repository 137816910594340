const path = "/desktops-api/desktops";

export const GetDesktops = async () => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(path, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.data];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetDesktopInfo = async (id) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/${id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const RemoveDesktop = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return response.status === 200;
};

export const CreateDesktop = async (desktop) => {
    try {
        const token = window.localStorage.getItem("token");
        let response = await fetch(path, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(desktop),
        });

        return [response.ok, response.status, response.ok ? (await response.json())?.data : null];
    } catch (e) {
        console.error(e);
        return [false, null, null];
    }
};

export const UpdateDesktop = async (desktop) => {
    try {
        let data = { ...desktop };
        let id = desktop.id;
        delete data.id;

        const token = window.localStorage.getItem("token");
        let response = await fetch(`${path}/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        return [response.ok, response.status];
    } catch (e) {
        console.error(e);
        return [false, null];
    }
};

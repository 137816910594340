import styled from "styled-components";

export const EventsLayout = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
`;

export const EventsLayoutPrimary = styled.div`
    position: relative;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.primary2};
    display: flex;
    flex-direction: column;
    width: ${({ hasSecondary }) => (hasSecondary ? "50%" : "100%")};
    padding: ${({ noPadding }) => (noPadding ? "0" : "24px")};
`;

export const EventsLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 90vh;
`;

export const EventsLayoutPrimaryInner = styled.div`
    position: relative;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.primary2};
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
`;

export const EventsLayoutSecondary = styled.div`
    flex: 1;
    height: 100%;
    width: 35vw;
    background-color: ${({ theme }) => theme.colors.primary3};
`;

export const EventsLayoutHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

export const EventsLayoutHeaderTitle = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
    padding-right: 32px;
    height: 32px;

    & > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const EventsLayoutHeaderToolbox = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;
`;

export const EventsLayoutHeaderToolboxItem = styled.div`
    & + & {
        margin-left: 8px;
    }
`;

export const EventsLayoutTableWrapper = styled.div`
    flex-direction: column;
    overflow-y: auto;
    display: inline-flex;
    padding: 0 24px;
    padding-bottom: 24px;
    margin: 0 -24px -24px -24px;
    max-height: 75vh;
`;

export const WideEventsLayoutTableWrapper = styled.div`
    flex: 1;
    flex-direction: column;
    overflow: auto;
    display: inline-flex;
    padding: 0 24px;
    padding-bottom: 24px;
    margin: 0 -24px -24px -24px;
    max-height: 75vh;
`;

export const EventsLayoutPaginationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
`;

import React, { useState, useRef } from "react";
import styled from "styled-components";

const ParentContainer = styled.span`
    position: relative;
`;

const TooltipContainer = styled.div`
    display: flex;
    position: fixed;
    z-index: 10;
    transform: translateY(0);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s linear, transform 0.1s linear, visibility 0s linear 0.1s;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent transparent ${({ theme }) => theme.colors.white};
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    ${({ isOpen }) => isOpen && openState()};
`;

const openState = () => `
    visibility: visible;
    opacity: 1;
    transform: translateY(-16px);
    transition: opacity 0.1s linear, transform 0.1s linear, visibility 0s linear 0s;
`;

export const LeftSideTooltip = ({ children, tooltipBody }) => {
    const parentContainerRef = useRef();
    const tooltipContainerRef = useRef();

    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        const parentRect = parentContainerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipContainerRef.current.getBoundingClientRect();

        tooltipContainerRef.current.style.left = parentRect.x - tooltipRect.width - 24 + "px";
        tooltipContainerRef.current.style.top =
            parentRect.y + parentRect.height + (parentRect.height - tooltipRect.height) / 2 + "px";

        setOpen(true);
    };

    return (
        <ParentContainer onMouseEnter={handleOpen} onMouseLeave={() => setOpen(false)} ref={parentContainerRef}>
            {children}
            <TooltipContainer isOpen={isOpen} ref={tooltipContainerRef}>
                {tooltipBody}
            </TooltipContainer>
        </ParentContainer>
    );
};

import React from "react";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../../../../components/DetailsBox";
import { Button, Dropdown, DropdownDivider, DropdownItem, Tab, TabPanel, Text } from "headpoint-react-components";
import PubSub from "pubsub-js";
import { SHOW_CREATE_GROUP_MODAL, SHOW_DELETE_GROUP_MODAL } from "../devicesGroupsTopics";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_UPDATE_MODE } from "../../../../components/CreateUpdateWizard/constants";

class DeviceGroupDetails extends React.Component {
    constructor(props) {
        super(props);
        this.tabSets = [];
        this.state = {
            detailsTab: "main",
        };
    }

    render() {
        const { selected, setSelected } = this.props;
        const { detailsTab } = this.state;

        const tabSet = this.tabSets.find((s) => s.key === detailsTab) ?? this.tabSets.find((s) => s.key === "devices");
        const { key: actualDetailsTab, component: actualDetailsComponent } = tabSet ?? {};

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {selected?.name}
                                    </Text>
                                </DetailsBoxTitle>
                                <DetailsBoxHeaderActions>
                                    <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                        <DropdownItem
                                            onClick={() => {
                                                PubSub.publish(SHOW_CREATE_GROUP_MODAL, {
                                                    wizardMode: WIZARD_UPDATE_MODE,
                                                    values: { ...selected },
                                                });
                                            }}
                                            as="button"
                                        >
                                            {strings("Редактировать")}
                                        </DropdownItem>

                                        <DropdownDivider />

                                        <DropdownItem
                                            onClick={() => {
                                                PubSub.publish(SHOW_DELETE_GROUP_MODAL, {
                                                    values: { ...selected },
                                                });
                                            }}
                                            as="button"
                                            colorVariant="danger"
                                        >
                                            {strings("Удалить")}
                                        </DropdownItem>
                                    </Dropdown>
                                    <Button icon="close" variant="secondary" onClick={setSelected} />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>
                            <DetailsBoxTabs>
                                <TabPanel>
                                    {this.tabSets.map((tab) => (
                                        <Tab
                                            key={`${tab.key}`}
                                            label={strings(tab.title)}
                                            isActive={actualDetailsTab === tab.key}
                                            onClick={() => this.setState({ detailsTab: tab.key })}
                                        />
                                    ))}
                                </TabPanel>
                            </DetailsBoxTabs>
                            {actualDetailsComponent && React.createElement(actualDetailsComponent, { ...this.props })}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

export default DeviceGroupDetails;

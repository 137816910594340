import React from "react";
import { Text, Table, TableHead, TableHeadCell, TableBody, TableRow, TableCell, Row } from "headpoint-react-components";

import { DetailsBoxContent } from "../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabDevices extends React.Component {
    render() {
        const { selectedAdapter } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Row marginTop={24} marginBottom={16}>
                            <Text variant="title" component="h3">
                                {`${selectedAdapter?.deviceTypes?.length ?? 0} ${strings("устройств адаптера")}`}
                            </Text>
                        </Row>

                        <Table noPadding>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Устройство")}</TableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedAdapter?.deviceTypes?.map((item, i) => (
                                    <TableRow>
                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {item.name}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

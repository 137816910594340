import React from "react";
import { EventsLayoutPaginationWrapper } from "../../layout/EventsLayout";
import { Pagination, Button } from "headpoint-react-components";
import { CultureContextConsumer } from "../../contexts/cultureContext/CultureContext";

export const ITEMS_ON_PAGE = 10;

export class CommonTablePagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            itemsOnPage: ITEMS_ON_PAGE,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { itemsOnPage, page } = this.state;

        if (
            this.props.tableData.length !== prevProps.tableData.length ||
            this.props.searchMode !== prevProps.searchMode
        ) {
            this.props.setPaging(this.handlePage(page, itemsOnPage));
        }
    }

    handlePage(page, itemsOnPage) {
        const { tableData, searchMode } = this.props;

        if (searchMode) {
            return { start: 0, end: tableData.length };
        }

        let start = itemsOnPage * (page - 1);
        let end = itemsOnPage * page;

        if (end - tableData.length >= itemsOnPage && page > 1) {
            page = Math.max(page - 1, 1);
            start = 0;
            end = itemsOnPage;
        }

        this.setState({ page, itemsOnPage });

        return { start, end };
    }

    render() {
        const { page, itemsOnPage } = this.state;
        const { tableData, searchMode } = this.props;

        const pagesCount = Math.ceil(tableData.length / itemsOnPage);
        let offset = Math.min(page > 3 ? page - 3 : 0, pagesCount > 5 ? pagesCount - 5 : 0);

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutPaginationWrapper>
                        {!searchMode ? (
                            <>
                                <Button
                                    variant="secondary"
                                    label={strings("Показать ещё")}
                                    onClick={() =>
                                        this.props.setPaging(this.handlePage(1, Math.trunc(itemsOnPage * 2)))
                                    }
                                />
                                <Pagination
                                    offset={offset}
                                    page={page}
                                    count={pagesCount}
                                    onChange={(p) => {
                                        this.props.setPaging(this.handlePage(p, itemsOnPage));
                                    }}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </EventsLayoutPaginationWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { Table, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { GetLocationList } from "../../../../../services/locations";
import { withSnackbar } from "notistack";
import { GetDevicesGroupsByDeviceId } from "../../../../../services/devicesGroups";
import { checkFeatureByDeviceType, getAdapters, getEquipmentType } from "../../../../../contexts/GeneralContext";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { SearchControlZonesList } from "../../../../../services/controlZones";

class DetailsTabMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationFullName: "",
            deviceGroups: [],
            controlZones: [],
        };
    }

    componentDidMount = async () => {
        const { selectedDevice } = this.props;
        const locationFullName = await this.loadLocationFullPath(selectedDevice?.locationId);
        const deviceGroups = await this.getDevicesGroups();
        const controlZones = await this.getControlZones();

        this.setState({
            locationFullName,
            deviceGroups,
            controlZones,
        });
    };

    componentDidUpdate = async (prevProps) => {
        if (this.props.selectedDevice?.locationId !== prevProps.selectedDevice?.locationId) {
            const locationFullName = await this.loadLocationFullPath(this.props.selectedDevice?.locationId);

            this.setState({
                locationFullName,
            });
        }
    };

    getDevicesGroups = async () => {
        const { selectedDevice, strings } = this.props;

        const [selectedGroupsStatus, deviceGroups] = await GetDevicesGroupsByDeviceId(selectedDevice.id);

        if (!selectedGroupsStatus) {
            this.props.enqueueSnackbar(`${strings("Ошибка получения групп устройства")} ${selectedDevice.id}`, {
                variant: "error",
            });
            return [];
        }

        return deviceGroups;
    };

    getControlZones = async () => {
        const { selectedDevice, strings } = this.props;

        const [status, controlZones] = await SearchControlZonesList({ deviceIds: [selectedDevice.id] });

        if (!status) {
            this.props.enqueueSnackbar(`${strings("Ошибка получения зон контроля")} ${selectedDevice.id}`, {
                variant: "error",
            });
            return [];
        }

        return controlZones;
    };

    loadLocationFullPath = async (locationId) => {
        if (!locationId) {
            return;
        }

        const { strings } = this.props;

        let [locationsStatus, locations] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locations?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const location = locations[0];
        [locationsStatus, locations] = await GetLocationList({ ids: location?.parentIds ?? [] });
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        return (locations?.map((l) => l.name) ?? []).concat(location.name).join(" / ");
    };

    geolocationRow = (device) => {
        const { strings } = this.props;
        const { latitude, longitude } = device?.properties?.geolocation;
        const hasLat = latitude !== null && latitude !== undefined && latitude !== "";
        const hasLng = longitude !== null && longitude !== undefined && longitude !== "";

        return hasLat && hasLng ? (
            <>
                <TableRow>
                    <TableCell noBorder>{strings("Местоположение")}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell indent={30} noBorder>
                        {strings("Широта")}
                    </TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {latitude}
                        </Text>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell indent={30}>{strings("Долгота")}</TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {longitude}
                        </Text>
                    </TableCell>
                </TableRow>
            </>
        ) : null;
    };

    selectedDeviceIsCamera = () => {
        return checkFeatureByDeviceType(
            this.props.selectedDevice?.typeId,
            getAdapters(this.props.generalInfo),
            "video.stream"
        );
    };

    render() {
        const { selectedDevice, generalInfo, deviceType, strings } = this.props;
        const { locationFullName, deviceGroups } = this.state;

        return (
            <DetailsBoxContent>
                <Table isFixed noPadding>
                    <TableBody>
                        <TableRow>
                            <TableCell>{strings("Тип оборудования")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {strings(getEquipmentType(generalInfo, selectedDevice))}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Тип устройства")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {strings(deviceType?.label)}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Группы оборудования")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {deviceGroups.map((group) => group.name).join(", ")}
                                </Text>
                            </TableCell>
                        </TableRow>

                        {this.selectedDeviceIsCamera() && (
                            <TableRow>
                                <TableCell>{strings("Объекты наблюдения")}</TableCell>
                                <TableCell>
                                    <Text scroll color="primary" variant="body-sm">
                                        {selectedDevice?.properties?.observedObjects}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell>{strings("Зоны контроля")}</TableCell>
                            <TableCell>
                                <Text scroll color="primary" variant="body-sm">
                                    {this.state.controlZones.map((zone) => zone.name).join(", ")}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Комментарий")}</TableCell>
                            <TableCell>
                                <Text scroll color="primary" variant="body-sm">
                                    {selectedDevice?.properties?.comment}
                                </Text>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{strings("Локация")}</TableCell>
                            <TableCell>
                                <Text color="primary" variant="body-sm">
                                    {locationFullName}
                                </Text>
                            </TableCell>
                        </TableRow>
                        {this.geolocationRow(selectedDevice)}
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabMain));

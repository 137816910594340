import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { Checkbox, Row, Select, TextField, Text, Space } from "headpoint-react-components";
import { CompareObjects } from "../../../../../../utilites/Comparer";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";
import UnumberInput from "./Fields/UnumberInput";
import { WIZARD_CREATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

const BITRATE_CODE = "bitrate";
const ARCHIVE_DEPTH_CODE = "archiveDepth";
const ARCHIVE_DEPTH_TYPE_CODE = "archiveDepthType";
const IS_ARCHIVE_CODE = "isArchive";

export class DeviceTypeSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDevTypeSettings: undefined,
        };
    }

    componentDidMount() {
        if (this.props.mode === WIZARD_CREATE_MODE && this.props.selectedDevTypeSettings) {
            let settingsToUpdate = {};
            this.props.selectedDevTypeSettings?.forEach((param) => {
                if (!this.props.deviceSettings[param.code]?.value) {
                    settingsToUpdate[param.code] = {
                        value: param.default,
                        required: param.required,
                        defaultValue: param.default,
                        name: param.name,
                    };
                }
            });

            this.setState({ selectedDevTypeSettings: this.props.selectedDevTypeSettings });
            this.props.SetDeviceSettings(settingsToUpdate);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mode === WIZARD_CREATE_MODE) {
            const { selectedDevTypeSettings } = this.props;
            const compareFrom = { selectedDevTypeSettings };

            if (!CompareObjects(compareFrom, prevProps)) {
                let settingsToUpdate = {};

                this.props.selectedDevTypeSettings?.forEach((param) => {
                    switch (param?.type) {
                        case "archive-select-input":
                            const elements = [param.input, param.checkbox, param.select];

                            elements.forEach((el) => {
                                if (!this.props.deviceSettings[el.code]?.value) {
                                    settingsToUpdate[el?.code] = {
                                        value: el?.default,
                                        required: el?.required,
                                        defaultValue: el?.default,
                                        name: el?.name,
                                    };
                                }
                            });
                            break;

                        default:
                            if (!this.props.deviceSettings[param.code]?.value) {
                                settingsToUpdate[param.code] = {
                                    value: param.default,
                                    required: param.required,
                                    defaultValue: param.default,
                                    name: param.name,
                                };
                            }
                            break;
                    }
                });

                this.props.SetDeviceSettings(settingsToUpdate);
            }
        }
    }

    render() {
        const { selectedDevTypeSettings, deviceSettings } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        {selectedDevTypeSettings ? (
                            selectedDevTypeSettings.map((param) => {
                                switch (param.type) {
                                    case "string":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <TextField
                                                    label={strings(param.name)}
                                                    name={param.code}
                                                    type="text"
                                                    value={deviceSettings[param.code]?.value ?? param?.default}
                                                    onChange={(e) => {
                                                        const code = param.code;
                                                        let settingsParameter = {};
                                                        settingsParameter[code] = {
                                                            value: e.target.value,
                                                            required: param.required,
                                                            defaultValue: param.default,
                                                            name: param.name,
                                                        };
                                                        this.props.SetDeviceSettings(settingsParameter);
                                                    }}
                                                    colorVariant="light"
                                                    fullWidth
                                                    error={
                                                        param.required && !deviceSettings[param.code]?.value
                                                            ? strings(param?.errorText)
                                                            : null
                                                    }
                                                />
                                            </ModalFormItem>
                                        );
                                    case "select":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <Select
                                                    label={strings(param.name)}
                                                    placeholder={strings("Выбрать тип")}
                                                    value={deviceSettings[param.code]?.value ?? param.default}
                                                    options={param.options ?? []}
                                                    onChange={(value) => {
                                                        const code = param.code;
                                                        let settingsParameter = {};
                                                        settingsParameter[code] = {
                                                            value: value,
                                                            required: param.required,
                                                            defaultValue: param.default,
                                                            name: param.name,
                                                        };
                                                        this.props.SetDeviceSettings(settingsParameter);
                                                    }}
                                                    colorVariant="default"
                                                    horizontalAlignment="left"
                                                    verticalAlignment="bottom"
                                                    fullWidth
                                                    error={
                                                        param.required && !deviceSettings[param.code]?.value
                                                            ? strings(param?.errorText)
                                                            : null
                                                    }
                                                />
                                            </ModalFormItem>
                                        );
                                    case "archive-select-input":
                                        const bitrateMb = Number(deviceSettings[BITRATE_CODE]?.value);
                                        const archiveDepth = Number(deviceSettings[ARCHIVE_DEPTH_CODE]?.value);

                                        const archive = deviceSettings[IS_ARCHIVE_CODE]?.value;

                                        const archiveDepthType =
                                            deviceSettings[ARCHIVE_DEPTH_TYPE_CODE]?.value ?? param?.select?.default;

                                        let approximateDepth = undefined;

                                        switch (archiveDepthType) {
                                            case "gb":
                                                const approximateDays = (archiveDepth * 8 * 1024) / bitrateMb / 86400;
                                                if (!isNaN(approximateDays)) {
                                                    approximateDepth = `${strings(
                                                        "Ориентировочная глубина записи"
                                                    )} ${approximateDays.toFixed()} ${strings("Дн")}`;
                                                }
                                                break;
                                            case "day":
                                                const approximateGb = (archiveDepth * 86400 * bitrateMb) / 8 / 1024;
                                                if (!isNaN(approximateGb)) {
                                                    approximateDepth = `${strings(
                                                        "Ориентировочная глубина записи"
                                                    )} ${approximateGb.toFixed(2)} ${strings("ГБ")}`;
                                                }
                                                break;
                                            default:
                                                break;
                                        }

                                        return (
                                            <ModalFormItem key={param.checkbox.code}>
                                                <ModalFormItem key={param.checkbox.code}>
                                                    <Checkbox
                                                        label={strings(param.checkbox.name)}
                                                        checked={deviceSettings[param.checkbox.code]?.value}
                                                        onChange={() => {
                                                            const code = param.checkbox.code;
                                                            let settingsParameter = {};
                                                            settingsParameter[code] = {
                                                                value: !deviceSettings[param?.checkbox?.code]?.value,
                                                                required: param?.checkbox?.required,
                                                                defaultValue: param?.checkbox?.default,
                                                                name: param.checkbox.name,
                                                            };
                                                            this.props.SetDeviceSettings(settingsParameter);
                                                        }}
                                                    />
                                                </ModalFormItem>

                                                {archive && (
                                                    <>
                                                        <Space></Space>
                                                        <Row>
                                                            <ModalFormItem key={param.input.code}>
                                                                <TextField
                                                                    label={strings(param.input.name)}
                                                                    name={param.input.code}
                                                                    type="number"
                                                                    value={
                                                                        deviceSettings[param.input.code]?.value ??
                                                                        param.input.default
                                                                    }
                                                                    onChange={(e) => {
                                                                        const code = param.input.code;
                                                                        let settingsParameter = {};
                                                                        settingsParameter[code] = {
                                                                            value: e.target.value,
                                                                            required: param.input.required,
                                                                            defaultValue: param.input.default,
                                                                            name: param.input.name,
                                                                        };
                                                                        this.props.SetDeviceSettings(settingsParameter);
                                                                    }}
                                                                    colorVariant="light"
                                                                    verticalAlignment="bottom"
                                                                    error={
                                                                        param?.input.required &&
                                                                        !deviceSettings[param.input.code]?.value
                                                                            ? strings(param?.input.errorText)
                                                                            : null
                                                                    }
                                                                />
                                                            </ModalFormItem>

                                                            <ModalFormItem>
                                                                <Select
                                                                    isInline={true}
                                                                    label={strings(param.select.name)}
                                                                    placeholder={strings("Выбрать тип")}
                                                                    width="50%"
                                                                    value={
                                                                        deviceSettings[param.select.code]?.value ??
                                                                        param.select.default
                                                                    }
                                                                    options={
                                                                        param.select.options.map((o) => {
                                                                            return {
                                                                                label: strings(o.label),
                                                                                value: o.value,
                                                                            };
                                                                        }) ?? []
                                                                    }
                                                                    onChange={(value) => {
                                                                        const code = param.select.code;
                                                                        let settingsParameter = {};
                                                                        settingsParameter[code] = {
                                                                            value: value,
                                                                            required: param.select.required,
                                                                            defaultValue: param.select.default,
                                                                            name: param.select.name,
                                                                        };
                                                                        this.props.SetDeviceSettings(settingsParameter);
                                                                    }}
                                                                    colorVariant="default"
                                                                    horizontalAlignment="left"
                                                                    verticalAlignment="bottom"
                                                                    error={
                                                                        param.select.required &&
                                                                        !deviceSettings[param.select.code]?.value
                                                                            ? strings(param?.select.errorText)
                                                                            : null
                                                                    }
                                                                />
                                                            </ModalFormItem>
                                                        </Row>

                                                        {approximateDepth !== undefined && (
                                                            <>
                                                                <Space></Space>

                                                                <Row>
                                                                    <Text variant="description" color="warning">
                                                                        {approximateDepth}
                                                                    </Text>
                                                                </Row>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </ModalFormItem>
                                        );
                                    case "int":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <TextField
                                                    label={strings(param.name)}
                                                    name={param.code}
                                                    type="number"
                                                    value={deviceSettings[param.code]?.value ?? param.default}
                                                    onChange={(e) => {
                                                        const code = param.code;
                                                        let settingsParameter = {};
                                                        settingsParameter[code] = {
                                                            value: e.target.value,
                                                            required: param.required,
                                                            defaultValue: param.default,
                                                            name: param.name,
                                                        };
                                                        this.props.SetDeviceSettings(settingsParameter);
                                                    }}
                                                    colorVariant="light"
                                                    fullWidth
                                                    error={
                                                        param?.required && !deviceSettings[param.code]?.value
                                                            ? strings(param?.errorText)
                                                            : null
                                                    }
                                                />
                                            </ModalFormItem>
                                        );
                                    case "unumber":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <UnumberInput
                                                    param={param}
                                                    deviceSettings={deviceSettings}
                                                    SetDeviceSettings={this.props.SetDeviceSettings}
                                                    strings={strings}
                                                />
                                            </ModalFormItem>
                                        );
                                    case "checkbox":
                                        return (
                                            <ModalFormItem key={param.code}>
                                                <Checkbox
                                                    label={strings(param.name)}
                                                    checked={deviceSettings[param.code]?.value}
                                                    onChange={() => {
                                                        const code = param.code;
                                                        let settingsParameter = {};
                                                        settingsParameter[code] = {
                                                            value: !deviceSettings[param.code]?.value,
                                                            required: param?.required,
                                                            defaultValue: param?.default,
                                                            name: param.name,
                                                        };
                                                        this.props.SetDeviceSettings(settingsParameter);
                                                    }}
                                                />
                                            </ModalFormItem>
                                        );
                                    default:
                                        return <></>;
                                }
                            })
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { withSnackbar } from "notistack";
import { Dropdown, DropdownItem, Text, ExpandableSearch } from "headpoint-react-components";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
} from "../../../../layout/EventsLayout";
import { VariableTable } from "./SystemVariablesTable";
import { VariableDetails } from "./details/SystemVariablesDetails";
import { CreateVariable, DeleteVariable, GetVariables, UpdateVariable } from "../../../../services/variables";
import { GeneralContextConsumer, permissionExists, getAvailableSystemVariables } from "../../../../contexts";
import { DeleteModal } from "../DeleteModal";
import { SystemVariablesModal } from "./modals/SystemVariablesModal";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const initialValues = {
    serviceCode: null,
    code: null,
    value: null,
};

class SystemVariablesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variables: [],
            selected: undefined,
            search: "",
            showModal: false,
            wizardMode: WIZARD_CREATE_MODE,
            values: initialValues,
        };
    }

    componentDidMount = async () => {
        const [status, variables] = await GetVariables();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения системных переменных"), { variant: "error" });
            return;
        }

        this.setState({ variables });
    };

    setSearch = (search) => {
        this.setState({ search: search });
    };

    createVariableHandler = async () => {
        const { values, variables } = this.state;
        const { enqueueSnackbar, strings } = this.props;
        if (values.value === null || values.value === undefined || values.value === "") {
            enqueueSnackbar(strings("Нельзя создать переменную без значения"), { variant: "error" });
            return;
        }

        const variable = this.valuesToVariable(values);
        const [status, statusCode] = await CreateVariable(variable);
        if (status) {
            this.setState({
                showModal: false,
                variables: [...(variables ?? []), { ...values }],
                values: { ...initialValues },
            });

            enqueueSnackbar(`${strings("Системная переменная")} '${strings(variable.name)}' ${strings("создана")}`, {
                variant: "success",
            });
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    enqueueSnackbar(
                        `${strings("Не хватает прав для создания системной переменной")} '${strings(variable.name)}'`,
                        {
                            variant: "warning",
                        }
                    );
                    return;

                case 409: // conflict
                    enqueueSnackbar(
                        `${strings("Системная переменная с именем")} '${strings(variable.name)}' ${strings(
                            "уже существует"
                        )}`,
                        {
                            variant: "warning",
                        }
                    );
                    return;

                default:
                    enqueueSnackbar(
                        `${strings("Не удалось создать системную переменную")} '${strings(variable.name)}'`,
                        {
                            variant: "error",
                        }
                    );
                    return;
            }
        }
    };

    updateVariableHandler = async () => {
        const { values, variables, selected } = this.state;
        const { enqueueSnackbar, strings } = this.props;

        if (!values.value && values.value !== 0) {
            enqueueSnackbar(strings("Нельзя сохранить переменную без значения"), { variant: "error" });
            return;
        }

        const variable = this.valuesToVariable(values);
        const [status, statusCode] = await UpdateVariable(variable);
        if (status) {
            enqueueSnackbar(
                `${strings("Системная переменная")} '${strings(variable.name)}' ${strings("успешно изменена")}`,
                {
                    variant: "success",
                }
            );
            const index = variables.findIndex((vr) => vr.code === values.code && vr.serviceCode === values.serviceCode);
            variables[index] = values;
            this.setState({
                selected: selected ? values : null,
                showModal: false,
                wizardMode: WIZARD_CREATE_MODE,
                values: { ...initialValues },
                variables: variables ?? [],
            });
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    enqueueSnackbar(
                        `${strings("Не хватает прав для изменения системной переменной")} '${strings(variable.name)}'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                case 409: // conflict
                    enqueueSnackbar(
                        `${strings("Системная переменная с именем")} '${strings(variable.name)}' ${strings(
                            "уже существует"
                        )}`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                default:
                    enqueueSnackbar(
                        `${strings("Не удалось изменить системную переменную")} '${strings(variable.name)}'`,
                        {
                            variant: "error",
                        }
                    );
                    break;
            }
        }
    };

    deleteVariableHandler = async () => {
        const { values, variables } = this.state;
        const { enqueueSnackbar, strings } = this.props;

        const variable = this.valuesToVariable(values);
        const [status, statusCode] = await DeleteVariable(variable.serviceCode, variable.code);
        if (status) {
            enqueueSnackbar(`${strings("Системная переменная")} '${strings(variable.name)}' ${strings("удалена")}`, {
                variant: "success",
            });
            this.setState({
                variables:
                    variables?.filter((v) => v.code !== values.code || v.serviceCode !== values.serviceCode) ?? [],
                selected: null,
                values: initialValues,
            });
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    enqueueSnackbar(
                        `${strings("Не хватает прав для удаления системной переменной")} '${strings(variable.name)}'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                default:
                    enqueueSnackbar(
                        `${strings("Не удалось удалить системную переменную")} '${strings(variable.name)}'`,
                        {
                            variant: "error",
                        }
                    );
                    break;
            }
        }
    };

    valuesToVariable = (values) => {
        let result = { ...values };
        result.properties = { value: values.value };
        delete result.value;

        return result;
    };

    variableToValues = (variable) => {
        let result = { ...variable, ...variable?.properties };
        delete result.properties;

        return result;
    };

    setInfo = (info, avalibleVariables) => {
        const { selected, wizardMode } = this.state;
        const variableInfo = avalibleVariables?.find((v) => `${v.serviceCode}__${v.code}` === info);
        if (!variableInfo) {
            return;
        }

        let values = { ...variableInfo };
        if (wizardMode !== WIZARD_CREATE_MODE) {
            values = { ...values, ...selected };
        }

        values.value = values.value ?? values.default;
        this.setState({ values });
    };

    render() {
        const { selected, variables, search, values, wizardMode, showModal } = this.state;
        const { userInfo, strings } = this.props;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => {
                    const avalibleVariables = getAvailableSystemVariables(generalInfo);
                    const currentVariables = variables?.reduce((accumulator, current) => {
                        const info = avalibleVariables.find(
                            (v) => current.code === v.code && current.serviceCode === v.serviceCode
                        );
                        return info ? [...accumulator, { ...info, ...current }] : accumulator;
                    }, []);

                    const source = !search
                        ? currentVariables
                        : currentVariables.filter(
                              (v) =>
                                  v.name?.toLowerCase().includes(search.toLowerCase()) ||
                                  v.code?.toLowerCase().includes(search.toLowerCase()) ||
                                  v.serviceCode?.toLowerCase().includes(search.toLowerCase())
                          );

                    return (
                        <>
                            <DeleteModal
                                visible={showModal && wizardMode === "delete"}
                                CloseHandler={() => this.setState({ showModal: false })}
                                RemoveHandler={this.deleteVariableHandler}
                                text={`${strings("Вы хотите удалить системную переменную")} "${strings(
                                    values?.name
                                )}". ${strings("Удалённую переменную нельзя восстановить. Продолжить?")}`}
                            />
                            {showModal && wizardMode !== "delete" && (
                                <SystemVariablesModal
                                    Mode={wizardMode}
                                    values={values}
                                    SetValue={(value) => this.setState({ values: { ...values, value } })}
                                    SetInfo={(info) => this.setInfo(info, avalibleVariables)}
                                    CreateHandler={this.createVariableHandler}
                                    EditHandler={this.updateVariableHandler}
                                    CloseHandler={() =>
                                        this.setState({ showModal: false, wizardMode: WIZARD_CREATE_MODE })
                                    }
                                    variables={currentVariables ?? []}
                                    avalibleVariables={avalibleVariables ?? []}
                                />
                            )}
                            <EventsLayoutPrimary hasSecondary={!!selected}>
                                <EventsLayoutHeader>
                                    <EventsLayoutHeaderTitle>
                                        <Text variant="title" component="h1">
                                            {strings("Системные переменные")}
                                        </Text>
                                        <ExpandableSearch
                                            value={search}
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            placeholder={strings("Найти")}
                                            colorVariant="dark"
                                            inHeader
                                        />
                                    </EventsLayoutHeaderTitle>
                                    {permissionExists(userInfo, [
                                        "variables.variable.read",
                                        "variables.variable.lifeCycle",
                                    ]) && (
                                        <EventsLayoutHeaderToolbox>
                                            <Dropdown
                                                toggleLabel={strings("Создать")}
                                                closeOnClickInside
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                <DropdownItem
                                                    as="button"
                                                    onClick={() =>
                                                        this.setState({
                                                            showModal: true,
                                                            values: { ...initialValues },
                                                            wizardMode: WIZARD_CREATE_MODE,
                                                        })
                                                    }
                                                >
                                                    {strings("Новую системную переменную")}
                                                </DropdownItem>
                                            </Dropdown>
                                        </EventsLayoutHeaderToolbox>
                                    )}
                                </EventsLayoutHeader>
                                <VariableTable
                                    variables={source ?? []}
                                    setselected={(variable) =>
                                        this.setState({ selected: this.variableToValues(variable) })
                                    }
                                    selected={selected}
                                    deleteVariable={(variable) =>
                                        this.setState({
                                            showModal: true,
                                            wizardMode: "delete",
                                            values: this.variableToValues(variable),
                                        })
                                    }
                                    editVariable={(variable) =>
                                        this.setState({
                                            showModal: true,
                                            wizardMode: WIZARD_UPDATE_MODE,
                                            values: this.variableToValues(variable),
                                        })
                                    }
                                />
                            </EventsLayoutPrimary>
                            {selected && (
                                <VariableDetails
                                    key={`${selected.serviceCode}__${selected.code}`}
                                    selected={selected}
                                    setSelected={(variable) => this.setState({ selected: variable })}
                                    deleteVariable={(variable) =>
                                        this.setState({
                                            showModal: true,
                                            wizardMode: "delete",
                                            values: this.variableToValues(variable),
                                        })
                                    }
                                    editVariable={(variable) =>
                                        this.setState({
                                            showModal: true,
                                            wizardMode: WIZARD_UPDATE_MODE,
                                            values: this.variableToValues(variable),
                                        })
                                    }
                                />
                            )}
                        </>
                    );
                }}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(SystemVariablesSettings)));

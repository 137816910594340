import React from "react";
import {
    VideoCameraControls,
    VideoCameraControlsDirections,
    VideoCameraControlsZoom,
    VideoCameraControlsBtn,
} from "headpoint-react-components";
import { GeneralContextConsumer } from "../../../contexts";
import { PRESETS_SERVICE_CODE, PTZ_FEATURE_CODE } from "./constants";
import { getServiceFeature, permissionExists, serviceExists } from "../../../contexts/GeneralContext";
import { ExecuteCommand } from "../../../services/featureCommands";
import { debounce } from "debounce";
import { throttle } from "throttle-debounce";
import { withSnackbar } from "notistack";
import { APPLY_PRESET_TOPIC_PREFIX, ADJUST_VIEW_ZONE, SHOW_STREAM_PLAYER_DIALOG_PREFIX } from "./streamTopics";
import PubSub from "pubsub-js";
import Presets from "./Presets";
import { Allow, Reset } from "../../../services/priorities";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class Ptz extends React.Component {
    constructor(props) {
        super(props);

        this.debouncePtzCommand = debounce(this.ptzCommand, 500);

        this.throttlePtzCommand = throttle(
            500,
            (...args) => {
                void this.ptzCommand(...args);
            },
            { noLeading: false, noTrailing: false }
        );

        this.state = {
            zoom: 1,
        };
    }

    componentDidMount = () => {
        this.showDialogTopic = PubSub.subscribe(
            SHOW_STREAM_PLAYER_DIALOG_PREFIX + this.props.currentFrame.id,
            async (msg, payload) => {
                if (payload?.type === "applyPreset") {
                    this.setState({ zoom: payload?.data?.preset?.properties?.ptz.zoom * 100 });
                }
            }
        );
    };

    componentWillUnmount = () => {
        this.showDialogTopic && PubSub.unsubscribe(this.showDialogTopic);
    };

    async ptzCommand(command, generalInfo, ptzFeatureSettings) {
        const { enqueueSnackbar, currentFrame: camera, strings } = this.props;
        const { zoom } = this.state;

        const [success, statusCode] = await Allow("ptz", camera.id);
        if (!success) {
            if (statusCode === 423) {
                this.props.enqueueSnackbar(strings("Управление камерой заблокировано"), {
                    variant: "warning",
                });
            } else {
                this.props.enqueueSnackbar(strings("Не удалось проверить приоритет пользователя"), {
                    variant: "error",
                });
            }

            return;
        }

        const ptzFeature = getServiceFeature(generalInfo, ptzFeatureSettings.serviceCode, PTZ_FEATURE_CODE);

        if (!ptzFeature) {
            this.props.enqueueSnackbar(
                `${strings("Не удалось найти сервис с кодом")} ${ptzFeatureSettings.serviceCode} ${strings(
                    "и фичей"
                )} ${PTZ_FEATURE_CODE}`,
                { variant: "error" }
            );
            return;
        }

        let parameters = { id: camera.id };
        let commandCode = "move";

        const ptzInversion = () => {
            return ptzFeatureSettings.ptzInversion ? -1.0 : 1.0;
        };

        const calcPanTilt = ({ pan, tilt }) => {
            return { pan: pan * ptzInversion(), tilt: tilt * ptzInversion() };
        };

        switch (command) {
            case "up-left":
                parameters = { ...parameters, ...calcPanTilt({ pan: -0.1, tilt: 0.1 }) };
                break;
            case "up":
                parameters = { ...parameters, ...calcPanTilt({ pan: 0.0, tilt: 0.1 }) };
                break;
            case "up-right":
                parameters = { ...parameters, ...calcPanTilt({ pan: 0.1, tilt: 0.1 }) };
                break;
            case "left":
                parameters = { ...parameters, ...calcPanTilt({ pan: -0.1, tilt: 0.0 }) };
                break;
            case "home":
                parameters = { ...parameters, ...{ index: 0 } };
                commandCode = "presetIndex";
                break;
            case "right":
                parameters = { ...parameters, ...calcPanTilt({ pan: 0.1, tilt: 0.0 }) };
                break;
            case "down-left":
                parameters = { ...parameters, ...calcPanTilt({ pan: -0.1, tilt: -0.1 }) };
                break;
            case "down":
                parameters = { ...parameters, ...calcPanTilt({ pan: 0.0, tilt: -0.1 }) };
                break;
            case "down-right":
                parameters = { ...parameters, ...calcPanTilt({ pan: 0.1, tilt: -0.1 }) };
                break;
            case "zoom":
                parameters = { ...parameters, ...{ scale: zoom / 100 ?? 0.0 } };
                commandCode = "zoom";
                break;
            default:
                this.props.enqueueSnackbar(`${strings("Неизвестная ptz команда")} ${command}`, { variant: "error" });
                return;
        }

        const moveCommand = ptzFeature?.commands?.list.find((c) => c.code === commandCode);

        if (moveCommand && moveCommand.path && moveCommand.method) {
            const [success] = await ExecuteCommand(
                moveCommand?.method,
                ptzFeatureSettings.serviceCode,
                PTZ_FEATURE_CODE,
                moveCommand?.path,
                parameters
            );
            if (!success) {
                const errorMessage =
                    command === "zoom"
                        ? strings("Невозможно изменить зум камеры")
                        : strings("Невозможно повернуть камеру");
                enqueueSnackbar(errorMessage, { variant: "error" });
            } else {
                PubSub.publish(APPLY_PRESET_TOPIC_PREFIX + camera?.id, { type: "reset" });

                if (command !== "zoom" && command !== "home") {
                    PubSub.publish(ADJUST_VIEW_ZONE, {
                        type: command,
                        data: {
                            ...parameters,
                            pan: parameters.pan * ptzInversion(),
                            tilt: parameters.tilt * ptzInversion(),
                        },
                    });
                }
                if (command === "home") {
                    this.setState({ zoom: 1 });
                }
            }
        } else {
            this.props.enqueueSnackbar(`${strings("Команда")} ${commandCode} ${strings("отсутствует у адаптера")}`, {
                variant: "error",
            });
        }
    }

    async resetControl() {
        const { currentFrame: camera, strings } = this.props;
        const [success] = await Reset("ptz", camera.id);
        if (success) {
            this.props.enqueueSnackbar(strings("Захват управления сброшен"), { variant: "success" });
        } else {
            this.props.enqueueSnackbar(strings("Не удалось сбросить управление"), { variant: "error" });
        }
    }

    render() {
        const { zoom } = this.state;
        const { userInfo, currentFrame, strings } = this.props;
        const featureSettings = currentFrame?.properties?.settings?.features;
        const ptzFeatureSettings = featureSettings ? featureSettings[PTZ_FEATURE_CODE] : undefined;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <VideoCameraControls
                            toggleTitle={strings("Управление камерой")}
                            mainControls={
                                <>
                                    <VideoCameraControlsBtn
                                        onClick={() => {
                                            void this.resetControl();
                                        }}
                                        label={strings("Сброс")}
                                        align="center"
                                    />
                                    <VideoCameraControlsDirections
                                        directions={[
                                            "up-left",
                                            "up",
                                            "up-right",
                                            "left",
                                            "home",
                                            "right",
                                            "down-left",
                                            "down",
                                            "down-right",
                                        ]}
                                        onClick={(direction) => {
                                            void this.throttlePtzCommand(direction, generalInfo, ptzFeatureSettings);
                                        }}
                                    />
                                </>
                            }
                            secondaryControls={
                                <>
                                    {serviceExists(generalInfo, PRESETS_SERVICE_CODE) &&
                                        permissionExists(userInfo, ["presets.access"]) && (
                                            <Presets currentFrame={currentFrame} />
                                        )}
                                    <VideoCameraControlsZoom
                                        value={zoom}
                                        onChange={(zoom) => {
                                            zoom = Math.min(100, Math.max(1, zoom));
                                            this.setState({ zoom: zoom }, () => {
                                                void this.debouncePtzCommand("zoom", generalInfo, ptzFeatureSettings);
                                            });
                                        }}
                                        min={1}
                                        max={110}
                                        step={10}
                                        label={strings("Зум")}
                                    />
                                </>
                            }
                        />
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(Ptz)));

import React from "react";
import { withSnackbar } from "notistack";
import { DeviceTypesTable } from "./DeviceTypesTable";
import { EventsLayoutPrimary, EventsLayoutHeader, EventsLayoutHeaderTitle } from "../../../../layout/EventsLayout";
import { ExpandableSearch, Text } from "headpoint-react-components";
import { GeneralContextConsumer, getDeviceTypes } from "../../../../contexts";
import DeviceTypesDetails from "./DeviceTypesDetails";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class DeviceTypesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            selectedType: undefined,
            detailsTab: "main",
        };
    }
    componentDidMount() {}

    setDetailsTab = (detailsTab) => {
        this.setState({ detailsTab: detailsTab });
    };

    render() {
        const { selectedType, detailsTab, search } = this.state;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => (
                            <>
                                <EventsLayoutPrimary hasSecondary={true}>
                                    <EventsLayoutHeader>
                                        <EventsLayoutHeaderTitle>
                                            <Text variant="title" component="h1">
                                                {strings("Типы оборудования")}
                                            </Text>
                                            <ExpandableSearch
                                                value={this.state.search}
                                                onChange={(e) => this.setState({ search: e.target.value })}
                                                onSubmit={() => {
                                                    /* ignore enter */
                                                }}
                                                placeholder={strings("Найти")}
                                                colorVariant="dark"
                                                inHeader
                                            />
                                        </EventsLayoutHeaderTitle>
                                    </EventsLayoutHeader>
                                    <DeviceTypesTable
                                        deviceTypesList={getDeviceTypes(generalInfo)}
                                        search={search}
                                        selectedType={selectedType}
                                        selectType={(type) => this.setState({ selectedType: type })}
                                    />
                                </EventsLayoutPrimary>
                                {selectedType &&
                                    getDeviceTypes(generalInfo).some((t) => t.value === selectedType.value) && (
                                        <DeviceTypesDetails
                                            selectedType={selectedType}
                                            setDetailsTab={this.setDetailsTab}
                                            detailsTab={detailsTab}
                                            closeDetailsTab={() => this.setState({ selectedType: undefined })}
                                        />
                                    )}
                            </>
                        )}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(DeviceTypesSettings);

import React from "react";
import { getDataFromSource, getImageStorageUrl } from "../utilites";
import { FaceRecognitionImg, Row, Text } from "headpoint-react-components";
import { AspectBox } from "../../../../components/DetailsBox";
import FullImageModal from "./FullImageModal";
import styled from "styled-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class Image2Similarity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullImage: false,
        };
    }

    handleFullImageClick(fullImageUrl) {
        if (fullImageUrl) {
            this.setState({ isFullImage: true });
        }
    }

    getFullImageUrlOrNull(media, selectedEvent) {
        let fullImageUrl = null;
        const fullImage = getDataFromSource(media.fullImage, selectedEvent);

        if (fullImage) {
            fullImageUrl = getImageStorageUrl(fullImage, media.storage);
        }

        return fullImageUrl;
    }

    render() {
        const { selectedEvent, media } = this.props;
        const similarity = getDataFromSource(media.similarity, selectedEvent) ?? 0;
        const recognizedImage = getImageStorageUrl(
            getDataFromSource(media.recognizedImage, selectedEvent),
            media.storage
        );

        const referenceImage = getImageStorageUrl(
            getDataFromSource(media.referenceImage, selectedEvent),
            media.storage
        );

        const fullImageUrl = this.getFullImageUrlOrNull(media, selectedEvent);
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        {this.state.isFullImage ? (
                            <FullImageModal
                                imageUrl={fullImageUrl}
                                handleClose={() => this.setState({ isFullImage: false })}
                            />
                        ) : null}

                        <Row align="middle" width="100%" marginBottom={16}>
                            <Row width="40%">
                                <AspectBox aspect="1:1">
                                    <ImageLink
                                        src={recognizedImage}
                                        alt=""
                                        width="1000"
                                        height="1000"
                                        onClick={() => this.handleFullImageClick(fullImageUrl)}
                                        fullImage={fullImageUrl}
                                    />
                                </AspectBox>
                            </Row>

                            <Row justify="center" marginLeft="auto" marginRight="auto" width="30%">
                                <FaceRecognitionImg
                                    imgSrc={referenceImage}
                                    imgSize={400}
                                    progress={similarity}
                                    label={
                                        <Text variant="body" color="currentcolor">
                                            {`${strings("Сходство:")} ${similarity}%`}
                                        </Text>
                                    }
                                />
                            </Row>
                        </Row>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}
const ImageLink = styled.img`
    cursor: ${({ fullImage }) => (fullImage ? "pointer" : "inherit")};
`;

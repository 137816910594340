import React from "react";
import { Table, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { rolePriorities } from "../constants";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class DetailsTabMain extends React.Component {
    render() {
        const { selected } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <colgroup>
                                <col style={{ width: "40%" }} />
                                <col style={{ width: "60%" }} />
                            </colgroup>

                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Приоритет")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {strings(
                                                rolePriorities.find(
                                                    ({ value }) => selected.properties?.priority === value
                                                )?.label
                                            )}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Комментарий")}</TableCell>
                                    <TableCell>
                                        <Text scroll color="primary" variant="body-sm">
                                            {selected.properties?.description}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

export default DetailsTabMain;

import React from "react";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";
import { ModalSection, Row, Text, TextField } from "headpoint-react-components";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import {
    fillNode,
    fillZoneGroups,
    GetAllControlZonesGroups,
    GetControlZones,
    GetZonesIdsByDevice,
    SearchControlZones,
} from "../../../../../../services/controlZones";
import { FilterAccordion } from "../../../../../components/Filters";
import { nanoid } from "nanoid";
import { SelectedCounter } from "../../../../../components/SelectedCounter/SelectedCounter";

class ModalStepControleZones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            searchList: [],
            tagSearch: "",
            tags: [],
            treeId: nanoid(),
            controlZoneGroupsTree: [],
            zones: [],
        };
    }

    async componentDidMount() {
        const { values, strings, SetValuesProperty } = this.props;

        const [zoneGroupsStatus, zoneGroups] = await GetAllControlZonesGroups();
        if (!zoneGroupsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения групп зон"), { variant: "error" });
        }

        let status,
            zonesIds = values?.zones ?? [];

        if (values.id) {
            [status, zonesIds] = await GetZonesIdsByDevice(values.id);
            if (status !== 200) {
                this.props.enqueueSnackbar(strings("Ошибка получения зон"), { variant: "error" });
            }
        }

        SetValuesProperty("zones", zonesIds);
        const tree = fillZoneGroups(zoneGroups, zonesIds);
        this.setState({
            controlZoneGroupsTree: tree ?? [],
        });
    }

    openZoneGroup = async (groupNode) => {
        const { zones, controlZoneGroupsTree } = this.state;
        const { strings } = this.props;

        const [getZonesStatus, newZones] = await GetControlZones(groupNode.id);
        if (!getZonesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения зон"), { variant: "error" });
            return;
        }

        if (groupNode.isClosed) {
            return;
        } else {
            fillNode(groupNode, newZones);
        }

        newZones.forEach((nd) => {
            if (zones.find((d) => d.id === nd.id)) {
                return;
            }
            zones.push(nd);
        });

        this.setState({
            controlZoneGroupsTree,
            zones,
        });
    };

    searchNodes = async (like) => {
        const { strings } = this.props;

        const [zonesStatus, zones] = await SearchControlZones(like);
        if (!zonesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения зон"), { variant: "error" });
            return;
        }

        let nodes = zones?.map((z) => ({ ...z, tag: "zone" })) ?? [];
        this.setState({ search: like, searchList: nodes });
    };

    render() {
        const { treeId, controlZoneGroupsTree, zones, search, searchList } = this.state;
        const { strings, values, SetValuesProperty } = this.props;

        const sources = search ? searchList : controlZoneGroupsTree;

        return (
            <ModalSection>
                <ModalFormItem>
                    <Row justify="between" align="bottom" marginBottom={24}>
                        <Text variant="body-sm">{strings("Зоны контроля")}</Text>
                        <SelectedCounter
                            count={values.zones?.length ?? 0}
                            onClear={() => SetValuesProperty("zones", [])}
                            strings={strings}
                        />
                    </Row>
                    <Row marginBottom={12}>
                        <TextField
                            autoComplete={false}
                            colorVariant="light"
                            fullWidth
                            icon="search"
                            onChange={(e) => this.searchNodes(e.target.value)}
                            placeholder={strings("Найти")}
                            type="search"
                            value={this.state.search}
                        />
                    </Row>
                    <FilterAccordion
                        key={treeId}
                        items={sources}
                        selected={values?.zones ?? []}
                        blockParentCheckbox={true}
                        preOpen={true}
                        selectHandler={(e) =>
                            SetValuesProperty("zones", e?.filter((item) => zones.some((z) => z.id === item)) ?? [])
                        }
                        openItem={(zoneGroup) => this.openZoneGroup(zoneGroup)}
                    />
                </ModalFormItem>
            </ModalSection>
        );
    }
}

export default withCultureContext(withSnackbar(ModalStepControleZones));

import React from "react";
import { CultureContextConsumer, withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import {
    DetailsBox,
    DetailsBoxContent,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../../../../components/DetailsBox";
import {
    Button,
    Dropdown,
    DropdownItem,
    Tab,
    TabPanel,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Text,
} from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import { useDispatch, useSelector } from "react-redux";
import { saveControlZonesState, showEditGroupWizard } from "../../../../../app/reducers/controlZonesReducer";
import { permissionExists } from "../../../../../contexts";

const ControlZonesGroupDetails = ({ selectedGroup, strings }) => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.persistedReducer);

    const DetailsTabMain = ({ selectedGroup }) => (
        <CultureContextConsumer>
            {({ strings }) => (
                <DetailsBoxContent>
                    <Table isFixed noPadding>
                        <TableBody>
                            <TableRow>
                                <TableCell>{strings("Зоны контроля")}</TableCell>
                                <TableCell>
                                    <Text scroll color="primary" variant="body-sm">
                                        {strings(selectedGroup?.children?.length)}
                                    </Text>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{strings("Комментарий")}</TableCell>
                                <TableCell>
                                    <Text scroll color="primary" variant="body-sm">
                                        {selectedGroup.comment}
                                    </Text>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DetailsBoxContent>
            )}
        </CultureContextConsumer>
    );

    return (
        <EventsLayoutSecondary>
            <DetailsBox>
                <DetailsBoxHeader sticky>
                    <DetailsBoxTitle>
                        <Text color="primary" variant="title" component="h2">
                            {strings(selectedGroup.name)}
                        </Text>
                    </DetailsBoxTitle>

                    <DetailsBoxHeaderActions>
                        {permissionExists(userInfo, ["controlZone.lifeCycle"]) ? (
                            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                <DropdownItem
                                    as="button"
                                    variant="secondary"
                                    onClick={() => dispatch(showEditGroupWizard(selectedGroup))}
                                >
                                    {strings("Редактировать")}
                                </DropdownItem>
                                <DropdownItem
                                    as="button"
                                    colorVariant="danger"
                                    onClick={() =>
                                        dispatch(saveControlZonesState({ showDeleteWizard: true, wizardType: "group" }))
                                    }
                                >
                                    {strings("Удалить")}
                                </DropdownItem>
                            </Dropdown>
                        ) : null}

                        <Button
                            icon="close"
                            variant="secondary"
                            onClick={() => dispatch(saveControlZonesState({ selectedGroup: null }))}
                        />
                    </DetailsBoxHeaderActions>
                </DetailsBoxHeader>
                <DetailsBoxTabs>
                    <TabPanel>
                        <Tab label={strings("Общее")} isActive={true} />
                    </TabPanel>
                </DetailsBoxTabs>
                {DetailsTabMain({ selectedGroup })}
            </DetailsBox>
        </EventsLayoutSecondary>
    );
};

export default withCultureContext(ControlZonesGroupDetails);

export const checkNodeName = (node, searchName) => {
    let nodes = [];
    if (node.name.toLowerCase().includes(searchName.toLowerCase())) {
        nodes.push(node);
    }

    if (node.children && node.children.length > 0) {
        node.children.forEach((childNode) => {
            nodes.push(...checkNodeName(childNode, searchName));
        });
    }

    return nodes;
};

export const flatten = (object) => {
    const getChild = (key, objectBit) => {
        return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
    };

    return Object.assign(
        {},
        ...(function _flatten(objectBit) {
            return [].concat(
                ...Object.keys(objectBit).map((key) =>
                    typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                )
            );
        })(object)
    );
};

export const filterTree = (tree, permissions) => {
    const getNodes = (result, object) => {
        if (permissions.includes(object.id)) {
            result.push(object);
            return result;
        }
        if (Array.isArray(object.children)) {
            const children = object.children.reduce(getNodes, []);
            if (children.length) result.push({ ...object, children });
        }
        return result;
    };

    return tree.reduce(getNodes, []);
};

export const updateNodes = (tree, key, value) => {
    let nodesToVisit = [...tree];

    while (nodesToVisit.length > 0) {
        let node = nodesToVisit.pop();

        if (node) {
            node[key] = value;

            if (node.children) {
                nodesToVisit.push(...node.children)
            }
        }
    }

    return tree;
}

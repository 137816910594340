import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { checkSuperUser } from "../../../../../../contexts/GeneralContext";
import { ModalSection, TextField, Row, Select, Checkbox } from "headpoint-react-components";
import ReplacePropertiesModal from "./ReplacePropertiesModal";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

class ModalStepDefault extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newGroupId: null,
        };
    }
    handleInputChange = (e) => this.props.SetValuesProperties({ [e.rawEvent.target.name]: e.rawEvent.target.value });

    render() {
        const { userInfo } = this.props;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        const { values, mode, SetValuesProperties, avalibleGroups } = this.props;
        const disabled = userInfo.userId === values.id;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            {this.state.newGroupId && (
                                <ReplacePropertiesModal
                                    onChoice={(choice) => {
                                        SetValuesProperties({
                                            inheritsGroupProperties: choice,
                                            group: this.state.newGroupId,
                                        });
                                        this.setState({ newGroupId: null });
                                    }}
                                />
                            )}
                            <ModalFormItem>
                                <Row justify="between">
                                    <TextField
                                        id="surname"
                                        label={strings("Фамилия")}
                                        name="surname"
                                        type="text"
                                        value={values.surname}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                        disabled={disabled}
                                    />

                                    <TextField
                                        id="name"
                                        label={strings("Имя")}
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                        disabled={disabled}
                                    />
                                </Row>
                            </ModalFormItem>

                            <ModalFormItem>
                                <Row justify="between">
                                    <TextField
                                        id="patronymic"
                                        label={strings("Отчество")}
                                        name="patronymic"
                                        type="text"
                                        value={values.patronymic}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                        disabled={disabled}
                                    />

                                    <TextField
                                        id="phone"
                                        label={strings("Номер телефона")}
                                        name="phone"
                                        type="tel"
                                        value={values.phone}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                        disabled={disabled}
                                    />
                                </Row>
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="email"
                                    label={strings("Почта")}
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    disabled={disabled}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="title"
                                    label={strings("Должность")}
                                    name="title"
                                    type="text"
                                    value={values.title}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    disabled={disabled}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="comment"
                                    label={strings("Комментарий")}
                                    name="comments"
                                    type="text"
                                    value={values.comments}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    inputProps={{
                                        as: "textarea",
                                        rows: 4,
                                    }}
                                    fullWidth
                                    disabled={disabled}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <Select
                                    label={strings("Группа пользователей")}
                                    placeholder={strings("Выберите группу")}
                                    value={values.group}
                                    onChange={(val) => {
                                        if (values.group !== val) {
                                            if (mode === WIZARD_CREATE_MODE) {
                                                SetValuesProperties({ group: val });
                                            } else {
                                                this.setState({ newGroupId: val });
                                            }
                                        }
                                    }}
                                    options={avalibleGroups?.map((g) => ({ value: g.id, label: g.name })) ?? []}
                                    fullWidth
                                    error={(() => {
                                        if (
                                            !checkSuperUser({ groupId: values.group }) &&
                                            (!values.roleId || values.roleId === EMPTY_UUID) &&
                                            mode === WIZARD_UPDATE_MODE
                                        ) {
                                            return strings("Для смены группы необходимо выбрать роль!");
                                        }

                                        return !values.group
                                            ? strings("Необходимо выбрать группу пользователей")
                                            : null;
                                    })()}
                                    disabled={disabled}
                                />
                            </ModalFormItem>
                            <ModalFormItem>
                                <Checkbox
                                    label={strings("Наследовать права группы")}
                                    checked={values.inheritsGroupProperties}
                                    onChange={(event) =>
                                        SetValuesProperties({ inheritsGroupProperties: event.target.checked })
                                    }
                                />
                            </ModalFormItem>
                        </ModalSection>

                        <ModalSection>
                            <ModalFormItem>
                                <Row justify="between">
                                    <TextField
                                        id="login"
                                        label={strings("Имя пользователя")}
                                        name="login"
                                        type="text"
                                        value={values.login}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                        error={!values.login ? strings("Поле не может быть пустым") : null}
                                        disabled={disabled}
                                    />

                                    <TextField
                                        id="password"
                                        label={strings("Пароль")}
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                        error={
                                            !values.password && mode === WIZARD_CREATE_MODE
                                                ? strings("Поле не может быть пустым")
                                                : null
                                        }
                                        autoComplete="new-password"
                                    />
                                </Row>
                            </ModalFormItem>
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(ModalStepDefault);

import React from "react";
import { withSnackbar } from "notistack";
import { Button, Dropdown, DropdownItem, ExpandableSearch, Pagination, Text } from "headpoint-react-components";
import {
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutPaginationWrapper,
    EventsLayoutPrimary,
    EventsLayoutWrapper,
} from "../../../../layout/EventsLayout";
import { TemplateTable } from "./ScenarioTemplatesTable";
import TemplateDetails from "./ScenarioTemplatesDetails";
import {
    CompareNodes,
    CreateTemplate,
    DeleteTemplate,
    GetFunctions,
    GetTemplates,
    UpdateTemplate,
} from "../../../../services/scenarios";

import { CreateUpdateWizard } from "../../../components/CreateUpdateWizard/CreateUpdateWizard";
import { wizardSteps } from "./constants";
import uuid from "react-uuid";
import { permissionExists } from "../../../../contexts";
import { DeleteModal } from "../DeleteModal";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const initialTemplateValues = {
    // default
    id: uuid(),
    name: "",
    functions: [
        {
            id: uuid(),
            name: "",
            parameters: {},
        },
    ],
};

const COUNT_ON_PAGE = 15;

class ScenarioTemplatesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsNav: undefined,
            scenarioTemplateList: undefined,
            scenarioFunctionsList: undefined,
            selectedTemplate: undefined,
            hasSecondary: undefined,
            templateToDelete: undefined,
            detailsTab: "main",
            search: "",
            showCreateTemplateModal: false,

            step: 1,
            activeStep: 1,
            wizardMode: WIZARD_CREATE_MODE,
            values: undefined,
            isLoading: false,

            pageNumber: 1,
            pagesCount: 1,
            countOnPage: COUNT_ON_PAGE,
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.pageNumber !== prevState.pageNumber || this.state.countOnPage !== prevState.countOnPage) {
            await this.getTemplatesData();
        }
    }

    async componentDidMount() {
        await this.getTemplatesData();
    }

    handlePage = (page) => {
        const { pagesCount } = this.state;

        if (page <= pagesCount) {
            this.setState({ pageNumber: page });
        }
    };

    handleShowMore = async () => {
        const { countOnPage } = this.state;

        this.setState({
            countOnPage: countOnPage + COUNT_ON_PAGE,
            pageNumber: 1,
        });
    };

    async getTemplatesData() {
        const { pageNumber, countOnPage } = this.state;

        let templatesData = await this.getTemplates(pageNumber, countOnPage);
        let functions = await this.getFunctions();

        const offset = Math.min(pageNumber > 3 ? pageNumber - 3 : 0, templatesData.count - 5);

        this.setState({
            scenarioTemplateList: templatesData.templates ?? [],
            scenarioFunctionsList: functions ?? [],
            pagesCount: Math.ceil(templatesData.count / countOnPage),
            offset: offset < 0 ? 0 : offset,
        });
    }

    async getTemplates(pageNumber, countOnPage) {
        const [status, templates] = await GetTemplates({
            skip: (pageNumber - 1) * countOnPage,
            limit: countOnPage,
        });

        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения шаблонов реагирования"), { variant: "error" });
        }

        return templates?.data;
    }

    async getFunctions() {
        const [status, functions] = await GetFunctions();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения шаблонов реагирования"), { variant: "error" });
            return;
        }

        if (!functions?.data?.online) {
            return;
        }

        return Object.entries(functions?.data?.online).map(([k, v]) => {
            v.id = k;
            return v;
        });
    }

    templateFromValues = (values) => {
        let properties = { ...values };
        delete properties.id;
        delete properties.name;
        delete properties.functions;

        return {
            id: values.id,
            name: values.name,
            comment: values.comment,
            functions: values.functions,
        };
    };

    valuesFromTemplate = (template) => {
        return {
            id: template.id,
            name: template.name,
            comment: template.comment,
            functions: template.functions.map((func) => ({
                ...func,
                id: uuid(),
            })),
        };
    };

    showCreateTemplateModal = () =>
        this.setState({
            showCreateTemplateModal: true,
            values: { ...initialTemplateValues },
            wizardMode: WIZARD_CREATE_MODE,
            step: 1,
            activeStep: 1,
        });
    showUpdateTemplateModal = (template) =>
        this.setState({
            showCreateTemplateModal: true,
            wizardMode: WIZARD_UPDATE_MODE,
            values: this.valuesFromTemplate(template),
        });
    showDeleteTemplateModal = (template) =>
        this.setState({ showCreateTemplateModal: true, values: this.valuesFromTemplate(template) });
    closeCreateTemplateModal = () => this.setState({ showCreateTemplateModal: false });

    setSearch = async (search) => {
        this.setState({ search: search });
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    SetValuesProperties = (name, value) => {
        let values = { ...this.state.values, [name]: value };
        this.setState({ values: values });
    };

    CreateTemplateHandler = async () => {
        const { strings } = this.props;

        if (!this.state.values.name) {
            this.props.enqueueSnackbar(strings("Нельзя создать шаблон реагирования без имени"), { variant: "error" });
            return;
        }

        if (this.state.values.functions.some((f) => !f.name || f.name === "")) {
            this.props.enqueueSnackbar(strings("Нельзя создать шаблон реагирования без указанных функций"), {
                variant: "error",
            });
            return;
        }

        this.setState({ isLoading: true });

        try {
            const { values } = this.state;
            let [status, statusCode, newTemplateId] = await CreateTemplate(values);
            if (status) {
                this.props.enqueueSnackbar(`${strings("Шаблон реагирования")} '${values.name}' ${strings("создан")}`, {
                    variant: "success",
                });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для создания шаблона")} '${values.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("Шаблоны реагирования должны иметь уникальные имена"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Не удалось создать шаблон реагирования")}  '${values.name}'`,
                            {
                                variant: "error",
                            }
                        );
                        break;
                }

                this.setState({
                    isLoading: false,
                    activeStep: 1,
                });

                return;
            }

            let template = {
                ...this.templateFromValues(this.state.values),
                id: newTemplateId,
            };

            let templates = [...this.state.scenarioTemplateList, template];

            this.setState({
                isLoading: false,
                showCreateTemplateModal: false,
                activeStep: 1,
                step: 1,
                scenarioTemplateList: templates ?? [],
                values: { ...initialTemplateValues },
            });
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    UpdateTemplateHandler = async () => {
        const { strings } = this.props;

        let template = this.templateFromValues(this.state.values);

        this.setState({ isLoading: true });

        try {
            let [status, statusCode] = await UpdateTemplate(template);
            if (status) {
                this.props.enqueueSnackbar(
                    `${strings("Шаблон реагирования")} '${template.name}' ${strings("успешно изменен")}`,
                    {
                        variant: "success",
                    }
                );
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для изменения шаблона реагирования")} '${template.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("Шаблоны реагирования должны иметь уникальные имена"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Не удалось изменить шаблон реагирования")} '${template.name}'`,
                            {
                                variant: "error",
                            }
                        );
                        break;
                }
                this.setState({
                    isLoading: false,
                    activeStep: 1,
                });

                return;
            }

            let { scenarioTemplateList, values } = this.state;

            const index = scenarioTemplateList.findIndex((template) => template.id === values.id);

            scenarioTemplateList[index] = template;

            let newState = {
                selectedTemplate: status && this.state.selectedTemplate ? template : this.state.selectedTemplate,
                showCreateTemplateModal: false,
                step: 1,
                activeStep: 1,
                values: { ...initialTemplateValues },
                scenarioTemplateList: scenarioTemplateList ?? [],
                isLoading: false,
            };

            this.setState(newState);
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    DeleteTemplateHandler = async () => {
        const { strings } = this.props;
        const { templateToDelete } = this.state;

        let [status, statusCode] = await DeleteTemplate(templateToDelete.id);

        if (status) {
            this.props.enqueueSnackbar(
                `${strings("Шаблон реагирования")} '${templateToDelete.name}' ${strings("удален")}`,
                {
                    variant: "success",
                }
            );
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для удаления шаблона реагирования")} '${templateToDelete.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    return;
                default:
                    this.props.enqueueSnackbar(
                        `${strings("Не удалось удалить шаблон реагирования")} '${templateToDelete.name}'`,
                        {
                            variant: "error",
                        }
                    );
                    return;
            }
        }

        const templates = this.state.scenarioTemplateList.filter((template) => template.id !== templateToDelete.id);
        this.setState({
            scenarioTemplateList: templates ?? [],
            selectedTemplate: undefined,
        });
    };

    deleteTemplate = (template) => this.setState({ showDeleteTemplateModal: true, templateToDelete: template });

    render() {
        const {
            selectedTemplate,
            hasSecondary,
            search,
            values,
            wizardMode,
            step,
            activeStep,
            showCreateTemplateModal,
            scenarioFunctionsList,
            showDeleteTemplateModal,
            scenarioTemplateList,
            isLoading,
            templateToDelete,
            pageNumber,
            pagesCount,
            offset,
        } = this.state;
        const { strings, userInfo } = this.props;

        let templates = scenarioTemplateList?.sort(CompareNodes) ?? [];
        if (search) {
            templates = scenarioTemplateList.filter((et) => et.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
        }

        return (
            <>
                {isLoading && <LoaderAnimation message={strings("Применение настроек")} />}
                <DeleteModal
                    visible={showDeleteTemplateModal}
                    CloseHandler={() => this.setState({ showDeleteTemplateModal: false })}
                    RemoveHandler={this.DeleteTemplateHandler}
                    text={`${strings("Вы хотите удалить шаблон реагирования")} "${templateToDelete?.name}". ${strings(
                        "Удалённый шаблон реагирования нельзя восстановить. Продолжить?"
                    )}`}
                />

                <CreateUpdateWizard
                    visible={showCreateTemplateModal}
                    title={`${wizardMode === WIZARD_CREATE_MODE ? strings("Создать") : strings("Изменить")} ${strings(
                        "шаблон реагирования"
                    )}`}
                    steps={wizardSteps}
                    step={step}
                    activeStep={activeStep}
                    values={values}
                    scenarioFunctionsList={scenarioFunctionsList}
                    NextStep={this.nextStep}
                    SetActiveStep={this.selectStep}
                    SetValuesPropertie={this.SetValuesProperties}
                    CreateHandler={this.CreateTemplateHandler}
                    EditHandler={this.UpdateTemplateHandler}
                    CloseHandler={this.closeCreateTemplateModal}
                    Mode={wizardMode}
                />

                <EventsLayoutPrimary hasSecondary={hasSecondary}>
                    <EventsLayoutHeader>
                        <EventsLayoutHeaderTitle>
                            <Text variant="title" component="h1">
                                {strings("Шаблоны реагирования")}
                            </Text>

                            <ExpandableSearch
                                value={this.state.search}
                                onChange={(e) => this.setSearch(e.target.value)}
                                onSubmit={() => {
                                    /* ignore enter */
                                }}
                                placeholder={strings("Найти")}
                                colorVariant="dark"
                                inHeader
                            />
                        </EventsLayoutHeaderTitle>

                        {permissionExists(userInfo, ["scenarios.service.access", "scenarios.template.lifeCycle"]) ? (
                            <EventsLayoutHeaderToolbox>
                                <Dropdown
                                    toggleLabel={strings("Создать")}
                                    closeOnClickInside
                                    toggleVariant="secondary"
                                    horizontalAlignment="right"
                                >
                                    <DropdownItem as="button" onClick={this.showCreateTemplateModal}>
                                        {strings("Шаблон реагирования")}
                                    </DropdownItem>
                                </Dropdown>
                            </EventsLayoutHeaderToolbox>
                        ) : (
                            <div />
                        )}
                    </EventsLayoutHeader>
                    <EventsLayoutWrapper>
                        <TemplateTable
                            scenarioTemplateList={templates ?? []}
                            setSelectedTemplate={(template) => this.setState({ selectedTemplate: template })}
                            selectedTemplate={selectedTemplate}
                            deleteTemplate={this.deleteTemplate}
                            editTemplate={this.showUpdateTemplateModal}
                        />
                        <EventsLayoutPaginationWrapper>
                            <Button variant="secondary" label={strings("Показать ещё")} onClick={this.handleShowMore} />
                            <Pagination
                                offset={offset}
                                page={pageNumber}
                                count={pagesCount}
                                onChange={this.handlePage}
                            />
                        </EventsLayoutPaginationWrapper>
                    </EventsLayoutWrapper>
                </EventsLayoutPrimary>
                {selectedTemplate && (
                    <TemplateDetails
                        selectedTemplate={selectedTemplate}
                        setDetailsTab={(detailsTab) => this.setState({ detailsTab: detailsTab })}
                        setSelectedTemplate={(template) => this.setState({ selectedTemplate: template })}
                        detailsTab={this.state.detailsTab}
                        deleteTemplate={this.deleteTemplate}
                        editTemplate={this.showUpdateTemplateModal}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(ScenarioTemplatesSettings)));

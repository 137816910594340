import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { FilterAccordionStatic } from "../../../../../components/FiltersStatic";
import { ModalSection, Text, Row, TextField } from "headpoint-react-components";
import { flatten } from "../../../../../../utilites/TreeUtils";
import { getGeneralPermissionsTree } from "../../../../../../contexts";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";
import { SelectedCounter } from "../../../../../components/SelectedCounter/SelectedCounter";

class ModalStepGeneralPermissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleGeneralPermissions: getGeneralPermissionsTree(props.generalInfo),
            search: "",
        };
    }

    setSearch(search) {
        this.setState({
            search: search,
        });
    }

    translateTree(tree) {
        if (!tree) {
            return null;
        }

        const { strings } = this.props;

        return tree.map((t) => {
            return { ...t, name: strings(t.name), children: this.translateTree(t.children) };
        });
    }

    render() {
        const { values, SetValuesProperty, strings } = this.props;
        const { search, roleGeneralPermissions } = this.state;

        let culturedPermissions = this.translateTree(roleGeneralPermissions);

        let searchRoleGeneralPermissions = culturedPermissions;

        if (search) {
            searchRoleGeneralPermissions =
                culturedPermissions
                    ?.map((group) => ({
                        ...group,
                        children: group.children?.filter(
                            (et) => et.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        ),
                    }))
                    .filter((group) => group.children?.length > 0) ?? [];
        }

        let flattenGeneralPermissions = Object.keys(flatten(roleGeneralPermissions));
        let selectedGeneralPermissionsCount = values.permissions.filter((perm) =>
            flattenGeneralPermissions.includes(perm)
        )?.length;

        return (
            <div style={{ maxWidth: "100%" }}>
                <ModalSection>
                    <ModalFormItem>
                        <Row justify="between" align="bottom" marginBottom={24}>
                            <Text variant="body">{strings("Функции системы")}</Text>
                            <SelectedCounter
                                count={selectedGeneralPermissionsCount}
                                onClear={() =>
                                    SetValuesProperty(
                                        "permissions",
                                        values.permissions.filter((perm) => !flattenGeneralPermissions.includes(perm))
                                    )
                                }
                                strings={strings}
                            />
                        </Row>

                        <Row marginBottom={12}>
                            <TextField
                                autoComplete={false}
                                colorVariant="light"
                                fullWidth
                                icon="search"
                                onChange={(e) => this.setSearch(e.target.value)}
                                placeholder={strings("Найти")}
                                type="search"
                                value={this.state.search}
                            />
                        </Row>

                        <FilterAccordionStatic
                            key={!!search}
                            preOpen={!!search}
                            items={searchRoleGeneralPermissions}
                            selected={values?.permissions ?? []}
                            selectHandler={(e) => SetValuesProperty("permissions", e)}
                        />
                    </ModalFormItem>
                </ModalSection>
            </div>
        );
    }
}

export default withCultureContext(ModalStepGeneralPermissions);

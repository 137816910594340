import { ModalSection, Select, TextField } from "headpoint-react-components";
import { useDispatch, useSelector } from "react-redux";
import { saveControlZonesState } from "../../../../../app/reducers/controlZonesReducer";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import React from "react";
import { withSnackbar } from "notistack";

const ModalZoneStepDefault = ({ enqueueSnackbar, strings }) => {
    const dispatch = useDispatch();

    const values = useSelector((state) => state.controlZones.values);
    const groups = useSelector((state) => state.controlZones.groups);

    const saveValues = (name, value) => {
        dispatch(saveControlZonesState({ values: { ...values, [name]: value } }));
    };

    return (
        <ModalSection>
            <ModalFormItem>
                <TextField
                    label={strings("Название зоны")}
                    name="name"
                    type="text"
                    colorVariant="light"
                    fullWidth
                    value={values.name}
                    onChange={(e) => saveValues("name", e.target.value)}
                    error={!values.name ? strings("Поле не может быть пустым") : null}
                />
            </ModalFormItem>

            <ModalFormItem>
                <TextField
                    label={strings("Комментарий")}
                    name="comment"
                    type="text"
                    colorVariant="light"
                    fullWidth
                    inputProps={{
                        as: "textarea",
                        rows: 3,
                    }}
                    value={values.comment}
                    onChange={(e) => saveValues("comment", e.target.value)}
                />
            </ModalFormItem>

            <ModalFormItem>
                <Select
                    label={strings("Группа")}
                    placeholder={strings("Выбрать группу")}
                    value={values?.groupId}
                    options={groups?.map((group) => ({
                        value: group.id,
                        label: group.name,
                    }))}
                    onChange={(id) => saveValues("groupId", id)}
                    colorVariant="default"
                    horizontalAlignment="left"
                    verticalAlignment="bottom"
                    fullWidth
                    error={!values.groupId ? strings("Поле не может быть пустым") : null}
                />
            </ModalFormItem>
        </ModalSection>
    );
};

export default withCultureContext(withSnackbar(ModalZoneStepDefault));

import React from "react";
import { Button, DropdownItem, Tab, TabPanel, Text } from "headpoint-react-components";
import { DetailsTabMain } from "./DetailsTabMain";
import { DetailsTabEventTypes } from "./DetailsTabEventTypes";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../../../components/DetailsBox";
import { getServiceFeaturesByServiceId } from "../../../../contexts/GeneralContext";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import AutoHideDropdown from "./components/AutoHideDropdown";
import CreateEventTypeModal from "./Modals/CreateEventTypeModal";
import DeleteEventTypeModal from "./Modals/DeleteEventTypeModal";
import UpdateEventTypeModal from "./Modals/UpdateEventTypeModal";

export class AdapterDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateEventTypeModal: false,
            showUpdateEventTypeModal: false,
            showDeleteEventTypeModal: false,
        };
    }

    hasCommand(serviceCode, commandCode) {
        return !!this.props.generalInfo?.services
            ?.find((s) => s.code === serviceCode)
            ?.properties?.commands?.list?.find((c) => c.code === commandCode);
    }

    render() {
        const { selectedAdapter, detailsTab, generalInfo } = this.props;

        const serviceFeatures = getServiceFeaturesByServiceId(generalInfo, selectedAdapter.serviceId);
        const featureWithRegistration = serviceFeatures.find((f) => f.registration);

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <EventsLayoutSecondary>
                            <DetailsBox>
                                <DetailsBoxHeader sticky>
                                    <DetailsBoxTitle>
                                        <Text color="primary" variant="title" component="h2">
                                            {strings(selectedAdapter.name)}
                                        </Text>
                                    </DetailsBoxTitle>

                                    <DetailsBoxHeaderActions>
                                        <AutoHideDropdown>
                                            {featureWithRegistration && (
                                                <DropdownItem
                                                    onClick={() => this.props.showConnectModal(featureWithRegistration)}
                                                    as="button"
                                                >
                                                    {strings("Подключить")}
                                                </DropdownItem>
                                            )}
                                            {this.hasCommand(selectedAdapter.serviceCode, "createEventType") && (
                                                <DropdownItem
                                                    as="button"
                                                    onClick={() => this.setState({ showCreateEventTypeModal: true })}
                                                >
                                                    {strings("Создать тип события")}
                                                </DropdownItem>
                                            )}
                                            {this.hasCommand(selectedAdapter.serviceCode, "updateEventType") && (
                                                <DropdownItem
                                                    as="button"
                                                    onClick={() => this.setState({ showUpdateEventTypeModal: true })}
                                                >
                                                    {strings("Редактировать")}
                                                </DropdownItem>
                                            )}
                                            {this.hasCommand(selectedAdapter.serviceCode, "deleteEventType") && (
                                                <DropdownItem
                                                    colorVariant="danger"
                                                    as="button"
                                                    onClick={() => this.setState({ showDeleteEventTypeModal: true })}
                                                >
                                                    {strings("Удалить")}
                                                </DropdownItem>
                                            )}
                                        </AutoHideDropdown>
                                        <Button
                                            icon="close"
                                            variant="secondary"
                                            onClick={() => this.props.closeDetails()}
                                        />
                                    </DetailsBoxHeaderActions>
                                </DetailsBoxHeader>

                                <DetailsBoxTabs>
                                    <TabPanel>
                                        <Tab
                                            label={strings("Общее")}
                                            isActive={detailsTab === "main"}
                                            onClick={() => this.props.setDetailsTab("main")}
                                        />
                                        {selectedAdapter.eventTypes?.length > 0 ? (
                                            <Tab
                                                label={strings("Типы событий")}
                                                isActive={detailsTab === "eventTypes"}
                                                onClick={() => this.props.setDetailsTab("eventTypes")}
                                            />
                                        ) : null}
                                    </TabPanel>
                                </DetailsBoxTabs>
                                {detailsTab === "main" && <DetailsTabMain selectedAdapter={selectedAdapter} />}

                                {detailsTab === "eventTypes" && (
                                    <DetailsTabEventTypes selectedAdapter={selectedAdapter} />
                                )}
                            </DetailsBox>
                        </EventsLayoutSecondary>
                        {this.state.showCreateEventTypeModal && (
                            <CreateEventTypeModal
                                generalInfo={generalInfo}
                                adapter={selectedAdapter}
                                closeHandler={() => this.setState({ showCreateEventTypeModal: false })}
                                strings={strings}
                            />
                        )}
                        {this.state.showDeleteEventTypeModal && (
                            <DeleteEventTypeModal
                                generalInfo={generalInfo}
                                adapter={selectedAdapter}
                                closeHandler={() => this.setState({ showDeleteEventTypeModal: false })}
                                strings={strings}
                            />
                        )}
                        {this.state.showUpdateEventTypeModal && (
                            <UpdateEventTypeModal
                                generalInfo={generalInfo}
                                adapter={selectedAdapter}
                                closeHandler={() => this.setState({ showUpdateEventTypeModal: false })}
                                strings={strings}
                            />
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

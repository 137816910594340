import React from "react";
import { Checkbox, ModalSection, Row, Text, TextField } from "headpoint-react-components";
import { GetDevicesGroups, GetDevicesGroupsByDeviceId } from "../../../../../../services/devicesGroups";
import { withSnackbar } from "notistack";
import { FilterItem } from "../../../../../components/FiltersPartion";
import { nanoid } from "nanoid";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";
import { SelectedCounter } from "../../../../../components/SelectedCounter/SelectedCounter";
import { WIZARD_UPDATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

class ModalStepDeviceGroups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupSearch: "",
            deviceGroupList: [],
        };
    }

    async componentDidMount() {
        const { strings } = this.props;
        const [status, fixedGroups] = await GetDevicesGroups();

        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
            return;
        }

        this.setState({
            deviceGroupList: fixedGroups,
        });

        const { values } = this.props;
        if (this.props.mode === WIZARD_UPDATE_MODE && values.id) {
            const [selectedGroupsStatus, selectedfixedGroups] = await GetDevicesGroupsByDeviceId(values.id);
            if (!selectedGroupsStatus) {
                this.props.enqueueSnackbar(`${strings("Ошибка получения групп устройства")} ${values.id}`, {
                    variant: "error",
                });
                return;
            }

            if (selectedfixedGroups) {
                this.checkSelectedGroups(selectedfixedGroups);
            }
        }
    }

    checkSelectedGroups(selectedGroups) {
        selectedGroups.forEach((g) => {
            let checkedGroups = this.props.values?.fixedGroups ?? [];

            if (!checkedGroups.includes(g.id)) {
                this.setGroups([...checkedGroups, g.id]);
            }
        });
    }

    handleCheckboxChange = (id) => {
        let groups = this.props.values?.fixedGroups ?? [];
        this.setGroups(groups.includes(id) ? groups.filter((item) => item !== id) : [...groups, id]);
    };

    setGroups = (group) => {
        const { SetValuesProperty } = this.props;
        SetValuesProperty("fixedGroups", group);
    };

    searchFilter = (search, data, keyExp) => {
        if (!search) {
            return data;
        }

        return data?.filter((t) => keyExp(t)?.toLowerCase()?.includes(search.toLowerCase()));
    };

    render() {
        const { groupSearch } = this.state;
        const { strings } = this.props;
        const selectedGroups = this.props.values?.fixedGroups ?? [];

        return (
            <ModalSection>
                <ModalFormItem>
                    <Row justify="between" align="bottom" marginBottom={24}>
                        <Text variant="body-sm">{strings("Группы оборудования")}</Text>
                        <SelectedCounter
                            count={selectedGroups?.length ?? 0}
                            onClear={() => {
                                this.setState({ groupSearch: "" });
                                this.setGroups([]);
                            }}
                            strings={strings}
                        />
                    </Row>
                    <Row marginBottom={12}>
                        <TextField
                            autoComplete={false}
                            colorVariant="light"
                            fullWidth
                            icon="search"
                            onChange={(e) => this.setState({ groupSearch: e.target?.value })}
                            placeholder={strings("Найти")}
                            type="search"
                            value={groupSearch}
                        />
                    </Row>
                    {this.searchFilter(
                        groupSearch,
                        this.state.deviceGroupList
                            ?.filter((g) => g.properties.groupType === "fixed")
                            .map((g) => ({ value: g.id, name: g.name })),
                        (g) => {
                            return g.name;
                        }
                    ).map((g) => (
                        <FilterItem key={nanoid()}>
                            <Checkbox
                                label={g.name}
                                checked={selectedGroups.includes(g.value)}
                                onChange={() => this.handleCheckboxChange(g.value)}
                            />
                        </FilterItem>
                    ))}
                </ModalFormItem>
            </ModalSection>
        );
    }
}

export default withCultureContext(withSnackbar(ModalStepDeviceGroups));

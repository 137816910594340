import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { Select } from "headpoint-react-components";

export class ModalFormEnumProperty extends React.Component {
    render() {
        const { itemId, handleAddParameters, description, name, enumValues } = this.props;
        return (
            <ModalFormItem>
                <Select
                    label={description.en}
                    placeholder=""
                    value={this.props.values.functions.find((x) => x.id === itemId).parameters?.[name] ?? ""}
                    onChange={(e) => handleAddParameters(itemId, name, e)}
                    options={enumValues.map((val) => ({
                        value: val,
                        label: description[val],
                    }))}
                    fullWidth
                />
            </ModalFormItem>
        );
    }
}

import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { EventsLayoutHeaderTitle } from "../../../../../layout/EventsLayout";
import { formatGroupDeviceCount } from "../../../../../utilites/TextUtils";
import { ExpandableSearch, Row, Text } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { SearchDevices } from "../../../../../services/devices";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../../CommonTablePagination";
import { GetLocationList, GetLocationNames } from "../../../../../services/locations";
import DeviceLocationList from "../../../../components/DeviceLocationList/DeviceLocationList";

const DEVICES_LIMIT = 100;

class DetailsTabDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            searchList: [],
            devices: [],
            pageData: { start: 0, end: ITEMS_ON_PAGE },
        };
    }

    loadDevices = async () => {
        let [getDevicesStatus, devices] = await SearchDevices({ deviceGroups: [this.props.selected?.id] });
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(this.props.strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        devices = await this.appendLocations(devices);

        this.setState({ devices });
    };

    setSearch = async (like) => {
        const { selected, strings } = this.props;
        let filter = { like, limit: DEVICES_LIMIT, deviceGroups: [selected.id] };
        const [devicesStatus, devices] = await SearchDevices(filter);
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let nodes = devices?.map((d) => ({ ...d, tag: "device" })) ?? [];

        nodes = await this.appendLocations(nodes);

        this.setState({ search: like, searchList: nodes });
    };

    getFullLocationName = (location, parentIdsMap) => {
        const rootLocationId = location.parentIds[1] ?? undefined;

        if (rootLocationId) {
            const parentLength = location.parentIds.length;

            if (parentLength === 2) {
                return `${parentIdsMap.get(rootLocationId)} / ${location.name}`;
            } else if (parentLength >= 3) {
                return `${parentIdsMap.get(rootLocationId)} / ... / ${location.name}`;
            }
        }

        return location.name;
    };

    appendLocations = async (devices) => {
        const { enqueueSnackbar, strings } = this.props;

        const deviceLocationsIds = devices.map((d) => d.locationId);

        let [locationsStatus, locations] = await GetLocationList({ ids: deviceLocationsIds });
        if (!locationsStatus) {
            enqueueSnackbar(strings("Ошибка при получении локаций"), { variant: "error" });
            return;
        }

        const parentIds = new Set();

        locations.forEach((location) => {
            const rootLocationId = location.parentIds[1];
            if (rootLocationId) {
                parentIds.add(rootLocationId);
            }
        });

        const [getNamesStatus, parentLocationIdNamePairs] = await GetLocationNames(parentIds);
        if (!getNamesStatus) {
            enqueueSnackbar(strings("Ошибка при получении названий локаций"), { variant: "error" });
            return;
        }

        return devices.map((device) => {
            const deviceLocation = locations.find((loc) => loc.id === device.locationId);

            return {
                ...device,
                location: deviceLocation,
                fullLocationName: this.getFullLocationName(deviceLocation, parentLocationIdNamePairs),
            };
        });
    };

    getHeaderText = () => {
        const { selected, strings } = this.props;
        const count = selected?.count ?? 0;
        return `${strings("В группе")} ${count} ${strings(formatGroupDeviceCount(count))}`;
    };

    componentDidMount = async () => {
        await this.loadDevices();
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.selected?.id !== this.props.selected?.id) {
            await this.loadDevices();
        }
    };

    render() {
        const { strings } = this.props;
        const { search, searchList, pageData, devices } = this.state;

        const devicesToPrint = search ? searchList : devices?.slice(pageData.start, pageData.end);

        return (
            <DetailsBoxContent
                style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}
            >
                <Row marginTop={24} marginBottom={24} justify="between" align="middle">
                    <EventsLayoutHeaderTitle>
                        <Text color="primary" variant="body">
                            {this.getHeaderText()}
                        </Text>
                        <ExpandableSearch
                            value={search}
                            onChange={(e) => this.setSearch(e.target.value)}
                            onSubmit={() => {}}
                            placeholder={strings("Найти")}
                            colorVariant="light"
                            inHeader
                        />
                    </EventsLayoutHeaderTitle>
                </Row>
                <DeviceLocationList devices={devicesToPrint} />
                <CommonTablePagination
                    tableData={devices}
                    setPaging={(pageData) => this.setState({ pageData })}
                    searchMode={search !== ""}
                />
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabDevices));

import React from "react";
import { Button, Text } from "headpoint-react-components";
import { LeftSideTooltip } from "./LeftSideTooltip";
import styled from "styled-components";
import { GeneralContextConsumer, getDeviceTypes } from "../../../contexts";
import { getEquipmentType } from "../../../contexts/GeneralContext";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { withRouter } from "react-router-dom";

class LocationDeviceInfoTooltip extends React.Component {
    locationType = () => {
        const { strings, device } = this.props;

        return (
            <>
                <Text color="grey" variant="body-sm">
                    {strings("Локация")}
                </Text>
                <Text color="black" variant="body-sm">
                    {device?.location ?? "..."}
                </Text>
            </>
        );
    };

    deviceType = () => {
        const { strings } = this.props;
        return (
            <GeneralContextConsumer>
                {(generalInfo) => {
                    const deviceType = getDeviceTypes(generalInfo).find(
                        (type) => type.value === this.props.device?.typeId
                    );
                    return (
                        <>
                            <Text color="grey" variant="body-sm">
                                {strings("Тип устройства")}
                            </Text>
                            <Text color="black" variant="body-sm">
                                {strings(deviceType?.label ?? "...")}
                            </Text>
                        </>
                    );
                }}
            </GeneralContextConsumer>
        );
    };

    equipmentType = () => {
        const { strings } = this.props;
        return (
            <GeneralContextConsumer>
                {(generalInfo) => {
                    return (
                        <>
                            <Text color="grey" variant="body-sm">
                                {strings("Тип оборудования")}
                            </Text>
                            <Text color="black" variant="body-sm">
                                {strings(getEquipmentType(generalInfo, this.props.device))}
                            </Text>
                        </>
                    );
                }}
            </GeneralContextConsumer>
        );
    };

    cardButton = () => {
        const { strings } = this.props;
        return (
            <TooltipButoon>
                <Button onClick={() => this.toDevices()} label={strings("Карточка устройства")} />
            </TooltipButoon>
        );
    };

    toDevices() {
        const { device } = this.props;

        this.props.history.push({
            pathname: "/settings",
            state: {
                partition: "/devices",
                type: "SelectDevice",
                device: device,
            },
        });
    }

    tooltip = () => {
        return (
            <TooltipBodyStyled>
                {this.locationType()}
                {this.equipmentType()}
                {this.deviceType()}
                {this.cardButton()}
            </TooltipBodyStyled>
        );
    };

    render() {
        return <LeftSideTooltip tooltipBody={this.tooltip()}>{this.props.children}</LeftSideTooltip>;
    }
}

export default withCultureContext(withRouter(LocationDeviceInfoTooltip));

const TooltipBodyStyled = styled.div`
    display: grid;
    column-gap: 20px;
    row-gap: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    padding: 12px 16px;
    max-width: 400px;
`;

const TooltipButoon = styled.div`
    grid-column-end: span 2;
    margin: 0 auto;
`;

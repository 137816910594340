const path = "/priorities-api/priorities";

export const Allow = async (facility, entityId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/allow/${facility}/${entityId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return [response.status === 200, response.status];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const Reset = async (facility, entityId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/reset/${facility}/${entityId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return [response.status === 200, response.status];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

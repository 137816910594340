import {
    closeWizard,
    createControlZone,
    createControlZoneGroup,
    editControlZone,
    editControlZoneGroup,
    saveControlZonesState,
} from "../../../../../app/reducers/controlZonesReducer";
import { CreateUpdateWizard } from "../../../../components/CreateUpdateWizard/CreateUpdateWizard";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { withSnackbar } from "notistack";
import { WIZARD_CREATE_MODE } from "../../../../components/CreateUpdateWizard/constants";

const ControlZonesCreateUpdateWizard = ({ strings, enqueueSnackbar, visible }) => {
    const { wizardMode, wizardType, steps, step, activeStep, values } = useSelector((state) => state.controlZones);

    const dispatch = useDispatch();

    const nextStep = () => {
        dispatch(saveControlZonesState({ step: step + 1, activeStep: step + 1 }));
    };

    const getGroupFromValues = () => {
        return {
            id: values.id,
            name: values.name,
            comment: values.comment,
        };
    };

    const getZoneFromValues = () => {
        return {
            id: values.id,
            name: values.name,
            comment: values.comment,
            deviceIds: values.deviceIds,
            groupId: values.groupId,
        };
    };

    const createGroupHandler = async () => {
        let group = getGroupFromValues();

        if (!group.name) {
            enqueueSnackbar(strings("Нельзя создать группу без имени"), { variant: "error" });
            return;
        }

        dispatch(createControlZoneGroup({ group, enqueueSnackbar, strings }));
    };

    const editGroupHandler = async () => {
        let group = getGroupFromValues();

        if (!group.name) {
            enqueueSnackbar(strings("Нельзя создать группу без имени"), { variant: "error" });
            return;
        }

        dispatch(editControlZoneGroup({ group, enqueueSnackbar, strings }));
    };

    const createZoneHandler = async () => {
        let zone = getZoneFromValues();

        if (!zone.name) {
            enqueueSnackbar(strings("Нельзя создать зону без имени"), { variant: "error" });
            return;
        }

        dispatch(createControlZone({ zone, enqueueSnackbar, strings }));
    };

    const editZoneHandler = async () => {
        let zone = getZoneFromValues();

        if (!zone.name) {
            enqueueSnackbar(strings("Нельзя создать зону без имени"), { variant: "error" });
            return;
        }

        dispatch(editControlZone({ zone, enqueueSnackbar, strings }));
    };

    return (
        <CreateUpdateWizard
            visible={visible}
            title={`${wizardMode === WIZARD_CREATE_MODE ? strings("Создать") : strings("Редактировать")} ${
                wizardType === "group" ? strings(" группу") : strings(" зону")
            }`}
            steps={steps}
            step={step}
            activeStep={activeStep}
            NextStep={nextStep}
            SetActiveStep={(payload) => dispatch(saveControlZonesState({ activeStep: payload }))}
            CreateHandler={wizardType === "group" ? createGroupHandler : createZoneHandler}
            EditHandler={wizardType === "group" ? editGroupHandler : editZoneHandler}
            CloseHandler={() => dispatch(closeWizard())}
            Mode={wizardMode}
            values={values}
        />
    );
};

export default withCultureContext(withSnackbar(ControlZonesCreateUpdateWizard));

export const datepickerStyle = `
  .DayPicker {
    display: inline-block;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .DayPicker-wrapper:focus {
    outline: none;
  }

  .DayPicker-Months {
    display: flex;
    justify-content: center;
  }

  .DayPicker-Month {
    position: relative;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  .DayPicker-Month + .DayPicker-Month {
    margin-left: 4px;
  }

  .DayPicker-NavBar {
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 1;
  }

  .DayPicker-NavButton {
    display: inline-block;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: opacity 0.16s linear;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.6;
  }

  .DayPicker-NavButton:focus {
    outline: none;
  }

  .DayPicker-NavButton:before {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .DayPicker-NavButton--prev:before {
    content: "\\e906";
  }

  .DayPicker-NavButton--next:before {
    content: "\\e908";
  }

  .DayPicker-NavButton + .DayPicker-NavButton {
    margin-left: 16px;
  }

  .DayPicker-NavButton--interactionDisabled {
    opacity: 0.25;
  }

  .DayPicker-Caption {
    display: table-caption;
    text-align: left;
    margin-bottom: 4px;
    padding: 0 6px;
  }

  .DayPicker-Caption > div {
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
    padding-bottom: 4px;
    text-transform: uppercase;
  }

  .DayPicker-Weekday:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 1px;
    background-color: rgba(13, 22, 34, 0.1);
  }

  .DayPicker-Weekday:first-child:after {
    left: 6px;
  }

  .DayPicker-Weekday:last-child:after {
    right: 6px;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    width: 42px;
    min-width: 42px;
    height: 42px;
    font-size: 14px;
    line-height: 16px;
    transition: background-color 0.16s linear, color 0.16s linear;
  }

  .DayPicker-Day:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: transparent;
    border: 6px solid #FFFFFF;
    pointer-events: none;
  }

  .DayPicker-Day:before {
    content: "";
    position: absolute;
    top: 6px;
    left: -6px;
    bottom: 6px;
    z-index: 2;
    width: 12px;
    pointer-events: none;
    transition: background-color 0.16s linear;
  }

  .DayPicker-Day:focus {
    outline: none;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  /* Default modifiers */

  .DayPicker-Day--outside {
    cursor: default;
  }

  .DayPicker-Day--disabled {
    cursor: default;
    pointer-events: none;
  }

  /* Example modifiers */

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
  }
`

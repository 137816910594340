function createPath(externalId, baseRequest) {
    // streamer url
    return `/balancer/dash-${externalId}/${baseRequest}/index.mpd`;
}

export function Base64EncodeUrl(str) {
    return window.btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, ".");
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function getDashUrl(url, deviceId, externalId) {
    const token = window.localStorage.getItem("token");
    const maxAttemps = 15;

    const request = {
        uri: url,
        segDuration: 2,
        targetLatency: 0,
        audioCodec: "aac",
        videoCodec: "copy",
        externalId: deviceId,
    };

    const baseRequest = Base64EncodeUrl(JSON.stringify(request));

    const path = createPath(externalId, baseRequest);

    for (let i = 0; i < maxAttemps; i++) {
        let response = await fetch(path, {
            method: "GET",
            headers: new Headers({
                Authorization: "Bearer " + token,
            }),
        });

        if (response.status === 401) {
            return [false, null, "unauthorized"];
        }

        if (response.status === 200) {
            return [true, path];
        }

        await sleep(1000);
    }

    return [false];
}

import React from "react";
import {
    DetailsBox,
    DetailsBoxContent,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
    DetailsBoxSubtitle,
} from "../../../components/DetailsBox";
import {
    Button,
    Row,
    Tab,
    TabPanel,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Text,
    DateRangePicker,
    Icon, Dropdown, DropdownItem
} from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import { useDispatch, useSelector } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { withSnackbar } from "notistack";
import { fetchRow, saveReportState, saveDates, fetchBoundaries } from "../../../../app/reducers/reportsReducer";
import { useEffect } from "react";
import { nanoid } from "nanoid";
import TableListBlock from "./TableListBlock";
import { getTimeRange } from "../../../../utilites/TimeUtils";
import Chart from "./Chart";
import moment from "moment";

const RowDetails = ({ selectedRow, selectedPlugin, setValue, strings, enqueueSnackbar, downloadCardReport }) => {
    const dispatch = useDispatch();

    const detailsTab = useSelector((state) => state.reports.detailsTab);
    const rowInfo = useSelector((state) => state.reports.rowInfo);
    const dates = useSelector((state) => state.reports.dates);

    const tabSets = selectedPlugin?.details?.sections ?? [];
    const details = selectedRow.concat(rowInfo ?? []);

    useEffect(() => {
        const rowId = selectedRow.find((d) => d.id === "id")?.value;
        if (rowId) {
            dispatch(
                fetchBoundaries({
                    id: selectedPlugin.id,
                    serviceCode: selectedPlugin.service,
                    item: rowId,
                    enqueueSnackbar,
                    strings,
                })
            );
        }
    }, [selectedPlugin, selectedRow, dispatch, enqueueSnackbar, strings]);

    useEffect(() => {
        const rowId = selectedRow.find((d) => d.id === "id")?.value;
        if (rowId) {
            dispatch(
                fetchRow({
                    id: selectedPlugin.id,
                    serviceCode: selectedPlugin.service,
                    item: rowId,
                    dates: dates,
                    enqueueSnackbar,
                    strings,
                })
            );
        }
        const tabs = selectedPlugin?.details?.sections ?? [];
        if (tabs.length > 0) {
            dispatch(saveReportState({ detailsTab: tabs[0].key }));
        }
    }, [selectedPlugin, dates, selectedRow, dispatch, enqueueSnackbar, strings]);

    const getValueFromSource = (source, details) => {
        return details.find((d) => d.id === source);
    };

    const handleDateRangePreset = (preset) => {
        const newDates = getTimeRange(preset);
        dispatch(saveDates(newDates));
    };

    const title = getValueFromSource(selectedPlugin?.details?.title, details);
    const subtitle = getValueFromSource(selectedPlugin?.details?.subtitle, details);

    const isService = selectedPlugin?.service;
    const selectedId = selectedRow?.find(r => r?.id === "id")?.value

    const DetailsTabDynamic = (tab) => (
        <Table isFixed noPadding key={nanoid()}>
            <TableBody>
                {tab?.fields?.map((f) => (
                    <React.Fragment key={nanoid()}>
                        {(() => {
                            switch (f.type) {
                                case "list-table":
                                    return <TableListBlock getValue={getValueFromSource} data={f} details={details} />;
                                case "chart":
                                    const rowId = selectedRow.find((d) => d.id === "id")?.value;
                                    return (
                                        <TableRow key={nanoid()}>
                                            <Chart
                                                selectedPlugin={selectedPlugin}
                                                rowId={rowId}
                                                dates={dates}
                                                sections={f.sections}
                                                setDates={(d) => dispatch(saveDates(d))}
                                            />
                                        </TableRow>
                                    );
                                default:
                                    return (
                                        <TableRow key={nanoid()}>
                                            <TableCell style={{ whiteSpace: "normal" }}>{strings(f.name)}</TableCell>
                                            <TableCell>
                                                <Text variant="body-sm">
                                                    {(() => {
                                                        let value = getValueFromSource(f.source, details);
                                                        switch (f.type) {
                                                            case "string":
                                                                return (
                                                                    <Text
                                                                        color={
                                                                            value?.properties?.color?.toLowerCase() ??
                                                                            "primary"
                                                                        }
                                                                    >
                                                                        {strings(value?.value ?? "")}
                                                                    </Text>
                                                                );
                                                            case "datetime":
                                                                return (
                                                                    <Text
                                                                        color={
                                                                            value?.properties?.color?.toLowerCase() ??
                                                                            "primary"
                                                                        }
                                                                    >
                                                                        {moment
                                                                            .unix(value?.value)
                                                                            .format(
                                                                                f?.format?.value ??
                                                                                    "DD.MM.YYYY HH:mm:ss"
                                                                            )}
                                                                    </Text>
                                                                );
                                                            default:
                                                                return strings(value?.value ?? "");
                                                        }
                                                    })()}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    );
                            }
                        })()}
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );

    return (
        <EventsLayoutSecondary>
            <DetailsBox>
                <DetailsBoxHeader sticky>
                    <DetailsBoxTitle>
                        <Text
                            color={title?.properties?.color?.toLowerCase() ?? "primary"}
                            variant="title"
                            component="h2"
                        >
                            {title?.value}
                        </Text>
                        <DetailsBoxSubtitle>
                            <Text variant="description" color="secondary">
                                {subtitle?.value}
                            </Text>
                        </DetailsBoxSubtitle>
                    </DetailsBoxTitle>

                    <DetailsBoxHeaderActions>
                        <Button
                            icon="refresh"
                            variant="secondary"
                            onClick={() =>
                                dispatch(
                                    fetchBoundaries({
                                        id: selectedPlugin.id,
                                        serviceCode: selectedPlugin.service,
                                        item: selectedRow.find((d) => d.id === "id")?.value,
                                        enqueueSnackbar,
                                        strings,
                                    })
                                )
                            }
                        />
                        {isService !== null ? (
                            <Dropdown
                                styleIcon="download"
                                closeOnClickInside
                                toggleVariant="secondary"
                                horizontalAlignment="right"
                            >
                                {selectedPlugin?.cardDownloads?.map((d, i) => {
                                    return (
                                        <DropdownItem key={i} as="button" onClick={() => downloadCardReport(d.type, selectedId, dates)}>
                                            {strings(d?.name)}
                                        </DropdownItem>
                                    );
                                })}
                            </Dropdown>
                        ) : (
                            <Button icon="download" variant="secondary" />
                        )}
                        <Button
                            icon="close"
                            variant="secondary"
                            onClick={() => {
                                setValue("selectedRow", null);
                            }}
                        />
                    </DetailsBoxHeaderActions>
                </DetailsBoxHeader>
                <Row>
                    <DetailsBoxTabs>
                        <TabPanel>
                            {tabSets.map((tab) => (
                                <Tab
                                    key={tab.key}
                                    label={strings(tab.name)}
                                    isActive={detailsTab === tab.key}
                                    onClick={() => dispatch(saveReportState({ detailsTab: tab.key }))}
                                />
                            ))}
                        </TabPanel>
                    </DetailsBoxTabs>
                    <DateRangePicker
                        dates={dates}
                        onChange={(d) => dispatch(saveDates(d))}
                        placeholder={strings("Выбрать период")}
                        colorVariant="transparent"
                        inputFormat="D.MM.YYYY HH:mm"
                        presets={[
                            {
                                label: strings("Последний час"),
                                handler: () => handleDateRangePreset("lastHour"),
                            },
                            {
                                label: strings("Последние 6 часов"),
                                handler: () => handleDateRangePreset("lastSixHours"),
                            },
                            {
                                label: strings("Последние сутки"),
                                handler: () => handleDateRangePreset("lastDay"),
                            },
                        ]}
                        withTime
                        customInputLabels={{
                            hours: strings("Часы"),
                            minutes: strings("Минуты"),
                        }}
                        customIcon={<Icon icon="calendar-clear" />}
                        events={[]}
                        calendarProps={{
                            disabledDays: { after: new Date() },
                        }}
                        horizontalAlignment={"right"}
                    />
                </Row>
                <DetailsBoxContent>
                    {tabSets.map((tab) =>
                        detailsTab === tab.key ? DetailsTabDynamic(tab) : <div key={nanoid()}></div>
                    )}
                </DetailsBoxContent>
            </DetailsBox>
        </EventsLayoutSecondary>
    );
};

export default withCultureContext(withSnackbar(RowDetails));

import { ModalFormEnumProperty } from "./properties/ModalFormEnumProperty";
import { ModalFormStringProperty } from "./properties/ModalFormStringProperty";
import { ModalFormArrayStringProperty } from "./properties/ModalFormArrayStringProperty";
import { ModalFormTextAreaProperty } from "./properties/ModalFormTextAreaProperty";
import ModalFormUsersGroupsTreeProperty from "./properties/ModalFormUsersGroupsTreeProperty";
import ModalFormUsersTreeProperty from "./properties/ModalFormUsersTreeProperty";
import ModalFormRolesTreeProperty from "./properties/ModalFormRolesTreeProperty";

export const FunctionComponents = {
    string: ModalFormStringProperty,
    textarea: ModalFormTextAreaProperty,
    enum: ModalFormEnumProperty,
    "tree.usersGroups": ModalFormUsersGroupsTreeProperty,
    "tree.users": ModalFormUsersTreeProperty,
    "tree.roles": ModalFormRolesTreeProperty,
    "array.phone": ModalFormArrayStringProperty,
    "array.email": ModalFormArrayStringProperty,
    double: ModalFormStringProperty,
};

export const TextFieldType = {
    textarea: "text",
    string: "text",
    double: "number",
    "array.phone": "tel",
    "array.email": "email",
};

import React from "react";
import {
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Button,
    TableHoverContainer,
} from "headpoint-react-components";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { GeneralContextConsumer } from "../../../../contexts";
import { fillAdapter } from "../../../../contexts/GeneralContext";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class ServicesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedService: null,
        };
    }

    render() {
        const { selectedService, select } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table hasHover stickyHeader isFixed bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Сервис")}</TableHeadCell>
                                    <TableHeadCell>{strings("Количество экземпляров")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <GeneralContextConsumer>
                                    {(generalInfo) => {
                                        let services =
                                            generalInfo?.services?.sort((s1, s2) => {
                                                if (s1?.serviceName > s2?.serviceName) {
                                                    return 1;
                                                }

                                                if (s1?.serviceName < s2?.serviceName) {
                                                    return -1;
                                                }

                                                return 0;
                                            }) ?? [];

                                        return services.map((service) => {
                                            fillAdapter(service);
                                            return (
                                                <TableRow
                                                    key={service?.serviceId}
                                                    isSelected={
                                                        selectedService &&
                                                        service?.serviceId === selectedService?.serviceId
                                                    }
                                                >
                                                    <TableCell verticalAlign="middle">
                                                        <Text color={"white"} variant="body-sm">
                                                            {service?.serviceName ?? "unknown service"}
                                                        </Text>
                                                        <br />
                                                        <Text color="secondary" variant="description">
                                                            {service?.code ?? ""}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell verticalAlign="middle">
                                                        <Text color={"white"} variant="body-sm">
                                                            {service?.instances ?? 0}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell verticalAlign="middle">
                                                        <TableHoverContainer>
                                                            <Button
                                                                icon="info"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    select(service);
                                                                }}
                                                                title="Info"
                                                                variant="ghost"
                                                            />
                                                        </TableHoverContainer>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        });
                                    }}
                                </GeneralContextConsumer>
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

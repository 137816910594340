import DetailsTabDevices from "./DetailsTabDevices";
import DetailsTabMain from "./DetailsTabMain";
import DeviceGroupDetails from "./DeviceGroupDetails";

class FixedGroupsDetails extends DeviceGroupDetails {
    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
        };

        this.tabSets = [
            {
                title: "Общее",
                key: "main",
                component: DetailsTabMain,
            },
            {
                title: "Оборудование",
                key: "devices",
                component: DetailsTabDevices,
            },
        ];
    }
}

export default FixedGroupsDetails;

const path = "/view-zones-api";

export const GetViewZone = async (cameraId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/finalDirection/${cameraId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();

        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

export const AddViewZoneDelta = async (cameraId, diff, userId) => {
    const token = window.localStorage.getItem("token");
    try {
        let data = { diff };
        if (userId) {
            data = { ...data, userId };
        }

        let response = await fetch(`${path}/viewZones/${cameraId}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        return [response.ok, response.status];
    } catch (e) {
        console.error(e);
        return [false, null];
    }
};

import React, { useCallback, useState } from "react";
import { ExecuteAdapterCommand } from "../../../../../services/featureCommands";
import {
    Button,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Select,
} from "headpoint-react-components";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { withSnackbar } from "notistack";
import { getCommand } from "../../../../../contexts/GeneralContext";

function DeleteEventTypeModal({ generalInfo, adapter, closeHandler, strings, enqueueSnackbar }) {
    const [eventTypeToDelete, setEventTypeToDelete] = useState(null);

    const deleteEventType = useCallback(
        async (eventTypeToDelete) => {
            const deleteCommand = getCommand(generalInfo, adapter?.serviceCode, "deleteEventType");

            if (!deleteCommand || !adapter) {
                return;
            }

            const [success] = await ExecuteAdapterCommand(
                deleteCommand?.method,
                adapter?.serviceCode,
                deleteCommand?.path,
                {
                    code: eventTypeToDelete.code,
                }
            );

            if (!success) {
                enqueueSnackbar(strings(`${strings("Не удалось удалить тип события")} '${eventTypeToDelete.name}'`), {
                    variant: "error",
                });
                return;
            }

            enqueueSnackbar(strings(`${strings("Тип события")} '${eventTypeToDelete.name}' ${strings("удален")}`), {
                variant: "success",
            });
            closeHandler();
        },
        [generalInfo, adapter, closeHandler, enqueueSnackbar, strings]
    );

    const getAvailableEventTypes = useCallback(() => {
        return (
            adapter?.eventTypes
                ?.filter((eventType) => !eventType.readonly)
                ?.map((eventType) => ({ value: eventType.code, label: eventType.name })) ?? []
        );
    }, [adapter?.eventTypes]);

    return (
        <Modal size="sm" closeHandler={closeHandler}>
            <ModalHeader title={strings("Удалить тип события")} align="center" closeHandler={closeHandler} />

            <ModalBody>
                <ModalBodyItem>
                    <ModalFormItem>
                        <Select
                            label={strings("Тип события")}
                            placeholder={strings("Выбрать тип события")}
                            value={eventTypeToDelete?.code}
                            options={getAvailableEventTypes()}
                            onChange={(code) => {
                                const selectedEventTypeToDelete = adapter?.eventTypes?.find(
                                    (eventType) => eventType.code === code
                                );
                                setEventTypeToDelete(selectedEventTypeToDelete);
                            }}
                            colorVariant="default"
                            horizontalAlignment="left"
                            verticalAlignment="bottom"
                            fullWidth
                        />
                    </ModalFormItem>
                </ModalBodyItem>
            </ModalBody>
            <ModalFooter>
                <ModalFooterItem>
                    <Button
                        type="button"
                        variant="primary"
                        label={strings("Удалить")}
                        onClick={() => deleteEventType(eventTypeToDelete)}
                        disabled={!eventTypeToDelete}
                    />
                </ModalFooterItem>
            </ModalFooter>
        </Modal>
    );
}

export default withSnackbar(DeleteEventTypeModal);

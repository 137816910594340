import React, { useCallback, useEffect, useState } from "react";
import { ExecuteAdapterCommand } from "../../../../../services/featureCommands";
import {
    Button,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    TextField,
} from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { getCommand } from "../../../../../contexts/GeneralContext";
import { isNullOrWhiteSpace } from "./stringUtils";

function CreateEventTypeModal({ generalInfo, adapter, closeHandler, strings, enqueueSnackbar }) {
    const [name, setName] = useState("");
    const [code, setCode] = useState("");

    useEffect(() => {
        const loadTypeCode = async () => {
            const typeCommand = getCommand(generalInfo, adapter?.serviceCode, "getTypeCode");

            if (!typeCommand || !adapter) {
                return;
            }

            const [success, , response] = await ExecuteAdapterCommand(
                typeCommand?.method,
                adapter?.serviceCode,
                typeCommand?.path,
                {}
            );

            if (!success) {
                enqueueSnackbar(strings("Не удалось получить новый код события"), { variant: "error" });
                return;
            }

            setCode(response.code);
        };

        loadTypeCode();
    }, [generalInfo, adapter, enqueueSnackbar, strings]);

    const validate = useCallback(
        (eventType) => {
            if (isNullOrWhiteSpace(eventType.code)) {
                enqueueSnackbar(strings("Нельзя создать тип события без идентификатора"), { variant: "error" });
                return false;
            }

            if (isNullOrWhiteSpace(eventType.name)) {
                enqueueSnackbar(strings("Нельзя создать тип события без имени"), { variant: "error" });
                return false;
            }

            return true;
        },
        [enqueueSnackbar, strings]
    );

    const createEventType = useCallback(
        async (eventType) => {
            if (!validate(eventType)) {
                return;
            }

            const createCommand = getCommand(generalInfo, adapter?.serviceCode, "createEventType");

            const [success] = await ExecuteAdapterCommand(
                createCommand?.method,
                adapter?.serviceCode,
                createCommand?.path,
                {
                    code: eventType.code,
                    name: eventType.name,
                    severity: "info",
                }
            );

            if (!success) {
                enqueueSnackbar(strings("Не удалось создать тип события"), { variant: "error" });
                return;
            }

            enqueueSnackbar(strings(`${strings("Тип события")} '${eventType.name}' ${strings("создан")}`), {
                variant: "success",
            });
            closeHandler();
        },
        [validate, generalInfo, adapter?.serviceCode, enqueueSnackbar, strings, closeHandler]
    );

    return (
        <Modal size="sm" closeHandler={closeHandler}>
            <ModalHeader title={strings("Создать тип события")} align="center" closeHandler={closeHandler} />

            <ModalBody>
                <ModalBodyItem>
                    <ModalFormItem>
                        <TextField
                            label={strings("Идентификатор события")}
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            colorVariant="light"
                            fullWidth
                            error={!isNullOrWhiteSpace(code) ? null : strings("Поле не может быть пустым")}
                        />
                    </ModalFormItem>
                    <ModalFormItem>
                        <TextField
                            label={strings("Название типа события")}
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            colorVariant="light"
                            fullWidth
                            error={!isNullOrWhiteSpace(name) ? null : strings("Поле не может быть пустым")}
                        />
                    </ModalFormItem>
                </ModalBodyItem>
            </ModalBody>
            <ModalFooter>
                <ModalFooterItem>
                    <Button
                        type="button"
                        variant="primary"
                        label={strings("Создать")}
                        onClick={() => createEventType({ code, name })}
                    />
                </ModalFooterItem>
            </ModalFooter>
        </Modal>
    );
}

export default withSnackbar(CreateEventTypeModal);

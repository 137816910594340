import React from "react";
import { withSnackbar } from "notistack";
import {
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
    EventsLayoutPrimary,
    EventsLayoutPrimaryInner,
} from "../../../../layout/EventsLayout";
import { Dropdown, DropdownItem, ExpandableSearch, Text } from "headpoint-react-components";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../CommonTablePagination";
import { getSystemVariableValue, permissionExists, withGeneralContext } from "../../../../contexts/GeneralContext";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { RuleTable } from "./RuleTable";
import { CreateRule, DeleteRule, GetRules } from "../../../../services/rtspQualityControl";
import { GetDeviceInfo } from "../../../../services/devices";
import { CreateUpdateWizard } from "../../../components/CreateUpdateWizard/CreateUpdateWizard";
import { wizardSteps } from "./constants";
import RuleDetails from "./Details/RuleDetails";
import { DeleteModal } from "../DeleteModal";
import { UpdateRule } from "../../../../services/rtspQualityControl";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const ruleInitialValues = {
    name: "",
    comment: "",
    permanent: false,
    createEvent: false,
    bitrateWarningViolation: 10,
    bitrateCriticalViolation: 20,
    fpsWarningViolation: 10,
    fpsCriticalViolation: 20,
    packetLossWarningViolation: 10,
    packetLossCriticalViolation: 20,
    analyseDurationSec: 10,
    startInterval: 10,
};

class RtspQualityControlSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateRuleModal: false,
            showDeleteRuleModal: false,

            wizardMode: WIZARD_CREATE_MODE,
            step: 1,
            activeStep: 1,
            ruleValues: ruleInitialValues,

            search: "",
            pageData: { start: 0, end: ITEMS_ON_PAGE },

            rules: [],
            selectedRuleId: undefined,
            detailsTab: "main",

            isLoading: false,
        };
    }

    async componentDidMount() {
        const rules = await this.getRules();

        this.setState({ rules: rules });
    }

    getRules = async () => {
        const { strings } = this.props;

        const [getRulesStatus, rules] = await GetRules();

        if (!getRulesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения правил качества изображений"), { variant: "error" });
        }

        for (let rule of rules) {
            const [getDevicesStatus, allowedDevices] = await GetDeviceInfo(rule.properties.deviceIds);

            if (!getDevicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения списка доступных устройств"), {
                    variant: "error",
                });
            }

            rule.properties.deviceIds = allowedDevices.map((d) => d.id);
        }

        return rules;
    };

    validateRule = (values) => {
        const { strings, enqueueSnackbar, generalInfo } = this.props;

        if (values.permanent) {
            return true;
        }

        if (values.analyseDurationSec < 10) {
            enqueueSnackbar(strings("Длительность проверки не может быть меньше 10"), {
                variant: "error",
            });

            return false;
        }

        const aggregationTime = getSystemVariableValue(
            generalInfo,
            "service.rtsp.quality",
            "rtsp.quality.aggregation.time",
            1
        );

        if (aggregationTime >= values.analyseDurationSec) {
            enqueueSnackbar(
                strings(
                    `Длительность проверки не может быть больше или равнным системной переменной "Время сбора статистики"`
                ),
                { variant: "error" }
            );
            return false;
        }

        if (values.startInterval < values.analyseDurationSec) {
            enqueueSnackbar(strings("Частота выполнения не может быть меньше длительности проверки"), {
                variant: "error",
            });
            return false;
        }

        return true;
    };

    createRule = async () => {
        const { ruleValues } = this.state;
        const { strings } = this.props;

        if (!this.validateRule(ruleValues)) {
            return;
        }

        this.setState({ isLoading: true });

        try {
            const [success, statusCode] = await CreateRule(this.valuesToRule(ruleValues));
            if (success) {
                this.props.enqueueSnackbar(strings("Правило создано"), { variant: "success" });
                this.setState({
                    showCreateRuleModal: false,
                    step: 1,
                    activeStep: 1,
                    ruleValues: ruleInitialValues,
                    rules: await this.getRules(),
                    isLoading: false,
                });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(strings("Не хватает прав для создания правила"), {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("У правила должно быть уникальное название"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(strings("Не удалось создать правило"), { variant: "error" });
                        break;
                }

                this.setState({
                    isLoading: false,
                    activeStep: 1,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    updateRule = async () => {
        const { ruleValues, selectedRuleId } = this.state;
        const { strings } = this.props;

        if (!this.validateRule(ruleValues)) {
            return;
        }

        this.setState({ isLoading: true });

        try {
            const [success, statusCode] = await UpdateRule(selectedRuleId, this.valuesToRule(ruleValues));

            if (success) {
                this.props.enqueueSnackbar(strings("Правило успешно изменено"), { variant: "success" });
                this.setState({
                    showCreateRuleModal: false,
                    step: 1,
                    activeStep: 1,
                    ruleValues: ruleInitialValues,
                    rules: await this.getRules(),
                    isLoading: false,
                });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(strings("Не хватает прав для изменения правила"), {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("У правила должно быть уникальное название"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(strings("Не удалось сохранить правило"), { variant: "error" });
                        break;
                }

                this.setState({
                    isLoading: false,
                    activeStep: 1,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    deleteRule = async () => {
        const { selectedRuleId } = this.state;
        const { strings } = this.props;

        const [success] = await DeleteRule(selectedRuleId);

        if (success) {
            this.props.enqueueSnackbar(strings("Правило успешно удалено"), { variant: "success" });
        } else {
            this.props.enqueueSnackbar(strings("Не удалось удалить правило"), { variant: "error" });
        }

        this.setState({
            rules: await this.getRules(),
            selectedRuleId: undefined,
        });
    };

    valuesToRule = (values) => {
        return {
            name: values.name,
            properties: {
                comment: values.comment,
                permanent: values.permanent,
                createEvent: values.createEvent,
                fpsViolation: {
                    warningViolation: values.fpsWarningViolation,
                    criticalViolation: values.fpsCriticalViolation,
                },
                bitrateViolation: {
                    warningViolation: values.bitrateWarningViolation,
                    criticalViolation: values.bitrateCriticalViolation,
                },
                packetLossViolation: {
                    warningViolation: values.packetLossWarningViolation,
                    criticalViolation: values.packetLossCriticalViolation,
                },
                analyseDurationSec: values.analyseDurationSec,
                startInterval: values.startInterval,
            },
        };
    };

    valuesFromRule = (rule) => {
        return {
            name: rule?.name,
            fpsWarningViolation: rule?.properties?.fpsViolation.warningViolation,
            fpsCriticalViolation: rule?.properties?.fpsViolation.criticalViolation,
            bitrateWarningViolation: rule?.properties?.bitrateViolation.warningViolation,
            bitrateCriticalViolation: rule?.properties?.bitrateViolation.criticalViolation,
            packetLossWarningViolation: rule?.properties?.packetLossViolation.warningViolation,
            packetLossCriticalViolation: rule?.properties?.packetLossViolation.criticalViolation,
            analyseDurationSec: rule?.properties?.analyseDurationSec,
            startInterval: rule?.properties?.startInterval,
            comment: rule?.properties?.comment,
            permanent: rule?.properties?.permanent,
            createEvent: rule?.properties?.createEvent,
        };
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    showCreateRuleModal = () =>
        this.setState({
            showCreateRuleModal: true,
            wizardMode: WIZARD_CREATE_MODE,
            ruleValues: ruleInitialValues,
            step: 1,
            activeStep: 1,
        });

    showUpdateRuleModal = (rule) => {
        this.setState({
            showCreateRuleModal: true,
            wizardMode: WIZARD_UPDATE_MODE,
            ruleValues: this.valuesFromRule(rule),
        });
    };
    showDeleteRuleModal = (rule) =>
        this.setState({
            showDeleteRuleModal: true,
            ruleValues: this.valuesFromRule(rule),
        });

    closeCreateRuleModal = () => this.setState({ showCreateRuleModal: false, step: 1, activeStep: 1 });

    render() {
        const { userInfo } = this.props;
        const {
            rules,
            selectedRuleId,
            pageData,
            search,
            showCreateRuleModal,
            wizardMode,
            step,
            activeStep,
            ruleValues,
            detailsTab,
            showDeleteRuleModal,
        } = this.state;
        const { strings } = this.props;

        let filteredRules = rules;
        if (search && search !== "") {
            filteredRules = filteredRules?.filter((r) => r.name?.toLowerCase()?.indexOf(search?.toLowerCase()) >= 0);
        }

        return (
            <>
                <EventsLayoutPrimary hasSecondary={!!selectedRuleId}>
                    <EventsLayoutPrimaryInner>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings("Проверка качества видеоизображений")}
                                </Text>

                                <ExpandableSearch
                                    value={this.state.search}
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                    onSubmit={() => {
                                        /* ignore enter */
                                    }}
                                    placeholder={strings("Найти")}
                                    colorVariant="dark"
                                    inHeader
                                />
                            </EventsLayoutHeaderTitle>

                            <EventsLayoutHeaderToolbox>
                                <EventsLayoutHeaderToolboxItem>
                                    {permissionExists(userInfo, "rtsp.quality.lifeCycle") ? (
                                        <Dropdown
                                            toggleLabel={strings("Создать")}
                                            closeOnClickInside
                                            toggleVariant="secondary"
                                            horizontalAlignment="right"
                                        >
                                            <DropdownItem as="button" onClick={this.showCreateRuleModal}>
                                                {strings("Правило проверки качества видеоизображений")}
                                            </DropdownItem>
                                        </Dropdown>
                                    ) : (
                                        <div />
                                    )}
                                </EventsLayoutHeaderToolboxItem>
                            </EventsLayoutHeaderToolbox>
                        </EventsLayoutHeader>
                        <RuleTable
                            rules={filteredRules.slice(pageData.start, pageData.end)}
                            selectedRuleId={selectedRuleId}
                            setSelectedRule={(rule) => this.setState({ selectedRuleId: rule.id })}
                        />
                    </EventsLayoutPrimaryInner>
                    <CommonTablePagination
                        tableData={filteredRules}
                        setPaging={(pageData) => this.setState({ pageData })}
                        searchMode={search !== ""}
                    />
                </EventsLayoutPrimary>

                {selectedRuleId && (
                    <RuleDetails
                        selectedRule={filteredRules?.find((r) => r.id === selectedRuleId)}
                        detailsTab={detailsTab}
                        setDetailsTab={(tab) => this.setState({ detailsTab: tab })}
                        deleteHandler={this.showDeleteRuleModal}
                        updateHandler={this.showUpdateRuleModal}
                        closeHandler={() => this.setState({ selectedRuleId: undefined })}
                    />
                )}

                <CreateUpdateWizard
                    visible={showCreateRuleModal}
                    CreateHandler={this.createRule}
                    EditHandler={this.updateRule}
                    CloseHandler={this.closeCreateRuleModal}
                    values={ruleValues}
                    title={
                        wizardMode === WIZARD_CREATE_MODE
                            ? strings("Создать правило проверки качества видеоизображений")
                            : strings("Изменить правило проверки качества видеоизображений")
                    }
                    step={step}
                    activeStep={activeStep}
                    NextStep={this.nextStep}
                    SetActiveStep={this.selectStep}
                    SetValuesProperty={(n, v) => {
                        this.setState({ ruleValues: { ...ruleValues, [n]: v } });
                    }}
                    steps={wizardSteps(ruleValues)}
                    Mode={wizardMode}
                />

                <DeleteModal
                    visible={showDeleteRuleModal}
                    CloseHandler={() => this.setState({ showDeleteRuleModal: false })}
                    RemoveHandler={this.deleteRule}
                    text={`${strings("Вы хотите удалить правило")} "${ruleValues?.name}". ${strings(
                        "Удалённое правило нельзя восстановить. Продолжить?"
                    )}`}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(
    mapStateToProps,
    null
)(withCultureContext(withGeneralContext(withSnackbar(RtspQualityControlSettings))));

import React from "react";
import { FilterDivider, FilterItem } from "../FiltersPartion";
import { Checkbox } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";

export const MapPlanEventsFilter = ({ eventsFilter, setEventsFilter }) => {
    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <>
                    <FilterItem>
                        <Checkbox
                            label={strings("Тревожные")}
                            checked={eventsFilter.showDanger}
                            onChange={() => {
                                eventsFilter.showDanger = !eventsFilter.showDanger;
                                setEventsFilter(eventsFilter);
                            }}
                            colorVariant="danger"
                        />
                    </FilterItem>
                    <FilterItem>
                        <Checkbox
                            label={strings("Требующие внимания")}
                            checked={eventsFilter.showWarning}
                            onChange={() => {
                                eventsFilter.showWarning = !eventsFilter.showWarning;
                                setEventsFilter(eventsFilter);
                            }}
                            colorVariant="warning"
                        />
                    </FilterItem>
                    <FilterDivider />
                    <FilterItem>
                        <Checkbox
                            label={strings("Показывать просмотренные")}
                            checked={eventsFilter.showRead}
                            onChange={() => {
                                eventsFilter.showRead = !eventsFilter.showRead;
                                setEventsFilter(eventsFilter);
                            }}
                            colorVariant="primary"
                        />
                    </FilterItem>
                </>
            )}
        </CultureContextConsumer>
    );
};

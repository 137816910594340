import React, { useCallback, useEffect, useState } from "react";
import { AccordionListGroup, AccordionListChild, AccordionListWrapper, CameraStatusIndicator } from "./elements";
import { GeneralContextConsumer } from "../../../contexts";
import { Text } from "headpoint-react-components";

export const SidebarTree = ({ frames, list, onSelect, onOpenLocation }) => {
    const [selectedParents, setSelectedParents] = useState([]);

    const checkChildrenCallback = useCallback(
        (childrenlist) => {
            const selectedFrameIds = Object.values(frames)
                .map((item) => item?.id)
                .flat();

            let parentAcc = [];

            const checkChildren = (children, parents = [], parent) => {
                children.map((child) => {
                    const newParents = parent ? [...parents, parent] : [...parents];

                    if ("children" in child) {
                        checkChildren(child.children, newParents, child.id);
                    } else {
                        if (selectedFrameIds.includes(child.id)) parentAcc = [...parentAcc, ...newParents];

                        return false;
                    }
                    return false;
                });
                setSelectedParents(parentAcc);
            };

            checkChildren(childrenlist);
        },
        [frames]
    );

    useEffect(() => {
        checkChildrenCallback(list);
    }, [list, frames, checkChildrenCallback]);

    const renderChildren = (childrenList) => {
        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        {childrenList.map((item) =>
                            item.children ? (
                                <AccordionListGroup
                                    key={item.id}
                                    label={item.label ?? item.name}
                                    isSelected={selectedParents.includes(item.id)}
                                    preOpen={item.preOpen || selectedParents.includes(item.id)}
                                    hasSelected={Object.keys(frames).length}
                                    onOpenLocation={onOpenLocation}
                                    location={item}
                                >
                                    {renderChildren(item.children)}
                                </AccordionListGroup>
                            ) : (
                                <AccordionListChild
                                    key={item.id}
                                    onClick={() => onSelect(item)}
                                    isSelected={Object.values(frames).find((f) => f?.id === item?.id)}
                                    hasSelected={Object.keys(frames).length}
                                >
                                    <CameraStatusIndicator status={item.recorderStatus} />
                                    <Text variant={"body-sm"}>{item.label ?? item.name}</Text>
                                </AccordionListChild>
                            )
                        )}
                    </>
                )}
            </GeneralContextConsumer>
        );
    };

    return <AccordionListWrapper>{renderChildren(list)}</AccordionListWrapper>;
};

import React from "react";
import styled from "styled-components";
import {
    Modal,
    ModalHeader,
    ModalFooter,
    ModalFooterItem,
    ModalBody,
    ModalBodyItem,
    TextField,
    Button,
    Icon,
    Text,
    Row,
    DateRangePicker,
    Select,
    Space,
} from "headpoint-react-components";
import { FillNode, GetLocations } from "../../../../services/locations";
import { GetDevices, SearchDevices } from "../../../../services/devices";
import { withSnackbar } from "notistack";
import {
    anyPermissionExists,
    getAdapters,
    getDeviceTypesByFeature,
    permissionExists,
} from "../../../../contexts/GeneralContext";
import moment from "moment/moment";
import { CreateArchiveOrdersBatch } from "../../../../services/archive";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import CamerasList from "./CamerasList/CamerasList";
import AddButton from "./AddButton";
import CreatedOrdersListModal from "./CreatedOrdersListModal";
import CamerasTree from "../../../components/CamerasTree/CamerasTree";
import { GetCameraLacunas } from "../../../../services/archive";
import { getDeviceTypes } from "../../../../contexts";
import { GROUP_ORDER_TYPE, PERSONAL_ORDER_TYPE, SYSTEM_ORDER_TYPE } from "../../constants";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";
import { FilterAccordionStatic } from "../../../components/FiltersStatic";
import { GetUsersGroups } from "../../../../services/users";
import { connect } from "react-redux";
import { SelectedCounter } from "../../../components/SelectedCounter/SelectedCounter";

class OrderArchiveModal extends React.Component {
    constructor(props) {
        super(props);

        const dates = { from: props?.dates?.from, to: props?.dates?.to };

        this.state = {
            devicesLocationsTree: [],
            filteredCameras: [],
            search: "",
            camerasCache: [],
            selectedCameras: [],
            uniqueRanges: [],
            showSelectedCameras: false,
            showAddCamerasTree: false,
            archiveRanges: dates,
            reason: "",
            type: null,
            entities: [],
            searchGroup: "",
            userGroup: null,
            isLoading: false,
            orderNumbers: null,
            userTypes: [],
            usersGroups: [],
        };
    }

    componentDidMount = async () => {
        let { userTypes, usersGroups } = this.state;
        const { userInfo, strings, enqueueSnackbar } = this.props;

        if (permissionExists(userInfo, "archive.order.personal.edit")) {
            userTypes.push(PERSONAL_ORDER_TYPE);
        }

        if (anyPermissionExists(userInfo, ["archive.order.group.other.edit", "archive.order.group.edit"])) {
            userTypes.push(GROUP_ORDER_TYPE);

            const [status, fetchedUserGroups] = await GetUsersGroups();

            if (!status) {
                enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
                return;
            }

            if (permissionExists(userInfo, "archive.order.group.edit")) {
                usersGroups = usersGroups.concat(fetchedUserGroups.filter((group) => group.id === userInfo.groupId));
            }

            if (permissionExists(userInfo, "archive.order.group.other.edit")) {
                usersGroups = usersGroups.concat(fetchedUserGroups.filter((group) => group.id !== userInfo.groupId));
            }
        }

        if (permissionExists(userInfo, "archive.order.system.edit")) {
            userTypes.push(SYSTEM_ORDER_TYPE);
        }

        this.setState(
            {
                devicesLocationsTree: this.copy(this.props.devicesLocationsTree),
                camerasCache: [...this.props.selectedCameras],
                userTypes,
                usersGroups,
            },
            async () => await this.handleSelect(this.props.selectedCameras.map((camera) => camera.id))
        );
    };

    getDays = (epochStart, epochEnd) => {
        const result = [];
        if (!epochStart || !epochEnd) {
            return result;
        }

        const startDate = moment(epochStart);
        const endDate = moment(epochEnd);
        while (startDate.isSameOrBefore(endDate)) {
            result.push(startDate.clone().toDate());
            startDate.add(1, "days");
        }

        return result;
    };

    handleSelect = async (cameraIds) => {
        const { generalInfo } = this.props;
        const { camerasCache } = this.state;

        let camerasLacunas = [];

        for (const cameraId of cameraIds) {
            const deviceTypeId = camerasCache.find((c) => c.id === cameraId)?.typeId;
            const serviceCode = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === deviceTypeId)?.serviceCode;

            const [getLacunasStatus, lacunas] = await GetCameraLacunas(serviceCode, cameraId);
            if (getLacunasStatus) {
                camerasLacunas = camerasLacunas.concat(lacunas.lacunas);
            }
        }

        camerasLacunas =
            camerasLacunas
                ?.map((lacuna) => this.getDays(moment.unix(lacuna.from)?._d, moment.unix(lacuna.to)?._d))
                .flat(1) ?? [];

        const uniqueRanges = [...new Set(camerasLacunas.map((date) => date.toDateString()))].map(
            (string) => new Date(string)
        );

        this.setState({ selectedCameras: cameraIds, uniqueRanges });
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.devicesLocationsTree !== prevProps.devicesLocationsTree) {
            this.setState({
                devicesLocationsTree: this.copy(this.props.devicesLocationsTree),
                selectedCameras: this.props.selectedCameras.map((camera) => camera.id),
                camerasCache: [...this.props.selectedCameras],
            });
        }
    };

    copy = (devicesLocationsTree) => {
        return JSON.parse(JSON.stringify(devicesLocationsTree));
    };

    selectCamera = (cameraId) => {
        const { selectedCameras, showSelectedCameras } = this.state;
        const cameraIndex = selectedCameras.indexOf(cameraId);

        if (cameraIndex >= 0) {
            selectedCameras.splice(cameraIndex, 1);
            const showCameras = showSelectedCameras && selectedCameras.length !== 0;

            this.setState({ selectedCameras, showSelectedCameras: showCameras });

            return;
        }

        this.setState({ selectedCameras: [...selectedCameras, cameraId] });
    };

    setDateRange(range) {
        const archiveRanges = {};
        archiveRanges.from = moment(range.from).seconds(0).milliseconds(0).toDate();

        archiveRanges.to = moment(range.to).seconds(0).milliseconds(0).toDate();

        this.setState({ archiveRanges });
    }

    validateOrderData() {
        const { selectedCameras, archiveRanges, type } = this.state;
        const { strings } = this.props;

        if (selectedCameras.length === 0) {
            this.props.enqueueSnackbar(strings("Не выбрано ни одной камеры"), { variant: "error" });
            return false;
        }

        if (!archiveRanges.from) {
            this.props.enqueueSnackbar(strings("Укажите дату начала архива"), { variant: "error" });
            return false;
        }

        if (!archiveRanges.to) {
            this.props.enqueueSnackbar(strings("Укажите дату конца архива"), { variant: "error" });
            return false;
        }

        if (archiveRanges.from > archiveRanges.to) {
            this.props.enqueueSnackbar(strings("Дата начала не может быть позже даты конца архива"), {
                variant: "error",
            });
            return false;
        }

        if (!type) {
            this.props.enqueueSnackbar(strings("Укажите тип"), { variant: "error" });
            return false;
        }

        return true;
    }

    combineDataToArchiveOrders() {
        const { camerasCache, selectedCameras, archiveRanges, reason, type, entities } = this.state;
        const { generalInfo, strings } = this.props;
        const adapters = getAdapters(generalInfo);

        let archiveOrders = [];

        selectedCameras.forEach((cameraId) => {
            const deviceTypeId = camerasCache.find((c) => c.id === cameraId)?.typeId;
            const adapterId = adapters?.find((a) => a.deviceTypes?.find((dt) => dt.id === deviceTypeId))?.id;
            const serviceId = generalInfo.services?.find((s) => s.properties?.adapter?.id === adapterId)?.serviceId;

            if (!serviceId || serviceId === "") {
                const errorMessage = `${strings("Невозможно получить информацию об адаптере для камеры:")} '${
                    camerasCache?.find((c) => c.id === cameraId)?.name
                }', '${cameraId}'`;

                this.props.enqueueSnackbar(errorMessage, { variant: "error" });

                return null;
            }

            const order = {
                cameraId,
                deviceTypeId,
                adapterId,
                serviceId,
                reason,
                from: archiveRanges.from,
                to: archiveRanges.to,
                type,
            };

            if (type === GROUP_ORDER_TYPE.value) {
                order.entities = entities;
            }

            archiveOrders.push(order);
        });

        return archiveOrders;
    }

    sendArchiveOrders = async (archiveOrders) => {
        this.setState({ isLoading: true });
        const { strings } = this.props;

        try {
            const [status, statusCode, response] = await CreateArchiveOrdersBatch(archiveOrders);
            if (!status) {
                switch (statusCode) {
                    case 409:
                        this.props.enqueueSnackbar(strings(`Нет подкюченных адаптеров для выгрузки архива камеры`), {
                            variant: "error",
                        });
                        return;
                    default:
                        this.props.enqueueSnackbar(strings(`Не удалось создать заказ архивной записи камеры`), {
                            variant: "error",
                        });
                        return;
                }
            }

            const orderNumbers = response.map(
                (order) => `${order.orderNumber}${order.orderSubnumber ? `-${order.orderSubnumber}` : ""}`
            );
            this.setState({ orderNumbers });
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.validateOrderData()) {
            return;
        }

        const archiveOrders = this.combineDataToArchiveOrders();

        if (!archiveOrders || archiveOrders?.length === 0) {
            return;
        }

        this.sendArchiveOrders(archiveOrders);
    };

    openLocation = async (locationNode) => {
        const { devicesLocationsTree, camerasCache } = this.state;
        const { generalInfo, strings } = this.props;

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        let adapters = getAdapters(generalInfo);

        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.archive");

        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.setState({ devicesLocationsTree, camerasCache });
            return;
        }

        const [getDevicesStatus, camerasFromLocation] = await GetDevices(locationNode.id, deviceTypeIds);

        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let newCameras = [
            ...camerasCache,
            ...camerasFromLocation.filter((c) => camerasCache.map((c) => c.id).includes(c.id) !== true),
        ];

        FillNode(locationNode, children, camerasFromLocation);

        this.setState({ devicesLocationsTree, camerasCache: newCameras });
    };

    async setSearch(search) {
        if (search && search !== "") {
            const { generalInfo, strings } = this.props;
            let { camerasCache } = this.state;

            let adapters = getAdapters(generalInfo);

            if (!adapters) {
                this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
                return;
            }

            let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.archive");

            if (!deviceTypeIds || deviceTypeIds.length === 0) {
                this.setState({ search, filteredCameras: [] });
                return;
            }

            let [devicesStatus, filteredCameras] = await SearchDevices({
                like: search,
                limit: 5,
                deviceTypes: deviceTypeIds,
            });

            if (!devicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            filteredCameras = filteredCameras.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });

            let newCameras = [];
            filteredCameras.forEach((camera) => {
                if (!camerasCache.some((c) => c.id === camera.id)) {
                    newCameras.push(camera);
                }
            });

            this.setState({ search, filteredCameras, camerasCache: [...camerasCache, ...newCameras] });
        } else {
            this.setState({ search, filteredCameras: [] });
        }
    }

    onSaveButtonClick = () => this.setState({ showAddCamerasTree: false });

    render() {
        const {
            reason,
            search,
            filteredCameras,
            devicesLocationsTree,
            selectedCameras,
            showAddCamerasTree,
            camerasCache,
            archiveRanges,
            isLoading,
            orderNumbers,
            uniqueRanges,
            type,
            usersGroups,
            searchGroup,
            entities,
            userTypes,
        } = this.state;

        const { strings } = this.props;

        const groups = usersGroups?.filter((ug) => ug?.name?.toLowerCase().includes(searchGroup.toLowerCase())) ?? [];

        return orderNumbers ? (
            <CreatedOrdersListModal orderNumbers={orderNumbers} onClose={this.props.handleClose} />
        ) : (
            <>
                {isLoading && <LoaderAnimation message={strings("Отправка архивных заданий")} />}
                <Modal size="lg" closeHandler={this.props.handleClose} style={{ zIndex: 1 }}>
                    <ModalHeader title={strings("Заказать запись")} closeHandler={this.props.handleClose} />

                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <ModalBodyItem>
                                <BodyHeightLimit>
                                    <Row marginBottom={24} align={"bottom"} justify={"between"}>
                                        <Text marginLeft={"auto"} color="primary" variant="body-sm" align={"left"}>
                                            {strings("Камера")}
                                        </Text>
                                        <Row align="middle">
                                            <SelectedCounter
                                                count={this.state.selectedCameras?.length ?? 0}
                                                onClear={() => this.setState({ selectedCameras: [] })}
                                                strings={strings}
                                            />
                                            <AddButton
                                                show={!showAddCamerasTree}
                                                onClick={() => this.setState({ showAddCamerasTree: true })}
                                            />
                                        </Row>
                                    </Row>
                                    <Row marginBottom={12}>
                                        <TextField
                                            autoComplete={false}
                                            colorVariant="light"
                                            fullWidth
                                            icon="search"
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            placeholder={strings("Найти")}
                                            type="search"
                                            value={search}
                                        />
                                    </Row>
                                    {showAddCamerasTree ? (
                                        <CamerasTree
                                            search={search}
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            filteredCameras={filteredCameras}
                                            selectedCameras={selectedCameras}
                                            selectCamera={this.selectCamera}
                                            devicesLocationsTree={devicesLocationsTree}
                                            openItem={this.openLocation}
                                            selectHandler={this.handleSelect}
                                        />
                                    ) : (
                                        <CamerasList
                                            selectedCameras={selectedCameras}
                                            camerasCache={camerasCache}
                                            devicesLocationsTree={devicesLocationsTree}
                                            selectCamera={this.selectCamera}
                                        />
                                    )}
                                </BodyHeightLimit>
                                {showAddCamerasTree && (
                                    <Row>
                                        <Button
                                            label={strings("Сохранить")}
                                            variant="secondary"
                                            onClick={this.onSaveButtonClick}
                                        />
                                    </Row>
                                )}
                            </ModalBodyItem>
                            <ModalBodyItem>
                                <Row marginBottom={12}>
                                    <DateRangePicker
                                        dates={archiveRanges}
                                        presets={[]}
                                        onChange={(range) => {
                                            this.setDateRange(range);
                                        }}
                                        placeholder={strings("Выбрать период")}
                                        colorVariant="transparent"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        withTime
                                        customInputLabels={{
                                            hours: strings("Часы"),
                                            minutes: strings("Минуты"),
                                        }}
                                        customIcon={<Icon icon="calendar-clear" />}
                                        calendarProps={{
                                            disabledDays: {
                                                after: new Date(),
                                            },
                                        }}
                                        events={uniqueRanges}
                                    />
                                </Row>

                                <Select
                                    label={strings("Тип")}
                                    placeholder={strings("Выбрать тип")}
                                    value={type}
                                    onChange={(e) => this.setState({ type: e })}
                                    options={userTypes.map((t) => {
                                        return {
                                            label: strings(t.label),
                                            value: t.value,
                                        };
                                    })}
                                    fullWidth
                                    error={!type ? strings("Поле не может быть пустым") : null}
                                />
                                {type === GROUP_ORDER_TYPE.value && (
                                    <>
                                        <Space />
                                        <ModalFormItem>
                                            <Row justify="between" align="bottom" marginBottom={24}>
                                                <Text variant="body">{strings("Группы пользователей")}</Text>
                                                <SelectedCounter
                                                    count={entities?.length ?? 0}
                                                    onClear={() => this.setState({ entities: [] })}
                                                    strings={strings}
                                                />
                                            </Row>
                                            <Row marginBottom={12}>
                                                <TextField
                                                    autoComplete={false}
                                                    colorVariant="light"
                                                    fullWidth
                                                    icon="search"
                                                    onChange={(e) => this.setState({ searchGroup: e.target.value })}
                                                    placeholder={strings("Найти")}
                                                    type="search"
                                                    value={searchGroup}
                                                />
                                            </Row>
                                            <FilterAccordionStatic
                                                key={!!searchGroup}
                                                preOpen={!!searchGroup}
                                                items={groups}
                                                selected={entities ?? []}
                                                selectHandler={(e) => this.setState({ entities: e })}
                                            />
                                        </ModalFormItem>
                                    </>
                                )}

                                <Space></Space>
                                <Row>
                                    <TextField
                                        label={strings("Причина заказа")}
                                        name="reason"
                                        type="text"
                                        value={reason}
                                        onChange={(e) => this.setState({ reason: e.target.value })}
                                        colorVariant="light"
                                        fullWidth
                                        inputProps={{
                                            as: "textarea",
                                            rows: 3,
                                        }}
                                    />
                                </Row>
                            </ModalBodyItem>
                        </ModalBody>

                        <ModalFooter>
                            <ModalFooterItem>
                                <Button
                                    disabled={selectedCameras.length === 0 || showAddCamerasTree}
                                    variant="primary"
                                    label={strings("Заказать")}
                                    onClick={this.handleSubmit}
                                    type="submit"
                                />
                            </ModalFooterItem>

                            <ModalFooterItem>
                                <Button
                                    variant="ghost"
                                    label={strings("Отмена")}
                                    onClick={this.props.handleClose}
                                    type="button"
                                />
                            </ModalFooterItem>
                        </ModalFooter>
                    </form>
                </Modal>
            </>
        );
    }
}

const BodyHeightLimit = styled.div`
    min-height: 554px;
    max-width: 400px;
`;

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(OrderArchiveModal));

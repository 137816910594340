import React from "react";
import { CameraLayoutItem, CameraPlaceholder } from "../../components/CameraLayout";
import { GeneralContextConsumer } from "../../../contexts";
import { GetLocationList } from "../../../services/locations";
import { ScreenshotPlayer } from "./ScreenshotPlayer";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class ScreenshotPlayerWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFrameLocationName: undefined,
        };
    }

    componentDidMount() {
        const currentFrame = this.props.frames[this.props.frameIndex];
        if (currentFrame) {
            this.getLocationById(currentFrame.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }

    getLocationById = async (locationId) => {
        const { strings } = this.props;

        if (!locationId) {
            return;
        }

        let [locationsStatus, locations] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locations?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        return locations?.[0];
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevSelectedFrame = prevProps.frames[prevProps.frameIndex];
        const selectedFrame = this.props.frames[this.props.frameIndex];

        if (prevSelectedFrame?.id !== selectedFrame?.id) {
            this.getLocationById(selectedFrame?.locationId).then((l) =>
                this.setState({ selectedFrameLocationName: l?.name })
            );
        }
    }
    render() {
        const { screenshotsInfo, offsetTime, handleOffset, strings } = this.props;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <CameraLayoutItem
                            showCameraInfo={this.props.showCameraInfo}
                            isSelected={this.props.selectedCameraFrame === this.props.frameIndex}
                            onSelect={() => this.props.handleCameraFrameSelect(this.props.frameIndex)}
                            onClear={() => this.props.handleCameraFrameClear(this.props.frameIndex)}
                            hasItem={
                                this.props.frames[this.props.frameIndex] && this.props.frames[this.props.frameIndex].id
                            }
                            label={
                                this.props.frames[this.props.frameIndex] &&
                                this.props.frames[this.props.frameIndex].name &&
                                this.props.frames[this.props.frameIndex].name
                            }
                            locationName={this.state.selectedFrameLocationName}
                            placeholderLabel={strings("Камера не выбрана")}
                            selectedPlaceholderLabel={strings("Выберите камеру")}
                        >
                            {this.props.frames[this.props.frameIndex] && this.props.frames[this.props.frameIndex].id ? (
                                <ScreenshotPlayer
                                    handleOffset={handleOffset}
                                    offsetTime={offsetTime}
                                    screenshotsInfo={screenshotsInfo}
                                    presets={this.props.presets}
                                    frameIndex={this.props.frameIndex}
                                    frames={this.props.frames}
                                    generalInfo={generalInfo}
                                    strings={strings}
                                />
                            ) : (
                                <CameraPlaceholder type="stream" />
                            )}
                        </CameraLayoutItem>
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

export default withCultureContext(withSnackbar(ScreenshotPlayerWrapper));

import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { Row, Table, TableAccordionItem, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { formatRoleFunctionCount } from "../../../../../utilites/TextUtils";
import { GeneralContextConsumer, getGeneralPermissionsTree } from "../../../../../contexts";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsSystemFunctionsTab extends React.Component {
    flatten = (object) => {
        const getChild = (key, objectBit) => {
            return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
        };

        return Object.assign(
            {},
            ...(function _flatten(objectBit) {
                return [].concat(
                    ...Object.keys(objectBit).map((key) =>
                        typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                    )
                );
            })(object)
        );
    };

    matchRolesFunctions = (selectedRole, roleGeneralPermissions) => {
        return roleGeneralPermissions
            .map((item) => ({
                ...item,
                children: item.children?.filter((permission) =>
                    selectedRole.properties?.permissions.includes(permission.id)
                ),
            }))
            .filter((item) => item.children?.length > 0);
    };

    render() {
        const { selected } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => {
                            const roleGeneralPermissions = getGeneralPermissionsTree(generalInfo);
                            const rolesFunctions = this.matchRolesFunctions(selected, roleGeneralPermissions);
                            const rolesFunctionsCount = Object.keys(this.flatten(rolesFunctions)).length;

                            return (
                                <DetailsBoxContent>
                                    <Row marginTop={24} marginBottom={16}>
                                        <Text color="primary" variant="body">
                                            {`${strings("Доступно")} ${rolesFunctionsCount} ${strings(
                                                formatRoleFunctionCount(rolesFunctionsCount)
                                            )} ${strings("системы")}`}
                                        </Text>
                                    </Row>

                                    <Table isFixed>
                                        <TableBody>
                                            {rolesFunctions?.map((row) => (
                                                <TableAccordionItem
                                                    key={row.id}
                                                    toggle={
                                                        <TableCell>
                                                            <Text color="primary" variant="body-sm">
                                                                {row.name}
                                                            </Text>
                                                        </TableCell>
                                                    }
                                                >
                                                    {row.children.map((item) => (
                                                        <TableRow key={item.id}>
                                                            <TableCell>
                                                                <Text color="primary" variant="body-sm">
                                                                    {item.name}
                                                                </Text>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableAccordionItem>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </DetailsBoxContent>
                            );
                        }}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

export default DetailsSystemFunctionsTab;

import React from "react";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { Checkbox, ModalSection, Text, TextField } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_UPDATE_MODE } from "../../../../components/CreateUpdateWizard/constants";

export class ModalStepDefault extends React.Component {
    handleInputChange = (e) => {
        const fieldName = e.rawEvent.target.name;
        this.props.SetValuesProperty(fieldName, e.target.value);
    };

    render() {
        const { values, mode } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <ModalFormItem>
                                <TextField
                                    id="name"
                                    label={strings("Название правила")}
                                    name="name"
                                    type="text"
                                    value={values.name}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    fullWidth
                                    error={!values.name ? strings("Поле не может быть пустым") : null}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <TextField
                                    id="comment"
                                    label={strings("Комментарий")}
                                    name="comment"
                                    type="text"
                                    value={values.comment}
                                    onChange={this.handleInputChange}
                                    colorVariant="light"
                                    inputProps={{
                                        as: "textarea",
                                        rows: 4,
                                    }}
                                    fullWidth
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <Checkbox
                                    id="createEvent"
                                    label={strings("Создание событий")}
                                    name="createEvent"
                                    checked={values.createEvent}
                                    onChange={() => {
                                        this.props.SetValuesProperty("createEvent", !values.createEvent);
                                    }}
                                />
                            </ModalFormItem>

                            <ModalFormItem>
                                <Checkbox
                                    id="permanent"
                                    disabled={mode === WIZARD_UPDATE_MODE}
                                    label={strings("Постоянная проверка")}
                                    name="permanent"
                                    checked={values.permanent}
                                    onChange={() => {
                                        this.props.SetValuesProperty("permanent", !values.permanent);
                                    }}
                                />
                            </ModalFormItem>

                            {values.permanent === true && (
                                <ModalFormItem>
                                    <Text color="warning" variant="description">
                                        Постоянная проверка предполагает непрерывное получение видеопотока, что может
                                        привести к увеличению нагрузки на каналы связи и снизить производительность
                                        устройства.
                                    </Text>
                                </ModalFormItem>
                            )}
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

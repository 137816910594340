import { SendFile } from "./storage";

export const EVENTS = "/events-api/events/list";
export const EVENTS_COUNT = "/events-api/events/count";
export const EVENTS_API_BASE = "/events-api/events";
export const EVENTS_READ_EVENT = "/events-api/events/read";

export const GetCount = async (filter) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(EVENTS_COUNT, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(filter),
    });

    if (response.status !== 200) {
        return [false, 0];
    }

    try {
        let request = await response.json();
        return [true, request?.total ?? 0];
    } catch (ex) {
        console.error(ex);

        return [false, 0];
    }
};

export const GetEvents = async (filter) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(EVENTS, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(filter),
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody?.items?.map((e) => ({
            ...e,
            properties: JSON.parse(e.properties ?? "{}"),
        }));

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetEventsByDevices = async (filter) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch("/events-api/events/by-devices", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(filter),
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody?.items?.map((e) => ({
            ...e,
            properties: JSON.parse(e.properties ?? "{}"),
        }));

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetEvent = async (eventId) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${EVENTS_API_BASE}/${eventId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, {}];
    }

    try {
        let responseBody = await response.json();
        let event = responseBody?.event;
        let result = {
            ...event,
            properties: JSON.parse(event.properties ?? "{}"),
        };

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, {}];
    }
};

export const UploadUserEventFiles = async (files) => {
    let uploadedFiles = [];
    for (const file of files) {
        const [sendStatus, fileId] = await SendFile(file);
        if (!sendStatus) {
            return [false, fileId /* response status */];
        }
        uploadedFiles.push({
            id: fileId,
            name: file.name,
            type: file.type ? file.type : "application/octet-stream",
            size: file.size,
        });
    }

    return [true, uploadedFiles];
};

export const CreateUserEvent = async (userEventsAdapterId, userEvent) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${EVENTS_API_BASE}/${userEventsAdapterId}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(userEvent),
    });

    return [response.status === 200, response.status];
};

export const ReadEvent = async (eventId, type) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(EVENTS_READ_EVENT + `/${eventId}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ type }),
    });

    return [response.status === 200];
};

export const GetEventsFilters = async (tag) => {
    const token = window.localStorage.getItem("token");

    const queryParams = new URLSearchParams({
        tag: tag,
    });

    let response = await fetch(`/events-api/events-filter/?${queryParams}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody?.data;

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetEventsFilter = async (id) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`/events-api/events-filter/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let responseBody = await response.json();

        let result = responseBody?.data;

        return [true, result];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const CreateEventsFilter = async (eventsFilter) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch("/events-api/events-filter/", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(eventsFilter),
    });

    return [response.status === 200, response.status];
};

export const UpdateEventsFilter = async (id, eventsFilter) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`/events-api/events-filter/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(eventsFilter),
    });

    return [response.status === 200, response.status];
};

export const DeleteEventsFilter = async (filterId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`/events-api/events-filter/${filterId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return response.status === 200;
};

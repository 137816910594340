import React from "react";
import { withSnackbar } from "notistack";
import { UsersTable } from "./UsersTable";
import { DeleteModal } from "../DeleteModal";
import UserDetails from "./details/UserDetails";
import { GetDevicesGroups } from "../../../../services/devicesGroups";
import {
    GetTableData,
    GetUsers,
    CreateUser,
    DeleteUser,
    UpdateUser,
    GetUsersGroups,
    CreateUserGroup,
    DeleteUsersGroup,
    UpdateUsersGroup,
} from "../../../../services/users";
import { GetLocations } from "../../../../services/locations";
import { GetRoles } from "../../../../services/roles";
import { CreateUpdateWizard } from "../../../components/CreateUpdateWizard/CreateUpdateWizard";
import { EMPTY_UUID, wizardSteps } from "./constants";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
    EventsLayoutPrimaryInner,
} from "../../../../layout/EventsLayout";
import { Text, Dropdown, DropdownItem, DropdownDivider, ExpandableSearch } from "headpoint-react-components";
import {
    GeneralContextConsumer,
    getGeneralPermissionsTree,
    getDeviceGroupsPermissionsTree,
    getDevicesTypesPermissionsTree,
} from "../../../../contexts";
import { checkSuperUser, SUPER_USER_GROUP_ID } from "../../../../contexts/GeneralContext";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../CommonTablePagination";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import UsersGroupModal from "./Modals/UserGroupCreateModals/UsersGroupModal";
import UpdateAllUsersModal from "./Modals/UserGroupCreateModals/UpdateAllUsersModal";
import GroupDetails from "./details/GroupDetails";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const userInitialValues = {
    name: "",
    surname: "",
    patronymic: "",
    phone: "",
    email: "",
    title: "",
    comments: "",
    group: EMPTY_UUID,
    inheritsGroupProperties: true,
    roleId: EMPTY_UUID,
    login: "",
    password: "",
    locations: [],
};

const userGroupInitialValues = {
    name: "",
    roleId: null,
    comment: "",
    locations: [],
    updateAllUsers: null,
};

class UsersSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            users: [],
            usersGroups: [],

            selectedUserId: undefined,
            selectedGroup: undefined,

            userValues: userInitialValues,
            userGroupValues: userGroupInitialValues,

            showDetailsUsersGroup: false,

            showCreateUserModal: false,
            showCreateUserGroupModal: false,
            showUpdateAllUsersModal: false,
            step: 1,
            activeStep: 1,
            wizardMode: WIZARD_CREATE_MODE,

            showDeleteUserModal: false,
            showDeleteUsersGroupModal: false,

            availableRoles: [],
            avavlibleLocations: [],

            detailsTab: "main",

            search: "",
            isLoading: false,
            pageData: { start: 0, end: ITEMS_ON_PAGE },
        };
    }

    async getUsers() {
        const [status, users] = await GetUsers();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения пользователей"), { variant: "error" });
        }

        return users;
    }

    async getUsersGroups() {
        const [status, usersGroups] = await GetUsersGroups();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
        }
        return usersGroups;
    }

    componentDidMount = async () => {
        const { strings } = this.props;

        const users = await this.getUsers();
        const usersGroups = await this.getUsersGroups();

        const [getRolesStatus, roles] = await GetRoles();
        if (!getRolesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения списка ролей"), { variant: "error" });
        }

        const [getLocationStatus, locations] = await GetLocations();
        if (!getLocationStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения списка локаций"), { variant: "error" });
        }

        const [status, deviceGroups] = await GetDevicesGroups();
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
        }

        this.setState({
            users: users ?? [],
            usersGroups: usersGroups ?? [],
            availableRoles: roles ?? [],
            avavlibleLocations: locations ?? [],
            avavlibleDeviceGroups: deviceGroups ?? [],
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.userValues.roleId) {
            this.setUserValuesProperties({ roleId: EMPTY_UUID });
        }

        this.updateCreateUserModal(prevState);
    };

    updateCreateUserModal = (prevState) => {
        if (!this.state.showCreateUserModal) {
            return;
        }

        const { userValues } = this.state;

        if (userValues.group === EMPTY_UUID) {
            return;
        }

        if (
            userValues.inheritsGroupProperties &&
            (prevState.userValues.inheritsGroupProperties !== userValues.inheritsGroupProperties ||
                prevState.userValues.group !== userValues.group)
        ) {
            const selectedUsersGroup = this.state.usersGroups.find((userGroup) => userGroup.id === userValues.group);
            const selectedUsersGroupValues = this.valuesFromUsersGroup(selectedUsersGroup);

            this.setUserValuesProperties({
                roleId: selectedUsersGroupValues.roleId,
                locations: selectedUsersGroupValues.locations,
            });
        }
    };

    showCreateUserModal = () =>
        this.setState({
            showCreateUserModal: true,
            wizardMode: WIZARD_CREATE_MODE,
            userValues: { ...userInitialValues },
            step: 1,
            activeStep: 1,
        });
    showUpdateUserModal = (user) =>
        this.setState({
            showCreateUserModal: true,
            wizardMode: WIZARD_UPDATE_MODE,
            userValues: this.valuesFromUser(user),
        });

    showDeleteUserModal = (user) => this.setState({ showDeleteUserModal: true, userValues: this.valuesFromUser(user) });

    closeCreateUserModal = () => this.setState({ showCreateUserModal: false, step: 1, activeStep: 1 });

    createUser = async () => {
        const { strings } = this.props;

        const { userValues } = this.state;
        if (!userValues.login) {
            this.props.enqueueSnackbar(strings("Нельзя создать пользователя без логина"), { variant: "error" });
            return;
        }

        if (!userValues.password) {
            this.props.enqueueSnackbar(strings("Нельзя создать пользователя без пароля"), { variant: "error" });
            return;
        }

        if (!userValues.group || userValues.group === EMPTY_UUID) {
            this.props.enqueueSnackbar(strings("Нельзя создать пользователя без группы"), { variant: "error" });
            return;
        }

        if (
            !userValues.roleId ||
            (userValues.roleId === EMPTY_UUID && !checkSuperUser({ groupId: userValues.group }))
        ) {
            this.props.enqueueSnackbar(strings("Нельзя создать пользователя без роли"), { variant: "error" });
            return;
        }

        this.setState({ isLoading: true });

        try {
            let user = this.userFromValues(userValues);
            const [success, statusCode] = await CreateUser(user);
            if (success) {
                this.props.enqueueSnackbar(`${strings("Пользователь")} '${user.name}' ${strings("создан")}`, {
                    variant: "success",
                });
                this.setState({
                    showCreateUserModal: false,
                    step: 1,
                    activeStep: 1,
                    userValues: userInitialValues,
                    users: await this.getUsers(),
                    usersGroups: await this.getUsersGroups(),
                    isLoading: false,
                });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для создания пользователя")} '${user.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(
                            `${strings("Пользователь с логином")} '${user.name}' ${strings("уже существует")}`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 402:
                        this.props.enqueueSnackbar(
                            strings("Достигнут лимит лицензии на создание новых пользователей"),
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    default:
                        this.props.enqueueSnackbar(`${strings("Не удалось создать пользователя")} '${user.name}'`, {
                            variant: "error",
                        });
                        break;
                }

                this.setState({
                    isLoading: false,
                    activeStep: 1,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    updateUser = async () => {
        const { strings } = this.props;

        const { userValues } = this.state;
        if (!userValues.login) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить пользователя без логина"), { variant: "error" });
            return;
        }

        if (!userValues.group) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить пользователя без группы"), { variant: "error" });
            return;
        }

        if (userValues.group !== SUPER_USER_GROUP_ID && (!userValues.roleId || userValues.roleId === EMPTY_UUID)) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить пользователя без роли"), { variant: "error" });
            return;
        }

        this.setState({ isLoading: true });

        try {
            let user = this.userFromValues(userValues);
            const [success, statusCode] = await UpdateUser(user);
            if (success) {
                this.props.enqueueSnackbar(`${strings("Пользователь")} '${user.name}' ${strings("успешно изменен")}`, {
                    variant: "success",
                });
                this.setState({
                    showCreateUserModal: false,
                    step: 1,
                    activeStep: 1,
                    userValues: userInitialValues,
                    users: await this.getUsers(),
                    usersGroups: await this.getUsersGroups(),
                    isLoading: false,
                });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для изменения пользователя")} '${user.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("У пользователей должен быть уникальный login"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(`${strings("Не удалось сохранить пользователя")} '${user.name}'`, {
                            variant: "error",
                        });
                        break;
                }

                this.setState({
                    isLoading: false,
                    activeStep: 1,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    deleteUser = async () => {
        const { strings } = this.props;

        const user = this.userFromValues(this.state.userValues);
        const [success, httpCode, statusCode] = await DeleteUser(user.id);
        if (success) {
            this.props.enqueueSnackbar(`${strings("Пользователь")} '${user.name}' ${strings("успешно удален")}`, {
                variant: "success",
            });
        } else {
            switch (httpCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для удаления пользователя"), {
                        variant: "warning",
                    });
                    break;

                case 409: // conflict
                    if (statusCode === "conflict-lastSuperUser") {
                        this.props.enqueueSnackbar(strings("Удаление последнего superuser запрещено"), {
                            variant: "warning",
                        });
                    } else if (statusCode === "conflict-deleteUs") {
                        this.props.enqueueSnackbar(strings("Запрещено удаление самого себя"), { variant: "warning" });
                    } else {
                        this.props.enqueueSnackbar(
                            `${strings("Конфликт помешал удалению пользователя")} '${user.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                    }

                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось удалить пользователя")} '${user.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }

        this.setState({
            users: await this.getUsers(),
            usersGroups: await this.getUsersGroups(),
            selectedUserId: null,
        });
    };

    userFromValues = (values) => {
        let properties = { ...values };
        delete properties.id;
        delete properties.login;
        delete properties.password;
        delete properties.group;
        delete properties.roleId;
        delete properties.locations;

        let roleProperties = {};

        if (values.locations) {
            roleProperties.locations = this.packLocations(values.locations);
        }

        return {
            id: values.id,
            name: values.login,
            password: values.password,
            groupId: values.group,
            inheritsGroupProperties: values.inheritsGroupProperties,
            roleId: values.roleId,
            roleProperties,
            properties: properties,
        };
    };

    packLocations = (locations) => {
        return locations.reduce((aloc, rloc) => {
            aloc[rloc] = {};
            return aloc;
        }, {});
    };

    unpackLocations = (locations) => Object.keys(locations);

    valuesFromUser = (user) => {
        let result = {
            id: user.id,
            login: user.login,
            password: "     ",
            group: user.groupId,
            inheritsGroupProperties: user.inheritsGroupProperties,
            roleId: user.roleId,
        };

        let locations = [];

        if (user.roleProperties?.locations) {
            locations = this.unpackLocations(user.roleProperties.locations);
        }

        return { ...result, ...user.properties, locations };
    };

    showDetailsUsersGroup = (group) =>
        this.setState({
            showDetailsUsersGroup: true,
            selectedUserId: undefined,
            selectedUser: undefined,
            wizardMode: WIZARD_UPDATE_MODE,
            selectedGroup: group,
        });
    showCreateUsersGroupModal = () =>
        this.setState({
            showCreateUserGroupModal: true,
            wizardMode: WIZARD_CREATE_MODE,
            userGroupValues: userGroupInitialValues,
        });
    showUpdateUsersGroupModal = (group) =>
        this.setState({
            showCreateUserGroupModal: true,
            wizardMode: WIZARD_UPDATE_MODE,
            userGroupValues: this.valuesFromUsersGroup(group),
        });
    showDeleteUsersGroupModal = (group) =>
        this.setState({ showDeleteUsersGroupModal: true, userGroupValues: this.valuesFromUsersGroup(group) });
    closeCreateUsersGroupModal = () => this.setState({ showCreateUserGroupModal: false });

    createUsersGroup = async () => {
        const { strings } = this.props;

        let group = this.usersGroupFromValues(this.state.userGroupValues);

        this.setState({ isLoading: true });

        try {
            const [success, statusCode] = await CreateUserGroup(group);

            if (success) {
                this.props.enqueueSnackbar(`${strings("Группа пользователей")} '${group.name}' ${strings("создана")}`, {
                    variant: "success",
                });
                this.setState({
                    showCreateUserGroupModal: false,
                    userGroupValues: userGroupInitialValues,
                    users: await this.getUsers(),
                    usersGroups: await this.getUsersGroups(),
                    isLoading: false,
                });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для создания группы пользователей")} '${group.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("У групп пользователей должно быть уникальное имя"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Не удалось создать группу пользователей")} '${group.name}'`,
                            {
                                variant: "error",
                            }
                        );
                        break;
                }

                this.setState({
                    isLoading: false,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    updateUsersGroup = async () => {
        const { strings } = this.props;

        const group = this.createUpdateUserGroupRequest(this.state.userGroupValues);

        this.setState({ isLoading: true });

        try {
            const [success, statusCode] = await UpdateUsersGroup(group);
            if (success) {
                this.props.enqueueSnackbar(
                    `${strings("Группа пользователей")} '${group.name}' ${strings("сохранена")}`,
                    { variant: "success" }
                );
                this.setState({
                    showCreateUserGroupModal: false,
                    userGroupValues: userGroupInitialValues,
                    selectedGroup: group,
                    users: await this.getUsers(),
                    usersGroups: await this.getUsersGroups(),
                    isLoading: false,
                });
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для изменения группы пользователей")} '${group.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("У групп пользователей должно быть уникальное имя"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Не удалось сохранить группу пользователей")} '${group.name}'`,
                            {
                                variant: "error",
                            }
                        );
                        break;
                }

                this.setState({
                    isLoading: false,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    deleteUsersGroup = async () => {
        const { strings } = this.props;
        const { userGroupValues } = this.state;

        const [success, statusCode] = await DeleteUsersGroup(userGroupValues.id);
        if (success) {
            this.props.enqueueSnackbar(
                `${strings("Группа пользователей")} '${userGroupValues.name}' ${strings("удалена")}`,
                {
                    variant: "success",
                }
            );

            this.setState({
                showDetailsUsersGroup: false,
                userGroupValues: userGroupInitialValues,
                users: await this.getUsers(),
                usersGroups: await this.getUsersGroups(),
            });
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для удаления группы пользователей")} '${userGroupValues.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                case 409: // conflict
                    this.props.enqueueSnackbar(
                        `${strings("Нельзя удалить")} '${userGroupValues.name}' ${strings(
                            "- группа содержит пользователей"
                        )}`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                default:
                    this.props.enqueueSnackbar(
                        `${strings("Не удалось удалить группу пользователей")} '${userGroupValues.name}'`,
                        {
                            variant: "error",
                        }
                    );
                    break;
            }

            return;
        }
    };

    usersGroupFromValues = (values) => ({
        name: values.name,
        id: values.id,
        users: values.users,
        properties: {
            roleId: values.roleId,
            comment: values.comment,
            locations: this.packLocations(values.locations),
        },
    });

    createUpdateUserGroupRequest = (values) => ({
        name: values.name,
        id: values.id,
        properties: {
            roleId: values.roleId,
            comment: values.comment,
            locations: this.packLocations(values.locations),
        },
        updateAllUsers: values.updateAllUsers,
    });

    valuesFromUsersGroup = (group) => {
        return {
            name: group?.name,
            id: group?.id,
            users: group?.users,
            roleId: group?.properties?.roleId,
            comment: group?.properties?.comment,
            locations: group?.properties?.locations ? this.unpackLocations(group.properties.locations) : [],
        };
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    setUserValuesProperty = (n, v) => {
        this.setState({ userValues: { ...this.state.userValues, [n]: v } });
    };

    setUserValuesProperties = (properties) => {
        this.setState({ userValues: { ...this.state.userValues, ...properties } });
    };

    setLocations = (items) => this.setUserValuesProperties({ locations: items, inheritsGroupProperties: false });

    render() {
        let { users, usersGroups } = this.state;
        const {
            selectedUserId,
            selectedGroup,
            showCreateUserModal,
            showCreateUserGroupModal,
            showUpdateAllUsersModal,
            showDeleteUserModal,
            showDeleteUsersGroupModal,
            showDetailsUsersGroup,
            userValues,
            wizardMode,
            step,
            activeStep,
            availableRoles,
            avavlibleLocations,
            userGroupValues,
            detailsTab,
            avavlibleDeviceGroups,
            search,
            isLoading,
            pageData,
        } = this.state;
        const { strings } = this.props;

        let filteredUsers;
        let filteredUserGroups;

        if (search) {
            filteredUsers = users?.filter(
                (u) =>
                    u?.login?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    u?.properties?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    u?.properties?.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    u?.properties?.patronymic?.toLowerCase()?.includes(search?.toLowerCase())
            );

            filteredUserGroups = usersGroups?.filter(
                (ug) =>
                    ug?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    filteredUsers?.some((u) => u.groupId === ug.id)
            );
        }

        const tableData = GetTableData(filteredUsers ?? users, filteredUserGroups ?? usersGroups);

        return (
            <>
                {isLoading && <LoaderAnimation message={strings("Применение настроек")} />}
                <EventsLayoutPrimary hasSecondary={!!selectedUserId || !!selectedGroup}>
                    <EventsLayoutPrimaryInner>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings("Пользователи")}
                                </Text>
                                <ExpandableSearch
                                    value={search}
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                    onSubmit={() => {}}
                                    placeholder={strings("Найти")}
                                    colorVariant="dark"
                                    inHeader
                                />
                            </EventsLayoutHeaderTitle>
                            <EventsLayoutHeaderToolbox>
                                <EventsLayoutHeaderToolboxItem>
                                    <Dropdown
                                        toggleLabel={strings("Создать")}
                                        closeOnClickInside
                                        toggleVariant="secondary"
                                        horizontalAlignment="right"
                                    >
                                        <DropdownItem as="button" onClick={this.showCreateUserModal}>
                                            {strings("Пользователя")}
                                        </DropdownItem>
                                        <DropdownDivider />
                                        <DropdownItem as="button" onClick={this.showCreateUsersGroupModal}>
                                            {strings("Группу пользователей")}
                                        </DropdownItem>
                                    </Dropdown>
                                </EventsLayoutHeaderToolboxItem>
                            </EventsLayoutHeaderToolbox>
                        </EventsLayoutHeader>
                        <UsersTable
                            defaultClosed={!search}
                            selectedUserId={selectedUserId}
                            tableData={tableData.slice(pageData.start, pageData.end)}
                            availableRoles={availableRoles}
                            editUser={this.showUpdateUserModal}
                            editUsersGroup={this.showUpdateUsersGroupModal}
                            deleteUser={this.showDeleteUserModal}
                            deleteUsersGroup={this.showDeleteUsersGroupModal}
                            selectUserHandle={(user) =>
                                this.setState({
                                    selectedUserId: user.id,
                                    showDetailsUsersGroup: false,
                                    selectedGroup: undefined,
                                })
                            }
                            selectedGroupHandle={(group) => this.showDetailsUsersGroup(group)}
                        />
                    </EventsLayoutPrimaryInner>
                    <CommonTablePagination
                        tableData={tableData}
                        setPaging={(pageData) => this.setState({ pageData })}
                        searchMode={search !== ""}
                    />
                </EventsLayoutPrimary>

                {showDetailsUsersGroup && (
                    <GroupDetails
                        selectedGroup={selectedGroup}
                        setSelectedGroup={() => this.setState({ showDetailsUsersGroup: false })}
                        availableRoles={availableRoles}
                        detailsTab={detailsTab}
                        setDetailsTab={(tab) => this.setState({ detailsTab: tab })}
                        editUsersGroup={(group) => {
                            this.showUpdateUsersGroupModal(group);
                        }}
                        deleteUsersGroup={(group) => {
                            this.showDeleteUsersGroupModal(group);
                        }}
                    />
                )}

                {selectedUserId && (
                    <GeneralContextConsumer>
                        {(generalInfo) => (
                            <UserDetails
                                selectedUser={users?.find((u) => u.id === selectedUserId)}
                                setSelectedUser={() => this.setState({ selectedUserId: null })}
                                setDetailsTab={(tab) => this.setState({ detailsTab: tab })}
                                availableRoles={availableRoles}
                                usersGroups={usersGroups}
                                detailsTab={detailsTab}
                                updateHandler={this.showUpdateUserModal}
                                deleteHandler={this.showDeleteUserModal}
                                roleGeneralPermissions={getGeneralPermissionsTree(generalInfo)}
                                roleDGPermissions={getDeviceGroupsPermissionsTree(avavlibleDeviceGroups, generalInfo)}
                                roleDeviceTypesPermissions={getDevicesTypesPermissionsTree(generalInfo)}
                            />
                        )}
                    </GeneralContextConsumer>
                )}

                <>
                    {showCreateUserGroupModal && (
                        <UsersGroupModal
                            mode={wizardMode}
                            values={userGroupValues}
                            availableRoles={availableRoles}
                            closeHandler={this.closeCreateUsersGroupModal}
                            createHandler={this.createUsersGroup}
                            editHandler={() => this.setState({ showUpdateAllUsersModal: true })}
                            setValuesHandler={(n, v) =>
                                this.setState({ userGroupValues: { ...userGroupValues, [n]: v } })
                            }
                        />
                    )}

                    {showUpdateAllUsersModal && (
                        <UpdateAllUsersModal
                            onChoice={(toAll) => {
                                this.setState(
                                    {
                                        userGroupValues: { ...userGroupValues, updateAllUsers: toAll },
                                        showUpdateAllUsersModal: false,
                                    },
                                    () => this.updateUsersGroup(this.state.userGroupValues)
                                );
                            }}
                        />
                    )}
                </>

                <CreateUpdateWizard
                    visible={showCreateUserModal}
                    CreateHandler={this.createUser}
                    EditHandler={this.updateUser}
                    CloseHandler={this.closeCreateUserModal}
                    values={userValues}
                    title={
                        wizardMode === WIZARD_CREATE_MODE
                            ? strings("Создать пользователя")
                            : strings("Изменить пользователя")
                    }
                    step={step}
                    activeStep={activeStep}
                    NextStep={this.nextStep}
                    SetActiveStep={this.selectStep}
                    SetValuesProperty={this.setUserValuesProperty}
                    SetValuesProperties={this.setUserValuesProperties}
                    setLocations={this.setLocations}
                    steps={wizardSteps}
                    avalibleGroups={usersGroups}
                    availableRoles={availableRoles}
                    avavlibleLocations={avavlibleLocations}
                    Mode={wizardMode}
                />

                <DeleteModal
                    visible={showDeleteUserModal}
                    CloseHandler={() => this.setState({ showDeleteUserModal: false })}
                    RemoveHandler={this.deleteUser}
                    text={`${strings("Вы хотите удалить пользователя")} "${userValues?.login}". ${strings(
                        "Удалённого пользователя нельзя восстановить. Продолжить?"
                    )}`}
                />

                <DeleteModal
                    visible={showDeleteUsersGroupModal}
                    CloseHandler={() => this.setState({ showDeleteUsersGroupModal: false })}
                    RemoveHandler={this.deleteUsersGroup}
                    text={`${strings("Вы хотите удалить группу пользователей")} "${userGroupValues?.name}". ${strings(
                        "Удалённый раздел пользователей нельзя восстановить. Продолжить?"
                    )}`}
                />
            </>
        );
    }
}

export default withCultureContext(withSnackbar(UsersSettings));

import React from "react";
import styled from "styled-components";
import { FilterAccordion } from "../../../../components/Filters";
import { GetLocations, FillLocations, GetLocationList } from "../../../../../services/locations";
import { withSnackbar } from "notistack";
import { Text, TextField, Space, ModalSection, Row, Checkbox } from "headpoint-react-components";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

const ModalFormItem = styled.div`
    & + & {
        margin-top: 16px;
    }
`;

class ModalStepLocations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            locationList: [],
            search: "",
        };
    }

    componentDidMount = async () => {
        const [locationsStatus, locations] = await GetLocations();
        if (!locationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        this.setState({
            devicesLocationsTree: FillLocations(locations, []),
            locationList: [],
            search: "",
        });
    };

    openLocation = async (locationNode) => {
        const { devicesLocationsTree } = this.state;
        const [locationsStatus, children] = await GetLocations(locationNode.id);
        if (!locationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        locationNode.children = FillLocations(children, []);

        this.setState({
            devicesLocationsTree: devicesLocationsTree,
        });
    };

    searchLocations = async (like) => {
        const [locationsStatus, locations] = await GetLocationList({ like });
        if (!locationsStatus) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        this.setState({ search: like, locationList: locations ?? [] });
    };

    render() {
        const { visible, values, setValues, strings } = this.props;
        const { devicesLocationsTree, locationList, search } = this.state;
        if (!visible) {
            return null;
        }

        let locations = (search ? locationList : devicesLocationsTree) ?? [];
        return (
            <ModalSection>
                <ModalFormItem>
                    <Text>{strings("Локация:")}</Text>
                    <Space />
                    <Row marginBottom={12}>
                        <TextField
                            autoComplete={false}
                            colorVariant="light"
                            fullWidth
                            icon="search"
                            onChange={(e) => this.searchLocations(e.target?.value)}
                            placeholder={strings("Найти")}
                            type="search"
                            value={search}
                        />
                    </Row>
                    {search && search !== "" ? (
                        locations.map((location) => (
                            <Row marginBottom={12} key={location.id}>
                                <Checkbox
                                    label={location.name}
                                    checked={values.location.id === location.id}
                                    onChange={() => {
                                        const parentId = values.parentId === location.id ? null : location.id;
                                        setValues("location", parentId);
                                    }}
                                />
                            </Row>
                        ))
                    ) : (
                        <FilterAccordion
                            items={locations}
                            selected={values.location ? [values.location] : []}
                            openItem={this.openLocation}
                            selectHandler={(items) => {
                                let item = items.pop();
                                setValues("location", item);
                            }}
                        />
                    )}
                </ModalFormItem>
            </ModalSection>
        );
    }
}

export default withCultureContext(withSnackbar(ModalStepLocations));

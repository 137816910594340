import React from "react";
import moment from "moment";
import { startPeer, closePeer } from "../../../services/webrtc";
import { getDashUrl } from "../../../services/dash";
import { VideoWrapper, VideoControlsDivider, VideoControlBtn, VideoControlsVolume } from "headpoint-react-components";
import { getLiveUrls } from "../../../services/stream";
import { withSnackbar } from "notistack";
import { Mutex } from "async-mutex";
import {
    GeneralContextConsumer,
    getDeviceTypes,
    getServiceConfig,
    permissionExists,
    serviceExists,
} from "../../../contexts";
import { WebGLPlayer } from "../../../services/WebAssembly/webgl";
import { WsClient } from "../../../services/WebAssembly/ws_client";
import Ptz from "./Ptz";
import CreatePresetModal from "./modals/CreatePresetModal";
import UpdatePresetModal from "./modals/UpdatePresetModal";
import { PTZ_FEATURE_CODE } from "./constants";
import { ACTIVE_STREAM_PLAYER, CRUD_PRESETS_TOPIC_PREFIX, SHOW_STREAM_PLAYER_DIALOG_PREFIX } from "./streamTopics";
import PubSub from "pubsub-js";
import ApplyPresetModal from "./modals/ApplyPresetModal";
import Module from "../../../services/WebAssembly/decoder.mjs";
import { nanoid } from "nanoid";
import { isCanvasBlank } from "../../../utilites/CanvasUtills";
import { WebCodecsClient } from "../../../services/WebCodecs/ws_client";
import { DeletePreset } from "../../../services/presets";
import { DeleteModal } from "../../settings/components/DeleteModal";
import { connect } from "react-redux";
import { CheckStatus, SaveScreenshot } from "../../../services/screenshot";
import dashjs from "dashjs";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { SHOW_NEW_EVENT } from "../../../core/coreTopics";
import { DownloadFile } from "../../../services/storage";
import { decrementRefreshCount, initRefreshCount } from "../../../app/reducers/streamReducer";

class StreamPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.videoTag = `videoCtrl${props.frameIndex}`;
        this.lock = new Mutex();
        this.peer = null;
        this.dashPausedPeer = null;
        this.clientProtocol = props.frames[props.frameIndex]?.properties?.settings?.clientProtocol ?? "webrtc";
        this.playerId = nanoid();
        this.state = {
            volume: 0.0,
            playing: false,
            url: "",
            currentFrame: props.frames[props.frameIndex],
            presets: [],
            wasmModule: null,
            closeStreamTimer: null,
        };
    }

    async start(url, externalId) {
        const { generalInfo, enqueueSnackbar, strings } = this.props;

        switch (this.clientProtocol) {
            case "dash":
                if (this.dashPausedPeer) {
                    this.dashPausedPeer.destroy();
                    this.dashPausedPeer = null;
                }

                let [status, _url, responseCode] = await getDashUrl(url, this.state.currentFrame.id, externalId);
                if (!status) {
                    if (responseCode === "unauthorized") {
                        enqueueSnackbar(`${strings("Нет доступа к устройству")} "${this.state.currentFrame.name}"`, {
                            variant: "error",
                        });
                    } else {
                        enqueueSnackbar(
                            `${strings("Ошибка подключения к устройству")} "${this.state.currentFrame.name}"`,
                            {
                                variant: "error",
                            }
                        );
                    }
                    return;
                }

                const video = document.getElementById(this.videoTag);
                const token = window.localStorage.getItem("token");

                this.peer = dashjs.MediaPlayer().create();
                this.peer.extend(
                    "RequestModifier",
                    () => {
                        return {
                            modifyRequestHeader: (xhr) => {
                                // do xhr.setRequestHeader type stuff here ...
                                xhr.setRequestHeader("Authorization", "Bearer " + token);
                                return xhr;
                            },
                            modifyRequestURL: (url) => {
                                // modify url as you need - add querystring etc ...
                                return url;
                            },
                        };
                    },
                    true
                );

                this.peer.initialize(video, _url, true);

                this.peer.updateSettings({
                    streaming: {
                        retryAttempts: {
                            MediaSegment: 32,
                        },
                        delay: {
                            liveDelayFragmentCount: 0,
                            liveDelay: 5,
                        },
                        liveCatchup: {
                            enabled: true,
                        },
                    },
                });

                this.setState({
                    playing: true,
                });

                break;
            case "webcodecs":
                this.peer = new WebCodecsClient({ videoTag: this.videoTag });
                this.peer.open({
                    url: `${WebCodecsClient.getUrl(externalId)}`,
                    rtsp: url,
                    externalId: externalId,
                });

                this.setState({
                    playing: true,
                });

                break;
            case "websocket":
                if (!this.state.wasmModule) {
                    enqueueSnackbar(strings("Отсутствует модуль WebAssembly"), { variant: "error" });
                    return;
                }

                this.peer = new WsClient({ Module: this.state.wasmModule });

                if (this.peer.isOpen()) {
                    this.peer.close();
                }

                const canvas = document.getElementById(this.videoTag);
                let player = new WebGLPlayer(canvas);

                this.peer.open({
                    url: `${WsClient.getUrl(externalId)}`,
                    player: player,
                    rtsp: url,
                    externalId: externalId,
                });

                this.setState({
                    playing: true,
                });

                break;

            default:
                const { rtcPeerConfig } = getServiceConfig(generalInfo, "service.api.stream");
                if (!rtcPeerConfig) {
                    enqueueSnackbar(strings("Отсутствует конфигурация RTCPeerConnection"), { variant: "error" });
                    return;
                }

                const release = await this.lock.acquire();
                try {
                    const [startPeerStatus, pc, responseCode] = await startPeer(
                        url,
                        externalId,
                        document.getElementById(this.videoTag),
                        JSON.parse(rtcPeerConfig)
                    );
                    if (!startPeerStatus) {
                        if (responseCode === "unauthorized") {
                            enqueueSnackbar(
                                `${strings("Нет доступа к устройству")} "${this.state.currentFrame.name}"`,
                                {
                                    variant: "error",
                                }
                            );
                        } else {
                            enqueueSnackbar(
                                `${strings("Ошибка подключения к устройству")} "${this.state.currentFrame.name}"`,
                                {
                                    variant: "error",
                                }
                            );
                        }
                        return;
                    }
                    pc.ondatachannel = (event) => {
                        event.channel.onmessage = (ev) => {
                            const message = JSON.parse(ev.data);
                            if (message.error) {
                                switch (message.error) {
                                    case "unsupportedCodec":
                                        this.props.enqueueSnackbar(
                                            `${strings("Неподдерживаемый кодек трансляции для устройства")} "${
                                                this.state.currentFrame.name
                                            }"`,
                                            {
                                                variant: "error",
                                            }
                                        );
                                        break;
                                    case "cameraConnectionTimeout":
                                        this.props.enqueueSnackbar(
                                            `${strings("Таймаут подключения к трансляции для устройства")} "${
                                                this.state.currentFrame.name
                                            }"`,
                                            {
                                                variant: "error",
                                            }
                                        );
                                        break;
                                    case "cameraConnectionFailed":
                                    case "unknown":
                                        this.props.enqueueSnackbar(
                                            `${strings("Ошибка подключения к трансляции для устройства")} "${
                                                this.state.currentFrame.name
                                            }"`,
                                            {
                                                variant: "error",
                                            }
                                        );
                                        break;
                                    default:
                                        this.props.enqueueSnackbar(
                                            `${strings("Неизвестная ошибка подключения для устройства")} "${
                                                this.state.currentFrame.name
                                            }"`,
                                            {
                                                variant: "error",
                                            }
                                        );
                                        break;
                                }
                            }
                            PubSub.publish(
                                ACTIVE_STREAM_PLAYER + `${this.state.currentFrame.id}.${this.playerId}`,
                                message
                            );
                            console.error(message.message);
                        };
                    };
                    this.peer = pc;
                    this.setState({
                        playing: true,
                    });
                } finally {
                    release();
                }
                break;
        }
    }

    async stop() {
        if (this.clientProtocol === "webrtc") {
            await closePeer(this.peer);
        } else if (this.peer) {
            if (this.clientProtocol === "dash") {
                this.peer.pause();
                this.dashPausedPeer = this.peer;
            } else {
                this.peer.close();
            }
        }

        this.peer = null;
        this.setState({
            playing: false,
        });
    }

    async playPause() {
        const video = document.getElementById(this.videoTag);
        if (this.peer) {
            if (this.clientProtocol === "webrtc") {
                video.pause();
            }

            await this.stop();
        } else {
            if (this.clientProtocol === "webrtc") {
                video.play();
            }

            await this.start(this.state.url, this.state.currentFrame.id);
        }
    }

    getSnapshotCanvas() {
        if (this.clientProtocol === "websocket") {
            return document.getElementById(this.videoTag);
        }

        const video = document.getElementById(this.videoTag);

        let canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas;
    }

    tryExitFullscreen() {
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else {
                alert("Exit fullscreen doesn't work");
            }
        }
    }

    async getSnapshot() {
        const { currentFrame } = this.state;
        const { enqueueSnackbar, strings } = this.props;

        const cameraName = this.state.currentFrame.name;

        const fileName = `${cameraName}-${moment().local().format("YYYY-MM-DD_HH:mm:ss")}.png`;

        const deviceData = { deviceId: currentFrame.id, time: null };
        const body = { devices: [deviceData] };

        const [success, orderIds] = await SaveScreenshot(body);
        if (!success) {
            enqueueSnackbar(strings("Не удалось сохранить скриншот"), { variant: "error" });
            return;
        }

        const orderId = orderIds[0];

        let interval = setInterval(async function () {
            const [successCheckStatus, order] = await CheckStatus(orderId);
            if (!successCheckStatus) {
                enqueueSnackbar(strings("Не удалось получить статус скриншота"), { variant: "error" });
                clearInterval(interval);
                return;
            }
            switch (order.status) {
                case "InProgress":
                    break;
                case "Error":
                    enqueueSnackbar(strings("Ошибка при сохранении скриншота"), { variant: "error" });
                    clearInterval(interval);
                    break;
                case "Succeed":
                    await DownloadFile(order.properties.screenshotFileId, fileName);
                    clearInterval(interval);
                    break;
                default:
                    break;
            }
        }, 1000);
    }

    createEvent() {
        const { strings } = this.props;
        const { currentFrame } = this.state;

        let canvas = this.getSnapshotCanvas();

        if (isCanvasBlank(canvas)) {
            this.props.enqueueSnackbar(strings("Дождитесь начала трансляции для снятия скриншота"), {
                variant: "warning",
            });
            return;
        }

        this.tryExitFullscreen();

        const cameraName = this.state.currentFrame.name;
        let fileName = `${cameraName}-${moment().local().format("YYYY-MM-DD_HH:mm:ss")}.png`;

        canvas.toBlob((blob) => {
            let file = new File([blob], fileName, { type: "image/png" });

            PubSub.publish(SHOW_NEW_EVENT, {
                locationId: currentFrame.locationId,
                deviceId: currentFrame.id,
                file,
            });
        });
    }

    async refresh() {
        if (this.peer) {
            await this.stop();
        }

        await this.start(this.state.url, this.state.currentFrame.id);
    }

    async componentDidMount() {
        const { strings, dispatch, generalInfo } = this.props;
        dispatch(initRefreshCount(generalInfo));

        let deviceId = this.state.currentFrame.id;
        const deviceTypeId = this.state.currentFrame.typeId;
        const serviceId = getDeviceTypes(this.props.generalInfo)?.find((dt) => dt?.value === deviceTypeId)?.serviceId;

        if (!serviceId) {
            this.props.enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), { variant: "error" });
            return;
        }

        if (this.clientProtocol === "websocket") {
            try {
                this.setState({ wasmModule: await Module() });
            } catch (error) {
                console.log(error);
                this.props.enqueueSnackbar(strings("Ошибка загрузки WebAssembly"), { variant: "error" });
            }
        }

        const [status, liveUrl] = await getLiveUrls(serviceId, deviceId);
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения URL трансляции"), { variant: "error" });
            return;
        }

        if (liveUrl.length > 0 && liveUrl[0].url !== this.state.url) {
            await this.stop();
            await this.start(liveUrl[0].url, this.state.currentFrame.id);

            this.setState({
                url: liveUrl[0].url,
            });
        }

        this.showDialogTopic = PubSub.subscribe(SHOW_STREAM_PLAYER_DIALOG_PREFIX + deviceId, async (msg, data) => {
            this.setState({ openDialog: data });
        });

        this.activeStream = PubSub.subscribe(
            ACTIVE_STREAM_PLAYER + `${deviceId}.${this.playerId}`,
            async (msg, data) => {
                const { refreshCount } = this.props.stream;
                console.log(data);

                if (data.error === "cameraConnectionTimeout" && refreshCount > 0) {
                    dispatch(decrementRefreshCount());
                    await this.refresh();
                } else {
                    await this.stop();
                }
            }
        );

        return async () => {
            await this.stop();
        };
    }
    setOnStreamFeatureClosed(player, timeout) {
        const options = { threshold: 0.1 };

        const callback = ([bodyElement]) => {
            if (bodyElement.isIntersecting) {
                clearTimeout(player.state.closeStreamTimer);
                player.setState({ closeStreamTimer: null });
            } else {
                player.setState({ closeStreamTimer: setTimeout(() => player.stop(), timeout) });
            }
        };

        const observer = new window.IntersectionObserver(callback, options);
        observer.observe(document.getElementById(this.videoTag));
    }

    async componentWillUnmount() {
        if (this.showDialogTopic) {
            PubSub.unsubscribe(this.showDialogTopic);
        }

        if (this.activeStream) {
            PubSub.unsubscribe(this.activeStream);
        }

        if (this.clientProtocol === "webrtc") {
            await closePeer(this.peer);
            return;
        }

        if (this.clientProtocol === "dash") {
            if (this.peer) {
                this.peer.destroy();
            }
            if (this.dashPausedPeer) {
                this.dashPausedPeer.destroy();
            }
            return;
        }

        if (this.peer) {
            this.peer.close();
        }
    }

    deletePresetHandler = async () => {
        const { enqueueSnackbar, strings } = this.props;
        const { openDialog } = this.state;
        const { camera, preset } = openDialog.data;

        const [success, code] = await DeletePreset(preset.id, camera.id);
        if (success) {
            enqueueSnackbar(strings("Пресет удален"), { variant: "success" });
        } else {
            switch (code) {
                case 403: // forbidden
                    enqueueSnackbar(strings("Не хватает прав для удаления пресета"), { variant: "warning" });
                    break;

                default:
                    enqueueSnackbar(strings("Не удалось удалить пресет"), { variant: "error" });
                    break;
            }

            return;
        }

        PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + camera?.id, undefined);
        PubSub.publish(CRUD_PRESETS_TOPIC_PREFIX + camera?.id, {
            type: "delete",
            data: {
                preset,
            },
        });
    };

    showModals = () => {
        const { openDialog, currentFrame } = this.state;
        const { strings } = this.props;

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        {openDialog?.type === "createPreset" && (
                            <CreatePresetModal
                                data={openDialog?.data}
                                generalInfo={generalInfo}
                                CloseHandler={() =>
                                    PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + currentFrame?.id, undefined)
                                }
                            />
                        )}

                        {openDialog?.type === "updatePreset" && (
                            <UpdatePresetModal
                                data={openDialog?.data}
                                generalInfo={generalInfo}
                                CloseHandler={() =>
                                    PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + currentFrame?.id, undefined)
                                }
                            />
                        )}

                        {openDialog?.type === "deletePreset" && (
                            <DeleteModal
                                visible={openDialog?.type === "deletePreset"}
                                CloseHandler={() =>
                                    PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + currentFrame?.id, undefined)
                                }
                                RemoveHandler={this.deletePresetHandler}
                                text={`${strings("Вы хотите удалить пресет")} "${
                                    openDialog?.data?.preset?.name
                                }". ${strings("Удалённый пресет нельзя восстановить. Продолжить?")}`}
                            />
                        )}

                        {openDialog?.type === "applyPreset" && (
                            <ApplyPresetModal data={openDialog?.data} generalInfo={generalInfo} />
                        )}
                    </>
                )}
            </GeneralContextConsumer>
        );
    };

    render() {
        let { currentFrame } = this.state;
        const { strings } = this.props;

        const featureSettings = currentFrame?.properties?.settings?.features;
        const ptzFeatureSettings = featureSettings ? featureSettings[PTZ_FEATURE_CODE] : undefined;

        const { userInfo } = this.props;
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <VideoWrapper
                            videoElement={
                                <>
                                    {this.showModals()}
                                    {this.clientProtocol === "websocket" ? (
                                        <canvas
                                            id={this.videoTag}
                                            style={{
                                                position: "absolute",
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                width: "auto",
                                                height: "auto",
                                                left: "50%",
                                                top: "50%",
                                                transform: "translate(-50%, -50%)",
                                            }}
                                        />
                                    ) : (
                                        <video
                                            id={this.videoTag}
                                            muted
                                            autoPlay
                                            style={{ position: "absolute", width: "100%", height: "100%" }}
                                        />
                                    )}
                                </>
                            }
                            mainControls={
                                <React.Fragment>
                                    {this.state.playing ? (
                                        <VideoControlBtn
                                            title={strings("Пауза")}
                                            icon="pause"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                void this.playPause();
                                            }}
                                        />
                                    ) : (
                                        <VideoControlBtn
                                            title={strings("Начать")}
                                            icon="play"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                void this.playPause();
                                            }}
                                        />
                                    )}
                                    {(this.clientProtocol === "webrtc" || this.clientProtocol === "dash") && (
                                        <VideoControlsVolume
                                            value={this.state.volume}
                                            onChange={(volume) => {
                                                console.log(volume);
                                                let video = document.getElementById(this.videoTag);
                                                video.volume = volume;
                                                if (volume > 0) {
                                                    video.muted = false;
                                                }
                                                this.setState({ volume });
                                            }}
                                        />
                                    )}
                                    <VideoControlsDivider />
                                    <VideoControlBtn
                                        title={strings("Обновить")}
                                        icon="refresh"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            void this.refresh();
                                        }}
                                    />
                                    <VideoControlBtn
                                        title={strings("Снимок экрана")}
                                        icon="screenshot"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.getSnapshot();
                                        }}
                                    />
                                    {ptzFeatureSettings &&
                                        serviceExists(generalInfo, ptzFeatureSettings?.serviceCode) &&
                                        permissionExists(userInfo, ["video.ptz.access"]) && (
                                            <Ptz currentFrame={currentFrame} strings={strings} />
                                        )}
                                    {serviceExists(generalInfo, "service.adapters.userEvents") &&
                                    permissionExists(userInfo, "events.user.post") ? (
                                        <VideoControlBtn
                                            title={strings("Создать событие")}
                                            icon="addevent"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.createEvent();
                                            }}
                                        />
                                    ) : (
                                        <div />
                                    )}
                                </React.Fragment>
                            }
                            withFullscreen
                            fullscreenToggleTitle={strings("На весь экран")}
                        />
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        stream: state.stream,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(StreamPlayer)));

import { urlWithQuery } from "../utilites/UrlUtils";

const path = "/locations-api/locations";

export const GetLocationList = async ({ like, limit, ids }) => {
    const token = window.localStorage.getItem("token");
    try {
        const p = `${path}/list`;
        const params = new URLSearchParams();
        if (like) {
            params.append("like", like);
        }

        if (limit) {
            params.append("limit", limit);
        }

        ids?.forEach((id) => {
            params.append("ids", id);
        });

        let response = await fetch(urlWithQuery(p, params), {
            method: "Get",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.locations];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetLocations = async (parent) => {
    const token = window.localStorage.getItem("token");
    try {
        const p = `${path}/tree`;
        const params = new URLSearchParams();
        if (parent) {
            params.append("parent", parent);
        }

        let response = await fetch(urlWithQuery(p, params), {
            method: "Get",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.locations?.sort(CompareDevicesAndLocations)];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const FillNode = (node, locations, devices) => {
    node.children = devices
        ?.filter((d) => d.locationId === node.id)
        .map((d) => ({ ...d, tag: "device" }))
        ?.sort(CompareDevicesAndLocations);

    const childLocations = FillLocations(locations, devices);
    node.children = (childLocations ?? []).concat(node.children);
};

const CompareDevices = (a, b) =>
    (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });

export const FillLocations = (locations, devices) => {
    return locations.map((l) => {
        let node = { ...l, tag: "location", isClosed: true };
        node.children = devices
            ?.filter((d) => d.locationId === l.id)
            .sort(CompareDevices)
            .map((d) => ({ ...d, tag: "device" }));
        return node;
    });
};

export const GetNodeById = (tree, id) => {
    for (let l of tree) {
        if (l?.id === id) {
            return l;
        } else if (l.children) {
            const result = GetNodeById(l.children, id);
            if (result) {
                return result;
            }
        }
    }

    return null;
};

export const AddDeviceToNode = (node, device) => {
    if (!node || node.isClosed) {
        return;
    }

    if (node.children) {
        node.children.push({ ...device, tag: "device" });
    } else {
        node.children = [{ ...device, tag: "device" }];
    }

    node.children.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
    });
};

export const RemoveFromNode = (node, id) => {
    if (node?.children) {
        node.children = node.children.filter((c) => c.id !== id);
    }
};

export const RemoveFromTree = (tree, id) => {
    if (!tree) {
        return null;
    }

    tree = tree.filter((n) => n.id !== id);
    tree.forEach((n) => {
        if (n.children) {
            n.children = RemoveFromTree(n.children, id);
        }
    });

    return tree;
};

const CompareDevicesAndLocations = (a, b) => {
    if (a.tag === "device" && b.tag === "location") {
        return 1;
    }

    if (a.tag === "location" && b.tag === "device") {
        return -1;
    }

    return (a.name ?? "").localeCompare(b.name ?? "", undefined, { numeric: true, sensitivity: "base" });
};

export const AddDeviceToTree = (tree, locationId, device) => {
    if (!tree) {
        return;
    }

    if (!locationId || locationId === "00000000-0000-0000-0000-000000000000") {
        tree.push({ ...device, tag: "device" });
        return;
    }

    tree.forEach((n) => {
        if (n.id === locationId) {
            if (n.children) {
                n.children.push({ ...device, tag: "device" });
                n.children.sort(CompareDevicesAndLocations);
            } else {
                n.children = [{ ...device, tag: "device" }];
            }
        } else if (n.children) {
            AddDeviceToTree(n.children, locationId, device);
        }
    });
};

export const AddLocationToTree = (tree, parentId, location) => {
    if (!tree) {
        return;
    }

    if (!parentId || parentId === "00000000-0000-0000-0000-000000000000") {
        tree.push({ ...location, tag: "location", isClosed: true });
        return;
    }

    tree.forEach((n) => {
        if (n.id === parentId) {
            if (n.children) {
                n.children.push({ ...location, tag: "location", isClosed: true });
                n.children.sort(CompareDevicesAndLocations);
            } else {
                n.children = [{ ...location, tag: "location", isClosed: true }];
            }
        } else if (n.children) {
            AddLocationToTree(n.children, parentId, location);
        }
    });
};

export const CreateLocation = async (location) => {
    try {
        const token = window.localStorage.getItem("token");
        let response = await fetch(path, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(location),
        });

        return [response.ok, response.status, response.ok ? (await response.json())?.id : null];
    } catch (e) {
        console.error(e);
        return [false, null];
    }
};

export const DeleteLocation = async (id) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response, response.status];
};

export const UpdateLocation = async (location) => {
    let id = location.id;
    let data = { ...location };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.status === 200, response.status, await response.json()];
};

export const GetLocationNames = async (ids) => {
    const [status, result] = await GetLocationList({ ids });
    if (!status) {
        return [false, null];
    }

    const map = new Map();
    result?.forEach((l) => {
        map.set(l.id, l.name);
    });

    return [status, map];
};

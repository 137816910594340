import React, { useCallback, useEffect } from "react";
import { Modal, ModalBody, ModalBodyItem, ModalHeader } from "headpoint-react-components";
import PQueue from "p-queue";
import { GetTask } from "../../../../../../services/devices";
import { withSnackbar } from "notistack";
import ProgressBar from "../../../../../components/ProgressBar";
import { isNumber } from "../../../../../../utilites/CommonUtils";

const queue = new PQueue({ concurrency: 1, autoStart: true });

function TaskStatusModal({ task, setTask, enqueueSnackbar, strings }) {
    const updateStatus = useCallback(async () => {
        const [isSuccess, , updatedTask] = await GetTask(task.id);

        if (!isSuccess) {
            enqueueSnackbar(strings("Не удалось получить статус задачи"), { variant: "error" });
        } else {
            setTask(updatedTask);
        }
    }, [task.id, setTask, enqueueSnackbar, strings]);

    const executeUpdate = useCallback(async () => await queue.add(async () => await updateStatus()), [updateStatus]);

    useEffect(() => {
        const intervalId = setInterval(executeUpdate, 1000);
        return () => {
            clearInterval(intervalId);
            queue.clear();
        };
    }, [executeUpdate]);

    const statusToHeader = useCallback(
        (status) => {
            switch (status) {
                case "FileSaved":
                    return strings("Файл сохранен");
                case "Validated":
                    return strings("Проверка пройдена");
                case "EntitiesSaved":
                    return strings("Записи сохранены");
                case "Processed":
                    return strings("Выполнено");
                case "Finished":
                    return strings("Завершено");
                case "Error":
                    return strings("Ошибка");
                default:
                    return strings("Обработка");
            }
        },
        [strings]
    );

    const progress = calculateProgress(task);

    return (
        <Modal size="sm">
            <ModalHeader title={statusToHeader(task.info.status)} align="center" />
            <ModalBody>
                <ModalBodyItem>
                    <ProgressBar min={0} max={100} current={progress} text={`${strings("Прогресс")}: ${progress}%`} />
                </ModalBodyItem>
            </ModalBody>
        </Modal>
    );
}

function calculateProgress(task) {
    const percentageShare = 30;

    let progress = 0;

    if (validationHasStarted(task)) {
        progress += 10;
    }

    const currentDeleted = task.info.properties.deleted;
    const currentDeletedWithErrors = task.info.properties.deletedWithErrors;
    const willDeleted = task.info.properties.willDelete;
    progress += calculateOverallPercentage(currentDeleted + currentDeletedWithErrors, willDeleted, percentageShare);

    const currentUpdated = task.info.properties.updated;
    const currentUpdatedWithErrors = task.info.properties.updatedWithErrors;
    const willUpdated = task.info.properties.willUpdate;
    progress += calculateOverallPercentage(currentUpdated + currentUpdatedWithErrors, willUpdated, percentageShare);

    const currentCreated = task.info.properties.creared;
    const currentCreatedWithErrors = task.info.properties.crearedWithErrors;
    const willCreated = task.info.properties.willCreate;
    progress += calculateOverallPercentage(currentCreated + currentCreatedWithErrors, willCreated, percentageShare);

    return progress;
}

function validationHasStarted(task) {
    return task.info.status !== "FileSaved";
}

function calculateOverallPercentage(value, inclusiveValue, inclusiveValuePercentage) {
    const overallPercentage = value / (inclusiveValue / inclusiveValuePercentage);
    return isNumber(overallPercentage) ? overallPercentage : 0;
}

export default withSnackbar(TaskStatusModal);

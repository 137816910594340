import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
    filter: {
        devicesFilter: [],
        locationsFilter: [],
        eventTypesFilter: [],
        adapterFilters: [],
        intervalFilter: "all",
        eventsFilter: {
            showInfo: false,
            showWarning: false,
            showDanger: false,
            showRead: false,
        },
        selectedFilter: undefined,
        zonesFilter: [],
        groupsFilter: [],
    },
    map: {
        center: undefined,
        zoom: undefined,
    },
    selected: undefined,
    showViewAngle: false,
    isEditing: false,
};

export const mapSlice = createSlice({
    name: "map",
    initialState: initialState,
    reducers: {
        saveDevices: (state, action) => {
            state.filter.devicesFilter = action.payload?.map((d) => ({
                id: d.id,
                locationId: d.locationId,
            }));
        },
        saveLocations: (state, action) => {
            state.filter.locationsFilter = action.payload?.map((d) => ({
                id: d.id,
                parendId: d.parendId,
                parentIds: d.parentIds,
                children: cloneDeep(d.children),
            }));
        },
        saveZones: (state, action) => {
            state.filter.zonesFilter = action.payload?.map((z) => ({
                id: z.id,
                groupId: z.groupId,
            }));
        },
        saveGroups: (state, action) => {
            state.filter.groupsFilter = action.payload?.map((g) => ({
                id: g.id,
                children: g.children.map((c) => ({ id: c.id, groupId: c.groupId })),
            }));
        },
        saveEventTypes: (state, action) => {
            state.filter.eventTypesFilter = action.payload;
        },
        saveInterval: (state, action) => {
            state.filter.intervalFilter = action.payload;
        },
        saveEventFilters: (state, action) => {
            state.filter.eventsFilter = { ...action.payload };
        },
        saveSelectedFilter: (state, action) => {
            state.filter.selectedFilter = action.payload;
        },
        saveMapPosition: (state, action) => {
            state.map = action.payload;
        },
        saveSelected: (state, action) => {
            state.selected = action.payload;
        },
        saveShowViewAngle: (state, action) => {
            state.showViewAngle = action.payload;
        },
        saveIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        resetFilter: (state) => {
            state.filter = initialState.filter;
        },
    },
});

export const {
    saveDevices,
    saveLocations,
    saveEventFilters,
    saveInterval,
    saveEventTypes,
    saveSelectedFilter,
    saveMapPosition,
    saveSelected,
    resetFilter,
    saveShowViewAngle,
    saveIsEditing,
    saveZones,
    saveGroups,
} = mapSlice.actions;

export default mapSlice.reducer;

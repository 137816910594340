import React from "react";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle,
} from "../../../../components/DetailsBox";
import { Button, Dropdown, DropdownItem, Tab, TabPanel, Text } from "headpoint-react-components";
import DetailsTabMain from "./DetailsTabMain";
import { checkFeatureByDeviceType, GeneralContextConsumer, getAdapters, getDeviceTypes } from "../../../../../contexts";
import DetailsTabDeviceType from "./DetailsTabDeviceType";
import PubSub from "pubsub-js";
import { COPY_RTSP_URL, SHOW_DELETE_DEVICE, SHOW_UPDATE_DEVICE } from "../deviceSettingsTopics";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DeviceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
        };
    }

    render() {
        const { selectedDevice, generalInfo, strings } = this.props;
        const { detailsTab } = this.state;
        const detailsTabs = [
            {
                title: strings("Общее"),
                key: "main",
                component: DetailsTabMain,
            },
        ];

        const deviceType = getDeviceTypes(generalInfo)?.find((t) => t.value === selectedDevice.typeId);
        const deviceGeneralSettings = deviceType?.settings?.general;
        if (deviceGeneralSettings) {
            detailsTabs.push({
                title: deviceType.label,
                key: "deviceType",
                component: DetailsTabDeviceType,
            });
        }

        const tab = detailsTabs.find((t) => t.key === detailsTab) ?? detailsTabs.find((s) => s.key === "main");
        const { key: actualDetailsTab, component: actualDetailsComponent } = tab;

        const isCamera = checkFeatureByDeviceType(deviceType?.value, getAdapters(generalInfo), "video.stream");

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {selectedDevice.name}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                        {isCamera ? (
                                            <DropdownItem
                                                as="button"
                                                onClick={() => PubSub.publish(COPY_RTSP_URL, selectedDevice)}
                                            >
                                                {strings("Копировать RTSP URL")}
                                            </DropdownItem>
                                        ) : (
                                            <></>
                                        )}
                                        <DropdownItem
                                            as="button"
                                            onClick={() =>
                                                PubSub.publish(SHOW_UPDATE_DEVICE, {
                                                    device: selectedDevice,
                                                    generalInfo,
                                                })
                                            }
                                        >
                                            {strings("Редактировать")}
                                        </DropdownItem>
                                        <DropdownItem
                                            as="button"
                                            colorVariant={"danger"}
                                            onClick={() => PubSub.publish(SHOW_DELETE_DEVICE, selectedDevice)}
                                        >
                                            {strings("Удалить")}
                                        </DropdownItem>
                                    </Dropdown>
                                    <Button
                                        icon="close"
                                        variant="secondary"
                                        onClick={() => this.props.closeDetails()}
                                    />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    {detailsTabs?.map((tab) => (
                                        <Tab
                                            key={tab.title}
                                            label={strings(tab.title)}
                                            isActive={actualDetailsTab === tab.key}
                                            onClick={() => this.setState({ detailsTab: tab.key })}
                                        />
                                    ))}
                                </TabPanel>
                            </DetailsBoxTabs>
                            {actualDetailsComponent &&
                                React.createElement(actualDetailsComponent, {
                                    ...this.props,
                                    deviceGeneralSettings,
                                    deviceType,
                                })}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </GeneralContextConsumer>
        );
    }
}

export default withCultureContext(DeviceDetails);

import React from "react";
import StreamPlayer from "../../../stream/components/StreamPlayer";
import { AspectBox } from "../../../components/AspectBox";
import { withSnackbar } from "notistack";
import { DetailsBoxContent, DetailsBoxMedia } from "../../../components/DetailsBox";
import { TableBody, Table, TableCell, TableRow, Text } from "headpoint-react-components";
import { serviceExists, permissionExists, checkFeatureByDeviceType } from "../../../../contexts";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import { SearchControlZonesList } from "../../../../services/controlZones";

class DetailsTabMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            controlZones: [],
        };
    }

    componentDidMount = async () => {
        const controlZones = await this.getControlZones(this.props.device.id);
        this.setState({ controlZones });
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.device.id !== this.props.device.id) {
            const controlZones = await this.getControlZones(this.props.device.id);
            this.setState({ controlZones });
        }
    };

    getControlZones = async (deviceId) => {
        const { strings } = this.props;

        const [status, controlZones] = await SearchControlZonesList({ deviceIds: [deviceId] });

        if (!status) {
            this.props.enqueueSnackbar(`${strings("Ошибка получения зон контроля")} ${deviceId}`, {
                variant: "error",
            });
            return [];
        }

        return controlZones;
    };

    render() {
        const { device, location, userPermissions, countDanger, countWarning, adapters, deviceType, generalInfo } =
            this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        {checkFeatureByDeviceType(deviceType?.value, adapters, "video.stream") &&
                            serviceExists(generalInfo, [
                                "service.api.stream",
                                "service.api.locations",
                                "service.api.devices",
                                "video.webrtc.service",
                                "rtsp.proxy.service",
                            ]) &&
                            permissionExists(userPermissions, "video.stream.access") && (
                                <DetailsBoxMedia>
                                    <AspectBox aspect="16:9">
                                        <StreamPlayer
                                            key={device?.id ?? ""}
                                            presets={[]}
                                            frameIndex={0}
                                            frames={[device]}
                                            generalInfo={generalInfo}
                                        />
                                    </AspectBox>
                                </DetailsBoxMedia>
                            )}
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Тип объекта")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {strings(deviceType?.label) ?? "..."}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Всего непросмотренных событий")}</TableCell>
                                    <TableCell>{/* An empty cell to save the table structure*/}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell indent={30} noBorder>
                                        {strings("Тревожных")}
                                    </TableCell>
                                    <TableCell>
                                        <Text color="danger" variant="body-sm">
                                            {countDanger === -1 ? "..." : countDanger}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell indent={30}>{strings("Требующих внимания")}</TableCell>
                                    <TableCell>
                                        <Text color="warning" variant="body-sm">
                                            {countWarning === -1 ? "..." : countWarning}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Зоны контроля")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {this.state.controlZones.map((zone) => zone.name).join(", ")}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Локация")}</TableCell>
                                    <TableCell indent={1}>
                                        <Text color="primary" variant="body-sm">
                                            {location?.name}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                {device?.properties?.observedObjects && (
                                    <TableRow>
                                        <TableCell>{strings("Объекты наблюдения")}</TableCell>
                                        <TableCell indent={1}>
                                            <Text scroll color="primary" variant="body-sm">
                                                {device?.properties?.observedObjects}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(DetailsTabMain);

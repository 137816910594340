import { Row, Table, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { nanoid } from "nanoid";
import { useSelector } from "react-redux";
import { convertUTCToLocal, getTimeInterval } from "../../utilites/TimeUtils";
import { withCultureContext } from "../../contexts/cultureContext/CultureContext";
import moment from "moment";
import { formatAvailabilityDaysCount } from "../../utilites/TextUtils";

const IntervalsTable = ({ strings }) => {
    const daysOfWeek = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];

    const scheduleInfo = useSelector((state) => state.schedule.scheduleInfo);

    const OneTimeType = () => (
        <Table isFixed noPadding>
            <TableBody>
                <TableRow>
                    <TableCell>{strings("Тип интервала выполнения")}</TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Однократное")}
                        </Text>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings("Время")}</TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Точное время")}
                        </Text>

                        <Row marginTop={4}>
                            <div>
                                {scheduleInfo?.properties?.times?.map((time) => (
                                    <div key={nanoid()}>{convertUTCToLocal(time) ?? "..."}</div>
                                ))}
                            </div>
                        </Row>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>{strings("Дата")}</TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Точная дата")}
                        </Text>

                        <Row marginTop={4}>
                            <div>
                                {scheduleInfo?.properties?.dates?.map((date) => (
                                    <div key={nanoid()}>{date ?? "..."}</div>
                                ))}
                            </div>
                        </Row>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );

    const RepetitionTimeType = () => (
        <Table isFixed noPadding>
            <TableBody>
                <TableRow>
                    <TableCell>{strings("Тип интервала выполнения")}</TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Повторение")}
                        </Text>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings("Дата и время начала")}</TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {scheduleInfo?.start ? moment(scheduleInfo?.start).format("DD-MM-YYYY HH:mm") : "..."}
                        </Text>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings("Дата и время завершения")}</TableCell>
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {scheduleInfo?.end
                                ? moment(scheduleInfo?.end).format("DD-MM-YYYY HH:mm")
                                : strings("Бесконечное расписание")}
                        </Text>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings("Время")}</TableCell>
                    {TimeType()}
                </TableRow>
                <TableRow>
                    <TableCell>{strings("Повторение")}</TableCell>
                    {DateType()}
                </TableRow>
            </TableBody>
        </Table>
    );

    const TimeType = () => {
        switch (scheduleInfo?.properties?.timeType) {
            case "time":
                return (
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Точное время")}
                        </Text>
                        <Row marginTop={4}>
                            <div>
                                {scheduleInfo?.properties?.times?.map((time) => (
                                    <div key={nanoid()}>{convertUTCToLocal(time) ?? "..."}</div>
                                ))}
                            </div>
                        </Row>
                    </TableCell>
                );
            case "interval":
                return (
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Временной интервал")}
                        </Text>
                        <Row marginTop={4}>
                            <div>
                                {scheduleInfo?.properties?.intervals?.map((interval) => (
                                    <div key={nanoid()}>
                                        {`${convertUTCToLocal(interval.from)} - ${convertUTCToLocal(
                                            interval.to
                                        )} ${getTimeInterval(interval)}` ?? "..."}
                                    </div>
                                ))}
                            </div>
                        </Row>
                    </TableCell>
                );
            default:
                return <></>;
        }
    };

    const DateType = () => {
        switch (scheduleInfo?.properties?.dateType) {
            case "day":
                return (
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Повтор каждый день")}
                        </Text>
                        <Row marginTop={4}>
                            <div>
                                <div key={nanoid()}>{`${strings("Повтор каждый")} ${getDays()} ${strings(
                                    formatAvailabilityDaysCount(getDays())
                                )}`}</div>
                            </div>
                        </Row>
                    </TableCell>
                );
            case "week":
                return (
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Еженедельно, дни недели")}
                        </Text>
                        <Row marginTop={4}>
                            <div>
                                {getWeekDays().map((day) => (
                                    <div key={nanoid()}>{daysOfWeek[day]}</div>
                                ))}
                            </div>
                        </Row>
                    </TableCell>
                );
            case "month":
                return (
                    <TableCell>
                        <Text color="primary" variant="body-sm">
                            {strings("Ежемесячно, дни месяца")}
                        </Text>
                        <Row marginTop={4}>
                            <div>
                                {scheduleInfo?.properties?.daysOfMonth.map((day) => (
                                    <div key={nanoid()}>{day}</div>
                                ))}
                            </div>
                        </Row>
                    </TableCell>
                );
            default:
                return <></>;
        }
    };

    const getDays = () => {
        const days = scheduleInfo?.properties?.days;
        let resultDays = "";

        for (let i = 0; i < days.length; i++) {
            if (i === days.length - 1) {
                resultDays += days[i];
            } else {
                resultDays += days[i] + ", ";
            }
        }

        return resultDays;
    };

    const getWeekDays = () => scheduleInfo?.properties?.daysOfWeek.map((day, i) => (day ? i : null));

    const Intervals = () => {
        switch (scheduleInfo?.properties?.intervalsType) {
            case "oneTime":
                return OneTimeType();
            case "repetition":
                return RepetitionTimeType();
            default:
                return <></>;
        }
    };

    return Intervals();
};

export default withCultureContext(IntervalsTable);

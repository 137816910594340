import React from "react";
import styled from "styled-components";

export const AspectBox = ({ children, aspect = "1:1" }) => (
    <AspectBoxContainer aspect={aspect}>
        <AspectBoxInner>{children}</AspectBoxInner>
    </AspectBoxContainer>
);

const getAspect = (aspect) => {
    switch (aspect) {
        case "16:9":
            return `${(9 / 16) * 100}%`;

        case "2:1":
            return "50%";

        case "1:1":
            return "100%";

        case "1:2":
            return "200%";

        default:
            return "100%";
    }
};

const AspectBoxContainer = styled.div`
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: ${({ aspect }) => getAspect(aspect)};
    position: relative;
`;

const AspectBoxInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & img {
        max-width: 100%;
        max-height: 100%;
    }
`;

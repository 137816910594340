import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { GetLocationNames } from "../../../../../services/locations";
import { checkSuperUser } from "../../../../../contexts/GeneralContext";
import { Text, Table, TableBody, TableRow, TableCell, TableAccordionItem } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsTabAccesability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationIdNamePairs: null,
        };
    }

    async componentDidMount() {
        const { selectedUser } = this.props;
        await this.loadLocationNames(selectedUser);
    }

    async componentDidUpdate(prevProps, _) {
        const { selectedUser: newUser } = this.props;
        const { selectedUser: oldUser } = prevProps;
        const newLocations = newUser?.roleProperties?.locations;
        const oldLocations = oldUser?.roleProperties?.locations;
        if (JSON.stringify(newLocations) !== JSON.stringify(oldLocations)) {
            await this.loadLocationNames(newUser);
        }
    }

    loadLocationNames = async (user) => {
        const { strings } = this.props;

        if (checkSuperUser(user)) {
            this.setState({ locationIdNamePairs: [[nanoid(), strings("Доступны все локации системы")]] });
            return;
        }

        const locationIds = !!user?.roleProperties?.locations
            ? Object.keys(user?.roleProperties?.locations).map((l) => l)
            : [];

        if (!locationIds || !locationIds.length) {
            this.setState({ locationIdNamePairs: [[nanoid(), strings("Нет доступных локаций")]] });
            return;
        }

        const [getNamesStatus, locationIdNamePairs] = await GetLocationNames(locationIds);
        if (!getNamesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка при получении названий локаций"), { variant: "error" });
            return;
        }

        this.setState({ locationIdNamePairs: Array.from(locationIdNamePairs) });
    };

    flatten = (object) => {
        const getChild = (key, objectBit) => {
            return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
        };

        return Object.assign(
            {},
            ...(function _flatten(objectBit) {
                return [].concat(
                    ...Object.keys(objectBit).map((key) =>
                        typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                    )
                );
            })(object)
        );
    };

    getUserRole = (roleId) => {
        const { availableRoles } = this.props;
        if (!availableRoles || !roleId) {
            return null;
        }

        return availableRoles.find((role) => role.id === roleId);
    };

    matchRolesFunctions = (selectedUser, roleGeneralPermissions) => {
        const { strings } = this.props;

        if (checkSuperUser(selectedUser)) {
            return [{ id: nanoid(), name: strings("Доступны все функции системы") }];
        }

        const selectedRole = this.getUserRole(selectedUser?.roleId);
        const rolesFunctions = roleGeneralPermissions
            .map((item) => ({
                ...item,
                children: item.children?.filter((permission) =>
                    selectedRole?.properties?.permissions.includes(permission.id)
                ),
            }))
            .filter((item) => item.children?.length > 0);

        if (!rolesFunctions || !rolesFunctions.length) {
            return [{ id: nanoid(), name: strings("Нет доступных функций") }];
        }

        return rolesFunctions;
    };

    matchRolesDeviceTypesPermissions = (selectedUser, roleDeviceTypesPermissions) => {
        const { strings } = this.props;

        if (checkSuperUser(selectedUser)) {
            return [{ id: nanoid(), name: strings("Доступны все типы оборудования системы") }];
        }

        const selectedRole = this.getUserRole(selectedUser?.roleId);
        const rolesDeviceTypes = roleDeviceTypesPermissions
            .map((type) => ({
                ...type,
                items: Object.values(this.flatten(type)).filter((item) =>
                    selectedRole?.properties?.permissions.includes(item.id)
                ),
            }))
            .filter((type) => type.items?.length > 0);

        if (!rolesDeviceTypes || !rolesDeviceTypes.length) {
            return [{ id: nanoid(), name: strings("Нет доступных типов оборудования") }];
        }

        return rolesDeviceTypes;
    };

    matchRolesDGPermissions = (selectedUser, roleDGPermissions) => {
        const { strings } = this.props;

        if (checkSuperUser(selectedUser)) {
            return [{ id: nanoid(), name: strings("Доступны все группы оборудования системы") }];
        }

        const selectedRole = this.getUserRole(selectedUser?.roleId);
        const rolesDG = roleDGPermissions
            .map((group) => ({
                ...group,
                deviceTypes: group.children
                    ?.map((item) => ({
                        ...item,
                        items: Object.values(this.flatten(item)).filter((item) =>
                            selectedRole?.properties?.permissions.includes(item.id)
                        ),
                    }))
                    .filter((item) => item.items?.length > 0),
            }))
            .filter((group) => group.deviceTypes?.length > 0);

        if (!rolesDG || !rolesDG.length) {
            return [{ id: nanoid(), name: strings("Нет доступных групп оборудования") }];
        }

        return rolesDG;
    };

    render() {
        const { selectedUser, roleGeneralPermissions, roleDeviceTypesPermissions, roleDGPermissions, strings } =
            this.props;
        const { locationIdNamePairs } = this.state;
        const rolesFunctions = this.matchRolesFunctions(selectedUser, roleGeneralPermissions);
        const deviceTypes = this.matchRolesDeviceTypesPermissions(selectedUser, roleDeviceTypesPermissions);
        const rolesDG = this.matchRolesDGPermissions(selectedUser, roleDGPermissions);

        return (
            <DetailsBoxContent>
                <Table isFixed>
                    <TableBody>
                        <TableAccordionItem
                            toggle={
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {strings("Локации")}
                                    </Text>
                                </TableCell>
                            }
                        >
                            {locationIdNamePairs?.map((l) => (
                                <TableRow key={l[0]}>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {`${l[1]}`}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableAccordionItem>

                        <TableAccordionItem
                            toggle={
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {strings("Функции интерфейса")}
                                    </Text>
                                </TableCell>
                            }
                        >
                            {rolesFunctions?.map((row) => {
                                if (!row.children) {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {row.name}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }

                                return (
                                    <TableAccordionItem
                                        key={row.id}
                                        toggle={
                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {row.name}
                                                </Text>
                                            </TableCell>
                                        }
                                    >
                                        {row.children.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Text color="primary" variant="body-sm">
                                                        {item.name}
                                                    </Text>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableAccordionItem>
                                );
                            })}
                        </TableAccordionItem>

                        <TableAccordionItem
                            toggle={
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {strings("Типы оборудования")}
                                    </Text>
                                </TableCell>
                            }
                        >
                            {deviceTypes.map((row) => {
                                if (!row.items) {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {row.name}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }

                                return (
                                    <TableAccordionItem
                                        key={row.id}
                                        toggle={
                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {row.name}
                                                </Text>
                                            </TableCell>
                                        }
                                    >
                                        {row.items.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Text color="primary" variant="body-sm">
                                                        {item.name}
                                                    </Text>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableAccordionItem>
                                );
                            })}
                        </TableAccordionItem>

                        <TableAccordionItem
                            toggle={
                                <TableCell>
                                    <Text color="primary" variant="body-sm">
                                        {strings("Группы оборудования")}
                                    </Text>
                                </TableCell>
                            }
                        >
                            {rolesDG.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {item.name}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableAccordionItem>
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabAccesability));

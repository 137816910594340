import { ModalFormItem } from "../../../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, Row, Select, TextField } from "headpoint-react-components";
import React from "react";
import { CultureContextConsumer } from "../../../../../../../contexts/cultureContext/CultureContext";

export default function ModalStepDefault({ values, SetValuesProperty, availableRoles }) {
    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <ModalSection>
                    <ModalFormItem>
                        <Row>
                            <TextField
                                label={strings("Название группы пользователей")}
                                name="name"
                                type="text"
                                value={values.name}
                                onChange={(e) => SetValuesProperty("name", e.target.value)}
                                colorVariant="light"
                                fullWidth={true}
                                error={!values.name ? strings("Поле не может быть пустым") : null}
                            />
                        </Row>
                    </ModalFormItem>
                    <ModalFormItem>
                        <Select
                            colorVariant="default"
                            fullWidth
                            horizontalAlignment="left"
                            label={strings("Роль")}
                            placeholder={strings("Выберите роль")}
                            value={values.roleId}
                            onChange={(val) => SetValuesProperty("roleId", val)}
                            options={availableRoles?.map((r) => ({ value: r.id, label: r.name })) ?? []}
                            verticalAlignment="bottom"
                        />
                    </ModalFormItem>

                    <ModalFormItem>
                        <TextField
                            label={strings("Комментарий")}
                            name="comment"
                            type="text"
                            value={values.comment}
                            onChange={(e) => SetValuesProperty("comment", e.target.value)}
                            colorVariant="light"
                            fullWidth
                            inputProps={{
                                as: "textarea",
                                rows: 3,
                            }}
                        />
                    </ModalFormItem>
                </ModalSection>
            )}
        </CultureContextConsumer>
    );
}

import { createSlice } from "@reduxjs/toolkit";
import { EVENTS_ON_PAGE } from "../../features/events/components/constants";
import { cloneDeep } from "lodash";

const initialState = {
    filter: {
        devicesFilter: [],
        locationsFilter: [],
        eventTypesFilter: [],
        adapterFilters: [],
        deviceGroupsFilter: [],
        controlZonesFilter: [],
        showInfo: false,
        showWarning: false,
        showDanger: false,
        showRead: false,
        selectedFilter: undefined,
        dates: {
            from: undefined,
            to: undefined,
        },
        pageNumber: 1,
        countOnPage: EVENTS_ON_PAGE,
    },
};

export const eventsSlice = createSlice({
    name: "events",
    initialState: initialState,
    reducers: {
        saveDevices: (state, action) => {
            state.filter.devicesFilter = action.payload?.map((d) => ({
                id: d.id,
                locationId: d.locationId,
            }));
        },
        saveLocations: (state, action) => {
            state.filter.locationsFilter = action.payload?.map((d) => ({
                id: d.id,
                parendId: d.parendId,
                parentIds: d.parentIds,
                children: cloneDeep(d.children),
            }));
        },
        saveEventTypes: (state, action) => {
            state.filter.eventTypesFilter = action.payload;
        },
        saveAdapter: (state, action) => {
            state.filter.adapterFilters = cloneDeep(action.payload);
        },
        saveDeviceGroups: (state, action) => {
            state.filter.deviceGroupsFilter = action.payload;
        },
        saveControlZonesFilter: (state, action) => {
            state.filter.controlZonesFilter = action.payload;
        },
        saveEventFilters: (state, action) => {
            state.filter.showDanger = action.payload.showDanger ?? state.filter.showDanger;
            state.filter.showInfo = action.payload.showInfo ?? state.filter.showInfo;
            state.filter.showWarning = action.payload.showWarning ?? state.filter.showWarning;
            state.filter.showRead = action.payload.showRead ?? state.filter.showRead;
        },
        saveSelectedFilter: (state, action) => {
            state.filter.selectedFilter = action.payload;
        },
        savePageNumber: (state, action) => {
            state.filter.pageNumber = action.payload;
        },
        saveCountOnPage: (state, action) => {
            state.filter.countOnPage = action.payload;
        },
        saveDates: (state, action) => {
            state.filter.dates = { ...action.payload };
        },
        resetFilter: (state) => {
            state.filter = initialState.filter;
        },
    },
});

export const {
    saveDevices,
    saveLocations,
    saveEventTypes,
    saveAdapter,
    saveDeviceGroups,
    saveControlZonesFilter,
    saveEventFilters,
    saveSelectedFilter,
    savePageNumber,
    saveCountOnPage,
    saveDates,
    resetFilter,
} = eventsSlice.actions;

export default eventsSlice.reducer;

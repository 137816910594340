import React from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class DetailsTabMain extends React.Component {
    render() {
        const { selectedAdapter } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                {selectedAdapter && selectedAdapter.id && (
                                    <TableRow>
                                        <TableCell>{strings("ID")}</TableCell>
                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {selectedAdapter.id}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                )}

                                {selectedAdapter && selectedAdapter.serviceId && (
                                    <TableRow>
                                        <TableCell>{strings("ID сервиса")}</TableCell>
                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {selectedAdapter.serviceId}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                )}

                                {selectedAdapter && selectedAdapter.serviceName && (
                                    <TableRow>
                                        <TableCell>{strings("Имя сервиса")}</TableCell>
                                        <TableCell>
                                            <Text color="primary" variant="body-sm">
                                                {strings(selectedAdapter.serviceName)}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                )}

                                {selectedAdapter &&
                                    selectedAdapter.deviceTypes &&
                                    selectedAdapter.deviceTypes.map((item, i) => (
                                        <TableRow key={item?.id}>
                                            {i === 0 && (
                                                <TableCell noBorder={selectedAdapter?.deviceTypes.length !== 1}>
                                                    {strings("Поддерживаемые устройства")}
                                                </TableCell>
                                            )}

                                            {i !== 0 && i !== selectedAdapter.deviceTypes.length - 1 && (
                                                <TableCell noBorder />
                                            )}

                                            {i !== 0 && i === selectedAdapter.deviceTypes.length - 1 && <TableCell />}

                                            <TableCell>
                                                <Text color="primary" variant="body-sm">
                                                    {strings(item?.name)}
                                                </Text>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

import React, { useCallback, useState } from "react";
import { ExecuteAdapterCommand } from "../../../../../services/featureCommands";
import {
    Button,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Select,
    TextField,
} from "headpoint-react-components";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { withSnackbar } from "notistack";
import { cloneDeep } from "lodash";
import { getCommand } from "../../../../../contexts/GeneralContext";
import { isNullOrWhiteSpace } from "./stringUtils";

function UpdateEventTypeModal({ generalInfo, adapter, closeHandler, strings, enqueueSnackbar }) {
    const [eventTypeToUpdate, setEventTypeToUpdate] = useState(null);

    const validate = useCallback(
        (eventType) => {
            if (isNullOrWhiteSpace(eventType.name)) {
                enqueueSnackbar(strings("Нельзя сохранить тип события без имени"), { variant: "error" });
                return false;
            }

            return true;
        },
        [enqueueSnackbar, strings]
    );

    const updateEventType = useCallback(
        async (eventTypeToUpdate) => {
            if (!validate(eventTypeToUpdate)) {
                return;
            }
            const updateCommand = getCommand(generalInfo, adapter?.serviceCode, "updateEventType");

            if (!updateCommand || !adapter) {
                return;
            }

            const [success] = await ExecuteAdapterCommand(
                updateCommand.method,
                adapter.serviceCode,
                updateCommand.path,
                {
                    code: eventTypeToUpdate.code,
                    name: eventTypeToUpdate.name,
                    severity: eventTypeToUpdate.severity,
                }
            );

            if (!success) {
                enqueueSnackbar(strings(`${strings("Не удалось обновить тип события")} '${eventTypeToUpdate.name}'`), {
                    variant: "error",
                });
                return;
            }

            enqueueSnackbar(strings(`${strings("Тип события")} '${eventTypeToUpdate.name}' ${strings("обновлен")}`), {
                variant: "success",
            });
            closeHandler();
        },
        [validate, generalInfo, adapter, closeHandler, enqueueSnackbar, strings]
    );

    const getAvailableEventTypes = useCallback(() => {
        return (
            adapter?.eventTypes
                ?.filter((eventType) => !eventType.readonly)
                ?.map((eventType) => ({ value: eventType.code, label: eventType.name })) ?? []
        );
    }, [adapter?.eventTypes]);

    return (
        <Modal size="sm" closeHandler={closeHandler}>
            <ModalHeader title={strings("Изменить тип события")} align="center" closeHandler={closeHandler} />

            <ModalBody>
                <ModalBodyItem>
                    <ModalFormItem>
                        <Select
                            label={strings("Тип события")}
                            placeholder={strings("Выбрать тип события")}
                            value={eventTypeToUpdate?.code}
                            options={getAvailableEventTypes()}
                            onChange={(code) => {
                                const selectedEventTypeToUpdate = adapter?.eventTypes?.find(
                                    (eventType) => eventType.code === code
                                );

                                setEventTypeToUpdate(cloneDeep(selectedEventTypeToUpdate));
                            }}
                            colorVariant="default"
                            horizontalAlignment="left"
                            verticalAlignment="bottom"
                            fullWidth
                        />
                    </ModalFormItem>
                    {eventTypeToUpdate && (
                        <ModalFormItem>
                            <TextField
                                label={strings("Название типа события")}
                                type="text"
                                value={eventTypeToUpdate.name}
                                onChange={(e) => setEventTypeToUpdate({ ...eventTypeToUpdate, name: e.target.value })}
                                colorVariant="light"
                                fullWidth
                                error={
                                    !isNullOrWhiteSpace(eventTypeToUpdate?.name)
                                        ? null
                                        : strings("Поле не может быть пустым")
                                }
                            />
                        </ModalFormItem>
                    )}
                </ModalBodyItem>
            </ModalBody>
            <ModalFooter>
                <ModalFooterItem>
                    <Button
                        type="button"
                        variant="primary"
                        label={strings("Сохранить")}
                        onClick={() => updateEventType(eventTypeToUpdate)}
                        disabled={!eventTypeToUpdate}
                    />
                </ModalFooterItem>
            </ModalFooter>
        </Modal>
    );
}

export default withSnackbar(UpdateEventTypeModal);

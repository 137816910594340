function createPath(externalId, cameraId) {
    return `/balancer/webrtc-${externalId}/${cameraId}`;
}

export function Base64EncodeUrl(str) {
    return window.btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, ".");
}

export async function startPeer(url, externalId, element, rtcPeerConfig) {
    const peerToOpen = new RTCPeerConnection(rtcPeerConfig);
    peerToOpen.ontrack = (event) => {
        element.srcObject = event.streams[0];
    };

    const token = window.localStorage.getItem("token");
    const request = {
        uri: url,
        token: token,
        externalId: externalId,
    };

    const cameraId = Base64EncodeUrl(JSON.stringify(request));
    let response = await fetch(createPath(externalId, cameraId), {
        method: "GET",
    });

    if (response.status === 401) {
        return [false, [], "unauthorized"];
    }

    if (response.status !== 200) {
        return [false, []];
    }

    let data = await response.json();

    try {
        await peerToOpen.setRemoteDescription(new RTCSessionDescription(data));
    } catch (e) {
        alert(e);
    }

    const answerRaw = await peerToOpen.createAnswer();
    const answer = JSON.parse(JSON.stringify(answerRaw));
    answer["id"] = data["id"];
    await peerToOpen.setLocalDescription(answerRaw);
    console.log("Generate answer");

    await fetch(createPath(externalId, cameraId), {
        method: "POST",
        body: JSON.stringify(answer),
    });

    return [true, peerToOpen];
}

export async function closePeer(peerToClose) {
    if (peerToClose != null) {
        console.log("Close peer.");
        peerToClose.ontrack = null;
        peerToClose.onicegatheringstatechange = null;
        peerToClose.oniceconnectionstatechange = null;
        peerToClose.onsignalingstatechange = null;
        peerToClose.onicecandidate = null;
        await peerToClose.close();
    } else {
        console.log(`CLOSE = NULL`);
    }
}

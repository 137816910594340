import React from "react";
import { LayoutPage, LayoutSidebar } from "../../../layout/MainLayout";
import PlanComponent from "./PlanComponent";
import { GetPlans, GetPoints } from "../../../services/plans";
import { FillLocations, GetLocations, GetLocationList } from "../../../services/locations";
import { nanoid } from "nanoid";
import { withSnackbar } from "notistack";
import Filters from "./Filters";
import PubSub from "pubsub-js";
import { GET_DEVICE_EVENTS_PLANS, PLANS_DEVICE_LOCATIONS_FILTER, SHOW_DETAILS_TOPIC } from "./plansTopics";
import { GeneralContextConsumer, getEventTypes, getSystemVariableValue } from "../../../contexts";
import { updateNodes } from "../../../utilites/TreeUtils.js";
import {
    CreateEventsFilter,
    DeleteEventsFilter,
    GetEventsFilter,
    GetEventsFilters,
    UpdateEventsFilter,
} from "../../../services/events";
import { DeleteModal } from "../../settings/components/DeleteModal";
import EventsFilterModal from "../../events/components/Modals/EventsFilterModal";
import { UpdateFilterModal } from "../../events/components/Modals/UpdateFilterModal";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import { withRouter } from "react-router-dom";
import {
    resetFilter,
    saveDevices,
    saveEventFilters,
    saveEventTypes,
    saveGroups,
    saveInterval,
    saveLocations,
    savePlan,
    saveSelectedFilter,
    saveZones,
} from "../../../app/reducers/planReducer";
import { GetDevicesByZones } from "../../../services/controlZones";
import { GetDeviceInfo } from "../../../services/devices";
import { GROUP_ZONE_FILTER } from "../../components/GroupZoneFilter/GroupZoneFilter";

const PLANS_TAG = "PLANS";

class Plans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            selectedPoints: this.props?.location?.state?.selectedPoints ?? [],
            selectedPlan: this.props?.location?.state?.selectedPlan ?? undefined,
            points: this.props?.location?.state?.points ?? [],
            locations: [],
            devicesLocationsTree: [],
            plansTree: [],
            devicesFilter: [],
            eventTypesFilter: [],
            intervalFilter: "all",
            eventsFilter: {
                showDanger: false,
                showWarning: false,
                showRead: false,
            },
            locationsFilter: [],
            zonesFilter: [],
            groupsFilter: [],
            devicesVisibility: [],
            timerId: null,
            eventsFilters: [],
            showNewEventsFilter: false,
            updateFilter: undefined,
            deleteFilter: undefined,
            selectedFilter: undefined,
        };
    }

    getEvents = (eventsFilter, eventTypesFilter, intervalFilter) =>
        PubSub.publish(GET_DEVICE_EVENTS_PLANS, { eventsFilter, eventTypesFilter, intervalFilter });

    componentDidMount = async () => {
        const {
            history,
            plan: { filter, selectedPlan },
            strings,
        } = this.props;

        const [locationsStatus, locations] = await GetLocations();
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        let { points } = this.state;

        if (selectedPlan) {
            const [pointsStatus, allPoints] = await GetPoints(selectedPlan.id);
            points = pointsStatus && points.length > 0 ? allPoints : points;
        }

        const devicesLocationsTree = FillLocations(locations, []);
        const plansTree = FillLocations(locations, []);

        const [filtersStatus, eventsFilters] = await GetEventsFilters(PLANS_TAG);

        if (!filtersStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
        }

        this.setState({
            locations: locations ?? [],
            devicesLocationsTree: devicesLocationsTree ?? [],
            plansTree: plansTree ?? [],
            points,
            eventsFilters,
        });

        const data = history.location.state;
        if (data) {
            history.replace({ ...history, state: undefined });
            this.props.dispatch(resetFilter());
            await this.openPlanFromHistory(data);
        }

        if (filter && !data) {
            await this.openPlanFromStorage(filter, selectedPlan);
        }

        this.startAutoUpdate();
    };

    openPlanFromStorage = async (filter, selectedPlan) => {
        const {
            plan: { selected },
        } = this.props;

        if (selectedPlan) {
            await this.openByLocationId(selectedPlan.locationId);
            this.setState({ updateTreeId: nanoid() });
            await this.handleSelectPlan(selectedPlan);
        }

        const zones = this.openTrees(filter);

        if (selected) {
            PubSub.publish(SHOW_DETAILS_TOPIC, selected);
        }

        this.setState({
            locationsFilter: filter.locationsFilter,
            devicesFilter: filter.devicesFilter,
            zonesFilter: zones,
            eventTypesFilter: filter.eventTypesFilter,
            selectedFilter: filter.selectedFilter,
            intervalFilter: filter.intervalFilter,
            eventsFilter: { ...filter.eventsFilter },
        });
    };

    openTrees = (filter) => {
        if (filter.devicesFilter?.length) {
            PubSub.publish(PLANS_DEVICE_LOCATIONS_FILTER, {
                type: "FilterByDevices",
                data: filter.devicesFilter,
            });
        }

        if (filter.locationsFilter?.length) {
            PubSub.publish(PLANS_DEVICE_LOCATIONS_FILTER, {
                type: "FilterByLocations",
                data: filter.locationsFilter,
            });
        }

        const zonesFromGroups = filter.groupsFilter?.map((g) => g.children).flat();
        const allZones = zonesFromGroups.concat(filter.zonesFilter);
        if (allZones.length) {
            PubSub.publish(GROUP_ZONE_FILTER, {
                data: allZones,
            });
        }

        return allZones;
    };

    openPlanFromHistory = async (data) => {
        switch (data?.type) {
            case "ShowPlan":
                this.setState({
                    selectedPlan: data.selectedPlan,
                    selectedPoints: data.selectedPoints,
                    points: data.points,
                });
                break;
            case "SelectPlan":
                await this.openByLocationId(data.selectedPlan.locationId);
                this.handleSelectPlan(data.selectedPlan);
                this.setState({ updateTreeId: nanoid() });
                break;
            default:
                break;
        }
    };

    openByLocationId = async (locationId) => {
        const { plansTree } = this.state;
        const { strings } = this.props;

        if (!locationId) {
            return;
        }

        let [locationsStatus, locationsDb] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locationsDb?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [locationDb] = locationsDb;

        let parentIds = [...locationDb.parentIds.slice(1), locationId];

        let locationNode;

        let tree = updateNodes(plansTree, "preOpen", false);

        for (const parentId of parentIds) {
            locationNode = tree.find((i) => i.id === parentId);

            locationNode.isClosed = false;

            if (!locationNode) {
                this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                    variant: "error",
                });
                return;
            }

            locationNode.preOpen = true;

            await this.openLocationPlan(locationNode);

            tree = locationNode.children;
        }

        this.setState({ plansTree: plansTree });
    };

    stopAutoUpdate = () => {
        if (this.state.timerId) {
            clearInterval(this.state.timerId);
            this.setState({ timerId: null });
        }
    };

    startAutoUpdate = () => {
        const timerId = this.createAutoUpdateTimer();
        this.setState({ timerId });
    };

    createAutoUpdateTimer = () => {
        const timeout = getSystemVariableValue(this.props.generalInfo, "service.api.events", "events.timeout", 60);

        return setInterval(() => {
            const { eventsFilter, eventTypesFilter, intervalFilter } = this.state;

            PubSub.publish(GET_DEVICE_EVENTS_PLANS, { eventsFilter, eventTypesFilter, intervalFilter });
        }, timeout * 1000);
    };

    componentWillUnmount() {
        this.refreshEvents && PubSub.unsubscribe(this.refreshEvents);
        this.stopAutoUpdate();
    }

    loadEventsFilterHandler = async (filterToLoad) => {
        const { selectedPlan } = this.state;
        const { strings } = this.props;

        if (!selectedPlan) {
            this.props.enqueueSnackbar(strings("Выберите план"), { variant: "error" });
            return;
        }

        const [filterStatus, eventsFilter] = await GetEventsFilter(filterToLoad.id);

        if (filterStatus) {
            const devicesFilter = eventsFilter.properties.devicesFilter;
            const locationsFilter = eventsFilter.properties.locationsFilter;
            const zonesFilter = eventsFilter.properties.zonesFilter;
            const groupsFilter = eventsFilter.properties.groupsFilter;
            const eventTypesFilter = eventsFilter.properties.eventTypesFilter;
            const intervalFilter = eventsFilter.properties.intervalFilter;

            let filter = { deviceIds: devicesFilter?.map((d) => d?.id) };

            const [pointsStatus, points] = await GetPoints(selectedPlan?.id, filter);
            if (!pointsStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения объектов плана"), { variant: "error" });
                return;
            }

            const devicesVisibility = points.map((p) => p.id);

            this.props.dispatch(saveDevices(devicesFilter ?? []));
            this.props.dispatch(saveLocations(locationsFilter ?? []));
            this.props.dispatch(saveGroups(groupsFilter ?? []));
            this.props.dispatch(saveZones(zonesFilter ?? []));
            this.props.dispatch(saveEventTypes(eventTypesFilter ?? []));
            this.props.dispatch(saveSelectedFilter(eventsFilter));
            this.props.dispatch(saveInterval(intervalFilter ?? "all"));
            this.props.dispatch(
                saveEventFilters({
                    showInfo: eventsFilter.properties.showInfo ?? false,
                    showWarning: eventsFilter.properties.showWarning ?? false,
                    showDanger: eventsFilter.properties.showDanger ?? false,
                    showRead: eventsFilter.properties.showRead ?? false,
                })
            );

            const zones = this.openTrees({ devicesFilter, locationsFilter, zonesFilter, groupsFilter });

            this.setState({
                devicesFilter: devicesFilter ?? [],
                locationsFilter: locationsFilter ?? [],
                zonesFilter: zones ?? [],
                groupsFilter: groupsFilter ?? [],
                eventTypesFilter: eventTypesFilter ?? [],
                intervalFilter: intervalFilter ?? [],
                eventsFilter: {
                    ...this.state.eventsFilter,
                    showInfo: eventsFilter.properties.showInfo ?? false,
                    showWarning: eventsFilter.properties.showWarning ?? false,
                    showDanger: eventsFilter.properties.showDanger ?? false,
                    showRead: eventsFilter.properties.showRead ?? false,
                },
                points: points ?? [],
                devicesVisibility: devicesVisibility ?? [],
                selectedFilter: eventsFilter,
            });

            await this.loadPoints(locationsFilter, devicesFilter);

            this.props.enqueueSnackbar(`${strings("Применён фильтр")} ${filterToLoad.name}`, { variant: "success" });
        } else {
            this.props.enqueueSnackbar(strings(`Не удалось загрузить фильтр`), {
                variant: "error",
            });
            return;
        }
    };

    getEventsFilterHandler = async (filter) => {
        const { strings } = this.props;

        const [filterStatus, eventsFilter] = await GetEventsFilter(filter.id);
        if (!filterStatus) {
            this.props.enqueueSnackbar(strings(`Не удалось редактировать фильтр`), {
                variant: "error",
            });
        } else {
            this.setState({ showNewEventsFilter: true, updateFilter: eventsFilter });
        }
    };

    combineDataToEventsFilter(data) {
        const {
            devicesFilter,
            locationsFilter,
            eventTypesFilter,
            intervalFilter,
            eventsFilter,
            zonesFilter,
            groupsFilter,
        } = this.state;

        return {
            name: data.name,
            description: data.comment,
            type: data.type,
            entities: data.entities,
            tag: PLANS_TAG,
            properties: {
                devicesFilter,
                locationsFilter,
                eventTypesFilter,
                intervalFilter,
                zonesFilter,
                groupsFilter,
                showDanger: eventsFilter.showDanger,
                showWarning: eventsFilter.showWarning,
                showRead: eventsFilter.showRead,
            },
        };
    }

    createEventsFilterHandler = async (data) => {
        const { strings } = this.props;

        const filter = this.combineDataToEventsFilter(data);

        const [status, code] = await CreateEventsFilter(filter);
        if (code === 409) {
            this.props.enqueueSnackbar(strings(`В одном контексте фильтры событий должны иметь уникальные имена`), {
                variant: "error",
            });
            return;
        }
        if (!status) {
            this.props.enqueueSnackbar(strings(`Не удалось сохранить фильтр`), {
                variant: "error",
            });
            return;
        } else {
            this.props.enqueueSnackbar(`${strings("Фильтр")} ${filter.name} ${strings("сохранен")}`, {
                variant: "success",
            });

            const [filtersStatus, eventsFilters] = await GetEventsFilters(PLANS_TAG);

            if (!filtersStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
            }

            this.setState({ showNewEventsFilter: false, eventsFilters });
        }
    };

    updateEventsFilterHandler = async () => {
        const { strings } = this.props;
        const { updateFilter } = this.state;

        const filter = this.combineDataToEventsFilter(updateFilter);

        const [status] = await UpdateEventsFilter(updateFilter.id, filter);
        if (!status) {
            this.props.enqueueSnackbar(strings(`Не удалось сохранить фильтр`), {
                variant: "error",
            });
            return;
        } else {
            this.props.enqueueSnackbar(`${strings("Фильтр")} ${filter.name} ${strings("сохранен")}`, {
                variant: "success",
            });

            const [filtersStatus, eventsFilters] = await GetEventsFilters(PLANS_TAG);

            if (!filtersStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
            }

            this.setState({ showNewEventsFilter: false, eventsFilters });
        }
    };

    deleteEventsFilterHandler = async () => {
        const { strings } = this.props;
        const { eventsFilters, deleteFilter } = this.state;

        if (await DeleteEventsFilter(deleteFilter.id)) {
            this.setState({
                eventsFilters: eventsFilters?.filter((d) => d.id !== deleteFilter.id),
                deleteFilter: undefined,
            });
            this.props.enqueueSnackbar(`${strings("Фильтр событий")} ${deleteFilter.name} ${strings("удален")}`, {
                variant: "success",
            });
        } else {
            this.setState({ deleteFilter: undefined });
            this.props.enqueueSnackbar(strings("Не удалось удалить фильтр событий"), { variant: "error" });
        }
    };

    openLocationPlan = async (locationNode) => {
        const { strings } = this.props;
        const { plansTree } = this.state;

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [plansStatus, plans] = await GetPlans(locationNode.id);
        if (!plansStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения планов"), { variant: "error" });
            return;
        }

        locationNode.children =
            children?.map((l) => ({
                ...l,
                tag: "location",
                isClosed: true,
                children: [],
            })) ?? [];

        locationNode.children = locationNode.children.concat(
            plans?.map((p) => ({ ...p, viewId: nanoid(), deviceCount: 0, tag: "plan" })) ?? []
        );

        this.setState({ plansTree });
    };

    handleSelectPlan = async (plan) => {
        const { strings } = this.props;
        const { locationsFilter, devicesFilter } = this.state;

        let filter = {};
        if (locationsFilter?.length) {
            filter.locationIds = locationsFilter?.map((l) => l.id);
        }

        if (devicesFilter?.length) {
            filter.deviceIds = devicesFilter?.map((d) => d.id);
        }

        const [pointsStatus, points] = await GetPoints(plan.id, filter);
        if (!pointsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения объектов плана"), { variant: "error" });
            return;
        }

        const devicesVisibility = points.map((p) => p.id);

        this.setState({ selectedPlan: plan, points: points ?? [], devicesVisibility: devicesVisibility ?? [] });
    };

    loadPoints = async (locationsFilter, devicesFilter) => {
        const { strings } = this.props;
        const { selectedPlan } = this.state;

        if (!selectedPlan) {
            this.props.enqueueSnackbar(strings("Выберите план"), { variant: "error" });
            return [];
        }

        let filter = {};

        if (locationsFilter?.length) {
            filter.locationIds = locationsFilter?.map((l) => l.id);
        }

        if (devicesFilter?.length) {
            filter.deviceIds = devicesFilter?.map((d) => d.id);
        }

        let [pointsStatus, points] = await GetPoints(selectedPlan.id, filter);
        if (!pointsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения объектов плана"), { variant: "error" });
            return [];
        }

        this.setState({ points });
    };

    loadDevicesTree = async (zonesFilterToLoad, groupsFilterToLoad, zonesFilterToUnload, groupsFilterToUnload) => {
        const { devicesFilter, locationsFilter } = this.state;
        const { devicesVisibility } = this.state;

        const zonesIdsToLoad = zonesFilterToLoad
            .concat(groupsFilterToLoad.map((g) => g.children).flat())
            .map((z) => z.id);

        const zonesIdsToUnload = zonesFilterToUnload
            .concat(groupsFilterToUnload.map((g) => g.children).flat())
            .map((z) => z.id);

        let [statusToLoad, devicesToLoad] = await GetDevicesByZones({ zonesIds: zonesIdsToLoad });
        if (!statusToLoad) {
            this.props.enqueueSnackbar("Ошибка получения девайсов", { variant: "error" });
            return;
        }

        let [statusToUnload, devicesToUnload] = await GetDevicesByZones({ zonesIds: zonesIdsToUnload });
        if (!statusToUnload) {
            this.props.enqueueSnackbar("Ошибка получения девайсов", { variant: "error" });
            return;
        }

        const visibleDevices = devicesToLoad.deviceIds?.filter((id) => devicesVisibility?.includes(id));

        let [deviceStatus, deviceInfo] = await GetDeviceInfo(visibleDevices);

        if (!deviceStatus) {
            this.props.enqueueSnackbar("Ошибка получения девайсов", { variant: "error" });
            return;
        }

        PubSub.publish(PLANS_DEVICE_LOCATIONS_FILTER, {
            type: "FilterByDevices",
            data: deviceInfo,
        });

        let devicesToShow = devicesFilter.filter((d) => !devicesToUnload.deviceIds.includes(d.id)).concat(deviceInfo);

        this.props.dispatch(saveDevices(devicesToShow));
        this.setState({ devicesFilter: devicesToShow });

        await this.loadPoints(locationsFilter, devicesToShow);
    };

    checkSelected = (item) => {
        const { devicesFilter, locationsFilter, locations } = this.state;

        if (item.tag === "location") {
            if (locationsFilter.some((l) => l.id === item.id)) {
                return true;
            }

            return !!item.parentIds?.some((id) => locationsFilter.some((l) => l.id === id));
        } else {
            if (devicesFilter.some((d) => d.id === item.id)) {
                return true;
            }

            if (locationsFilter.some((l) => l.id === item.locationId)) {
                return true;
            }

            let location = locations.find((l) => l.id === item.locationId);
            return !!location?.parentIds?.some((id) => locationsFilter.some((l) => l.id === id));
        }
    };

    checkIndeterminate = (item) =>
        !this.checkSelected(item) && item.children?.some((c) => this.checkSelected(c) || this.checkIndeterminate(c));

    checkDrawSelected = (item) => {
        if (this.checkSelected(item)) {
            return true;
        }

        if (item.tag !== "location") {
            return false;
        }

        return (
            !!item?.children?.length &&
            item?.children?.every((c) => {
                return this.checkDrawSelected(c);
            })
        );
    };

    resetFilter = async () => {
        this.props.dispatch(resetFilter());

        this.setState({
            devicesFilter: [],
            locationsFilter: [],
            zonesFilter: [],
            groupsFilter: [],
            eventTypesFilter: [],
            adapterFilters: [],
            deviceGroupsFilter: [],
            intervalFilter: "all",
            eventsFilter: {
                showInfo: false,
                showWarning: false,
                showDanger: false,
                showRead: false,
            },
            selectedFilter: undefined,
        });

        await this.loadPoints([], []);
    };

    render() {
        let {
            plansTree,
            selectedPlan,
            selectedPoints,
            points,
            devicesFilter,
            locationsFilter,
            devicesVisibility,
            updateTreeId,
            eventsFilter,
            eventTypesFilter,
            intervalFilter,
            eventsFilters,
            showNewEventsFilter,
            deleteFilter,
            updateFilter,
            selectedFilter,
            zonesFilter,
            groupsFilter,
        } = this.state;

        const { generalInfo, strings } = this.props;

        const disabled =
            eventsFilter.showDanger ||
            eventsFilter.showWarning ||
            eventsFilter.showRead ||
            eventTypesFilter.length > 0 ||
            locationsFilter.length > 0 ||
            devicesFilter.length > 0 ||
            intervalFilter !== "all" ||
            selectedFilter;

        return (
            <>
                <DeleteModal
                    visible={!!deleteFilter}
                    CloseHandler={() => this.setState({ deleteFilter: undefined })}
                    RemoveHandler={this.deleteEventsFilterHandler}
                    text={`${strings("Вы хотите удалить фильтр событий")} "${deleteFilter?.name}". ${strings(
                        "Удалённый фильтр событий нельзя восстановить. Продолжить?"
                    )}`}
                />

                <UpdateFilterModal
                    visible={!!updateFilter}
                    CloseHandler={() => this.setState({ updateFilter: undefined })}
                    SaveHandler={this.updateEventsFilterHandler}
                    filter={updateFilter}
                    strings={strings}
                />
                <LayoutPage>
                    <LayoutSidebar>
                        <Filters
                            key={updateTreeId}
                            setEventsFilter={(eventsFilter) => {
                                this.props.dispatch(saveEventFilters(eventsFilter));

                                this.setState({ eventsFilter });

                                const { eventTypesFilter, intervalFilter } = this.state;

                                this.getEvents(eventsFilter, eventTypesFilter, intervalFilter);
                            }}
                            setEventsTypesFilter={(eventTypesFilter) => {
                                this.props.dispatch(saveEventTypes(eventTypesFilter));
                                this.setState({ eventTypesFilter });

                                const { eventsFilter, intervalFilter } = this.state;

                                this.getEvents(eventsFilter, eventTypesFilter, intervalFilter);
                            }}
                            setEventsIntervalFilter={(intervalFilter) => {
                                this.props.dispatch(saveInterval(intervalFilter));
                                this.setState({ intervalFilter });

                                const { eventsFilter, eventTypesFilter } = this.state;

                                this.getEvents(eventsFilter, eventTypesFilter, intervalFilter);
                            }}
                            eventTypesFilter={eventTypesFilter}
                            defaultEventTypes={getEventTypes(generalInfo)}
                            devicesFilter={devicesFilter}
                            eventsFilter={eventsFilter}
                            intervalFilter={intervalFilter}
                            locationsFilter={locationsFilter}
                            zonesFilter={zonesFilter}
                            groupsFilter={groupsFilter}
                            setValue={(name, val) => this.setState({ [name]: val })}
                            plansTree={plansTree}
                            devicesVisibility={devicesVisibility}
                            openLocationPlan={(location) => this.openLocationPlan(location)}
                            selectedPlan={selectedPlan}
                            handlePlanSelect={(plan) => {
                                this.handleSelectPlan(plan);
                                this.props.dispatch(savePlan(plan));
                            }}
                            setDeviceLocationFilter={async (
                                devicesFilter,
                                locationsFilter,
                                setDeviceLocationFilter
                            ) => {
                                await this.loadPoints(setDeviceLocationFilter, devicesFilter);

                                devicesFilter && this.props.dispatch(saveDevices(devicesFilter));
                                locationsFilter && this.props.dispatch(saveLocations(locationsFilter));

                                devicesFilter && this.setState({ devicesFilter });
                                locationsFilter && this.setState({ locationsFilter });

                                this.setState({ zonesFilter: [], groupsFilter: [] });
                            }}
                            setGroupZoneFilter={async (zonesFilterToLoad, groupsFilterToLoad) => {
                                const { zonesFilter, groupsFilter } = this.state;

                                zonesFilterToLoad && this.props.dispatch(saveZones(zonesFilterToLoad));
                                groupsFilterToLoad && this.props.dispatch(saveGroups(groupsFilterToLoad));

                                zonesFilterToLoad && this.setState({ zonesFilter: zonesFilterToLoad });
                                groupsFilterToLoad && this.setState({ groupsFilter: groupsFilterToLoad });

                                const zonesFilterToUnload = zonesFilter.filter((z) => !zonesFilterToLoad.includes(z));

                                const groupsFilterToUnload = groupsFilter.filter(
                                    (g) => !groupsFilterToLoad.includes(g)
                                );

                                this.loadDevicesTree(
                                    zonesFilterToLoad,
                                    groupsFilterToLoad,
                                    zonesFilterToUnload,
                                    groupsFilterToUnload
                                );
                            }}
                            resetGroupZoneFilter={async () => {
                                const { zonesFilter, groupsFilter } = this.state;

                                this.props.dispatch(saveZones([]));
                                this.props.dispatch(saveGroups([]));

                                this.setState({
                                    zonesFilter: [],
                                    groupsFilter: [],
                                });

                                this.loadDevicesTree([], [], zonesFilter, groupsFilter);
                            }}
                            resetdeviceLocationFilter={async () => {
                                await this.loadPoints([], []);

                                this.props.dispatch(saveDevices([]));
                                this.props.dispatch(saveLocations([]));

                                this.setState({
                                    devicesFilter: [],
                                    locationsFilter: [],
                                });
                            }}
                            eventsFilters={eventsFilters}
                            handleOpen={() => this.setState({ showNewEventsFilter: true })}
                            handleUpdate={async (data) => {
                                this.setState({ updateFilter: data });
                            }}
                            loadFilter={this.loadEventsFilterHandler}
                            deleteFilter={(data) => {
                                this.setState({ deleteFilter: data });
                            }}
                            selectedFilter={selectedFilter}
                            dropFilter={this.resetFilter}
                            disabled={!disabled}
                        />
                    </LayoutSidebar>
                    {!!selectedPlan ? (
                        <PlanComponent
                            isClustering={true}
                            plan={selectedPlan}
                            generalInfo={generalInfo}
                            selected={selectedPoints}
                            points={points}
                            eventsFilter={eventsFilter}
                            intervalFilter={intervalFilter}
                            eventTypesFilter={eventTypesFilter}
                            strings={strings}
                        />
                    ) : null}
                </LayoutPage>
                {showNewEventsFilter && (
                    <EventsFilterModal
                        generalInfo={generalInfo}
                        defaultEventTypes={getEventTypes(generalInfo)}
                        handleClose={() => this.setState({ showNewEventsFilter: false })}
                        createEventsFilter={async (data) => await this.createEventsFilterHandler(data)}
                    />
                )}
            </>
        );
    }
}

class PlansWrapper extends React.Component {
    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => React.createElement(Plans, { ...this.props, generalInfo, strings })}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        plan: state.plan,
    };
};

export default connect(mapStateToProps)(withSnackbar(withRouter(PlansWrapper)));

import React from "react";
import { DetailsTabMain } from "./DetailsTabMain";
import { DetailsTabEventTypes } from "./DetailsTabEventTypes";
import { DetailsTabDevices } from "./DetailsTabDevices";
import { Button, Text, Tab, TabPanel } from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
} from "../../../components/DetailsBox";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export default class ServiceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
        };
    }

    render() {
        const { selectedService, select } = this.props;
        const { detailsTab } = this.state;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {selectedService.serviceName}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Button icon="close" variant="secondary" onClick={() => select(null)} />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    <Tab
                                        label={strings("Общее")}
                                        isActive={detailsTab === "main"}
                                        onClick={() => this.setState({ detailsTab: "main" })}
                                    />
                                    {selectedService.properties?.adapter && (
                                        <Tab
                                            label={strings("Устройства")}
                                            isActive={detailsTab === "devices"}
                                            onClick={() => this.setState({ detailsTab: "devices" })}
                                        />
                                    )}
                                    {selectedService.properties?.adapter && (
                                        <Tab
                                            label={strings("Типы событий")}
                                            isActive={detailsTab === "eventTypes"}
                                            onClick={() => this.setState({ detailsTab: "eventTypes" })}
                                        />
                                    )}
                                </TabPanel>
                            </DetailsBoxTabs>

                            {detailsTab === "main" && <DetailsTabMain selectedService={selectedService} />}
                            {detailsTab === "devices" && selectedService.properties?.adapter && (
                                <DetailsTabDevices selectedAdapter={selectedService.properties.adapter} />
                            )}
                            {detailsTab === "eventTypes" && selectedService.properties?.adapter && (
                                <DetailsTabEventTypes selectedAdapter={selectedService.properties.adapter} />
                            )}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

import { urlWithQuery } from "../utilites/UrlUtils";

const archiveTasksApi = "/archive-api/archives";
const archiveCountApi = "/archive-api/archives/count";
const archiveCamerasApi = "/archive-api/cameras";

export const GetArchiveUrl = async (serviceCode, cameraId, from, to, scale) => {
    const token = window.localStorage.getItem("token");

    let url = `${archiveCamerasApi}/archiveUrl/${serviceCode}?cameraId=${cameraId}&from=${from}`;

    if (!isNaN(to) && to > 0) {
        url += `&to=${to}`;
    }

    if (scale) {
        if (scale < 0) {
            url = `${archiveCamerasApi}/archiveUrl/${serviceCode}?cameraId=${cameraId}&from=${from}`;
        }

        url += `&scale=${scale}`;
    }

    const response = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        return [false, null];
    }

    const res = await response.json();

    return [true, res];
};

export const GetCameraRanges = async (serviceCode, cameraId) => {
    const token = window.localStorage.getItem("token");

    try {
        let response = await fetch(`${archiveCamerasApi}/archiveRanges/${serviceCode}?cameraId=${cameraId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status !== 200) {
            return [false, [], response.status];
        }

        let res = await response.json();

        return [true, res];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export async function GetCameraLacunas(serviceCode, cameraId) {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${archiveCamerasApi}/archiveLacunas/${serviceCode}?cameraId=${cameraId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.status !== 200) {
            return [false, [], response.status];
        }

        let res = await response.json();

        return [true, res];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
}

export const CreateArchiveOrdersBatch = async (archiveOrders) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(`${archiveTasksApi}/batch`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(archiveOrders),
    });

    if (response.status !== 200) {
        return [false, response.status, null];
    }

    try {
        const batchResponse = await response.json();
        return [true, response.status, batchResponse];
    } catch (ex) {
        console.error(ex);

        return [false, response.status, null];
    }
};

export const CountArchiveOrders = async (searchString) => {
    const token = window.localStorage.getItem("token");

    let query = new URLSearchParams();

    if (searchString) {
        query.append("searchString", searchString);
    }

    let response = await fetch(urlWithQuery(archiveCountApi, query), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, 0];
    }

    try {
        let request = await response.json();
        return [true, request?.total ?? 0];
    } catch (ex) {
        console.error(ex);

        return [false, 0];
    }
};

export const GetArchiveOrders = async (pageNumber, rowsLimit, searchString) => {
    const token = window.localStorage.getItem("token");
    let query = new URLSearchParams({ pageNumber, rowsLimit });

    if (searchString) {
        query.append("searchString", searchString);
    }

    let response = await fetch(urlWithQuery(archiveTasksApi, query), {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, []];
    }

    try {
        let result = await response.json();

        return [true, result?.items];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const GetArchiveOrder = async (taskId) => {
    const token = window.localStorage.getItem("token");

    let response = await fetch(archiveTasksApi + `/${taskId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        return [false, null];
    }

    try {
        let result = await response.json();

        return [true, result?.items];
    } catch (ex) {
        console.error(ex);

        return [false, null];
    }
};

export const CancelArchiveOrder = async (taskId, serviceId) => {
    const token = window.localStorage.getItem("token");
    let query = new URLSearchParams({ serviceId });

    let response = await fetch(urlWithQuery(archiveTasksApi + `/${taskId}`, query), {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    let result = null;
    try {
        result = await response.json();
    } catch {
        // ignore
    }
    return [response.status === 200, response.status, result];
};

export const eventImportance = [
    {
        code: "danger",
        label: "Тревожное",
        color: "danger",
    },
    {
        code: "warning",
        label: "Требующее внимания",
        color: "warning",
    },
    {
        code: "primary",
        label: "Обычное",
        color: "primary",
    },
    {
        code: "info",
        label: "Обычное",
        color: "primary",
    },
];

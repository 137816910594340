import React from "react";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import {
    Table,
    TableHead,
    TableHeadCell,
    TableRow,
    Text,
    TableBody,
    TableCell,
    TableAccordionItem,
    Row,
} from "headpoint-react-components";
import { findMatchingCode } from "../../../../services/license";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class LicenseTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseSections: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.licenseInfo !== prevProps.licenseInfo &&
            this.props.licenseEntities !== prevProps.licenseEntities
        ) {
            this.getLicenseSections();
        }
    }

    getLicenseSections = () => {
        const { licenseInfo } = this.props;

        if (licenseInfo) {
            let sections = licenseInfo.sections?.map((s) => ({
                sectionName: s.sectionName,
                sectionCodes: s.licenseCodes?.map((code) => ({
                    sectionCode: code,
                    activeLicenses: this.getActiveLicense(code, s.licenseCodes),
                    availableLicenses: licenseInfo.entities?.find((e) => e.licenseCode === code)?.count,
                })),
            }));

            this.setState({ licenseSections: sections });
        }

        return 0;
    };

    getActiveLicense(code, licenseCodes) {
        const { licenseEntities } = this.props;

        licenseEntities.forEach((entity) => {
            if (entity.entityCode[entity.entityCode.length - 1] === ".") {
                entity.entityCode = entity.entityCode.slice(0, -1);
            }
        });

        let count = 0;

        let matchingCode = findMatchingCode(
            code,
            licenseEntities.map((l) => l.entityCode)
        );

        if (code.includes(".*")) {
            code = code.replace(".*", "");

            let entityArray = licenseEntities.filter((e) => !licenseCodes.includes(e.entityCode));

            entityArray.forEach((entity) => {
                if (entity.entityCode.includes(code)) {
                    count += licenseEntities.find((l) => l.entityCode === entity.entityCode)?.count;
                }
            });
        } else {
            count = licenseEntities.find((l) => l.entityCode === matchingCode)?.count ?? 0;
        }

        return count;
    }

    render() {
        const { licenseSections } = this.state;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "30%" }} />
                                <col style={{ width: "30%" }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("лицензия")}</TableHeadCell>
                                    <TableHeadCell>{strings("активные")}</TableHeadCell>
                                    <TableHeadCell>{strings("доступно")}</TableHeadCell>
                                    <TableHeadCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {licenseSections?.map((section, index) => (
                                    <TableAccordionItem
                                        defaultClosed={true}
                                        key={`LicenseSection-${index}`}
                                        toggle={
                                            <>
                                                <TableCell verticalAlign="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        {strings(section?.sectionName)}
                                                    </Text>
                                                </TableCell>
                                                <TableCell />
                                                <TableCell />
                                                <TableCell />
                                            </>
                                        }
                                    >
                                        {section?.sectionCodes?.map((code, index) => (
                                            <TableRow key={`License-${index}`}>
                                                <TableCell verticalAlign="middle">
                                                    <Row align="middle">
                                                        <Text color="primary" variant="body-sm">
                                                            {code?.sectionCode}
                                                        </Text>
                                                    </Row>
                                                </TableCell>
                                                <TableCell verticalAlign="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        {code?.activeLicenses}
                                                    </Text>
                                                </TableCell>
                                                <TableCell verticalAlign="middle">
                                                    <Text color="primary" variant="body-sm">
                                                        {code?.availableLicenses}
                                                    </Text>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        ))}
                                    </TableAccordionItem>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import { Checkbox, Row } from "headpoint-react-components";
import React from "react";

const FilteredCameras = (props) => {
    return props.filteredCameras?.map((device) => (
        <Row marginBottom={12} key={device.id}>
            <Checkbox
                label={device.name}
                checked={props.selectedCameras.includes(device.id)}
                onChange={() => {
                    props.selectCamera(device.id);
                }}
            />
        </Row>
    ));
};

export default FilteredCameras;

import React from "react";
import { withSnackbar } from "notistack";
import { Button, Dropdown, DropdownItem, ExpandableSearch, Pagination, Text } from "headpoint-react-components";
import {
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutPaginationWrapper,
    EventsLayoutPrimary,
    EventsLayoutWrapper,
} from "../../../../layout/EventsLayout";

import { ScenariosTable } from "./ScenariosTable";
import ScenariosDetails from "./ScenariosDetails";
import {
    CompareNodes,
    CreateScenario,
    DeleteScenario,
    GetScenarios,
    UpdateScenario,
} from "../../../../services/scenarios";
import { CreateUpdateWizard } from "../../../components/CreateUpdateWizard/CreateUpdateWizard";
import { wizardSteps } from "./constants";
import uuid from "react-uuid";
import { permissionExists } from "../../../../contexts";
import { DeleteModal } from "../DeleteModal";
import { convertLocalToUTC, convertUTCToLocal } from "../../../../utilites/TimeUtils";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const scenarioInitialValues = {
    title: "Рабочие места",
    eventUnitCodes: [{ id: uuid(), title: "" }],
    templateId: "",
    time: 1,
    date: 1,
    deviceIds: [],
    timeType: "all",
    dateType: "all",
    dates: [],
    times: [],
    comment: "",
};

const COUNT_ON_PAGE = 15;

class ScenariosSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasSecondary: undefined,
            detailsTab: "main",
            search: "",
            selectedScenario: undefined,
            scenarios: [],
            scenarioToDelete: null,

            showCreateScenarioModal: false,
            step: 1,
            activeStep: 1,
            wizardMode: WIZARD_CREATE_MODE,
            values: undefined,
            isLoading: false,

            pageNumber: 1,
            pagesCount: 1,
            countOnPage: COUNT_ON_PAGE,
        };
    }

    async componentDidMount() {
        await this.getScenariosData();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.pageNumber !== prevState.pageNumber || this.state.countOnPage !== prevState.countOnPage) {
            await this.getScenariosData();
        }
    }

    handlePage = (page) => {
        const { pagesCount } = this.state;

        if (page <= pagesCount) {
            this.setState({ pageNumber: page });
        }
    };

    handleShowMore = async () => {
        const { countOnPage } = this.state;

        this.setState({
            countOnPage: countOnPage + COUNT_ON_PAGE,
            pageNumber: 1,
        });
    };

    async getScenariosData() {
        const { pageNumber, countOnPage } = this.state;

        let scenariosData = await this.getScenarios(pageNumber, countOnPage);

        const offset = Math.min(pageNumber > 3 ? pageNumber - 3 : 0, scenariosData.count - 5);

        this.setState({
            scenarios: scenariosData?.scenarios ?? [],
            pagesCount: Math.ceil(scenariosData.count / countOnPage),
            offset: offset < 0 ? 0 : offset,
        });
    }

    async getScenarios(pageNumber, countOnPage) {
        const [status, scenarios] = await GetScenarios({ skip: (pageNumber - 1) * countOnPage, limit: countOnPage });
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения шаблонов выполнения"), { variant: "error" });
            return;
        }

        return scenarios?.data;
    }

    setSearch = async (search) => {
        this.setState({ search: search });
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    deleteScenarioHandler = async () => {
        const { strings } = this.props;
        const { scenarioToDelete } = this.state;

        let [status, statusCode] = await DeleteScenario(scenarioToDelete.id);
        if (status) {
            this.props.enqueueSnackbar(
                `${strings("Сценарий реагирования")} '${scenarioToDelete.name}' ${strings("удален")}`,
                {
                    variant: "success",
                }
            );
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для удаления сценария реагирования")} '${scenarioToDelete.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    return;
                default:
                    this.props.enqueueSnackbar(
                        `${strings("Не удалось удалить сценарий реагирования")} '${scenarioToDelete.name}'`,
                        {
                            variant: "error",
                        }
                    );
                    return;
            }
        }

        const scenarios = this.state.scenarios.filter((scenario) => scenario.id !== scenarioToDelete.id);
        this.setState({
            scenarios: scenarios ?? [],
            selectedScenario: undefined,
        });
    };

    playPauseScenarioHandler = async (handledScenario, enable) => {
        const { strings } = this.props;
        let { scenarios } = this.state;

        const index = scenarios.findIndex((sc) => sc.id === handledScenario.id);
        if (index < 0) {
            this.props.enqueueSnackbar(`${strings("Cценарий")} '${handledScenario.name}' ${strings("не найден")}`, {
                variant: "error",
            });
            return;
        }

        let scenario = scenarios[index];
        let updatedScenario = { ...scenario };
        updatedScenario.enabled = enable;

        let [status, statusCode] = await UpdateScenario(updatedScenario);
        if (status) {
            this.props.enqueueSnackbar(
                `${strings("Сценарий реагирования")} '${updatedScenario.name}' ${strings("успешно")} ${
                    enable ? strings("возобновлен") : strings("приостановлен")
                }`,
                {
                    variant: "success",
                }
            );
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для изменения сценария реагирования")} '${updatedScenario.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    return;

                default:
                    this.props.enqueueSnackbar(
                        `${strings("Не удалось изменить сценарий реагирования")} '${updatedScenario.name}'`,
                        {
                            variant: "error",
                        }
                    );
                    return;
            }
        }

        scenario.enabled = enable;

        this.setState({
            scenarios: scenarios,
        });
    };

    updateScenarioHandler = async () => {
        const { strings } = this.props;

        let scenario = this.scenarioFromValues(this.state.values);
        scenario.eventUnitCodes = scenario.eventUnitCodes?.map((x) => x.title);

        this.setState({ isLoading: true });

        try {
            let [status, statusCode] = await UpdateScenario(scenario);
            if (status) {
                this.props.enqueueSnackbar(
                    `${strings("Сценарий реагирования")} '${scenario.name}' ${strings("успешно изменен")}`,
                    {
                        variant: "success",
                    }
                );
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для изменения сценария реагирования")} '${scenario.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("Сценарии реагирования должны иметь уникальные имена"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Не удалось изменить сценарий реагирования")} '${scenario.name}'`,
                            {
                                variant: "error",
                            }
                        );
                        break;
                }

                this.setState({ isLoading: false, activeStep: 1 });

                return;
            }

            let { scenarios } = this.state;
            const index = scenarios.findIndex((sc) => sc.id === scenario.id);
            scenarios[index] = scenario;

            this.setState({
                isLoading: false,
                showCreateScenarioModal: false,
                step: 1,
                activeStep: 1,
                values: { ...scenarioInitialValues },
                scenarios: scenarios ?? [],
                selectedScenario: status && this.state.selectedScenario ? scenario : this.state.selectedScenario,
            });
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    createScenarioHandler = async () => {
        const { strings } = this.props;

        let scenario = this.scenarioFromValues(this.state.values);

        if (!scenario.name) {
            this.props.enqueueSnackbar(strings("Нельзя создать сценарий реагирования без имени"), { variant: "error" });
            return;
        }

        if (!scenario.templateId || scenario.templateId === "") {
            this.props.enqueueSnackbar(strings("Нельзя создать сценарий реагирования без привязанного шаблона"), {
                variant: "error",
            });
            return;
        }

        this.setState({ isLoading: true });

        try {
            scenario.eventUnitCodes = scenario.eventUnitCodes?.map((x) => x.title);

            let [status, statusCode, newScenarioId] = await CreateScenario(scenario);
            if (status) {
                this.props.enqueueSnackbar(
                    `${strings("Сценарий реагирования")} '${scenario.name}' ${strings("создан")}`,
                    {
                        variant: "success",
                    }
                );
            } else {
                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(
                            `${strings("Не хватает прав для создания сценария реагирования")} '${scenario.name}'`,
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(strings("Сценарии реагирования должны иметь уникальные имена"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(
                            `${strings("Не удалось создать сценарий реагирования")} '${scenario.name}'`,
                            {
                                variant: "error",
                            }
                        );
                        break;
                }

                this.setState({ isLoading: false, activeStep: 1 });

                return;
            }

            let newScenario = { ...scenario, id: newScenarioId, enabled: true };
            let scenarios = [...this.state.scenarios, newScenario];

            this.setState({
                isLoading: false,
                showCreateScenarioModal: false,
                activeStep: 1,
                step: 1,
                scenarios: scenarios ?? [],
                values: { ...scenarioInitialValues },
            });
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    scenarioFromValues = (values) => {
        let properties = { ...values };
        delete properties.id;
        delete properties.name;
        delete properties.eventUnitCodes;
        delete properties.templateId;
        delete properties.deviceIds;
        delete properties.workPeriods;

        let times;
        switch (values.timeType) {
            case "time":
                times = values.times?.map((time) => convertLocalToUTC(time));
                break;
            case "interval":
                times = values.times?.map((time) => ({
                    from: convertLocalToUTC(time.from),
                    to: convertLocalToUTC(time.to),
                }));
                break;
            case "dateTime":
            default:
                times = values.times;
        }

        return {
            id: values.id,
            name: values.name,
            eventUnitCodes: values.eventUnitCodes,
            deviceIds: values.deviceIds,
            templateId: values.templateId,
            workPeriods: {
                times: times,
                timeType: values.timeType,
                dates: values.dates,
                dateType: values.dateType,
            },
            comment: values.comment,
            enabled: true,
        };
    };

    valuesFromScenario = (scenario) => {
        const scenarioWP = scenario.workPeriods;
        let times;
        switch (scenarioWP?.timeType) {
            case "time":
                times = scenarioWP.times?.map((time) => convertUTCToLocal(time));
                break;
            case "interval":
                times = scenarioWP.times?.map((time) => ({
                    from: convertUTCToLocal(time.from),
                    to: convertUTCToLocal(time.to),
                }));
                break;
            case "dateTime":
                times = scenarioWP.times?.map((time) => new Date(time));
                break;
            default:
                times = [];
        }

        let dates = scenarioWP?.dates;
        if (scenarioWP?.dateType === "date") {
            dates = scenarioWP.dates?.map((date) => new Date(date));
        }

        return {
            id: scenario.id,
            name: scenario.name,
            eventUnitCodes: scenario.eventUnitCodes?.map((evt) => ({
                title: evt,
                id: uuid(),
            })),
            comment: scenario.comment,
            deviceIds: scenario.deviceIds,
            templateId: scenario.templateId,
            dateType: scenarioWP?.dateType,
            dates: dates,
            timeType: scenarioWP?.timeType,
            times: times,
        };
    };

    showCreateScenarioModal = () =>
        this.setState({
            showCreateScenarioModal: true,
            wizardMode: WIZARD_CREATE_MODE,
            values: { ...scenarioInitialValues },
            step: 1,
            activeStep: 1,
        });
    showUpdateScenarioModal = (scenario) =>
        this.setState({
            showCreateScenarioModal: true,
            wizardMode: WIZARD_UPDATE_MODE,
            values: this.valuesFromScenario(scenario),
        });

    showDeleteScenarioModal = (scenario) =>
        this.setState({ showDeleteUserModal: true, values: this.valuesFromScenario(scenario) });

    closeCreateScenarioModal = () => this.setState({ showCreateScenarioModal: false });

    deleteScenario = (scenario) => this.setState({ showDeleteScenarioModal: true, scenarioToDelete: scenario });

    render() {
        const {
            hasSecondary,
            search,
            scenarios,
            selectedScenario,
            detailsTab,
            step,
            activeStep,
            values,
            wizardMode,
            showCreateScenarioModal,
            showDeleteScenarioModal,
            isLoading,
            scenarioToDelete,
            pageNumber,
            pagesCount,
            offset,
        } = this.state;
        const { userInfo, strings } = this.props;

        let searchScenarios = scenarios?.sort(CompareNodes) ?? [];
        if (search) {
            searchScenarios = scenarios.filter((et) => et.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
        }

        return (
            <>
                {isLoading && <LoaderAnimation message={strings("Применение настроек")} />}
                <DeleteModal
                    visible={showDeleteScenarioModal}
                    CloseHandler={() => this.setState({ showDeleteScenarioModal: false })}
                    RemoveHandler={this.deleteScenarioHandler}
                    text={`${strings("Вы хотите удалить сценарий реагирования")} "${scenarioToDelete?.name}". ${strings(
                        "Удалённый сценарий реагирования нельзя восстановить. Продолжить?"
                    )}`}
                />

                <CreateUpdateWizard
                    visible={showCreateScenarioModal}
                    title={`${
                        wizardMode === WIZARD_CREATE_MODE ? strings("Создать новый") : strings("Изменить")
                    } ${strings("сценарий реагирования")}`}
                    steps={wizardSteps}
                    step={step}
                    activeStep={activeStep}
                    values={values}
                    setValues={(value) => this.setState({ values: value })}
                    NextStep={this.nextStep}
                    SetActiveStep={this.selectStep}
                    CreateHandler={this.createScenarioHandler}
                    EditHandler={this.updateScenarioHandler}
                    CloseHandler={this.closeCreateScenarioModal}
                    Mode={wizardMode}
                />
                <EventsLayoutPrimary hasSecondary={hasSecondary}>
                    <EventsLayoutHeader>
                        <EventsLayoutHeaderTitle>
                            <Text variant="title" component="h1">
                                {strings("Сценарии реагирования")}
                            </Text>

                            <ExpandableSearch
                                value={search}
                                onChange={(e) => this.setSearch(e.target.value)}
                                onSubmit={() => {
                                    /* ignore enter */
                                }}
                                placeholder={strings("Найти")}
                                colorVariant="dark"
                                inHeader
                            />
                        </EventsLayoutHeaderTitle>

                        {permissionExists(userInfo, ["scenarios.service.access", "scenarios.scenario.lifeCycle"]) ? (
                            <EventsLayoutHeaderToolbox>
                                <Dropdown
                                    toggleLabel={strings("Создать")}
                                    closeOnClickInside
                                    toggleVariant="secondary"
                                    horizontalAlignment="right"
                                >
                                    <DropdownItem as="button" onClick={this.showCreateScenarioModal}>
                                        {strings("Сценарий реагирования")}
                                    </DropdownItem>
                                </Dropdown>
                            </EventsLayoutHeaderToolbox>
                        ) : (
                            <div />
                        )}
                    </EventsLayoutHeader>
                    <EventsLayoutWrapper>
                        <ScenariosTable
                            scenarioList={searchScenarios}
                            selectedScenario={selectedScenario}
                            setSelectedScenario={(scenario) => this.setState({ selectedScenario: scenario })}
                            deleteScenario={this.deleteScenario}
                            editScenario={this.showUpdateScenarioModal}
                        />
                        <EventsLayoutPaginationWrapper>
                            <Button variant="secondary" label={strings("Показать ещё")} onClick={this.handleShowMore} />
                            <Pagination
                                offset={offset}
                                page={pageNumber}
                                count={pagesCount}
                                onChange={this.handlePage}
                            />
                        </EventsLayoutPaginationWrapper>
                    </EventsLayoutWrapper>
                </EventsLayoutPrimary>
                {selectedScenario && (
                    <ScenariosDetails
                        key={`${selectedScenario.id}-${selectedScenario.deviceIds}`}
                        selectedScenario={selectedScenario}
                        detailsTab={detailsTab}
                        setDetailsTab={(detailsTab) => this.setState({ detailsTab: detailsTab })}
                        setSelectedScenario={(scenario) => this.setState({ selectedScenario: scenario })}
                        deleteScenario={this.deleteScenario}
                        editScenario={this.showUpdateScenarioModal}
                        playPauseScenario={this.playPauseScenarioHandler}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps)(withCultureContext(withSnackbar(ScenariosSettings)));

import React from "react";
import styled from "styled-components";
import {
    Button,
    Checkbox,
    Icon,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Row,
    Select,
    Space,
    Text,
    TextField,
} from "headpoint-react-components";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { FilterAccordion } from "../../../components/Filters";
import { FillLocations, FillNode, GetLocationList, GetLocations } from "../../../../services/locations";
import { GetDevices, SearchDevices } from "../../../../services/devices";
import { CreateUserEvent, UploadUserEventFiles } from "../../../../services/events";
import moment from "moment/moment";
import { withSnackbar } from "notistack";
import { DatepickerSeconds } from "./DatePickerSeconds";
import { InoneFileInput } from "../../../components/FileInput/InoneFileInput";
import { getSystemVariableValue } from "../../../../contexts";
import LargeFilesModal from "./LargeFilesModal";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

const ModalFormItem = styled.div`
    & + & {
        margin-top: 16px;
    }
`;

const FileInputComponent = React.memo(({ setFile, preloadedFiles, validSize, setIsValidSize, strings }) => (
    <InoneFileInput
        onChange={setFile}
        addMoreLabel={strings("Добавить еще...")}
        placeholder={strings("Выберите файл или перенесите его сюда")}
        fullWidth
        multiple={true}
        preloadedFiles={preloadedFiles}
        validSize={validSize}
        errorLabels={{
            sizeError: `${strings("Размер файла превышает допустимый предел в")} ${validSize} ${strings(
                "МБ. Обратитесь к администратору"
            )}`,
        }}
        setIsValidSize={setIsValidSize}
    />
));

const USER_EVENTS_ADAPTER_CODE = "service.adapters.userEvents";

class EventsNewEventModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            filteredDevices: [],
            search: "",
            devices: [],
            userEvent: {
                eventType: "user-event",
                message: "",
                description: "",
                deviceId: undefined,
                date: this.props.screenshotTime ?? moment(new Date()).milliseconds(0).toDate(),
                files: this.props.file ? [this.props.file] : [],
            },
            showLargeFileSizeModal: false,
            isValidSize: true,
        };
    }

    getFileSizeLimit() {
        const mbLimit = getSystemVariableValue(
            this.props.generalInfo,
            "service.api.events",
            "events.file.size.limit",
            1
        );
        return mbLimit * Math.pow(2, 20);
    }

    setUserEventProperties = (name, value) => {
        let values = { ...this.state.userEvent, [name]: value };
        this.setState({ userEvent: values });
    };

    setFile = (files) => {
        let values = { ...this.state.userEvent, files };
        this.setState({ userEvent: values });
    };

    hasLargeFiles = () => {
        return this.getLargeFiles().length > 0;
    };

    getLargeFiles = () => {
        return this.state.userEvent.files.filter((f) => f.size > this.getFileSizeLimit());
    };

    handleSubmit = async (e) => {
        if (this.hasLargeFiles()) {
            this.setState({ showLargeFileSizeModal: true });
        } else {
            await this.createEvent(e);
        }
    };

    getUserEventsAdapter = () => this.props.generalInfo?.services?.find((s) => s.code === USER_EVENTS_ADAPTER_CODE);

    createEvent = async (e) => {
        e.preventDefault();
        const { userEvent } = this.state;
        const { strings } = this.props;

        if (!userEvent.deviceId) {
            this.props.enqueueSnackbar(strings("Укажите устройство"), { variant: "error" });
            return;
        }
        const userEventsAdapter = this.getUserEventsAdapter();
        if (!userEventsAdapter?.serviceId) {
            this.props.enqueueSnackbar(strings("Адаптер пользовательских событий не найден!"), { variant: "error" });
            this.setState({ createUserEvent: false, userEvent: {} });
            return;
        }

        if (userEvent.date > moment(new Date()).milliseconds(0).toDate()) {
            this.props.enqueueSnackbar(strings("Невозможно создать событие в будущем времени"), { variant: "error" });
            return;
        }

        this.setState({ loaderMessage: strings("Загрузка файлов..."), createUserEvent: false });

        try {
            let [loadStatus, files] = await UploadUserEventFiles(userEvent.files);
            if (!loadStatus) {
                this.props.enqueueSnackbar(strings("Ошибка загрузки файлов"), { variant: "error" });
                this.setState({ loaderMessage: null, createUserEvent: false, userEvent: {} });
                return;
            }

            userEvent.files = files;

            this.setState({
                loaderMessage: strings("Отправка события. Подождите..."),
                createUserEvent: false,
            });
            let [status, responseStatus] = await CreateUserEvent(userEventsAdapter?.serviceId, userEvent);
            if (status) {
                this.props.enqueueSnackbar(strings("Событие создано"), { variant: "success" });
            } else if (responseStatus === 404) {
                this.props.enqueueSnackbar(strings("Устройство пользовательских событий не найдено"), {
                    variant: "error",
                });
            } else {
                this.props.enqueueSnackbar(strings("Ошибка при создании пользовательского события"), {
                    variant: "error",
                });
            }
        } finally {
            this.setState({
                loaderMessage: null,
            });
        }

        this.props.handleClose();
    };

    cmdFilterBySingleDevice = async () => {
        this.setState({ treeLoading: true });

        const { deviceId, locationId, strings } = this.props;
        const { devicesLocationsTree } = this.state;

        try {
            let [locationsStatus, locationsDb] = await GetLocationList({ ids: [locationId] });
            if (!locationsStatus || !locationsDb?.length) {
                this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
                return;
            }

            const [locationDb] = locationsDb;

            let parentIds = [...locationDb.parentIds.slice(1), locationId];

            let locationNode;

            let tree = devicesLocationsTree;

            for (const parentId of parentIds) {
                locationNode = tree.find((i) => i.id === parentId);

                if (!locationNode) {
                    this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                        variant: "error",
                    });
                    return;
                }

                locationNode.preOpen = true;

                await this.openLocation(locationNode);

                tree = locationNode.children;
            }

            if (!locationNode?.children?.find((d) => d.id === deviceId)) {
                this.props.enqueueSnackbar(`${strings("Не удалось найти устройство с идентификатором")} ${deviceId}`, {
                    variant: "error",
                });
            }

            this.setUserEventProperties("deviceId", deviceId);
        } finally {
            this.setState({ treeLoading: false });
        }
    };

    openLocation = async (locationNode) => {
        const { devicesLocationsTree, devices } = this.state;
        const { strings } = this.props;

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [getDevicesStatus, devicesFromLocation] = await GetDevices(locationNode.id);

        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let newDevices = [
            ...devices,
            ...devicesFromLocation.filter((c) => devices.map((c) => c.id).includes(c.id) !== true),
        ];

        FillNode(locationNode, children, devicesFromLocation);

        this.setState({ devicesLocationsTree, devices: newDevices });
    };

    componentDidMount = async () => {
        const { locationId, strings } = this.props;

        const [status, locations] = await GetLocations();

        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        this.setState({
            devicesLocationsTree: FillLocations(locations, []),
        });

        if (locationId) {
            await this.cmdFilterBySingleDevice();
        }
    };

    async setSearch(search) {
        const { strings } = this.props;

        if (search && search !== "") {
            let [devicesStatus, devices] = await SearchDevices({
                like: search,
                limit: 5,
            });

            if (!devicesStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                return;
            }

            devices = devices.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });

            this.setState({ search, filteredDevices: devices });
        } else {
            this.setState({ search, filteredDevices: [] });
        }
    }

    showLargeFileSizeModal = () => {
        const { strings } = this.props;

        return (
            <LargeFilesModal
                largeFiles={this.getLargeFiles()}
                onNotApproved={() => this.setState({ showLargeFileSizeModal: false })}
                onLastApproved={async (e) => {
                    this.setState({ showLargeFileSizeModal: false });
                    await this.createEvent(e);
                }}
                strings={strings}
            />
        );
    };

    getAvailableEventTypes = (strings) => {
        return (
            this.getUserEventsAdapter()?.properties?.eventTypes?.map((eventType) => ({
                value: eventType.code,
                label: strings(eventType.name),
            })) ?? []
        );
    };

    render() {
        const { userEvent, search, filteredDevices, devicesLocationsTree, loaderMessage, showLargeFileSizeModal } =
            this.state;
        const { strings } = this.props;

        return (
            <>
                {loaderMessage && <LoaderAnimation message={loaderMessage} />}
                {showLargeFileSizeModal && this.showLargeFileSizeModal()}
                <Modal size="sm" closeHandler={this.props.handleClose}>
                    <ModalHeader title={strings("Создать событие")} closeHandler={this.props.handleClose} />
                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <ModalBodyItem>
                                <ModalFormItem>
                                    <TextField
                                        label={strings("Краткая информация")}
                                        name="message"
                                        type="text"
                                        value={userEvent.message}
                                        onChange={(e) => this.setUserEventProperties("message", e.target.value)}
                                        colorVariant="light"
                                        fullWidth
                                    />
                                </ModalFormItem>
                                <ModalFormItem>
                                    <Select
                                        label={strings("Тип события")}
                                        placeholder={strings("Выбрать тип события")}
                                        value={userEvent.eventType}
                                        options={this.getAvailableEventTypes(strings)}
                                        onChange={(eventType) => this.setUserEventProperties("eventType", eventType)}
                                        colorVariant="default"
                                        horizontalAlignment="left"
                                        verticalAlignment="bottom"
                                        fullWidth
                                    />
                                </ModalFormItem>
                                <ModalFormItem>
                                    <TextField
                                        label={strings("Описание")}
                                        name="description"
                                        type="text"
                                        value={userEvent.description}
                                        onChange={(e) => this.setUserEventProperties("description", e.target.value)}
                                        colorVariant="light"
                                        fullWidth
                                        inputProps={{
                                            as: "textarea",
                                            rows: 3,
                                        }}
                                    />
                                </ModalFormItem>

                                <ModalFormItem>
                                    <Text color="secondary" variant="body-sm">
                                        {strings("Устройство")}
                                    </Text>
                                    <Space />
                                    <Row marginBottom={12}>
                                        <TextField
                                            autoComplete={false}
                                            colorVariant="light"
                                            fullWidth
                                            icon="search"
                                            onChange={(e) => this.setSearch(e.target.value)}
                                            placeholder={strings("Найти")}
                                            type="search"
                                            value={search}
                                        />
                                    </Row>
                                    {search && search !== "" ? (
                                        filteredDevices?.map((device) => (
                                            <Row marginBottom={12} key={device.id}>
                                                <Checkbox
                                                    label={device.name}
                                                    checked={userEvent.deviceId === device.id}
                                                    onChange={() => {
                                                        this.setUserEventProperties("deviceId", device.id);
                                                    }}
                                                />
                                            </Row>
                                        ))
                                    ) : (
                                        <FilterAccordion
                                            items={devicesLocationsTree}
                                            selected={[userEvent.deviceId]}
                                            openItem={this.openLocation}
                                            blockParentCheckbox={true}
                                            selectHandler={(items) => {
                                                this.setUserEventProperties("deviceId", items.pop());
                                            }}
                                        />
                                    )}
                                </ModalFormItem>

                                <ModalFormItem>
                                    <DatepickerSeconds
                                        date={userEvent.date}
                                        onChange={(e) => this.setUserEventProperties("date", e)}
                                        label={strings("Дата наступления")}
                                        colorVariant="transparent"
                                        inputFormat="DD.MM.YY HH:mm:ss"
                                        customIcon={<Icon icon="calendar" />}
                                        withTime
                                        customInputLabels={{
                                            hours: strings("Часы"),
                                            minutes: strings("Минуты"),
                                            seconds: strings("Секунды"),
                                        }}
                                        calendarProps={{
                                            disabledDays: {
                                                after: new Date(),
                                            },
                                        }}
                                    />
                                </ModalFormItem>
                                <ModalFormItem></ModalFormItem>
                                <ModalFormItem>
                                    <FileInputComponent
                                        setFile={(files) => this.setFile(files)}
                                        preloadedFiles={userEvent.files ?? []}
                                        validSize={getSystemVariableValue(
                                            this.props.generalInfo,
                                            USER_EVENTS_ADAPTER_CODE,
                                            "user.events.maxsize",
                                            null
                                        )}
                                        setIsValidSize={(isValidSize) => this.setState({ isValidSize })}
                                        strings={strings}
                                    />
                                </ModalFormItem>
                            </ModalBodyItem>
                        </ModalBody>

                        <ModalFooter>
                            <ModalFooterItem>
                                <Button
                                    type="button"
                                    variant="primary"
                                    label={strings("Создать")}
                                    disabled={!this.state.isValidSize}
                                    onClick={this.handleSubmit}
                                />
                            </ModalFooterItem>

                            <ModalFooterItem>
                                <Button
                                    type="button"
                                    variant="ghost"
                                    label={strings("Отмена")}
                                    onClick={this.props.handleClose}
                                />
                            </ModalFooterItem>
                        </ModalFooter>
                    </form>
                </Modal>
            </>
        );
    }
}

export default withCultureContext(withSnackbar(EventsNewEventModal));

const path = "/presets-api/presets";

export const CreatePreset = async (preset, cameraId) => {
    try {
        const token = window.localStorage.getItem("token");
        let response = await fetch(`${path}/${cameraId}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(preset),
        });

        return [response.ok, response.status, response.ok ? (await response.json())?.data : null];
    } catch (e) {
        console.error(e);
        return [false, null, null];
    }
};

export const GetPresets = async (cameraId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/${cameraId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const UpdatePreset = async (preset, presetId, cameraId) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${cameraId}/${presetId}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(preset),
    });

    return [response.status === 200, response.status];
};

export const DeletePreset = async (presetId, cameraId) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${cameraId}/${presetId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return [response.status === 200, response.status];
};

export const GetPerset = async (presetId, cameraId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/${cameraId}/${presetId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, null];
        }

        let res = await response.json();
        return [true, res?.data];
    } catch (ex) {
        console.error(ex);
        return [false, null];
    }
};

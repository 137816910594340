import React from "react";
import { LayoutSidebar, LayoutSidebarBody, LayoutSidebarItem } from "../../../layout/MainLayout";
import { SidebarTree } from "../../components/Sidebar/SidebarTree";
import { permissionExists } from "../../../contexts";
import { connect } from "react-redux";
import { FilterGroup, FilterGroupList, FilterItem, FilterWrapper } from "../../components/FiltersPartion";
import { Checkbox, TextField } from "headpoint-react-components";
import { nanoid } from "nanoid";
import { saveDeviceOffset } from "../../../app/reducers/galleryReducer";

class Filters extends React.Component {
    handleCameraSelect = (cameraItem) => {
        const {
            frames,
            selectedCameraFrame,
            currentLayout,
            setFrames,
            setSelectedCamera,
            setSelectedCameraFrame,
            generalInfo,
        } = this.props;

        const nextFrames = { ...frames };

        if (Object.keys(nextFrames).length && Object.values(nextFrames).find((f) => f.id === cameraItem.id)) {
            return;
        }

        const targetFrame =
            !selectedCameraFrame && currentLayout === "1" && !Object.keys(nextFrames).length ? 0 : selectedCameraFrame;

        if (targetFrame === undefined || targetFrame === null) {
            return;
        }

        setSelectedCameraFrame(targetFrame);

        if (setSelectedCamera) {
            setSelectedCamera(generalInfo, cameraItem);
        }

        nextFrames[targetFrame] = cameraItem;

        setFrames(nextFrames);
    };

    handleScheduleCameraSelect = (cameraItem) => {
        const {
            frames,
            selectedCameraFrame,
            setSelectedCameraFrame,
            gallery: { deviceOffset },
        } = this.props;

        const deviceIndex = Number(Object.keys(frames).find((key) => frames[key].id === cameraItem.id));

        if (isNaN(deviceIndex)) return;

        this.props.dispatch(saveDeviceOffset(deviceOffset - (selectedCameraFrame - deviceIndex)));
        setSelectedCameraFrame(deviceIndex);
    };

    render() {
        const {
            search,
            setSearch,
            frames,
            devicesLocationsTree,
            openLocation,
            strings,
            scheduleSearch,
            setScheduleSearch,
            schedules,
            selectedScheduleId,
            selectSchedule,
        } = this.props;
        const { userInfo } = this.props;

        return (
            <LayoutSidebar>
                <LayoutSidebarBody>
                    <LayoutSidebarItem>
                        <LayoutSidebarItem>
                            <TextField
                                autoComplete={false}
                                colorVariant="light"
                                fullWidth
                                icon="search"
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={strings("Найти")}
                                type="search"
                                value={search}
                            />
                        </LayoutSidebarItem>
                        {permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
                            <LayoutSidebarItem hasScroll hasHeight={"250px"}>
                                <SidebarTree
                                    frames={frames}
                                    list={devicesLocationsTree}
                                    onSelect={
                                        selectedScheduleId ? this.handleScheduleCameraSelect : this.handleCameraSelect
                                    }
                                    onOpenLocation={openLocation}
                                />
                            </LayoutSidebarItem>
                        ) : (
                            <div />
                        )}
                    </LayoutSidebarItem>
                    <FilterWrapper>
                        <FilterGroup
                            title={strings("Расписания")}
                            onClear={() => setScheduleSearch("")}
                            isMuted={true}
                            isOpened={true}
                        >
                            <TextField
                                colorVariant="light"
                                fullWidth
                                onChange={(e) => setScheduleSearch(e.target.value)}
                                placeholder={strings("Найти")}
                                type="text"
                                value={scheduleSearch}
                            />

                            <FilterGroupList>
                                {schedules.map((schedule) => (
                                    <FilterItem key={nanoid()}>
                                        <Checkbox
                                            label={strings(schedule.name)}
                                            checked={selectedScheduleId && selectedScheduleId === schedule.id}
                                            onChange={() => selectSchedule(schedule)}
                                        />
                                    </FilterItem>
                                ))}
                            </FilterGroupList>
                        </FilterGroup>
                    </FilterWrapper>
                </LayoutSidebarBody>
            </LayoutSidebar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        gallery: state.gallery,
    };
};

export default connect(mapStateToProps, null)(Filters);

import { ModalSection, Row, Text, TextField } from "headpoint-react-components";
import { FilterGroup, FilterGroupList, FilterNoParentAccordion } from "../../../../components/Filters";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveScheduleState } from "../../../../../app/reducers/scheduleReducer";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { withGeneralContext } from "../../../../../contexts/GeneralContext";
import { GetTags } from "../../../../../services/tags";
import { GetDevicesGroups } from "../../../../../services/devicesGroups";
import { withSnackbar } from "notistack";

const ScheduleDevicesDynamicFilter = ({ enqueueSnackbar, strings }) => {
    const dispatch = useDispatch();
    const values = useSelector((state) => state.schedule.values);
    const [tagSearch, setTagSearch] = useState(null);
    const [groupSearch, setGroupSearch] = useState(null);
    const [tags, setTags] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const getTags = async () => {
            const [tagStatus, tags] = await GetTags();
            if (!tagStatus) {
                enqueueSnackbar(strings("Ошибка получения тегов оборудования"), { variant: "error" });
                return;
            }

            setTags(tags);
        };

        const getDeviceGroups = async () => {
            const [status, deviceGroups] = await GetDevicesGroups();
            if (!status) {
                enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
            }

            setGroups(deviceGroups);
        };

        getTags();
        getDeviceGroups();
    }, [enqueueSnackbar, strings]);

    const saveFilter = (name, value) => {
        dispatch(saveScheduleState({ values: { ...values, [name]: value } }));
    };

    const searchFilter = (search, data, keyExp) => {
        if (!search) {
            return data;
        }

        return data?.filter((t) => keyExp(t)?.toLowerCase()?.includes(search.toLowerCase()));
    };

    const handleCheckboxChange = (filterName, id) => {
        let filterValue = values[filterName];
        saveFilter(
            filterName,
            filterValue?.includes(id) ? filterValue?.filter((item) => item !== id) : [...filterValue, id]
        );
    };

    const showCount = true;

    return (
        <>
            <ModalSection>
                <FilterGroup
                    title={
                        <Row justify="between">
                            <Text variant="body-sm">{strings("Теги оборудования")}</Text>
                            {showCount && (
                                <Text variant="body-sm" align="right">
                                    {`${strings("Выбрано:")} ${values.tagsFilter?.length ?? 0}`}
                                </Text>
                            )}
                        </Row>
                    }
                    hasValue={!!values.tagsFilter?.length}
                    onClear={() => {
                        setTagSearch("");
                        saveFilter("tagsFilter", []);
                    }}
                    noBorder={true}
                >
                    <TextField
                        colorVariant="light"
                        fullWidth
                        onChange={(e) => setTagSearch(e.target?.value)}
                        placeholder={strings("Найти")}
                        type="text"
                        value={tagSearch}
                    />

                    <FilterGroupList>
                        <FilterNoParentAccordion
                            items={searchFilter(tagSearch, tags, (t) => t.name)}
                            selected={values.tagsFilter}
                            selectHandler={(id, tag) => handleCheckboxChange("tagsFilter", tag.id)}
                        />
                    </FilterGroupList>
                </FilterGroup>
            </ModalSection>
            <ModalSection>
                <FilterGroup
                    title={
                        <Row justify="between">
                            <Text variant="body-sm">{strings("Группы оборудования")}</Text>
                            {showCount && (
                                <Text variant="body-sm" align="right">
                                    {`${strings("Выбрано:")} ${values.groupsFilter?.length ?? 0}`}
                                </Text>
                            )}
                        </Row>
                    }
                    hasValue={!!values.groupsFilter?.length}
                    onClear={() => {
                        setGroupSearch("");
                        saveFilter("groupsFilter", []);
                    }}
                    noBorder={true}
                >
                    <TextField
                        colorVariant="light"
                        fullWidth
                        onChange={(e) => setGroupSearch(e.target?.value)}
                        placeholder={strings("Найти")}
                        type="text"
                        value={groupSearch}
                    />

                    <FilterGroupList>
                        <FilterGroupList>
                            <FilterNoParentAccordion
                                items={searchFilter(groupSearch, groups, (g) => g.name)}
                                selected={values.groupsFilter}
                                selectHandler={(id, group) => handleCheckboxChange("groupsFilter", group.id)}
                            />
                        </FilterGroupList>
                    </FilterGroupList>
                </FilterGroup>
            </ModalSection>
        </>
    );
};

export default withCultureContext(withGeneralContext(withSnackbar(ScheduleDevicesDynamicFilter)));

import { getDataFromSource } from "../utilites";
import React from "react";
import { TableCell, TableRow, Text } from "headpoint-react-components";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import moment from "moment/moment";
import {
    formatAvailabilityDaysCount,
    formatAvailabilityHoursCount,
    formatAvailabilityMinutesCount,
} from "../../../../../utilites/TextUtils";

class TableListBlock extends React.Component {
    getFormatTimeSpan = (value) => {
        const { selectedEvent, strings } = this.props;
        const sourceValue = getDataFromSource(value.source, selectedEvent);

        if (sourceValue === undefined || sourceValue === null) return;

        switch (value.type) {
            case "timespan":
                const duration = moment.duration(sourceValue);
                let timespanLabel = "";
                const dd = Math.trunc(duration.asDays());
                const hh = duration.hours();
                const mm = duration.minutes();

                if (dd > 0) {
                    timespanLabel += `${dd} ${strings(formatAvailabilityDaysCount(dd))} `;
                }

                if (hh > 0) {
                    timespanLabel += `${hh} ${strings(formatAvailabilityHoursCount(hh))} `;
                }

                if (mm > 0) {
                    timespanLabel += `${mm} ${strings(formatAvailabilityMinutesCount(mm))}`;
                }

                if (timespanLabel.length === 0) {
                    return strings("Несколько секунд");
                }

                return timespanLabel;
            default:
                return strings(sourceValue);
        }
    };

    render() {
        const { data, strings } = this.props;
        return (
            <>
                <TableRow>
                    <TableCell>{data.name}</TableCell>
                    <TableCell />
                </TableRow>

                {data.list?.map((value, id) => {
                    return (
                        <TableRow key={id}>
                            <TableCell indent={30} noBorder>
                                {strings(value.name)}
                            </TableCell>
                            <TableCell noBorder>
                                <Text color="primary" variant="body-sm">
                                    {this.getFormatTimeSpan(value)}
                                </Text>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </>
        );
    }
}

export default withCultureContext(TableListBlock);

import React from "react";
import { DeviceTypeSettings } from "./DeviceTypesSettings";
import { ModalSection } from "headpoint-react-components";

export class ModalStepDeviceTypeSettings extends React.Component {
    render() {
        const { DeviceTypes, values, steps, activeStep } = this.props;
        const selectedDeviceType = DeviceTypes?.find((dt) => dt.value === values.deviceType);
        const currentStepName = steps[activeStep - 1]?.name;
        const sectionSettings = selectedDeviceType?.settings?.sections?.find(
            (s) => s.name === currentStepName
        )?.settings;

        return (
            <>
                <ModalSection>
                    <DeviceTypeSettings
                        deviceSettings={this.props.values.settings}
                        selectedDevTypeSettings={sectionSettings}
                        SetDeviceSettings={this.props.SetDeviceSettings}
                        SetValuesProperty={this.props.SetValuesProperty}
                        mode={this.props.mode}
                    />
                </ModalSection>
            </>
        );
    }
}

import React from "react";

export class WebCodecsClient extends React.Component {
    constructor(props) {
        super(props);
        this.ws = null;
        this.cachedSPSBuffer = new ArrayBuffer();
        this.cachedPPSBuffer = new ArrayBuffer();
        this.stream = new TransformStream();
        this.streamWriter = this.stream.writable.getWriter();
        this.worker = new window.Worker("stream_worker.js");
        this.generator = new window.MediaStreamTrackGenerator({ kind: "video" });
        const video = document.getElementById(props.videoTag);
        if (video) {
            video.srcObject = new MediaStream([this.generator]);
        }

        this.captureTimeHeader = new Uint8Array([0x05, 0x05, 0x05]);
        this.NAL_UNIT_TYPE = {
            IDR: 5,
            NON_IDR: 1,
            SPS: 7,
            PPS: 8,
        };
    }

    static getUrl = (externalId) => {
        const wsProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
        const url = new URL(window.location.href);
        return `${wsProtocol}${url.hostname}:${url.port}/balancer/wasm-${externalId}/`;
    };

    isOpen() {
        return this.ws != null;
    }

    open(options) {
        let { worker } = this;

        if (this.ws != null) {
            console.log("ws open error: already opened");
            return;
        }
        this.options = { ...this.options, ...options };
        console.log("ws open options:");
        console.log(this.options);
        if (this.options.url == null) {
            console.log("ws open error: url is null");
            return;
        }
        worker.postMessage(
            {
                type: "stream",
                streams: { input: this.stream.readable, output: this.generator.writable },
            },
            [this.stream.readable, this.generator.writable]
        );
        const ws = new WebSocket(this.options.url);
        ws.binaryType = "arraybuffer";
        ws.onopen = (e) => this.onopen(e, this.options.rtsp, this.options.externalId);
        ws.onmessage = (e) => this.onmessage(e);
        ws.onclose = (e) => this.onclose(e);
        ws.onerror = (e) => this.onerror(e);
        this.ws = ws;
    }

    close() {
        if (this.ws != null) {
            console.log("ws close");
            this.ws.close();
            this.ws = null;
            if (this.worker) {
                this.worker.postMessage({
                    type: "stop",
                });
            }
            this.worker.terminate();
            this.worker = null;
        }
    }

    onopen(e, rtspUrl, externalId) {
        const token = window.localStorage.getItem("token");
        const request = {
            uri: rtspUrl,
            token: token,
            externalId: externalId,
            type: "webcodecs",
        };
        const cameraRequest = JSON.stringify(request);

        console.log(`ws open: ${this.options.url}`);
        this.options.onopen && this.options.onopen(e);
        this.ws.send(cameraRequest);
    }

    onmessage(message) {
        let { NAL_UNIT_TYPE, concatArrayBuffers } = this;

        if (message.id || !message.data || !this.worker) {
            return;
        }

        const u8a = new Uint8Array(message.data);
        if (this.props.handleWebsocketTimestamp) {
            const startIndex = this.arrayIndexOfMulti(u8a, this.captureTimeHeader, 0);
            if (startIndex >= 0) {
                const stringTs = new TextDecoder().decode(u8a.slice(3));
                const captureTime = parseInt(stringTs, 10);
                this.props.handleWebsocketTimestamp(captureTime);
                return;
            }
        }

        const timestamp = Date.now();
        const nalUnitType = u8a[3] & 0x1f;
        switch (nalUnitType) {
            case NAL_UNIT_TYPE.IDR:
                if (this.cachedSPSBuffer.byteLength === 0) {
                    return;
                }

                const spsU8a = new Uint8Array(this.cachedSPSBuffer);
                const hexProfile = spsU8a[4].toString(16).padStart(2, "0");
                const hexCompatibility = spsU8a[5].toString(16).padStart(2, "0");
                const hexLevel = spsU8a[6].toString(16).padStart(2, "0");
                const codec = `avc1.${hexProfile}${hexCompatibility}${hexLevel}`;

                this.streamWriter.write({
                    config: { codec: codec },
                    type: "key",
                    timestamp,
                    data: concatArrayBuffers([this.cachedSPSBuffer, this.cachedPPSBuffer, u8a.buffer]),
                });
                break;

            case NAL_UNIT_TYPE.NON_IDR:
                this.streamWriter.write({
                    type: "delta",
                    timestamp,
                    data: u8a.buffer,
                });
                break;

            case NAL_UNIT_TYPE.SPS:
                this.cachedSPSBuffer = u8a.buffer;
                break;

            case NAL_UNIT_TYPE.PPS:
                this.cachedPPSBuffer = u8a.buffer;
                break;

            default:
                break;
        }
    }

    onclose(e) {
        console.log(`ws close: ${this.options.url}`);
        this.options.onclose && this.options.onclose(e);
    }

    onerror(e) {
        console.log(`ws error: ${this.options.url}, ${e}`);
        this.options.onerror && this.options.onerror(e);
    }

    concatArrayBuffers(arrayBuffers) {
        const sumByteLength = arrayBuffers.reduce((acc, cur) => {
            return acc + cur.byteLength;
        }, 0);

        const concatenatedUint8Array = new Uint8Array(sumByteLength);
        let offset = 0;

        for (const arrayBuffer of arrayBuffers) {
            concatenatedUint8Array.set(new Uint8Array(arrayBuffer), offset);
            offset += arrayBuffer.byteLength;
        }

        return concatenatedUint8Array.buffer;
    }

    arrayIndexOfMulti(array, searchElements, fromIndex) {
        const index = array.indexOf(searchElements[0], fromIndex);

        if (searchElements.length === 1 || index === -1) {
            return index;
        }

        let i = index;
        for (let j = 0; j < searchElements.length && i < array.length; i++, j++) {
            if (array[i] !== searchElements[j]) {
                return this.arrayIndexOfMulti(array, searchElements, index + 1);
            }
        }

        return i === index + searchElements.length ? index : -1;
    }
}

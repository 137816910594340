import React from "react";
import { withSnackbar } from "notistack";
import PubSub from "pubsub-js";
import { DeleteModal as DeleteModalComponent } from "../../DeleteModal";
import { GROUP_DELETED_TOPIC, SHOW_DELETE_GROUP_MODAL } from "../devicesGroupsTopics";
import { DeleteDevicesGroup } from "../../../../../services/devicesGroups";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DeleteModal extends React.Component {
    removeHandler = async () => {
        const { groupForRemove, strings } = this.props;
        if (!groupForRemove) {
            return;
        }

        let [res, statusCode] = await DeleteDevicesGroup(groupForRemove.id);
        if (res) {
            this.props.enqueueSnackbar(
                `${strings("Группа оборудования")} '${groupForRemove.name}' ${strings("удалена")}`,
                { variant: "success" }
            );
        } else {
            switch (statusCode) {
                case 409: // conflict
                    this.props.enqueueSnackbar(strings("Нельзя удалить группу, которая содержит оборудование"), {
                        variant: "warning",
                    });
                    return;
                default:
                    this.props.enqueueSnackbar(strings("Не удалось удалить группу оборудования"), { variant: "error" });
                    return;
            }
        }

        PubSub.publish(GROUP_DELETED_TOPIC, groupForRemove.id);
    };

    render() {
        const { strings } = this.props;

        return (
            <DeleteModalComponent
                visible={true}
                CloseHandler={() => PubSub.publish(SHOW_DELETE_GROUP_MODAL, undefined)}
                RemoveHandler={this.removeHandler}
                text={`${strings("Вы хотите удалить группу")} "${this.props.groupForRemove.name}". ${strings(
                    "Удалённый раздел нельзя восстановить. Продолжить?"
                )}`}
            />
        );
    }
}

export default withCultureContext(withSnackbar(DeleteModal));

import { urlWithQuery } from "../utilites/UrlUtils";
import { DownloadByPath } from "./storage";

const qs = require("qs");

const path = "/devices-api/devices";
const massOperationsPath = "/devices-api/mass-operations";

export const GetDevicesCount = async (locationId) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/count/${locationId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, -1];
        }

        let res = await response.json();
        return [true, res?.count ?? -1];
    } catch (ex) {
        console.error(ex);
        return [false, -1];
    }
};

export const SearchDevices = async (filter) => {
    const token = window.localStorage.getItem("token");

    const groupQuery = qs.stringify(filter, { arrayFormat: "repeat" });

    try {
        let response = await fetch(`${path}/search?${groupQuery}`, {
            method: "Get",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const CreateDevice = async (device) => {
    try {
        const token = window.localStorage.getItem("token");
        let response = await fetch(path, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(device),
        });

        return [response.ok, response.status, await response.json()];
    } catch (e) {
        console.error(e);
        return [false, null, null];
    }
};

export const GetDeviceInfo = async (id) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}/info`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

export const GetDevices = async (locationId, deviceTypeIds, deviceGroupIds, deviceTagIds, deviceIds) => {
    const token = window.localStorage.getItem("token");
    try {
        let queryFilter = `?${serializeArrayToQuery(deviceTypeIds, "deviceTypes", null)}`;
        queryFilter += `${queryFilter?.length ? "&" : "?"}${serializeArrayToQuery(deviceTagIds, "deviceTags", null)}`;
        queryFilter += `${queryFilter?.length ? "&" : "?"}${serializeArrayToQuery(
            deviceGroupIds,
            "deviceGroups",
            null
        )}`;
        queryFilter += `${queryFilter?.length ? "&" : "?"}${serializeArrayToQuery(deviceIds, "deviceIds", null)}`;

        let response = await fetch(`${path}/list/${locationId}${queryFilter}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items];
    } catch (ex) {
        console.error(ex);

        return [false, []];
    }
};

export const RemoveDevice = async (serviceId, deviceId) => {
    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${serviceId}/${deviceId}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return response.status === 200;
};

export const UpdateDevice = async (device) => {
    let id = device.id;
    let data = { ...device };
    delete data.id;

    const token = window.localStorage.getItem("token");
    let response = await fetch(`${path}/${id}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    return [response.status === 200, response.status];
};

export const DownloadTemplate = async (deviceTypeId) => {
    const url = `${massOperationsPath}/template/${deviceTypeId}`;

    const params = new URLSearchParams();
    const token = window.localStorage.getItem("token");
    params.append("authToken", `Bearer ${token}`);

    const path = urlWithQuery(url, params);

    try {
        DownloadByPath(path);
        return true;
    } catch (exception) {
        return false;
    }
};

export const DownloadReport = async (taskId) => {
    const url = `${massOperationsPath}/report/${taskId}`;

    const params = new URLSearchParams();
    const token = window.localStorage.getItem("token");
    params.append("authToken", `Bearer ${token}`);

    const path = urlWithQuery(url, params);

    try {
        DownloadByPath(path);
        return true;
    } catch (exception) {
        return false;
    }
};

export const ImportFile = async (file, deviceTypeId, serviceId) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("deviceTypeId", deviceTypeId);
    formData.append("serviceId", serviceId);

    const url = `${massOperationsPath}`;
    const token = window.localStorage.getItem("token");

    const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        return [false, response.status, null];
    }

    const result = await response.json();
    return [true, response.status, result];
};

export const GetTask = async (taskId) => {
    const url = `${massOperationsPath}/${taskId}`;
    const token = window.localStorage.getItem("token");

    const response = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status !== 200) {
        return [false, response.status, null];
    }

    return [true, response.status, await response.json()];
};

export const serializeArrayToQuery = function (obj, objName) {
    let str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(`${objName}=${encodeURIComponent(obj[p])}`);
        }
    }

    if (str.length === 1) {
        return str[0];
    }

    return str.join("&");
};

export function getConflictErrorMessage(messageCode) {
    switch (messageCode) {
        case "conflict-same-device-names":
            return "В одной локации должны быть устройства с разными именами";
        case "conflict-license-service-not-found":
            return "Не удалось найти сервис для проверки типа лицензии устройства";
        case "conflict-license-code-not-found":
            return "Не удалось получить лицензионный код для выбранного типа устройства";
        default:
            return "При создании устройства произошел конфликт";
    }
}

import React from "react";
import { LayoutSidebarBody, LayoutSidebarItem } from "../../layout/MainLayout";
import { Checkbox, Text } from "headpoint-react-components";
import { FilterItem } from "../components/Filters";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { saveScheduleState } from "../../app/reducers/scheduleReducer";
import { withCultureContext } from "../../contexts/cultureContext/CultureContext";

const TextWrapper = styled.div`
    margin-bottom: 10px;
`;

const Filters = ({ strings }) => {
    const dispatch = useDispatch();

    const status = useSelector((state) => state.schedule.status);

    return (
        <LayoutSidebarBody>
            <LayoutSidebarItem>
                <TextWrapper>
                    <Text>{strings("Статус")}</Text>
                </TextWrapper>
                <FilterItem>
                    <Checkbox
                        label={strings("Активно")}
                        checked={status.active}
                        onChange={() =>
                            dispatch(
                                saveScheduleState({
                                    status: { ...status, active: !status.active },
                                })
                            )
                        }
                    />
                </FilterItem>
                <FilterItem>
                    <Checkbox
                        label={strings("Приостановлено")}
                        checked={status.paused}
                        onChange={() =>
                            dispatch(
                                saveScheduleState({
                                    status: { ...status, paused: !status.paused },
                                })
                            )
                        }
                    />
                </FilterItem>
                <FilterItem>
                    <Checkbox
                        label={strings("Завершено")}
                        checked={status.completed}
                        onChange={() =>
                            dispatch(
                                saveScheduleState({
                                    status: { ...status, completed: !status.completed },
                                })
                            )
                        }
                    />
                </FilterItem>
            </LayoutSidebarItem>
        </LayoutSidebarBody>
    );
};

export default withCultureContext(Filters);

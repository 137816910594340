import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class DetailsTabMain extends React.Component {
    getUserRoleName = (roleId) => {
        const { availableRoles } = this.props;
        if (!availableRoles || !roleId) {
            return "";
        }

        return availableRoles.find((role) => role.id === roleId)?.name ?? "";
    };

    getUserGroupName = (groupId) => {
        const { usersGroups } = this.props;
        if (!usersGroups || !groupId) {
            return "";
        }

        return usersGroups.find((group) => group.id === groupId)?.name ?? "";
    };

    render() {
        const { selectedUser } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <DetailsBoxContent>
                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("ID пользователя")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser.id}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Фамилия")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser.properties?.surname ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Имя")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser.properties?.name ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Отчество")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser.properties?.patronymic ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Номер телефона")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser.properties?.phone ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Почта")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser.properties?.email ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Должность")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser?.properties?.title ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Роль")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {this.getUserRoleName(selectedUser?.roleId)}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Группа пользователей")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {this.getUserGroupName(selectedUser?.groupId)}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Имя пользователя")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser?.login}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Наследование")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedUser?.inheritsGroupProperties ? strings("Да") : strings("Нет")}
                                        </Text>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>{strings("Комментарий")}</TableCell>
                                    <TableCell>
                                        <Text scroll color="primary" variant="body-sm">
                                            {selectedUser?.properties?.comments ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DetailsBoxContent>
                )}
            </CultureContextConsumer>
        );
    }
}

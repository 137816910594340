import React from "react";
import { withSnackbar } from "notistack";
import { LayoutSidebarBody, LayoutSidebarItem } from "../../../layout/MainLayout";
import { Checkbox, Radio, TextField } from "headpoint-react-components";
import { PlanAccordionList } from "./PlanAccordionList";
import { FilterGroup, FilterGroupList, FilterWrapper } from "../../components/Filters";
import { permissionExists } from "../../../contexts";
import DeviceLocationFilter from "../../components/DeviceLocationFilter";
import { SearchPlans } from "../../../services/plans";
import { PLANS_DEVICE_LOCATIONS_FILTER } from "./plansTopics";
import { MapPlanEventsFilter } from "../../components/MapPlanFilter/MapPlanEventsFilter";
import { FilterItem } from "../../components/FiltersPartion";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import DropDownFilter from "../../components/DropDownFilter/DropDownFilter";
import GroupZoneFilter from "../../components/GroupZoneFilter/GroupZoneFilter";

class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plan: undefined,
            search: "",
            typeSearch: "",
            planList: [],
        };
    }

    handleCheckboxChange = (array, setter, e) => {
        let newArray = [...array];
        if (newArray.includes(e)) {
            newArray = newArray.filter((et) => et !== e);
        } else {
            newArray = [...newArray, e];
        }

        setter(newArray);
    };

    searchPlans = async (search) => {
        const [plansResult, plans] = await SearchPlans({ like: search, limit: 100 });
        if (!plansResult) {
            this.props.enqueueSnackbar("Ошибка получения планов", { variant: "error" });
            return;
        }

        this.setState({ search, planList: plans });
    };

    render() {
        const { search, planList, typeSearch } = this.state;
        const {
            sourcesTree,
            plansTree,
            openLocationPlan,
            selectedPlan,
            handlePlanSelect,
            setValue,
            devicesFilter,
            devicesVisibility,
            locationsFilter,
            setDeviceLocationFilter,
            setGroupZoneFilter,
            resetdeviceLocationFilter,
            eventsFilter,
            setEventsFilter,
            defaultEventTypes,
            eventTypesFilter,
            setEventsTypesFilter,
            intervalFilter,
            setEventsIntervalFilter,
            eventsFilters,
            handleOpen,
            handleUpdate,
            loadFilter,
            deleteFilter,
            selectedFilter,
            dropFilter,
            disabled,
            userInfo,
            zonesFilter,
            groupsFilter,
            resetGroupZoneFilter,
        } = this.props;

        const plans = search ? planList : plansTree;

        const availableEventTypes = defaultEventTypes?.filter((t) => permissionExists(userInfo, t.value)) ?? [];

        const eventTypes = typeSearch
            ? availableEventTypes?.filter((t) => t.label.toLowerCase().includes(typeSearch.toLowerCase()))
            : availableEventTypes;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <LayoutSidebarBody>
                            {eventsFilter && setEventsFilter && (
                                <LayoutSidebarItem>
                                    <MapPlanEventsFilter
                                        eventsFilter={eventsFilter}
                                        setEventsFilter={setEventsFilter}
                                    />
                                </LayoutSidebarItem>
                            )}
                            <FilterWrapper>
                                <FilterGroup
                                    title={strings("План")}
                                    hasValue={!!selectedPlan}
                                    isMuted={!sourcesTree?.length}
                                    onClear={() => {
                                        setValue("selectedPlan", undefined);
                                    }}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.searchPlans(e.target?.value)}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={search}
                                    />
                                    <FilterGroupList>
                                        <PlanAccordionList
                                            list={plans}
                                            onSelect={(plan) => handlePlanSelect(plan)}
                                            selected={selectedPlan}
                                            openLocation={openLocationPlan}
                                        ></PlanAccordionList>
                                    </FilterGroupList>
                                </FilterGroup>
                                {permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
                                    <DeviceLocationFilter
                                        key={selectedPlan?.id + "devices"}
                                        locationsFilter={locationsFilter}
                                        devicesFilter={devicesFilter}
                                        devicesVisibility={devicesVisibility}
                                        setDeviceLocationFilter={setDeviceLocationFilter}
                                        resetFilter={resetdeviceLocationFilter}
                                        cmdChannelTopic={PLANS_DEVICE_LOCATIONS_FILTER}
                                    />
                                ) : (
                                    <div />
                                )}
                                {permissionExists(userInfo, ["service.api.devices"]) ? (
                                    <GroupZoneFilter
                                        key={selectedPlan?.id + "zones"}
                                        zonesFilter={zonesFilter}
                                        groupsFilter={groupsFilter}
                                        setGroupZoneFilter={setGroupZoneFilter}
                                        resetFilter={resetGroupZoneFilter}
                                    />
                                ) : (
                                    <div />
                                )}
                                <FilterGroup
                                    title={strings("Тип события")}
                                    hasValue={!!eventTypesFilter?.length}
                                    onClear={() => {
                                        this.setState({ typeSearch: "" });
                                        setEventsTypesFilter([]);
                                    }}
                                    isMuted={!defaultEventTypes.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ typeSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={typeSearch}
                                    />

                                    <FilterGroupList>
                                        {eventTypes.map((et) => (
                                            <FilterItem key={nanoid()}>
                                                <Checkbox
                                                    label={strings(et.label)}
                                                    checked={eventTypesFilter.includes(et.value)}
                                                    onChange={() =>
                                                        this.handleCheckboxChange(
                                                            eventTypesFilter,
                                                            (arr) => setEventsTypesFilter(arr),
                                                            et.value
                                                        )
                                                    }
                                                />
                                            </FilterItem>
                                        ))}
                                    </FilterGroupList>
                                </FilterGroup>
                                <FilterGroup title={strings("Интервал")}>
                                    <FilterGroupList>
                                        <FilterItem>
                                            <Radio
                                                label={strings("Все события")}
                                                checked={intervalFilter === "all"}
                                                onChange={() => setEventsIntervalFilter("all")}
                                            />
                                        </FilterItem>
                                        <FilterItem>
                                            <Radio
                                                label={strings("Последние события")}
                                                checked={intervalFilter === "last"}
                                                onChange={() => setEventsIntervalFilter("last")}
                                            />
                                        </FilterItem>
                                        <FilterItem>
                                            <Radio
                                                label={strings("Новые события")}
                                                checked={intervalFilter === "new"}
                                                onChange={() => setEventsIntervalFilter("new")}
                                            />
                                        </FilterItem>
                                    </FilterGroupList>
                                </FilterGroup>
                            </FilterWrapper>
                        </LayoutSidebarBody>
                        <DropDownFilter
                            selectedFilter={selectedFilter}
                            filters={eventsFilters}
                            createFilter={handleOpen}
                            updateFilter={handleUpdate}
                            selectFilter={loadFilter}
                            deleteFilter={deleteFilter}
                            dropFilter={dropFilter}
                            disabled={disabled}
                        />
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(Filters));

import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import styled from "styled-components";
import { TextField, Button, Row, Text } from "headpoint-react-components";
import uuid from "react-uuid";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

export class ModalFormArrayStringProperty extends React.Component {
    handleAddArrayParameters = () => {
        const { itemId, values, handleAddParameters, name } = this.props;
        let params = values.functions.find((x) => x.id === itemId).parameters[name];
        const nextParameters = [...params, { id: uuid(), value: "" }];

        handleAddParameters(itemId, name, nextParameters);
    };

    handleRemoveItem = (id) => {
        const { itemId, values, handleAddParameters, name } = this.props;
        let params = values.functions.find((x) => x.id === itemId).parameters[name];
        const nextParameters = [...params];

        const targetIndex = nextParameters.findIndex((item) => item.id === id);

        nextParameters.splice(targetIndex, 1);

        handleAddParameters(itemId, name, nextParameters);
    };

    handleChangeParameters = (key, value) => {
        const { itemId, values, handleAddParameters, name } = this.props;
        let params = values.functions.find((x) => x.id === itemId).parameters[name];
        const nextParameters = [...params];

        const targetIndex = nextParameters.findIndex((item) => item.id === key);

        nextParameters[targetIndex] = {
            ...nextParameters[targetIndex],
            value: value,
        };

        handleAddParameters(itemId, name, nextParameters);
    };

    render() {
        const { itemId, handleAddParameters, description, name, type, values } = this.props;
        let params = values.functions.find((x) => x.id === itemId).parameters;

        if (Object.keys(params).length === 0) {
            handleAddParameters(itemId, name, [{ id: uuid(), value: "" }]);
        }

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <ModalFormItem>
                        <Text color="secondary" variant="description">
                            {description.en}
                        </Text>
                        {params[name].map((item, i) => (
                            <Row key={item.id} justify="between" marginTop={4}>
                                <InputItem inLine>
                                    {params[name].length > 1 && (
                                        <InputItemRemoveBtn
                                            type="button"
                                            onClick={() => this.handleRemoveItem(item.id)}
                                        />
                                    )}

                                    <TextField
                                        placeholder={strings("Введите текст")}
                                        name={name}
                                        type={type}
                                        value={item.value ?? ""}
                                        onChange={(e) => this.handleChangeParameters(item.id, e.target.value)}
                                        colorVariant="light"
                                        fullWidth
                                    />
                                </InputItem>

                                {i === params[name].length - 1 && (
                                    <Button
                                        elSize="md"
                                        icon="plus"
                                        onClick={this.handleAddArrayParameters}
                                        type="button"
                                        variant="secondary"
                                    />
                                )}
                            </Row>
                        ))}
                    </ModalFormItem>
                )}
            </CultureContextConsumer>
        );
    }
}

const InputItem = styled.div`
    position: relative;
    display: inline-block;
    margin: ${({ inLine }) => (inLine ? "0" : "8px")};
`;

const InputItemRemoveBtn = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: none;
    text-align: center;
    cursor: pointer;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 26px;
    right: -4px;
    z-index: 2;

    &:after {
        content: "";
        width: 7px;
        height: 1px;
        background-color: ${({ theme }) => theme.colors.primary2};
        position: absolute;
        top: 6px;
        left: 3px;
        z-index: 1;
    }
`;

import React from "react";
import { Icon, Space, Table, TableBody, TableCell, TableRow, Text } from "headpoint-react-components";
import { formatGroupDeviceTypes, formatGroupTags } from "../../../../../utilites/TextUtils";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { GeneralContextConsumer, getDeviceTypes } from "../../../../../contexts";
import { GetTags } from "../../../../../services/tags";
import { withSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsTabTagsAndTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceTags: [],
        };
    }

    componentDidMount = async () => {
        const { enqueueSnackbar, strings } = this.props;
        const [tagStatus, tags] = await GetTags();
        if (!tagStatus) {
            enqueueSnackbar(strings("Ошибка получения тегов оборудования"), { variant: "error" });
            return;
        }

        this.setState({
            deviceTags: tags ?? [],
        });
    };

    render() {
        const { selected, strings } = this.props;
        const { deviceTags } = this.state;

        const types = selected?.properties?.types ?? [];
        const typesTitle = `${strings("В группу включено оборудование")} ${types.length} ${strings(
            formatGroupDeviceTypes(types.length)
        )}`;

        const tags = selected?.properties?.tags ?? [];
        const tagsTitle = `${strings("В группу включено оборудование с хотя бы одним из")} ${tags.length} ${strings(
            formatGroupTags(tags.length)
        )}`;

        return (
            <DetailsBoxContent>
                {!!types.length && (
                    <Table isFixed noPadding>
                        <TableBody>
                            <TableRow>
                                <TableCell noBorder>
                                    <Text color="primary" variant="body-sm">
                                        {strings(typesTitle)}
                                    </Text>
                                </TableCell>
                            </TableRow>
                            <GeneralContextConsumer>
                                {(generalInfo) => {
                                    const deviceTypes = getDeviceTypes(generalInfo) ?? [];
                                    return deviceTypes
                                        .filter((type) => types.some((id) => type.value === id))
                                        .map((type) => (
                                            <TableRow key={type.value ?? nanoid()}>
                                                <TableCell indent={15}>
                                                    <Text color="primary" variant="body-sm">
                                                        <Icon icon="tag" size={16} />
                                                    </Text>
                                                    <Space />
                                                    <Text color="primary" variant="body-sm">
                                                        {strings(type?.label) ?? ""}
                                                    </Text>
                                                </TableCell>
                                            </TableRow>
                                        ));
                                }}
                            </GeneralContextConsumer>
                        </TableBody>
                    </Table>
                )}
                {!!tags.length && (
                    <Table isFixed noPadding>
                        <TableBody>
                            <TableRow>
                                <TableCell noBorder>
                                    <Text color="primary" variant="body-sm">
                                        {strings(tagsTitle)}
                                    </Text>
                                </TableCell>
                            </TableRow>
                            {deviceTags
                                .filter((tag) => tags.some((id) => tag.id === id))
                                .map((tag) => (
                                    <TableRow key={tag.id ?? nanoid()}>
                                        <TableCell indent={15}>
                                            <Text color="primary" variant="body-sm">
                                                <Icon icon="tag" size={16} />
                                            </Text>
                                            <Space />
                                            <Text color="primary" variant="body-sm">
                                                {strings(tag?.name) ?? ""}
                                            </Text>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                )}
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabTagsAndTypes));

import React from "react";
import { WebGLPlayer } from "./webgl";

const WsClientOptions = {
    // required on open
    url: null,
    stream: null,
    rtsp: null,

    decode_async: false,
    decode_queue_size: 0,
    decode_thread_count: 0,
    decode_thread_type: 0,

    // need pthreads support
    //  COOP, COEP headers are correctly set
    //  https://emscripten.org/docs/porting/pthreads.html
    // decode_async: false,  // not works well as clone frame not supported
    // decode_queue_size: 10,
    // decode_thread_count: 4,
    // // 1: FF_THREAD_FRAME, 2: FF_THREAD_SLICE
    // decode_thread_type: 1,

    player: null,

    onopen: null,
    onmessage: null,
    onclose: null,
    onerror: null,

    ondata: null,
};

export class WsClient extends React.Component {
    constructor(props) {
        super(props);
        this.coreModule = props.Module;
        this.options = { ...WsClientOptions, ...props.options };
        this.decoder = null;
        this.ws = null;
    }

    static getUrl = (externalId) => {
        const wsProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
        const url = new URL(window.location.href);
        return `${wsProtocol}${url.hostname}:${url.port}/balancer/wasm-${externalId}/`;
    };

    createOpenGLPlayer() {
        return new this.coreModule.OpenGLPlayer();
    }

    isOpen() {
        return this.ws != null;
    }

    open(options) {
        if (this.ws != null) {
            console.log("ws open error: already opened");
            return;
        }
        this.options = { ...this.options, ...options };
        console.log("ws open options:");
        console.log(this.options);
        if (this.options.url == null) {
            console.log("ws open error: url is null");
            return;
        }
        const ws = new WebSocket(this.options.url);
        ws.binaryType = "arraybuffer";
        ws.onopen = (e) => this.onopen(e, this.options.rtsp, this.options.externalId);
        ws.onmessage = (e) => this.onmessage(e);
        ws.onclose = (e) => this.onclose(e);
        ws.onerror = (e) => this.onerror(e);
        this.ws = ws;
    }

    close() {
        if (this.ws != null) {
            console.log("ws close");
            this.ws.close();
            this.ws = null;
            if (this.decoder) {
                this.decoder.delete();
            }
            this.decoder = null;
            this.options.dbg && this.coreModule.DoLeakCheck && this.coreModule.DoLeakCheck();
        }
    }

    onopen(e, rtspUrl, externalId) {
        const token = window.localStorage.getItem("token");
        const request = {
            uri: rtspUrl,
            token: token,
            externalId: externalId,
        };
        const cameraRequest = JSON.stringify(request);

        console.log(`ws open: ${this.options.url}`);
        this.options.onopen && this.options.onopen(e);
        this.ws.send(cameraRequest);
    }

    onmessage(e) {
        if (!this.decoder) {
            let stream = JSON.parse(e.data);
            this.options.stream = stream;
            this.decoder = new this.coreModule.Decoder();
            this.decoder.open(
                JSON.stringify(this.options.stream),
                this.options.decode_queue_size,
                this.options.decode_thread_count,
                this.options.decode_thread_type,
                this.ondecode.bind(this)
            );

            const player = this.options.player;
            if (player) {
                if (player instanceof WebGLPlayer) {
                    player.canvas.width = this.options.stream.video.codecpar.width;
                    player.canvas.height = this.options.stream.video.codecpar.height;
                }
            }

            return;
        }

        let data = new Uint8Array(e.data);
        if (this.decoder) {
            const buf = this.coreModule._malloc(data.length);
            try {
                this.coreModule.HEAPU8.set(data, buf);
                if (this.options.decode_async) {
                    this.decoder.decodeAsync(buf, data.length);
                } else {
                    // process the frame in #ondecode callback
                    const frame = this.decoder.decode(buf, data.length);
                    // release the return frame reference
                    if (frame != null) frame.delete();
                }
            } finally {
                this.coreModule._free(buf);
            }
        } else {
            this.options.ondata && this.options.ondata(data);
        }
    }

    ondecode(frame) {
        if (frame != null) {
            //console.log(`ws frame size=${frame.width}x${frame.height}, pts=${frame.pts}`);
            if (frame.captureTime && frame.captureTime > 0) {
                this.props.handleWebsocketTimestamp(frame.captureTime);
            }

            const player = this.options.player;
            if (player) {
                if (player instanceof WebGLPlayer) {
                    frame.bytes = frame.getBytes();
                    //frame.bytes = new Uint8Array(Module.HEAPU8.buffer, frame.data, frame.size);
                }
                player.render(frame);
            }
            this.options.ondata && this.options.ondata(frame);
            frame.delete();
        } else {
            console.log("ws frame is null: decode error or need new packets");
        }
    }

    onclose(e) {
        console.log(`ws close: ${this.options.url}`);
        this.options.onclose && this.options.onclose(e);
    }

    onerror(e) {
        console.log(`ws error: ${this.options.url}, ${e}`);
        this.options.onerror && this.options.onerror(e);
    }
}

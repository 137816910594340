import { useDispatch, useSelector } from "react-redux";
import {
    deleteControlZone,
    deleteControlZonesGroup,
    saveControlZonesState,
} from "../../../../../app/reducers/controlZonesReducer";
import { DeleteModal } from "../../DeleteModal";
import React from "react";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

const ControlZonesDeleteWizard = ({ visible, strings, enqueueSnackbar }) => {
    const dispatch = useDispatch();
    const { wizardType, selectedZone, selectedGroup } = useSelector((state) => state.controlZones);

    const selectedItem = wizardType === "group" ? selectedGroup : selectedZone;

    const text =
        wizardType === "group"
            ? `${strings("Вы хотите удалить группу")} "${selectedItem?.name}". ${strings(
                  "Удалённую группу нельзя восстановить. Продолжить?"
              )}`
            : `${strings("Вы хотите удалить зону контроля")} "${selectedItem?.name}". ${strings(
                  "Удалённую зону контроля нельзя восстановить. Продолжить?"
              )}`;

    const removeHandler =
        wizardType === "group"
            ? () => dispatch(deleteControlZonesGroup({ group: selectedItem, enqueueSnackbar, strings }))
            : () => dispatch(deleteControlZone({ zone: selectedItem, enqueueSnackbar, strings }));

    return (
        <DeleteModal
            visible={visible}
            CloseHandler={() => dispatch(saveControlZonesState({ showDeleteWizard: false }))}
            RemoveHandler={() => removeHandler()}
            text={text}
        />
    );
};

export default withCultureContext(withSnackbar(ControlZonesDeleteWizard));

import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import {
    Text,
    Row,
    Table,
    TableBody,
    TableRow,
    ExpandableSearch,
    DeviceIcon,
    Space,
    TableCell,
} from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { GetLocationList, GetLocationNames } from "../../../../../services/locations";
import { GetDeviceInfo } from "../../../../../services/devices";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../../CommonTablePagination";
import { EventsLayoutHeaderTitle } from "../../../../../layout/EventsLayout";
import { GeneralContextConsumer, getDeviceTypes } from "../../../../../contexts";
import { formatAvailabilityControlPrefix, formatGroupDeviceCount } from "../../../../../utilites/TextUtils";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import DeviceTooltip from "../../../../components/DeviceTooltip/DeviceTooltip";

class DetailsTabDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],

            search: "",
            pageData: { start: 0, end: ITEMS_ON_PAGE },
        };
    }
    async componentDidMount() {
        const { enqueueSnackbar, selectedRule, strings } = this.props;

        const [getDevicesStatus, newDevices] = await GetDeviceInfo(selectedRule.properties.deviceIds);
        if (!getDevicesStatus) {
            enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const deviceLocationsIds = newDevices.map((d) => d.locationId);

        let [locationsStatus, locations] = await GetLocationList({ ids: deviceLocationsIds });
        if (!locationsStatus) {
            enqueueSnackbar(strings("Ошибка при получении локаций"), { variant: "error" });
            return;
        }

        const parentIds = new Set();

        locations.forEach((device) => {
            const rootLocationId = device.parentIds[1];
            if (rootLocationId) {
                parentIds.add(rootLocationId);
            }
        });

        const [getNamesStatus, parentLocationIdNamePairs] = await GetLocationNames(parentIds);
        if (!getNamesStatus) {
            enqueueSnackbar(strings("Ошибка при получении названий локаций"), { variant: "error" });
            return;
        }

        const devicesWithLocations = newDevices.map((device) => {
            const deviceLocation = locations.find((loc) => loc.id === device.locationId);

            return {
                ...device,
                location: deviceLocation,
                fullLocationName: this.getFullLocationName(deviceLocation, parentLocationIdNamePairs),
            };
        });

        this.setState({
            devices: devicesWithLocations,
            search: "",
        });
    }

    getFullLocationName = (location, parentIdsMap) => {
        const rootLocationId = location.parentIds[1] ?? undefined;

        if (rootLocationId) {
            const parentLength = location.parentIds.length;

            if (parentLength === 2) {
                return `${parentIdsMap.get(rootLocationId)} / ${location.name}`;
            } else if (parentLength >= 3) {
                return `${parentIdsMap.get(rootLocationId)} / ... / ${location.name}`;
            }
        }

        return location.name;
    };

    render() {
        const { devices, search, pageData } = this.state;
        const { selectedRule, strings } = this.props;

        const searchFilterDevices = devices?.filter((d) => d?.name?.toLowerCase().includes(search.toLowerCase()));

        const count = selectedRule?.properties?.deviceIds.length ?? 0;

        return (
            <DetailsBoxContent
                style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}
            >
                <div>
                    <Row marginTop={24} marginBottom={24} justify="between" align="middle">
                        <EventsLayoutHeaderTitle>
                            <Text color="primary" variant="body">
                                {`${strings("Правилу")} ${strings(
                                    formatAvailabilityControlPrefix(count)
                                )} ${count} ${strings(formatGroupDeviceCount(count))}`}
                            </Text>
                            <ExpandableSearch
                                value={search}
                                onChange={(e) => this.setState({ search: e.target.value })}
                                onSubmit={() => {}}
                                placeholder={strings("Найти")}
                                colorVariant="light"
                                inHeader
                            />
                        </EventsLayoutHeaderTitle>
                    </Row>

                    <Table noPadding>
                        <TableBody>
                            {searchFilterDevices?.slice(pageData.start, pageData.end).map((device, _) => (
                                <TableRow key={device.id}>
                                    <TableCell>
                                        <DeviceTooltip device={device}>
                                            <Text color={device?.statusCode} variant="body-sm">
                                                <GeneralContextConsumer>
                                                    {(generalInfo) => {
                                                        const deviceTypes = getDeviceTypes(generalInfo);
                                                        const icon = deviceTypes.find(
                                                            (dt) => dt.value === device.typeId
                                                        )?.icon?.type;

                                                        return (
                                                            <>
                                                                <DeviceIcon icon={icon} size={16} />
                                                            </>
                                                        );
                                                    }}
                                                </GeneralContextConsumer>
                                            </Text>
                                            <Space />
                                            <Space />
                                            <Text variant="body-sm" color="primary">
                                                {device?.name}
                                            </Text>
                                        </DeviceTooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Text variant="body-sm" color="primary">
                                            {device?.location?.name}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <CommonTablePagination
                    tableData={devices}
                    setPaging={(pageData) => this.setState({ pageData })}
                    searchMode={search !== ""}
                />
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabDevices));

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    CreateControlZone,
    CreateControlZonesGroup,
    DeleteControlZone,
    DeleteControlZonesGroup,
    EditControlZone,
    EditControlZonesGroup,
    GetControlZones,
    GetControlZonesGroups,
    SearchControlZonesList,
} from "../../services/controlZones";
import {
    wizardGroupDefault,
    wizardZoneDefault,
} from "../../features/settings/components/controlZonesSettings/constants";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../features/components/CreateUpdateWizard/constants";

export const CONTROL_ZONE_ITEMS_ON_PAGE = 10;

const initialState = {
    groups: [],
    searchList: null,

    countOnPage: CONTROL_ZONE_ITEMS_ON_PAGE,
    pageNumber: 1,
    pagesCount: 0,
    offset: 0,

    search: "",
    values: null,
    steps: wizardGroupDefault,
    step: 1,
    activeStep: 1,
    isLoading: false,
    wizardMode: null,
    wizardType: null,
    selectedGroup: null,
    selectedZone: null,
    showDeleteWizard: false,
};

const initialValues = {
    name: "",
    comment: "",
};

export const controlZonesSlice = createSlice({
    initialState: initialState,
    name: "controlZones",
    reducers: {
        saveControlZonesState: (state, { payload }) => {
            for (const key in payload) {
                state[key] = payload[key];
            }
        },
        closeGroup: (state, action) => {
            const group = state.groups.find((g) => g.id === action.payload.groupId);
            delete group.children;
            group.isClosed = true;
        },
        showCreateGroupWizard: (state) => {
            state.wizardMode = WIZARD_CREATE_MODE;
            state.wizardType = "group";
            state.steps = wizardGroupDefault;
            state.showCreateWizard = true;
            state.values = initialValues;
        },
        showCreateZoneWizard: (state) => {
            state.wizardMode = WIZARD_CREATE_MODE;
            state.wizardType = "zone";
            state.steps = wizardZoneDefault;
            state.showCreateWizard = true;
            state.values = initialValues;
        },
        showEditZoneWizard: (state, action) => {
            state.wizardMode = WIZARD_UPDATE_MODE;
            state.wizardType = "zone";
            state.steps = wizardZoneDefault;
            state.showCreateWizard = true;
            state.values = action.payload;
        },
        showEditGroupWizard: (state, action) => {
            state.wizardMode = WIZARD_UPDATE_MODE;
            state.wizardType = "group";
            state.steps = wizardGroupDefault;
            state.showCreateWizard = true;
            state.values = action.payload;
        },
        closeWizard: (state) => {
            state.showCreateWizard = false;
            state.values = initialValues;
            state.activeStep = 1;
            state.step = 1;
            state.steps = null;
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchControlZonesGroups.fulfilled, (state, action) => {
                const groupsCount = action.payload.count;
                const pagesCount = Math.ceil(groupsCount / state.countOnPage);
                const offset = Math.min(state.pageNumber > 3 ? state.pageNumber - 3 : 0, groupsCount - 5);

                state.groups = action.payload.groups;
                state.pagesCount = pagesCount;
                state.offset = offset < 0 ? 0 : offset;
            })
            .addCase(fetchControlZones.fulfilled, (state, action) => {
                if (action.payload.zones?.length > 0 && state?.groups) {
                    const group = state.groups.find((g) => g.id === action.payload.zones[0].groupId);
                    group.children = action.payload.zones;
                }
            })
            .addCase(createControlZoneGroup.fulfilled, (state, action) => {
                state.showCreateWizard = false;
                state.groups = [...state.groups, action.payload];
                state.values = initialValues;
                state.activeStep = 1;
                state.step = 1;
                state.isLoading = false;
            })
            .addCase(createControlZone.fulfilled, (state, action) => {
                state.showCreateWizard = false;
                let group = state?.groups.find((g) => g.id === action.payload.groupId);
                if (group.children) {
                    group.children = [...group?.children, action.payload];
                }
                state.values = initialValues;
                state.activeStep = 1;
                state.step = 1;
                state.isLoading = false;
            })
            .addCase(editControlZoneGroup.fulfilled, (state, action) => {
                state.showCreateWizard = false;
                const editedGroup = action.payload;
                let group = state?.groups?.find((g) => g.id === editedGroup.id);

                if (group) {
                    group.name = editedGroup.name;
                    group.comment = editedGroup.comment;
                    state.selectedGroup = group;
                }

                state.values = initialValues;
                state.activeStep = 1;
                state.step = 1;
                state.isLoading = false;
            })
            .addCase(editControlZone.fulfilled, (state, action) => {
                state.showCreateWizard = false;
                if (state?.selectedZone?.groupId) {
                    let group = state.groups.find((g) => g.id === state.selectedZone.groupId);
                    let zone = group.children?.find((z) => z.id === state.selectedZone.id);
                    let editedZone = action.payload;

                    zone.name = editedZone.name;
                    zone.comment = editedZone.comment;
                    zone.deviceIds = editedZone.deviceIds;
                    zone.devicesCount = editedZone.deviceIds?.length ?? 0;

                    state.selectedZone = zone;
                }

                state.values = initialValues;
                state.activeStep = 1;
                state.step = 1;
                state.isLoading = false;
            })
            .addCase(deleteControlZone.fulfilled, (state) => {
                if (state?.selectedZone?.groupId) {
                    let group = state.groups.find((g) => g.id === state.selectedZone.groupId);
                    group.children = [...group.children?.filter((z) => z.id !== state.selectedZone.id)];
                }
                state.wizardType = null;
                state.showDeleteWizard = false;
                state.selectedZone = null;
            })
            .addCase(deleteControlZonesGroup.fulfilled, (state) => {
                if (state?.selectedGroup?.id) {
                    state.groups = [...state?.groups.filter((g) => g.id !== state?.selectedGroup?.id)];
                }
                state.wizardType = null;
                state.showDeleteWizard = false;
                state.selectedGroup = null;
            })
            .addCase(searchControlZones.fulfilled, (state, action) => {
                const zonesCount = action.payload.count;
                const pagesCount = Math.ceil(zonesCount / state.countOnPage);
                const offset = Math.min(state.pageNumber > 3 ? state.pageNumber - 3 : 0, zonesCount - 5);

                state.searchList = action.payload.zones;
                state.pagesCount = pagesCount;
                state.offset = offset < 0 ? 0 : offset;
            });
    },
});

export const createControlZoneGroup = createAsyncThunk(
    "controlZones/createZoneGroup",
    async ({ group, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let [statusCode, groupResponse] = await CreateControlZonesGroup(group);

        switch (statusCode) {
            case 200:
                enqueueSnackbar(strings("Группа создана"), { variant: "success" });
                group.id = groupResponse.message;
                return group;
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для создания группы зон контроля"), { variant: "error" });
                return rejectWithValue();
            case 409: // conflict
                enqueueSnackbar(strings("Группы зон контроля должны иметь уникальные имена"), {
                    variant: "warning",
                });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось создать группу зон контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const editControlZoneGroup = createAsyncThunk(
    "controlZones/editControlZoneGroup",
    async ({ group, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let [statusCode, groupResponse] = await EditControlZonesGroup(group);

        switch (statusCode) {
            case 200:
                enqueueSnackbar(strings("Зона изменена"), { variant: "success" });
                group.id = groupResponse.message;
                return group;
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для создания группы зон контроля"), { variant: "error" });
                return rejectWithValue();
            case 409: // conflict
                enqueueSnackbar(strings("Группы зон контроля должны иметь уникальные имена"), {
                    variant: "warning",
                });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось создать группу зон контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const createControlZone = createAsyncThunk(
    "controlZones/createControlZone",
    async ({ zone, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let [statusCode, response] = await CreateControlZone(zone);

        switch (statusCode) {
            case 200:
                enqueueSnackbar(strings("Зона создана"), { variant: "success" });
                zone.id = response.message;
                zone.devicesCount = zone.deviceIds?.length ?? 0;
                return zone;
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для создания зоны контроля"), { variant: "error" });
                return rejectWithValue();
            case 409: // conflict
                enqueueSnackbar(strings("Зоны контроля должны иметь уникальные имена"), {
                    variant: "warning",
                });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось создать зону контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const editControlZone = createAsyncThunk(
    "controlZones/editControlZone",
    async ({ zone, enqueueSnackbar, strings }, { rejectWithValue }) => {
        let [statusCode] = await EditControlZone(zone);

        switch (statusCode) {
            case 200:
                enqueueSnackbar(strings("Зона изменена"), { variant: "success" });
                return zone;
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для создания зоны контроля"), { variant: "error" });
                return rejectWithValue();
            case 409: // conflict
                enqueueSnackbar(strings("Зоны контроля должны иметь уникальные имена"), {
                    variant: "warning",
                });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось создать зону контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const fetchControlZones = createAsyncThunk(
    "controlZones/fetchZones",
    async ({ groupId, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [getStatus, zones, count] = await GetControlZones(groupId);

        switch (getStatus) {
            case 200:
                return { zones, count };
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для получения зон контроля"), { variant: "error" });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось получить зоны контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const fetchControlZonesGroups = createAsyncThunk(
    "controlZones/fetchGroups",
    async ({ filter, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [getStatus, groups, count] = await GetControlZonesGroups(filter);

        switch (getStatus) {
            case 200:
                return { groups, count };
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для получения зон контроля"), { variant: "error" });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings('Не удалось получить группы зон контроля"'), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const deleteControlZonesGroup = createAsyncThunk(
    "controlZones/deleteControlZonesGroup",
    async ({ group, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const deleteStatus = await DeleteControlZonesGroup(group);

        switch (deleteStatus) {
            case 200:
                enqueueSnackbar(strings("Группа успешно удалена"), { variant: "success" });
                return deleteStatus;
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для удаления группы зоны контроля"), { variant: "error" });
                return rejectWithValue();
            case 409: // conflict
                enqueueSnackbar(strings("Нельзя удалить группу с зонами"), { variant: "error" });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось удалить группу зон контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const deleteControlZone = createAsyncThunk(
    "controlZones/deleteControlZone",
    async ({ zone, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const deleteStatus = await DeleteControlZone(zone);

        switch (deleteStatus) {
            case 200:
                enqueueSnackbar(strings("Зона успешно удалена"), { variant: "success" });
                return deleteStatus;
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для удаления зоны контроля"), { variant: "error" });
                return rejectWithValue();
            case 409: // conflict
                enqueueSnackbar(strings("Нельзя удалить зону с устройствами"), { variant: "error" });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось удалить зону контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const searchControlZones = createAsyncThunk(
    "controlZones/searchControlZones",
    async ({ filter, enqueueSnackbar, strings }, { rejectWithValue }) => {
        const [searchStatus, zones, count] = await SearchControlZonesList(filter);

        switch (searchStatus) {
            case 200:
                enqueueSnackbar(strings("Группа успешно удалена"), { variant: "success" });
                return { zones, count };
            case 403: // forbidden
                enqueueSnackbar(strings("Не хватает прав для получения зон контроля"), { variant: "error" });
                return rejectWithValue();
            default:
                enqueueSnackbar(strings("Не удалось выполнить поиск по зонам контроля"), { variant: "error" });
                return rejectWithValue();
        }
    }
);

export const {
    saveControlZonesState,
    closeGroup,
    showCreateGroupWizard,
    showCreateZoneWizard,
    closeWizard,
    showEditZoneWizard,
    showEditGroupWizard,
} = controlZonesSlice.actions;

export default controlZonesSlice.reducer;

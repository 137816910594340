import React from "react";
import { DetailsTabMain } from "./DetailsTabMain";
import DetailsTabDevices from "./DetailsTabDevices";
import { Button, DropdownDivider, DropdownItem, Tab, TabPanel, Text, Dropdown } from "headpoint-react-components";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
    DetailsBoxTitle
} from "../../../../components/DetailsBox";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";
import { permissionExists } from "../../../../../contexts";
import styled from "styled-components";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

class RuleDetails extends React.Component {
    render() {
        const { userInfo, selectedRule, closeHandler, detailsTab, setDetailsTab, deleteHandler, updateHandler } =
            this.props;
        const uniqueKey = `${selectedRule?.id}/${selectedRule?.properties?.deviceIds?.join("/")}`;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <RulesDetailsWrapper>
                                <DetailsBoxHeader sticky>
                                    <DetailsBoxTitle>
                                        <Text color="primary" variant="title" component="h2">
                                            {selectedRule?.name}
                                        </Text>
                                    </DetailsBoxTitle>

                                    <DetailsBoxHeaderActions>
                                        {permissionExists(userInfo, "devices.availability.lifeCycle") ? (
                                            <Dropdown
                                                closeOnClickInside
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                <DropdownItem onClick={() => updateHandler(selectedRule)} as="button">
                                                    {strings("Редактировать")}
                                                </DropdownItem>
                                                <DropdownDivider />
                                                <DropdownItem
                                                    onClick={() => deleteHandler(selectedRule)}
                                                    as="button"
                                                    colorVariant="danger"
                                                >
                                                    {strings("Удалить")}
                                                </DropdownItem>
                                            </Dropdown>
                                        ) : (
                                            <div />
                                        )}
                                        <Button icon="close" variant="secondary" onClick={() => closeHandler()} />
                                    </DetailsBoxHeaderActions>
                                </DetailsBoxHeader>
                                <DetailsBoxTabs style={{ flex: "none" }}>
                                    <TabPanel>
                                        <Tab
                                            label={strings("Общее")}
                                            isActive={detailsTab === "main"}
                                            onClick={() => setDetailsTab("main")}
                                        />
                                        <Tab
                                            label={strings("Оборудование")}
                                            isActive={detailsTab === "devices"}
                                            onClick={() => setDetailsTab("devices")}
                                        />
                                    </TabPanel>
                                </DetailsBoxTabs>
                                {detailsTab === "main" && <DetailsTabMain selectedRule={selectedRule} />}
                                {detailsTab === "devices" && (
                                    <DetailsTabDevices key={uniqueKey} selectedRule={selectedRule} />
                                )}
                            </RulesDetailsWrapper>
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

export const RulesDetailsWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(RuleDetails));

import React from "react";
import styled from "styled-components";
import { Modal, Button, ModalBody, Row } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import { GeneralContextConsumer } from "../../../../contexts";
import { ModalFormItem } from "../../../components/CreateUpdateWizard/Styled";

const ModalStepWrapper = styled.div`
    flex: 1;
`;

const ModalButtonsContainer = styled.div`
    position: absolute;
    float: right;
    display: flex;
    align-items: center;
`;

const ModalButton = styled.div`
    display: flex;
    margin: 5px;
`;

const ModalFooter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 96px;
    border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 24px 24px;
`;

const ModalSection = styled.div`
    width: 100%;
    padding: 24px 24px;

    & + & {
        border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    }
`;

const TextWrapper = styled.div`
    overflow-wrap: anywhere;
`;

const Text = styled.span`
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    line-height: 20px;

    font-weight: normal;
    padding: 0;
    margin: 0;
    white-space: normal;
`;

export class UpdateDesktopModal extends React.Component {
    render() {
        if (!this.props.visible) {
            return null;
        }

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => (
                            <>
                                <Modal size="sm" closeHandler={this.props.CloseHandler}>
                                    <ModalStepWrapper>
                                        <ModalBody>
                                            <ModalSection>
                                                <ModalFormItem>
                                                    <Row justify="left" align="bottom">
                                                        <TextWrapper>
                                                            <Text variant="body" color="primary" align={"left"}>
                                                                {`${strings(
                                                                    "Сохранить изменения для рабочего стола"
                                                                )} «${this.props.desktop.name}»?`}
                                                            </Text>
                                                        </TextWrapper>
                                                    </Row>
                                                </ModalFormItem>
                                            </ModalSection>
                                        </ModalBody>
                                        <ModalFooter>
                                            <ModalButtonsContainer>
                                                <ModalButton>
                                                    <Button
                                                        type="button"
                                                        variant="primary"
                                                        label={strings("Да, сохранить")}
                                                        width="150px"
                                                        onClick={() => {
                                                            this.props.SaveHandler(generalInfo);
                                                            this.props.CloseHandler();
                                                        }}
                                                    />
                                                </ModalButton>
                                                <ModalButton>
                                                    <Button
                                                        type="button"
                                                        variant="secondary"
                                                        label={strings("Отмена")}
                                                        width="80"
                                                        display="right"
                                                        onClick={this.props.CloseHandler}
                                                    />
                                                </ModalButton>
                                            </ModalButtonsContainer>
                                        </ModalFooter>
                                    </ModalStepWrapper>
                                </Modal>
                            </>
                        )}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { WizardSteps } from "./WizardSteps";
import { ModalSteps } from "./ModalSteps";
import { Modal, ModalHeader, ModalBody } from "headpoint-react-components";

export class CreateUpdateWizard extends React.Component {
    render() {
        if (!this.props?.visible) {
            return null;
        }

        return (
            <Modal size={this.props.size ?? "md"}>
                <ModalHeader title={this.props.title} align="center" closeHandler={this.props.CloseHandler} />

                <ModalBody>
                    <WizardSteps
                        mode={this.props.Mode}
                        steps={this.props.steps}
                        step={this.props.step}
                        activeStep={this.props.activeStep}
                        SetActiveStep={this.props.SetActiveStep}
                    />

                    <ModalSteps
                        mode={this.props.Mode}
                        step={this.props.step}
                        activeStep={this.props.activeStep}
                        values={this.props.values}
                        HandleNextStep={this.props.NextStep}
                        SetValuesProperty={this.props.SetValuesProperty}
                        CreateHandler={this.props.CreateHandler}
                        {...this.props}
                    />
                </ModalBody>
            </Modal>
        );
    }
}

import React from "react";
import { GetTableData } from "../../../../../services/users";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { GetUsers, GetUsersGroups } from "../../../../../services/users";
import {
    DeviceIcon,
    Row,
    Space,
    Table,
    TableAccordionItem,
    TableBody,
    TableCell,
    TableRow,
    Text,
} from "headpoint-react-components";
import { formatRoleUserCount } from "../../../../../utilites/TextUtils";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsUsersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            usersGroups: [],
        };
    }

    componentDidMount = async () => {
        const { strings } = this.props;

        const [usersStatus, users] = await GetUsers();
        if (!usersStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения пользователей"), { variant: "error" });
            return;
        }

        const [usersGroupsStatus, usersGroups] = await GetUsersGroups();
        if (!usersGroupsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп пользователей"), { variant: "error" });
            return;
        }

        this.setState({ users, usersGroups });
    };

    render() {
        const { selected, strings } = this.props;
        const { users, usersGroups } = this.state;
        const roleUsers = users.filter((user) => user.roleId === selected.id);
        const userGroups = GetTableData(roleUsers, usersGroups).filter((group) => group.users?.length > 0);

        return (
            <DetailsBoxContent>
                <Row marginTop={24} marginBottom={16}>
                    <Text color="primary" variant="body">
                        {`${strings("Роль назначена")} ${roleUsers.length} ${strings(
                            formatRoleUserCount(roleUsers.length)
                        )}`}
                    </Text>
                </Row>

                <Table isFixed>
                    <TableBody>
                        {userGroups.map((row) => (
                            <TableAccordionItem
                                key={row.id}
                                toggle={
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {row.name}
                                        </Text>
                                    </TableCell>
                                }
                            >
                                {row.users.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <Row align="middle">
                                                <Text color={"success"} variant="body-sm">
                                                    <DeviceIcon icon="user" size={16} />
                                                </Text>
                                                <Space />
                                                <Text color="primary" variant="body-sm">
                                                    {item.properties?.name}
                                                    <Space />
                                                    {item.properties?.surname}
                                                </Text>
                                            </Row>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableAccordionItem>
                        ))}
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsUsersTab));

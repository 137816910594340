import React from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";

export const DetailsBox = ({ children }) => (
    <DetailsBoxStyled>
        <DetailsBoxInner>{children}</DetailsBoxInner>
    </DetailsBoxStyled>
);

export const AspectBox = ({ children, aspect = "1:1" }) => (
    <AspectBoxContainer aspect={aspect}>
        <AspectBoxInner>{children}</AspectBoxInner>
    </AspectBoxContainer>
);

const getAspect = (aspect) => {
    switch (aspect) {
        case "16:9":
            return `${(9 / 16) * 100}%`;

        case "2:1":
            return "50%";

        case "1:1":
            return "100%";

        case "1:2":
            return "200%";

        default:
            return "100%";
    }
};

const DetailsBoxStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.primary3};
    height: 100%;

    div + & {
        width: 50%;
    }
`;

const DetailsBoxInner = styled.div`
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    width: 100%;
    padding: 0 24px 24px 24px;
`;

export const DetailsBoxHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    margin: 0 -24px;

    ${({ sticky }) =>
        sticky &&
        css`
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: ${({ theme }) => theme.colors.primary3};
        `}

    &:after {
        content: "";
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 24px;
        right: 24px;
        z-index: 1;
        background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
    }
`;

export const DetailsBoxTitle = styled.div`
    min-width: 0;
    margin-right: 10px;

    & > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const DetailsBoxSubtitle = styled.div`
    margin-top: 4px;
`;

export const DetailsBoxHeaderActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;

    & > * + * {
        margin-left: 8px;
    }
`;

export const DetailsBoxTabsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailsBoxTabs = styled.div`
    flex: 1;
    margin: 0 -24px;
    overflow: auto;
    padding: 0 24px;
`;

export const DetailsBoxContent = styled.div`
    margin-top: 20px;
    padding-bottom: 24px;
    max-height: 75vh;
`;

export const DetailsBoxMedia = styled.div`
    margin-bottom: 20px;

    & img {
        width: 100%;
    }
`;

export const DetailsBoxItem = styled.div`
    margin-bottom: 20px;
`;

export const DetailsBoxGallery = styled.div`
    display: flex;
`;

export const DetailsBoxGalleryItem = styled.div`
    flex: 1;
    margin-bottom: 1px;

    & + & {
        margin-left: 1px;
    }
`;

const AspectBoxContainer = styled.div`
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: ${({ aspect }) => getAspect(aspect)};
    position: relative;
`;

const AspectBoxInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & img {
        max-width: 100%;
        max-height: 100%;
    }
`;

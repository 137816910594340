import React from "react";
import PubSub from "pubsub-js";
import styled from "styled-components";
import { SHOW_ALARM_NOTIFIER } from "./alarmTopics";
import { NOTIFICATIONS_ASSETS_PATH } from "../Notifier/noficatorConsts";

class AlarmNotifier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alarmEnabled: false,
        };
    }

    componentDidMount() {
        this.notifyEvent = PubSub.subscribe(SHOW_ALARM_NOTIFIER, async (msg, alarmData) => {
            this.startAlarm(alarmData);
        });
    }

    componentWillUnmount() {
        if (this.notifyEvent) {
            PubSub.unsubscribe(this.notifyEvent);
        }
    }

    startAlarm(alarmData) {
        this.setState({ alarmEnabled: true });

        if (!this.audio && alarmData?.sound) {
            const soundUrl = `${NOTIFICATIONS_ASSETS_PATH}/${alarmData.sound}`;
            this.audio = new Audio(soundUrl);

            this.audio.loop = true;
            this.audio.play();
        }
    }

    stopAlarm() {
        this.setState({ alarmEnabled: false });
        if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.audio = null;
        }
    }

    render() {
        return this.state.alarmEnabled ? <AlarmScreen onClick={() => this.stopAlarm()} /> : null;
    }
}

export default AlarmNotifier;

const AlarmScreen = styled.div`
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    position: fixed;
    background: red;
    opacity: 0.5;
    z-index: 1500;

    animation: blink-animation 500ms steps(200, start) infinite;
    -webkit-animation: blink-animation 500ms steps(200, start) infinite;

    @keyframes blink-animation {
        to {
            opacity: 0;
        }
    }

    @-webkit-keyframes blink-animation {
        to {
            opacity: 0;
        }
    }

    &:hover {
        cursor: pointer;
    }
`;

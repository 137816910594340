import React from "react";
import { LayoutMain, LayoutPage, LayoutSidebar } from "../../../layout/MainLayout";
import { MapContainer, TileLayer } from "react-leaflet";
import { withSnackbar } from "notistack";
import {
    GeneralContextConsumer,
    getEventTypes,
    getServiceConfig,
    getSystemVariableValue,
    permissionExists,
} from "../../../contexts";
import { LocationDetails } from "./LocationDetails";
import DeviceDetails from "./DeviceDetails/DeviceDetails";
import "../styles/Maps.css";
import MapPoints from "./MapPoints";
import { GetClusters, GetPoints, UpdatePoint } from "../../../services/map";
import PubSub from "pubsub-js";
import {
    SHOW_DETAILS_TOPIC,
    FREEZE_MAP_TOPIC,
    OPEN_CLUSTER_TOPIC,
    LOAD_CLUSTER_VIEW_TOPIC,
    MAP_DEVICE_LOCATIONS_FILTER,
} from "./mapTopics";
import { throttle, debounce } from "throttle-debounce";
import Filters from "./Filters";
import {
    CreateEventsFilter,
    DeleteEventsFilter,
    GetEventsByDevices,
    GetEventsFilter,
    GetEventsFilters,
    UpdateEventsFilter,
} from "../../../services/events";
import moment from "moment";
import { DeleteModal } from "../../settings/components/DeleteModal";
import EventsFilterModal from "../../events/components/Modals/EventsFilterModal";
import { UpdateFilterModal } from "../../events/components/Modals/UpdateFilterModal";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { EventsLayout, EventsLayoutPrimary } from "../../../layout/EventsLayout";
import { withRouter } from "react-router-dom";
import {
    resetFilter,
    saveDevices,
    saveEventFilters,
    saveEventTypes,
    saveGroups,
    saveInterval,
    saveLocations,
    saveMapPosition,
    saveSelected,
    saveSelectedFilter,
    saveZones,
} from "../../../app/reducers/mapReducer";
import { GetDeviceInfo } from "../../../services/devices";
import { GetDevicesByZones } from "../../../services/controlZones";
import { GROUP_ZONE_FILTER } from "../../components/GroupZoneFilter/GroupZoneFilter";

const MAP_TAG = "MAP";

const CLUSTER_RADIUS = 32;
const OPEN_CLUSTER_COUNT = 12;
const EVENTS_FILTER_DELAY = 1000;

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            selected: null,
            devicesFilter: [],
            eventTypesFilter: [],
            intervalFilter: "all",
            eventsFilter: {
                showDanger: false,
                showWarning: false,
                showRead: false,
            },
            locationsFilter: [],
            zonesFilter: [],
            groupsFilter: [],
            allCheckedLocations: [],
            points: null,
            isEditing: false,
            timerId: null,
            eventsFilters: [],
            showNewEventsFilter: false,
            showUpdateEventsFilter: false,
            deleteFilter: undefined,
            updateFilter: undefined,
            selectedFilter: undefined,
        };

        this.throttleUpdateAllMapObjects = throttle(500, (...args) => void this.updateAllMapObjects(...args), {
            noLeading: false,
            noTrailing: false,
        });

        this.throttleUpdateAllMapObjectsWithClustersRestoring = throttle(
            500,
            (...args) => void this.updateAllMapObjectsWithClustersRestoring(...args),
            {
                noLeading: false,
                noTrailing: false,
            }
        );

        this.debounceGetEvents = debounce(EVENTS_FILTER_DELAY, (...args) => void this.addEventsToPoints(...args), {
            noLeading: false,
            noTrailing: false,
        });

        this.mapRef = React.createRef();
    }

    getEpsilon = () => {
        const bounds = this.mapRef?.getBounds();
        const xScale = (bounds._southWest.lng - bounds._northEast.lng) / this.mapRef?._size.x;
        const yScale = (bounds._southWest.lat - bounds._northEast.lat) / this.mapRef?._size.y;

        return Math.max(Math.abs(xScale), Math.abs(yScale)) * CLUSTER_RADIUS;
    };

    getDistance = (a, b) => {
        return Math.sqrt(
            Math.pow(a.properties?.geolocation?.latitude - b.properties?.geolocation?.latitude, 2) +
                Math.pow(a.properties?.geolocation?.longitude - b.properties?.geolocation?.longitude, 2)
        );
    };

    getViewZone = () => {
        const bounds = this.mapRef?.getBounds();

        return {
            southWest: {
                latitude: bounds._southWest.lat,
                longitude: bounds._southWest.lng,
            },
            northEast: {
                latitude: bounds._northEast.lat,
                longitude: bounds._northEast.lng,
            },
        };
    };

    componentDidMount = async () => {
        const { strings } = this.props;

        this.showDetailsTopic = PubSub.subscribe(SHOW_DETAILS_TOPIC, async (msg, selected) => {
            this.setState({ selected });
            this.props.dispatch(saveSelected(selected));

            this.mapRef?.invalidateSize();
            if (!selected) {
                return;
            }

            const [lat, long] = [
                selected.properties?.geolocation?.latitude ?? this.mapRef?._lastCenter?.lat ?? 0,
                selected.properties?.geolocation?.longitude ?? this.mapRef?._lastCenter?.lng ?? 0,
            ];

            if (this.mapRef) {
                this.props.dispatch(
                    saveMapPosition({
                        center: [lat, long],
                        zoom: this.mapRef._zoom,
                    })
                );

                this.mapRef.setView([lat, long], this.mapRef?._zoom ?? 0);
            }
        });

        this.zoneEditiongHandler = PubSub.subscribe(FREEZE_MAP_TOPIC, async (msg, start) => {
            if (start) {
                this.mapRef?.dragging?.disable();
                this.mapRef?.scrollWheelZoom?.disable();
            } else {
                this.mapRef?.dragging?.enable();
                this.mapRef?.scrollWheelZoom?.enable();
            }
        });

        this.openClusterTopic = PubSub.subscribe(OPEN_CLUSTER_TOPIC, async (msg, data) => {
            const { clusters } = this.state;
            if (data.id === null || data.id === undefined || !clusters?.length) {
                return;
            }

            const cluster = clusters.find(({ id }) => data.id === id);
            if (cluster.pointIds?.length > OPEN_CLUSTER_COUNT && this.mapRef._zoom < this.mapRef._layersMaxZoom) {
                this.mapRef?.setView([cluster.latitude, cluster.longitude], (this.mapRef._zoom ?? 0) + 1);
                return;
            }

            if (data.isOpen) {
                const [pointsDevicesStatus, pointsDevices] = await GetPoints({ deviceIds: cluster.pointIds });
                if (pointsDevicesStatus) {
                    cluster.points = pointsDevices;
                    cluster.isOpen = data.isOpen;
                    PubSub.publish(LOAD_CLUSTER_VIEW_TOPIC, cluster);
                } else {
                    this.props.enqueueSnackbar(strings("Ошибка получения объектов"), { variant: "error" });
                }
            } else {
                cluster.points = null;
                cluster.isOpen = false;
            }

            this.setState({ clusters: [...clusters] });
        });

        const [filtersStatus, eventsFilters] = await GetEventsFilters(MAP_TAG);

        if (!filtersStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
        }

        this.setState({ eventsFilters });

        this.startAutoUpdate();
    };

    componentWillUnmount() {
        if (this.showDetailsTopic) {
            PubSub.unsubscribe(this.showDetailsTopic);
        }

        if (this.zoneEditiongHandler) {
            PubSub.unsubscribe(this.showDetailsTopic);
        }

        if (this.openClusterTopic) {
            PubSub.unsubscribe(this.openClusterTopic);
        }

        this.stopAutoUpdate();
    }

    stopAutoUpdate = () => {
        if (this.state.timerId) {
            clearInterval(this.state.timerId);
            this.setState({ timerId: null });
        }
    };

    startAutoUpdate = () => {
        const timerId = this.createAutoUpdateTimer();
        this.setState({ timerId });
    };

    createAutoUpdateTimer = () => {
        const timeout = getSystemVariableValue(this.props.generalInfo, "service.api.events", "events.timeout", 60);
        return setInterval(() => this.intervalHandler(), timeout * 1000);
    };

    async intervalHandler() {
        const { eventsFilter, points, clusters, eventTypesFilter, intervalFilter } = this.state;
        await this.addEventsToPoints(eventsFilter, points, clusters, eventTypesFilter, intervalFilter);
    }

    async addEventsToPoints(eventsFilter, points, clusters, eventTypesFilter, intervalFilter) {
        const [pointsWithEvents, clustersWithEvents] = await this.getEvents(
            eventsFilter,
            points,
            clusters,
            eventTypesFilter,
            intervalFilter
        );

        this.setState({ points: pointsWithEvents, clusters: clustersWithEvents });
    }

    openDeviceFromHistory = (data, points) => {
        switch (data?.type) {
            case "ShowMap":
                const point = points.find((p) => p.id === data.device.id);

                if (!point) {
                    this.props.enqueueSnackbar("Не удалось найти точку на карте - очистите фильтры", {
                        variant: "warning",
                    });
                    return;
                }

                PubSub.publish(SHOW_DETAILS_TOPIC, point);
                break;

            default:
                break;
        }
    };

    openMapFromStorage = async (filter) => {
        const {
            map: { selected },
        } = this.props;

        const zones = this.openTrees(filter);

        if (selected) {
            PubSub.publish(SHOW_DETAILS_TOPIC, selected);
        }

        this.setState({
            devicesFilter: filter.devicesFilter,
            locationsFilter: filter.locationsFilter,
            zonesFilter: zones,
            eventTypesFilter: filter.eventTypesFilter,
            selectedFilter: filter.selectedFilter,
            intervalFilter: filter.intervalFilter,
            eventsFilter: { ...filter.eventsFilter },
        });

        this.throttleUpdateAllMapObjects(
            filter.locationsFilter,
            filter.devicesFilter,
            filter.eventTypesFilter,
            filter.intervalFilter
        );
    };

    openTrees = (filter) => {
        if (filter.locationsFilter?.length) {
            PubSub.publish(MAP_DEVICE_LOCATIONS_FILTER, {
                type: "FilterByLocations",
                data: filter.locationsFilter,
            });
        }

        if (filter.devicesFilter?.length) {
            PubSub.publish(MAP_DEVICE_LOCATIONS_FILTER, {
                type: "FilterByDevices",
                data: filter.devicesFilter,
            });
        }

        const zonesFromGroups = filter.groupsFilter?.map((g) => g.children).flat();
        const allZones = zonesFromGroups?.concat(filter.zonesFilter);
        if (allZones?.length) {
            PubSub.publish(GROUP_ZONE_FILTER, {
                data: allZones,
            });
        }

        return allZones;
    };

    loadEventsFilterHandler = async (filter) => {
        const { strings } = this.props;

        const [filterStatus, eventsFilter] = await GetEventsFilter(filter.id);
        if (filterStatus) {
            const devicesFilter = eventsFilter.properties.devicesFilter;
            const locationsFilter = eventsFilter.properties.locationsFilter;
            const zonesFilter = eventsFilter.properties.zonesFilter;
            const groupsFilter = eventsFilter.properties.groupsFilter;
            const eventTypesFilter = eventsFilter.properties.eventTypesFilter;
            const intervalFilter = eventsFilter.properties.intervalFilter;

            this.props.dispatch(saveDevices(devicesFilter ?? []));
            this.props.dispatch(saveZones(zonesFilter ?? []));
            this.props.dispatch(saveGroups(groupsFilter ?? []));
            this.props.dispatch(saveLocations(locationsFilter ?? []));
            this.props.dispatch(saveEventTypes(eventTypesFilter ?? []));
            this.props.dispatch(saveSelectedFilter(eventsFilter));
            this.props.dispatch(saveInterval(intervalFilter ?? "all"));
            this.props.dispatch(
                saveEventFilters({
                    showInfo: eventsFilter.properties.showInfo ?? false,
                    showWarning: eventsFilter.properties.showWarning ?? false,
                    showDanger: eventsFilter.properties.showDanger ?? false,
                    showRead: eventsFilter.properties.showRead ?? false,
                })
            );

            const zones = this.openTrees({ devicesFilter, locationsFilter, zonesFilter, groupsFilter });

            this.setState({
                devicesFilter: devicesFilter ?? [],
                locationsFilter: locationsFilter ?? [],
                zonesFilter: zones ?? [],
                groupsFilter: groupsFilter ?? [],
                eventTypesFilter: eventTypesFilter ?? [],
                intervalFilter: intervalFilter ?? "all",
                eventsFilter: {
                    ...this.state.eventsFilter,
                    showInfo: eventsFilter.properties.showInfo ?? false,
                    showWarning: eventsFilter.properties.showWarning ?? false,
                    showDanger: eventsFilter.properties.showDanger ?? false,
                    showRead: eventsFilter.properties.showRead ?? false,
                },
                selectedFilter: eventsFilter,
            });

            void this.throttleUpdateAllMapObjects(locationsFilter, devicesFilter, eventTypesFilter, intervalFilter);

            this.props.enqueueSnackbar(`${strings("Применён фильтр")} ${filter.name}`, { variant: "success" });
        } else {
            this.props.enqueueSnackbar(strings(`Не удалось загрузить фильтр`), {
                variant: "error",
            });
            return;
        }
    };

    combineDataToEventsFilter(data) {
        const {
            devicesFilter,
            locationsFilter,
            eventTypesFilter,
            intervalFilter,
            eventsFilter,
            zonesFilter,
            groupsFilter,
        } = this.state;

        return {
            name: data.name,
            description: data.comment,
            type: data.type,
            entities: data.entities,
            tag: MAP_TAG,
            properties: {
                devicesFilter,
                locationsFilter,
                eventTypesFilter,
                intervalFilter,
                zonesFilter,
                groupsFilter,
                showDanger: eventsFilter.showDanger,
                showWarning: eventsFilter.showWarning,
                showRead: eventsFilter.showRead,
            },
        };
    }

    createEventsFilterHandler = async (data) => {
        const { strings } = this.props;
        const filter = this.combineDataToEventsFilter(data);

        const [status, code] = await CreateEventsFilter(filter);
        if (code === 409) {
            this.props.enqueueSnackbar(strings(`В одном контексте фильтры событий должны иметь уникальные имена`), {
                variant: "error",
            });
            return;
        }
        if (!status) {
            this.props.enqueueSnackbar(strings(`Не удалось сохранить фильтр`), {
                variant: "error",
            });
            return;
        } else {
            this.props.enqueueSnackbar(`${strings("Фильтр")} ${filter.name} ${strings("сохранен")}`, {
                variant: "success",
            });

            const [filtersStatus, eventsFilters] = await GetEventsFilters(MAP_TAG);

            if (!filtersStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
            }

            this.setState({ showNewEventsFilter: false, eventsFilters });
        }
    };

    updateEventsFilterHandler = async () => {
        const { strings } = this.props;
        const { updateFilter } = this.state;

        const filter = this.combineDataToEventsFilter(updateFilter);

        const [status] = await UpdateEventsFilter(updateFilter.id, filter);
        if (!status) {
            this.props.enqueueSnackbar(strings(`Не удалось сохранить фильтр`), {
                variant: "error",
            });
            return;
        } else {
            this.props.enqueueSnackbar(`${strings("Фильтр")} ${filter.name} ${strings("сохранен")}`, {
                variant: "success",
            });

            const [filtersStatus, eventsFilters] = await GetEventsFilters(MAP_TAG);

            if (!filtersStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
            }

            this.setState({ showNewEventsFilter: false, eventsFilters });
        }
    };

    deleteEventsFilterHandler = async () => {
        const { strings } = this.props;
        const { eventsFilters, deleteFilter } = this.state;

        if (await DeleteEventsFilter(deleteFilter.id)) {
            this.setState({
                eventsFilters: eventsFilters?.filter((d) => d.id !== deleteFilter.id),
                deleteFilter: undefined,
            });
            this.props.enqueueSnackbar(`${strings("Фильтр событий")} ${deleteFilter.name} ${strings("удален")}`, {
                variant: "success",
            });
        } else {
            this.setState({ deleteFilter: undefined });
            this.props.enqueueSnackbar(strings("Не удалось удалить фильтр событий"), { variant: "error" });
        }
    };

    updateAllMapObjects = async (locationsFilter, devicesFilter, eventTypesFilter, intervalFilter) => {
        const { history } = this.props;

        let [points, clusters] = await this.getAllMapObjects(locationsFilter, devicesFilter);

        await this.addEventsToPoints(this.state.eventsFilter, points, clusters, eventTypesFilter, intervalFilter);

        const data = history.location.state;
        if (data) {
            history.replace({ ...history, state: undefined });
            this.openDeviceFromHistory(data, points);
        }
    };

    updateAllMapObjectsWithClustersRestoring = async (
        locationsFilter,
        devicesFilter,
        eventTypesFilter,
        intervalFilter
    ) => {
        let [points, clusters] = await this.getAllMapObjects(locationsFilter, devicesFilter);

        clusters = this.restoreClusters(clusters);

        await this.addEventsToPoints(this.state.eventsFilter, points, clusters, eventTypesFilter, intervalFilter);
    };

    getAllMapObjects = async (locationsFilter, devicesFilter) => {
        let points = await this.getPoints(locationsFilter, devicesFilter);
        let clusters = await this.getClusters(locationsFilter, devicesFilter);

        /*
         * The library for building clusters on the backend cannot build a cluster when there are only 2 points on the map :)
         * Therefore, for cases when there are only two points on the map, this code is executed.
         * It combines points into a cluster if necessary.
         */
        if (!clusters.length && points.length === 2 && this.getEpsilon() >= this.getDistance(points[0], points[1])) {
            clusters.push({
                id: 0,
                deviceIds: points?.map((p) => p.id) ?? [],
                latitude:
                    (Number.parseFloat(points[0].properties?.geolocation?.latitude) +
                        Number.parseFloat(points[1].properties?.geolocation?.latitude)) /
                    2,
                longitude:
                    (Number.parseFloat(points[0].properties?.geolocation?.longitude) +
                        Number.parseFloat(points[1].properties?.geolocation?.longitude)) /
                    2,
            });

            points = [];
        }

        return [points, clusters];
    };

    restoreClusters = (clusters) => {
        const oldClusters = this.state.clusters;
        const oldClustersMap = {};
        oldClusters.forEach((oldCluster) => (oldClustersMap[oldCluster.id] = oldCluster));

        const restoredClusters = [...clusters];
        for (let restoredCluster of restoredClusters) {
            const oldCluster = oldClustersMap[restoredCluster.id];

            if (oldCluster) {
                restoredCluster.isOpen = oldCluster.isOpen;
                restoredCluster.points = oldCluster.points;
            }
        }

        return restoredClusters;
    };

    getPoints = async (locationsFilter, devicesFilter) => {
        const { strings } = this.props;

        let points = [];
        const filter = {
            viewZone: this.getViewZone(),
            eps: this.getEpsilon(),
        };

        if (locationsFilter?.length) {
            filter.locationIds = locationsFilter?.map((l) => l.id);
        }

        if (devicesFilter?.length) {
            filter.deviceIds = devicesFilter?.map((d) => d.id);
        }

        const [pointsDevicesStatus, pointsDevices] = await GetPoints(filter);
        if (pointsDevicesStatus) {
            points = points.concat(pointsDevices);
        } else {
            this.props.enqueueSnackbar(strings("Ошибка получения объектов"), { variant: "error" });
        }

        return points;
    };

    getClusters = async (locationsFilter, devicesFilter) => {
        const { strings } = this.props;

        let clusters = [];
        let filter = {
            eps: this.getEpsilon(),
            viewZone: this.getViewZone(),
        };

        if (locationsFilter?.length) {
            filter.locationIds = locationsFilter?.map((l) => l.id);
        }

        if (devicesFilter?.length) {
            filter.deviceIds = devicesFilter?.map((d) => d.id);
        }

        const [devicesClustersStatus, devicesClusters] = await GetClusters(filter);
        if (devicesClustersStatus) {
            clusters = clusters.concat(devicesClusters);
        } else {
            this.props.enqueueSnackbar(strings("Ошибка получения объектов"), { variant: "error" });
        }

        return clusters;
    };

    async getEvents(filters, points, clusters, eventTypesFilter, intervalFilter) {
        const { strings } = this.props;

        const deviceIds = (points.map((p) => p.id) ?? []).concat((clusters ?? []).map((c) => c.pointIds).flat());
        const filter = this.createFilter(filters, deviceIds, eventTypesFilter, intervalFilter);
        const [success, events] = await GetEventsByDevices(filter);

        if (success) {
            this.setState(events);

            points = points.map((p) => ({
                ...p,
                severity: (p.severity = events.find(({ id }) => id === p.id)?.severity),
            }));

            clusters = clusters?.map((c) => ({
                ...c,
                events: (c.events = this.getClusterEvents(c, events)),
            }));

            return [points, clusters];
        } else {
            this.props.enqueueSnackbar(strings("Не удалось получить события"), {
                variant: "warning",
            });

            clusters = clusters?.map((c) => ({
                ...c,
                pointIds: c.deviceIds,
            }));

            return [points, clusters];
        }
    }

    getClusterEvents(cluster, events) {
        let clusterEvents = [];

        events.forEach((e) => {
            if (cluster.pointIds.includes(e.id)) {
                clusterEvents.push(e);
            }
        });

        return clusterEvents;
    }

    createFilter(filters, deviceIds, eventTypes, intervalFilter) {
        const showRead = filters.showRead;
        const severity = [];

        filters.showDanger && severity.push("danger");
        filters.showWarning && severity.push("warning");
        let result = { severity, showRead, deviceIds };

        if (eventTypes?.length) {
            result = { ...result, eventTypes };
        }

        switch (intervalFilter) {
            case "last":
                const interval = getSystemVariableValue(
                    this.props.generalInfo,
                    "service.api.map",
                    "map.events.interval",
                    60
                );

                result = { ...result, from: moment().utc().add(-interval, "minutes") };
                break;

            case "new":
                const partitionsCloseTimeStr = window.localStorage.getItem("partitionsCloseTime");
                const partitionsCloseTime = JSON.parse(partitionsCloseTimeStr ?? "{}");
                const from = partitionsCloseTime?.["/map"];
                result = from ? { ...result, from } : result;
                break;

            default:
                break;
        }

        return result;
    }

    updateMapObjects = () => {
        const { devicesFilter, allCheckedLocations, eventTypesFilter, intervalFilter } = this.state;

        void this.throttleUpdateAllMapObjectsWithClustersRestoring(
            allCheckedLocations,
            devicesFilter,
            eventTypesFilter,
            intervalFilter
        );
    };

    resetFilter = () => {
        this.props.dispatch(resetFilter());

        this.setState({
            devicesFilter: [],
            locationsFilter: [],
            eventTypesFilter: [],
            adapterFilters: [],
            zonesFilter: [],
            intervalFilter: "all",
            eventsFilter: {
                showInfo: false,
                showWarning: false,
                showDanger: false,
                showRead: false,
            },
            selectedFilter: undefined,
        });

        void this.throttleUpdateAllMapObjects([], [], [], []);
    };

    onDragHandler = async (p, l) => {
        const { devicesFilter, locationsFilter, eventTypesFilter, intervalFilter } = this.state;

        const lngBounds = (lng) => {
            if (lng > 180) return 180;
            if (lng < -180) return -180;
            return lng;
        };

        await UpdatePoint(p.id, {
            latitude: l.lat,
            longitude: lngBounds(l.lng),
            pointType: p.type === "Device" ? 1 : 0,
        });

        await this.updateAllMapObjects(locationsFilter, devicesFilter, eventTypesFilter, intervalFilter);
    };

    loadDevicesTree = async (zonesFilterToLoad, groupsFilterToLoad, zonesFilterToUnload, groupsFilterToUnload) => {
        const { devicesFilter } = this.state;

        const zonesIdsToLoad = zonesFilterToLoad
            .concat(groupsFilterToLoad.map((g) => g.children).flat())
            .map((z) => z.id);

        const zonesIdsToUnload = zonesFilterToUnload
            .concat(groupsFilterToUnload.map((g) => g.children).flat())
            .map((z) => z.id);

        let [statusToLoad, devicesToLoad] = await GetDevicesByZones({ zonesIds: zonesIdsToLoad });
        if (!statusToLoad) {
            this.props.enqueueSnackbar("Ошибка получения девайсов", { variant: "error" });
            return;
        }

        let [statusToUnload, devicesToUnload] = await GetDevicesByZones({ zonesIds: zonesIdsToUnload });
        if (!statusToUnload) {
            this.props.enqueueSnackbar("Ошибка получения девайсов", { variant: "error" });
            return;
        }

        const { eventTypesFilter, intervalFilter, locationsFilter } = this.state;

        let [deviceStatus, deviceInfo] = await GetDeviceInfo(devicesToLoad.deviceIds);

        if (!deviceStatus) {
            this.props.enqueueSnackbar("Ошибка получения девайсов", { variant: "error" });
            return;
        }

        PubSub.publish(MAP_DEVICE_LOCATIONS_FILTER, {
            type: "FilterByDevices",
            data: deviceInfo,
        });

        let devicesToShow = devicesFilter
            .filter((d) => !devicesToUnload.deviceIds.includes(d.id))
            .concat(deviceInfo)
            .filter((device, i, devices) => devices.findIndex((d) => d.id === device.id) === i);

        this.props.dispatch(saveDevices(devicesToShow));
        this.setState({ devicesFilter: devicesToShow });

        void this.throttleUpdateAllMapObjects(locationsFilter, devicesToShow, eventTypesFilter, intervalFilter);
    };

    render() {
        const {
            devicesFilter,
            locationsFilter,
            selected,
            isEditing,
            points,
            clusters,
            eventsFilter,
            eventTypesFilter,
            intervalFilter,
            eventsFilters,
            showNewEventsFilter,
            deleteFilter,
            updateFilter,
            selectedFilter,
            zonesFilter,
            groupsFilter,
        } = this.state;

        const {
            generalInfo,
            history,
            map: { filter, map },
            userInfo,
            strings,
        } = this.props;

        const data = history.location.state;

        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        const disabled =
            eventsFilter.showDanger ||
            eventsFilter.showWarning ||
            eventsFilter.showRead ||
            eventTypesFilter.length > 0 ||
            locationsFilter.length > 0 ||
            devicesFilter.length > 0 ||
            intervalFilter !== "all" ||
            selectedFilter;

        return (
            <>
                <DeleteModal
                    visible={!!deleteFilter}
                    CloseHandler={() => this.setState({ deleteFilter: undefined })}
                    RemoveHandler={this.deleteEventsFilterHandler}
                    text={`${strings("Вы хотите удалить фильтр событий")} "${deleteFilter?.name}". ${strings(
                        "Удалённый фильтр событий нельзя восстановить. Продолжить?"
                    )}`}
                    strings={strings}
                />

                <UpdateFilterModal
                    visible={!!updateFilter}
                    CloseHandler={() => this.setState({ updateFilter: undefined })}
                    SaveHandler={this.updateEventsFilterHandler}
                    filter={updateFilter}
                    strings={strings}
                />
                <LayoutPage>
                    <LayoutSidebar>
                        <Filters
                            setEventsFilter={(eventsFilter) => {
                                this.props.dispatch(saveEventFilters(eventsFilter));
                                this.setState({ eventsFilter });
                                const { points, clusters, eventTypesFilter, intervalFilter } = this.state;
                                void this.addEventsToPoints(
                                    eventsFilter,
                                    points,
                                    clusters,
                                    eventTypesFilter,
                                    intervalFilter
                                );
                            }}
                            setEventsTypesFilter={(eventTypesFilter) => {
                                this.props.dispatch(saveEventTypes(eventTypesFilter));
                                this.setState({ eventTypesFilter });

                                const { points, clusters, intervalFilter } = this.state;
                                void this.addEventsToPoints(
                                    eventsFilter,
                                    points,
                                    clusters,
                                    eventTypesFilter,
                                    intervalFilter
                                );
                            }}
                            setEventsIntervalFilter={(intervalFilter) => {
                                this.props.dispatch(saveInterval(intervalFilter));
                                this.setState({ intervalFilter });

                                const { points, clusters, eventTypesFilter } = this.state;
                                void this.addEventsToPoints(
                                    eventsFilter,
                                    points,
                                    clusters,
                                    eventTypesFilter,
                                    intervalFilter
                                );
                            }}
                            eventTypesFilter={eventTypesFilter}
                            defaultEventTypes={getEventTypes(generalInfo)}
                            devicesFilter={devicesFilter}
                            eventsFilter={eventsFilter}
                            locationsFilter={locationsFilter}
                            zonesFilter={zonesFilter}
                            groupsFilter={groupsFilter}
                            intervalFilter={intervalFilter}
                            selectedFilter={selectedFilter}
                            dropFilter={this.resetFilter}
                            setDeviceLocationFilter={async (
                                devicesFilterToLoad,
                                locationsFilterToLoad,
                                allCheckedLocations
                            ) => {
                                const { eventTypesFilter, intervalFilter } = this.state;

                                devicesFilterToLoad && this.props.dispatch(saveDevices(devicesFilterToLoad));
                                locationsFilterToLoad && this.props.dispatch(saveLocations(locationsFilterToLoad));

                                devicesFilterToLoad && this.setState({ devicesFilter: devicesFilterToLoad });
                                locationsFilterToLoad && this.setState({ locationsFilter: locationsFilterToLoad });

                                void this.throttleUpdateAllMapObjects(
                                    allCheckedLocations,
                                    devicesFilterToLoad,
                                    eventTypesFilter,
                                    intervalFilter
                                );

                                this.setState({ zonesFilter: [], groupsFilter: [] });
                            }}
                            setGroupZoneFilter={async (zonesFilterToLoad, groupsFilterToLoad) => {
                                const { zonesFilter, groupsFilter } = this.state;

                                zonesFilterToLoad && this.props.dispatch(saveZones(zonesFilterToLoad));
                                groupsFilterToLoad && this.props.dispatch(saveGroups(groupsFilterToLoad));

                                zonesFilterToLoad && this.setState({ zonesFilter: zonesFilterToLoad });
                                groupsFilterToLoad && this.setState({ groupsFilter: groupsFilterToLoad });

                                const zonesFilterToUnload = zonesFilter.filter((z) => !zonesFilterToLoad.includes(z));

                                const groupsFilterToUnload = groupsFilter.filter(
                                    (g) => !groupsFilterToLoad.includes(g)
                                );

                                this.loadDevicesTree(
                                    zonesFilterToLoad,
                                    groupsFilterToLoad,
                                    zonesFilterToUnload,
                                    groupsFilterToUnload
                                );
                            }}
                            resetGroupZoneFilter={async () => {
                                const { zonesFilter, groupsFilter } = this.state;

                                this.props.dispatch(saveZones([]));
                                this.props.dispatch(saveGroups([]));

                                this.setState({
                                    zonesFilter: [],
                                    groupsFilter: [],
                                });

                                this.loadDevicesTree([], [], zonesFilter, groupsFilter);
                            }}
                            resetdeviceLocationFilter={async () => {
                                this.props.dispatch(saveDevices([]));
                                this.props.dispatch(saveLocations([]));
                                this.setState({ devicesFilter: [], locationsFilter: [], points, clusters });
                                void this.throttleUpdateAllMapObjects();
                            }}
                            eventsFilters={eventsFilters}
                            handleOpen={() => this.setState({ showNewEventsFilter: true })}
                            handleUpdate={async (data) => {
                                this.setState({ updateFilter: data });
                            }}
                            loadFilter={this.loadEventsFilterHandler}
                            deleteFilter={(data) => {
                                this.setState({ deleteFilter: data });
                            }}
                            disabled={!disabled}
                            strings={strings}
                        />
                    </LayoutSidebar>
                    <LayoutMain>
                        <EventsLayout>
                            <GeneralContextConsumer>
                                {(generalContext) => {
                                    let { mapDefaultCenter, mapZoom } = getServiceConfig(
                                        generalContext,
                                        "service.api.map"
                                    );

                                    mapDefaultCenter = map.center ?? mapDefaultCenter;
                                    mapZoom = map.zoom ?? mapZoom;

                                    return (
                                        <>
                                            <EventsLayoutPrimary noPadding style={{ zIndex: 1 }}>
                                                <MapContainer
                                                    whenCreated={async (mapInstance) => {
                                                        this.mapRef = mapInstance;

                                                        if (filter && !data) {
                                                            await this.openMapFromStorage(filter, map);
                                                        } else {
                                                            void this.throttleUpdateAllMapObjects();
                                                        }

                                                        mapInstance.on("resize", () => {
                                                            this.props.dispatch(
                                                                saveMapPosition({
                                                                    center: mapInstance.getCenter(),
                                                                    zoom: mapInstance._zoom,
                                                                })
                                                            );
                                                        });

                                                        mapInstance.on("dragend", () => {
                                                            const {
                                                                devicesFilter,
                                                                locationsFilter,
                                                                eventTypesFilter,
                                                                intervalFilter,
                                                            } = this.state;

                                                            this.props.dispatch(
                                                                saveMapPosition({
                                                                    center: mapInstance.getCenter(),
                                                                    zoom: mapInstance._zoom,
                                                                })
                                                            );

                                                            void this.throttleUpdateAllMapObjects(
                                                                locationsFilter,
                                                                devicesFilter,
                                                                eventTypesFilter,
                                                                intervalFilter
                                                            );
                                                        });

                                                        mapInstance.on("zoomend", () => {
                                                            const {
                                                                devicesFilter,
                                                                locationsFilter,
                                                                eventTypesFilter,
                                                                intervalFilter,
                                                            } = this.state;

                                                            this.props.dispatch(
                                                                saveMapPosition({
                                                                    center: mapInstance.getCenter(),
                                                                    zoom: mapInstance._zoom,
                                                                })
                                                            );

                                                            void this.throttleUpdateAllMapObjects(
                                                                locationsFilter,
                                                                devicesFilter,
                                                                eventTypesFilter,
                                                                intervalFilter
                                                            );
                                                        });
                                                    }}
                                                    center={mapDefaultCenter}
                                                    zoom={mapZoom}
                                                    scrollWheelZoom={true}
                                                    doubleClickZoom={false}
                                                >
                                                    <TileLayer url="/tile/{z}/{x}/{y}.png" />
                                                    <MapPoints
                                                        generalContext={generalContext}
                                                        points={points}
                                                        clusters={clusters}
                                                        selected={selected}
                                                        eventsFilter={eventsFilter}
                                                        onDrag={this.onDragHandler}
                                                        strings={strings}
                                                    />
                                                </MapContainer>
                                            </EventsLayoutPrimary>
                                            {selected &&
                                                !isEditing &&
                                                selected.type?.toLowerCase() === "location" &&
                                                permissionExists(userInfo, [
                                                    "devices.device.read",
                                                    "devices.deviceGroup.read",
                                                ]) && (
                                                    <LocationDetails
                                                        selectedLocation={selected}
                                                        closeHandler={() => {
                                                            PubSub.publish(SHOW_DETAILS_TOPIC, undefined);
                                                            PubSub.publish(FREEZE_MAP_TOPIC, false);
                                                        }}
                                                        generalContext={generalContext}
                                                        strings={strings}
                                                    />
                                                )}

                                            {selected &&
                                                !isEditing &&
                                                selected.type?.toLowerCase() === "device" &&
                                                permissionExists(userInfo, [
                                                    "devices.device.read",
                                                    "devices.deviceGroup.read",
                                                ]) && (
                                                    <DeviceDetails
                                                        device={selected}
                                                        generalContext={generalContext}
                                                        closeHandler={() => {
                                                            PubSub.publish(SHOW_DETAILS_TOPIC, undefined);
                                                            PubSub.publish(FREEZE_MAP_TOPIC, false);
                                                        }}
                                                        strings={strings}
                                                    />
                                                )}
                                        </>
                                    );
                                }}
                            </GeneralContextConsumer>
                        </EventsLayout>
                    </LayoutMain>
                </LayoutPage>
                {showNewEventsFilter && (
                    <EventsFilterModal
                        generalInfo={generalInfo}
                        defaultEventTypes={getEventTypes(generalInfo)}
                        handleClose={() => this.setState({ showNewEventsFilter: false })}
                        createEventsFilter={async (data) => await this.createEventsFilterHandler(data)}
                    />
                )}
            </>
        );
    }
}

class MapWrapper extends React.Component {
    render() {
        return (
            <GeneralContextConsumer>
                {(generalInfo) => React.createElement(Map, { ...this.props, generalInfo })}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        map: state.map,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(withRouter(MapWrapper))));

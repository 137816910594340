import React from "react";
import { Toolbar } from "./Toolbar";
import { LayoutMain, LayoutPage } from "../../../layout/MainLayout";
import { FillLocations, GetLocations, GetLocationList, FillNode } from "../../../services/locations";
import { GetDevices, SearchDevices, GetDeviceInfo } from "../../../services/devices";
import { nanoid } from "nanoid";
import { withSnackbar } from "notistack";
import { GeneralContextConsumer } from "../../../contexts";
import { getAdapters, getDeviceTypesByFeature, anyPermissionExists } from "../../../contexts/GeneralContext";
import PubSub from "pubsub-js";
import { ACTIVATE_DESKTOP_TOPIC } from "../../stream/components/streamTopics";
import { isEqual as isDeepEqual } from "lodash";
import { updateNodes } from "../../../utilites/TreeUtils.js";
import { GetScreenshotsDate, SearchScreenshots } from "../../../services/screenshot";
import moment from "moment/moment";
import CameraFrames from "./CameraFrames";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import ArchiveOrderListModal from "./ArchiveOrderListModal";
import OrderArchiveModal from "./OrderArchiveModal/OrderArchiveModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    saveActiveDesktop,
    saveDates,
    saveFrames,
    saveScreenshotsDates,
    saveScreenshotsInfo,
    saveSelectedCameraFrame,
    saveShowCameraInfo,
    saveFramesCount,
    fetchSchedules,
    fetchSchedule,
    resetScheduleInfo,
} from "../../../app/reducers/galleryReducer";
import Filters from "./Filters";

const DEVICES_LIMIT = 100;

const initialDesktop = {
    layout: "1",
};

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesLocationsTree: [],
            searchDevices: [],
            features: [],
            search: "",
            activeDesktop: initialDesktop,
            frames: {},
            showOrderList: false,
            showNewOrderModal: false,
            selectedScheduleId: undefined,
        };
    }

    async componentDidMount() {
        const { history, strings } = this.props;

        this.selectedDesktopTopic = PubSub.subscribe(ACTIVATE_DESKTOP_TOPIC, async (msg, data) => {
            await this.activateDesktop(data);
        });

        const [getLocationsStatus, locations] = await GetLocations();
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        const tree = FillLocations(locations, []);
        this.setState({
            devicesLocationsTree: tree ?? [],
        });

        const frames = this.props.gallery.frames;

        if (frames) {
            for (const frame in frames) {
                await this.openLocationById(tree, frames[frame].locationId);
                this.setState({
                    updateTreeId: nanoid(),
                });
            }

            await this.setFrames(frames);
        }

        const data = history.location.state;
        if (data) {
            history.replace({ ...history, state: undefined });

            switch (data.type) {
                case "FromSchedule":
                    await this.openGalleryFromSchedule(data.schedule);
                    break;

                default:
                    await this.openGalleryFromHistory(data, tree);
                    break;
            }
        }

        const filter = {
            generalInfo: this.props.generalInfo,
        };

        this.props.dispatch(fetchSchedules({ filter, enqueueSnackbar: this.props.enqueueSnackbar, strings }));
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const [getLocationsStatus, locations] = await GetLocations();
            if (!getLocationsStatus) {
                this.props.enqueueSnackbar("Ошибка получения локаций", { variant: "error" });
            }

            const tree = FillLocations(locations, []);
            this.setState({
                devicesLocationsTree: tree ?? [],
                frames: {},
            });
        }

        if (this.state.selectedScheduleId !== this.props.gallery.scheduleInfo?.scheduleId) {
            this.setState(
                {
                    selectedScheduleId: this.props.gallery.scheduleInfo?.scheduleId,
                },
                () => {
                    this.setFrames(this.props.gallery.scheduleInfo?.frames ?? {});
                }
            );
        }
    };

    componentWillUnmount() {
        if (this.selectedDesktopTopic) {
            PubSub.unsubscribe(this.selectedDesktopTopic);
        }
    }

    openGalleryFromHistory = async (data, tree) => {
        switch (data?.type) {
            case "SelectCamera":
                await this.openLocationById(tree, data.device.locationId);
                this.props.dispatch(saveActiveDesktop(initialDesktop));
                this.setState({
                    frames: [data.device],
                    updateTreeId: nanoid(),
                });
                break;

            default:
                break;
        }
    };

    openGalleryFromSchedule = async (schedule) => {
        this.selectSchedule(schedule);
    };

    openLocationById = async (devicesLocationsTree, locationId) => {
        const { strings } = this.props;

        if (!locationId) {
            return;
        }

        let [locationsStatus, locationsDb] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locationsDb?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [locationDb] = locationsDb;

        let parentIds = [...locationDb.parentIds.slice(1), locationId];
        let locationNode;

        let tree = updateNodes(devicesLocationsTree, "preOpen", true);

        for (const parentId of parentIds) {
            locationNode = tree.find((i) => i.id === parentId);

            locationNode.isClosed = false;

            if (!locationNode) {
                this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                    variant: "error",
                });
                return;
            }

            locationNode.preOpen = true;

            await this.openLocation(locationNode, this.props.generalInfo);

            tree = locationNode.children;
        }

        this.setState({ devicesLocationsTree: devicesLocationsTree });
    };

    activateDesktop = async (desktop) => {
        const { strings } = this.props;

        const frames = {};
        if (desktop.cameras) {
            for (let index in desktop.cameras) {
                const [getDeviceStatus, devices] = await GetDeviceInfo([desktop.cameras[index]]);
                if (getDeviceStatus && devices?.length) {
                    frames[`${index}`] = devices[0];
                } else {
                    this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                }
            }
        }
        this.props.dispatch(saveActiveDesktop(desktop));

        this.setState({ frames });
    };

    openLocation = async (locationNode, generalInfo) => {
        const { strings } = this.props;

        const { devicesLocationsTree } = this.state;
        const adapters = getAdapters(generalInfo);
        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.stream");
        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.props.enqueueSnackbar(strings("Не найдено адаптеров поддерживающих стрим"), { variant: "warning" });
            this.setState({ devicesLocationsTree });
            return;
        }

        const [getDevicesStatus, devices] = await GetDevices(locationNode.id, deviceTypeIds);

        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);

        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, devices);
        }

        this.setState({
            devicesLocationsTree,
        });
    };

    framesToDesktopCameras = (frames) => {
        let cameras = {};

        for (const index of Object.keys(frames)) {
            cameras[index] = frames[index].id;
        }

        return cameras;
    };

    framesToArray = (frames) => {
        let deviceIds = [];

        for (const index of Object.keys(frames)) {
            deviceIds.push(frames[index].id);
        }

        return deviceIds;
    };

    setFrames = async (frames) => {
        if (Object.keys(frames).length === 0) {
            this.props.dispatch(saveFrames(frames));
            this.setState({ frames });
            return;
        }

        let {
            gallery: { dates, activeDesktop },
            strings,
        } = this.props;

        if (activeDesktop.cameras) {
            let cameras = this.framesToDesktopCameras(frames);
            if (!isDeepEqual(activeDesktop.cameras, cameras)) {
                activeDesktop = { ...initialDesktop, layout: activeDesktop.layout };
            }
        }

        let requestDates = {
            from: dates.from ? moment(dates.from).unix() : dates.from,
            to: dates.to ? moment(dates.to).unix() : dates.to,
        };

        const deviceIds = this.framesToArray(frames);

        const [screenshotsStatus, screenshotsInfo] = await SearchScreenshots(
            deviceIds,
            this.state.selectedScheduleId,
            requestDates.from,
            requestDates.to
        );

        if (!screenshotsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения скриншотов"), { variant: "error" });
            return;
        }

        const [screenshotsDatesStatus, screenshotsDates] = await GetScreenshotsDate(deviceIds);

        if (!screenshotsDatesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения времени скриншотов"), { variant: "error" });
            return;
        }

        screenshotsInfo.sort((a, b) => new Date(a.captureTimestamp) - new Date(b.captureTimestamp));

        const screenshotsDate = screenshotsInfo.map((info) => new Date(info.captureTimestamp));
        const fromDate = moment(new Date(Math.min(...screenshotsDate))).unix();

        const screenshots = screenshotsInfo.map((info) => {
            const unixTime = moment(info.captureTimestamp).unix();
            return {
                ...info,
                offset: unixTime - fromDate,
            };
        });

        this.props.dispatch(saveActiveDesktop(activeDesktop));
        this.props.dispatch(saveFrames(frames));
        this.props.dispatch(saveScreenshotsDates(screenshotsDates));
        this.props.dispatch(saveScreenshotsInfo(screenshots));
        this.setState({ frames: frames });
    };

    setCurrentLayout = (layout, framesCount) => {
        const { frames, selectedScheduleId } = this.state;
        let {
            gallery: { screenshotsInfo, activeDesktop, selectedCameraFrame },
        } = this.props;

        if (activeDesktop.layout !== layout) {
            this.props.dispatch(saveActiveDesktop({ ...initialDesktop, layout }));
            this.props.dispatch(saveFramesCount(framesCount));

            if (!selectedScheduleId) {
                let nextFrames;

                if (layout === "1") {
                    nextFrames = { 0: frames[selectedCameraFrame] };
                } else {
                    nextFrames = { ...frames };
                    Object.keys(nextFrames).forEach((key) => {
                        if (key >= framesCount) {
                            delete nextFrames[key];
                        }
                    });
                }

                const frameIds = Object.values(nextFrames).map((f) => f.id);
                const updatedScreenshotInfo = screenshotsInfo.filter((s) => frameIds.includes(s.deviceId));

                this.props.dispatch(saveScreenshotsInfo(updatedScreenshotInfo));
                this.props.dispatch(saveFrames(nextFrames));
                this.props.dispatch(
                    saveDates({
                        from: undefined,
                        to: undefined,
                    })
                );

                this.setState({ frames: nextFrames });
            }
        }
    };

    setSelectedCameraFrame = (selectedCameraFrame) => {
        this.props.dispatch(saveSelectedCameraFrame(selectedCameraFrame));
    };

    setShowCameraInfo = (show) => {
        this.props.dispatch(saveShowCameraInfo(show));
    };

    setSearch = async (like, generalInfo) => {
        const { strings } = this.props;

        let adapters = getAdapters(generalInfo);
        if (!adapters) {
            this.props.enqueueSnackbar(strings("Ошибка получения адаптеров"), { variant: "error" });
            return;
        }

        let deviceTypeIds = getDeviceTypesByFeature(adapters, "video.stream");
        if (!deviceTypeIds || deviceTypeIds.length === 0) {
            this.props.enqueueSnackbar(strings("Не найдено адаптеров поддерживающих стрим"), { variant: "warning" });
            this.setState({ search: like, searchDevices: [] });
            return;
        }

        const [devicesStatus, searchDevices] = await SearchDevices({
            like,
            limit: DEVICES_LIMIT,
            deviceTypes: deviceTypeIds,
        });
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        this.setState({ search: like, searchDevices: searchDevices?.map((d) => ({ ...d, tag: "device" })) });
    };

    setScheduleSearch = async (like, generalInfo) => {
        const filter = {
            generalInfo: generalInfo,
            like: like,
        };

        this.props.dispatch(
            fetchSchedules({ filter, enqueueSnackbar: this.props.enqueueSnackbar, strings: this.props.strings })
        );
    };

    setDates = async (dates) => {
        const { strings } = this.props;
        const { frames, selectedScheduleId } = this.state;
        const deviceIds = this.framesToArray(frames);

        const [screenshotsStatus, screenshotsInfo] = await SearchScreenshots(
            deviceIds,
            selectedScheduleId,
            moment(dates.from).unix(),
            moment(dates.to).unix()
        );

        if (!screenshotsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения скриншотов"), { variant: "error" });
            return;
        }

        screenshotsInfo.sort((a, b) => new Date(a.captureTimestamp) - new Date(b.captureTimestamp));

        const screenshotsDate = screenshotsInfo.map((info) => new Date(info.captureTimestamp));
        const fromDate = moment(new Date(Math.min(...screenshotsDate))).unix();

        const screenshots = screenshotsInfo.map((info) => {
            const unixTime = moment(info.captureTimestamp).unix();
            return {
                ...info,
                offset: unixTime - fromDate,
            };
        });

        this.props.dispatch(saveScreenshotsInfo(screenshots));
        this.props.dispatch(saveDates(dates));
    };

    createCameraForArchiveOrder = (frame) => {
        return {
            id: frame.id,
            locationId: frame.locationId,
            name: frame.name,
            settings: frame.settings,
            typeId: frame.typeId,
        };
    };

    selectSchedule = (schedule) => {
        const { selectedScheduleId } = this.state;
        const { enqueueSnackbar } = this.props;

        if (!selectedScheduleId || selectedScheduleId !== schedule.id) {
            this.props.dispatch(
                fetchSchedule({
                    schedule,
                    enqueueSnackbar,
                })
            );
        } else {
            this.props.dispatch(resetScheduleInfo());
        }
    };

    render() {
        const { userInfo } = this.props;
        const {
            devicesLocationsTree,
            searchDevices,
            search,
            frames,
            updateTreeId,
            showOrderList,
            showNewOrderModal,
            selectedScheduleId,
            scheduleSearch,
        } = this.state;
        let {
            gallery: {
                activeDesktop,
                screenshotsDates,
                screenshotsInfo,
                schedules,
                selectedCameraFrame,
                showCameraInfo,
                framesCount,
            },
            strings,
        } = this.props;

        const source = search ? searchDevices : devicesLocationsTree;

        return (
            <LayoutPage>
                <GeneralContextConsumer>
                    {(generalInfo) => (
                        <>
                            <Filters
                                key={updateTreeId}
                                id={nanoid()}
                                search={search}
                                setSearch={(like) => this.setSearch(like, generalInfo)}
                                frames={frames}
                                setFrames={this.setFrames}
                                selectedCameraFrame={selectedCameraFrame}
                                setSelectedCameraFrame={this.setSelectedCameraFrame}
                                currentLayout={activeDesktop.layout}
                                devicesLocationsTree={source ?? []}
                                openLocation={(location) => this.openLocation(location, generalInfo)}
                                strings={strings}
                                schedules={schedules}
                                selectedScheduleId={selectedScheduleId}
                                scheduleSearch={scheduleSearch}
                                setScheduleSearch={(like) => this.setScheduleSearch(like, generalInfo)}
                                selectSchedule={this.selectSchedule}
                            />

                            {showNewOrderModal === true &&
                            anyPermissionExists(userInfo, [
                                "screenshot.archive.edit.personal",
                                "screenshot.archive.edit.group",
                                "screenshot.archive.edit.group.all",
                                "screenshot.archive.edit.system",
                            ]) ? (
                                <OrderArchiveModal
                                    selectedCameras={Object.values(this.state.frames).map(
                                        this.createCameraForArchiveOrder
                                    )}
                                    devicesLocationsTree={devicesLocationsTree}
                                    handleClose={() => this.setState({ showNewOrderModal: false })}
                                    generalInfo={generalInfo}
                                    userInfo={userInfo}
                                />
                            ) : (
                                <div />
                            )}
                            {showOrderList === true &&
                            anyPermissionExists(userInfo, [
                                "screenshot.archive.view.personal",
                                "screenshot.archive.view.group",
                                "screenshot.archive.view.system",
                            ]) ? (
                                <ArchiveOrderListModal
                                    archiveOrderList={[]}
                                    handleClose={() => this.setState({ showOrderList: false })}
                                    generalInfo={generalInfo}
                                    userInfo={userInfo}
                                />
                            ) : (
                                <div />
                            )}
                        </>
                    )}
                </GeneralContextConsumer>
                <LayoutMain>
                    <Toolbar
                        screenshotsDates={screenshotsDates}
                        frames={frames}
                        showCameraInfo={showCameraInfo}
                        setShowCameraInfo={this.setShowCameraInfo}
                        setFrames={this.setFrames}
                        activeDesktop={activeDesktop}
                        setCurrentLayout={this.setCurrentLayout}
                        selectedCameraFrame={selectedCameraFrame}
                        userInfo={userInfo}
                        setDates={this.setDates}
                        dates={this.props.gallery.dates}
                        strings={strings}
                        openOrderList={() => this.setState({ showOrderList: true })}
                        handleOrderRecordModal={() => this.setState({ showNewOrderModal: true })}
                    />
                    <CameraFrames
                        screenshotsInfo={screenshotsInfo}
                        showCameraInfo={showCameraInfo}
                        selectedCameraFrame={selectedCameraFrame}
                        setSelectedCameraFrame={this.setSelectedCameraFrame}
                        frames={frames}
                        setFrames={this.setFrames}
                        currentLayout={activeDesktop.layout}
                        strings={strings}
                        selectedScheduleId={selectedScheduleId}
                        framesCount={framesCount}
                    />
                </LayoutMain>
            </LayoutPage>
        );
    }
}

class GalleryWrapper extends React.Component {
    render() {
        return (
            <GeneralContextConsumer>
                {(generalInfo) => React.createElement(Gallery, { ...this.props, generalInfo })}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        gallery: state.gallery,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(withRouter(GalleryWrapper))));

import styled from "styled-components";

export const StreamToolbar = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary1};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 12px 16px;
    height: 56px;
`;

export const StreamToolbarPrimary = styled.div`
    display: flex;
    align-items: center;
`;

export const StreamToolbarSecondary = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

export const StreamToolbarItem = styled.div`
    display: flex;

    & + & {
        margin-left: 16px;
    }
`;

export const StreamToolbarInnerItem = styled.div`
    & + & {
        margin-left: 8px;
    }
`;

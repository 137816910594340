import { DevicesSettings } from "./devicesSettings";
import { DevicesGroupsSettings } from "./devicesGroupsSettings";
import { RolesSettings } from "./rolesSettings";
import { UsersSettings } from "./usersSettings";
import { AdaptersSettings } from "./adaptersSettings";
import { EventTypesSettings } from "./eventTypesSettings";
import { ScenarioTemplatesSettings } from "./scenarioTemplatesSettings";
import { ScenariosSettings } from "./scenariosSettings";
import { PlansSettings } from "./plansSettings";
import { ServicesSettings } from "./servicesSettings";
import { DeviceTypesSettings } from "./deviceTypes";
import { SystemVariablesSettings } from "./systemVariablesSettings";
import { DeviceTagsSettings } from "./deviceTagsSettings";
import LicenseSettings from "./licenseSettings/LicenseSettings";
import { AvailabilityControlSettings } from "./availabilityControlSettings";
import { RtspQualityControlSettings } from "./rtspQualityControlSettings";
import ControlZonesSettings from "./controlZonesSettings/ControlZonesSettings";

export const pages = [
    {
        icon: "device",
        label: "Оборудование",
        servicesNames: ["service.api.devices", "service.api.locations"],
        url: "/settings/devices",
        permissions: ["devices.device.read", "devices.deviceGroup.read"],
        control: DevicesSettings,
    },
    {
        label: "Группы оборудования",
        servicesNames: ["service.api.devices"],
        url: "/settings/devices-groups",
        permissions: ["devices.deviceGroup.read"],
        control: DevicesGroupsSettings,
    },
    {
        label: "Типы оборудования",
        servicesNames: ["service.api.devices"],
        url: "/settings/device-types",
        permissions: ["devices.device.read"],
        control: DeviceTypesSettings,
    },
    {
        label: "Теги оборудования",
        servicesNames: ["service.api.tags", "service.api.devices"],
        url: "/settings/tags",
        permissions: ["devices.device.read", "devices.device.lifeCycle"],
        control: DeviceTagsSettings,
    },
    {
        type: "nested",
        label: "Проверка устройств",
        icon: "arrow-down",
        pages: [
            {
                label: "Проверка доступности",
                servicesNames: ["service.devices.availability"],
                url: "/settings/availability-control",
                permissions: ["devices.availability.read"],
                control: AvailabilityControlSettings,
            },
            {
                label: "Проверка качества видеоизображений",
                servicesNames: ["service.rtsp.quality"],
                url: "/settings/rtsp-quality-control",
                permissions: ["rtsp.quality.read"],
                control: RtspQualityControlSettings,
            },
        ],
        closed: true,
    },
    {
        label: "Пользователи",
        servicesNames: ["service.api.users", "service.api.locations", "service.api.roles"],
        url: "/settings/users",
        permissions: ["users.user.read", "users.userGroup.read"],
        control: UsersSettings,
    },
    {
        label: "Роли",
        servicesNames: ["service.api.roles"],
        url: "/settings/roles",
        permissions: ["roles.roleGroup.read", "roles.role.read"],
        control: RolesSettings,
    },
    {
        label: "Адаптеры",
        servicesNames: "alwaysAllow",
        url: "/settings/adapters",
        permissions: ["adapters.read"],
        control: AdaptersSettings,
    },
    {
        label: "Зоны контроля",
        servicesNames: ["service.api.controlZones"],
        url: "/settings/control-zones",
        permissions: ["controlZoneGroups.read", "controlZone.read"],
        control: ControlZonesSettings,
    },
    {
        label: "Типы событий",
        servicesNames: ["service.api.events"],
        url: "/settings/event-types",
        permissions: ["events.types.read"],
        control: EventTypesSettings,
    },
    {
        label: "Шаблоны реагирования",
        servicesNames: ["service.api.scenarios"],
        url: "/settings/scenario-templates",
        permissions: ["scenarios.service.access", "scenarios.template.read"],
        control: ScenarioTemplatesSettings,
    },
    {
        label: "Сценарии реагирования",
        servicesNames: ["service.api.scenarios"],
        url: "/settings/scenario",
        permissions: ["scenarios.service.access"],
        control: ScenariosSettings,
    },
    {
        label: "Планы",
        servicesNames: ["service.api.plans", "service.api.locations", "service.api.devices", "service.api.tiles"],
        url: "/settings/plans",
        permissions: ["plans.service.access"],
        control: PlansSettings,
    },
    {
        label: "Активные сервисы",
        servicesNames: "alwaysAllow",
        url: "/settings/services",
        control: ServicesSettings,
    },
    {
        label: "Системные переменные",
        servicesNames: "service.api.variables",
        url: "/settings/system",
        permissions: ["variables.variable.read"],
        control: SystemVariablesSettings,
    },
    {
        label: "Лицензии",
        servicesNames: "service.license",
        url: "/settings/licenses",
        permissions: ["settings.licenses.access"],
        control: LicenseSettings,
    },
];

import React from "react";
import { LayoutMain } from "../../../layout/MainLayout";
import styled from "styled-components";
import { MapContainer, TileLayer } from "react-leaflet";
import { EventsLayout, EventsLayoutPrimary } from "../../../layout/EventsLayout";
import { GeneralContextConsumer, getSystemVariableValue } from "../../../contexts";
import DeviceDetails from "./DeviceDetails/DeviceDetails";
import PlanPoints from "./PlanPoints";
import PubSub from "pubsub-js";
import { SHOW_DETAILS_TOPIC, FREEZE_PLAN_TOPIC, LOAD_CLUSTER_VIEW_TOPIC } from "./plansTopics";
import { point, featureCollection, clustersDbscan, clusterReduce, centroid } from "@turf/turf";
import { OPEN_CLUSTER_TOPIC } from "./plansTopics";
import { nanoid } from "nanoid";
import { GetPoints } from "../../../services/plans";
import { withSnackbar } from "notistack";
import { GET_DEVICE_EVENTS_PLANS } from "./plansTopics";
import { GetEventsByDevices } from "../../../services/events";
import moment from "moment";
import { saveSelected } from "../../../app/reducers/planReducer";
import { connect } from "react-redux";

const CLUSTER_RADIUS = 32;
const OPEN_CLUSTER_COUNT = 12;

const PlanControlsWrapper = styled.div`
    position: absolute;
    left: 12px;
    bottom: 8px;
    z-index: 2;
    display: flex;

    & > * + * {
        margin-left: 8px;
    }
`;

class PlanComponent extends React.Component {
    constructor(props) {
        super(props);
        this.mapTileRef = React.createRef();
        this.mapRef = React.createRef();
        this.state = {
            isMultipleMode: false,
            selectedDevice: null,
            points: [],
            clusters: [],
        };
    }

    componentDidUpdate(prevProps, _) {
        const { plan, points } = this.props;

        if (this.mapRef._layersMaxZoom) {
            this.mapRef._layersMaxZoom = plan.maxZoom;
        }

        if (plan?.id && plan?.id !== prevProps.plan?.id) {
            const url = window.location.origin + "/plans-api/tiles/" + plan?.id + "/{z}/{x}/{y}.png";
            this.mapTileRef.current.setUrl(url);
            this.mapRef?.setView([0, 0], 0);
        }

        if (points !== prevProps.points) {
            this.getAllMapObjects();
        }
    }

    componentDidMount = async () => {
        this.showDetailsTopic = PubSub.subscribe(SHOW_DETAILS_TOPIC, async (msg, selectedDevice) => {
            this.setState({ selectedDevice });
            this.props.dispatch(saveSelected(selectedDevice));

            this.mapRef?.invalidateSize();
            this.updateCenter(selectedDevice?.id);
        });

        this.zoneEditiongHandler = PubSub.subscribe(FREEZE_PLAN_TOPIC, async (msg, start) => {
            if (start) {
                this.mapRef?.dragging?.disable();
                this.mapRef?.scrollWheelZoom?.disable();
            } else {
                this.mapRef?.dragging?.enable();
                this.mapRef?.scrollWheelZoom?.enable();
            }
        });

        this.openClusterTopic = PubSub.subscribe(OPEN_CLUSTER_TOPIC, async (msg, data) => {
            const { strings, plan } = this.props;
            const { clusters } = this.state;

            if (data.id === null || data.id === undefined || !clusters?.length) {
                return;
            }

            const cluster = clusters.find(({ id }) => data.id === id);
            if (cluster.points?.length > OPEN_CLUSTER_COUNT && this.mapRef._zoom < this.mapRef._layersMaxZoom) {
                this.mapRef?.setView([cluster.x, cluster.y], (this.mapRef._zoom ?? 0) + 1);
                return;
            }

            if (data.isOpen) {
                const [pointsDevicesStatus, pointsDevices] = await GetPoints(plan?.id, {
                    deviceIds: cluster.points?.map((p) => p.id),
                });

                if (pointsDevicesStatus) {
                    cluster.points = pointsDevices;
                    cluster.isOpen = data.isOpen;
                    PubSub.publish(LOAD_CLUSTER_VIEW_TOPIC, cluster);
                } else {
                    this.props.enqueueSnackbar(strings("Ошибка получения объектов"), { variant: "error" });
                }
            } else {
                cluster.isOpen = false;
            }

            this.setState({ clusters: [...clusters] });
        });

        this.refreshEvents = PubSub.subscribe(GET_DEVICE_EVENTS_PLANS, (msg, data) => {
            const { points, clusters } = this.state;
            void this.getEvents(points, clusters, data?.eventsFilter, data?.eventTypesFilter, data?.intervalFilter);
        });
    };

    componentWillUnmount() {
        if (this.showDetailsTopic) {
            PubSub.unsubscribe(this.showDetailsTopic);
        }

        if (this.zoneEditiongHandler) {
            PubSub.unsubscribe(this.showDetailsTopic);
        }

        if (this.openClusterTopic) {
            PubSub.unsubscribe(this.openClusterTopic);
        }

        if (this.refreshEvents) {
            PubSub.unsubscribe(this.refreshEvents);
        }
    }

    async getEvents(points, clusters, eventsFilter, eventTypesFilter, intervalFilter) {
        const deviceIds = (points.map((p) => p.id) ?? []).concat(
            (clusters ?? []).map((c) => c.points.map((p) => p.id)).flat()
        );
        const filter = this.createFilter(eventsFilter, deviceIds, eventTypesFilter, intervalFilter);
        const [success, events] = await GetEventsByDevices(filter);

        if (success) {
            this.setState(events);

            points = points.map((p) => ({
                ...p,
                severity: (p.severity = events.find(({ id }) => id === p.id)?.severity),
            }));

            clusters = clusters?.map((c) => ({
                ...c,
                events: (c.events = this.getClusterEvents(c, events)),
            }));

            this.setState({ points });

            if (clusters) {
                this.setState({ clusters });
            }
        } else {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Не удалось получить события"), {
                variant: "warning",
            });

            this.setState({ points });

            if (clusters) {
                this.setState({ clusters });
            }
        }
    }

    getClusterEvents(cluster, events) {
        let clusterEvents = [];

        events.forEach((e) => {
            if (cluster.points.includes(e.id)) {
                clusterEvents.push(e);
            }
        });

        return clusterEvents;
    }

    createFilter(eventsFilter, deviceIds, eventTypes, intervalFilter) {
        const showRead = eventsFilter?.showRead;
        const severity = [];

        eventsFilter?.showDanger && severity.push("danger");
        eventsFilter?.showWarning && severity.push("warning");
        let result = { severity, showRead, deviceIds };

        if (eventTypes?.length) {
            result = { ...result, eventTypes };
        }

        switch (intervalFilter) {
            case "last":
                const interval = getSystemVariableValue(
                    this.props.generalInfo,
                    "service.api.plans",
                    "plan.events.interval",
                    60
                );

                result = { ...result, from: moment().utc().add(-interval, "minutes") };
                break;

            case "new":
                const partitionsCloseTimeStr = window.localStorage.getItem("partitionsCloseTime");
                const partitionsCloseTime = JSON.parse(partitionsCloseTimeStr ?? "{}");
                const from = partitionsCloseTime?.["/plans"];
                result = from ? { ...result, from } : result;
                break;

            default:
                break;
        }

        return result;
    }

    updateCenter(deviceId) {
        const { points } = this.props;

        if (!deviceId) {
            return;
        }

        const devicePoint = points.find((p) => p.id === deviceId);
        if (!devicePoint) {
            return;
        }

        const viewCenter = [devicePoint.y, devicePoint.x];

        this.mapRef?.invalidateSize();
        this.mapRef?.setView(viewCenter);
    }

    getEpsilon = () => {
        if (!this.mapRef?.getBounds) {
            return CLUSTER_RADIUS;
        }

        const bounds = this.mapRef?.getBounds();
        const xScale = Math.min(bounds._southWest.lng - bounds._northEast.lng, 360) / this.mapRef?._size.x;
        const yScale = (bounds._southWest.lat - bounds._northEast.lat) / this.mapRef?._size.y;

        return Math.max(Math.abs(xScale), Math.abs(yScale)) * CLUSTER_RADIUS;
    };

    getAllMapObjects = () => {
        let { points, isClustering, eventsFilter, eventTypesFilter, intervalFilter } = this.props;
        if (!isClustering) {
            this.setState({ points, clusters: [] });
            return;
        }

        let clustered = clustersDbscan(
            featureCollection(points?.map((p) => point([(p.x ?? 0) / 2, (p.y ?? 0) / 2], p)) ?? []),
            this.getEpsilon() / 2,
            {
                minPoints: 2,
                units: "degrees",
            }
        );

        let newPoints = [...points];
        let clusters = clusterReduce(
            clustered,
            "cluster",
            (clusters, cluster) => {
                newPoints = newPoints?.filter((p) => !cluster.features?.some((f) => f.properties.id === p.id));
                const cent = centroid(cluster);
                return [
                    ...clusters,
                    {
                        ...cluster,
                        id: nanoid(),
                        points: points?.filter((p) => cluster.features?.some((f) => f.properties.id === p.id)),
                        y: (cent?.geometry?.coordinates?.[1] ?? 0) * 2,
                        x: (cent?.geometry?.coordinates?.[0] ?? 0) * 2,
                    },
                ];
            },
            []
        );

        void this.getEvents(newPoints, clusters, eventsFilter, eventTypesFilter, intervalFilter);
    };

    render() {
        const { onDrag, plan, eventsFilter, strings } = this.props;
        const { selectedDevice, points, clusters } = this.state;

        return (
            <LayoutMain noPadding hasSecondary={!!selectedDevice}>
                <EventsLayout style={{ minHeight: "800px" }}>
                    <GeneralContextConsumer>
                        {(generalContext) => {
                            return (
                                <>
                                    <EventsLayoutPrimary
                                        noPadding
                                        style={{ zIndex: 1 }}
                                        hasSecondary={!!selectedDevice}
                                    >
                                        <MapContainer
                                            whenCreated={(mapInstance) => {
                                                this.mapRef = mapInstance;
                                                this.getAllMapObjects();

                                                mapInstance.on("zoomend", () => {
                                                    this.getAllMapObjects();
                                                });
                                            }}
                                            center={[0, 0]}
                                            zoom={0}
                                            scrollWheelZoom={true}
                                            doubleClickZoom={false}
                                        >
                                            <TileLayer
                                                ref={this.mapTileRef}
                                                url={
                                                    window.location.origin +
                                                    "/plans-api/tiles/" +
                                                    plan?.id +
                                                    "/{z}/{x}/{y}.png"
                                                }
                                                noWrap={true}
                                            />
                                            <PlanPoints
                                                points={points}
                                                clusters={clusters}
                                                generalContext={generalContext}
                                                onDrag={
                                                    onDrag
                                                        ? async (p, pos) => {
                                                              await onDrag(p, pos);
                                                              this.getAllMapObjects();
                                                          }
                                                        : null
                                                }
                                                plan={plan}
                                                selected={selectedDevice}
                                                eventsFilter={eventsFilter}
                                                strings={strings}
                                            />
                                        </MapContainer>
                                    </EventsLayoutPrimary>
                                    {this.state.selectedDevice && (
                                        <DeviceDetails
                                            device={this.state.selectedDevice}
                                            generalContext={generalContext}
                                            setSelected={() => {
                                                PubSub.publish(SHOW_DETAILS_TOPIC, undefined);
                                                PubSub.publish(FREEZE_PLAN_TOPIC, false);
                                            }}
                                            selected={selectedDevice}
                                            strings={strings}
                                        />
                                    )}
                                </>
                            );
                        }}
                    </GeneralContextConsumer>
                    <PlanControlsWrapper></PlanControlsWrapper>
                </EventsLayout>
            </LayoutMain>
        );
    }
}

export default connect()(withSnackbar(PlanComponent));

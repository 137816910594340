import React from "react";
import { filesize } from "filesize";
import {
    Button,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Text,
} from "headpoint-react-components";
import { ModalOverall } from "../../../components/ModalOverall";

export default class LargeFilesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentFileIndex: 0 };
    }

    formatFileSize(size) {
        const { strings } = this.props;

        return filesize(size, {
            symbols: { B: strings("Б"), KB: strings("КБ"), MB: strings("МБ") },
            base: 2,
            standard: "jedec",
        });
    }

    isOnLastFile = () => {
        return this.state.currentFileIndex === this.props.largeFiles.length - 1;
    };

    onApproved = (e) => {
        if (this.isOnLastFile()) {
            this.props.onLastApproved(e);
        } else {
            this.setState((prevState) => ({ currentFileIndex: prevState.currentFileIndex + 1 }));
        }
    };

    render() {
        const { largeFiles, strings } = this.props;
        const { currentFileIndex } = this.state;

        return (
            <ModalOverall size="sm" closeHandler={this.props.onNotApproved}>
                <ModalHeader title={strings("Предупреждение")} closeHandler={this.props.onNotApproved} />
                <form onSubmit={this.onApproved}>
                    <ModalBody>
                        <ModalBodyItem>
                            <Text color="secondary" variant="body-sm">
                                {`${strings("Вы пытаетесь сохранить файл размером")} ${this.formatFileSize(
                                    largeFiles[currentFileIndex].size
                                )}.`}
                            </Text>
                            <br />
                            <Text color="secondary" variant="body-sm">
                                {strings("Вы уверены?")}
                            </Text>
                        </ModalBodyItem>
                    </ModalBody>

                    <ModalFooter>
                        <ModalFooterItem>
                            <Button type="button" variant="primary" label={strings("Да")} onClick={this.onApproved} />
                        </ModalFooterItem>

                        <ModalFooterItem>
                            <Button
                                type="button"
                                variant="ghost"
                                label={strings("Нет")}
                                onClick={this.props.onNotApproved}
                            />
                        </ModalFooterItem>
                    </ModalFooter>
                </form>
            </ModalOverall>
        );
    }
}

import React from "react";
import styled from "styled-components";
import { Modal, Button, Icon } from "headpoint-react-components";

const LoginAboutModal = (props) => {
    const handleClose = () => {
        props.Close();
    };

    const { strings } = props;

    return (
        <Modal size="lg" closeHandler={handleClose}>
            <AboutModalHeader>
                <CloseButton type="button" variant="secondary" icon="close" onClick={handleClose} />

                <HeaderTitle>{strings("О платформе InOne")}</HeaderTitle>

                <HeaderBody>
                    <HeaderBodyMain>
                        <p>{strings('ПО "Система ситуационного видеомониторинга"')}</p>
                        <p>{strings("Интеграционная IoT‑платформа inOne, версия 2.0")}</p>
                        <p>
                            {strings(
                                "InOne – цифровая интеграционная платформа, позволяющая эффективно решать задачи мониторинга,\n" +
                                    "                            диспетчеризации и автоматизации процессов реагирования для организаций различного масштаба,\n" +
                                    "                            имеющих территориально-распределенную структуру или большое количество подключенных\n" +
                                    "                            источников цифровых данных."
                            )}
                        </p>
                    </HeaderBodyMain>

                    <HeaderBodySide>
                        <img src="/img/minekonomsvjaz.png" alt="Минкомсвязь РФ" width="58" height="62" />
                        <p>{strings("Включена в единый реестр российских программ Минкомсвязи РФ")}</p>
                    </HeaderBodySide>
                </HeaderBody>
            </AboutModalHeader>

            <AboutModalFooter>
                <FooterLink href="https://head-point.ru/about">
                    {strings("Узнать больше о платформе на сайте Head-Point")} <Icon icon="arrow-right" />
                </FooterLink>
            </AboutModalFooter>
        </Modal>
    );
};

const AboutModalHeader = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blackText};
    padding: 40px 40px 64px 40px;
`;

const HeaderTitle = styled.h2`
    margin: 0 0 56px 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.regular};
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
`;

const CloseButton = styled(Button)`
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.75);
    border-color: rgba(13, 22, 35, 0.5);

    @media (hover: hover) {
        &:hover {
            color: rgba(0, 0, 0, 1);
            border-color: rgba(13, 22, 35, 0.75);
        }
    }
`;

const HeaderBody = styled.div`
    display: flex;
`;

const HeaderBodyMain = styled.div`
    width: 502px;

    & p {
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        margin: 0;
    }

    & p + p {
        margin-top: 26px;
    }
`;

const HeaderBodySide = styled.div`
    width: 230px;
    margin-left: auto;

    & img {
        margin-bottom: 16px;
    }

    & p {
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        line-height: 17px;
        padding: 0;
        margin: 0;
    }

    & p + p {
        margin-top: 22px;
    }
`;

const AboutModalFooter = styled.div`
    background-color: #f4f4f4;
    color: ${({ theme }) => theme.colors.blackText};
    padding: 40px 40px 48px 40px;
`;

const FooterLink = styled.a`
    display: inline-flex;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.blue};
    transition: color 0.16s linear;

    & i {
        margin-left: 4px;
    }

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.colors.blueAlt};
        }
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

export default LoginAboutModal;

import React from "react";
import {
    Button,
    Text,
    Row,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Dropdown,
    DropdownItem,
    DropdownDivider,
} from "headpoint-react-components";
import { EventsLayout, EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxContent,
    DetailsBoxMedia,
} from "../../../components/DetailsBox";
import "react-lazy-load-image-component/src/effects/blur.css";
import { AspectBox } from "../../../components/AspectBox";
import PlanDetailsMap from "./PlanDetailsMap";
import { GetPoints } from "../../../../services/plans";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class PlanDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            points: [],
        };
    }
    async componentDidMount() {
        await this.getPoints();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedPlan?.id !== prevProps.selectedPlan?.id) {
            await this.getPoints();
        }
    }

    async getPoints() {
        const { selectedPlan, strings } = this.props;
        const [pointsStatus, points] = await GetPoints(selectedPlan.id);
        if (!pointsStatus) {
            this.props.enqueueSnackbar(`${strings("Ошибка получения объектов плана")} '${selectedPlan.name}'`, {
                variant: "error",
            });
        }

        this.setState({ points });
    }

    render() {
        const { selectedPlan, editPlan, removePlan, openDevicesPlacement, strings } = this.props;
        return (
            <EventsLayoutSecondary>
                <DetailsBox>
                    <DetailsBoxHeader sticky>
                        <DetailsBoxTitle>
                            <Text color="primary" variant="title" component="h2">
                                {selectedPlan.name}
                            </Text>
                        </DetailsBoxTitle>

                        <DetailsBoxHeaderActions>
                            <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                <DropdownItem as="button" onClick={() => editPlan()}>
                                    {strings("Редактировать")}
                                </DropdownItem>
                                <DropdownDivider />
                                <DropdownItem
                                    as="button"
                                    onClick={() => removePlan(selectedPlan)}
                                    colorVariant="danger"
                                >
                                    {strings("Удалить")}
                                </DropdownItem>
                            </Dropdown>
                            <Button icon="close" variant="secondary" onClick={() => this.props.setSelectedPlan()} />
                        </DetailsBoxHeaderActions>
                    </DetailsBoxHeader>

                    <DetailsBoxContent>
                        <DetailsBoxMedia>
                            <AspectBox aspect="1:1">
                                <EventsLayout>
                                    <PlanDetailsMap plan={selectedPlan} points={this.state.points} />
                                </EventsLayout>
                            </AspectBox>
                        </DetailsBoxMedia>

                        <Table isFixed noPadding>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{strings("Идентификатор плана")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedPlan?.id ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Графическая подложка плана")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedPlan?.imageName ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Местоположение")}</TableCell>
                                    <TableCell>
                                        <Text color="primary" variant="body-sm">
                                            {selectedPlan?.locationName ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{strings("Комментарий")}</TableCell>
                                    <TableCell>
                                        <Text scroll color="primary" variant="body-sm">
                                            {selectedPlan?.properties?.comment ?? ""}
                                        </Text>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Row marginTop={24}>
                            <Button
                                elSize="md"
                                label={strings("Редактировать размещение оборудования")}
                                title={strings("Редактировать размещение оборудования")}
                                variant="secondary"
                                onClick={(selectedPlan) => openDevicesPlacement(selectedPlan)}
                            />
                        </Row>
                    </DetailsBoxContent>
                </DetailsBox>
            </EventsLayoutSecondary>
        );
    }
}

export default withCultureContext(PlanDetails);

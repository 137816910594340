export const PERSONAL_DESKTOP_TYPE = "personal";
export const GROUP_DESKTOP_TYPE = "group";
export const SYSTEM_DESKTOP_TYPE = "system";

export const PERSONAL_PRESET_TYPE = "personal";
export const GROUP_PRESET_TYPE = "group";
export const SYSTEM_PRESET_TYPE = "system";

export const PTZ_FEATURE_CODE = "video.ptz";
export const PRESETS_SERVICE_CODE = "service.api.ptz.presets";

import { clearUserInfo, saveUserInfo } from "../app/reducers/userReducer";
import { store } from "../app/store";

export const LOGIN_API = "/auth-api/auth/login";

export const LOGOUT_API = "/auth-api/auth/logout";

export const TOKEN_CHECK = "/auth-api/auth/checkauth";

export async function SignIn(login, password, licenseAgreement) {
    let body = { login: `${login}`, password: `${password}` };
    if (licenseAgreement) {
        body = { ...body, licenseAgreement: true };
    }
    let response = await window.fetch(LOGIN_API, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `*`,
        },
        body: JSON.stringify(body),
    });

    if (response.status === 200) {
        const responseBody = await response.json();
        if (responseBody.code === "Ok") {
            let token = responseBody.token;
            window.localStorage.setItem("token", token);
            if (responseBody.info) {
                store.dispatch(saveUserInfo(responseBody.info));
            }

            return {
                token: responseBody.token,
                code: responseBody.code,
                status: response.status,
            };
        }

        return {
            code: responseBody.code,
            status: response.status,
        };
    }

    return {
        status: response.status,
    };
}

export async function CheckToken(token, setUserInfo) {
    try {
        if (!token) {
            return false;
        }

        let response = await fetch(TOKEN_CHECK, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": `*`,
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.statusText === "OK") {
            const responseBody = await response.json();

            if (setUserInfo) {
                setUserInfo(responseBody.info);
            }
            return true;
        }
    } catch {
        // ignore
    }

    return false;
}

export const SignOut = () => {
    const token = window.localStorage.getItem("token");
    try {
        window.fetch(LOGOUT_API, {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": `*`,
                Authorization: `Bearer ${token}`,
            },
        });
    } finally {
        ClearUserInfo();
    }
};

export const ClearUserInfo = () => {
    store.dispatch(clearUserInfo());
    window.localStorage.removeItem("token");
};

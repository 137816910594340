import React from "react";
import { GeneralContextConsumer, permissionExists, serviceExists } from "../../../contexts";
import Desktops from "./Desktops";
import { Tab, Icon, Text, Dropdown, DropdownItem } from "headpoint-react-components";
import {
    StreamToolbar,
    StreamToolbarSecondary,
    StreamToolbarItem,
    StreamToolbarInnerItem,
    StreamToolbarPrimary,
} from "../../components/StreamToolbar";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";

class Toolbar extends React.Component {
    getFrameCount(layout) {
        let count;

        switch (layout) {
            case "1":
                count = 1;
                break;

            case "2":
                count = 2;
                break;

            case "4":
                count = 4;
                break;

            case "1-5":
                count = 6;
                break;

            case "1-7":
                count = 8;
                break;

            case "16":
                count = 16;
                break;

            case "32":
                count = 32;
                break;

            default:
                count = 1;
        }

        return count;
    }

    handleLayout(layout) {
        const { setCurrentLayout } = this.props;

        if (setCurrentLayout) {
            setCurrentLayout(layout, this.getFrameCount(layout));
        }
    }

    render() {
        const { userInfo, frames, setFrames, activeDesktop, showCameraInfo, setShowCameraInfo } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <StreamToolbar>
                        <StreamToolbarPrimary>
                            <GeneralContextConsumer>
                                {(generalInfo) =>
                                    serviceExists(generalInfo, "service.api.desktops") &&
                                    permissionExists(userInfo, "desktops.access") && (
                                        <Desktops frames={frames} activeDesktop={activeDesktop} />
                                    )
                                }
                            </GeneralContextConsumer>
                        </StreamToolbarPrimary>

                        <StreamToolbarSecondary>
                            <StreamToolbarItem>
                                <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                    {showCameraInfo ? (
                                        <DropdownItem as="button" onClick={() => setShowCameraInfo(false)}>
                                            {strings("Скрыть информацию о камерах")}
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem as="button" onClick={() => setShowCameraInfo(true)}>
                                            {strings("Показать информацию о камерах")}
                                        </DropdownItem>
                                    )}
                                    <DropdownItem
                                        as="button"
                                        onClick={() => {
                                            if (setFrames) {
                                                setFrames({});
                                            }
                                        }}
                                    >
                                        {strings("Очистить рабочий стол")}
                                    </DropdownItem>
                                </Dropdown>
                            </StreamToolbarItem>
                            <StreamToolbarItem>
                                {["1", "2", "4", "1-5", "1-7", "16", "32"].map((tab) => (
                                    <StreamToolbarInnerItem key={tab}>
                                        <Tab
                                            label={
                                                tab === "32" ? (
                                                    <Text>32</Text>
                                                ) : (
                                                    <Icon icon={tab === "1" ? "window" : `windows-${tab}`} size={24} />
                                                )
                                            }
                                            iconTab
                                            isActive={activeDesktop?.layout === tab}
                                            onClick={() => this.handleLayout(tab)}
                                        />
                                    </StreamToolbarInnerItem>
                                ))}
                            </StreamToolbarItem>
                        </StreamToolbarSecondary>
                    </StreamToolbar>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(Toolbar);

import React, { createRef } from "react";
import { EventsLayoutSecondary } from "../../../../layout/EventsLayout";
import { DetailsTabProperties } from "./DetailsTabProperties";
import DetailsTabInfo from "./DetailsTabInfo";
import DetailsTabOnPlan from "./DetailsTabOnPlan";
import {
    checkFeatureByDeviceType,
    GeneralContextConsumer,
    getAdapters,
    getDeviceTypes,
    getEventTypes,
    permissionExists,
    serviceExists,
    getSystemVariableValue,
} from "../../../../contexts";
import { Button, Text, Tab, TabPanel, Dropdown, DropdownItem } from "headpoint-react-components";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxSubtitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabsWrapper,
    DetailsBoxTabs,
} from "../../../components/DetailsBox";
import { pages } from "../../../../layout/Navbar/pages";
import { GetPlanByDeviceId, GetPoints } from "../../../../services/plans";
import { eventImportance } from "../constants";
import DetailsTabArchive from "./DetailsTabArchive";
import { GetDeviceInfo } from "../../../../services/devices";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import DetailsTabOnMap from "./DetailsTabOnMap";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";

class EventDetails extends React.Component {
    constructor(props) {
        super(props);
        this.detailsTabRef = createRef();
        this.state = {
            detailsTab: undefined,
            selectedPlan: undefined,
            selectedPoints: [],
            points: [],
            device: null,
            selectedEvent: null,
        };
    }

    async getPlanWithPoints() {
        const { selectedEvent } = this.props;
        const [planStatus, selectedPlan] = await GetPlanByDeviceId(selectedEvent.deviceId);

        if (planStatus) {
            const [pointsStatus, points] = await GetPoints(selectedPlan.id, { deviceIds: [selectedEvent.deviceId] });

            if (pointsStatus && points.length > 0) {
                this.setState({ points, selectedPlan });
            }
        } else {
            this.setState({ points: [], selectedPlan: undefined });
        }
    }

    async loadDevice() {
        const { selectedEvent, strings } = this.props;
        const [infoStatus, info] = await GetDeviceInfo([selectedEvent.deviceId]);

        if (!infoStatus || !info?.length) {
            this.props.enqueueSnackbar(strings("Не удалось получить устройство"), { variant: "error" });
            return;
        }

        this.setState({ device: info[0], selectedEvent });
    }

    async componentDidMount() {
        await this.getPlanWithPoints();
        await this.loadDevice();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedEvent } = this.props;

        if (selectedEvent.id !== prevProps.selectedEvent.id) {
            await this.getPlanWithPoints();
            await this.loadDevice();
        }
    }

    componentWillUnmount() {
        this.setState({ points: [], selectedPlan: undefined });
    }

    toArchive(device) {
        const archiveDelta = getSystemVariableValue(
            this.props.generalInfo,
            "service.api.events",
            "events.archive.delta",
            5
        );

        let fromDate = new Date(this.state.selectedEvent.eventDate);
        fromDate.setSeconds(fromDate.getSeconds() - archiveDelta);

        this.props.history.push({
            pathname: "/archive",
            state: {
                type: "SelectCamera",
                device,
                dates: {
                    from: fromDate,
                    to: new Date(),
                },
                captureTime: this.detailsTabRef.current?.archivePlayerRef?.current?.state?.captureTime,
            },
        });
    }

    toStream(device) {
        this.props.history.push({
            pathname: "/stream",
            state: {
                type: "SelectCamera",
                device,
            },
        });
    }

    render() {
        const { detailsTab, selectedPlan, selectedPoints, points, device, selectedEvent } = this.state;
        const { strings } = this.props;

        if (!selectedEvent) {
            return null;
        }

        const { userInfo } = this.props;

        const planPage = pages.find((p) => p.url === "/plans");
        const mapPage = pages.find((p) => p.url === "/map");
        const eventSeverity = eventImportance.find((ei) => ei.code === selectedEvent.severity);
        const eventSeverityColor = eventSeverity?.color === "info" ? "primary" : eventSeverity?.color ?? "primary";

        return (
            <EventsLayoutSecondary>
                <GeneralContextConsumer>
                    {(generalInfo) => {
                        let tabSets = [];
                        const eventType = getEventTypes(generalInfo).find((info) => info.value === selectedEvent.type);
                        if (eventType?.model?.sections?.length) {
                            eventType.model.sections.forEach((section) => {
                                if (section.key === "info" || section.key === "onPlan") {
                                    return;
                                }

                                tabSets.push({
                                    title: strings(section.name),
                                    key: section.key,
                                    fields: section.fields.map((f) => {
                                        return { ...f, name: strings(f.name) };
                                    }),
                                    media: section.media,
                                    component: DetailsTabProperties,
                                });
                            });
                        }

                        if (
                            serviceExists(generalInfo, planPage.serviceCodes) &&
                            permissionExists(userInfo, planPage.permissions) &&
                            selectedPlan
                        ) {
                            tabSets.push({
                                title: strings("На плане"),
                                key: "onPlan",
                                component: DetailsTabOnPlan,
                            });
                        }

                        if (
                            serviceExists(generalInfo, mapPage.serviceCodes) &&
                            permissionExists(userInfo, mapPage.permissions) &&
                            device?.properties?.geolocation?.latitude &&
                            device?.properties?.geolocation?.longitude
                        ) {
                            tabSets.push({
                                title: strings("На карте"),
                                key: "onMap",
                                component: DetailsTabOnMap,
                            });
                        }

                        let tab = {
                            title: strings("Общая информация"),
                            key: "info",
                            component: DetailsTabInfo,
                        };

                        if (eventType?.model?.sections?.length) {
                            const section = eventType.model.sections.find((section) => section.key === "info");
                            if (section) {
                                tab.fields = [...(section.fields ?? []), ...(tab.fields ?? [])];
                                tab.media = [...(section.media ?? []), ...(tab.media ?? [])];
                            }
                        }

                        tabSets.push(tab);

                        const adapters = getAdapters(generalInfo) ?? [];
                        const deviceType = getDeviceTypes(generalInfo).find((type) => type.value === device?.typeId);
                        const showArchive =
                            checkFeatureByDeviceType(deviceType?.value, adapters, "video.archive") &&
                            permissionExists(userInfo, "video.archive.access") &&
                            serviceExists(generalInfo, [
                                "service.api.archive",
                                "service.api.locations",
                                "service.api.devices",
                                "video.webrtc.service",
                                "video.recorder.service",
                            ]);
                        if (showArchive) {
                            tabSets.push({
                                title: strings("Архив"),
                                key: "archive",
                                component: DetailsTabArchive,
                            });
                        }

                        const tabSet = tabSets.find((s) => s.key === detailsTab) ?? tabSets?.[0];
                        const { key: actualDetailsTab, component: actualDetailsComponent } = tabSet;

                        return (
                            <DetailsBox>
                                <DetailsBoxHeader sticky>
                                    <DetailsBoxTitle>
                                        <Text color={eventSeverityColor} variant="title" component="h2">
                                            {selectedEvent.deviceName}
                                        </Text>

                                        {selectedEvent.locationId && (
                                            <DetailsBoxSubtitle>
                                                <Text variant="description" color="secondary">
                                                    {selectedEvent.locationName ?? selectedEvent.locationId}
                                                </Text>
                                            </DetailsBoxSubtitle>
                                        )}
                                    </DetailsBoxTitle>

                                    <DetailsBoxHeaderActions>
                                        {selectedEvent.isRead ? (
                                            <Text variant="secondary" color="secondary">
                                                {strings("Просмотрено")}
                                            </Text>
                                        ) : (
                                            <Dropdown
                                                toggleLabel={strings("Просмотреть")}
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                <DropdownItem
                                                    as="button"
                                                    onClick={() => this.props.readEvent(selectedEvent.id, "user")}
                                                >
                                                    {strings("Просмотреть для себя")}
                                                </DropdownItem>
                                                <DropdownItem
                                                    as="button"
                                                    onClick={() => this.props.readEvent(selectedEvent.id, "group")}
                                                >
                                                    {strings("Просмотреть для группы")}
                                                </DropdownItem>
                                                <DropdownItem
                                                    as="button"
                                                    onClick={() => this.props.readEvent(selectedEvent.id, "everyone")}
                                                >
                                                    {strings("Просмотреть для всех")}
                                                </DropdownItem>
                                            </Dropdown>
                                        )}
                                        <Dropdown
                                            closeOnClickInside
                                            toggleVariant="secondary"
                                            horizontalAlignment="right"
                                        >
                                            <DropdownItem as="button" onClick={() => this.toStream(device)}>
                                                {strings("Видеонаблюдение")}
                                            </DropdownItem>
                                            <DropdownItem as="button" onClick={() => this.toArchive(device)}>
                                                {strings("Видеоархив")}
                                            </DropdownItem>
                                        </Dropdown>
                                        <Button
                                            icon="close"
                                            variant="secondary"
                                            onClick={() => this.props.setSelectedEvent(null)}
                                        />
                                    </DetailsBoxHeaderActions>
                                </DetailsBoxHeader>

                                <DetailsBoxTabsWrapper>
                                    <DetailsBoxTabs>
                                        <TabPanel>
                                            {selectedEvent &&
                                                tabSets.map((tab) => (
                                                    <Tab
                                                        key={`${tab.key}`}
                                                        label={tab.title}
                                                        isActive={actualDetailsTab === tab.key}
                                                        onClick={() => this.setState({ detailsTab: tab.key })}
                                                    />
                                                ))}
                                        </TabPanel>
                                    </DetailsBoxTabs>
                                </DetailsBoxTabsWrapper>

                                {actualDetailsComponent &&
                                    React.createElement(actualDetailsComponent, {
                                        ...this.props,
                                        selectedEvent,
                                        tabSet,
                                        eventSeverity,
                                        eventSeverityColor,
                                        selectedPlan,
                                        selectedPoints,
                                        points,
                                        device,
                                        generalInfo,
                                        forwardRef: this.detailsTabRef,
                                    })}
                            </DetailsBox>
                        );
                    }}
                </GeneralContextConsumer>
            </EventsLayoutSecondary>
        );
    }
}

class EventDetailsWrapper extends React.Component {
    render() {
        return (
            <GeneralContextConsumer>
                {(generalInfo) => React.createElement(EventDetails, { ...this.props, generalInfo })}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps)(withCultureContext(withSnackbar(withRouter(EventDetailsWrapper))));

import React from "react";
import { LayoutMain, LayoutPage, LayoutSidebar } from "../../../layout/MainLayout";
import { EventsLayout } from "../../../layout/EventsLayout";
import EventDetails from "./EventDetails/EventDetails";
import {
    GetEvents,
    GetEvent,
    GetCount,
    ReadEvent,
    GetEventsFilters,
    DeleteEventsFilter,
    CreateEventsFilter,
    GetEventsFilter,
    UpdateEventsFilter,
} from "../../../services/events";
import { GetLocationNames } from "../../../services/locations";
import { CompareObjects } from "../../../utilites/Comparer";
import Filters from "./Filters";
import EventList from "./EventList";
import { EVENTS_ON_PAGE } from "./constants";
import { GeneralContextConsumer, getEventTypes, getSystemVariableValue, permissionExists } from "../../../contexts";
import { GetDevicesGroups } from "../../../services/devicesGroups";
import { throttle } from "throttle-debounce";
import { withSnackbar } from "notistack";
import PQueue from "p-queue";
import PubSub from "pubsub-js";
import { EVENTS_DEVICE_LOCATIONS_FILTER } from "./eventsTopics";
import { DeleteModal } from "../../settings/components/DeleteModal";
import EventsFilterModal from "./Modals/EventsFilterModal";
import { UpdateFilterModal } from "./Modals/UpdateFilterModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    resetFilter,
    saveAdapter,
    saveControlZonesFilter,
    saveCountOnPage,
    saveDeviceGroups,
    saveDevices,
    saveEventFilters,
    saveEventTypes,
    saveLocations,
    savePageNumber,
    saveSelectedFilter,
} from "../../../app/reducers/eventsReducer";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import { GetAllControlZones } from "../../../services/controlZones";

const EVENTS_TAG = "EVENTS";

const queue = new PQueue({ concurrency: 1, autoStart: true });

class Events extends React.Component {
    constructor(props) {
        super(props);

        this.throttleFilterCommand = throttle(
            1000,
            (...args) => {
                queue.add(async () => await this.loadEvents(...args));
            },
            { noLeading: false, noTrailing: false }
        );

        this.state = {
            eventTypesFilter: [],
            selectedEvent: null,
            events: [],
            count: 0,
            pageNumber: 1,
            adapterFilters: [],
            countOnPage: EVENTS_ON_PAGE,
            showDanger: false,
            showWarning: false,
            showInfo: false,
            showRead: false,
            isRead: false,
            typeSearch: "",
            devicesFilter: [],
            locationsFilter: [],
            controlZones: [],
            controlZonesFilter: [],
            deviceGroups: [],
            deviceGroupsFilter: [],
            eventsFilters: [],
            deleteFilter: undefined,
            updateFilter: undefined,
            selectedFilter: undefined,
            dates: {
                from: undefined,
                to: undefined,
            },
            timerId: null,
            showNewEventsFilter: false,
            disabled: true,
        };
    }

    async getEventsAndCount(nextPageNumber, previousPageNumber) {
        const {
            countOnPage,
            isRead,
            showDanger,
            showWarning,
            showInfo,
            showRead,
            eventTypesFilter,
            devicesFilter,
            locationsFilter,
            events,
            count,
            dates,
            adapterFilters,
            deviceGroupsFilter,
            controlZonesFilter,
        } = this.state;

        const { strings } = this.props;

        let severity = [];
        showDanger && severity.push("danger");
        showWarning && severity.push("warning");
        showInfo && severity.push("info");

        let skipNumber = 0;

        if (nextPageNumber !== undefined && previousPageNumber !== undefined) {
            skipNumber = nextPageNumber - previousPageNumber;
        }

        let markNumber = 0;

        if (skipNumber !== 0 && events && events?.length > 0) {
            markNumber = skipNumber > 0 ? events.pop().rowNumber : events[0].rowNumber;
        }

        let eventsFilter = {
            countOnPage,
            pageNumber: skipNumber,
            rowNumber: markNumber,
            severity,
            showRead,
            eventTypes: eventTypesFilter,
            deviceIds: devicesFilter?.map((d) => d.id),
            locationIds: locationsFilter?.map((l) => l.id),
            adapterFilters,
            deviceGroupIds: deviceGroupsFilter,
            controlZonesIds: controlZonesFilter,
            from: dates?.from,
            to: dates?.to,
            isRead,
        };

        const [getEventsStatus, newEvents] = await GetEvents(eventsFilter);
        if (!getEventsStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить список событий"), { variant: "error" });
            return { events, count };
        }

        const [getCountStatus, pagesCount] = await GetCount(eventsFilter);
        if (!getCountStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить количество событий"), { variant: "error" });
            return { events, count };
        }

        return { events: newEvents, count: pagesCount };
    }

    async updateLocationNames(locationIds) {
        const { generalInfo, strings } = this.props;
        const { locationNames } = this.state;
        if (!generalInfo.services.some((s) => s.code === "service.api.locations")) {
            return;
        }

        const uniqueIds = [...new Set(locationIds)].filter((id) => id !== null);

        const newIds = locationNames ? uniqueIds.filter((id) => !locationNames.has(id)) : uniqueIds;

        if (newIds.length === 0) {
            return locationNames ? locationNames : new Map();
        }

        const [status, names] = await GetLocationNames(newIds);
        if (!status) {
            this.props.enqueueSnackbar(strings("Не удалось получить локации"), { variant: "error" });
        }

        if (locationNames) {
            return new Map([...locationNames, ...names]);
        }

        return names;
    }

    async readEvent(eventId, type) {
        const { strings } = this.props;

        let [readEventStatus] = await ReadEvent(eventId, type);
        if (!readEventStatus) {
            this.props.enqueueSnackbar(strings("Не удалось просмотреть событие"), { variant: "error" });
            return;
        }

        let [getEventStatus, event] = await GetEvent(eventId);
        if (!getEventStatus) {
            this.props.enqueueSnackbar(strings("Не удалось получить обновленное событие"), { variant: "error" });
            return;
        }

        event["locationName"] = this.state.locationNames?.get(event.locationId);

        let { events } = this.state;
        const eventIndex = events.findIndex((e) => e.id === eventId);
        if (eventIndex !== -1) {
            events[eventIndex] = event;
        }

        this.setState({
            selectedEvent: event,
            events,
        });
    }

    async loadEvents(nextPageNumber, previousPageNumber) {
        const { events, count } = await this.getEventsAndCount(nextPageNumber, previousPageNumber);
        const locationNames = await this.updateLocationNames(events.map((e) => e.locationId));

        this.setState({
            events: events,
            count: count,
            locationNames: locationNames,
        });
    }

    async componentDidUpdate(_, prevState, __) {
        const {
            pageNumber,
            countOnPage,
            showDanger,
            showWarning,
            showInfo,
            showRead,
            isRead,
            dates,
            devicesFilter,
            locationsFilter,
            eventTypesFilter,
            adapterFilters,
            deviceGroupsFilter,
            controlZonesFilter,
        } = this.state;

        const compareFrom = {
            pageNumber,
            countOnPage,
            showDanger,
            showWarning,
            showInfo,
            showRead,
            isRead,
            devicesFilter,
            locationsFilter,
            dates,
            eventTypesFilter,
            adapterFilters,
            deviceGroupsFilter,
            controlZonesFilter,
        };

        if (!CompareObjects(compareFrom, prevState)) {
            void this.throttleFilterCommand(compareFrom.pageNumber, prevState.pageNumber);
        }
    }

    async componentDidMount() {
        const {
            history,
            events: { filter },
            userInfo,
            strings,
            generalInfo,
        } = this.props;

        const data = history.location.state;
        if (data) {
            history.replace({ ...history, state: undefined });
            this.openEventFromHistory(data);
            return;
        }

        if (!permissionExists(userInfo, "events.service.access")) {
            return;
        }

        const { events, count } = await this.getEventsAndCount();

        if (!generalInfo.services.some((s) => s.code === "service.api.locations")) {
            this.setState({
                events: events,
                count: count,
            });

            return;
        }

        const locationNames = await this.updateLocationNames(events ? events.map((e) => e.locationId) : []);

        const [zonesStatus, controlZones] = await GetAllControlZones();
        if (!zonesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения зон контроля"), { variant: "error" });
        }

        const [status, deviceGroups] = await GetDevicesGroups();
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
        }

        const timerId = this.createAutoUpdateTimer();

        const [filtersStatus, eventsFilters] = await GetEventsFilters(EVENTS_TAG);

        if (!filtersStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
        }

        this.setState({
            events: events,
            count: count,
            deviceGroups: deviceGroups,
            controlZones: controlZones,
            locationNames: locationNames,
            timerId,
            eventsFilters,
        });

        if (filter) {
            this.openEventFromStorage(filter);
        }
    }

    componentWillUnmount() {
        this.stopAutoUpdate();
    }

    openEventFromStorage = (filter) => {
        if (filter.devicesFilter.length) {
            PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                type: "FilterByDevices",
                data: filter.devicesFilter,
            });
        }

        if (filter.locationsFilter.length) {
            PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                type: "FilterByLocations",
                data: filter.locationsFilter,
            });
        }

        this.setState({
            devicesFilter: filter.devicesFilter,
            locationsFilter: filter.locationsFilter,
            eventTypesFilter: filter.eventTypesFilter,
            adapterFilters: filter.adapterFilters,
            controlZonesFilter: filter.controlZonesFilter,
            deviceGroupsFilter: filter.deviceGroupsFilter,
            showDanger: filter.showDanger,
            showWarning: filter.showWarning,
            showInfo: filter.showInfo,
            showRead: filter.showRead,
            selectedFilter: filter.selectedFilter,
            pageNumber: filter.pageNumber,
            countOnPage: filter.countOnPage,
            dates: filter.dates,
        });
    };

    openEventFromHistory = (data) => {
        switch (data?.type) {
            case "FilterBySingleDevice":
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterBySingleDevice",
                    data: {
                        device: data.device,
                    },
                });
                break;
            case "FilterBySingleLocation":
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterBySingleLocation",
                    data: {
                        location: data.location,
                    },
                });
                break;
            case "ShowSpecificEventCard":
                this.setState({ selectedEvent: data.event });
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterBySingleDevice",
                    data: {
                        device: data.device,
                    },
                });
                break;

            default:
                break;
        }
    };

    openEventFromHistory = (data) => {
        switch (data?.type) {
            case "FilterBySingleDevice":
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterBySingleDevice",
                    data: {
                        device: data.device,
                    },
                });
                break;
            case "FilterBySingleLocation":
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterBySingleLocation",
                    data: {
                        location: data.location,
                    },
                });
                break;
            case "ShowSpecificEventCard":
                this.setState({ selectedEvent: data.event });
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterBySingleDevice",
                    data: {
                        device: data.device,
                    },
                });
                break;

            default:
                break;
        }
    };

    stopAutoUpdate = () => {
        if (this.state.timerId) {
            clearInterval(this.state.timerId);
            this.setState({ timerId: null });
        }
    };

    startAutoUpdate = () => {
        const timerId = this.createAutoUpdateTimer();
        this.setState({ timerId });
    };

    createAutoUpdateTimer = () => {
        const timeout = getSystemVariableValue(this.props.generalInfo, "service.api.events", "events.timeout", 60);
        return setInterval(() => this.intervalHandler(), timeout * 1000);
    };

    handleShowMore = async () => {
        let { countOnPage } = this.state;

        this.props.dispatch(savePageNumber(1));
        this.props.dispatch(saveCountOnPage(countOnPage + EVENTS_ON_PAGE));

        this.setState({
            countOnPage: countOnPage + EVENTS_ON_PAGE,
            events: [],
            pageNumber: 1,
        });
    };

    async intervalHandler() {
        if (this.state.pageNumber === 1) {
            await queue.add(async () => await this.loadEvents(this.state.pageNumber, this.state.pageNumber));
        }
    }

    loadEventsFilterHandler = async (filter) => {
        const { strings } = this.props;

        const [filterStatus, eventsFilter] = await GetEventsFilter(filter.id);
        if (filterStatus) {
            this.props.dispatch(saveDevices(eventsFilter.properties.devicesFilter ?? []));
            this.props.dispatch(saveLocations(eventsFilter.properties.locationsFilter ?? []));
            this.props.dispatch(saveEventTypes(eventsFilter.properties.eventTypesFilter ?? []));
            this.props.dispatch(saveAdapter(eventsFilter.properties.adapterFilters ?? []));
            this.props.dispatch(saveDeviceGroups(eventsFilter.properties.deviceGroupsFilter ?? []));
            this.props.dispatch(saveControlZonesFilter(eventsFilter.properties.controlZonesFilter ?? []));
            this.props.dispatch(saveSelectedFilter(eventsFilter));
            this.props.dispatch(
                saveEventFilters({
                    showInfo: eventsFilter.properties.showInfo ?? false,
                    showWarning: eventsFilter.properties.showWarning ?? false,
                    showDanger: eventsFilter.properties.showDanger ?? false,
                    showRead: eventsFilter.properties.showRead ?? false,
                })
            );

            if (eventsFilter.properties?.devicesFilter?.length) {
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterByDevices",
                    data: eventsFilter.properties?.devicesFilter,
                });
            }

            if (eventsFilter.properties?.locationsFilter?.length) {
                PubSub.publish(EVENTS_DEVICE_LOCATIONS_FILTER, {
                    type: "FilterByLocations",
                    data: eventsFilter.properties?.locationsFilter,
                });
            }

            this.setState({
                devicesFilter: eventsFilter.properties.devicesFilter ?? [],
                locationsFilter: eventsFilter.properties.locationsFilter ?? [],
                eventTypesFilter: eventsFilter.properties.eventTypesFilter ?? [],
                adapterFilters: eventsFilter.properties.adapterFilters ?? [],
                deviceGroupsFilter: eventsFilter.properties.deviceGroupsFilter ?? [],
                controlZonesFilter: eventsFilter.properties.controlZonesFilter ?? [],
                showInfo: eventsFilter.properties.showInfo ?? false,
                showWarning: eventsFilter.properties.showWarning ?? false,
                showDanger: eventsFilter.properties.showDanger ?? false,
                showRead: eventsFilter.properties.showRead ?? false,
                selectedFilter: eventsFilter,
            });

            this.props.enqueueSnackbar(`${strings("Применён фильтр")} ${filter.name}`, { variant: "success" });
        } else {
            this.props.enqueueSnackbar(strings(`Не удалось загрузить фильтр`), {
                variant: "error",
            });
            return;
        }
    };

    combineDataToEventsFilter(data) {
        const {
            eventTypesFilter,
            adapterFilters,
            deviceGroupsFilter,
            showDanger,
            showWarning,
            showInfo,
            showRead,
            devicesFilter,
            locationsFilter,
            controlZonesFilter,
        } = this.state;

        return {
            name: data.name,
            description: data.comment,
            type: data.type,
            entities: data.entities,
            tag: EVENTS_TAG,
            properties: {
                devicesFilter,
                locationsFilter,
                eventTypesFilter,
                adapterFilters,
                deviceGroupsFilter,
                controlZonesFilter,
                showDanger,
                showWarning,
                showInfo,
                showRead,
            },
        };
    }

    createEventsFilterHandler = async (data) => {
        const { strings } = this.props;
        const filter = this.combineDataToEventsFilter(data);

        const [status, code] = await CreateEventsFilter(filter);
        if (code === 409) {
            this.props.enqueueSnackbar(strings(`В одном контексте фильтры событий должны иметь уникальные имена`), {
                variant: "error",
            });
            return;
        }
        if (!status) {
            this.props.enqueueSnackbar(strings(`Не удалось сохранить фильтр`), {
                variant: "error",
            });
            return;
        } else {
            this.props.enqueueSnackbar(`${strings("Фильтр")} ${filter.name} ${strings("сохранен")}`, {
                variant: "success",
            });

            const [filtersStatus, eventsFilters] = await GetEventsFilters(EVENTS_TAG);

            if (!filtersStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения фильтров событий"), { variant: "error" });
            }

            this.setState({ showNewEventsFilter: false, eventsFilters });
        }
    };

    updateEventsFilterHandler = async () => {
        const { updateFilter } = this.state;
        const { strings } = this.props;

        const filter = this.combineDataToEventsFilter(updateFilter);

        const [status] = await UpdateEventsFilter(updateFilter.id, filter);
        if (!status) {
            this.props.enqueueSnackbar(strings(`Не удалось сохранить фильтр`), {
                variant: "error",
            });
        } else {
            this.props.enqueueSnackbar(`${strings("Фильтр")} ${filter.name} ${strings("сохранен")}`, {
                variant: "success",
            });

            const [filtersStatus, eventsFilters] = await GetEventsFilters(EVENTS_TAG);

            if (!filtersStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения фильтов событий"), { variant: "error" });
            }

            this.setState({ showNewEventsFilter: false, eventsFilters });
        }
    };

    getEventsFilterHandler = async (filter) => {
        const { strings } = this.props;

        const [filterStatus, eventsFilter] = await GetEventsFilter(filter.id);
        if (!filterStatus) {
            this.props.enqueueSnackbar(strings(`Не удалось редактировать фильтр`), {
                variant: "error",
            });
        } else {
            this.setState({ showNewEventsFilter: true, updateFilter: eventsFilter });
        }
    };

    deleteEventsFilterHandler = async () => {
        const { eventsFilters, deleteFilter } = this.state;
        const { strings } = this.props;

        if (await DeleteEventsFilter(deleteFilter.id)) {
            this.setState({
                eventsFilters: eventsFilters?.filter((d) => d.id !== deleteFilter.id),
                deleteFilter: undefined,
            });
            this.props.enqueueSnackbar(`${strings("Фильтр событий")} ${deleteFilter.name} ${strings("удален")}`, {
                variant: "success",
            });
        } else {
            this.setState({ deleteFilter: undefined });
            this.props.enqueueSnackbar(strings("Не удалось удалить фильтр событий"), { variant: "error" });
        }
    };

    resetFilter = () => {
        this.props.dispatch(resetFilter());

        this.setState({
            eventTypesFilter: [],
            adapterFilters: [],
            deviceGroupsFilter: [],
            controlZonesFilter: [],
            dates: {
                from: undefined,
                to: undefined,
            },
            showInfo: false,
            showWarning: false,
            showDanger: false,
            showRead: false,
            selectedFilter: undefined,
            devicesFilter: [],
            locationsFilter: [],
        });
    };

    render() {
        let {
            count,
            pageNumber,
            countOnPage,
            events,
            showDanger,
            showWarning,
            showInfo,
            showRead,
            dates,
            selectedEvent,
            eventTypesFilter,
            adapterFilters,
            deviceGroups,
            controlZones,
            controlZonesFilter,
            deviceGroupsFilter,
            locationNames,
            timerId,
            eventsFilters,
            showNewEventsFilter,
            deleteFilter,
            updateFilter,
            selectedFilter,
            locationsFilter,
            devicesFilter,
        } = this.state;

        const { generalInfo, strings } = this.props;

        const disabled =
            showDanger ||
            showWarning ||
            showInfo ||
            showRead ||
            dates.from ||
            dates.to ||
            eventTypesFilter.length > 0 ||
            deviceGroupsFilter.length > 0 ||
            locationsFilter.length > 0 ||
            devicesFilter.length > 0 ||
            adapterFilters.length > 0 ||
            selectedFilter;

        return (
            <>
                <DeleteModal
                    visible={!!deleteFilter}
                    CloseHandler={() => this.setState({ deleteFilter: undefined })}
                    RemoveHandler={this.deleteEventsFilterHandler}
                    text={`${strings("Вы хотите удалить фильтр событий")} "${deleteFilter?.name}". ${strings(
                        "Удалённый фильтр событий нельзя восстановить. Продолжить?"
                    )}`}
                    strings={strings}
                />

                <UpdateFilterModal
                    visible={!!updateFilter}
                    CloseHandler={() => this.setState({ updateFilter: undefined })}
                    SaveHandler={this.updateEventsFilterHandler}
                    filter={updateFilter}
                    strings={strings}
                />

                <LayoutPage>
                    <LayoutSidebar>
                        <Filters
                            selectedFilter={selectedFilter}
                            defaultEventTypes={getEventTypes(generalInfo)}
                            eventTypesFilter={eventTypesFilter}
                            devicesFilter={devicesFilter}
                            locationsFilter={locationsFilter}
                            adapterFilters={adapterFilters}
                            deviceGroups={deviceGroups}
                            controlZones={controlZones}
                            controlZonesFilter={controlZonesFilter}
                            deviceGroupsFilter={deviceGroupsFilter}
                            setValue={(name, val) => this.setState({ [name]: val })}
                            showDanger={showDanger}
                            showWarning={showWarning}
                            showInfo={showInfo}
                            showRead={showRead}
                            dates={dates}
                            eventsFilters={eventsFilters}
                            dropFilter={this.resetFilter}
                            setDeviceLocationFilter={async (devicesFilter, locationsFilter) => {
                                devicesFilter && this.props.dispatch(saveDevices(devicesFilter));
                                locationsFilter && this.props.dispatch(saveLocations(locationsFilter));

                                devicesFilter && this.setState({ devicesFilter });
                                locationsFilter && this.setState({ locationsFilter });
                            }}
                            resetdeviceLocationFilter={async () => {
                                this.props.dispatch(saveDevices([]));
                                this.props.dispatch(saveLocations([]));

                                this.setState({ devicesFilter: [], locationsFilter: [] });
                            }}
                            handleOpen={() => this.setState({ showNewEventsFilter: true })}
                            handleUpdate={async (data) => {
                                this.setState({ updateFilter: data });
                            }}
                            loadFilter={this.loadEventsFilterHandler}
                            deleteFilter={(data) => {
                                this.setState({ deleteFilter: data });
                            }}
                            disabled={!disabled}
                            strings={strings}
                        />
                    </LayoutSidebar>

                    <LayoutMain>
                        <EventsLayout>
                            <EventList
                                hasSecondary={selectedEvent}
                                selectedEvent={selectedEvent}
                                timerId={timerId}
                                stopAutoUpdate={this.stopAutoUpdate}
                                startAutoUpdate={this.startAutoUpdate}
                                events={events}
                                page={pageNumber}
                                pagesCount={Math.ceil(count / countOnPage)}
                                setSelectedEvent={(e) => this.setState({ selectedEvent: e })}
                                SetValue={(name, val) => {
                                    this.props.dispatch(savePageNumber(val));
                                    this.setState({ pageNumber: val });
                                }}
                                HandleShowMore={this.handleShowMore}
                                locationNames={locationNames}
                                defaultEventTypes={getEventTypes(generalInfo)}
                                generalInfo={generalInfo}
                                strings={strings}
                            />

                            {selectedEvent && (
                                <EventDetails
                                    selectedEvent={selectedEvent}
                                    defaultEventTypes={getEventTypes(generalInfo)}
                                    readEvent={(e, type) => this.readEvent(e, type)}
                                    setSelectedEvent={(e) => this.setState({ selectedEvent: e })}
                                />
                            )}
                        </EventsLayout>
                    </LayoutMain>
                </LayoutPage>
                {showNewEventsFilter && (
                    <EventsFilterModal
                        generalInfo={generalInfo}
                        defaultEventTypes={getEventTypes(generalInfo)}
                        handleClose={() => this.setState({ showNewEventsFilter: false })}
                        createEventsFilter={async (data) => await this.createEventsFilterHandler(data)}
                    />
                )}
            </>
        );
    }
}

class EventsWrapper extends React.Component {
    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => React.createElement(Events, { ...this.props, generalInfo, strings })}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
        events: state.events,
    };
};

export default connect(mapStateToProps)(withSnackbar(withRouter(EventsWrapper)));

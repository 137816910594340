import styled from "styled-components";
import React, { useCallback, useEffect, useRef, useState } from "react";

const OnClickBottomTooltip = ({ children, tooltipBody }) => {
    const parentContainerRef = useRef();
    const tooltipContainerRef = useRef();

    const [isOpen, setOpen] = useState(false);

    const handleBodyClick = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.addEventListener("click", handleBodyClick, false);
        } else {
            document.body.removeEventListener("click", handleBodyClick, false);
        }

        return () => {
            document.body.removeEventListener("click", handleBodyClick, false);
        };
    }, [isOpen, handleBodyClick]);

    const handleOpen = () => {
        if (isOpen) {
            setOpen(false);
            return;
        }

        const parentRect = parentContainerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipContainerRef.current.getBoundingClientRect();

        const infoButtonPadding = 4;
        tooltipContainerRef.current.style.left = parentRect.width - tooltipRect.width - infoButtonPadding + "px";
        tooltipContainerRef.current.style.top = parentRect.height + "px";

        setOpen(true);
    };

    return (
        <ParentContainer onClick={handleOpen} ref={parentContainerRef}>
            {children}
            <TooltipContainer isOpen={isOpen} ref={tooltipContainerRef}>
                {tooltipBody}
            </TooltipContainer>
        </ParentContainer>
    );
};

const ParentContainer = styled.span`
    position: relative;
`;

const TooltipContainer = styled.div`
    display: flex;
    position: absolute;
    z-index: 10;
    transform: translateY(0);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s linear, transform 0.1s linear, visibility 0s linear 0.1s;

    ${({ isOpen }) => isOpen && openState()};
`;

const openState = () => `
    visibility: visible;
    opacity: 1;
    transition: opacity 0.1s linear, transform 0.1s linear, visibility 0s linear 0s;
`;

export default OnClickBottomTooltip;

import React from "react";
import { Text, ExpandableSearch } from "headpoint-react-components";
import { GeneralContextConsumer } from "../../../../contexts";
import { getEventTypesSettings } from "../../../../contexts/GeneralContext";
import { EventTable } from "./EventTypesTable";
import { EventDetails } from "./EventDetails";
import { withSnackbar } from "notistack";
import { EventsLayoutPrimary, EventsLayoutHeader, EventsLayoutHeaderTitle } from "../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class EventTypesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsNav: undefined,
            eventTypesList: undefined,
            selectedEvent: undefined,
            detailsTab: "main",
            search: "",
        };
    }

    setSearch = (search, generalInfo) => {
        var eventTypes = getEventTypesSettings(generalInfo);
        if (search) {
            eventTypes = eventTypes.filter((et) => et.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
        }
        this.setState({ search: search, eventTypesList: eventTypes });
    };

    render() {
        const { hasSecondary } = this.props;
        const { selectedEvent } = this.state;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => (
                            <>
                                <EventsLayoutPrimary hasSecondary={hasSecondary}>
                                    <EventsLayoutHeader>
                                        <EventsLayoutHeaderTitle>
                                            <Text variant="title" component="h1">
                                                {strings("Типы событий")}
                                            </Text>

                                            {
                                                <ExpandableSearch
                                                    value={this.state.search}
                                                    onChange={(e) => this.setSearch(e.target.value, generalInfo)}
                                                    onSubmit={() => {
                                                        /* ignore enter */
                                                    }}
                                                    placeholder={strings("Найти")}
                                                    colorVariant="dark"
                                                    inHeader
                                                />
                                            }
                                        </EventsLayoutHeaderTitle>
                                    </EventsLayoutHeader>
                                    <EventTable
                                        tableData={this.state.eventTypesList ?? getEventTypesSettings(generalInfo)}
                                        setSelectedEvent={(event) => this.setState({ selectedEvent: event })}
                                        selectedEvent={selectedEvent}
                                    />
                                </EventsLayoutPrimary>
                                {selectedEvent && (
                                    <EventDetails
                                        selectedEvent={selectedEvent}
                                        setDetailsTab={(detailsTab) => this.setState({ detailsTab: detailsTab })}
                                        setSelectedEvent={(event) => this.setState({ selectedEvent: event })}
                                        detailsTab={this.state.detailsTab}
                                    />
                                )}
                            </>
                        )}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(EventTypesSettings);

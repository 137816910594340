import React from "react";
import PubSub from "pubsub-js";
import { SHOW_ALARM_NOTIFIER } from "../AlarmNotifier/alarmTopics";
import { SHOW_NOTIFIER_DIALOG } from "../DialogNotifier/dialogTopics";
import { NOTIFICATIONS_API } from "./noficatorConsts";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Notificator extends React.Component {
    componentDidMount() {
        this.createWebsocket();
    }

    componentWillUnmount() {
        try {
            this?.socket?.close();
        } catch (ex) {
            console.error(ex);
        }
    }

    createWebsocket() {
        const token = window.localStorage.getItem("token");

        if (token) {
            const wsProtocol = window.location.protocol === "https:" ? "wss://" : "ws://";
            this.socket = new WebSocket(`${wsProtocol}${window.location.host}${NOTIFICATIONS_API}`);

            this.socket.onclose = async () => {
                console.error(`Connection to ${NOTIFICATIONS_API} closed.`);
                await sleep(1000);
                this.createWebsocket();
            };

            this.socket.onmessage = (e) => {
                const model = JSON.parse(e.data);

                switch (model?.type) {
                    case "alert":
                        this.processAlert(model.data);
                        break;
                    default:
                        console.log(`unknown notification type ${model?.type}`);
                        break;
                }
            };

            this.socket.onopen = () => {
                const request = {
                    type: "subscribe",
                    token: token,
                };
                const subscribeRequest = JSON.stringify(request);

                console.log(`ws open: ${NOTIFICATIONS_API}`);
                this.socket.send(subscribeRequest);
            };
        }
    }

    processAlert(alertInfo) {
        switch (alertInfo?.type) {
            case "alarm":
                PubSub.publish(SHOW_ALARM_NOTIFIER, alertInfo?.data);
                break;
            case "dialog":
                PubSub.publish(SHOW_NOTIFIER_DIALOG, alertInfo?.data);
                break;
            default:
                console.log(`unknown alert type ${alertInfo?.type}`);
                break;
        }
    }

    render() {
        return null;
    }
}

export default Notificator;

import React from "react";
import { Text, Table, TableBody, TableRow, TableCell, Icon, Row, Select, Space } from "headpoint-react-components";
import { DetailsBoxContent } from "../../../components/DetailsBox";
import { GeneralContextConsumer } from "../../../../contexts";
import { ExecuteAdapterCommand } from "../../../../services/featureCommands";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class DetailsTabEvents extends React.Component {
    hasCommand(generalInfo, serviceCode, commandCode) {
        return !!generalInfo.services
            .find((s) => s.code === serviceCode)
            ?.properties?.commands?.list?.find((c) => c.code === commandCode);
    }

    getSeverity(generalInfo, serviceCode, eventCode) {
        return generalInfo.services
            .find((s) => s.code === serviceCode)
            ?.properties?.eventTypes?.find((ev) => ev.code === eventCode)?.severity;
    }

    async setEventTypeSeverity(eventType, severity) {
        const [status] = await ExecuteAdapterCommand("PUT", this.props.selectedEvent.serviceCode, "/severity", {
            eventType,
            severity,
        });

        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Не удалось установить важность события"), { variant: "error" });
        }
    }

    render() {
        const { selectedEvent, strings } = this.props;
        const severities = [
            { value: "info", label: "Обычные" },
            { value: "warning", label: "Требующие внимания" },
            { value: "danger", label: "Тревожные" },
        ];

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <DetailsBoxContent>
                        {selectedEvent.events.map((event) => (
                            <div key={event.id}>
                                <Row marginTop={32} marginBottom={16}>
                                    <Text color="primary" variant="body">
                                        <Icon icon="adapter" size={16} />
                                        <Space isDouble={true} />
                                        {strings(event.name)}
                                    </Text>
                                </Row>

                                <Table isFixed noPadding>
                                    <TableBody>
                                        {event.children
                                            .filter((child) => child.code === selectedEvent.code)
                                            .map((child) => (
                                                <TableRow key={child.code}>
                                                    <TableCell>{strings(child.code)}</TableCell>
                                                    <TableCell>
                                                        <Text color="primary" variant="body-sm">
                                                            {strings(child.name)}
                                                        </Text>
                                                    </TableCell>
                                                    <TableCell>
                                                        {this.hasCommand(
                                                            generalInfo,
                                                            selectedEvent.serviceCode,
                                                            "setEventTypeSeverity"
                                                        ) ? (
                                                            <Select
                                                                value={
                                                                    this.getSeverity(
                                                                        generalInfo,
                                                                        selectedEvent.serviceCode,
                                                                        child.code
                                                                    ) ?? "info"
                                                                }
                                                                options={severities.map((s) => {
                                                                    return { ...s, label: strings(s.label) };
                                                                })}
                                                                onChange={async (value) =>
                                                                    await this.setEventTypeSeverity(child.code, value)
                                                                }
                                                                colorVariant="default"
                                                                horizontalAlignment="left"
                                                            />
                                                        ) : (
                                                            <Text color="primary" variant="body-sm">
                                                                {this.getSeverity(
                                                                    generalInfo,
                                                                    selectedEvent.serviceCode,
                                                                    child.code
                                                                ) ?? "info"}
                                                            </Text>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </div>
                        ))}
                    </DetailsBoxContent>
                )}
            </GeneralContextConsumer>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabEvents));

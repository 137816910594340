import React from "react";
import { FilterGroup } from "../../components/FiltersPartion";
import { Space, TextField } from "headpoint-react-components";
import { getAllEventFilters } from "../../../contexts/GeneralContext";
import { CultureContextConsumer, withCultureContext } from "../../../contexts/cultureContext/CultureContext";
import { connect } from "react-redux";
import { saveAdapter } from "../../../app/reducers/eventsReducer";

class AdapterFilters extends React.Component {
    addOrUpdateFilters = (filterSchema, sectionCode, value) => {
        const adapterFilters = [...this.props.adapterFilters];

        let section = adapterFilters.find((af) => af.code === sectionCode);
        if (!section) {
            section = { code: sectionCode, filters: [] };
        }

        let sectionFilter = section.filters.find((sf) => sf.code === filterSchema.code);
        if (!sectionFilter) {
            sectionFilter = {
                ...filterSchema,
                value,
            };

            if (section.filters.length < 1) {
                section.filters = [...section.filters, sectionFilter];

                return [...adapterFilters, section];
            }

            section.filters = [...section.filters, sectionFilter];
        }

        sectionFilter.value = value;

        return adapterFilters;
    };

    removeSection(filterSchema, sectionCode) {
        const adapterFilters = [...this.props.adapterFilters];
        let sectionIndex = adapterFilters.findIndex((af) => af.code === sectionCode);

        if (sectionIndex < 0) {
            return adapterFilters;
        }

        const section = adapterFilters[sectionIndex];
        let sectionFilterIndex = section.filters.findIndex((sf) => sf.code === filterSchema.code);
        if (sectionFilterIndex < 0) {
            return adapterFilters;
        }

        section.filters.splice(sectionFilterIndex, 1);
        if (section.filters.length === 0) {
            adapterFilters.splice(sectionIndex, 1);
        }

        return adapterFilters;
    }

    onChange = (filterSchema, sectionCode, value) => {
        let newAdapterFilters;
        if (value && value !== "") {
            newAdapterFilters = this.addOrUpdateFilters(filterSchema, sectionCode, value);
        } else {
            newAdapterFilters = this.removeSection(filterSchema, sectionCode);
        }

        this.props.dispatch(saveAdapter(newAdapterFilters));
        this.props.setValue("adapterFilters", newAdapterFilters);
    };

    createFilterField(filterSchema, sectionCode) {
        const { strings } = this.props;

        const sectionFilters = this.props.adapterFilters.find((af) => af.code === sectionCode)?.filters;
        const filterValue = sectionFilters?.find((sf) => sf.code === filterSchema.code)?.value;
        let textFieldType;
        switch (filterSchema.type) {
            case "string":
                textFieldType = "text";
                break;
            case "uint":
                textFieldType = "uint";
                break;
            case "int":
                textFieldType = "int";
                break;
            default:
                break;
        }

        return textFieldType ? (
            <TextField
                colorVariant="light"
                fullWidth
                onChange={(e) => this.onChange(filterSchema, sectionCode, e.target?.value)}
                placeholder={strings(filterSchema.displayName)}
                type={textFieldType}
                value={filterValue}
            />
        ) : (
            <></>
        );
    }

    render() {
        const { generalInfo, adapterFilters } = this.props;

        const filtersSchema = getAllEventFilters(generalInfo).filter((ef) => ef.filters && ef.filters.length > 0);

        return filtersSchema && filtersSchema.length > 0 ? (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        {filtersSchema.map((af) => {
                            return (
                                <FilterGroup
                                    key={af.sectionCode}
                                    title={strings(af.sectionName)}
                                    hasValue={
                                        adapterFilters.find((f) => f.code === af.sectionCode)?.filters?.length > 0
                                    }
                                    onClear={() => {
                                        const sectionIndex = adapterFilters.findIndex((f) => f.code === af.sectionCode);

                                        this.props.dispatch(
                                            this.props.dispatch(
                                                saveAdapter([
                                                    ...adapterFilters.slice(0, sectionIndex),
                                                    ...adapterFilters.slice(sectionIndex + 1),
                                                ])
                                            )
                                        );
                                        this.props.setValue("adapterFilters", [
                                            ...adapterFilters.slice(0, sectionIndex),
                                            ...adapterFilters.slice(sectionIndex + 1),
                                        ]);
                                    }}
                                >
                                    {af.filters.map((f, index) => {
                                        return (
                                            <div key={af.sectionCode + f.code}>
                                                {this.createFilterField(f, af.sectionCode)}
                                                {index < af.filters.length - 1 && <Space />}
                                            </div>
                                        );
                                    })}
                                </FilterGroup>
                            );
                        })}
                    </>
                )}
            </CultureContextConsumer>
        ) : (
            <></>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        events: state.events,
    };
};

export default connect(mapStateToProps)(withCultureContext(AdapterFilters));

import React from "react";
import { GetUsers } from "../../../../../../services/users";
import { nanoid } from "nanoid";
import { ModalFormTreeProperty } from "./ModalFormTreeProperty";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class ModalFormUsersTreeProperty extends React.Component {
    async getUsers() {
        const { strings } = this.props;
        const [status, users] = await GetUsers();

        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения пользователей"), { variant: "error" });
            return [];
        }

        return [
            {
                id: nanoid(),
                name: strings("Пользователи"),
                children: users?.map((u) => ({ ...u, name: u.login })) ?? [],
            },
        ];
    }

    render() {
        const { itemId, handleAddParameters, name, values } = this.props;
        const selected = values?.functions.find((x) => x.id === itemId).parameters?.[name] ?? [];

        return (
            <ModalFormTreeProperty
                selected={selected}
                selectItems={(items) => handleAddParameters(itemId, name, items)}
                loadItems={() => this.getUsers()}
            />
        );
    }
}
export default withCultureContext(withSnackbar(ModalFormUsersTreeProperty));

import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { Checkbox, ModalSection, Select, TextField } from "headpoint-react-components";
import { DeviceTypeSettings } from "./DeviceTypesSettings";
import { checkFeatureByDeviceType, getAdapters } from "../../../../../../contexts";
import { checkManyFeaturesByDeviceType, getAllServiceFeatures } from "../../../../../../contexts/GeneralContext";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";
import { WIZARD_UPDATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

const VIDEO_STREAM_FEATURE_CODE = "video.stream";

export const ModalStepDefault = ({
    DeviceTypes,
    SetValuesProperty,
    SetDeviceSettings,
    values,
    setDeviceType,
    checkServiceFeature,
    generalInfo,
    mode,
}) => {
    const selectedDeviceType = DeviceTypes.find((dt) => dt.value === values.deviceType);
    const deviceGeneralSettings = selectedDeviceType?.settings?.general;
    const serviceFeatures = getAllServiceFeatures(generalInfo).filter((f) => f.commands);
    const adapters = getAdapters(generalInfo);

    const groupedServiceFeatures = serviceFeatures.reduce((feature, className) => {
        const { featureCode } = className;
        feature[featureCode] = feature[featureCode] ?? [];
        feature[featureCode].push(className);
        return feature;
    }, {});

    const groupedFeatures = [];
    for (let prop in groupedServiceFeatures) {
        groupedFeatures.push({ featureCode: prop, serviceFeatures: groupedServiceFeatures[prop] });
    }

    const disabledDeviceType = mode === WIZARD_UPDATE_MODE;

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <ModalSection>
                    <ModalFormItem>
                        <Select
                            label={strings("Тип устройства")}
                            placeholder={strings("Выбрать тип")}
                            value={values.deviceType}
                            options={DeviceTypes.map((t) => {
                                return { ...t, label: strings(t.label) };
                            })}
                            onChange={(dt) => {
                                const selectedDeviceType = DeviceTypes.find((type) => type.value === dt);
                                const serviceId = selectedDeviceType?.serviceId;
                                const sections = selectedDeviceType?.settings?.sections ?? [];
                                setDeviceType(dt, sections, serviceId);
                            }}
                            colorVariant="default"
                            horizontalAlignment="left"
                            verticalAlignment="bottom"
                            fullWidth
                            error={!values.deviceType ? strings("Выберите тип оборудования") : null}
                            disabled={disabledDeviceType}
                        />
                    </ModalFormItem>

                    <ModalFormItem>
                        <TextField
                            label={strings("Внешний ID")}
                            name="externalId"
                            type="text"
                            value={values.externalId}
                            onChange={(e) => SetValuesProperty("externalId", e.target.value)}
                            colorVariant="light"
                            fullWidth
                        />
                    </ModalFormItem>

                    <ModalFormItem>
                        <TextField
                            label={strings("Название устройства")}
                            name="name"
                            type="text"
                            value={values.name}
                            onChange={(e) => SetValuesProperty("name", e.target.value)}
                            colorVariant="light"
                            fullWidth
                            error={!values.name ? strings("Поле не может быть пустым") : null}
                        />
                    </ModalFormItem>

                    <ModalFormItem>
                        <TextField
                            label={strings("Комментарий")}
                            name="comment"
                            type="text"
                            value={values.comment}
                            onChange={(e) => SetValuesProperty("comment", e.target.value)}
                            colorVariant="light"
                            fullWidth
                            inputProps={{
                                as: "textarea",
                                rows: 3,
                            }}
                        />
                    </ModalFormItem>
                    {checkFeatureByDeviceType(selectedDeviceType?.value, adapters, VIDEO_STREAM_FEATURE_CODE) && (
                        <ModalFormItem>
                            <TextField
                                label={strings("Объекты наблюдения")}
                                name="observedObjects"
                                type="text"
                                value={values.observedObjects}
                                onChange={(e) => SetValuesProperty("observedObjects", e.target.value)}
                                colorVariant="light"
                                fullWidth
                                inputProps={{
                                    as: "textarea",
                                    rows: 3,
                                }}
                            />
                        </ModalFormItem>
                    )}
                    <DeviceTypeSettings
                        deviceTypes={DeviceTypes}
                        selectedDevType={values.deviceType}
                        deviceSettings={values.settings}
                        SetDeviceSettings={SetDeviceSettings}
                        selectedDevTypeSettings={deviceGeneralSettings}
                        SetValuesProperty={SetValuesProperty}
                        mode={mode}
                    />
                    {serviceFeatures && selectedDeviceType ? (
                        groupedFeatures.map((feature) => {
                            if (
                                checkManyFeaturesByDeviceType(
                                    selectedDeviceType?.value,
                                    adapters,
                                    feature.serviceFeatures[0]?.compatibleDeviceFeatures
                                )
                            ) {
                                const checked = values?.serviceFeatureCodes?.includes(feature.featureCode);
                                return (
                                    <ModalFormItem key={feature.serviceFeatures[0].featureClassName}>
                                        <Checkbox
                                            label={strings(feature.serviceFeatures[0].featureClassName)}
                                            checked={checked}
                                            onChange={() => {
                                                checkServiceFeature(
                                                    feature.featureCode,
                                                    feature.serviceFeatures,
                                                    !checked
                                                );
                                            }}
                                        />
                                    </ModalFormItem>
                                );
                            }

                            return null;
                        })
                    ) : (
                        <></>
                    )}
                </ModalSection>
            )}
        </CultureContextConsumer>
    );
};

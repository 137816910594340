export const urlWithQuery = (url, query) => {
    const queryString = query.toString();
    if (!queryString) {
        return url;
    }

    return `${url}?${queryString}`;
};

export const objectToParams = (obj, searchParams, prefix) => {
    for (let key in obj) {
        if (typeof obj[key] === "object") {
            objectToParams(obj[key], searchParams, `${prefix}${key}.`);
        } else {
            searchParams.append(`${prefix}${key}`, obj[key]);
        }
    }
};

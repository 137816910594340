import React from "react";
import { GetTags, GetTagsByEntity } from "../../../../../../services/tags";
import { withSnackbar } from "notistack";
import { Checkbox, ModalSection, Row, Text, TextField } from "headpoint-react-components";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { FilterItem } from "../../../../../components/FiltersPartion";
import { nanoid } from "nanoid";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";
import { SelectedCounter } from "../../../../../components/SelectedCounter/SelectedCounter";
import { WIZARD_UPDATE_MODE } from "../../../../../components/CreateUpdateWizard/constants";

class ModalStepTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tagSearch: "",
            tags: [],
        };
    }

    componentDidMount = async () => {
        const { strings } = this.props;
        const [tagStatus, tags] = await GetTags();

        if (!tagStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения тегов оборудования"), { variant: "error" });
            return;
        }

        this.setState({
            tags: tags ?? [],
        });

        const { values } = this.props;
        if (this.props.mode === WIZARD_UPDATE_MODE && values.id) {
            const [selectedTagStatus, selectedTags] = await GetTagsByEntity(values.id);
            if (!selectedTagStatus) {
                this.props.enqueueSnackbar(strings("Ошибка получения тегов оборудования"), { variant: "error" });
                return;
            }

            this.setTags(selectedTags?.map((t) => t.id) ?? []);
        }
    };

    handleCheckboxChange = (id) => {
        let tags = this.props.values?.tags ?? [];
        this.setTags(tags.includes(id) ? tags.filter((item) => item !== id) : [...tags, id]);
    };

    searchFilter = (search, data, keyExp) => {
        if (!search) {
            return data;
        }

        return data?.filter((t) => keyExp(t)?.toLowerCase()?.includes(search.toLowerCase()));
    };

    setTags = (tags) => {
        const { SetValuesProperty } = this.props;
        SetValuesProperty("tags", tags);
    };

    render() {
        const { tagSearch, tags } = this.state;
        const { strings } = this.props;
        const selectedTags = this.props.values.tags ?? [];

        return (
            <ModalSection>
                <ModalFormItem>
                    <Row justify="between" align="bottom" marginBottom={24}>
                        <Text variant="body-sm">{strings("Теги оборудования")}</Text>
                        <SelectedCounter
                            count={selectedTags?.length ?? 0}
                            onClear={() => {
                                this.setState({ tagSearch: "" });
                                this.setTags([]);
                            }}
                            strings={strings}
                        />
                    </Row>
                    <Row marginBottom={12}>
                        <TextField
                            autoComplete={false}
                            colorVariant="light"
                            fullWidth
                            icon="search"
                            onChange={(e) => this.setState({ tagSearch: e.target?.value })}
                            placeholder={strings("Найти")}
                            type="search"
                            value={tagSearch}
                        />
                    </Row>
                    {this.searchFilter(tagSearch, tags, (t) => t.name).map((tag) => (
                        <FilterItem key={nanoid()}>
                            <Checkbox
                                label={tag.name}
                                checked={selectedTags.includes(tag.id)}
                                onChange={() => this.handleCheckboxChange(tag.id)}
                            />
                        </FilterItem>
                    ))}
                </ModalFormItem>
            </ModalSection>
        );
    }
}

export default withCultureContext(withSnackbar(ModalStepTags));

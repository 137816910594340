import React from "react";
import {
    Button,
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    TableHoverContainer,
} from "headpoint-react-components";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class ScenariosTable extends React.Component {
    flatten = (object) => {
        const getChild = (key, objectBit) => {
            return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
        };

        return Object.assign(
            {},
            ...(function _flatten(objectBit) {
                return [].concat(
                    ...Object.keys(objectBit).map((key) =>
                        typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                    )
                );
            })(object)
        );
    };

    handleShowDetails = (id) => {
        const flattenScenarioList = this.flatten(this.props.scenarioList);
        const nextScenario = flattenScenarioList[id];
        this.props.setSelectedScenario(nextScenario);
    };

    RowActions = (scenario) => (
        <TableHoverContainer>
            <Button
                icon="info"
                onClick={(e) => {
                    e.stopPropagation();
                    this.handleShowDetails(scenario.id);
                }}
                title="Info"
                variant="ghost"
            />
        </TableHoverContainer>
    );

    render() {
        const { selectedScenario } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Сценарий")}</TableHeadCell>
                                    <TableHeadCell>{strings("Статус")}</TableHeadCell>
                                    <TableHeadCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.props.scenarioList.map((scenario) => (
                                    <TableRow
                                        key={scenario.id}
                                        isSelected={selectedScenario && scenario.id === selectedScenario.id}
                                    >
                                        <TableCell verticalAlign="middle">
                                            <Text color={scenario.isCompleted ? "muted" : "primary"} variant="body-sm">
                                                <Icon icon="scenario" size={16} />
                                                {"\u00A0\u00A0"}
                                                {scenario.name}
                                            </Text>
                                        </TableCell>

                                        <TableCell verticalAlign="middle">
                                            <Text color={!scenario?.enabled ? "warning" : "success"} variant="body-sm">
                                                {!scenario?.enabled ? strings("Приостановлено") : strings("Активно")}
                                            </Text>
                                        </TableCell>

                                        <TableCell verticalAlign="middle" noVerticalSpace>
                                            {this.RowActions(scenario)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import { ModalSection } from "headpoint-react-components";
import { ServiceFeatureSettings } from "./ServiceFeatureSettings";
import { GeneralContextConsumer, getAllServiceFeatures } from "../../../../../../contexts/GeneralContext";

export class ModalStepFeatureSettings extends React.Component {
    render() {
        const { values, generalInfo } = this.props;
        const linkedFeatureCodes = values.serviceFeatureCodes;
        const availableServiceFeatures = getAllServiceFeatures(generalInfo).filter((sf) =>
            linkedFeatureCodes.includes(sf?.featureCode)
        );

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <ModalSection>
                            <ServiceFeatureSettings
                                availableServiceFeatures={availableServiceFeatures}
                                deviceFeatureSettings={this.props.values.settings.features}
                                setFeatureSettings={this.props.setFeatureSettings}
                                activeStep={this.props.activeStep}
                                steps={this.props.steps}
                                mode={this.props.mode}
                                generalInfo={generalInfo}
                            />
                        </ModalSection>
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import streamReducer from "./reducers/streamReducer";
import archiveReducer from "./reducers/archiveReducer";
import galleryReducer from "./reducers/galleryReducer";
import eventsReducer from "./reducers/eventsReducer";
import mapReducer from "./reducers/mapReducer";
import planReducer from "./reducers/planReducer";
import scheduleReducer from "./reducers/scheduleReducer";
import controlZonesReducer from "./reducers/controlZonesReducer";
import reportsReducer from "./reducers/reportsReducer";

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
    reducer: {
        persistedReducer,
        stream: streamReducer,
        archive: archiveReducer,
        gallery: galleryReducer,
        events: eventsReducer,
        map: mapReducer,
        plan: planReducer,
        schedule: scheduleReducer,
        controlZones: controlZonesReducer,
        reports: reportsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

import React from "react";
import { DetailsBoxContent } from "../../../../components/DetailsBox";
import { Text, Table, TableBody, TableRow, TableCell } from "headpoint-react-components";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";

class DetailsTabMain extends React.Component {
    render() {
        const { tag, strings } = this.props;

        return (
            <DetailsBoxContent>
                <Table isFixed noPadding>
                    <TableBody>
                        <TableRow>
                            <TableCell>{strings("Комментарий")}</TableCell>
                            <TableCell>
                                <Text scroll color="primary" variant="body-sm">
                                    {tag?.properties?.comment ?? ""}
                                </Text>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DetailsBoxContent>
        );
    }
}

export default withCultureContext(withSnackbar(DetailsTabMain));

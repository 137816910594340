const createCountFormatter = ({ few, one, two }) => {
    const titles = [one, two, few];

    return (number) => {
        const cases = [2, 0, 1, 1, 1, 2];

        return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
    };
};

export const isStringEmpty = (value) => value == null || value.trim().length === 0;

export const formatUserCount = createCountFormatter({
    few: "пользователей",
    one: "пользователь",
    two: "пользователя",
});

export const formatRoleUserCount = createCountFormatter({
    few: "пользователям",
    one: "пользователю",
    two: "пользователям",
});

export const formatRoleFunctionCount = createCountFormatter({
    few: "функций",
    one: "функция",
    two: "функции",
});

export const formatPlanDeviceCount = createCountFormatter({
    few: "устройств",
    one: "устройство",
    two: "устройства",
});

export const formatGroupDeviceCount = createCountFormatter({
    few: "устройств",
    one: "устройство",
    two: "устройства",
});

export const formatGroupTags = createCountFormatter({
    few: "тегов",
    one: "тега",
    two: "тегов",
});

export const formatGroupDeviceTypes = createCountFormatter({
    few: "типов",
    one: "типа",
    two: "типов",
});

export const formatTagsDeviceCount = createCountFormatter({
    few: "устройствам",
    one: "устройству",
    two: "устройствам",
});

export const formatAvailabilityControlDeviceCount = createCountFormatter({
    few: "устройств",
    one: "устройство",
    two: "устройства",
});
export const formatAvailabilityControlPrefix = createCountFormatter({
    few: "следуют",
    one: "следует",
    two: "следуют",
});
export const formatAdapterSettingsEventCount = createCountFormatter({
    few: "событий",
    one: "событие",
    two: "события",
});
export const formatAvailabilityDaysCount = createCountFormatter({
    few: "дней",
    one: "день",
    two: "дня",
});
export const formatAvailabilityHoursCount = createCountFormatter({
    few: "часов",
    one: "час",
    two: "часа",
});
export const formatAvailabilityMinutesCount = createCountFormatter({
    few: "минут",
    one: "минута",
    two: "минуты",
});

import { createSlice } from "@reduxjs/toolkit";
import { getSystemVariableValue } from "../../contexts";

export const STREAM_REFRESH_COUNT_NOT_INITIALIZED = -1;

const initialState = {
    activeDesktop: {
        layout: "1",
    },
    frames: {},
    showCameraInfo: false,
    refreshCount: STREAM_REFRESH_COUNT_NOT_INITIALIZED,
};

export const streamSlice = createSlice({
    name: "stream",
    initialState: initialState,
    reducers: {
        saveFrames: (state, action) => {
            state.frames = action.payload;
        },
        saveActiveDesktop: (state, action) => {
            state.activeDesktop = action.payload;
        },
        saveShowCameraInfo: (state, action) => {
            state.showCameraInfo = action.payload;
        },
        decrementRefreshCount: (state, action) => {
            if (state.refreshCount > 0) {
                state.refreshCount = state.refreshCount - 1;
            }
        },
        initRefreshCount: (state, action) => {
            if (state.refreshCount === -1) {
                state.refreshCount = getSystemVariableValue(
                    action.payload,
                    "service.api.stream",
                    "stream.refresh.count",
                    0
                );
            }
        },
    },
});

export const { saveFrames, saveActiveDesktop, saveShowCameraInfo, decrementRefreshCount, initRefreshCount } =
    streamSlice.actions;

export default streamSlice.reducer;

import { ModalStepDefault } from "./modals/CreateDeviceModal/ModalStepDefault";
import ModalStepDeviceGroups from "./modals/CreateDeviceModal/ModalStepDeviceGroups";
import ModalStepLocation from "./modals/CreateDeviceModal/ModalStepLocation";
import LocationStepDefault from "./modals/locationModals/LocationStepDefault";
import LocationStepPosition from "./modals/locationModals/LocationStepPosition";
import ModalStepTags from "./modals/CreateDeviceModal/ModalStepTags";
import ModalStepControleZones from "./modals/CreateDeviceModal/ModalStepControleZones";

export const wizardLocationSteps = [
    { name: "Общее", component: LocationStepDefault },
    { name: "Расположение", component: LocationStepPosition },
];

export const wizardDeviceStepsDefault = [
    { name: "Общее", component: ModalStepDefault },
    {
        name: "Фиксированные группы оборудования",
        permission: "devices.deviceGroup.lifeCycle",
        component: ModalStepDeviceGroups,
    },
    { name: "Теги оборудования", component: ModalStepTags },
    {
        name: "Зоны контроля",
        serviceCode: "service.api.controlZones",
        permission: "controlZone.lifeCycle",
        component: ModalStepControleZones,
    },
    { name: "Расположение", component: ModalStepLocation },
];

import React from "react";
import { Text, ExpandableSearch } from "headpoint-react-components";
import { AdaptersTable } from "./AdaptersTable";
import { AdapterDetails } from "./AdapterDetails";
import { withSnackbar } from "notistack";
import { getAdapters } from "../../../../contexts";
import { EventsLayoutPrimary, EventsLayoutHeader, EventsLayoutHeaderTitle } from "../../../../layout/EventsLayout";
import ConnectDeviceModal from "./Modals/ConnectDeviceModal";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import { withGeneralContext } from "../../../../contexts/GeneralContext";

class AdaptersSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showDelete: false,
            settingsNav: undefined,
            adapterList: undefined,
            search: "",
            selectedAdapter: undefined,
            detailsTab: "main",
            showConnectModal: false,
            serviceFeature: undefined,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevState.selectedAdapter?.serviceCode !== this.state.selectedAdapter?.serviceCode ||
            prevProps.generalInfo !== this.props.generalInfo
        ) {
            const actualSelectedAdapter = getAdapters(this.props.generalInfo).find(
                (adapter) => adapter.serviceCode === this.state.selectedAdapter?.serviceCode
            );

            this.setState({ selectedAdapter: actualSelectedAdapter });
        }
    }

    setSearch = (search, generalInfo) => {
        var adapters = getAdapters(generalInfo);
        if (search) {
            adapters = adapters.filter((adapter) => adapter.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
        }

        this.setState({ search: search, adapterList: adapters });
    };

    setDetailsTab = (detailsTab) => {
        this.setState({ detailsTab: detailsTab });
    };

    showConnectModal = (serviceFeature) => {
        this.setState({ showConnectModal: true, serviceFeature });
    };

    closeConnectModal() {
        this.setState({ showConnectModal: false, serviceFeature: undefined });
    }

    render() {
        const { selectedAdapter } = this.state;
        const { generalInfo } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <EventsLayoutPrimary hasSecondary={this.props.hasSecondary}>
                            <EventsLayoutHeader>
                                <EventsLayoutHeaderTitle>
                                    <Text variant="title" component="h1">
                                        {strings("Адаптеры")}
                                    </Text>

                                    <ExpandableSearch
                                        value={this.state.search}
                                        onChange={(e) => this.setSearch(e.target.value, generalInfo)}
                                        onSubmit={() => {
                                            /* ignore enter */
                                        }}
                                        placeholder={strings("Найти")}
                                        colorVariant="dark"
                                        inHeader
                                    />
                                </EventsLayoutHeaderTitle>
                            </EventsLayoutHeader>

                            <AdaptersTable
                                tableData={this.state.adapterList ?? getAdapters(generalInfo)}
                                setSelectedAdapter={(adapter) =>
                                    this.setState({ selectedAdapter: adapter, detailsTab: "main" })
                                }
                                selectedAdapter={selectedAdapter}
                            />
                        </EventsLayoutPrimary>
                        {selectedAdapter && (
                            <AdapterDetails
                                selectedAdapter={selectedAdapter}
                                closeDetails={() => this.setState({ selectedAdapter: undefined, detailsTab: "main" })}
                                setDetailsTab={this.setDetailsTab}
                                detailsTab={this.state.detailsTab}
                                generalInfo={generalInfo}
                                showConnectModal={(show, feature) => {
                                    this.showConnectModal(show, feature);
                                }}
                            />
                        )}
                        {this.state.showConnectModal && (
                            <ConnectDeviceModal
                                serviceFeature={this.state.serviceFeature}
                                generalInfo={generalInfo}
                                closeConnectModal={() => {
                                    this.closeConnectModal();
                                }}
                                selectedAdapter={selectedAdapter}
                            />
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withGeneralContext(withSnackbar(AdaptersSettings));

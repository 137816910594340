import { withSnackbar } from "notistack";
import { LayoutMain, LayoutPage, LayoutSidebar } from "../../layout/MainLayout";
import SchedulesList from "./SchedulesList";
import { EventsLayout } from "../../layout/EventsLayout";
import Filters from "./Filters";
import { useDispatch, useSelector } from "react-redux";
import ScheduleDetails from "./ScheduleDetails";
import { CreateUpdateWizard } from "../components/CreateUpdateWizard/CreateUpdateWizard";
import { GROUP_SCHEDULE_TYPE, wizardScheduleDefault } from "./constants";
import { withGeneralContext } from "../../contexts/GeneralContext";
import {
    closeWizard,
    createSchedule,
    deleteSchedule,
    saveScheduleState,
    updateSchedule,
} from "../../app/reducers/scheduleReducer";
import { withCultureContext } from "../../contexts/cultureContext/CultureContext";
import ModalStepFeatureSettings from "./Modals/ModalStepFeatureSettings";
import { LoaderAnimation } from "../components/LoaderAnimation/LoaderAnimation";
import { DeleteModal } from "../settings/components/DeleteModal";
import { convertLocalToUTC } from "../../utilites/TimeUtils";
import moment from "moment";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../components/CreateUpdateWizard/constants";

const Schedules = ({ strings, enqueueSnackbar }) => {
    const dispatch = useDispatch();

    const {
        selectedSchedule,
        showCreateWizard,
        showDeleteScheduleModal,
        wizardMode,
        values,
        steps,
        step,
        activeStep,
        isLoading,
    } = useSelector((state) => state.schedule);

    const scheduleFromValues = () => {
        const times = values.times?.map((time) => convertLocalToUTC(time));
        const intervals = values.intervals?.map((interval) => {
            return {
                ...interval,
                from: interval.from ? convertLocalToUTC(interval.from) : null,
                to: interval.to ? convertLocalToUTC(interval.to) : null,
            };
        });

        const dates = values.dates?.map((date) => moment(date).format("DD.MM.YYYY"));

        return {
            id: values.id,
            name: values.name,
            comment: values.comment,

            type: values.type,
            groupIds: values.groupIds,

            start: values.start ? moment(values.start).format("DD.MM.YYYY HH:mm") : null,
            end: values.end ? moment(values.end).format("DD.MM.YYYY HH:mm") : null,
            intervalsType: values.intervalsType,
            dateType: values.dateType,
            timeType: values.timeType,

            times,
            intervals,
            dates,
            days: values.days,
            daysOfMonth: values.daysOfMonth,
            daysOfWeek: values.daysOfWeek,

            deviceFilters: {
                deviceIds: values.deviceIdsFilter,
                filters: {
                    groupIds: values.groupsFilter,
                    typeIds: values.typesFilter,
                    tagsIds: values.tagsFilter,
                },
            },
        };
    };

    const setScheduleType = (feature) => {
        const featureSettings = feature.commands.settings;

        const featureSteps = featureSettings.map((f) => ({
            name: f.name,
            isFeatureStep: true,
            featureCode: f.code,
            component: ModalStepFeatureSettings,
        }));

        const steps = getScheduleSteps(featureSteps);

        dispatch(saveScheduleState({ values: { ...values, feature }, steps: steps }));
    };

    const getScheduleSteps = (scheduleTypeSteps = []) => {
        return wizardScheduleDefault.concat(scheduleTypeSteps);
    };

    const nextStep = () => {
        dispatch(saveScheduleState({ step: step + 1, activeStep: step + 1 }));
    };

    const createScheduleHandler = async () => {
        let schedule = scheduleFromValues();

        if (!schedule.name) {
            enqueueSnackbar(strings("Нельзя создать расписание без имени"), { variant: "error" });
            return;
        }

        if (!values.feature) {
            enqueueSnackbar(strings("Нельзя создать расписание без вида"), { variant: "error" });
            return;
        }

        if (!schedule.type) {
            enqueueSnackbar(strings("Нельзя создать расписание без типа"), { variant: "error" });
            return;
        }

        if (values.intervalsType === "oneTime" && (!values.times || values.times?.length < 1)) {
            enqueueSnackbar(strings("Нельзя создать однократное расписание без времени"), { variant: "error" });
            return;
        }

        if (values.intervalsType === "oneTime" && (!values.dates || values.dates?.length < 1)) {
            enqueueSnackbar(strings("Нельзя создать однократное расписание без даты"), { variant: "error" });
            return;
        }

        if (values.intervalsType === "oneTime" && checkAnyIncorrectDate(values.dates, values.times)) {
            enqueueSnackbar(strings("Дата однократного расписания не может быть в прошлом"), {
                variant: "error",
            });
            return;
        }

        if (schedule.type === GROUP_SCHEDULE_TYPE && schedule.groupIds.length < 1) {
            enqueueSnackbar(strings("Нельзя создать расписание без выбранной группы"), { variant: "error" });
            return;
        }

        if (moment(values.start).toDate() < moment().toDate()) {
            dispatch(saveScheduleState({ values: { ...values, start: moment().toDate() } }));
        }

        if (values.end && moment(values.end).toDate() < moment().toDate()) {
            enqueueSnackbar(strings("Дата завершения не может быть в прошлом"), { variant: "error" });
            return;
        }

        if (values.end && moment(values.start).toDate() > moment(values.end).toDate()) {
            enqueueSnackbar(strings("Дата завершения должна быть больше даты начала"), { variant: "error" });
            return;
        }

        let serviceId = values.feature.serviceId;
        if (wizardMode === WIZARD_UPDATE_MODE) {
            dispatch(updateSchedule({ schedule, serviceId, enqueueSnackbar, strings }));
        } else {
            dispatch(createSchedule({ schedule, serviceId, enqueueSnackbar, strings }));
        }
    };

    const checkAnyIncorrectDate = (dates, times) => {
        // если только одна дата, то не должно быть прошедшего времени (time)
        if (dates.length === 1) {
            return checkAnyIncorrectTime(dates[0], times);
        }

        // если много дат, то не должно быть дат в прошлом (date)
        const now = moment().toDate();
        const notSorted = [...times];
        const sorted = notSorted.sort((a, b) => a.localeCompare(b));
        const biggestTime = sorted[sorted.length - 1].split(":");

        return dates.find((d) => {
            const latest = moment(d).toDate();
            latest.setHours(biggestTime[0]);
            latest.setMinutes(biggestTime[1]);
            return latest < now;
        });
    };

    const checkAnyIncorrectTime = (date, times) => {
        const now = moment().toDate();
        let isCorrect = true;

        times.forEach((t) => {
            if (!isCorrect) {
                return true;
            }

            const time = t.split(":");
            date.setHours(time[0]);
            date.setMinutes(time[1]);
            isCorrect = date > now;
        });

        return !isCorrect;
    };

    return (
        <>
            {isLoading && <LoaderAnimation message={strings("Создание расписания")} />}
            <DeleteModal
                visible={showDeleteScheduleModal}
                CloseHandler={() => dispatch(saveScheduleState({ showDeleteScheduleModal: false }))}
                RemoveHandler={() => dispatch(deleteSchedule({ schedule: selectedSchedule, enqueueSnackbar, strings }))}
                text={`${strings("Вы хотите удалить расписание")} "${selectedSchedule?.name}". ${strings(
                    "Удалённое расписание нельзя восстановить. Продолжить?"
                )}`}
            />
            <LayoutPage>
                <LayoutSidebar>
                    <Filters />
                </LayoutSidebar>
                <LayoutMain>
                    <EventsLayout>
                        <SchedulesList />
                        {selectedSchedule && <ScheduleDetails />}
                        <CreateUpdateWizard
                            visible={showCreateWizard}
                            title={`${
                                wizardMode === WIZARD_CREATE_MODE ? strings("Создать") : strings("Редактировать")
                            } ${strings(" расписание")}`}
                            steps={steps}
                            step={step}
                            activeStep={activeStep}
                            NextStep={nextStep}
                            SetActiveStep={(payload) => dispatch(saveScheduleState({ activeStep: payload }))}
                            CreateHandler={createScheduleHandler}
                            EditHandler={createScheduleHandler}
                            CloseHandler={() => dispatch(closeWizard())}
                            Mode={wizardMode}
                            setScheduleType={setScheduleType}
                        />
                    </EventsLayout>
                </LayoutMain>
            </LayoutPage>
        </>
    );
};

export default withSnackbar(withGeneralContext(withCultureContext(Schedules)));

import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, Text, Row, Button, Select, TextField } from "headpoint-react-components";
import { nanoid } from "nanoid";
import { GetDevicesGroups } from "../../../../../../services/devicesGroups";
import { withSnackbar } from "notistack";
import { FilterAccordionStatic } from "../../../../../components/FiltersStatic";
import { filterTree, flatten } from "../../../../../../utilites/TreeUtils";
import { devicesGroupsTypes } from "../../constants";
import { getDeviceGroupsPermissionsTree } from "../../../../../../contexts";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

class ModalStepDGPermissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devicesGroups: [],
            roleDGPermissions: [],
            newDeviceGroup: false,
            devicesGroupType: undefined,
            search: "",
        };
    }

    componentDidMount = async () => {
        const devicesGroups = await this.getDeviceGroups();
        const roleDGPermissions = this.getRoleDGPermissions(devicesGroups);
        this.setState({ devicesGroups, roleDGPermissions });
    };

    async getDeviceGroups() {
        const [status, deviceGroups] = await GetDevicesGroups();
        if (!status) {
            const { strings } = this.props;
            this.props.enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
        }

        return deviceGroups;
    }

    getRoleDGPermissions = (devicesGroups) => {
        const roleDGPermissions = getDeviceGroupsPermissionsTree(devicesGroups, this.props.generalInfo);

        roleDGPermissions.forEach((deviceGroup) => {
            deviceGroup.children = deviceGroup.children
                .filter((child) => child.children?.length > 0)
                .map((c) => this.translateChildren(c));
        });

        return roleDGPermissions;
    };

    translateChildren = (child) => {
        if (!!!child) {
            return undefined;
        }

        let { children } = child;
        if (children) {
            children = children.map((c) => this.translateChildren(c));
            child.children = [...children];
        }

        return { ...child, name: this.props.strings(child.name) };
    };

    handleAddGroup = async (id) => {
        const { values, SetValuesProperty } = this.props;
        SetValuesProperty("devicesGroups", [...(values?.devicesGroups ?? []), id]);
        this.setState({ newDeviceGroup: false, devicesGroupType: undefined });
    };

    handleRemoveGroup = async (id) => {
        const { values, SetValuesProperty } = this.props;
        await SetValuesProperty("permissions", values?.permissions?.filter((p) => !p.includes(id)) ?? []);
        await SetValuesProperty("devicesGroups", values?.devicesGroups?.filter((group) => group !== id) ?? []);
    };

    availableDeviceGroupsTypes = (devicesGroupsTypes) => {
        const { devicesGroups } = this.state;

        const types = [];

        devicesGroupsTypes.forEach((d) => {
            const devices = devicesGroups.filter((dG) => dG?.properties?.groupType === d.value);

            if (devices?.length > 0) {
                types.push(d);
            }
        });

        return types;
    };

    render() {
        const { values, SetValuesProperty, strings } = this.props;
        const { devicesGroups, newDeviceGroup, search, devicesGroupType, roleDGPermissions } = this.state;

        const typedDevicesGroups =
            devicesGroups
                ?.filter((dg) => !values?.devicesGroups?.some((id) => dg?.id === id))
                ?.filter((dg) => dg?.properties?.groupType === devicesGroupType)
                ?.map((dg) => ({
                    value: dg.id,
                    label: dg.name,
                })) ?? [];

        return (
            <>
                {values?.devicesGroups?.map((id, i) => {
                    const name =
                        devicesGroups.find((g) => g.id === id)?.name ?? `${strings("Группа оборудования")} ${i + 1}`;
                    let dgPermissions =
                        roleDGPermissions?.find((p) => {
                            return p.id === id;
                        })?.children ?? [];

                    if (search) {
                        let searchingDGPermissions = Object.values(flatten(roleDGPermissions)).filter(
                            (et) => et.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        );

                        dgPermissions = filterTree(
                            dgPermissions,
                            searchingDGPermissions.map((perm) => perm.id)
                        );
                    }

                    return (
                        <ModalSection key={id ?? nanoid()}>
                            <Row marginBottom={14} justify="between" align="middle">
                                <Text color="primary" variant="body">
                                    {name}
                                </Text>
                                <Button
                                    elSize="lg"
                                    icon="trash"
                                    onClick={() => this.handleRemoveGroup(id)}
                                    type="button"
                                    variant="ghost-danger"
                                    noPadding
                                />
                            </Row>

                            <Row marginBottom={12}>
                                <TextField
                                    autoComplete={false}
                                    colorVariant="light"
                                    fullWidth
                                    icon="search"
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                    placeholder={strings("Найти")}
                                    type="search"
                                    value={search}
                                />
                            </Row>

                            <FilterAccordionStatic
                                key={!!search}
                                preOpen={!!search}
                                items={dgPermissions}
                                selected={values?.permissions ?? []}
                                selectHandler={(e) => SetValuesProperty("permissions", e)}
                            />
                        </ModalSection>
                    );
                })}

                {newDeviceGroup && (
                    <ModalSection>
                        <Row marginBottom={14} justify="between" align="middle">
                            <Text color="primary" variant="body">
                                {strings("Выбор группы оборудования")}
                            </Text>

                            <Button
                                elSize="lg"
                                icon="trash"
                                onClick={() => this.setState({ newDeviceGroup: false })}
                                type="button"
                                variant="ghost-danger"
                                noPadding
                            />
                        </Row>
                        <ModalFormItem>
                            <Select
                                label={strings("Тип группы оборудования")}
                                placeholder={strings("Выберите тип группы оборудования")}
                                onChange={(e) => this.setState({ devicesGroupType: e })}
                                options={devicesGroupsTypes.map((t) => {
                                    return { ...t, label: strings(t.label) };
                                })}
                                fullWidth
                                value={devicesGroupType}
                            />
                        </ModalFormItem>
                        {devicesGroupType && (
                            <ModalFormItem>
                                <Select
                                    label={strings("Группа оборудования")}
                                    placeholder={strings("Выберите группу оборудования")}
                                    onChange={this.handleAddGroup}
                                    options={typedDevicesGroups}
                                    fullWidth
                                    disabled={!typedDevicesGroups.length}
                                />
                            </ModalFormItem>
                        )}
                    </ModalSection>
                )}

                {!newDeviceGroup && (
                    <ModalSection>
                        <Button
                            elSize="lg"
                            label={strings("+ Добавить группу оборудования")}
                            noPadding
                            onClick={() => this.setState({ newDeviceGroup: true })}
                            title={strings("+ Добавить группу оборудования")}
                            type="button"
                            variant="ghost"
                        />
                    </ModalSection>
                )}
            </>
        );
    }
}

export default withCultureContext(withSnackbar(ModalStepDGPermissions));

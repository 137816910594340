import React from "react";
import { Button, Text, Tab, TabPanel, Dropdown, DropdownItem, DropdownDivider } from "headpoint-react-components";
import { EventsLayoutSecondary } from "../../../../../layout/EventsLayout";
import {
    DetailsBox,
    DetailsBoxHeader,
    DetailsBoxTitle,
    DetailsBoxHeaderActions,
    DetailsBoxTabs,
} from "../../../../components/DetailsBox";
import { withSnackbar } from "notistack";
import { GroupDetailsTabMain } from "./GroupDetailsTabMain";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

const SUPER_USER_GROUP_ID = "929ea950-16e8-4365-9f09-e3160d220c46";

class GroupDetails extends React.Component {
    render() {
        const { selectedGroup, detailsTab, setSelectedGroup, availableRoles, editUsersGroup, deleteUsersGroup } =
            this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutSecondary>
                        <DetailsBox>
                            <DetailsBoxHeader sticky>
                                <DetailsBoxTitle>
                                    <Text color="primary" variant="title" component="h2">
                                        {selectedGroup?.name}
                                    </Text>
                                </DetailsBoxTitle>

                                <DetailsBoxHeaderActions>
                                    <Dropdown closeOnClickInside toggleVariant="secondary" horizontalAlignment="right">
                                        <DropdownItem as="button" onClick={() => editUsersGroup(selectedGroup)}>
                                            {strings("Редактировать")}
                                        </DropdownItem>
                                        {selectedGroup.id !== SUPER_USER_GROUP_ID ? (
                                            <>
                                                <DropdownDivider />
                                                <DropdownItem
                                                    as="button"
                                                    colorVariant="danger"
                                                    onClick={() => deleteUsersGroup(selectedGroup)}
                                                >
                                                    {strings("Удалить")}
                                                </DropdownItem>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Dropdown>

                                    <Button icon="close" variant="secondary" onClick={setSelectedGroup} />
                                </DetailsBoxHeaderActions>
                            </DetailsBoxHeader>

                            <DetailsBoxTabs>
                                <TabPanel>
                                    <Tab
                                        label={strings("Общее")}
                                        isActive={detailsTab === "main"}
                                        onClick={() => this.props.setDetailsTab("main")}
                                    />
                                </TabPanel>
                            </DetailsBoxTabs>

                            {detailsTab === "main" && (
                                <GroupDetailsTabMain selectedGroup={selectedGroup} availableRoles={availableRoles} />
                            )}
                        </DetailsBox>
                    </EventsLayoutSecondary>
                )}
            </CultureContextConsumer>
        );
    }
}

export default withSnackbar(GroupDetails);

import RolesGroupsModal from "./RolesGroupsModal";
import { CreateRolesGroup } from "../../../../../../services/roles";
import { withSnackbar } from "notistack";
import PubSub from "pubsub-js";
import { CLOSE_ALL_MODALS, ROLE_GROUP_CREATED } from "../../rolesSettingsTopics";
import { withCultureContext } from "../../../../../../contexts/cultureContext/CultureContext";

const initialRolesGroupValues = {
    groupName: "",
    properties: { comment: "" },
};

class CreateRolesGroupModal extends RolesGroupsModal {
    constructor(props) {
        const { strings } = props;

        super(props);
        this.title = strings("Создать группу ролей");
        this.state = {
            values: initialRolesGroupValues,
        };
    }

    saveHandler = async () => {
        const { strings } = this.props;
        const { values } = this.state;
        const group = this.rolesGroupFromValues(values);
        const [status, statusCode, response] = await CreateRolesGroup(group);

        if (status) {
            this.props.enqueueSnackbar(`${strings("Группа ролей")} '${group.name}' ${strings("создан")}`, {
                variant: "success",
            });
            PubSub.publish(ROLE_GROUP_CREATED, { ...group, id: response.id });
            PubSub.publish(CLOSE_ALL_MODALS);
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для создания группы ролей")} '${group.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                case 409: // conflict
                    switch (response.code) {
                        case "conflict-name":
                            this.props.enqueueSnackbar(
                                `${strings("Группа ролей с именем")} '${group.name}' ${strings("уже существует")}`,
                                {
                                    variant: "warning",
                                }
                            );
                            break;
                        default:
                            this.props.enqueueSnackbar(strings("Неизвестный конфликт"), { variant: "warning" });
                            break;
                    }

                    break;

                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось создать группу ролей")} '${group.name}'`, {
                        variant: "error",
                    });
                    break;
            }
        }
    };
}

export default withCultureContext(withSnackbar(CreateRolesGroupModal));

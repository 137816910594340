import { ModalSection, Row, Text, TextField } from "headpoint-react-components";
import { useDispatch, useSelector } from "react-redux";
import { saveControlZonesState } from "../../../../../app/reducers/controlZonesReducer";
import { withCultureContext } from "../../../../../contexts/cultureContext/CultureContext";
import { FilterAccordion } from "../../../../components/Filters";
import React, { useEffect, useState } from "react";
import { GetDevices, SearchDevices } from "../../../../../services/devices";
import { withSnackbar } from "notistack";
import { FillLocations, FillNode, GetLocations } from "../../../../../services/locations";
import { nanoid } from "nanoid";
import { SelectedCounter } from "../../../../components/SelectedCounter/SelectedCounter";

const DEVICES_LIMIT = 100;

const ModalZoneStepDevices = ({ enqueueSnackbar, strings }) => {
    const dispatch = useDispatch();

    const values = useSelector((state) => state.controlZones.values);
    const [search, setSearch] = useState("");
    const [searchList, setSearchList] = useState([]);
    const [locationsTree, setLocationsTree] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [devices, setDevices] = useState([]);
    const saveValues = (name, value) => {
        dispatch(saveControlZonesState({ values: { ...values, [name]: value } }));
    };

    useEffect(() => {
        const getDevices = async () => {
            const [getLocationsStatus, locations] = await GetLocations();
            if (!getLocationsStatus) {
                enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            }

            const tree = FillLocations(locations, []);
            setLocationsTree(tree);
        };

        getDevices();
    }, [dispatch, strings, enqueueSnackbar]);

    const searchNodes = async (like) => {
        const [devicesStatus, foundDevices] = await SearchDevices({ like, limit: DEVICES_LIMIT });

        if (!devicesStatus) {
            enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        setSearch(like);
        setSearchList(foundDevices ?? []);
    };

    const openLocation = async (locationNode) => {
        const [getDevicesStatus, locationDevices] = await GetDevices(locationNode.id);
        if (!getDevicesStatus) {
            enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        const [getLocationsStatus, children] = await GetLocations(locationNode.id);
        if (!getLocationsStatus) {
            enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        if (locationNode.isClosed) {
            return;
        } else {
            FillNode(locationNode, children, locationDevices);
        }

        const deviceLocationTree = updateTree(locationsTree);

        setDevices(locationDevices);
        setLocationsTree(deviceLocationTree);
    };

    const updateTree = (tree) => {
        tree?.forEach((node) => {
            node.accordionId = nanoid();
            if (node.children) {
                updateTree(node.children);
            }
        });
        return tree;
    };

    const sources = search ? searchList : locationsTree;
    return (
        <ModalSection>
            <ModalSection>
                <Row justify="between" align="bottom" marginBottom={24}>
                    <Text variant="body">{strings("Устройства")}</Text>
                    <SelectedCounter
                        count={values.deviceIds?.length ?? 0}
                        onClear={() => saveValues("deviceIds", [])}
                        strings={strings}
                    />
                </Row>
                <Row marginBottom={12}>
                    <TextField
                        autoComplete={false}
                        colorVariant="light"
                        fullWidth
                        icon="search"
                        onChange={(e) => searchNodes(e.target.value)}
                        placeholder={strings("Найти")}
                        type="search"
                        value={search}
                    />
                </Row>
                <FilterAccordion
                    items={sources}
                    selected={values.deviceIds ?? []}
                    blockParentCheckbox={true}
                    selectHandler={(e) => {
                        saveValues("deviceIds", e);
                    }}
                    openItem={(location) => openLocation(location)}
                />
            </ModalSection>
        </ModalSection>
    );
};

export default withCultureContext(withSnackbar(ModalZoneStepDevices));

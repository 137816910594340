import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { CheckToken, ClearUserInfo } from "./services/authentication";
import { SnackbarProvider } from "notistack";
import { GlobalStyles, darkTheme, lightTheme } from "./theme";
import Router from "./routing/Router";
import { GeneralContextProvider } from "./contexts";
import "./index.css";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import { CultureContextProvider } from "./contexts/cultureContext/CultureContext";
import { IsLicenseValid } from "./services/license";
import { TooltipContextProvider } from "headpoint-react-components";

const storage = window.localStorage;

const main = async () => {
    setInterval(async () => {
        if (!(await IsLicenseValid())) {
            window.location.href = "/";
            return;
        }

        let token = storage.getItem("token");

        if (token && (await CheckToken(token))) {
            return;
        }

        ClearUserInfo();

        if (window.location.pathname !== "/login") {
            window.location.href = "/login";
        }
    }, 5000);

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SnackbarProvider
                        maxSnack={5}
                        style={{
                            maxWidth: "700px",
                            whiteSpace: "pre-line",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            wordBreak: "break-all",
                        }}
                    >
                        <ThemeProvider theme={storage.getItem("light") ? lightTheme : darkTheme}>
                            <GlobalStyles />
                            <CultureContextProvider culture={"ru"}>
                                <GeneralContextProvider>
                                    <TooltipContextProvider>
                                        <Router />
                                    </TooltipContextProvider>
                                </GeneralContextProvider>
                            </CultureContextProvider>
                        </ThemeProvider>
                    </SnackbarProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>,
        document.getElementById("root")
    );
};

void main();

import React, { createRef } from "react";
import { CameraLayoutRow, CameraLayoutWrapper } from "../../../components/CameraLayout";
import {
    checkFeatureByDeviceType,
    GeneralContextConsumer,
    getAdapters,
    getEventTypes,
    getSystemVariableValue,
} from "../../../../contexts";
import ProgressWrapper from "../../../components/ProgressWrapper";
import {
    Button,
    VideoControlBtn,
    VideoControlsDivider,
    VideoControlsMenu,
    VideoControlsMenuItem,
    VideoControlsMenuTitle,
    VideoControlsTimecode,
    VideoWrapper,
} from "headpoint-react-components";
import { SyncArchivePlayerWrapper } from "./SyncArchivePlayerWrapper";
import { withSnackbar } from "notistack";
import moment from "moment/moment";
import { BeginOfArchiveRecord, formatDate } from "../../../../services/ErrorMessages/archiveErrorMessages";
import { nanoid } from "nanoid";
import { createFFmpeg } from "@ffmpeg/ffmpeg";
import { throttle } from "throttle-debounce";
import { Mutex } from "async-mutex";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { CheckStatus, SaveScreenshot } from "../../../../services/screenshot";
import { DownloadFile } from "../../../../services/storage";

const ffmpeg = createFFmpeg({ log: false, corePath: "/ffmpeg/ffmpeg-core.js" });

const ARCHIVE_SCALE_FEATURE_CODE = "video.scale";

export const DEFAULT_MODE = 1;
export const FRAME_MODE = -1;

const PLAYER_MODE = [
    { label: "Покадровый", value: FRAME_MODE },
    { label: "Обычный", value: DEFAULT_MODE },
];

const SCALE_OPTIONS = [0.5, 1, 1.5, 2, 4, 8, 16, 32];

const DIRECTION_SCALE_OPTIONS = [
    { label: "Вперед", value: 1 },
    { label: "Назад", value: -1 },
];

class SyncCameraFrames extends React.Component {
    constructor(props) {
        super(props);

        this.convertToPng = this.convertToPng.bind(this);
        this.lock = new Mutex();

        this.wrapperPlayerRefs = [];

        this.throttleHandleNextScreenshotFrame = throttle(
            700,
            (...args) => void this.handleNextScreenshotFrame(...args),
            {
                noLeading: false,
                noTrailing: false,
            }
        );

        this.throttleHandlePreviousScreenshotFrame = throttle(
            700,
            (...args) => void this.handlePreviousScreenshotFrame(...args),
            {
                noLeading: false,
                noTrailing: false,
            }
        );

        this.state = {
            mainOffset: 0,
            playing: false,
            scale: 1,
            scaleDirection: 1,
            playerMode: DEFAULT_MODE,
            marks: [],
        };
    }

    async componentDidMount() {
        if (!ffmpeg.isLoaded()) {
            await ffmpeg.load();
        }

        this.wrapperPlayerRefs = Object.values(this.props.frames).map(() => createRef());
    }

    getPlayerRef(ref) {
        return ref?.current?.archiveRef?.current;
    }

    writeToFfmpegMemory(sourceImage, image) {
        ffmpeg.FS("writeFile", sourceImage, image);
    }

    async convertToPng(sourceImage, outputImage) {
        const release = await this.lock.acquire();

        try {
            await ffmpeg.run("-i", sourceImage, "-frames:", "1", outputImage);
        } finally {
            release();
        }
    }

    readFromFfmpegMemory(outputImage) {
        return ffmpeg.FS("readFile", outputImage);
    }

    removeFromFfmpegMemory(fileName) {
        try {
            // удаляем предыдущий скриншот, чтобы не засорять память
            ffmpeg.FS("unlink", fileName);
        } catch (e) {
            // пропускаем ошибку удаления несуществуюшего файла, так как при первой конвертации нельзя проверить есть ли файл в памяти
        }
    }

    async setScale(scale) {
        this.setState({ scale: scale });

        await this.stop();
        this.start();
    }

    async setScaleDirection(direction) {
        this.setState({ scaleDirection: direction });

        await this.stop();
        this.start();
    }

    scaleVideoFeatureExists(cameraFrame) {
        const { generalInfo } = this.props;

        const adapters = getAdapters(generalInfo) ?? [];
        return checkFeatureByDeviceType(cameraFrame?.typeId, adapters, ARCHIVE_SCALE_FEATURE_CODE);
    }

    async setPlayerMode(mode) {
        const { enqueueSnackbar, strings } = this.props;

        if (mode === FRAME_MODE) {
            if (!ffmpeg.isLoaded()) {
                enqueueSnackbar(strings(`ffmpeg не загружен`), {
                    variant: "warning",
                });
                return;
            }

            for (const ref of this.wrapperPlayerRefs) {
                const refPlayer = this.getPlayerRef(ref);

                const cameraFrame = refPlayer.state.currentFrame;
                let scaleVideoFeatureExists = this.scaleVideoFeatureExists(cameraFrame);

                if (!scaleVideoFeatureExists) {
                    enqueueSnackbar(
                        `${strings("Нет сущности")} ${ARCHIVE_SCALE_FEATURE_CODE} ${strings("у камеры")} ${
                            cameraFrame.name
                        } `,
                        {
                            variant: "warning",
                        }
                    );

                    return;
                }
            }
        }
        this.setState({ playerMode: mode });
    }

    handlePreviousScreenshotFrame = async () => {
        const { mainOffset } = this.state;
        const { intersection, enqueueSnackbar, strings } = this.props;

        const newOffset = mainOffset - 1;

        if (newOffset < 0) {
            enqueueSnackbar(
                `${strings("Закончились пересечения архивных записей за")} ${formatDate(
                    intersection.from,
                    strings
                )} - ${formatDate(intersection.to, strings)}`,
                {
                    variant: "success",
                }
            );

            return;
        }

        this.setState({ mainOffset: newOffset }, () => {
            for (const ref of this.wrapperPlayerRefs) {
                const refPlayer = this.getPlayerRef(ref);

                if (refPlayer) {
                    refPlayer.convertImage(intersection.from + newOffset);
                }
            }
        });
    };

    handleNextScreenshotFrame = async () => {
        const { mainOffset } = this.state;
        const { intersection, enqueueSnackbar, strings } = this.props;

        const newOffset = mainOffset + 1;

        if (newOffset > intersection.to - intersection.from) {
            enqueueSnackbar(
                `${strings("Закончились пересечения архивных записей за")} ${formatDate(
                    intersection.from,
                    strings
                )} - ${formatDate(intersection.to, strings)}`,
                {
                    variant: "success",
                }
            );

            return;
        }

        this.setState({ mainOffset: newOffset }, () => {
            for (const ref of this.wrapperPlayerRefs) {
                const refPlayer = this.getPlayerRef(ref);

                if (refPlayer) {
                    refPlayer.convertImage(intersection.from + newOffset);
                }
            }
        });
    };

    handleCameraFrameSelect = (cameraId) => {
        const { selectedCameraFrame, setSelectedCameraFrame } = this.props;

        const nextValue = selectedCameraFrame === cameraId ? null : cameraId;
        setSelectedCameraFrame(nextValue);
    };

    handleCameraFrameClear = (frameId) => {
        const { cameraFrameClear } = this.props;

        cameraFrameClear(frameId);
    };

    toEvents(event, device) {
        this.props.history.push({
            pathname: "/events",
            state: { type: "ShowSpecificEventCard", event, device },
        });
    }

    async getSnapshots() {
        const { enqueueSnackbar, strings } = this.props;

        const devicesData = []

        for (const ref of this.wrapperPlayerRefs) {
            const refPlayer = ref?.current?.archiveRef?.current;

            const deviceData = {deviceId: refPlayer.state.currentFrame.id, time: refPlayer.state.captureTime}
            devicesData.push(deviceData)
        }

        const body = {devices: devicesData}

        const [success, orderIds] = await SaveScreenshot(body);
        if (!success) {
            enqueueSnackbar(strings("Не удалось сохранить скриншоты"), { variant: "error" });
            return;
        }

        for (const orderId of orderIds) {
            let interval = setInterval(async function(){
                const [successCheckStatus, order] = await CheckStatus(orderId)
                if (!successCheckStatus) {
                    enqueueSnackbar(strings("Не удалось получить статус скриншота"), { variant: "error" });
                    clearInterval(interval);
                    return;
                }
                switch (order.status) {
                    case "InProgress":
                        break;
                    case "Error":
                        enqueueSnackbar(strings("Ошибка при сохранении скриншота"), { variant: "error" });
                        clearInterval(interval);
                        break;
                    case "Succeed":
                        const cameraName = order.properties.deviceName;
                        const screenShotText = `${cameraName}-${moment.unix(order.captureTimestamp).format("YYYY-MM-DD_HH:mm:ss")}.png`;

                        await DownloadFile(order.properties.screenshotFileId, screenShotText)
                        clearInterval(interval);
                        break;
                    default:
                        break;
                }

            }, 1000);
        }
    }

    pause = async () => {
        await this.stop();
        this.setState({ playing: false });
    };

    seek = async (nextOffsetTime, isFinal) => {
        const { mainOffset } = this.state;

        if (!nextOffsetTime || mainOffset === nextOffsetTime) {
            return;
        }

        if (isFinal) {
            await this.stop();

            this.setState({ mainOffset: nextOffsetTime }, () => {
                this.start();
            });
        }
    };

    rewindSeconds = async (s) => {
        let { mainOffset } = this.state;

        mainOffset += s;

        if (!mainOffset || mainOffset < 0) {
            mainOffset = 0;
        }

        await this.stop();

        this.setState({ mainOffset }, () => {
            this.start();
        });
    };

    start = () => {
        for (const ref of this.wrapperPlayerRefs) {
            const playerRef = this.getPlayerRef(ref);

            if (playerRef) {
                playerRef.start(playerRef.state.currentFrame);
            }
        }
    };

    stop = async () => {
        for (const ref of this.wrapperPlayerRefs) {
            const playerRef = this.getPlayerRef(ref);

            if (playerRef) {
                await playerRef.stop(playerRef.peer);
            }
        }
    };

    handleMainOffset = async (offset) => {
        const { mainOffset, scaleDirection, playing } = this.state;
        const { intersection, enqueueSnackbar, strings } = this.props;

        if (scaleDirection < 0) {
            this.setState({ mainOffset: offset, playing: true });
        } else {
            if (offset > mainOffset) {
                this.setState({ mainOffset: offset, playing: true });
            }
        }

        if (offset >= intersection.to - intersection.from) {
            await this.stop();

            if (playing) {
                enqueueSnackbar(
                    `${strings("Закончились пересечения архивных записей за")} ${formatDate(
                        intersection.from,
                        strings
                    )} - ${formatDate(intersection.to, strings)}`,
                    {
                        variant: "success",
                    }
                );

                this.setState({ playing: false });
            }
        } else if (offset <= 0 && scaleDirection === -1) {
            await this.stop();

            enqueueSnackbar(BeginOfArchiveRecord(), {
                variant: "success",
            });

            this.setState({ playing: false });
        }
    };

    handleCurrentLayoutFrame = () => {
        const { mainOffset, scale, scaleDirection, playerMode } = this.state;
        const { currentLayout, showCameraInfo, frames, selectedCameraFrame, dates, intersection } = this.props;

        return (
            <>
                {currentLayout === "1" && (
                    <CameraLayoutRow>
                        <SyncArchivePlayerWrapper
                            removeFromFfmpegMemory={this.removeFromFfmpegMemory}
                            writeToFfmpegMemory={this.writeToFfmpegMemory}
                            convertToPng={this.convertToPng}
                            readFromFfmpegMemory={this.readFromFfmpegMemory}
                            ref={this.wrapperPlayerRefs[0]}
                            key={0}
                            playerMode={playerMode}
                            scaleDirection={scaleDirection}
                            scale={scale}
                            intersection={intersection}
                            dates={dates}
                            showCameraInfo={showCameraInfo}
                            frameIndex={0}
                            handleMainOffset={this.handleMainOffset}
                            mainOffset={mainOffset}
                            frames={frames}
                            selectedCameraFrame={selectedCameraFrame}
                            handleCameraFrameSelect={this.handleCameraFrameSelect}
                            handleCameraFrameClear={this.handleCameraFrameClear}
                        />
                    </CameraLayoutRow>
                )}

                {currentLayout === "2" && (
                    <CameraLayoutRow>
                        {[0, 1].map((i) => (
                            <SyncArchivePlayerWrapper
                                removeFromFfmpegMemory={this.removeFromFfmpegMemory}
                                writeToFfmpegMemory={this.writeToFfmpegMemory}
                                convertToPng={this.convertToPng}
                                readFromFfmpegMemory={this.readFromFfmpegMemory}
                                playerMode={playerMode}
                                intersection={intersection}
                                ref={this.wrapperPlayerRefs[i]}
                                scaleDirection={scaleDirection}
                                key={i}
                                scale={scale}
                                dates={dates}
                                showCameraInfo={showCameraInfo}
                                handleMainOffset={this.handleMainOffset}
                                mainOffset={mainOffset}
                                frameIndex={i}
                                frames={frames}
                                selectedCameraFrame={selectedCameraFrame}
                                handleCameraFrameSelect={this.handleCameraFrameSelect}
                                handleCameraFrameClear={this.handleCameraFrameClear}
                            />
                        ))}
                    </CameraLayoutRow>
                )}

                {currentLayout === "4" && (
                    <React.Fragment>
                        <CameraLayoutRow rowHeight="50%">
                            {[0, 1].map((i) => (
                                <SyncArchivePlayerWrapper
                                    removeFromFfmpegMemory={this.removeFromFfmpegMemory}
                                    writeToFfmpegMemory={this.writeToFfmpegMemory}
                                    convertToPng={this.convertToPng}
                                    readFromFfmpegMemory={this.readFromFfmpegMemory}
                                    playerMode={playerMode}
                                    scaleDirection={scaleDirection}
                                    scale={scale}
                                    mainOffset={mainOffset}
                                    intersection={intersection}
                                    ref={this.wrapperPlayerRefs[i]}
                                    key={i}
                                    dates={dates}
                                    handleMainOffset={this.handleMainOffset}
                                    showCameraInfo={showCameraInfo}
                                    frameIndex={i}
                                    frames={frames}
                                    selectedCameraFrame={selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>

                        <CameraLayoutRow rowHeight="50%">
                            {[2, 3].map((i) => (
                                <SyncArchivePlayerWrapper
                                    removeFromFfmpegMemory={this.removeFromFfmpegMemory}
                                    writeToFfmpegMemory={this.writeToFfmpegMemory}
                                    convertToPng={async () => await this.convertToPng()}
                                    readFromFfmpegMemory={this.readFromFfmpegMemory}
                                    playerMode={playerMode}
                                    scaleDirection={scaleDirection}
                                    scale={scale}
                                    intersection={intersection}
                                    ref={this.wrapperPlayerRefs[i]}
                                    key={i}
                                    dates={dates}
                                    handleMainOffset={this.handleMainOffset}
                                    showCameraInfo={showCameraInfo}
                                    frameIndex={i}
                                    frames={frames}
                                    mainOffset={mainOffset}
                                    selectedCameraFrame={selectedCameraFrame}
                                    handleCameraFrameSelect={this.handleCameraFrameSelect}
                                    handleCameraFrameClear={this.handleCameraFrameClear}
                                />
                            ))}
                        </CameraLayoutRow>
                    </React.Fragment>
                )}
            </>
        );
    };

    render() {
        const { playing, mainOffset, scale, scaleDirection, playerMode } = this.state;
        const { intersection, events, generalInfo, frames, strings } = this.props;

        const marks = events.map((ev) => {
            const seekToEventDelta = getSystemVariableValue(
                generalInfo,
                "service.api.events",
                "events.archive.delta",
                5
            );
            const eventType = getEventTypes(generalInfo).find((info) => info.value === ev.type);

            const duration = intersection.to - intersection.from;
            const offset = moment(ev.eventDate).unix() - intersection.from;

            return {
                id: nanoid(),
                position: (100.0 * offset) / duration,
                data: [
                    {
                        label: strings("Камера"),
                        value: ev.deviceName,
                    },
                    {
                        label: strings("Тип события"),
                        value: strings(eventType?.label),
                    },
                    {
                        label: strings("Дата"),
                        value: `${moment(ev.eventDate).format("DD.MM.YYYY HH:mm:ss")}`,
                    },
                    {
                        label: "",
                        value: (
                            <Button
                                key={ev.id}
                                onClick={() =>
                                    this.toEvents(
                                        ev,
                                        Object.values(frames).find((f) => f.id === ev.deviceId)
                                    )
                                }
                                label={strings("Перейти в журнал")}
                            />
                        ),
                    },
                ],
                onClick: async () => {
                    const eventTimeCode = moment(ev.eventDate).unix() - intersection.from;
                    await this.seek(eventTimeCode - seekToEventDelta, true);
                },
            };
        });

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <CameraLayoutWrapper>
                            {Object.values(frames).length > 0 ? (
                                <VideoWrapper
                                    videoElement={this.handleCurrentLayoutFrame(generalInfo)}
                                    progress={
                                        <ProgressWrapper
                                            rangeStart={intersection.from}
                                            rangeEnd={intersection.to}
                                            marks={marks}
                                            position={mainOffset}
                                            seek={this.seek}
                                            setOffset={(offset, v) => v}
                                        />
                                    }
                                    mainControls={
                                        <React.Fragment>
                                            {playerMode === FRAME_MODE ? (
                                                <>
                                                    <VideoControlBtn
                                                        icon="arrow-left"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.throttleHandlePreviousScreenshotFrame();
                                                        }}
                                                        title="Prev"
                                                    />
                                                    <VideoControlBtn
                                                        icon="arrow-right"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.throttleHandleNextScreenshotFrame();
                                                        }}
                                                        title="Next"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {playing ? (
                                                        <VideoControlBtn
                                                            title={strings("Пауза")}
                                                            icon="pause"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.pause();
                                                            }}
                                                        />
                                                    ) : (
                                                        <VideoControlBtn
                                                            title={strings("Начать")}
                                                            icon="play"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.start();
                                                            }}
                                                        />
                                                    )}
                                                    <VideoControlBtn
                                                        title={strings("Назад")}
                                                        icon="s-rewind"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.rewindSeconds(-15);
                                                        }}
                                                    />
                                                    <VideoControlBtn
                                                        title={strings("Вперед")}
                                                        icon="s-forward"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.rewindSeconds(15);
                                                        }}
                                                    />
                                                </>
                                            )}
                                            <VideoControlsDivider />
                                            <VideoControlBtn
                                                title={strings("Снимок экрана")}
                                                icon="screenshot"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.getSnapshots();
                                                }}
                                            />
                                            <VideoControlsTimecode
                                                text={`${moment
                                                    .unix(intersection.from + mainOffset)
                                                    .format("DD.MM.YYYY HH:mm:ss")}`}
                                            />
                                        </React.Fragment>
                                    }
                                    toolbox={
                                        <>
                                            <VideoControlsMenu
                                                align="right"
                                                icon="settings"
                                                toggleTitle={strings("Настройки")}
                                            >
                                                <VideoControlsMenuTitle title={strings("скорость")} />
                                                {SCALE_OPTIONS.map((opt, id) => {
                                                    return (
                                                        <VideoControlsMenuItem
                                                            key={id}
                                                            onClick={async () => await this.setScale(opt)}
                                                            label={`${opt.toString()}x`}
                                                            isSelected={opt === scale}
                                                        />
                                                    );
                                                })}
                                                <VideoControlsMenuTitle title={strings("Направление")} />
                                                {DIRECTION_SCALE_OPTIONS.map((opt, id) => {
                                                    return (
                                                        <VideoControlsMenuItem
                                                            key={id}
                                                            onClick={async () =>
                                                                await this.setScaleDirection(opt.value)
                                                            }
                                                            label={strings(opt.label)}
                                                            isSelected={opt.value === scaleDirection}
                                                        />
                                                    );
                                                })}
                                                <VideoControlsMenuTitle title={strings("Режим")} />
                                                {PLAYER_MODE.map((opt, id) => {
                                                    return (
                                                        <VideoControlsMenuItem
                                                            key={id}
                                                            onClick={async () => await this.setPlayerMode(opt.value)}
                                                            label={strings(opt.label)}
                                                            isSelected={opt.value === playerMode}
                                                        />
                                                    );
                                                })}
                                            </VideoControlsMenu>
                                        </>
                                    }
                                    withFullscreen
                                    fullscreenToggleTitle={strings("На весь экран")}
                                />
                            ) : (
                                this.handleCurrentLayoutFrame(generalInfo)
                            )}
                        </CameraLayoutWrapper>
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

export default withCultureContext(withSnackbar(SyncCameraFrames));

import { ModalSection, Select } from "headpoint-react-components";
import { DYNAMIC_FILTER_TYPE, FIXED_FILTER_TYPE } from "../constants";
import { ModalFormItem } from "../../components/CreateUpdateWizard/Styled";
import { useDispatch, useSelector } from "react-redux";
import { saveScheduleState } from "../../../app/reducers/scheduleReducer";
import ScheduleDevicesFixedFilter from "./FeatureComponents/FilterComponents/ScheduleDevicesFixedFilter";
import ScheduleDevicesDynamicFilter from "./FeatureComponents/FilterComponents/ScheduleDevicesDynamicFilter";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

const ModalStepFilters = ({ strings }) => {
    const types = [
        {
            label: strings("Фиксированный список"),
            value: FIXED_FILTER_TYPE,
        },
        {
            label: strings("Динамический список"),
            value: DYNAMIC_FILTER_TYPE,
        },
    ];

    const dispatch = useDispatch();
    const values = useSelector((state) => state.schedule.values);

    const changeType = (type) => {
        if (type === FIXED_FILTER_TYPE) {
            dispatch(
                saveScheduleState({
                    values: { ...values, filterType: type, tagsFilter: [], groupsFilter: [], typesFilter: [] },
                })
            );
        }

        if (type === DYNAMIC_FILTER_TYPE) {
            dispatch(saveScheduleState({ values: { ...values, filterType: type, deviceIdsFilter: [] } }));
        }
    };

    return (
        <>
            <ModalSection>
                <ModalFormItem>
                    <Select
                        label={strings("Тип формирования состава оборудования")}
                        placeholder={strings("Выберите тип")}
                        value={values.filterType}
                        onChange={(e) => changeType(e)}
                        options={types.map((t) => ({
                            value: t.value,
                            label: strings(t.label),
                        }))}
                        fullWidth
                        error={!values.filterType ? strings("Поле не может быть пустым") : null}
                    />
                </ModalFormItem>
            </ModalSection>
            {values.filterType === FIXED_FILTER_TYPE && <ScheduleDevicesFixedFilter />}
            {values.filterType === DYNAMIC_FILTER_TYPE && <ScheduleDevicesDynamicFilter />}
        </>
    );
};

export default withCultureContext(ModalStepFilters);

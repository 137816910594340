import React from "react";
import { withSnackbar } from "notistack";
import PubSub from "pubsub-js";
import { APPLY_PRESET_TOPIC_PREFIX, CRUD_PRESETS_TOPIC_PREFIX, SHOW_STREAM_PLAYER_DIALOG_PREFIX } from "./streamTopics";
import { permissionExists } from "../../../contexts";
import { GetPresets } from "../../../services/presets";
import { VideoCameraControlsBtn, VideoCameraControlsPresets } from "headpoint-react-components";
import { Allow } from "../../../services/priorities";
import { connect } from "react-redux";
import { withCultureContext } from "../../../contexts/cultureContext/CultureContext";

class Presets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            presets: [],
            currentPreset: null,
        };
    }

    async componentDidMount() {
        const { currentFrame, enqueueSnackbar, strings } = this.props;

        await this.loadPresets();

        this.crudPresetsTopic = PubSub.subscribe(CRUD_PRESETS_TOPIC_PREFIX + currentFrame.id, async (msg, data) => {
            let { presets, currentPreset } = this.state;
            const {
                type,
                data: { preset },
            } = data;

            if (!type || !preset) {
                enqueueSnackbar(strings("В объекте отсутствует информация!"), { variant: "error" });
                console.error(data);
                return;
            }

            switch (type) {
                case "create":
                    this.setState({
                        presets: [...presets, preset].sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        }),
                    });
                    break;
                case "update":
                    presets = presets.filter((p) => p.id !== preset.id);
                    this.setState({ presets: [...presets, preset] });
                    break;
                case "delete":
                    presets = presets.filter((p) => p.id !== preset.id);
                    this.setState({ presets, currentPreset: currentPreset === preset.id ? null : currentPreset });
                    break;
                default:
                    enqueueSnackbar(`${strings("Неизвестный тип операции:")} ${type}!`, { variant: "error" });
                    console.error(data);
                    return;
            }
        });

        this.applyPresetTopic = PubSub.subscribe(APPLY_PRESET_TOPIC_PREFIX + currentFrame.id, async (msg, data) => {
            const { type, data: info } = data;
            switch (type) {
                case "applied":
                    this.setState({ currentPreset: info?.preset?.id });
                    break;
                case "reset":
                    this.setState({ currentPreset: null });
                    break;
                default:
                    enqueueSnackbar(`${strings("Неизвестный тип операции:")} ${type}!`, { variant: "error" });
                    console.error(data);
                    return;
            }
        });
    }

    componentWillUnmount() {
        if (this.crudPresetsTopic) {
            PubSub.unsubscribe(this.crudPresetsTopic);
        }

        if (this.applyPresetTopic) {
            PubSub.unsubscribe(this.applyPresetTopic);
        }
    }

    loadPresets = async () => {
        const { userInfo, currentFrame, enqueueSnackbar, strings } = this.props;

        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        if (!permissionExists(userInfo, ["presets.access"])) {
            return;
        }

        if (!currentFrame?.id) {
            enqueueSnackbar(strings("Для загрузки пресетов камера должна иметь ID!"), { variant: "error" });
            return;
        }

        const [status, presets] = await GetPresets(currentFrame?.id);
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения пресетов"), { variant: "error" });
            return;
        }

        this.setState({
            presets: presets.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            }),
        });
    };

    async allowSetPtzPreset(id) {
        const { strings } = this.props;

        const [success, statusCode] = await Allow("ptz", id);
        if (!success) {
            if (statusCode === 423) {
                this.props.enqueueSnackbar(strings("Управление камерой заблокировано"), {
                    variant: "warning",
                });
            } else {
                this.props.enqueueSnackbar(strings("Не удалось проверить приоритет пользователя"), {
                    variant: "error",
                });
            }

            return false;
        }

        return true;
    }

    render() {
        const { presets, currentPreset } = this.state;
        const { userInfo, currentFrame, strings } = this.props;

        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <>
                <VideoCameraControlsPresets
                    presets={presets?.map((p) => ({
                        ...p,
                        label: p.name,
                    }))}
                    currentPreset={currentPreset}
                    onSelect={async (id) => {
                        const allowed = await this.allowSetPtzPreset(currentFrame.id);
                        if (allowed) {
                            let preset = presets?.find((p) => p?.id === id);
                            PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + currentFrame?.id, {
                                type: "applyPreset",
                                data: {
                                    preset: preset,
                                    camera: currentFrame,
                                },
                            });
                        }
                    }}
                    handleDelete={(id) => {
                        PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + currentFrame?.id, {
                            type: "deletePreset",
                            data: {
                                preset: presets?.find((p) => p?.id === id),
                                camera: currentFrame,
                            },
                        });
                    }}
                    handleSave={(id) => {
                        PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + currentFrame?.id, {
                            type: "updatePreset",
                            data: {
                                preset: presets?.find((p) => p?.id === id),
                                camera: currentFrame,
                            },
                        });
                    }}
                    placeholder={strings("Выбрать пресет")}
                    isChanged={false}
                />

                {(permissionExists(userInfo, ["presets.personal.lifeCycle"]) ||
                    permissionExists(userInfo, ["presets.group.lifeCycle"]) ||
                    permissionExists(userInfo, ["presets.system.lifeCycle"])) && (
                    <VideoCameraControlsBtn
                        onClick={() => {
                            PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + currentFrame?.id, {
                                type: "createPreset",
                                data: {
                                    camera: currentFrame,
                                },
                            });
                        }}
                        label={strings("Создать пресет")}
                        align="left"
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(Presets)));

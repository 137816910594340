export const EVENTS_ON_PAGE = 10;

export const eventImportance = [
    {
        code: "danger",
        label: "Тревожные",
        color: "danger",
    },
    {
        code: "warning",
        label: "Требующие внимания",
        color: "warning",
    },
    {
        code: "primary",
        label: "Обычные",
        color: "primary",
    },
    {
        code: "info",
        label: "Обычные",
        color: "info",
    },
];

export const PERSONAL_EVENT_TYPE = "personal";
export const GROUP_EVENT_TYPE = "group";
export const SYSTEM_EVENT_TYPE = "system";

export const VIEW_FILTERS = "events.service.filters.view";
export const EDIT_PERSONAL_FILTERS = "events.service.filters.edit.personal";
export const EDIT_GROUP_FILTERS = "events.service.filters.edit.group";
export const EDIT_ALL_GROUP_FILTERS = "events.service.filters.edit.group.all";
export const EDIT_SYSTEM_FILTERS = "events.service.filters.edit.system";

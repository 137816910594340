import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";

import { Checkbox } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";

export const FilterWrapper = styled.div`
    padding-top: 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.primary3};
`;

export const FilterGroup = ({
    title,
    children,
    isMuted,
    onClear,
    hasValue,
    isOpened = false,
    hasBottomLine = true,
}) => {
    const [isOpen, setOpen] = useState(isOpened);

    const handleClearAll = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (onClear) onClear();
    };

    const FilterGroup = hasBottomLine ? FilterGroupWrapper : FilterGroupNoBottomLineWrapper;

    return (
        <FilterGroup>
            <FilterGroupToggle type="button" onClick={() => setOpen(!isOpen)} isOpen={isOpen} isMuted={isMuted}>
                <span>{title}</span>
                {hasValue && <FilterGroupClearBtn type="button" onClick={handleClearAll} />}
            </FilterGroupToggle>

            <FilterGroupChildren isOpen={isOpen}>
                <FilterGroupChildrenInner>{children}</FilterGroupChildrenInner>
            </FilterGroupChildren>
        </FilterGroup>
    );
};

const FilterGroupNoBottomLineWrapper = styled.div`
    max-height: 500px;
`;

const FilterGroupWrapper = styled.div`
    max-height: 500px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary3};
`;

const iconStyle = css`
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

const FilterGroupToggle = styled.div`
    position: relative;
    padding: 12px 70px 12px 16px;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 16px;
    transition: color 0.16s linear;

    &:after {
        content: "\\e905";

        ${iconStyle};

        position: absolute;
        right: 16px;
        top: 50%;
        z-index: 1;
        margin-top: -7px;

        ${({ isOpen }) =>
            isOpen &&
            css`
                transform: scale(1, -1);
            `}

        color: ${({ isOpen, theme }) => (isOpen ? theme.colors.secondary : rgba(theme.colors.secondary, 0.5))};
        transition: color 0.16s linear;
    }

    & span {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;

        ${({ isMuted }) =>
            isMuted &&
            css`
                opacity: 0.5;
            `}
    }

    ${({ isOpen }) =>
        isOpen &&
        css`
            & span {
                opacity: 1;
            }
        `}
`;

const FilterGroupClearBtn = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    position: absolute;
    top: 12px;
    right: 38px;
    z-index: 1;
    color: ${({ theme }) => theme.colors.blueAlt};
    cursor: pointer;

    &:before {
        content: "\\e934";

        ${iconStyle};
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

const FilterGroupChildren = styled.div`
    overflow-y: auto;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.16s linear, opacity 0.16s linear;

    ${({ isOpen }) =>
        isOpen &&
        css`
            max-height: 500px;
            opacity: 1;
        `}
`;

const FilterGroupChildrenInner = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 0 16px 16px 16px;
    width: 100%;
    max-height: 500px;
`;

export const FilterGroupList = ({ maxHeight = "250px", children }) => (
    <FilterGroupListStyled>
        <FilterGroupListInner maxHeight={maxHeight}>{children}</FilterGroupListInner>
    </FilterGroupListStyled>
);

const FilterGroupListStyled = styled.div`
    overflow-y: auto;
    flex: 1;
    margin-top: 8px;
    margin-bottom: -16px;
`;

const FilterGroupListInner = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 0 0 16px 0;
    width: 100%;
    max-height: ${({ maxHeight }) => maxHeight};
`;

const getIndicatorColor = (theme, status) => {
    switch (status) {
        case "success":
            return theme.colors.green;

        case "warning":
            return theme.colors.yellow;

        case "danger":
            return theme.colors.red;

        default:
            return "";
    }
};

export const FilterItem = styled.div`
    padding: 4px 0;
    max-width: 90%;

    ${({ indicator }) =>
        indicator &&
        css`
            position: relative;
            padding-left: 8px;

            &:before {
                content: "";
                position: absolute;
                top: 9px;
                left: -8px;
                z-index: 1;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: ${({ theme }) => getIndicatorColor(theme, indicator)};
            }
        `};
`;

export const FilterButtonItem = styled.button`
    margin: 3px;
    border: none;
    background: none;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 25px;
    text-align: left;
    cursor: pointer;
    color: ${({ theme, isLight }) =>
        isLight ? rgba(theme.colors.blackText, 0.75) : rgba(theme.colors.secondary, 0.75)};
    transition: background-color 0.16s linear, color 0.16s linear;

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${({ theme, isLight }) =>
                isLight ? rgba(theme.colors.blackText, 0.05) : rgba(theme.colors.secondary, 0.1)};
            color: ${({ theme, isLight }) => (isLight ? theme.colors.blackText : theme.colors.secondary)};
        `}

    @media(hover:hover) {
        &:hover {
            color: ${({ theme, isLight }) => (isLight ? theme.colors.blackText : theme.colors.secondary)};
        }

        &:active,
        &:focus {
            color: ${({ theme, isLight }) =>
                isLight ? rgba(theme.colors.blackText, 0.75) : rgba(theme.colors.secondary, 0.75)};
        }
    }

    &:active,
    &:focus {
        outline: none;
    }
`;

export const FilterDivider = styled.div`
    margin: 10px 0;
    height: 1px;
    background-color: ${({ theme }) => rgba(theme.colors.white, 0.1)};
`;

const FilterAccordionItemChildren = styled.div`
    padding-left: 12px;

    ${({ isOpen }) =>
        !isOpen &&
        css`
            height: 0;
            overflow: hidden;
        `};
`;

const FilterAccordionItemWrapper = styled.div``;

const FilterAccordionItemParent = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
`;

const FilterAccordionItemToggle = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    min-width: 16px;
    height: 16px;
    background: none;
    border: none;
    padding: 0;
    margin: 4px 4px 4px 0;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    &:before {
        content: "${({ isOpen }) => (isOpen ? "\\e930" : "\\e931")}";
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &:focus,
    &:active {
        outline: none;
    }
`;

const FilterAccordionChild = styled.div`
    position: relative;
    padding-left: 18px;
`;

export const FilterAccordionPartion = ({
    items,
    selected,
    selectHandler,
    active = [],
    labelClickHandler = () => {},
    preOpen,
    openItem,
    itemTooltip,
}) => {
    const handleSelect = (id) => {
        const newSelected = [...selected].includes(id)
            ? [...selected].filter((item) => item !== id)
            : [...selected, id];
        selectHandler(newSelected);
    };

    return items.map((item) => (
        <CultureContextConsumer key={item.id}>
            {({ strings }) => (
                <React.Fragment key={item.id}>
                    {item.children ? (
                        <FilterAccordionGroup
                            id={item.accordionId}
                            item={item}
                            selected={selected}
                            selectHandler={selectHandler}
                            preOpen={preOpen}
                            openItem={openItem}
                        >
                            <FilterAccordionPartion
                                id={item.accordionId}
                                items={item.children}
                                selected={selected}
                                selectHandler={selectHandler}
                                active={active}
                                labelClickHandler={labelClickHandler}
                                preOpen={preOpen}
                                itemTooltip={itemTooltip}
                                openItem={openItem}
                            />
                        </FilterAccordionGroup>
                    ) : (
                        <FilterAccordionChild id={item.accordionId}>
                            <FilterItem indicator={item.indicator}>
                                <Checkbox
                                    label={strings(item.name)}
                                    checked={selected.includes(item.id)}
                                    onChange={() => handleSelect(item.id)}
                                    onLabelClick={(e) => labelClickHandler(item, e)}
                                    colorVariant={active.includes(item.id) ? "info" : "primary"}
                                />
                                {itemTooltip && itemTooltip(item)}
                            </FilterItem>
                        </FilterAccordionChild>
                    )}
                </React.Fragment>
            )}
        </CultureContextConsumer>
    ));
};

const FilterAccordionGroup = ({ id, children, item, selected, selectHandler, openItem, preOpen = false }) => {
    const [isOpen, setOpen] = useState(preOpen);

    const [state, setState] = useState();

    const [childrenIds, setChildrenIds] = useState([]);

    useEffect(() => {
        const getChildrenIds = (childItems = [], acc = []) => {
            childItems.forEach((child) => {
                if (child.children) getChildrenIds(child.children, acc);
                return child.children ? acc : acc.push(child.id);
            });

            return acc;
        };

        const newChildrenIds = getChildrenIds(item.children, []);

        setChildrenIds(newChildrenIds);
    }, [item, id]);

    useEffect(() => {
        let incl = [];

        incl = selected.filter((c) => childrenIds.includes(c));

        if (incl.length === childrenIds.length) setState("selected");

        if (incl.length !== childrenIds.length) setState("indeterminate");

        if (!incl.length) setState("");
    }, [selected, childrenIds]);

    const handleGroupSelect = (e) => {
        const { checked } = e.target;

        let newSelected = [...selected];

        if (checked) {
            childrenIds.map((c) => (newSelected.includes(c) ? c : newSelected.push(c)));
        } else {
            newSelected = newSelected.filter((c) => !childrenIds.includes(c));
        }

        selectHandler(newSelected);

        const newState = checked ? "selected" : "";

        setState(newState);
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <FilterAccordionItemWrapper>
                    <FilterAccordionItemParent>
                        <FilterAccordionItemToggle
                            type="button"
                            onClick={() => {
                                item.isClosed = isOpen;
                                let open = !isOpen;
                                setOpen(open);
                                if (open && openItem) {
                                    openItem(item);
                                }
                            }}
                            isOpen={isOpen}
                        />

                        <FilterItem>
                            <Checkbox
                                label={strings(item.name)}
                                checked={state === "selected"}
                                indeterminate={state === "indeterminate"}
                                onChange={(e) => handleGroupSelect(e)}
                            />
                        </FilterItem>
                    </FilterAccordionItemParent>

                    <FilterAccordionItemChildren isOpen={isOpen}>{children}</FilterAccordionItemChildren>
                </FilterAccordionItemWrapper>
            )}
        </CultureContextConsumer>
    );
};

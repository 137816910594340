function createPath(externalId, cameraId) {
    return `/balancer/webrtc-${externalId}/${cameraId}`;
}

export function Base64EncodeUrl(str) {
    return window.btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, ".");
}

let state = {
    videoElement: null,
    videoTag: null,
    videoStream: null,
    videoPeer: null,
};

export async function setVideoElement(videoElement, videoTag) {
    if (videoTag !== state.videoTag) {
        await closePeer();
    }

    state.videoElement = videoElement;
    state.videoTag = videoTag;
}

export function hasStream(videoTag) {
    return state.videoStream && videoTag === state.videoTag;
}

export async function startPeer(url, externalId, videoTag, rtcPeerConfig) {
    if (!state.videoElement) {
        return [false, "state has no videoelement"];
    }

    if (hasStream(videoTag)) {
        state.videoElement.srcObject = state.videoStream;
        return [true];
    }

    const peerToOpen = new RTCPeerConnection(rtcPeerConfig);
    peerToOpen.ontrack = (event) => {
        state.videoElement.srcObject = event.streams[0];
        state.videoStream = event.streams[0];
        state.videoPeer = peerToOpen;
    };

    const token = window.localStorage.getItem("token");
    var request = {
        uri: url,
        token: token,
        externalId: externalId,
    };

    const cameraId = Base64EncodeUrl(JSON.stringify(request));
    let response = await fetch(createPath(externalId, cameraId), {
        method: "GET",
    });

    if (response.status === 401) {
        return [false, "unauthorized"];
    }

    if (response.status !== 200) {
        return [false, "error"];
    }

    let data = await response.json();

    try {
        await peerToOpen.setRemoteDescription(new RTCSessionDescription(data));
    } catch (e) {
        alert(e);
    }

    const answerRaw = await peerToOpen.createAnswer();
    const answer = JSON.parse(JSON.stringify(answerRaw));
    answer["id"] = data["id"];
    await peerToOpen.setLocalDescription(answerRaw);
    console.log("Generate answer");

    response = await fetch(createPath(externalId, cameraId), {
        method: "POST",
        body: JSON.stringify(answer),
    });

    if (response.status !== 200) {
        return [false, "error"];
    }

    return [true];
}

export async function closePeer() {
    if (state.videoPeer != null) {
        console.log("Close peer.");
        state.videoPeer.ontrack = null;
        state.videoPeer.onicegatheringstatechange = null;
        state.videoPeer.oniceconnectionstatechange = null;
        state.videoPeer.onsignalingstatechange = null;
        state.videoPeer.onicecandidate = null;
        await state.videoPeer.close();

        state.videoPeer = null;
        state.videoStream = null;
    } else {
        console.log(`CLOSE = NULL`);
    }
}

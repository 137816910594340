import { urlWithQuery } from "../utilites/UrlUtils";

const path = "/general-api/service/";

export const ExecuteCommand = async (methodType, serviceCode, featureCode, commandPath, parameters) => {
    try {
        const token = window.localStorage.getItem("token");

        let url = `${path}${serviceCode}/command/${featureCode}${commandPath}`;
        if (methodType === "GET" || methodType === "DELETE") {
            url = urlWithQuery(url, new URLSearchParams(parameters));
        }

        const requestInit = {
            method: methodType,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        if (methodType === "POST") {
            requestInit["headers"] = {
                ...requestInit["headers"],
                ...{
                    "Content-Type": "application/json",
                },
            };
            requestInit["body"] = JSON.stringify(parameters);
        }

        const response = await fetch(url, requestInit);

        if (response.status !== 200) {
            return [false, response.status, ""];
        }

        const responseText = await response.text();
        const result = responseText.length === 0 ? {} : JSON.parse(responseText);

        return [true, response.status, result];
    } catch (ex) {
        console.error(ex);

        return [false, "", ""];
    }
};

export const ExecuteAdapterCommand = async (methodType, serviceCode, commandPath, parameters) => {
    try {
        const token = window.localStorage.getItem("token");

        let url = `${path}${serviceCode}${commandPath}`;
        if (methodType === "GET" || methodType === "DELETE") {
            url = urlWithQuery(url, new URLSearchParams(parameters));
        }

        const requestInit = {
            method: methodType,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        switch (methodType) {
            case "POST":
            case "PUT":
                requestInit["headers"] = {
                    ...requestInit["headers"],
                    ...{
                        "Content-Type": "application/json",
                    },
                };
                requestInit["body"] = JSON.stringify(parameters);
                break;
            default:
                break;
        }

        const response = await fetch(url, requestInit);

        if (response.status !== 200) {
            return [false, response.status, ""];
        }

        const responseText = await response.text();
        const result = responseText.length === 0 ? {} : JSON.parse(responseText);

        return [true, response.status, result];
    } catch (ex) {
        console.error(ex);

        return [false, "", ""];
    }
};

export const GetOptions = async (serviceCode, endpointPath) => {
    const token = window.localStorage.getItem("token");
    try {
        let response = await fetch(`${path}${serviceCode}${endpointPath}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            return [false, []];
        }

        let res = await response.json();

        return [true, res?.items ?? []];
    } catch (ex) {
        console.error(ex);
        return [false, []];
    }
};

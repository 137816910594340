import React from "react";
import { withRouter } from "react-router-dom";
import { Layout } from "../../layout/MainLayout";
import Notificator from "../../services/Notifier/Notificator";
import DialogNotifier from "../../services/DialogNotifier/DialogNotifier";
import AlarmNotifier from "../../services/AlarmNotifier/AlarmNotifier";
import EventsNewEventModal from "../../features/events/components/Modals/EventsNewEventModal";
import { withSnackbar } from "notistack";
import { SHOW_NEW_EVENT } from "../coreTopics";
import PubSub from "pubsub-js";
import { throttle } from "throttle-debounce";
import { withCultureContext } from "../../contexts/cultureContext/CultureContext";

const SNACKBAR_THROTTLE_TIME = 2000;

class Core extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: {},
            showNewEventDialog: false,
            locationId: undefined,
            deviceId: undefined,
            screenshotTime: undefined,
        };

        this.throttleShowSnackbar = throttle(
            SNACKBAR_THROTTLE_TIME,
            (variant, text) => {
                this.props.enqueueSnackbar(text, { variant: variant });
            },
            { noLeading: true, noTrailing: false }
        );
    }

    componentDidMount() {
        this.showEventModal = PubSub.subscribe(SHOW_NEW_EVENT, (_, data) => {
            this.setState({
                newEventFile: data?.file,
                locationId: data?.locationId,
                deviceId: data?.deviceId,
                screenshotTime: data?.screenshotTime,
                showNewEventDialog: true,
            });
        });
    }

    componentWillUnmount() {
        this.showEventModal && PubSub.unsubscribe(this.showEventModal);
    }

    render() {
        const { generalInfo } = this.props;
        const { locationId, deviceId, screenshotTime, newEventFile, showNewEventDialog } = this.state;

        return (
            <>
                <Notificator />
                <DialogNotifier />
                <AlarmNotifier />
                {showNewEventDialog && (
                    <EventsNewEventModal
                        generalInfo={generalInfo}
                        file={newEventFile}
                        locationId={locationId}
                        deviceId={deviceId}
                        screenshotTime={screenshotTime}
                        handleClose={() => this.setState({ showNewEventDialog: false })}
                    />
                )}
                <Layout>{this.props.children}</Layout>
            </>
        );
    }
}

export default withCultureContext(withSnackbar(withRouter(Core)));

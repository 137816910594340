import React from "react";
import { ModalStepWrapper } from "./Styled";
import { ModalFooter, Button } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";
import { WIZARD_UPDATE_MODE } from "./constants";

export class ModalSteps extends React.Component {
    EditFooter = () => {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <Button
                        type="button"
                        variant="primary"
                        label={strings("Сохранить")}
                        onClick={() => this.props.EditHandler(this.props.values)}
                    />
                )}
            </CultureContextConsumer>
        );
    };

    CreateFooter = () => {
        if (this.props.step === this.props.activeStep && this.props.step === this.props.steps.length) {
            return (
                <CultureContextConsumer>
                    {({ strings }) => (
                        <Button
                            type="button"
                            variant="primary"
                            label={strings("Создать")}
                            onClick={() => this.props.CreateHandler(this.props.values)}
                        />
                    )}
                </CultureContextConsumer>
            );
        }

        if (this.props.step === this.props.activeStep) {
            return (
                <CultureContextConsumer>
                    {({ strings }) => (
                        <Button
                            type="button"
                            variant="primary"
                            label={strings("Продолжить")}
                            onClick={this.props.HandleNextStep}
                        />
                    )}
                </CultureContextConsumer>
            );
        }

        return null;
    };

    render() {
        if (!this.props?.activeStep || !this.props?.steps) {
            return null;
        }

        let component = this.props.steps[this.props.activeStep - 1]?.component;
        return (
            <ModalStepWrapper>
                {component
                    ? React.createElement(component, {
                          ...this.props,
                      })
                    : null}
                <ModalFooter>
                    {this.props.mode && this.props.mode === WIZARD_UPDATE_MODE
                        ? this.EditFooter()
                        : this.CreateFooter()}
                </ModalFooter>
            </ModalStepWrapper>
        );
    }
}

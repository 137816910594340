import {
    Button,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Text,
} from "headpoint-react-components";
import React from "react";
import { DownloadReport } from "../../../../../../services/devices";

export default function TaskResultsModal({ task, closeHandler, strings }) {
    const taskProperties = task.info.properties;

    return (
        <Modal size="sm">
            <ModalHeader
                title={strings("Результаты выполнения массовой опреации")}
                align="center"
                closeHandler={closeHandler}
            />
            <ModalBody>
                <ModalBodyItem>
                    <Text color="primary" variant="body-sm">
                        {`${strings("Успешно создано устройств")}: ${taskProperties.created}`}
                    </Text>
                    <br />
                    <Text color="primary" variant="body-sm">
                        {`${strings("Успешно отредактированно устройств")}: ${taskProperties.updated}`}
                    </Text>
                    <br />
                    <Text color="primary" variant="body-sm">
                        {`${strings("Успешно удалено устройств")}: ${taskProperties.deleted}`}
                    </Text>
                    <br />
                    <Text color="primary" variant="body-sm">
                        {strings("Вы можете скачать отчет с  детализацией результатов выполнения массовой операции.")}
                    </Text>
                </ModalBodyItem>
            </ModalBody>
            <ModalFooter>
                <ModalFooterItem>
                    <Button label={strings("Ок")} variant="primary" onClick={closeHandler} />
                </ModalFooterItem>
                <ModalFooterItem>
                    <Button
                        variant="secondary"
                        label={strings("Скачать отчет")}
                        icon="download"
                        onClick={async () => await DownloadReport(task.id)}
                    />
                </ModalFooterItem>
            </ModalFooter>
        </Modal>
    );
}

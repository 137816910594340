import React from "react";
import { withSnackbar } from "notistack";
import RoleTable from "./RoleTable";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
} from "../../../../layout/EventsLayout";
import { Dropdown, DropdownDivider, DropdownItem, ExpandableSearch, Text } from "headpoint-react-components";
import { permissionExists } from "../../../../contexts";
import PubSub from "pubsub-js";
import {
    CLOSE_ALL_MODALS,
    ROLE_DELETED,
    ROLE_UPDATED,
    SHOW_COPY_ROLE,
    SHOW_CREATE_ROLE,
    SHOW_CREATE_ROLE_GROUP,
    SHOW_DELETE_ROLE,
    SHOW_DELETE_ROLE_GROUP,
    SHOW_ROLE_DETAILS,
    SHOW_ROLE_GROUP_DETAILS,
    SHOW_UPDATE_ROLE,
    SHOW_UPDATE_ROLE_GROUP,
} from "./rolesSettingsTopics";
import { nanoid } from "nanoid";
import CreateRolesGroupModal from "./modals/rolesGroups/CreateRolesGroupModal";
import DeleteRolesGroupModal from "./modals/rolesGroups/DeleteRolesGroupModal";
import EditRolesGroupModal from "./modals/rolesGroups/EditRolesGroupModal";
import CreateUpdateRoleModal from "./modals/roles/CreateUpdateRoleModal";
import DeleteRoleModal from "./modals/roles/DeleteRoleModal";
import { RolesDetails } from "./details/RolesDetails";
import RoleCopyModal from "./modals/roles/ModalRoleCopy";
import { RolesGroupDetails } from "./details/RoleGroupDetails";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

class RolesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            selectedRole: undefined,
            selectedRoleGroup: undefined,

            showCreateRolesGroup: false,
            showDeleteRolesGroup: false,
            showUpdateRolesGroup: false,
            groupToEditOrDelete: undefined,

            modeRoleModal: WIZARD_CREATE_MODE,
            showRoleModal: false,
            showDeleteRole: false,
            roleToEditOrDelete: undefined,

            roleToCopy: undefined,
            showRoleCopy: false,
        };
    }

    async componentDidMount() {
        this.closeModalsTopic = PubSub.subscribe(CLOSE_ALL_MODALS, () => {
            this.setState({
                showCreateRolesGroup: false,
                showDeleteRolesGroup: false,
                showUpdateRolesGroup: false,

                showRoleModal: false,
                showDeleteRole: false,
                showRoleCopy: false,
            });
        });

        this.showCreateRoleGroup = PubSub.subscribe(SHOW_CREATE_ROLE_GROUP, () => {
            this.setState({
                showCreateRolesGroup: true,
            });
        });

        this.showUpdateRoleGroup = PubSub.subscribe(SHOW_UPDATE_ROLE_GROUP, (_, data) => {
            this.setState({
                showUpdateRolesGroup: true,
                groupToEditOrDelete: data,
            });
        });

        this.showDeleteRoleGroup = PubSub.subscribe(SHOW_DELETE_ROLE_GROUP, (_, data) => {
            this.setState({
                showDeleteRolesGroup: true,
                groupToEditOrDelete: data,
            });
        });

        this.showCreateRole = PubSub.subscribe(SHOW_CREATE_ROLE, () => {
            this.setState({
                modeRoleModal: WIZARD_CREATE_MODE,
                showRoleModal: true,
                roleToEditOrDelete: null,
            });
        });

        this.showCopyRole = PubSub.subscribe(SHOW_COPY_ROLE, (_, data) => {
            this.setState({
                roleToCopy: data,
                showRoleCopy: true,
            });
        });

        this.showDeleteRoleGroup = PubSub.subscribe(SHOW_DELETE_ROLE, (_, data) => {
            this.setState({
                showDeleteRole: true,
                roleToEditOrDelete: data,
            });
        });

        this.showUpdateRole = PubSub.subscribe(SHOW_UPDATE_ROLE, (_, data) => {
            this.setState({
                modeRoleModal: WIZARD_UPDATE_MODE,
                showRoleModal: true,
                roleToEditOrDelete: data,
            });
        });

        this.showRoleDetails = PubSub.subscribe(SHOW_ROLE_DETAILS, (_, data) => {
            this.setState({
                selectedRole: data,
                selectedRoleGroup: undefined,
            });
        });

        this.showRoleGroupDetails = PubSub.subscribe(SHOW_ROLE_GROUP_DETAILS, (_, data) => {
            this.setState({
                selectedRoleGroup: data,
                selectedRole: undefined,
            });
        });

        this.roleDeleted = PubSub.subscribe(ROLE_DELETED, (msg, data) => {
            const { selectedRole } = this.state;
            this.setState({
                selectedRole: selectedRole?.id === data?.id ? undefined : selectedRole,
            });
        });

        this.roleEdited = PubSub.subscribe(ROLE_UPDATED, (msg, data) => {
            const { selectedRole } = this.state;
            this.setState({
                selectedRole: selectedRole?.id === data?.id ? data : selectedRole,
            });
        });
    }

    componentWillUnmount() {
        this.closeModalsTopic && PubSub.unsubscribe(this.closeModalsTopic);
        this.showCreateRoleGroup && PubSub.unsubscribe(this.showCreateRoleGroup);
        this.showUpdateRoleGroup && PubSub.unsubscribe(this.showUpdateRoleGroup);
        this.showDeleteRoleGroup && PubSub.unsubscribe(this.showDeleteRoleGroup);
        this.showCreateRole && PubSub.unsubscribe(this.showCreateRole);
        this.showDeleteRoleGroup && PubSub.unsubscribe(this.showDeleteRoleGroup);
        this.showUpdateRole && PubSub.unsubscribe(this.showUpdateRole);
        this.showRoleDetails && PubSub.unsubscribe(this.showRoleDetails);
        this.showRoleGroupDetails && PubSub.unsubscribe(this.showRoleGroupDetails);
        this.roleDeleted && PubSub.unsubscribe(this.roleDeleted);
        this.roleEdited && PubSub.unsubscribe(this.roleEdited);
        this.showCopyRole && PubSub.unsubscribe(this.showCopyRole);
    }

    render() {
        const { userInfo } = this.props;
        const {
            search,
            selectedRole,
            selectedRoleGroup,
            showCreateRolesGroup,
            showDeleteRolesGroup,
            groupToEditOrDelete,
            showUpdateRolesGroup,
            modeRoleModal,
            showRoleModal,
            roleToEditOrDelete,
            showDeleteRole,
            roleToCopy,
            showRoleCopy,
        } = this.state;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        {showCreateRolesGroup && <CreateRolesGroupModal />}
                        {showDeleteRolesGroup && <DeleteRolesGroupModal group={groupToEditOrDelete} />}
                        {showUpdateRolesGroup && <EditRolesGroupModal group={groupToEditOrDelete} />}

                        {showRoleModal && <CreateUpdateRoleModal mode={modeRoleModal} role={roleToEditOrDelete} />}
                        {showDeleteRole && <DeleteRoleModal role={roleToEditOrDelete} />}
                        {showRoleCopy && <RoleCopyModal role={roleToCopy} />}

                        <EventsLayoutPrimary hasSecondary={true}>
                            <EventsLayoutHeader>
                                <EventsLayoutHeaderTitle>
                                    <Text variant="title" component="h1">
                                        {strings("Роли")}
                                    </Text>

                                    <ExpandableSearch
                                        value={this.state.search}
                                        onChange={(e) => this.setState({ search: e.target.value })}
                                        onSubmit={() => {
                                            /* ignore enter */
                                        }}
                                        placeholder={strings("Найти")}
                                        colorVariant="dark"
                                        inHeader
                                    />
                                </EventsLayoutHeaderTitle>

                                {permissionExists(userInfo, ["roles.role.lifeCycle", "roles.roleGroup.lifeCycle"]) ? (
                                    <EventsLayoutHeaderToolbox>
                                        <EventsLayoutHeaderToolboxItem>
                                            <Dropdown
                                                toggleLabel={strings("Создать")}
                                                closeOnClickInside
                                                toggleVariant="secondary"
                                                horizontalAlignment="right"
                                            >
                                                {permissionExists(userInfo, "roles.role.lifeCycle") ? (
                                                    <DropdownItem
                                                        as="button"
                                                        onClick={() => PubSub.publish(SHOW_CREATE_ROLE)}
                                                    >
                                                        {strings("Создать роль")}
                                                    </DropdownItem>
                                                ) : (
                                                    <Text />
                                                )}
                                                {permissionExists(userInfo, "roles.roleGroup.lifeCycle") ? (
                                                    <DropdownItem
                                                        as="button"
                                                        onClick={() => PubSub.publish(SHOW_COPY_ROLE, true)}
                                                    >
                                                        {strings("Копировать роль")}
                                                    </DropdownItem>
                                                ) : (
                                                    <Text />
                                                )}
                                                <DropdownDivider />
                                                {permissionExists(userInfo, "roles.roleGroup.lifeCycle") ? (
                                                    <DropdownItem
                                                        as="button"
                                                        onClick={() => PubSub.publish(SHOW_CREATE_ROLE_GROUP)}
                                                    >
                                                        {strings("Создать группу ролей")}
                                                    </DropdownItem>
                                                ) : (
                                                    <Text />
                                                )}
                                            </Dropdown>
                                        </EventsLayoutHeaderToolboxItem>
                                    </EventsLayoutHeaderToolbox>
                                ) : (
                                    <div />
                                )}
                            </EventsLayoutHeader>

                            <RoleTable key={search ?? nanoid()} search={search} selected={selectedRole} />
                        </EventsLayoutPrimary>

                        {selectedRole && <RolesDetails key={selectedRole?.id ?? nanoid()} selected={selectedRole} />}
                        {selectedRoleGroup && (
                            <RolesGroupDetails key={selectedRoleGroup?.id ?? nanoid()} selected={selectedRoleGroup} />
                        )}
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(RolesSettings));

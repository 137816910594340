import { withSnackbar } from "notistack";
import React from "react";
import { GetPerset } from "../../../../services/presets";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import PubSub from "pubsub-js";
import { SHOW_STREAM_PLAYER_DIALOG_PREFIX, APPLY_PRESET_TOPIC_PREFIX } from "../streamTopics";
import { getServiceFeature } from "../../../../contexts/GeneralContext";
import { ExecuteCommand } from "../../../../services/featureCommands";
import { PTZ_FEATURE_CODE } from "../constants";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

class ApplyPresetModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: props.strings("Загрузка пресета..."),
        };
    }

    async componentDidMount() {
        const {
            enqueueSnackbar,
            generalInfo,
            data: { preset, camera },
            strings,
        } = this.props;

        const [status, presetInfo] = await GetPerset(preset.id, camera.id);
        if (!status) {
            PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + camera?.id, undefined);
            enqueueSnackbar(strings("Ошибка при загрузке пресета"), { variant: "error" });
            return;
        }

        this.setState({ message: strings("Отправка PTZ камере...") });
        await this.setPtzCommand(presetInfo, camera, generalInfo);
    }

    async setPtzCommand(preset, camera, generalInfo) {
        const { enqueueSnackbar, strings } = this.props;
        const featureSettings = camera?.properties?.settings?.features;
        const ptzFeatureSettings = featureSettings ? featureSettings[PTZ_FEATURE_CODE] : undefined;
        const ptzFeature = getServiceFeature(generalInfo, ptzFeatureSettings.serviceCode, PTZ_FEATURE_CODE);

        if (!ptzFeature) {
            this.props.enqueueSnackbar(
                `${strings("Не удалось найти сервис с кодом")} ${ptzFeatureSettings.serviceCode} ${strings(
                    "и фичей"
                )} ${PTZ_FEATURE_CODE}`,
                { variant: "error" }
            );
            return;
        }

        const calcPanTilt = ({ pan, tilt }) => {
            return { pan, tilt };
        };

        let commandCode = "setPosition";
        let parameters = {
            ...calcPanTilt(preset?.properties?.ptz ?? {}),
            zoom: preset?.properties?.ptz.zoom,
            id: camera.id,
        };

        const moveCommand = ptzFeature?.commands?.list.find((c) => c.code === commandCode);

        if (moveCommand && moveCommand.path && moveCommand.method) {
            const [success] = await ExecuteCommand(
                moveCommand?.method,
                ptzFeatureSettings.serviceCode,
                PTZ_FEATURE_CODE,
                moveCommand?.path,
                parameters
            );

            if (success) {
                enqueueSnackbar(strings("Пресет применен"), { variant: "success" });
                PubSub.publish(APPLY_PRESET_TOPIC_PREFIX + camera?.id, {
                    type: "applied",
                    data: { preset },
                });
            } else {
                enqueueSnackbar(strings("Невозможно установить новое положение камеры"), { variant: "error" });
            }
        } else {
            this.props.enqueueSnackbar(`${strings("Команда")} ${commandCode} ${strings("отсутствует у адаптера")}`, {
                variant: "error",
            });
        }

        PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + camera?.id, undefined);
    }

    render() {
        const { message } = this.state;
        return <LoaderAnimation message={message} />;
    }
}

export default withCultureContext(withSnackbar(ApplyPresetModal));

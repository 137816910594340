import React, { useState, useCallback, useEffect } from "react";
import { AccordionListWrapper, AccordionListGroup, AccordionListChild } from "./elements";
import { Icon, Text } from "headpoint-react-components";

export const PlanAccordionList = ({ list, onSelect, selected, openLocation }) => {
    const [selectedParents, setSelectedParents] = useState([]);

    const checkChildrenCallback = useCallback(
        (childrenlist) => {
            const checkChildren = (children, parents = [], parent) => {
                if (!selected) setSelectedParents([]);

                children.map((child) => {
                    const newParents = parent ? [...parents, parent] : [...parents];

                    if ("children" in child) {
                        checkChildren(child.children, newParents, child.id);
                    } else {
                        if (child.id === selected) {
                            setSelectedParents(newParents);
                        }

                        return false;
                    }
                    return false;
                });
            };

            checkChildren(childrenlist);
        },
        [selected]
    );

    useEffect(() => {
        checkChildrenCallback(list);
    }, [list, selected, checkChildrenCallback]);

    const renderChildren = (childrenList) => {
        return (
            <>
                {childrenList.map((item) =>
                    item.children ? (
                        <AccordionListGroup
                            key={item.id}
                            label={item.name}
                            isSelected={selectedParents.includes(item.id)}
                            preOpen={item.preOpen || selectedParents.includes(item.id)}
                            onOpenLocation={openLocation}
                            location={item}
                            hasSelected
                        >
                            {renderChildren(item.children)}
                        </AccordionListGroup>
                    ) : (
                        <AccordionListChild
                            key={item.id}
                            onClick={() => onSelect(item)}
                            isSelected={item.id === selected?.id}
                            hasSelected
                        >
                            <Icon icon="map" />
                            <Text variant={"body-sm"}>{item.name}</Text>
                        </AccordionListChild>
                    )
                )}
            </>
        );
    };

    return <AccordionListWrapper>{renderChildren(list)}</AccordionListWrapper>;
};

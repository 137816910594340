import React from "react";
import DeviceList from "./DeviceList";
import { DeleteModal } from "../DeleteModal";
import { withSnackbar } from "notistack";
import { CreateUpdateWizard } from "../../../components/CreateUpdateWizard/CreateUpdateWizard";
import {
    GetDevices,
    RemoveDevice,
    CreateDevice,
    UpdateDevice,
    SearchDevices,
    getConflictErrorMessage,
} from "../../../../services/devices";
import {
    GetLocations,
    FillLocations,
    CreateLocation,
    UpdateLocation,
    DeleteLocation,
    FillNode,
    AddLocationToTree,
    RemoveFromTree,
    AddDeviceToTree,
    GetLocationList,
} from "../../../../services/locations";
import { wizardDeviceStepsDefault, wizardLocationSteps } from "./constants";
import LocationExtensionStep from "./modals/locationModals/LocationExtensionStep";
import { GeneralContextConsumer, getDeviceTypes, getExtensions, permissionExists } from "../../../../contexts";
import { ModalStepDeviceTypeSettings } from "./modals/CreateDeviceModal/ModalStepDeviceTypeSettings";
import { ModalStepFeatureSettings } from "./modals/CreateDeviceModal/ModalStepFeatureSettings";
import { getAllServiceFeatures, serviceExists, withGeneralContext } from "../../../../contexts/GeneralContext";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { withRouter } from "react-router-dom";
import DeviceLocationDetails from "./details/DeviceLocationDetails";
import { UpdateTagsByEntity } from "../../../../services/tags";
import { UpdateDevicesGroupsByDeviceId } from "../../../../services/devicesGroups";
import PubSub from "pubsub-js";
import {
    SHOW_CREATE_DEVICE,
    SHOW_CREATE_LOCATION,
    SHOW_DELETE_DEVICE,
    SHOW_DELETE_LOCATION,
    SHOW_IMPORT_FILE_MODAL,
    SHOW_UPDATE_DEVICE,
    SHOW_UPDATE_LOCATION,
    COPY_RTSP_URL,
} from "./deviceSettingsTopics";
import { cloneDeep } from "lodash";
import { GetActiveLicenses, GetLicenseInfo } from "../../../../services/license";
import ImportFileModal from "./modals/ImportFileModal/ImportFileModal";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { AddDeviceToControlZones, UpdateDeviceToControlZones } from "../../../../services/controlZones";
import { getLiveUrls } from "../../../../services/stream";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const LOCATIONS_LIMIT = 100;
const DEVICES_LIMIT = 100;

const initDeviceValues = {
    // default
    id: undefined,
    deviceType: undefined,
    serviceFeatureCodes: [],
    externalId: "",
    name: "",
    comment: "",
    observedObjects: "",
    tags: [],
    zones: [],
    // groups
    fixedGroups: [],
    // locations
    location: null,
    longitude: undefined,
    latitude: undefined,
    settings: { features: {} },
    serviceId: "",
};

const initLocationValues = {
    name: "",
    parentId: null,
};

class DevicesSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locationId: undefined,

            values: null,

            search: "",
            sources: [],

            selectedDevice: undefined,
            deviceToDelete: undefined,
            showDeleteModalDevice: false,
            showCreateDeviceModal: false,

            showDeleteModalLocation: false,
            showCreateLocationModal: false,
            selectedLocation: null,
            locationToDelete: null,

            showImportFileModal: false,

            step: 1,
            activeStep: 1,
            wizardMode: WIZARD_CREATE_MODE,
            deviceTypeSteps: [],
            previewCameraId: null,
            showCameraPreview: false,
            isLoading: false,
        };
    }

    removeLocationHandler = async () => {
        let { sources, locationToDelete } = this.state;
        const { strings } = this.props;

        if (!locationToDelete) {
            return;
        }

        const [response, statusCode] = await DeleteLocation(locationToDelete.id);
        if (statusCode === 200) {
            this.props.enqueueSnackbar(`${strings("Локация")} '${locationToDelete.name}' ${strings("удалена")}`, {
                variant: "success",
            });
        } else {
            switch (statusCode) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(
                        `${strings("Не хватает прав для удаления локации")} '${locationToDelete.name}'`,
                        {
                            variant: "warning",
                        }
                    );
                    break;

                case 409: // conflict
                    if (response.code === "conflict-notEmpty-plan") {
                        this.props.enqueueSnackbar(
                            `${strings("Нельзя удалить")} '${locationToDelete.name}' ${strings(
                                "- локация содержит план"
                            )}`,
                            {
                                variant: "warning",
                            }
                        );
                    } else {
                        this.props.enqueueSnackbar(
                            `${strings("Нельзя удалить")} '${locationToDelete.name}' ${strings(
                                "- локация содержит потомков"
                            )}`,
                            {
                                variant: "warning",
                            }
                        );
                    }
                    break;
                default:
                    this.props.enqueueSnackbar(`${strings("Не удалось удалить локацию")} '${locationToDelete.name}'`, {
                        variant: "error",
                    });
                    break;
            }

            return;
        }

        sources = RemoveFromTree(sources, locationToDelete.id);
        this.setState({ sources });
    };

    removeDeviceHandler = async (generalInfo) => {
        let { sources, deviceToDelete } = this.state;
        const { strings } = this.props;

        if (!deviceToDelete) {
            return;
        }

        const serviceId = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === deviceToDelete.typeId)?.serviceId;
        if (!serviceId) {
            this.props.enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), { variant: "error" });
            return;
        }

        if (await RemoveDevice(serviceId, deviceToDelete.id)) {
            this.props.enqueueSnackbar(`${strings("Устройство")} '${deviceToDelete.name}' ${strings("удалено")}`, {
                variant: "success",
            });
        } else {
            this.props.enqueueSnackbar(`${strings("Не удалось удалить устройство")} '${deviceToDelete.name}'`, {
                variant: "error",
            });
            return;
        }

        sources = RemoveFromTree(sources, deviceToDelete.id);
        this.setState({ sources });
    };

    setDeviceType = (value, sections, serviceId) => {
        let newValues = { ...cloneDeep(initDeviceValues), deviceType: value, serviceId };

        const deviceTypeSteps = sections.map((s) => {
            return {
                name: s.name,
                component: ModalStepDeviceTypeSettings,
            };
        });

        const deviceSteps = this.getDeviceSteps(deviceTypeSteps);
        const activeStep = Math.min(this.state.activeStep ?? 0, deviceSteps?.length ?? 0);
        const step = Math.min(this.state.step ?? 0, deviceSteps?.length ?? 0);
        this.setState({ values: newValues, deviceTypeSteps: deviceTypeSteps, activeStep, step });
    };

    checkServiceFeature = (featureCode, serviceFeaturesByCode, checked) => {
        let { deviceTypeSteps, values, activeStep, step } = this.state;
        let { settings, serviceFeatureCodes } = values ?? cloneDeep(initDeviceValues);

        if (!checked && settings?.features[featureCode]) {
            delete settings.features[featureCode];
        }

        serviceFeatureCodes = serviceFeatureCodes.filter((code) => code);

        if (serviceFeatureCodes?.includes(featureCode)) {
            serviceFeatureCodes.splice(serviceFeatureCodes.indexOf(featureCode), 1);

            let stepIndex = deviceTypeSteps.map((s) => s.featureCode).indexOf(featureCode);
            deviceTypeSteps.splice(stepIndex, 1);
        } else {
            serviceFeatureCodes = [...serviceFeatureCodes, featureCode];

            if (!deviceTypeSteps.some((d) => d.featureCode === featureCode)) {
                deviceTypeSteps.push({
                    name: serviceFeaturesByCode.find((f) => f.featureCode === featureCode)?.featureClassName,
                    isFeatureStep: true,
                    featureCode: featureCode,
                    component: ModalStepFeatureSettings,
                });
            }
        }

        const deviceSteps = this.getDeviceSteps(deviceTypeSteps.filter((el) => el.isFeatureStep));
        activeStep = Math.min(activeStep ?? 0, deviceSteps?.length ?? 0);
        step = Math.min(step ?? 0, deviceSteps?.length ?? 0);

        this.setState({
            values: { ...values, serviceFeatureCodes },
            deviceTypeSteps,
            activeStep,
            step,
        });
    };

    initServiceFeatures = (featureCodes, serviceFeatures) => {
        let { deviceTypeSteps } = this.state;
        deviceTypeSteps = deviceTypeSteps.filter((el) => !el.isFeatureStep);

        featureCodes = featureCodes.filter((name) => !!name);
        if (!featureCodes || featureCodes.length === 0) {
            let deviceTypeSteps = this.state.deviceTypeSteps;
            this.setState({ deviceTypeSteps: deviceTypeSteps.filter((step) => !step.isFeatureStep) });
            return;
        }

        featureCodes.forEach((featureCode) => {
            if (!featureCode) {
                return;
            }

            const currentFeature = serviceFeatures.find((f) => f.featureCode === featureCode);
            if (!currentFeature) {
                return;
            }

            let featureStep = {
                name: currentFeature?.featureClassName,
                isFeatureStep: true,
                featureCode: featureCode,
                component: ModalStepFeatureSettings,
            };

            deviceTypeSteps = deviceTypeSteps.concat([featureStep]);
        });

        this.setState({ deviceTypeSteps });
    };

    setFeatureSettings = (value, featureCode, serviceCode, featureName) => {
        const { values } = this.state;

        if (!values?.settings) {
            values.settings = {};
        }

        if (!values?.settings?.features) {
            values.settings.features = {};
        }

        if (!values?.settings?.features?.[featureCode]) {
            values.settings.features[featureCode] = {};
        }

        value[featureCode] = { ...value[featureCode], ...{ serviceCode }, ...{ featureName } };
        values.settings.features[featureCode] = Object.assign(
            values.settings.features[featureCode],
            value[featureCode]
        );

        this.setState({ values });
    };

    setValuesProperty = (name, value) => {
        let values = { ...this.state.values, [name]: value };

        this.setState({ values: values });
    };

    checkArchiveLicense = async () => {
        const { strings } = this.props;
        const archiveCode = "archive";
        const [licenseStatus, licenseInfo] = await GetLicenseInfo();
        const [entitiesStatus, licenseEntities] = await GetActiveLicenses();

        if (!entitiesStatus || !licenseStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения лицензий"), { variant: "error" });
            return false;
        }

        const licenseCount = licenseInfo.entities.find((l) => l.licenseCode.includes(archiveCode))?.count;
        if (!licenseCount) {
            this.props.enqueueSnackbar(strings("Отсутствует лицензия на запись архива"), {
                variant: "warning",
            });
            return false;
        }

        const entityCount = licenseEntities.find((l) => l.entityCode.includes(archiveCode))?.count ?? 0;

        if (licenseCount - entityCount <= 0) {
            this.props.enqueueSnackbar(strings("Достигнут лимит лицензии на запись архива"), {
                variant: "warning",
            });
            return false;
        }

        return true;
    };

    setDeviceSettings = async (value) => {
        if (value.isArchive?.value && !(await this.checkArchiveLicense())) {
            return;
        }

        const { values } = this.state;
        const settings = { ...values.settings, ...value };
        const newValues = { ...values, settings: settings };

        this.setState({ values: newValues });
    };

    deviceFromValues = (values) => {
        return {
            serviceId: values.serviceId,
            id: values.id,
            typeId: values.deviceType,
            name: values.name,
            locationId: values.location,
            tags: values.tags,
            fixedGroups: values.fixedGroups,
            properties: {
                externalId: values.externalId,
                comment: values.comment,
                observedObjects: values.observedObjects,
                geolocation: {
                    longitude: values.longitude,
                    latitude: values.latitude,
                },
                settings: values.settings,
            },
        };
    };

    valuesFromDevice = (device) => {
        const properties = device.properties;
        const serviceFeatureCodes = [];
        const settings = {};

        for (let prop in properties?.settings) {
            if (prop === "features") {
                for (let feature in properties?.settings[prop]) {
                    serviceFeatureCodes.push(feature);
                }

                settings[prop] = properties.settings[prop];
            } else {
                settings[prop] = {
                    value: properties.settings[prop],
                    required: undefined,
                    default: undefined,
                    name: undefined,
                };
            }
        }

        return {
            id: device.id,
            deviceType: device.typeId,
            name: device.name,
            location: device.locationId,
            tags: device.tags,
            fixedGroups: device.fixedGroups,
            externalId: device.properties?.externalId,
            comment: device.properties?.comment,
            observedObjects: device.properties?.observedObjects,
            longitude: device.properties?.geolocation?.longitude,
            latitude: device.properties?.geolocation?.latitude,
            settings: settings,
            serviceFeatureCodes: serviceFeatureCodes,
        };
    };

    addRequiredMissingSettings = (values, generalInfo, device) => {
        const augmentedValues = { ...values, settings: { ...values.settings } };
        const deviceType = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === device.typeId);

        const additionalSettings = deviceType?.settings?.general?.filter((setting) => setting.required) ?? [];

        for (const setting of additionalSettings) {
            if (!augmentedValues.settings.hasOwnProperty(setting.code)) {
                augmentedValues.settings[setting.code] = {
                    value: null,
                    required: setting.required,
                    default: setting.default,
                    name: setting.name,
                };
            }
        }

        return augmentedValues;
    };

    validateDeviceData = (values, availableServiceFeatures) => {
        const { strings } = this.props;

        values = this.getDeepClone(values);

        if (!this.state.values.name || this.state.values?.name?.trim() === "") {
            this.props.enqueueSnackbar(strings("Нельзя сохранить устройство без имени"), { variant: "error" });
            return;
        }

        if (!this.state.values.deviceType) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить устройство без типа"), { variant: "error" });
            return;
        }

        if (!this.state.values.location) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить устройство без локации"), { variant: "error" });
            return;
        }

        if ((values.longitude && isNaN(values.longitude)) || values.longitude < -180 || values.longitude > 180) {
            this.props.enqueueSnackbar(strings("Значение долготы должно быть числом от -180 до 180"), {
                variant: "error",
            });
            return false;
        }

        if ((values.latitude && isNaN(values.latitude)) || values.latitude < -90 || values.latitude > 90) {
            this.props.enqueueSnackbar(strings("Значение широты должно быть числом в диапазоне от -90 до 90"), {
                variant: "error",
            });
            return false;
        }

        if (!!availableServiceFeatures && values?.serviceFeatureCodes.length > 0) {
            let hasError = false;
            this.state.values.serviceFeatureCodes.forEach((featureCode) => {
                const serviceFeature = availableServiceFeatures.find((sf) => sf.featureCode === featureCode);

                const featureSettings = serviceFeature?.commands?.settings;

                featureSettings?.forEach((parameter) => {
                    if (parameter?.required) {
                        if (hasError) {
                            return;
                        }

                        if (!values.settings?.features[serviceFeature?.featureCode]) {
                            this.props.enqueueSnackbar(
                                `${strings("Не выбран функционал для")} ${serviceFeature?.featureClassName}`,
                                {
                                    variant: "error",
                                }
                            );
                            hasError = true;
                            return;
                        }

                        if (
                            !values.settings?.features[serviceFeature?.featureCode][parameter.code] &&
                            !parameter?.default
                        ) {
                            this.props.enqueueSnackbar(
                                `${strings("Не заполнено поле")} ${parameter.name} ${strings("для функции")} ${
                                    serviceFeature?.featureClassName
                                }`,
                                {
                                    variant: "error",
                                }
                            );
                            hasError = true;
                        }

                        if (!values?.settings?.features[serviceFeature?.featureCode][parameter.code]) {
                            values.settings.features[serviceFeature?.featureCode][parameter.code] = parameter.default;
                        }
                    }
                });

                if (serviceFeature?.featureNotify && !hasError) {
                    values.settings.features[serviceFeature?.featureCode]["featureNotify"] =
                        serviceFeature.featureNotify;
                }
            });

            if (hasError) {
                return;
            }
        }

        for (const property in values?.settings) {
            if (property === "features") {
                continue;
            }

            const parameter = values.settings[property];

            if (parameter?.required && !parameter?.value) {
                if (!parameter?.default) {
                    this.props.enqueueSnackbar(`${strings("Не заполнено поле")} ${parameter.name}`, {
                        variant: "error",
                    });
                    return;
                }

                values.settings[property].value = parameter?.default;
            }

            values.settings[property] = parameter?.value;
        }

        const device = this.deviceFromValues(values);

        this.setState({
            activeStep: 1,
        });

        return device;
    };

    getDeepClone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    createDeviceHandler = async (values, availableServiceFeatures) => {
        const { strings } = this.props;
        const { sources } = this.state;
        const device = this.validateDeviceData(values, availableServiceFeatures);
        if (!device) {
            return;
        }

        this.setState({ isLoading: true });

        try {
            const [success, statusCodeCreate, response] = await CreateDevice(device);
            if (success) {
                this.props.enqueueSnackbar(`${strings("Устройство")} '${device.name}' ${strings("создано")}`, {
                    variant: "success",
                });

                void this.updateTags(response.id, values.tags);

                void this.updateDeviceGroups(response.id, values.fixedGroups);

                void this.addDeviceToControlZones(response.id, values.zones);
            } else {
                this.setState({ isLoading: false });

                switch (statusCodeCreate) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(strings("Не хватает прав для создания устройства"), {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(getConflictErrorMessage(response.code), {
                            variant: "warning",
                        });
                        break;

                    case 402:
                        this.props.enqueueSnackbar(strings("Достигнут лимит лицензии на создание новых устройств"), {
                            variant: "warning",
                        });
                        break;

                    default:
                        this.props.enqueueSnackbar(`${strings("Не удалось создать устройство")} '${device.name}'`, {
                            variant: "error",
                        });
                        break;
                }

                return;
            }
            AddDeviceToTree(sources, device.locationId, { ...device, id: response?.id });
        } catch (ex) {
            this.setState({ isLoading: false });
        }

        this.setState({
            showCreateDeviceModal: false,
            values: cloneDeep(initDeviceValues),
            step: 1,
            activeStep: 1,
            sources,
            isLoading: false,
        });
    };

    editDeviceHandler = async (values, availableServiceFeatures) => {
        const { strings } = this.props;
        let { sources } = this.state;
        const device = this.validateDeviceData(values, availableServiceFeatures);
        if (!device) {
            return;
        }

        this.setState({ isLoading: true });

        try {
            const [success, statusCode] = await UpdateDevice(device);
            if (success) {
                this.props.enqueueSnackbar(`${strings("Устройство")} '${device.name}' ${strings("сохранено")}`, {
                    variant: "success",
                });

                void this.updateTags(device.id, values.tags);

                void this.updateDeviceGroups(device.id, values.fixedGroups);

                void this.updateDeviceToControlZones(device.id, values.zones);
            } else {
                this.setState({ isLoading: false });

                switch (statusCode) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(strings("Не хватает прав для изменения устройства"), {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(
                            strings("В одной локации должны быть устройства с разными именами"),
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    default:
                        this.props.enqueueSnackbar(`${strings("Не удалось изменить устройство")} '${device.name}'`, {
                            variant: "error",
                        });
                        break;
                }

                return;
            }

            sources = RemoveFromTree(sources, device.id);
            AddDeviceToTree(sources, device.locationId, device);
        } catch (ex) {
            this.setState({ isLoading: false });
        }

        this.setState({
            showCreateDeviceModal: false,
            values: cloneDeep(initDeviceValues),
            step: 1,
            activeStep: 1,
            sources,
            isLoading: false,
            selectedDevice: device,
        });
    };

    canUpdateDeviceGroups = (fixedGroups) => {
        const { userInfo } = this.props;
        return fixedGroups && permissionExists(userInfo, "devices.deviceGroup.lifeCycle");
    };

    updateTags = async (deviceId, tags) => {
        const { strings } = this.props;

        if (!tags) {
            return;
        }

        const status = await UpdateTagsByEntity(deviceId, tags);

        if (!status) {
            this.props.enqueueSnackbar(strings("Не удалось обновить тэги"), { variant: "error" });
        }
    };

    updateDeviceGroups = async (deviceId, groups) => {
        const { strings } = this.props;

        if (!this.canUpdateDeviceGroups(groups)) {
            return;
        }

        const status = await UpdateDevicesGroupsByDeviceId(deviceId, groups);

        if (!status) {
            this.props.enqueueSnackbar(strings("Не удалось обновить группы оборудования"), { variant: "error" });
        }
    };

    addDeviceToControlZones = async (deviceId, zoneIds) => {
        const { strings, userInfo, generalInfo } = this.props;

        if (
            permissionExists(userInfo, "controlZone.lifeCycle") &&
            serviceExists(generalInfo, ["service.api.controlZones"])
        ) {
            const status = await AddDeviceToControlZones(deviceId, zoneIds);
            if (!status) {
                this.props.enqueueSnackbar(strings("Не удалось обновить зоны контроля"), { variant: "error" });
            }
        }
    };

    updateDeviceToControlZones = async (deviceId, zoneIds) => {
        const { strings, userInfo, generalInfo } = this.props;

        if (
            permissionExists(userInfo, "controlZone.lifeCycle") &&
            serviceExists(generalInfo, ["service.api.controlZones"])
        ) {
            const status = await UpdateDeviceToControlZones(deviceId, zoneIds);
            if (!status) {
                this.props.enqueueSnackbar(strings("Не удалось обновить зоны контроля"), { variant: "error" });
            }
        }
    };

    nextStep = () => {
        let step = this.state.step + 1;
        this.setState({
            step: step,
            activeStep: step,
        });
    };

    selectStep = (step) => {
        this.setState({
            activeStep: step,
        });
    };

    async getLocations(parentId) {
        const { strings } = this.props;

        const [getLocationsStatus, locations] = await GetLocations(parentId);
        if (!getLocationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
        }

        return locations;
    }

    async getDevices(locationId) {
        const { strings } = this.props;

        const [getDevicesStatus, devices] = await GetDevices(locationId);
        if (!getDevicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
        }

        return devices;
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            const locations = await this.getLocations();
            this.setState({
                sources: FillLocations(locations, []),
            });
        }
    };

    async componentDidMount() {
        const { strings } = this.props;
        const locations = await this.getLocations();
        this.setState({
            ...this.props.history.location.state,
            sources: FillLocations(locations, []),
        });

        this.unlisten = this.props.history.listen((location) => {
            this.setState({ ...location.state });
        });

        this.showCreateDevice = PubSub.subscribe(SHOW_CREATE_DEVICE, () => {
            this.setState({
                showCreateDeviceModal: true,
                values: cloneDeep(initDeviceValues),
                wizardMode: WIZARD_CREATE_MODE,
                step: 1,
                activeStep: 1,
            });
        });

        this.showUpdateDevice = PubSub.subscribe(SHOW_UPDATE_DEVICE, (msg, data) => {
            const { generalInfo, device } = data;

            const serviceId = getDeviceTypes(generalInfo)?.find((dt) => dt?.value === device.typeId)?.serviceId;
            if (!serviceId) {
                this.props.enqueueSnackbar(strings("Отсутствует адаптер данного типа оборудования"), {
                    variant: "error",
                });
                return;
            }

            let values = this.valuesFromDevice(device);
            values = this.addRequiredMissingSettings(values, generalInfo, device);

            const serviceFeatures = getAllServiceFeatures(generalInfo).filter((f) => f.commands);

            this.initServiceFeatures(values.serviceFeatureCodes, serviceFeatures);

            this.setState({
                showCreateDeviceModal: true,
                values: { ...values, serviceId },
                wizardMode: WIZARD_UPDATE_MODE,
                step: 1,
                activeStep: 1,
            });
        });

        this.showDeleteDevice = PubSub.subscribe(SHOW_DELETE_DEVICE, (msg, device) => {
            this.setState({ showDeleteModalDevice: true, deviceToDelete: device });
        });

        this.showCreateLocation = PubSub.subscribe(SHOW_CREATE_LOCATION, () => {
            this.setState({
                showCreateLocationModal: true,
                values: initLocationValues,
                wizardMode: WIZARD_CREATE_MODE,
                step: 1,
                activeStep: 1,
            });
        });

        this.showUpdateLocation = PubSub.subscribe(SHOW_UPDATE_LOCATION, (msg, data) => {
            const locationsExtensions = getExtensions(data.generalInfo, "system.locations");
            this.setState({
                showCreateLocationModal: true,
                values: this.loadDefaultLocationValues(locationsExtensions, this.valuesFromLocation(data.location)),
                wizardMode: WIZARD_UPDATE_MODE,
                step: 1,
                activeStep: 1,
            });
        });

        this.showDeleteLocation = PubSub.subscribe(SHOW_DELETE_LOCATION, (msg, location) => {
            this.setState({ showDeleteModalLocation: true, locationToDelete: location });
        });

        this.showImportFileModal = PubSub.subscribe(SHOW_IMPORT_FILE_MODAL, () => {
            this.setState({ showImportFileModal: true });
        });

        this.copyRtspUrl = PubSub.subscribe(COPY_RTSP_URL, async (msg, device) => {
            const serviceId = getDeviceTypes(this.props.generalInfo)?.find(
                (dt) => dt?.value === device.typeId
            )?.serviceId;

            if (!serviceId) {
                this.props.enqueueSnackbar(strings("Не удалось найти сервис по типу оборудования"), {
                    variant: "error",
                });
                return;
            }

            const [status, liveUrls] = await getLiveUrls(serviceId, device.id);
            if (!status || !liveUrls?.length) {
                this.props.enqueueSnackbar(strings("Ошибка получения URL трансляции"), { variant: "error" });
                return;
            }

            if (!window.isSecureContext) {
                this.props.enqueueSnackbar(strings("Копирование URL трансляции требует HTTPS"), { variant: "error" });
                return;
            }

            let host = new URL(window.location.href);
            let url = new URL(liveUrls[0].url.replace("rtsp", "http"));
            url.hostname = host.hostname;
            url = url.toString().replace("http", "rtsp");

            try {
                await navigator.clipboard.writeText(url);
                this.props.enqueueSnackbar(strings("URL трансляции скопирован"), { variant: "success" });
            } catch (ex) {
                this.props.enqueueSnackbar(strings("Ошибка копирования URL трансляции"), { variant: "error" });
            }
        });

        await this.cmdFilterBySingleDevice();
    }

    componentWillUnmount() {
        this.unlisten && this.unlisten();

        this.showCreateDevice && PubSub.unsubscribe(this.showCreateDevice);
        this.showUpdateDevice && PubSub.unsubscribe(this.showUpdateDevice);
        this.showDeleteDevice && PubSub.unsubscribe(this.showDeleteDevice);

        this.showCreateLocation && PubSub.unsubscribe(this.showCreateLocation);
        this.showUpdateLocation && PubSub.unsubscribe(this.showUpdateLocation);
        this.showDeleteLocation && PubSub.unsubscribe(this.showDeleteLocation);

        this.showImportFileModal && PubSub.unsubscribe(this.showImportFileModal);

        this.copyRtspUrl && PubSub.unsubscribe(this.copyRtspUrl);
    }

    cmdFilterBySingleDevice = async () => {
        const { sources, locationId } = this.state;
        const { strings } = this.props;

        if (!locationId) {
            return;
        }

        let [locationsStatus, locationsDb] = await GetLocationList({ ids: [locationId] });
        if (!locationsStatus || !locationsDb?.length) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [locationDb] = locationsDb;

        let parentIds = [...locationDb.parentIds.slice(1), locationId];

        let locationNode;

        let tree = sources;

        for (const parentId of parentIds) {
            locationNode = tree.find((i) => i.id === parentId);

            locationNode.isClosed = false;

            if (!locationNode) {
                this.props.enqueueSnackbar(`${strings("Не удалось найти локацию с идентификатором")} ${parentId}`, {
                    variant: "error",
                });
                return;
            }

            locationNode.preOpen = true;

            await this.openLocation(locationNode);

            tree = locationNode.children;
        }

        this.setState({ sources: sources });
    };

    locationFromValues = (values) => {
        let geolocation = {};
        if (values?.longitude !== "" && values?.latitude !== "") {
            geolocation = {
                longitude: values.longitude,
                latitude: values.latitude,
            };
        }

        let properties = { ...values, geolocation };
        delete properties.id;
        delete properties.name;
        delete properties.parentId;
        delete properties.longitude;
        delete properties.latitude;

        return {
            id: values.id,
            name: values.name,
            parentId: values.parentId,
            properties,
        };
    };

    valuesFromLocation = (location) => {
        let properties = { ...location.properties };
        delete properties.geolocation;

        return {
            ...properties,
            id: location.id,
            name: location.name,
            comment: location.properties?.comment,
            parentId: location.parentId,
            longitude: location.properties?.geolocation?.longitude,
            latitude: location.properties?.geolocation?.latitude,
        };
    };

    validateLocationData(values, locationsExtensions) {
        const { strings } = this.props;

        if (!values.name) {
            this.props.enqueueSnackbar(strings("Нельзя создать локацию без имени"), { variant: "error" });
            return false;
        }

        if ((values.longitude && isNaN(values.longitude)) || values.longitude < -180 || values.longitude > 180) {
            this.props.enqueueSnackbar(strings("Значение долготы должно быть числом от -180 до 180"), {
                variant: "error",
            });
            return false;
        }

        if ((values.latitude && isNaN(values.latitude)) || values.latitude < -90 || values.latitude > 90) {
            this.props.enqueueSnackbar(strings("Значение широты должно быть числом в диапазоне от -90 до 90"), {
                variant: "error",
            });
            return false;
        }

        const coordinates = [values.latitude, values.longitude].map((x) => (x === "" ? undefined : x));
        if (!coordinates.every((c) => c === undefined)) {
            if (!coordinates.every((c) => c !== undefined)) {
                this.props.enqueueSnackbar(strings("Заполните значения широты и долготы"), { variant: "error" });
                return false;
            }
        }

        for (let ext of locationsExtensions) {
            if (
                ext.settings?.required &&
                (values[ext.name] === null || values[ext.name] === undefined || values[ext.name] === "")
            ) {
                this.props.enqueueSnackbar(`${strings("Заполните значение")} "${ext.title ?? ext.name}"`, {
                    variant: "error",
                });
                return false;
            }
        }

        return true;
    }

    editLocationHandler = async (values, locationsExtensions) => {
        const { strings } = this.props;
        let { sources } = this.state;

        if (!this.state.values.name) {
            this.props.enqueueSnackbar(strings("Нельзя сохранить локацию без имени"), { variant: "error" });
            return;
        }

        if (!this.validateLocationData(values, locationsExtensions)) {
            return;
        }

        try {
            this.setState({ isLoading: true });

            const location = this.locationFromValues(values);
            const [success, statusCode, response] = await UpdateLocation(location);

            if (success) {
                this.props.enqueueSnackbar(strings("Локация сохранена"), { variant: "success" });
                sources = RemoveFromTree(sources, values.id);
                AddLocationToTree(sources, values.parentId, location);
                this.setState({
                    values: null,
                    step: 1,
                    activeStep: 1,
                    showCreateLocationModal: false,
                    sources,
                    isLoading: false,
                    selectedLocation: location,
                });
            } else {
                switch (statusCode) {
                    case 403: // forbidden
                        this.props.enqueueSnackbar(strings("Не хватает прав для изменения локации"), {
                            variant: "warning",
                        });
                        break;
                    case 409: // conflict
                        let message = strings("В одной локации должны быть подлокации с разными именами");

                        if (response.code === "conflict-parent") {
                            message = strings("Нельзя установить эту локацию в качестве родительской");
                        }

                        this.props.enqueueSnackbar(message, { variant: "warning" });
                        break;

                    default:
                        this.props.enqueueSnackbar(strings("Не удалось изменить локацию"), { variant: "error" });
                        break;
                }

                this.setState({
                    activeStep: 1,
                    isLoading: false,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    createLocationHandler = async (values, locationsExtensions) => {
        const { strings } = this.props;
        let { sources } = this.state;

        if (!this.state.values.name) {
            this.props.enqueueSnackbar(strings("Нельзя создать локацию без имени"), { variant: "error" });
            return;
        }

        if (!this.validateLocationData(values, locationsExtensions)) {
            return;
        }

        this.setState({ isLoading: true });

        try {
            const [success, statusCodeCreate, id] = await CreateLocation(this.locationFromValues(values));
            if (success) {
                this.props.enqueueSnackbar(strings("Локация создана"), { variant: "success" });
                AddLocationToTree(sources, values.parentId, { ...values, id });
                this.setState({
                    values: null,
                    step: 1,
                    activeStep: 1,
                    showCreateLocationModal: false,
                    sources,
                    isLoading: false,
                });
            } else {
                switch (statusCodeCreate) {
                    case 403: // forbiden
                        this.props.enqueueSnackbar(strings("Не хватает прав для создания локации"), {
                            variant: "warning",
                        });
                        break;

                    case 409: // conflict
                        this.props.enqueueSnackbar(
                            strings("В одной локации должны быть подлокации с разными именами"),
                            {
                                variant: "warning",
                            }
                        );
                        break;

                    default:
                        this.props.enqueueSnackbar(strings("Не удалось создать локацию"), { variant: "error" });
                        break;
                }

                this.setState({
                    activeStep: 1,
                    isLoading: false,
                });
            }
        } catch (ex) {
            this.setState({ isLoading: false });
        }
    };

    openLocation = async (locationNode) => {
        const { sources } = this.state;
        const children = await this.getLocations(locationNode.id);
        const devices = await this.getDevices(locationNode.id);

        FillNode(locationNode, children, devices);
        this.setState({
            sources: sources,
        });
    };

    setSearch = async (search) => {
        const { strings } = this.props;

        if (!search) {
            const locations = await this.getLocations();
            this.setState({
                search: "",
                sources: FillLocations(locations, []),
            });
            return;
        }

        let [locationsStatus, locations] = await GetLocationList({ like: search, limit: LOCATIONS_LIMIT });
        if (!locationsStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения локаций"), { variant: "error" });
            return;
        }

        const [devicesStatus, devices] = await SearchDevices({ like: search, limit: DEVICES_LIMIT });
        if (!devicesStatus) {
            this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
            return;
        }

        let nodes = locations?.map((l) => ({ ...l, tag: "location", isClosed: true })) ?? [];
        nodes = nodes.concat(devices?.map((d) => ({ ...d, tag: "device" })) ?? []);
        this.setState({ search, sources: nodes });
    };

    loadDefaultLocationValues = (extensions, values) => {
        let result = { ...values };
        extensions?.forEach((ext) => {
            if (
                (values[ext.name] === null || values[ext.name] === undefined) &&
                (ext.settings?.default !== null || true)
            ) {
                result = { ...result, [ext.name]: ext.settings?.default };
            }
        });

        return result;
    };

    getDeviceSteps(deviceTypeSteps) {
        const { userInfo, generalInfo } = this.props;
        let steps = [];

        wizardDeviceStepsDefault.concat(deviceTypeSteps).forEach((step) => {
            if (
                (!step.permission || permissionExists(userInfo, step.permission)) &&
                (!step.serviceCode || serviceExists(generalInfo, [step.serviceCode]))
            ) {
                steps.push(step);
            }
        });

        return steps;
    }

    render() {
        const {
            values,
            showDeleteModalLocation,
            showCreateDeviceModal,
            wizardMode,
            step,
            activeStep,
            showDeleteModalDevice,
            showCreateLocationModal,
            showImportFileModal,
            deviceTypeSteps,
            showCameraPreview,
            selectedDevice,
            deviceToDelete,
            locationToDelete,
            previewCameraId,
            sources,
            search,
            isLoading,
        } = this.state;
        const { strings } = this.props;

        const deviceSteps = this.getDeviceSteps(deviceTypeSteps);
        return (
            <>
                {isLoading && <LoaderAnimation message={strings("Применение настроек")} />}
                <DeleteModal
                    visible={showDeleteModalDevice}
                    CloseHandler={() => this.setState({ showDeleteModalDevice: false })}
                    RemoveHandler={this.removeDeviceHandler}
                    text={`${strings("Вы хотите удалить устройство")} "${deviceToDelete?.name}". ${strings(
                        "Удалённое устройство нельзя восстановить. Продолжить?"
                    )}`}
                />

                <DeleteModal
                    visible={showDeleteModalLocation}
                    CloseHandler={() => this.setState({ showDeleteModalLocation: false })}
                    RemoveHandler={this.removeLocationHandler}
                    text={`${strings("Вы хотите удалить локацию")} "${locationToDelete?.name}". ${strings(
                        "Удалённую локацию нельзя восстановить. Продолжить?"
                    )}`}
                />

                {showImportFileModal && (
                    <ImportFileModal
                        closeHandler={() => this.setState({ showImportFileModal: false })}
                        strings={strings}
                    />
                )}

                <GeneralContextConsumer>
                    {(generalInfo) => {
                        const availableServiceFeatures = getAllServiceFeatures(generalInfo).filter((sf) =>
                            values?.serviceFeatureCodes?.includes(sf?.featureCode)
                        );
                        const locationsExtensions = getExtensions(generalInfo, "system.locations");
                        const extensionSteps = locationsExtensions?.reduce((result, e) => {
                            if (
                                e.settings &&
                                e.settings?.section !== "general" &&
                                e.settings?.section !== "position" &&
                                result?.every((step) => step.section !== e.settings?.section)
                            ) {
                                return [
                                    ...result,
                                    {
                                        name: e.settings?.sectionName ?? e.settings?.section,
                                        section: e.settings?.section,
                                        component: LocationExtensionStep,
                                    },
                                ];
                            }

                            return result;
                        }, []);

                        const locationSteps = wizardLocationSteps.concat(extensionSteps);
                        return (
                            <>
                                <CreateUpdateWizard
                                    visible={showCreateDeviceModal}
                                    title={`${
                                        wizardMode === WIZARD_CREATE_MODE ? strings("Создать") : strings("Изменить")
                                    } ${strings("устройство")}`}
                                    steps={deviceSteps}
                                    step={step}
                                    activeStep={activeStep}
                                    values={values}
                                    NextStep={this.nextStep}
                                    setDeviceType={this.setDeviceType}
                                    checkServiceFeature={this.checkServiceFeature}
                                    initServiceFeatures={this.initServiceFeatures}
                                    SetActiveStep={this.selectStep}
                                    SetValuesProperty={this.setValuesProperty}
                                    SetDeviceSettings={this.setDeviceSettings}
                                    setFeatureSettings={this.setFeatureSettings}
                                    CreateHandler={(values) =>
                                        this.createDeviceHandler(values, availableServiceFeatures)
                                    }
                                    EditHandler={(values) => this.editDeviceHandler(values, availableServiceFeatures)}
                                    CloseHandler={() =>
                                        this.setState({
                                            showCreateDeviceModal: false,
                                            deviceTypeSteps: [],
                                        })
                                    }
                                    Mode={wizardMode}
                                    DeviceTypes={getDeviceTypes(generalInfo)}
                                    generalInfo={generalInfo}
                                />

                                <CreateUpdateWizard
                                    visible={showCreateLocationModal}
                                    CreateHandler={(values) => this.createLocationHandler(values, locationsExtensions)}
                                    EditHandler={(values) => this.editLocationHandler(values, locationsExtensions)}
                                    CloseHandler={() => this.setState({ showCreateLocationModal: false })}
                                    values={values}
                                    title={`${
                                        wizardMode === WIZARD_CREATE_MODE ? strings("Создать") : strings("Изменить")
                                    } ${strings("локацию")}`}
                                    step={step}
                                    activeStep={activeStep}
                                    NextStep={this.nextStep}
                                    SetActiveStep={this.selectStep}
                                    SetValuesProperty={this.setValuesProperty}
                                    steps={locationSteps}
                                    Mode={wizardMode}
                                    openItem={(location) => this.openLocation(location)}
                                    locationExtensions={locationsExtensions}
                                />

                                <DeviceList
                                    generalInfo={generalInfo}
                                    showCameraPreview={showCameraPreview}
                                    setShowCameraPreview={(val) =>
                                        this.setState({ showCameraPreview: val, previewCameraId: null })
                                    }
                                    previewCameraId={previewCameraId}
                                    selectCameraId={(id) => this.setState({ previewCameraId: id })}
                                    selectedDevice={selectedDevice}
                                    setSelectedDevice={(device) => this.setState({ selectedDevice: device })}
                                    sources={sources}
                                    openLocation={(location) => this.openLocation(location)}
                                    showLocationHandler={async (location) => {
                                        this.setState({
                                            selectedLocation: location,
                                            selectedDevice: undefined,
                                        });
                                    }}
                                    setSearch={this.setSearch}
                                    search={search}
                                />
                                {this.state.selectedLocation && !this.state.selectedDevice && (
                                    <DeviceLocationDetails
                                        close={() => this.setState({ selectedLocation: undefined })}
                                        location={this.state.selectedLocation}
                                        key={this.state.selectedLocation?.id}
                                    />
                                )}
                            </>
                        );
                    }}
                </GeneralContextConsumer>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(
    mapStateToProps,
    null
)(withCultureContext(withSnackbar(withRouter(withGeneralContext(DevicesSettings)))));

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeviceLocationTreeComponent from "./FeatureComponents/DeviceLocationTreeComponents/DeviceLocationTree";
import DeviceLocationTreePreview from "./FeatureComponents/DeviceLocationTreeComponents/DeviceLocationTreePreview";
import ModalStepFilters from "./ModalStepFilters";

const ModalStepFeatureSettings = ({ steps, activeStep }) => {
    const values = useSelector((state) => state.schedule.values);
    const [selectedFeature, setSelectedFeatureCode] = useState([]);

    useEffect(() => {
        const selectedFeatureCode = steps[activeStep - 1].featureCode;
        const features = values.feature.commands.settings;
        const selectedFeature = features.find((s) => s.code === selectedFeatureCode);

        setSelectedFeatureCode(selectedFeature);
    }, [activeStep, steps, values.feature]);

    const Feature = (selectedFeature) => {
        switch (selectedFeature.type) {
            case "device-location-tree":
                return <DeviceLocationTreeComponent selectedFeature={selectedFeature} />;
            case "device-location-tree-preview":
                return <DeviceLocationTreePreview selectedFeature={selectedFeature} />;
            case "device-filter":
                return <ModalStepFilters />;
            default:
                return <></>;
        }
    };

    return <>{Feature(selectedFeature)}</>;
};

export default ModalStepFeatureSettings;

import React from "react";
import { withSnackbar } from "notistack";
import { DevicesGroupsTable } from "./DevicesGroupsTable";
import { GetDevicesGroups, CountDevicesInGroup } from "../../../../services/devicesGroups";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutHeaderToolbox,
    EventsLayoutPrimaryInner,
} from "../../../../layout/EventsLayout";
import { Dropdown, DropdownItem, ExpandableSearch, Text } from "headpoint-react-components";
import { permissionExists } from "../../../../contexts";
import pLimit from "p-limit";
import FixedGroupModal from "./modals/FixedGroupModal";
import PubSub from "pubsub-js";
import {
    GROUP_CREATED_TOPIC,
    GROUP_DELETED_TOPIC,
    GROUP_EDITED_TOPIC,
    SHOW_CREATE_GROUP_MODAL,
    SHOW_DELETE_GROUP_MODAL,
} from "./devicesGroupsTopics";
import { SearchDevices } from "../../../../services/devices";
import DeleteModal from "./modals/DeleteModal";
import { nanoid } from "nanoid";
import DynamicGroupModal from "./modals/DynamicGroupModal";
import DynamicGroupsDetails from "./details/DynamicGroupsDetails";
import FixedGroupsDetails from "./details/FixedGroupsDetails";
import { CommonTablePagination, ITEMS_ON_PAGE } from "../../CommonTablePagination";
import { connect } from "react-redux";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { WIZARD_CREATE_MODE, WIZARD_UPDATE_MODE } from "../../../components/CreateUpdateWizard/constants";

const initialValues = {
    name: "",
    properties: {},
};

const limit = pLimit(10);

class DevicesGroupsSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateGroupModal: false,
            showDeleteGroupModal: false,
            wizardMode: WIZARD_CREATE_MODE,
            values: undefined,
            devicesGroups: [],
            selectedGroup: undefined,
            groupForRemove: undefined,
            filteredDeviceGroups: [],
            search: "",
            detailsId: nanoid(),
            pageData: { start: 0, end: ITEMS_ON_PAGE },
        };
    }

    async getDeviceGroups() {
        const { strings } = this.props;

        const [status, deviceGroups] = await GetDevicesGroups();
        if (!status) {
            this.props.enqueueSnackbar(strings("Ошибка получения групп устройств"), { variant: "error" });
        }

        return deviceGroups;
    }

    async componentDidMount() {
        const { strings } = this.props;

        this.showFixegGroupTopic = PubSub.subscribe(SHOW_CREATE_GROUP_MODAL, async (msg, data) => {
            if (!data?.values) {
                this.setState({ showCreateGroupModal: false });
                return;
            }

            if (data.wizardMode === WIZARD_UPDATE_MODE && data.values?.id) {
                let [getDevicesStatus, devices] = await SearchDevices({ deviceGroups: [data.values.id] });
                if (getDevicesStatus) {
                    data.values.devices = devices?.map((d) => d.id) ?? [];
                } else {
                    this.props.enqueueSnackbar(strings("Ошибка получения устройств"), { variant: "error" });
                }
            }

            this.setState({
                showCreateGroupModal: true,
                values: data.values,
                wizardMode: data.wizardMode,
            });
        });

        this.showDeleteGroupTopic = PubSub.subscribe(SHOW_DELETE_GROUP_MODAL, (msg, data) => {
            if (!data?.values?.id) {
                this.setState({ showDeleteGroupModal: false });
                return;
            }

            this.setState({ showDeleteGroupModal: true, groupForRemove: data.values });
        });

        this.groupCreatedTopic = PubSub.subscribe(GROUP_CREATED_TOPIC, (msg, data) => {
            let devicesGroups = [...this.state.devicesGroups, data];
            this.setState({
                showModal: false,
                values: { ...initialValues },
                devicesGroups: devicesGroups,
                search: "",
            });
        });

        this.groupEditedTopic = PubSub.subscribe(GROUP_EDITED_TOPIC, async (msg, data) => {
            const [status, count] = await CountDevicesInGroup(data.id);
            if (status) {
                data.count = count;
            } else {
                data.count = 0;
                this.props.enqueueSnackbar(strings("Ошибка получения количества устройств"), { variant: "error" });
            }

            const devicesGroups = this.state.devicesGroups.filter((dg) => dg.id !== data.id);
            devicesGroups.push(data);

            let newState = {
                showModal: false,
                values: { ...initialValues },
                devicesGroups,
                search: "",
                detailsId: nanoid(),
            };

            if (this.state.selectedGroup) {
                newState.selectedGroup = data;
            }

            this.setState(newState);
        });

        this.groupDeletedTopic = PubSub.subscribe(GROUP_DELETED_TOPIC, (msg, data) => {
            const devicesGroups = this.state.devicesGroups.filter((dg) => dg.id !== data);
            this.setState({
                groupForRemove: null,
                showDeleteRoleModal: false,
                devicesGroups: devicesGroups,
                selectedGroup: null,
                search: "",
            });
        });

        let devicesGroups = await this.getDeviceGroups();
        this.setState({
            devicesGroups: devicesGroups,
        });

        this.loadDevicesByGroup(devicesGroups);
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (this.props.userInfo.updateId !== prevProps.userInfo.updateId) {
            let devicesGroups = await this.getDeviceGroups();
            this.setState({
                devicesGroups: devicesGroups,
            });

            this.loadDevicesByGroup(devicesGroups);
        }
    };

    componentWillUnmount() {
        this.showFixegGroupTopic && PubSub.unsubscribe(this.showFixegGroupTopic);
        this.showDeleteGroupTopic && PubSub.unsubscribe(this.showDeleteGroupTopic);
        this.groupCreatedTopic && PubSub.unsubscribe(this.groupCreatedTopic);
        this.groupEditedTopic && PubSub.unsubscribe(this.groupEditedTopic);
        this.groupDeletedTopic && PubSub.unsubscribe(this.groupDeletedTopic);
    }

    loadDevicesByGroup(devicesGroups) {
        devicesGroups?.forEach((dg) =>
            limit(async () => {
                let [, count] = await CountDevicesInGroup(dg.id);
                let groupsFromState = this.state.devicesGroups;
                const index = groupsFromState.findIndex((group) => dg.id === group.id);
                groupsFromState[index].count = count;
                this.setState({ devicesGroups: groupsFromState });
            })
        );
    }

    render() {
        const {
            wizardMode,
            showCreateGroupModal,
            values,
            search,
            devicesGroups,
            selectedGroup,
            showDeleteGroupModal,
            groupForRemove,
            detailsId,
            pageData,
        } = this.state;
        const { strings } = this.props;

        const { userInfo } = this.props;

        let deviceGroupList = devicesGroups;
        if (search && search !== "") {
            deviceGroupList = devicesGroups?.filter(
                (dg) => dg.name?.toLowerCase()?.indexOf(search?.toLowerCase()) >= 0
            );
        }

        return (
            <>
                {showCreateGroupModal && values?.properties?.groupType === "fixed" && (
                    <FixedGroupModal mode={wizardMode} values={values} />
                )}

                {showCreateGroupModal && values?.properties?.groupType === "dynamic" && (
                    <DynamicGroupModal mode={wizardMode} values={values} />
                )}

                {showDeleteGroupModal && <DeleteModal groupForRemove={groupForRemove} />}

                <EventsLayoutPrimary hasSecondary={true}>
                    <EventsLayoutPrimaryInner>
                        <EventsLayoutHeader>
                            <EventsLayoutHeaderTitle>
                                <Text variant="title" component="h1">
                                    {strings("Группы оборудования")}
                                </Text>

                                <ExpandableSearch
                                    value={this.state.search}
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                    onSubmit={() => {
                                        /* ignore enter */
                                    }}
                                    placeholder={strings("Найти")}
                                    colorVariant="dark"
                                    inHeader
                                />
                            </EventsLayoutHeaderTitle>

                            {permissionExists(userInfo, "devices.deviceGroup.lifeCycle") ? (
                                <EventsLayoutHeaderToolbox>
                                    <Dropdown
                                        toggleLabel={strings("Создать")}
                                        closeOnClickInside
                                        toggleVariant="secondary"
                                        horizontalAlignment="right"
                                    >
                                        <DropdownItem
                                            as="button"
                                            onClick={() =>
                                                PubSub.publish(SHOW_CREATE_GROUP_MODAL, {
                                                    wizardMode: WIZARD_CREATE_MODE,
                                                    values: { ...initialValues, properties: { groupType: "fixed" } },
                                                })
                                            }
                                        >
                                            {strings("Фиксированную группу")}
                                        </DropdownItem>
                                        <DropdownItem
                                            as="button"
                                            onClick={() =>
                                                PubSub.publish(SHOW_CREATE_GROUP_MODAL, {
                                                    wizardMode: WIZARD_CREATE_MODE,
                                                    values: { ...initialValues, properties: { groupType: "dynamic" } },
                                                })
                                            }
                                        >
                                            {strings("Динамическую группу")}
                                        </DropdownItem>
                                    </Dropdown>
                                </EventsLayoutHeaderToolbox>
                            ) : (
                                <Text />
                            )}
                        </EventsLayoutHeader>
                        <DevicesGroupsTable
                            deviceGroupList={deviceGroupList.slice(pageData.start, pageData.end)}
                            selectedGroup={selectedGroup}
                            selectGroup={(group) => this.setState({ selectedGroup: group })}
                            strings={strings}
                        />
                    </EventsLayoutPrimaryInner>
                    <CommonTablePagination
                        tableData={deviceGroupList}
                        setPaging={(pageData) => this.setState({ pageData })}
                        searchMode={search !== ""}
                    />
                </EventsLayoutPrimary>

                {selectedGroup?.properties?.groupType === "dynamic" && (
                    <DynamicGroupsDetails
                        key={detailsId}
                        selected={selectedGroup}
                        setSelected={(group) => this.setState({ selectedGroup: group })}
                    />
                )}

                {selectedGroup?.properties?.groupType === "fixed" && (
                    <FixedGroupsDetails
                        key={detailsId}
                        selected={selectedGroup}
                        setSelected={(group) => this.setState({ selectedGroup: group })}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withCultureContext(withSnackbar(DevicesGroupsSettings)));

import React from "react";
import {
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    TableHoverContainer,
    TableAccordionItem,
    Icon,
    Row,
    Button,
} from "headpoint-react-components";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class EventTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            draggable: undefined,
            allowDrag: undefined,
        };
    }

    flatten = (object) => {
        const getChild = (key, objectBit) => {
            return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
        };

        return Object.assign(
            {},
            ...(function _flatten(objectBit) {
                return [].concat(
                    ...Object.keys(objectBit).map((key) =>
                        typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                    )
                );
            })(object)
        );
    };

    handleShowDetails = (id) => {
        const flattenEventTypeList = this.flatten(this.props.tableData);
        const nextEvent = flattenEventTypeList[id];
        this.props.setSelectedEvent(nextEvent);
    };

    handleDragStart = (e, item) => {
        if (!this.state.allowDrag) e.preventDefault();

        e.dataTransfer.setData("text/html", item.id);

        this.setState({ draggable: item });
    };

    handleDrop = (e, i, j) => {
        e.preventDefault();
        e.stopPropagation();

        const nextTableData = [...this.props.tableData];
        const draggableData = this.getIndexOf(nextTableData, this.state.draggable.id);

        nextTableData[draggableData.parentIndex].children.splice(draggableData.childIndex, 1);

        nextTableData[i].children.splice(j, 0, this.state.draggable);

        this.props.setTableData(nextTableData);
    };

    handleAllowDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        return false;
    };

    handleDropParent = (e, i) => {
        e.preventDefault();
        e.stopPropagation();

        const nextTableData = [...this.props.tableData];
        const draggableData = this.getIndexOf(nextTableData, this.state.draggable.id);

        nextTableData[draggableData.parentIndex].children.splice(draggableData.childIndex, 1);

        nextTableData[i].children.push(this.state.draggable);

        this.props.setTableData(nextTableData);
    };

    render() {
        const { selectedEvent, tableData } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("Нормализованные события и их типы")}</TableHeadCell>
                                    <TableHeadCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {tableData.map((eventType, i) => (
                                    <TableAccordionItem
                                        key={eventType.code}
                                        toggle={
                                            <TableCell verticalAlign="middle" colSpan={selectedEvent ? 2 : 4}>
                                                <Text color="primary" variant="body-sm">
                                                    {strings(eventType.name)}
                                                </Text>
                                            </TableCell>
                                        }
                                        onDragOver={this.handleAllowDrop}
                                        onDrop={(e) => this.handleDropParent(e, i)}
                                    >
                                        {eventType.children &&
                                            eventType.children.map((event, j) => (
                                                <TableRow
                                                    key={event.code}
                                                    isSelected={selectedEvent && event.id === selectedEvent.id}
                                                    draggable
                                                    onDragStart={(e) => this.handleDragStart(e, event)}
                                                    onDragOver={this.handleAllowDrop}
                                                    onDrop={(e) => this.handleDrop(e, i, j)}
                                                    onDragEnd={this.handleDragEnd}
                                                >
                                                    <TableCell verticalAlign="middle">
                                                        <Row align="middle">
                                                            <Text color="primary" variant="body-sm">
                                                                <Icon icon="settings-event" size={16} />
                                                            </Text>
                                                            {"\u00A0\u00A0"}
                                                            <Text color="primary" variant="body-sm">
                                                                {strings(event.name)}
                                                            </Text>
                                                        </Row>
                                                    </TableCell>

                                                    <>
                                                        <TableCell verticalAlign="middle">
                                                            <TableHoverContainer>
                                                                <Button
                                                                    icon="info"
                                                                    title="Info"
                                                                    variant="ghost"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.handleShowDetails(event.id);
                                                                    }}
                                                                />
                                                            </TableHoverContainer>
                                                        </TableCell>
                                                    </>
                                                </TableRow>
                                            ))}
                                    </TableAccordionItem>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

import React from "react";
import {
    Button,
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Pagination,
} from "headpoint-react-components";
import {
    EventsLayoutPrimary,
    EventsLayoutHeader,
    EventsLayoutHeaderTitle,
    EventsLayoutTableWrapper,
    EventsLayoutPaginationWrapper,
    EventsLayoutHeaderToolbox,
    EventsLayoutHeaderToolboxItem,
} from "../../../layout/EventsLayout";
import { withSnackbar } from "notistack";
import { GeneralContextConsumer, permissionExists, serviceExists } from "../../../contexts";
import { eventImportance } from "./constants";
import moment from "moment";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { SHOW_NEW_EVENT } from "../../../core/coreTopics";
import { nanoid } from "nanoid";

class EventList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            offset: 0,
            showNewEventModal: false,
        };
    }

    handleShowDetails = (eventId) => {
        if (!this.props.events) return;
        const nextEvent = this.props.events.find((ev) => ev.id === eventId);
        nextEvent["locationName"] = this.props.locationNames?.get(nextEvent.locationId);
        this.props.setSelectedEvent(nextEvent);
    };

    handlePage = (p) => {
        if (p <= this.props.pagesCount) {
            this.props.SetValue("page", p);
        }
    };

    render() {
        const { userInfo, pagesCount, page, locationNames, defaultEventTypes, timerId, strings } = this.props;
        const count = pagesCount;
        let offset = Math.min(page > 3 ? page - 3 : 0, count - 5);
        offset < 0 && (offset = 0);

        return (
            <GeneralContextConsumer>
                {(generalInfo) => (
                    <>
                        <EventsLayoutPrimary hasSecondary={this.props.hasSecondary}>
                            <EventsLayoutHeader>
                                <EventsLayoutHeaderTitle>
                                    <Text variant="title" component="h1">
                                        {strings("События")}
                                    </Text>
                                </EventsLayoutHeaderTitle>

                                <EventsLayoutHeaderToolbox>
                                    {serviceExists(generalInfo, "service.adapters.userEvents") &&
                                    permissionExists(userInfo, "events.user.post") ? (
                                        <EventsLayoutHeaderToolboxItem>
                                            <Button
                                                variant="secondary"
                                                label={strings("Создать")}
                                                onClick={() => PubSub.publish(SHOW_NEW_EVENT, undefined)}
                                            >
                                                {strings("Создать")}
                                            </Button>
                                        </EventsLayoutHeaderToolboxItem>
                                    ) : (
                                        <div />
                                    )}

                                    <EventsLayoutHeaderToolboxItem>
                                        {timerId ? (
                                            <Button
                                                icon="pause"
                                                variant="secondary"
                                                title={strings("Пауза")}
                                                onClick={() => this.props.stopAutoUpdate()}
                                            />
                                        ) : (
                                            <Button
                                                icon="arrow-right"
                                                variant="secondary"
                                                title={strings("Старт")}
                                                onClick={() => this.props.startAutoUpdate()}
                                            />
                                        )}
                                    </EventsLayoutHeaderToolboxItem>
                                </EventsLayoutHeaderToolbox>
                            </EventsLayoutHeader>

                            <EventsLayoutTableWrapper>
                                <Table hasHover stickyHeader isFixed bgColor="dark">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeadCell>{strings("Источник")}</TableHeadCell>
                                            <TableHeadCell>{strings("Тип")}</TableHeadCell>
                                            <TableHeadCell>{strings("Дата регистрации события")}</TableHeadCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {this.props?.events?.map((row) => {
                                            const eventSeverity = eventImportance.find(
                                                (ei) => ei.code === row.severity
                                            );
                                            const eventSeverityColor =
                                                eventSeverity?.color === "info"
                                                    ? "primary"
                                                    : eventSeverity?.color ?? "primary";
                                            return (
                                                <TableRow
                                                    key={nanoid()}
                                                    isDisabled={row.isRead}
                                                    onClick={() => this.handleShowDetails(row.id)}
                                                    isSelected={
                                                        this.props.selectedEvent &&
                                                        row.id === this.props.selectedEvent.id
                                                    }
                                                >
                                                    <TableCell>
                                                        <Text color={eventSeverityColor} variant="body-sm">
                                                            {row.deviceName ?? row.deviceId}
                                                        </Text>
                                                        <br />
                                                        <Text color="secondary" variant="description">
                                                            {locationNames instanceof Map
                                                                ? locationNames.get(row.locationId)
                                                                : row.locationId}
                                                        </Text>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Text
                                                            color={row.isRead ? "secondary" : eventSeverityColor}
                                                            variant="body-sm"
                                                        >
                                                            {strings(
                                                                defaultEventTypes.find((et) => et.value === row.type)
                                                                    ?.label
                                                            ) ?? row.type}
                                                        </Text>
                                                    </TableCell>

                                                    <TableCell>
                                                        <Text
                                                            color={row.isRead ? "secondary" : "white"}
                                                            variant="body-sm"
                                                        >
                                                            {row.registrationDate
                                                                ? moment(row.registrationDate).format("DD-MM-YYYY")
                                                                : ""}
                                                        </Text>
                                                        <br />
                                                        <Text color="secondary" variant="description">
                                                            {row.registrationDate
                                                                ? moment(row.registrationDate).format("HH:mm:ss")
                                                                : ""}
                                                        </Text>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </EventsLayoutTableWrapper>
                            <EventsLayoutPaginationWrapper>
                                <Button
                                    variant="secondary"
                                    label={strings("Показать ещё")}
                                    onClick={this.props.HandleShowMore}
                                />

                                <Pagination
                                    offset={offset}
                                    page={page}
                                    count={count}
                                    onChange={(e) => this.handlePage(e)}
                                />
                            </EventsLayoutPaginationWrapper>
                        </EventsLayoutPrimary>
                    </>
                )}
            </GeneralContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withSnackbar(EventList));

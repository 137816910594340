import DetailsTabDevices from "./DetailsTabDevices";
import DetailsTabMain from "./DetailsTabMain";
import DetailsTabTagsAndTypes from "./DetailsTabTagsAndTypes";
import DeviceGroupDetails from "./DeviceGroupDetails";

class DynamicGroupsDetails extends DeviceGroupDetails {
    constructor(props) {
        super(props);
        this.state = {
            detailsTab: "main",
        };

        this.tabSets = [
            {
                title: "Общее",
                key: "main",
                component: DetailsTabMain,
            },
            {
                title: "Типы и теги оборудования",
                key: "typesTags",
                component: DetailsTabTagsAndTypes,
            },
            {
                title: "Оборудование",
                key: "devices",
                component: DetailsTabDevices,
            },
        ];
    }
}

export default DynamicGroupsDetails;

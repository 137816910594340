import React, { useEffect } from "react";
import {
    EventsLayoutPaginationWrapper,
    EventsLayoutTableWrapper,
    EventsLayoutWrapper,
} from "../../../../layout/EventsLayout";
import {
    Table,
    TableHead,
    TableHeadCell,
    TableRow,
    Text,
    TableBody,
    TableCell,
    Space,
    TableHoverContainer,
    TableAccordionItem,
    Button,
    Pagination,
    Icon,
} from "headpoint-react-components";
import { CultureContextConsumer, withCultureContext } from "../../../../contexts/cultureContext/CultureContext";
import { useDispatch, useSelector } from "react-redux";
import {
    closeGroup,
    CONTROL_ZONE_ITEMS_ON_PAGE,
    fetchControlZones,
    fetchControlZonesGroups,
    saveControlZonesState,
    searchControlZones,
} from "../../../../app/reducers/controlZonesReducer";
import { withSnackbar } from "notistack";

const ControlZonesTable = ({ strings, enqueueSnackbar }) => {
    const dispatch = useDispatch();

    const { groups, status, countOnPage, pageNumber, pagesCount, search, offset, searchList } = useSelector(
        (state) => state.controlZones
    );

    useEffect(() => {
        const filter = {
            countOnPage: countOnPage,
            page: pageNumber - 1,
            like: search,
        };

        if (search?.length > 0) {
            const filter = { like: search, countOnPage, page: pageNumber - 1 };
            dispatch(searchControlZones({ filter, enqueueSnackbar, strings }));
        } else {
            dispatch(fetchControlZonesGroups({ filter, enqueueSnackbar, strings }));
        }
    }, [pageNumber, countOnPage, status, search, dispatch, strings, enqueueSnackbar]);

    const groupActions = (zoneGroup) => {
        return (
            <TableHoverContainer>
                <Button
                    icon="info"
                    title="Info"
                    variant="ghost"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(saveControlZonesState({ selectedGroup: zoneGroup, selectedZone: null }));
                    }}
                    noPadding
                    width="32px"
                />
            </TableHoverContainer>
        );
    };

    const rowActions = (zone) => (
        <TableHoverContainer>
            <Button
                icon="info"
                title="Info"
                variant="ghost"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch(saveControlZonesState({ selectedZone: zone, selectedGroup: null }));
                }}
                noPadding
                width="32px"
            />
        </TableHoverContainer>
    );

    const handlePage = (page) => {
        if (page <= pagesCount) {
            dispatch(saveControlZonesState({ pageNumber: page }));
        }
    };

    const handleShowMore = async () => {
        dispatch(
            saveControlZonesState({
                groups: [],
                countOnPage: countOnPage + CONTROL_ZONE_ITEMS_ON_PAGE,
                pageNumber: 1,
            })
        );
    };

    const createRow = (tree) => {
        if (!tree) {
            return null;
        }
        return tree?.map((obj) => (obj.groupId ? zoneNode(obj) : groupNode(obj)));
    };

    const groupNode = (group) => {
        return (
            <TableAccordionItem
                key={group.id}
                onClick={() => {
                    if (group.children) {
                        dispatch(closeGroup({ groupId: group.id }));
                    } else {
                        dispatch(fetchControlZones({ groupId: group.id, enqueueSnackbar, strings }));
                    }
                }}
                toggle={
                    <>
                        <TableCell verticalAlign="middle">
                            <Text color="primary" variant="body-sm">
                                {group.name}
                            </Text>
                        </TableCell>
                        <TableCell verticalAlign="middle" />
                        <TableCell verticalAlign="middle">{groupActions(group)}</TableCell>
                    </>
                }
            >
                {createRow(group.children)}
            </TableAccordionItem>
        );
    };

    const zoneNode = (zone) => {
        return (
            <TableRow key={zone.id}>
                <TableCell verticalAlign="middle">
                    <Text variant="body-sm">
                        <Icon icon={"devicegroup"} size={16} />
                    </Text>
                    <Space isDouble />
                    <Text color="primary" variant="body-sm">
                        {zone.name}
                    </Text>
                </TableCell>
                <TableCell verticalAlign="middle">{zone?.devicesCount}</TableCell>
                <TableCell verticalAlign="middle">{rowActions(zone)}</TableCell>
            </TableRow>
        );
    };

    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <EventsLayoutWrapper>
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>

                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("группы зон котроля и зоны")}</TableHeadCell>
                                    <TableHeadCell>{strings("устройств")}</TableHeadCell>
                                    <TableHeadCell>{strings("инфо")}</TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>{search?.length > 0 ? createRow(searchList) : createRow(groups)}</TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                    <EventsLayoutPaginationWrapper>
                        <Button variant="secondary" label={strings("Показать ещё")} onClick={handleShowMore} />
                        <Pagination
                            offset={offset}
                            page={pageNumber}
                            count={pagesCount}
                            onChange={(e) => handlePage(e)}
                        />
                    </EventsLayoutPaginationWrapper>
                </EventsLayoutWrapper>
            )}
        </CultureContextConsumer>
    );
};

export default withCultureContext(withSnackbar(ControlZonesTable));

import React from "react";
import { ModalFormItem } from "../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField, Row, Text } from "headpoint-react-components";
import styled from "styled-components";
import { CultureContextConsumer } from "../../../../../contexts/cultureContext/CultureContext";

export class ModalStepCheckParameters extends React.Component {
    handleInputChange = (e) => {
        const fieldName = e.rawEvent.target.name;
        this.props.SetValuesProperty(fieldName, e.target.value);
    };

    render() {
        const { values } = this.props;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <>
                        <ModalSection>
                            <Row marginBottom={14}>
                                <Text color="primary" variant="body">
                                    {strings("Колебание битрейта - %")}
                                </Text>
                            </Row>

                            <ModalFormItem>
                                <Row justify="between">
                                    <TextField
                                        label={strings("Допустимое")}
                                        id="bitrateWarningViolation"
                                        name="bitrateWarningViolation"
                                        type="uint"
                                        value={values.bitrateWarningViolation}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                    />

                                    <TextField
                                        label={strings("Предельное")}
                                        id="bitrateCriticalViolation"
                                        name="bitrateCriticalViolation"
                                        type="uint"
                                        value={values.bitrateCriticalViolation}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                    />
                                </Row>
                            </ModalFormItem>
                        </ModalSection>

                        <ModalSection>
                            <Row marginBottom={14}>
                                <Text color="primary" variant="body">
                                    {strings("Потери кадров - %")}
                                </Text>
                            </Row>

                            <ModalFormItem>
                                <Row justify="between">
                                    <TextField
                                        label={strings("Допустимое")}
                                        id="packetLossWarningViolation"
                                        name="packetLossWarningViolation"
                                        type="uint"
                                        value={values.packetLossWarningViolation}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                    />

                                    <TextField
                                        label={strings("Предельное")}
                                        id="packetLossCriticalViolation"
                                        name="packetLossCriticalViolation"
                                        type="uint"
                                        value={values.packetLossCriticalViolation}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                    />
                                </Row>
                            </ModalFormItem>
                        </ModalSection>

                        <ModalSection>
                            <Row marginBottom={14}>
                                <Text color="primary" variant="body">
                                    {strings("Колебание частоты кадров - %")}
                                </Text>
                            </Row>

                            <ModalFormItem>
                                <Row justify="between">
                                    <TextField
                                        label={strings("Допустимое")}
                                        id="fpsWarningViolation"
                                        name="fpsWarningViolation"
                                        type="uint"
                                        value={values.fpsWarningViolation}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                    />

                                    <TextField
                                        label={strings("Предельное")}
                                        id="fpsCriticalViolation"
                                        name="fpsCriticalViolation"
                                        type="uint"
                                        value={values.fpsCriticalViolation}
                                        onChange={this.handleInputChange}
                                        colorVariant="light"
                                        width={180}
                                    />
                                </Row>
                            </ModalFormItem>
                        </ModalSection>
                    </>
                )}
            </CultureContextConsumer>
        );
    }
}

export const TimeoutInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

import React from "react";
import { DetailsBoxContent, DetailsBoxMedia } from "../../../components/DetailsBox";
import { GeneralContextConsumer, permissionExists, serviceExists } from "../../../../contexts";
import { Button, Row, Text } from "headpoint-react-components";
import { AspectBox } from "../../../components/AspectBox";
import { EventsLayout } from "../../../../layout/EventsLayout";
import { pages } from "../../../../layout/Navbar/pages";
import EventDetailsMap from "./EventDetailsMap";
import { withRouter } from "react-router-dom";
import { nanoid } from "nanoid";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

class DetailsTabOnMap extends React.Component {
    navigateToMap(path) {
        this.props.history.push({
            pathname: path,
            state: { type: "ShowMap", device: this.props.device },
        });
    }

    render() {
        const { userInfo, device } = this.props;
        const mapPage = pages.find((p) => p.url === "/map");
        if (!userInfo) {
            throw new Error("Error! UserInfo not found!");
        }

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <GeneralContextConsumer>
                        {(generalInfo) => (
                            <DetailsBoxContent>
                                {serviceExists(generalInfo, mapPage.serviceCodes) &&
                                    permissionExists(userInfo, mapPage.permissions) && (
                                        <>
                                            <Row marginTop={32} marginBottom={16}>
                                                <Text variant="title" component="h3">
                                                    {strings("На карте")}
                                                </Text>
                                            </Row>
                                            <DetailsBoxMedia>
                                                <AspectBox aspect="16:9">
                                                    <EventsLayout>
                                                        <EventDetailsMap
                                                            key={device.id ?? nanoid()}
                                                            device={device}
                                                            generalInfo={generalInfo}
                                                            strings={strings}
                                                        />
                                                    </EventsLayout>
                                                </AspectBox>
                                            </DetailsBoxMedia>
                                            <Button
                                                variant="secondary"
                                                label={strings("Перейти на карту")}
                                                icon="arrow-right"
                                                isIconAfter
                                                onClick={() => this.navigateToMap(mapPage.url)}
                                            />
                                        </>
                                    )}
                            </DetailsBoxContent>
                        )}
                    </GeneralContextConsumer>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(withRouter(DetailsTabOnMap));

import React from "react";
import CreateUpdatePresetModal from "./CreateUpdatePresetModal";
import { SHOW_STREAM_PLAYER_DIALOG_PREFIX, CRUD_PRESETS_TOPIC_PREFIX } from "../streamTopics";
import PubSub from "pubsub-js";
import { CreatePreset } from "../../../../services/presets";
import { LoaderAnimation } from "../../../components/LoaderAnimation/LoaderAnimation";
import { PTZ_FEATURE_CODE } from "../constants";
import { getServiceFeature } from "../../../../contexts/GeneralContext";
import { ExecuteCommand } from "../../../../services/featureCommands";
import { withSnackbar } from "notistack";
import { withCultureContext } from "../../../../contexts/cultureContext/CultureContext";

const initialPresetValues = {
    name: "",
    description: "",
    pan: 0,
    tilt: 0,
    zoom: 0,
    type: null,
};

class CreatePresetModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preset: { ...initialPresetValues },
            loaded: false,
        };
    }

    componentDidMount = async () => {
        await this.getPosition();
        this.setState({
            loaded: true,
        });
    };

    dtoFromPreset(values) {
        let dto = { ...values };

        delete dto.pan;
        delete dto.tilt;
        delete dto.zoom;

        dto.properties = {
            ptz: {
                pan: values.pan,
                tilt: values.tilt,
                zoom: values.zoom,
            },
        };

        return dto;
    }

    createHandler = async (preset) => {
        const {
            data: { camera },
            strings,
        } = this.props;

        const dto = this.dtoFromPreset(preset);
        const [success, code, id] = await CreatePreset(dto, camera?.id);
        if (success) {
            this.props.enqueueSnackbar(strings("Пресет создан"), { variant: "success" });
        } else {
            switch (code) {
                case 403: // forbiden
                    this.props.enqueueSnackbar(strings("Не хватает прав для создания пресета"), { variant: "warning" });
                    break;

                case 409: // conflict
                    this.props.enqueueSnackbar(strings("В одном пространстве должны быть пресеты с разными именами"), {
                        variant: "warning",
                    });
                    break;

                default:
                    this.props.enqueueSnackbar(strings("Не удалось создать пресет"), { variant: "error" });
                    break;
            }

            return;
        }

        PubSub.publish(SHOW_STREAM_PLAYER_DIALOG_PREFIX + camera?.id, undefined);
        PubSub.publish(CRUD_PRESETS_TOPIC_PREFIX + camera?.id, {
            type: "create",
            data: {
                preset: { ...dto, id },
            },
        });
    };

    getPosition = async () => {
        const {
            generalInfo,
            enqueueSnackbar,
            data: { camera },
            strings,
        } = this.props;
        const { preset } = this.state;
        const featureSettings = camera?.properties?.settings?.features;
        const ptzFeatureSettings = featureSettings ? featureSettings[PTZ_FEATURE_CODE] : undefined;
        if (!ptzFeatureSettings) {
            enqueueSnackbar(strings("Отсутствуют настройки PTZ"), { variant: "error" });
            return;
        }

        const ptzFeature = getServiceFeature(generalInfo, ptzFeatureSettings.serviceCode, PTZ_FEATURE_CODE);

        if (!ptzFeature) {
            enqueueSnackbar(
                `${strings("Не удалось найти сервис с кодом")} ${ptzFeatureSettings.serviceCode} ${strings(
                    "и фичей"
                )} ${PTZ_FEATURE_CODE}`,
                { variant: "error" }
            );
            return;
        }

        let parameters = { id: camera.id };
        let commandCode = "getPosition";
        const command = ptzFeature?.commands?.list.find((c) => c.code === commandCode);

        if (command && command.path && command.method) {
            const [success, , data] = await ExecuteCommand(
                command?.method,
                ptzFeatureSettings.serviceCode,
                PTZ_FEATURE_CODE,
                command?.path,
                parameters
            );
            if (!success) {
                enqueueSnackbar(strings("Невозможно получить позицию камеры"), { variant: "error" });
            } else {
                this.setState({ preset: { ...preset, ...data } });
            }
        } else {
            enqueueSnackbar(`${strings("Команда")} ${commandCode} ${strings("отсутствует у адаптера")}`, {
                variant: "error",
            });
        }
    };

    render() {
        const { CloseHandler, strings } = this.props;
        const { preset, loaded } = this.state;

        if (!loaded) {
            return <LoaderAnimation message={strings("Загрузка текущей позиции камеры...")} />;
        }

        return (
            <CreateUpdatePresetModal
                title={strings("Создать пресет")}
                values={preset}
                CloseHandler={CloseHandler}
                SetValuesProperty={(name, value) => this.setState({ preset: { ...preset, [name]: value } })}
                SaveHandler={this.createHandler}
            />
        );
    }
}

export default withCultureContext(withSnackbar(CreatePresetModal));

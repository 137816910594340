import {
    Button,
    Modal,
    ModalBody,
    ModalBodyItem,
    ModalFooter,
    ModalFooterItem,
    ModalHeader,
    Text,
} from "headpoint-react-components";
import React from "react";

export function ExtensionErrorModal({ closeHandler, okHandler, strings }) {
    return (
        <Modal size="sm">
            <ModalHeader title={strings("Результаты проверки")} align="center" closeHandler={closeHandler} />
            <ModalBody>
                <ModalBodyItem>
                    <Text color="danger" variant="body-sm">
                        {`${strings("Некорректное расширение файла.")}`}
                    </Text>
                    <br />
                    <Text color="danger" variant="body-sm">
                        {`${strings("Пожалуйста убедитесь, что расширение загружаемого файла - .xls/.xlsx")}`}
                    </Text>
                </ModalBodyItem>
            </ModalBody>
            <ModalFooter>
                <ModalFooterItem>
                    <Button label={strings("Ок")} variant="primary" onClick={okHandler} />
                </ModalFooterItem>
            </ModalFooter>
        </Modal>
    );
}

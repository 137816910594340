import { Component, createContext } from "react";
import culturesArray from "./cultures";
export const CultureContext = createContext({});
const cultures = [
    {
        code: "ru",
        default: true,
        strings: {
            get(key) {
                return key;
            },
        },
    },
    ...culturesArray,
];
export class CultureContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cultureCode: window.localStorage.getItem("cultureCode"),
        };
    }

    provideStrings(cultureCode) {
        let culture = cultures.find((c) => c.default);

        if (cultureCode) {
            culture = cultures.find((c) => c.code === cultureCode);
        }

        return {
            strings: (key) => culture.strings.get(key) ?? key,
            code: culture.code,
            setCulture: (cultureCode) => {
                this.setState({ cultureCode });
                window.localStorage.setItem("cultureCode", cultureCode);
            },
        };
    }

    render() {
        const { cultureCode } = this.state;

        return (
            <CultureContext.Provider value={this.provideStrings(cultureCode)}>
                {this.props.children}
            </CultureContext.Provider>
        );
    }
}

export class CultureContextConsumer extends Component {
    render() {
        return <CultureContext.Consumer>{this.props.children}</CultureContext.Consumer>;
    }
}

export function withCultureContext(WrappedComponent) {
    return class extends Component {
        render() {
            return (
                <CultureContextConsumer>
                    {({ strings, code, setCulture }) => (
                        <WrappedComponent {...this.props} strings={strings} code={code} setCulture={setCulture} />
                    )}
                </CultureContextConsumer>
            );
        }
    };
}

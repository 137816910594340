import React from "react";
import PubSub from "pubsub-js";
import DialogOk from "./Dialogs/DialogOk";
import { SHOW_NOTIFIER_DIALOG } from "./dialogTopics";
import { DIALOG_OK } from "./dialogTypes";

class DialogNotifier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogToShow: null,
        };
    }

    componentDidMount() {
        this.notifyEvent = PubSub.subscribe(SHOW_NOTIFIER_DIALOG, async (msg, dialogData) => {
            await this.showDialog(dialogData);
        });
    }

    componentWillUnmount() {
        if (this.notifyEvent) {
            PubSub.unsubscribe(this.notifyEvent);
        }
    }

    showDialog = async (dialogData) => {
        let dialog;

        switch (dialogData.type) {
            case DIALOG_OK:
                dialog = <DialogOk handleClose={() => this.closeDialog()} text={dialogData?.text}></DialogOk>;
                break;
            default:
                const warning = "Неизвестный тип диалога: " + dialogData.type;
                dialog = <DialogOk handleClose={() => this.closeDialog()} text={warning}></DialogOk>;
        }

        this.setState({ dialogToShow: dialog });
    };

    closeDialog() {
        this.setState({ dialogToShow: null });
    }

    render() {
        return this.state.dialogToShow;
    }
}

export default DialogNotifier;

import React from "react";
import {
    Button,
    Text,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Icon,
    Row,
    TableHoverContainer,
} from "headpoint-react-components";
import { EventsLayoutTableWrapper } from "../../../../layout/EventsLayout";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";

export class TemplateTable extends React.Component {
    RowActions = (template) => (
        <TableHoverContainer>
            <Button
                icon="info"
                title="Info"
                variant="ghost"
                onClick={(e) => {
                    e.stopPropagation();
                    this.handleShowDetails(template.id);
                }}
            />
        </TableHoverContainer>
    );

    constructor(props) {
        super(props);
        this.state = {
            draggable: undefined,
            allowDrag: undefined,
        };
    }

    flatten = (object) => {
        const getChild = (key, objectBit) => {
            return !("children" in objectBit) && !Array.isArray(objectBit) ? { [objectBit.id]: objectBit } : null;
        };

        return Object.assign(
            {},
            ...(function _flatten(objectBit) {
                return [].concat(
                    ...Object.keys(objectBit).map((key) =>
                        typeof objectBit[key] === "object" ? _flatten(objectBit[key]) : getChild(key, objectBit)
                    )
                );
            })(object)
        );
    };

    handleShowDetails = (id) => {
        const flattenScenarioTemplateList = this.flatten(this.props.scenarioTemplateList);
        const nextTemplate = flattenScenarioTemplateList[id];
        this.props.setSelectedTemplate(nextTemplate);
    };

    render() {
        const { selectedTemplate } = this.props;
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <EventsLayoutTableWrapper>
                        <Table isFixed hasHover stickyHeader bgColor="dark">
                            <colgroup>
                                <col />
                                <col />
                                <col style={{ width: "80px" }} />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell>{strings("шаблон")}</TableHeadCell>
                                    <TableHeadCell>{strings("кол-во функций")}</TableHeadCell>
                                    <TableHeadCell></TableHeadCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.props.scenarioTemplateList.map((template) => (
                                    <TableRow
                                        key={template.id}
                                        isSelected={selectedTemplate && template.id === selectedTemplate.id}
                                    >
                                        <TableCell verticalAlign="middle">
                                            <Row verticalAlign="middle">
                                                <Text color="primary" variant="body-sm">
                                                    <Icon icon="template" size={16} />
                                                </Text>
                                                {"\u00A0\u00A0"}
                                                <Text color="primary" variant="body-sm">
                                                    {template.name}
                                                </Text>
                                            </Row>
                                        </TableCell>

                                        <TableCell verticalAlign="middle">
                                            <Text color="primary" variant="body-sm">
                                                {template.functions.length}
                                            </Text>
                                        </TableCell>

                                        <TableCell verticalAlign="middle" noVerticalSpace>
                                            {this.RowActions(template)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </EventsLayoutTableWrapper>
                )}
            </CultureContextConsumer>
        );
    }
}

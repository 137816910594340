import React from "react";
import { ModalFormItem } from "../../../../../components/CreateUpdateWizard/Styled";
import { ModalSection, TextField } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../../../contexts/cultureContext/CultureContext";

export class ModalStepDefault extends React.Component {
    handleInputChange = (e) => this.props.SetValuesPropertie(e.target.name, e.target.value);

    render() {
        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <ModalSection>
                        <ModalFormItem>
                            <TextField
                                label={strings("Название")}
                                name="name"
                                type="text"
                                value={this.props.values.name}
                                onChange={(e) => this.props.SetValuesPropertie("name", e.target.value)}
                                colorVariant="light"
                                error={!this.props.values.name ? strings("Поле не может быть пустым") : null}
                                fullWidth
                            />
                        </ModalFormItem>
                        <ModalFormItem>
                            <TextField
                                label={strings("Комментарий")}
                                name="comment"
                                type="text"
                                value={this.props.values.comment}
                                onChange={(e) => this.props.SetValuesPropertie("comment", e.target.value)}
                                colorVariant="light"
                                fullWidth
                                inputProps={{
                                    as: "textarea",
                                    rows: 4,
                                }}
                            />
                        </ModalFormItem>
                    </ModalSection>
                )}
            </CultureContextConsumer>
        );
    }
}

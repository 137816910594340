import React, { useState } from "react";
import { nanoid } from "nanoid";
import { Progress } from "headpoint-react-components";

export const ProgressWrapper = ({
    rangeStart,
    rangeEnd,
    position,
    seek,
    lacunas,
    marks,
    setOffset,
    detailInterval,
}) => {
    const min = 0;
    const max = rangeEnd - rangeStart;
    const percentage = ((position - min) * max) / (max - min) || 0;

    const [valueSeek, setValueSeek] = useState(null);
    const [startSetOffset, setStartSetOffset] = useState(false);

    const seekPercentage = (x) => {
        setValueSeek((x * max) / max);
        seek(valueSeek, false);
        if (startSetOffset) {
            setOffset(x);
        }
    };

    const handleMouseUp = () => {
        setStartSetOffset(false);
        setOffset(undefined);
        if (valueSeek) {
            seek(valueSeek, true);
        }
    };
    const handleMouseDown = () => {
        setStartSetOffset(true);
        setValueSeek(null);
    };

    const lac = lacunas
        ? lacunas.map((lac) => {
              let f = lac.from - rangeStart;
              let t = lac.to - rangeStart;
              return {
                  id: nanoid(),
                  fromPr: ((f < 0 ? 0 : f) * max) / (max - min) || 0,
                  toPr: ((t < 0 ? 0 : t) * max) / (max - min) || 0,
                  ...lac,
              };
          })
        : [];

    const intervalTime = detailInterval * 60 > max ? max : detailInterval * 60;

    return (
        <div
            onClick={(e) => {
                e.preventDefault();
            }}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
        >
            <Progress
                duration={max}
                currentTime={percentage}
                onSeekTo={seekPercentage}
                scale={intervalTime}
                lacunas={lac}
                timestamp={new Date(rangeStart * 1000)}
                marks={marks}
            />
        </div>
    );
};

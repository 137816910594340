import { Button, Modal, ModalBody, ModalBodyItem, Text } from "headpoint-react-components";
import { CultureContextConsumer } from "../../../../contexts/cultureContext/CultureContext";
import { ModalBodyWrapper } from "../../../archive/components/OrderArchiveModal/CreatedOrdersListModal";

const CreatedOrdersListModal = ({ orderNumber, onClose }) => {
    return (
        <CultureContextConsumer>
            {({ strings }) => (
                <Modal wrap size="sm" style={{ zIndex: 1 }}>
                    <ModalBody>
                        <ModalBodyItem>
                            <ModalBodyWrapper>
                                <Text color="secondary">
                                    {`${strings("Номер заказа")} ${orderNumber}`}
                                    <br />
                                </Text>
                                <Button style={{ marginTop: "2em" }} label={strings("Закрыть")} onClick={onClose} />
                            </ModalBodyWrapper>
                        </ModalBodyItem>
                    </ModalBody>
                </Modal>
            )}
        </CultureContextConsumer>
    );
};

export default CreatedOrdersListModal;

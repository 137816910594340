import React from "react";
import { LayoutSidebarBody, LayoutSidebarItem } from "../../../layout/MainLayout";
import { DateRangePicker, Icon, TextField, Checkbox, DropdownDivider, Space } from "headpoint-react-components";
import {
    FilterGroup,
    FilterItem,
    FilterGroupList,
    FilterAccordionPartion,
    FilterWrapper,
    FilterButtonItem,
} from "../../components/FiltersPartion";
import { nanoid } from "nanoid";
import { getTimeRange } from "../../../utilites/TimeUtils";
import { ReporterFilterTypes } from "../constants";
import { checkNodeName } from "../../../utilites/TreeUtils";
import { permissionExists } from "../../../contexts";
import { connect } from "react-redux";
import { CultureContextConsumer } from "../../../contexts/cultureContext/CultureContext";

class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSearch: "",
            checkedUsers: "",
            deviceGroupSearch: "",
            availabilityRuleSearch: "",
            qualityRuleSearch: "",
            deviceTypeSearch: "",
            sourceSearch: "",
            actionSearch: "",
            checkedSources: [],
        };
    }

    handleCheckboxChange = (array, setter, e) => {
        let newArray = [...array];
        if (newArray.includes(e)) {
            newArray = newArray.filter((et) => et !== e);
        } else {
            newArray = [...newArray, e];
        }

        setter(newArray);
    };

    handleDateRangePreset = (preset) => {
        const newDates = getTimeRange(preset);
        this.props.setValue("dates", newDates);
    };

    findAllNodes = (tree, searchName) => {
        let treeNodes = [];
        tree.forEach((node) => treeNodes.push(...checkNodeName(node, searchName)));
        return treeNodes;
    };

    findChildNodes = (tree, searchName) => {
        let treeNodes = [];
        tree.forEach((node) => treeNodes.push(...this.onlyChildNodes(node, searchName)));
        return treeNodes;
    };

    onlyChildNodes = (node, searchName) => {
        let nodes = [];
        if (!!!node.children && node.name.toLowerCase().includes(searchName.toLowerCase())) {
            nodes.push(node);
        }

        if (node.children && node.children.length > 0) {
            node.children.forEach((childNode) => {
                nodes.push(...checkNodeName(childNode, searchName));
            });
        }

        return nodes;
    };

    render() {
        const {
            userInfo,
            sourcesTree,
            openLocation,
            dates,
            plugins,
            selectedPlugin,
            sourceFilter,
            userFilter,
            userTree,
            statusFilter,
            statusTree,
            actionTree,
            actionFilter,
            entityTree,
            entityFilter,
            showNormal,
            showWarning,
            showDanger,
            deviceTypeFilter,
            defaultDeviceTypes,
            availabilityRulesFilter,
            defaultAvailabilityRules,
            qualityRulesFilter,
            defaultQualityRules,
            deviceGroupsFilter,
            defaultDeviceGroups,
        } = this.props;

        const {
            sourceSearch,
            userSearch,
            actionSearch,
            entitySearch,
            deviceTypeSearch,
            availabilityRuleSearch,
            deviceGroupSearch,
            qualityRuleSearch,
        } = this.state;
        const sources = sourceSearch ? this.findAllNodes(sourcesTree, sourceSearch) : sourcesTree;
        const users = userSearch ? this.findChildNodes(userTree, userSearch) : userTree;
        const actions = actionSearch ? this.findAllNodes(actionTree, actionSearch) : actionTree;
        const entities = entitySearch ? this.findAllNodes(entityTree, entitySearch) : entityTree;
        const deviceTypes = deviceTypeSearch
            ? defaultDeviceTypes?.filter((t) => t.label.toLowerCase().includes(deviceTypeSearch.toLowerCase()))
            : defaultDeviceTypes;
        const availabilityRules = availabilityRuleSearch
            ? defaultAvailabilityRules?.filter((t) =>
                  t.label.toLowerCase().includes(availabilityRuleSearch.toLowerCase())
              )
            : defaultAvailabilityRules;
        const qualityRules = qualityRuleSearch
            ? defaultQualityRules?.filter((t) => t.label.toLowerCase().includes(qualityRuleSearch.toLowerCase()))
            : defaultQualityRules;
        const deviceGroups = deviceGroupSearch
            ? defaultDeviceGroups?.filter((t) => t.label.toLowerCase().includes(deviceGroupSearch.toLowerCase()))
            : defaultDeviceGroups;

        const useDateFilter = selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.DateTime) ?? false;
        const useDeviceFilter = selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.DeviceIds) ?? false;
        const useUserFilter = selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.UserIds) ?? false;
        const useActionFilter = selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.Actions) ?? false;
        const useEntityFilter = selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.Entities) ?? false;
        const useStatusFilter = selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.Statuses) ?? false;
        const useSeverityFilter =
            selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.Severity) ?? false;
        const useDeviceTypeFilter =
            selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.DeviceType) ?? false;
        const useAvailabilityRuleFilter =
            selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.AvailabilityRule) ?? false;
        const useQualityRuleFilter =
            selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.QualityRule) ?? false;
        const useDeviceGroupsFilter =
            selectedPlugin?.filters?.some((f) => f.type === ReporterFilterTypes.DeviceGroups) ?? false;

        return (
            <CultureContextConsumer>
                {({ strings }) => (
                    <LayoutSidebarBody>
                        <FilterGroup
                            title={strings("Отчеты")}
                            isOpened={true}
                            hasBottomLine={false}
                            maxHeight={"100px"}
                        >
                            <FilterGroupList maxHeight="145px">
                                {plugins.map((plugin) => (
                                    <FilterButtonItem
                                        key={nanoid()}
                                        isActive={plugin.id === selectedPlugin?.id}
                                        onClick={() => this.props.setValue("selectedPlugin", plugin ?? {})}
                                    >
                                        {strings(plugin.name)}
                                    </FilterButtonItem>
                                ))}
                            </FilterGroupList>
                        </FilterGroup>
                        <Space />
                        <DropdownDivider />
                        <FilterWrapper>
                            {useSeverityFilter ? (
                                <LayoutSidebarItem>
                                    <FilterItem>
                                        <Checkbox
                                            label={strings("Доступно")}
                                            checked={showNormal}
                                            onChange={() => {
                                                this.props.setValue("showNormal", !showNormal);
                                            }}
                                            colorVariant="primary"
                                        />
                                    </FilterItem>
                                    <FilterItem>
                                        <Checkbox
                                            label={strings("Требует внимания")}
                                            checked={showWarning}
                                            onChange={() => {
                                                this.props.setValue("showWarning", !showWarning);
                                            }}
                                            colorVariant="warning"
                                        />
                                    </FilterItem>
                                    <FilterItem>
                                        <Checkbox
                                            label={strings("Недоступно")}
                                            checked={showDanger}
                                            onChange={() => {
                                                this.props.setValue("showDanger", !showDanger);
                                            }}
                                            colorVariant="danger"
                                        />
                                    </FilterItem>
                                </LayoutSidebarItem>
                            ) : (
                                <></>
                            )}

                            {useDeviceFilter &&
                            permissionExists(userInfo, ["devices.device.read", "locations.location.read"]) ? (
                                <FilterGroup
                                    title={strings("Источник")}
                                    hasValue={!!sourceFilter?.length}
                                    onClear={() => {
                                        this.setState({ sourceSearch: "" });
                                        this.props.setValue("sourceFilter", []);
                                    }}
                                    isMuted={!sourcesTree?.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ sourceSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={this.state.sourceSearch}
                                    />
                                    <FilterGroupList>
                                        <FilterAccordionPartion
                                            items={sources ?? []}
                                            selected={sourceFilter ?? []}
                                            selectHandler={(items) => this.props.setValue("sourceFilter", items)}
                                            openItem={openLocation}
                                        />
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <div />
                            )}

                            {useUserFilter &&
                            permissionExists(userInfo, ["users.user.read", "users.userGroup.read"]) ? (
                                <FilterGroup
                                    title={strings("Пользователи")}
                                    hasValue={!!userFilter?.length}
                                    onClear={() => {
                                        this.setState({ userSearch: "" });
                                        this.props.setValue("userFilter", []);
                                    }}
                                    isMuted={!userFilter?.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ userSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={userSearch}
                                    />
                                    <FilterGroupList>
                                        <FilterAccordionPartion
                                            items={users ?? []}
                                            selected={userFilter ?? []}
                                            selectHandler={(items) => this.props.setValue("userFilter", items)}
                                        />
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <div />
                            )}

                            {useActionFilter ? (
                                <FilterGroup
                                    title={strings("Тип действия")}
                                    hasValue={!!actionFilter?.length}
                                    onClear={() => {
                                        this.setState({ userSearch: "" });
                                        this.props.setValue("actionFilter", []);
                                    }}
                                    isMuted={!actionFilter?.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ actionSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={actionSearch}
                                    />
                                    <FilterGroupList>
                                        <FilterAccordionPartion
                                            items={actions ?? []}
                                            selected={actionFilter ?? []}
                                            selectHandler={(items) => this.props.setValue("actionFilter", items)}
                                        />
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <div />
                            )}

                            {useEntityFilter ? (
                                <FilterGroup
                                    title={strings("Объект в системе")}
                                    hasValue={!!entityFilter?.length}
                                    onClear={() => {
                                        this.setState({ userSearch: "" });
                                        this.props.setValue("actionFilter", []);
                                    }}
                                    isMuted={!entityFilter?.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ entitySearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={entitySearch}
                                    />
                                    <FilterGroupList>
                                        <FilterAccordionPartion
                                            items={entities ?? []}
                                            selected={entityFilter ?? []}
                                            selectHandler={(items) => this.props.setValue("entityFilter", items)}
                                        />
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <div />
                            )}

                            {useStatusFilter && (
                                <FilterGroup
                                    title={strings("Статус")}
                                    hasValue={!!statusFilter?.length}
                                    isMuted={!statusFilter?.length}
                                >
                                    <FilterGroupList>
                                        <FilterAccordionPartion
                                            items={statusTree ?? []}
                                            selected={statusFilter ?? []}
                                            selectHandler={(items) => this.props.setValue("statusFilter", items)}
                                        />
                                    </FilterGroupList>
                                </FilterGroup>
                            )}

                            {useDateFilter ? (
                                <FilterGroup
                                    title={strings("Дата")}
                                    hasValue={!!dates.from || !!dates.to}
                                    onClear={() => this.props.setValue("dates", { from: undefined, to: undefined })}
                                    isMuted={!dates.from && !dates.to}
                                >
                                    <DateRangePicker
                                        dates={dates}
                                        onChange={(d) => this.props.setValue("dates", d)}
                                        placeholder={strings("Выбрать период")}
                                        colorVariant="transparent"
                                        inputFormat="D.MM.YYYY HH:mm"
                                        presets={[
                                            {
                                                label: strings("Последний час"),
                                                handler: () => this.handleDateRangePreset("lastHour"),
                                            },
                                            {
                                                label: strings("Последние 6 часов"),
                                                handler: () => this.handleDateRangePreset("lastSixHours"),
                                            },
                                            {
                                                label: strings("Последние сутки"),
                                                handler: () => this.handleDateRangePreset("lastDay"),
                                            },
                                        ]}
                                        withTime
                                        customInputLabels={{
                                            hours: strings("Часы"),
                                            minutes: strings("Минуты"),
                                        }}
                                        customIcon={<Icon icon="calendar-clear" />}
                                        fullWidth
                                        positionFixed
                                    />
                                </FilterGroup>
                            ) : (
                                <div />
                            )}

                            {useDeviceTypeFilter ? (
                                <FilterGroup
                                    title={strings("Типы оборудования")}
                                    hasValue={!!deviceTypeFilter?.length}
                                    onClear={() => {
                                        this.setState({ deviceTypeSearch: "" });
                                        this.props.setValue("deviceTypeFilter", []);
                                    }}
                                    isMuted={!defaultDeviceTypes.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ deviceTypeSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={this.state.deviceTypeSearch}
                                    />

                                    <FilterGroupList>
                                        {deviceTypes.map((et) => (
                                            <FilterItem key={nanoid()}>
                                                <Checkbox
                                                    label={strings(et.label)}
                                                    checked={deviceTypeFilter.includes(et.value)}
                                                    onChange={() =>
                                                        this.handleCheckboxChange(
                                                            deviceTypeFilter,
                                                            (arr) => {
                                                                this.props.setValue("deviceTypeFilter", arr);
                                                            },
                                                            et.value
                                                        )
                                                    }
                                                />
                                            </FilterItem>
                                        ))}
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <></>
                            )}

                            {useDeviceGroupsFilter ? (
                                <FilterGroup
                                    title={strings("Группы оборудования")}
                                    hasValue={!!deviceGroupsFilter?.length}
                                    onClear={() => {
                                        this.setState({ deviceGroupSearch: "" });
                                        this.props.setValue("deviceGroupsFilter", []);
                                    }}
                                    isMuted={!defaultDeviceGroups.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ deviceGroupSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={this.state.deviceGroupSearch}
                                    />

                                    <FilterGroupList>
                                        {deviceGroups.map((et) => (
                                            <FilterItem key={nanoid()}>
                                                <Checkbox
                                                    label={strings(et.label)}
                                                    checked={deviceGroupsFilter.includes(et.value)}
                                                    onChange={() =>
                                                        this.handleCheckboxChange(
                                                            deviceGroupsFilter,
                                                            (arr) => {
                                                                this.props.setValue("deviceGroupsFilter", arr);
                                                            },
                                                            et.value
                                                        )
                                                    }
                                                />
                                            </FilterItem>
                                        ))}
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <></>
                            )}

                            {useAvailabilityRuleFilter ? (
                                <FilterGroup
                                    title={strings("Правила")}
                                    hasValue={!!availabilityRulesFilter?.length}
                                    onClear={() => {
                                        this.setState({ availabilityRuleSearch: "" });
                                        this.props.setValue("availabilityRulesFilter", []);
                                    }}
                                    isMuted={!defaultAvailabilityRules.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ availabilityRuleSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={this.state.availabilityRuleSearch}
                                    />

                                    <FilterGroupList>
                                        {availabilityRules.map((et) => (
                                            <FilterItem key={nanoid()}>
                                                <Checkbox
                                                    label={strings(et.label)}
                                                    checked={availabilityRulesFilter.includes(et.value)}
                                                    onChange={() =>
                                                        this.handleCheckboxChange(
                                                            availabilityRulesFilter,
                                                            (arr) => {
                                                                this.props.setValue("availabilityRulesFilter", arr);
                                                            },
                                                            et.value
                                                        )
                                                    }
                                                />
                                            </FilterItem>
                                        ))}
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <></>
                            )}

                            {useQualityRuleFilter ? (
                                <FilterGroup
                                    title={strings("Правила")}
                                    hasValue={!!qualityRulesFilter?.length}
                                    onClear={() => {
                                        this.setState({ qualityRuleSearch: "" });
                                        this.props.setValue("qualityRulesFilter", []);
                                    }}
                                    isMuted={!defaultQualityRules.length}
                                >
                                    <TextField
                                        colorVariant="light"
                                        fullWidth
                                        onChange={(e) => this.setState({ qualityRuleSearch: e.target?.value })}
                                        placeholder={strings("Найти")}
                                        type="text"
                                        value={this.state.qualityRuleSearch}
                                    />

                                    <FilterGroupList>
                                        {qualityRules.map((et) => (
                                            <FilterItem key={nanoid()}>
                                                <Checkbox
                                                    label={strings(et.label)}
                                                    checked={qualityRulesFilter.includes(et.value)}
                                                    onChange={() =>
                                                        this.handleCheckboxChange(
                                                            qualityRulesFilter,
                                                            (arr) => {
                                                                this.props.setValue("qualityRulesFilter", arr);
                                                            },
                                                            et.value
                                                        )
                                                    }
                                                />
                                            </FilterItem>
                                        ))}
                                    </FilterGroupList>
                                </FilterGroup>
                            ) : (
                                <></>
                            )}
                        </FilterWrapper>
                    </LayoutSidebarBody>
                )}
            </CultureContextConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.persistedReducer.userInfo,
    };
};

export default connect(mapStateToProps, null)(Filters);
